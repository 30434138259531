import React, { forwardRef, useState } from "react";
import {
  Container,
  Segment,
  Dimmer,
  Input,
  TextArea,
  Loader,
  Modal,
  Divider,
  Table,
  Dropdown,
  Form,
  Button,
  Message,
  Icon,
  Grid,
  List,
  Image,
  Checkbox as SementicCheckbox,
} from "semantic-ui-react";
import Lottie from "react-lottie";
import ReactPlayer from "react-player";
import loading from "../img/graphic/loading.json";
import {
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  Checkbox,
  Radio,
  CircularProgress,
  Switch,
  IconButton,
  LinearProgress,
  FormControl,
  ListItemText,
  InputLabel,
  Popover,
} from "@material-ui/core";
import MaterialTable from "material-table";
import { S3Image } from "aws-amplify-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faPrint,
  faSpinner,
  faCircleQuestion,
  faArrowLeft,
  faArrowRight,
  faCircleCheck,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import chroma from "chroma-js";
import CreatableSelect from "react-select/creatable";
import ReactSelect from "react-select";
import * as dateFns from "date-fns";
import { DatePicker } from "@material-ui/pickers";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import moment from "moment";
import DaumPostcode from "react-daum-postcode";
import _ from "lodash";
import { I18n } from "aws-amplify";
import JsBarcode from "jsbarcode";

import {
  numberToCurrency,
  connectAppStore,
  getPhoneDevicePlatform,
  strToHtml,
  refreshBrowser,
  generateBarcode,
} from "../helpers/Utils";
import Authority from "../services/Authority";
import Constants, {
  STOCK_ACTION_TYPE,
  STOCK_ACTION_TYPE_FOR_MULTI,
} from "../helpers/Constants";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FilterList from "@material-ui/icons/FilterList";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Clear from "@material-ui/icons/Clear";
import Search from "@material-ui/icons/Search";
import SaveAlt from "@material-ui/icons/SaveAlt";
import CloseIcon from "@material-ui/icons/Close";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import ViewColumn from "@material-ui/icons/ViewColumn";
import {
  TABLE_HEADER_FOR_COUNT_HISTORY,
  TABLE_HEADER_FOR_TRANSFER_HISTORY,
  TEMPLATE_KEYS_FOR_ITEM,
  TEMPLATE_KEYS_FOR_ORDER,
} from "../helpers/GuideData";
import { getCheckStatus, getExpiryDateOfCoupon } from "../reducers/coupon";

export const Title = ({ title, description, marginTop }) => (
  <Container
    style={{ marginTop: _.isNumber(marginTop) ? marginTop : 40 }}
    textAlign="left"
    fluid
  >
    <span className="text-sub-title">{title}</span>
    <span className="text-14 opacity-54">{description}</span>
  </Container>
);

export const DefaultLoader = ({ active }) => (
  <Segment className={"width-100 height-100 " + (active ? "" : "display-none")}>
    <Dimmer active={active} inverted page>
      <Loader inline="centered" size="large" />
    </Dimmer>
  </Segment>
);

export const TileLoader = ({}) => <Loader inline="centered" size="large" />;

export const AddressModal = ({
  open,
  onOpen,
  onChange,
  onCancel,
  onConfirm,
}) => (
  <Modal
    open={open}
    trigger={
      <Button className="primary-outline-button" size="mini" onClick={onOpen}>
        {I18n.get("settings.store.location.find")}
      </Button>
    }
  >
    <Modal.Header align="center">
      {I18n.get("settings.store.location.find")}
    </Modal.Header>
    <Modal.Content scrolling>
      <DaumPostcode onComplete={onChange} />
    </Modal.Content>
    <Modal.Actions>
      <Button basic onClick={onCancel}>
        {I18n.get("settings.store.location.cancel")}
      </Button>
      <Button color="blue" onClick={onConfirm}>
        {I18n.get("settings.store.location.confirm")}
      </Button>
    </Modal.Actions>
  </Modal>
);

export const InquiryModal = ({
  open,
  onOpen,
  onChange,
  onCancel,
  errorMessage,
  onConfirm,
}) => (
  <Modal
    open={open}
    // trigger={
    //   <Button basic color="blue" size="mini" onClick={onOpen}>
    //     {I18n.get("settings.store.location.find")}
    //   </Button>
    // }
  >
    <Modal.Header align="left">{"문의하기"}</Modal.Header>
    <Modal.Content scrolling>
      <Form>
        <Form.Group widths="equal">
          <Form.Field
            id="name"
            required
            control={Input}
            onChange={onChange}
            label="이름"
            placeholder="이름"
          />
          <Form.Field
            id="storeName"
            control={Input}
            onChange={onChange}
            label="매장 이름"
            placeholder="매장 이름"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            id="phoneNumber"
            control={Input}
            onChange={onChange}
            label="전화번호"
            placeholder="전화번호"
          />
          <Form.Field
            id="bizType"
            required
            control={Input}
            onChange={onChange}
            label="업종"
            placeholder="예) 음식업, 소매점 등"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            id="email"
            required
            control={Input}
            onChange={onChange}
            label="이메일"
            placeholder="이메일"
          />
          <Form.Field
            id="location"
            control={Input}
            onChange={onChange}
            label="지역"
            placeholder="예) 서울, 부산 등"
          />
        </Form.Group>

        <Form.Field
          id="inquiry"
          onChange={onChange}
          control={TextArea}
          label="문의사항"
          placeholder="궁금하신 점이 있거나 상담이 필요한 내용이 있으시면 적어주세요 :)"
        />
      </Form>
      <Message hidden={!errorMessage} error content={errorMessage} />
    </Modal.Content>
    <Modal.Actions>
      <Button basic onClick={onCancel}>
        {I18n.get("settings.store.location.cancel")}
      </Button>
      <Button color="blue" onClick={onConfirm}>
        {I18n.get("settings.store.location.confirm")}
      </Button>
    </Modal.Actions>
  </Modal>
);

export const TableIcons = {
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export const SimpleModal = React.memo(
  ({
    open,
    onClose,
    modalTitle,
    bodyContent,
    onConfirmPress,
    loading,
    buttonText,
    hasOneButton,
    noFooter,
    hasFixedFooter,
    maxHeight,
    isScrolling,
    isCenterHeader = false,
    noCloseButton,
    width,
    notScrolling,
  }) => (
    <Modal
      style={{ maxHeight: maxHeight, width: width ? width : {} }}
      className="around-sku-modal"
      open={open}
      onClose={onClose}
    >
      <Modal.Header
        id="store-modal-header-padding"
        className="vertical-horizontal-center "
      >
        {modalTitle}
        {!noCloseButton && (
          <Button
            id="primary-nofill-button"
            className={
              isCenterHeader
                ? "simple-modal-close-wrapper"
                : "force-right-margin p-8"
            }
            onClick={onClose}
            icon
          >
            <CloseIcon />
          </Button>
        )}
      </Modal.Header>
      <Modal.Content
        scrolling={!notScrolling}
        style={{ padding: "1.875em", maxHeight: "45rem" }}
      >
        {bodyContent}
        {noFooter === true ? null : (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1.5rem",
            }}
          >
            {!hasOneButton && (
              <FilledButton
                loading={loading}
                label={buttonText}
                buttonColor="red-outline"
                onClick={onConfirmPress}
                customClass="mr-line"
              />
            )}
            <FilledButton
              buttonColor="coleGray"
              onClick={onClose}
              label="닫기"
            />
          </div>
        )}
      </Modal.Content>
      {hasFixedFooter ? hasFixedFooter : null}
    </Modal>
  )
);

export const ConfirmModal = React.memo(
  ({
    open,
    onClose,
    title,
    bodyContent,
    onClick,
    buttonText,
    loading,
    customWidth,
    disabled,
  }) => (
    <Modal
      className={customWidth ? customWidth : "max-width-300px"}
      open={open}
      onClose={onClose}
    >
      <Modal.Header
        id="store-modal-header-padding"
        className="vertical-horizontal-center "
      >
        {title ? title : null}
        <Button
          id="primary-nofill-button"
          className="force-right-margin p-8"
          onClick={onClose}
          icon
        >
          <Icon className="black-color mr-0">
            <Image
              src={require("../img/graphic/close.png")}
              className="width-100"
            />
          </Icon>
        </Button>
      </Modal.Header>
      <Modal.Content scrolling>{bodyContent}</Modal.Content>
      <div className="force-bottom">
        <Button
          disabled={disabled}
          onClick={onClick}
          loading={loading}
          className="primary-nofill-button primary-nofill-button--bottom width-100 mr-0"
        >
          <div className="around-subheader1">{buttonText}</div>
        </Button>
      </div>
    </Modal>
  )
);

export const Subtitle = React.memo(({ title, subtitle, marginBottom }) => (
  <div className={marginBottom === false ? "" : "mb-sm"}>
    <div className="text-sub-title mb-xs">{title}</div>
    {subtitle ? <div className="h6">{subtitle}</div> : null}
  </div>
));

export const DropdownSelector = ({
  items,
  placeholder,
  helperText,
  onChange,
  value,
  isMultiple,
  error,
  marginTop,
  customWidth,
}) => (
  <div className={marginTop ? marginTop : "mt-md"}>
    <div className="flex-wrap">
      <Select
        displayEmpty
        multiple={isMultiple || false}
        className={customWidth ? customWidth : "min-width-300px"}
        value={value}
        error={error}
        onChange={onChange}
      >
        <MenuItem disabled value={""}>
          {placeholder}
        </MenuItem>
        {(items || []).map((item) => (
          <MenuItem value={item.id}>{item.text}</MenuItem>
        ))}
      </Select>
    </div>
    <FormHelperText>{helperText}</FormHelperText>
  </div>
);

export const DashboardPanel = ({
  id,
  leftTitle,
  leftDesc,
  rightBody,
  rightActions,
  isFirst,
}) => (
  <div
    id={id}
    className="dashboard-panel-wrapper"
    style={isFirst ? null : { borderTop: "1px solid #d1d4d8" }}
  >
    <div className="dashboard-panel-sub-wrapper">
      <div className="dashboard-panel-layout">
        <div className="dashboard-panel">
          <div className="dashboard-panel-margin dashboard-panel-left">
            <h4>{leftTitle}</h4>
            <div className="text-14 font-300">{leftDesc}</div>
          </div>
          <div className="dashboard-panel-margin dashboard-panel-right">
            <div className="panel-card">
              <div style={{ padding: "1.42rem" }}>{rightBody}</div>
            </div>
            <div className="mt-sm">{rightActions}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export const ReportNav = ({ onBackPress }) => (
  <div className="mb-md">
    <Button
      onClick={onBackPress}
      className="d-flex align-items-center pl-0"
      id="primary-nofill-button"
    >
      <ArrowBackIosIcon style={{ color: "#1a283e", fontSize: "2rem" }} />
      <div className="text-14 font-bold line-height-0">{"리포트 홈"}</div>
    </Button>
  </div>
);

export const TagComp = ({
  customStyle,
  onTagPress,
  index,
  tag,
  hasDelete,
  onTagUpdate,
}) => (
  <div
    onClick={onTagPress ? onTagPress : () => null}
    key={index}
    className="tag-comp"
    style={{
      backgroundColor: "#fff",
      display: "flex",
      alignItems: "center",
      ...customStyle,
    }}
  >
    <div className="h6 font-bold">{`#${tag.label}`}</div>
    {hasDelete ? (
      <IconButton className="ml-xs" onClick={onTagUpdate}>
        <CloseIcon style={{ fontSize: "1rem" }} />
      </IconButton>
    ) : null}
  </div>
);

export const MessageCampaignReport = React.memo(
  ({ view, onChangeView, campaign, keys, items }) => {
    let SCOPE = "marketing.campaignReport";
    const REPORT_OPTIONS = [
      {
        key: "gender",
        value: "gender",
        text: "성별",
      },
      {
        key: "age",
        value: "age",
        text: "연령별",
      },
    ];
    const TABLE_HEADERS = [
      { text: "empty", key: "" },
      { text: "messages", key: "" },
      { text: "visitNumber", key: "" },
      { text: "visitRate", key: "" },
      { text: "averageVisit", key: "" },
      { text: "orderAmount", key: "" },
    ];
    return (
      <Grid className="ml-mr-0 mt-mb-md">
        <Grid.Row columns={1} className="mb-xs">
          <Grid.Column className="d-flex align-items-baseline" width={3}>
            <Dropdown
              className="drop-down-shadow  pr-1 d-flex justify-space-between"
              icon="chevron down"
              options={REPORT_OPTIONS}
              value={view}
              selection
              onChange={onChangeView}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={4}>
          <Grid.Column className="d-i-grid">
            <text className="label">
              {I18n.get(`${SCOPE}.report.recipient`)}
            </text>
            <text className="text-sub-title blue-color">
              {campaign.recipientCount || (campaign.recipientList || []).length}
            </text>
          </Grid.Column>
          <Grid.Column className="d-i-grid">
            <text className="label">
              {I18n.get(`${SCOPE}.report.totalCost`)}
            </text>
            <text className="text-sub-title blue-color">
              {items.total.currency === "credit"
                ? `${campaign.report.total.cost || 0} 캔디`
                : numberToCurrency(
                    campaign.report.total.cost || 0,
                    items.total.currency
                  )}
            </text>
          </Grid.Column>
          <Grid.Column className="d-i-grid">
            <text className="label">
              {I18n.get(`${SCOPE}.report.visitRate`)}
            </text>
            <text className="text-sub-title blue-color">{`${items.total.revisitRate}%`}</text>
          </Grid.Column>
          <Grid.Column className="d-i-grid">
            <text className="label">
              {I18n.get(`${SCOPE}.report.averageVisit`)}
            </text>
            <text className="text-sub-title blue-color">
              {items.total.revisitDays + I18n.get(`${SCOPE}.symbol.day`)}
            </text>
          </Grid.Column>
        </Grid.Row>
        <Divider className="parent-border" />
        <Grid.Row columns={1}>
          <Grid.Column>
            <Table basic="very">
              <Table.Header>
                <Table.Row>
                  {TABLE_HEADERS.map((header, index) => (
                    <Table.HeaderCell className="text-overflow" key={index}>
                      {I18n.get(`${SCOPE}.report.table.${header.text}`)}
                    </Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>전체</Table.Cell>
                  <Table.Cell>{items.total.count}</Table.Cell>
                  {/* <Table.Cell>{items.total.cost}</Table.Cell> */}
                  <Table.Cell>{items.total.revisit}</Table.Cell>
                  <Table.Cell>{`${items.total.revisitRate}%`}</Table.Cell>
                  <Table.Cell>{`${items.total.revisitDays}일 `}</Table.Cell>
                  <Table.Cell>
                    {numberToCurrency(items.total.avgOrderAmount)}
                  </Table.Cell>
                </Table.Row>
                {keys.map((key, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{key.text}</Table.Cell>
                    <Table.Cell>{items[key.id].count}</Table.Cell>
                    {/* <Table.Cell>{items[key.id].cost}</Table.Cell> */}
                    <Table.Cell>{items[key.id].revisit}</Table.Cell>
                    <Table.Cell>{`${items[key.id].revisitRate}%`}</Table.Cell>
                    <Table.Cell>{`${items[key.id].revisitDays}일 `}</Table.Cell>
                    <Table.Cell>
                      {numberToCurrency(items[key.id].avgOrderAmount)}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
);

export const WarningModal = React.memo(
  ({
    open,
    forceText,
    title,
    description,
    onCancel,
    forceColor,
    forceLoading,
    onForce,
  }) => (
    <Modal open={open}>
      <MainModalHeaderWithCustomRight
        onCancel={onCancel}
        title={title}
        hasBottomDivider
      />
      <Modal.Content className="p-sm">
        <div>{description}</div>
        <Divider className="parent-border" />
        <div className="mt-small flex-end">
          <FilledButton
            buttonColor="coleGray"
            label="닫기"
            onClick={onCancel}
          />
          {onForce ? (
            <FilledButton
              buttonColor={forceColor || "red"}
              label={forceText || "나가기"}
              customClass="ml-line"
              loading={forceLoading}
              onClick={onForce}
            />
          ) : null}
        </div>
      </Modal.Content>
    </Modal>
  )
);

export const LabelInputComp = React.memo(
  ({
    label,
    required,
    rightComp,
    hasUpperBorder,
    expandHeight,
    customLabelWidthRate,
    noBottomBorder,
    customLabelStyle,
  }) => (
    <div
      className="d-flex align-items-center width-100"
      style={{
        height: expandHeight ? "auto" : "3.6rem",
        minHeight: "3.6rem",
        borderBottom: noBottomBorder ? "none" : "1px solid #d1d4d8",
        borderTop: hasUpperBorder ? "1px solid #d1d4d8" : "",
      }}
    >
      <div
        style={{
          flex: customLabelWidthRate ? customLabelWidthRate : 0.2,
          paddingLeft: "20px",
          borderRight: "1px solid #d1d4d8",
          height: "100%",
          verticalAlign: "center",
          display: "flex",
          alignItems: "center",
          ...customLabelStyle,
        }}
      >
        <div className={`text-14 font-bold ${required ? "require" : ""}`}>
          {label}
        </div>
      </div>
      <div
        style={{
          flex: customLabelWidthRate ? 1 - customLabelWidthRate : 0.8,
          height: "100%",
        }}
      >
        {rightComp}
      </div>
    </div>
  )
);

export const MainModalHeaderWithSave = React.memo(
  ({ onCancel, title, onSave, isDisabled, isLoading }) => (
    <Modal.Header className="d-flex p-0" style={{ minHeight: "4.3rem" }}>
      <div style={{ flex: 0.2 }}>
        <Button
          onClick={onCancel}
          icon
          basic
          className="tertiary height-100"
          style={{ padding: "11px 20px" }}
        >
          <CloseIcon style={{ color: "#1a283e", size: "1.5rem" }} />
        </Button>
      </div>
      <div
        style={{
          flex: 0.6,
          textAlign: "center",
        }}
        className="flex-center"
      >
        <div className="h5 bold">{title || ""}</div>
      </div>
      <div style={{ flex: 0.2, textAlign: "right" }}>
        {onSave ? (
          <Button
            loading={isLoading}
            disabled={isDisabled}
            onClick={onSave}
            className="blue-rectangle-button height-100"
          >
            <div className="white-color label font-bold">{"저장"}</div>
          </Button>
        ) : null}
      </div>
    </Modal.Header>
  )
);

export const MainModalHeaderWithSaveNoModal = React.memo(
  ({ onCancel, title, onSave, isDisabled, isLoading }) => (
    <div
      className="d-flex p-0"
      style={{ minHeight: "4.3rem", borderBottom: "1px solid #d1d4d8" }}
    >
      <div style={{ flex: 0.2 }}>
        <Button
          onClick={onCancel}
          icon
          basic
          className="tertiary height-100"
          style={{ padding: "11px 20px" }}
        >
          <CloseIcon style={{ color: "#1a283e", size: "1.5rem" }} />
        </Button>
      </div>
      <div
        style={{
          flex: 0.6,
          textAlign: "center",
        }}
        className="flex-center h5 bold"
      >
        {title || ""}
      </div>
      <div style={{ flex: 0.2, textAlign: "right" }}>
        {onSave ? (
          <Button
            loading={isLoading}
            disabled={isDisabled}
            onClick={onSave}
            className="blue-rectangle-button height-100"
          >
            <div className="white-color label font-bold">{"저장"}</div>
          </Button>
        ) : null}
      </div>
    </div>
  )
);

export const MainModalHeaderWithCustomRight = React.memo(
  ({ onCancel, title, rightBtn, isDisabled, isLoading, hasBottomDivider }) => (
    <Modal.Header
      className="d-flex p-0"
      style={{
        minHeight: "4.3rem",
        backgroundColor: "#fff",
        borderBottom: hasBottomDivider ? "1px solid #d1d4d8" : "none",
      }}
    >
      <div style={{ flex: 0.3 }}>
        {onCancel && (
          <Button
            onClick={onCancel}
            icon
            basic
            className="tertiary height-100"
            style={{ padding: "11px 20px" }}
          >
            <CloseIcon style={{ color: "#1a283e", size: "1.5rem" }} />
          </Button>
        )}
      </div>
      <div
        style={{
          flex: 0.4,
          textAlign: "center",
        }}
        className="flex-center h4 bold"
      >
        {title || ""}
      </div>
      <div
        style={{
          flex: 0.3,
          textAlign: "right",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        {rightBtn ? rightBtn : null}
      </div>
    </Modal.Header>
  )
);

export const InventoryAdjustModal = React.memo(
  ({
    open,
    onCancel,
    onAdjustTypeSelect,
    adjustType,
    onAdjustSave,
    quantity,
    onInventoryQtyChange,
    cost,
    onInventoryCostChange,
    onInventoryNoteChange,
    note,
    getFutureStock,
    isLoading,
    hasMultiLoc,
    selectedLocationId,
    getLocations,
    locationsById,
    onLocationSelect,
    hasExpiry,
    expiryDate,
    onExpiryDateChange,
  }) => {
    let hasMulti = hasMultiLoc();
    let _types = hasMulti
      ? STOCK_ACTION_TYPE_FOR_MULTI
      : STOCK_ACTION_TYPE.reduce((obj, t) => {
          obj[t.value] = t;
          return obj;
        }, {});
    let _locations = getLocations(Object.values(locationsById || {})).reduce(
      (obj, l) => {
        obj[l.value] = l;
        return obj;
      },
      {}
    );
    let isProviderLocation = selectedLocationId
      ? locationsById[selectedLocationId].provider
        ? true
        : false
      : false;

    return (
      <Modal open={open} className="p-0 width-50 min-width-450px">
        <MainModalHeaderWithSave
          title={"재고 조정"}
          onCancel={onCancel}
          onSave={onAdjustSave}
          isDisabled={
            (!quantity && quantity !== 0) ||
            (adjustType !== "force" && quantity === 0) ||
            (hasMulti && !selectedLocationId)
          }
          isLoading={isLoading}
        />
        <Modal.Content className="p-lg">
          <div>
            {isProviderLocation ? (
              <div className="mb-base">
                <div className="h6 blue-color">
                  타채널 재고조정의 경우, <b>연동된 상품만</b> 타채널로 조정된
                  내역이 반영됩니다.
                  <br />
                  연동되지 않은 제품은 타채널로 반영되지 않습니다.
                </div>
              </div>
            ) : null}
            <div className="flex-between">
              <div className="h6 blue-color">{`기존 재고: ${
                getFutureStock().now
              }`}</div>
              {adjustType === "damaged" ? (
                <div className="h6 red-color bold">
                  {"불용재고로 처리됩니다."}
                </div>
              ) : adjustType === "loss" ? (
                <div className="h6 red-color bold">{"재고가 감소됩니다."}</div>
              ) : adjustType === "force" ? (
                <div className="h6 red-color bold">
                  {"아래 입력된 수량으로 재고수량이 변경됩니다."}
                </div>
              ) : adjustType === "remove" ? (
                <div className="h6 red-color bold">
                  {"수량만 차감되며, 판매로 취급되지 않습니다."}
                </div>
              ) : null}
            </div>
            <ReactSelect
              name={"adjustType"}
              placeholder={"조정 타입 선택"}
              value={_types[adjustType]}
              options={STOCK_ACTION_TYPE}
              onChange={onAdjustTypeSelect}
              styles={{
                ...ReactSelectForLabelInput,
                control: (provided) => ({
                  ...provided,
                  borderWidth: 1,
                  borderColor: "#d1d4d8",
                  borderBottomWidth: hasMulti ? 0 : 1,
                  borderRadius: 0,
                  padding: "0 20px",
                  height: "55px",
                }),
              }}
            />
            {adjustType && hasMulti ? (
              <ReactSelect
                name={"locationId"}
                placeholder={"조정 지점 선택"}
                value={_locations[selectedLocationId]}
                options={getLocations(Object.values(locationsById))}
                onChange={onLocationSelect}
                styles={{
                  ...ReactSelectForLabelInput,
                  control: (provided) => ({
                    ...provided,
                    borderWidth: 1,
                    borderColor: "#d1d4d8",
                    borderRadius: 0,
                    borderBottomWidth: 1,
                    padding: "0 20px",
                    height: "55px",
                  }),
                }}
              />
            ) : null}
          </div>
          {adjustType ? (
            adjustType === "newStock" ? (
              <React.Fragment>
                <LabelInputComp
                  required
                  label="수량"
                  rightComp={
                    <CustomTextField
                      variant="standard"
                      style={{ width: "100%", height: "100%" }}
                      name="quantity"
                      type={"number"}
                      InputProps={{ disableUnderline: true }}
                      value={quantity?.toString()}
                      onChange={onInventoryQtyChange}
                    />
                  }
                />
                <LabelInputComp
                  label="단가"
                  rightComp={
                    <CustomTextField
                      variant="standard"
                      style={{ width: "100%", height: "100%" }}
                      name="cost"
                      placeholder="개당 단가"
                      type={"number"}
                      InputProps={{ disableUnderline: true }}
                      value={cost?.toString()}
                      onChange={onInventoryCostChange}
                    />
                  }
                />
                {hasExpiry && (
                  <LabelInputComp
                    label={"소비기한(유통기한)"}
                    rightComp={
                      <DatePicker
                        variant="standard"
                        InputProps={{ disableUnderline: true }}
                        style={{
                          height: "100%",
                          paddingLeft: "10px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                        format="YYYY-MM-DD"
                        type="date"
                        value={expiryDate}
                        onChange={onExpiryDateChange}
                      />
                    }
                  />
                )}
              </React.Fragment>
            ) : (
              <LabelInputComp
                required
                label="수량"
                rightComp={
                  <CustomTextField
                    variant="standard"
                    style={{ width: "100%", height: "100%" }}
                    name="quantity"
                    type={"number"}
                    InputProps={{ disableUnderline: true }}
                    value={quantity?.toString()}
                    onChange={onInventoryQtyChange}
                  />
                }
              />
            )
          ) : null}
          <LabelInputComp
            label="노트"
            rightComp={
              <CustomTextField
                variant="standard"
                style={{ width: "100%", height: "100%" }}
                name="note"
                placeholder="재고 노트 입력"
                InputProps={{ disableUnderline: true }}
                value={note || ""}
                onChange={onInventoryNoteChange}
              />
            }
          />
          <div className="flex-center mt-md">
            <div className="label font-light">{getFutureStock().now}</div>
            <ArrowForwardIos className="ml-mr-5" color="#337ef3" />
            <div
              className="label font-bold text-align-right"
              style={{ backgroundColor: "#f6d662" }}
            >
              &nbsp;{getFutureStock().future}&nbsp;
            </div>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
);

export const InventoryAttributesEditModal = React.memo(
  ({
    open,
    onCancel,
    isNewInventory,
    attributes,
    hasExpiry,
    inventoryExpiryDate,
    onAttributeValueChange,
    onAttributeOptionChange,
    onEditSave,
    selectedAttributes,
    errorMessage,
    onExpiryDateChange,
    isLoading,
  }) => {
    let selectedInvFilters = selectedAttributes?.map((attr) => attr.name) || [];
    return (
      <Modal open={open} className="p-0 width-50 min-width-450px">
        <MainModalHeaderWithSave
          title={isNewInventory ? "신규 속성 만들기" : "속성 수정하기"}
          onCancel={onCancel}
          onSave={onEditSave}
          isDisabled={
            (selectedAttributes.filter((attr) => !attr.value) || []).length > 0
          }
          isLoading={isLoading}
        />

        <Modal.Content className="p-lg">
          {errorMessage ? (
            <div className="text-14 text-align-center error-text mb-md">
              {errorMessage}
            </div>
          ) : null}
          <div className="d-flex-column align-items-center justify-c-f-start">
            <FormControl variant="outlined" className="width-100">
              <Select
                displayEmpty
                style={{ borderRadius: 0 }}
                multiple
                value={selectedInvFilters}
                renderValue={(selected) => "커스텀 필드 선택"}
              >
                <MenuItem value={null} disabled>
                  {"커스텀 필드"}
                </MenuItem>
                {attributes.map((f) => (
                  <MenuItem
                    key={f.name}
                    value={f.name}
                    onClick={() => onAttributeOptionChange(f.name)}
                  >
                    <CustomCheckbox
                      checked={selectedInvFilters.indexOf(f.name) > -1}
                    />
                    <ListItemText primary={f.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {selectedAttributes
              ? selectedAttributes.map((attr, index) => (
                  <LabelInputComp
                    key={index}
                    required
                    label={attr.name}
                    rightComp={
                      <CustomTextField
                        variant="standard"
                        style={{ width: "100%", height: "100%" }}
                        type={attr.type}
                        InputProps={{ disableUnderline: true }}
                        value={attr.value}
                        onChange={(e) => onAttributeValueChange(e, attr.name)}
                      />
                    }
                  />
                ))
              : null}
            {hasExpiry ? (
              <LabelInputComp
                required
                label={"유통기한"}
                rightComp={
                  <CustomTextField
                    variant="standard"
                    style={{ width: "100%", height: "100%" }}
                    InputProps={{ disableUnderline: true }}
                    type="date"
                    value={moment(inventoryExpiryDate).format("YYYY-MM-DD")}
                    onChange={onExpiryDateChange}
                  />
                }
              />
            ) : null}
          </div>
        </Modal.Content>
      </Modal>
    );
  }
);

export const GetInventoryTransactionReason = (reason, quantity) => {
  switch (reason) {
    case "sales":
      return "판매";
    case "newStock":
      return "입고";
    case "damaged":
      return "파손 재고";
    case "loss":
      return "손실 재고";
    case "restock":
      return "재입고";
    case "remove":
      return "임의차감";
    case "returns":
      return "반품입고";
    case "transfer":
      return "지점이동";
    case "force":
      return "임의조정";
    case "exchange":
      if (quantity >= 0) {
        return "교환반품";
      } else if (quantity < 0) {
        return "교환출고";
      } else {
        return "교환";
      }
    case null:
    case true:
    default:
      return "기타";
  }
};

export const InventoryHistoryModal = React.memo(
  ({ open, title, onCancel, items, rightBtn }) => {
    return (
      <Modal open={open} className="p-0 width-60  min-width-450px">
        <MainModalHeaderWithCustomRight
          title={title}
          onCancel={onCancel}
          rightBtn={rightBtn}
        />
        <Modal.Content scrolling className="p-lg">
          <MaterialTable
            localization={{
              body: {
                emptyDataSourceMessage: "재고 변동사항이 없습니다.",
              },
            }}
            icons={TableIcons}
            style={{ boxShadow: "none", width: "100%" }}
            columns={items.headers}
            data={items.data}
            options={{
              showTitle: false,
              search: false,
              sorting: false,
              pageSize: 10,
              draggable: false,
              toolbar: false,
            }}
          />
        </Modal.Content>
      </Modal>
    );
  }
);

export const CompleteAlertModal = React.memo(
  ({ open, onCancel, bodyContent, progress }) => (
    <Modal
      style={{ width: "28rem" }}
      open={open}
      className="p-0 min-width-300px"
    >
      <MainModalHeaderWithCustomRight onCancel={onCancel} />
      <Modal.Content scrolling className="p-lg">
        <div className="flex-column justify-content-center align-items-center text-align-center">
          <CheckCircleOutlineIcon style={{ fontSize: "10rem" }} />
          {bodyContent}
        </div>
        <CustomLinearProgress
          style={{ marginTop: "24px" }}
          variant="determinate"
          value={progress}
        />
      </Modal.Content>
    </Modal>
  )
);

export const CustomCell = React.memo(
  ({
    width,
    isHeader,
    value,
    isQty,
    isAmount,
    hasSort,
    getColor,
    customFunc,
    isChild,
    index,
    onHeaderClick,
    isSorting,
    isRate,
    hasLeftBorder,
    customStyle,
    customBackgroundColor,
    allowTwoLine,
    isBold,
    isCenter,
    hasRightBorder,
  }) => (
    <div
      onClick={isHeader && hasSort ? onHeaderClick : () => null}
      style={{
        padding: isHeader ? "10px 15px" : "15px",
        width: width,
        minWidth: "130px",
        borderBottom: "1px solid #d1d4d8",
        borderTop: isHeader ? "0.5px solid #d1d4d8" : "none",
        minHeight: isHeader ? "auto" : allowTwoLine ? "70px" : "53px",
        borderLeft: hasLeftBorder ? "1px solid #d1d4d8" : "none",
        borderRight: hasRightBorder ? "1px solid #d1d4d8" : "none",
        display: "flex",
        alignItems: "center",
        backgroundColor: customBackgroundColor
          ? customBackgroundColor
          : "inherit",
        ...customStyle,
      }}
      className={isHeader && hasSort ? "custom-table-header" : ""}
    >
      <div
        className={`text-14 hover-show pl-pr-0 ${
          allowTwoLine ? "limit-two-line line-breaker" : "limit-one-line"
        } ${isCenter ? "text-align-center" : ""}`}
        style={{
          fontWeight: isHeader || isBold ? 800 : 300,
          color: getColor
            ? getColor
            : isQty || isAmount
            ? value > 0
              ? "#337ef3"
              : value < 0
              ? "#e02020"
              : "#1a283e"
            : "#1a283e",
        }}
      >
        {isChild && index === 0 ? (
          <div>&#9495;&nbsp;{value}</div>
        ) : customFunc && value ? (
          customFunc(value)
        ) : isSorting ? (
          isSorting === Constants.SORT_DIRECTION.ASCEND ? (
            <div>{value}&nbsp;&#8593;</div>
          ) : (
            <div>{value}&nbsp;&#8595;</div>
          )
        ) : isRate ? (
          `${value}%`
        ) : (
          value
        )}
      </div>
    </div>
  )
);

export const CustomRow = React.memo(
  ({
    item,
    headers,
    onRowClick,
    getColor,
    allowTwoLine,
    customBackgroundColor,
  }) => (
    <div
      onClick={onRowClick ? () => onRowClick(item) : () => null}
      className={`inventory-list-row ${
        onRowClick ? "inventory-list-row-clickable" : ""
      }`}
    >
      {headers.map((header, index) => (
        <CustomCell
          key={index}
          index={index}
          width={header.width}
          value={item[header.key]}
          customFunc={header.func}
          isChild={item?.isChild}
          isQty={header.key === "quantity" || header.isQty}
          isAmount={header.isAmount}
          isRate={header.isRate}
          customBackgroundColor={customBackgroundColor}
          getColor={
            header.key === "status" ? getColor(item.statusOrigin) : null
          }
          allowTwoLine={allowTwoLine}
        />
      ))}
    </div>
  )
);

export const CustomHeader = React.memo(
  ({ headers, onHeaderSortPress, sortBy, customStyle = {} }) => (
    <div className="inventory-list-row" style={customStyle}>
      {headers.map((header, index) => (
        <CustomCell
          key={index}
          width={header.width}
          value={header.label}
          isHeader
          hasSort={header.hasSort}
          onHeaderClick={() => onHeaderSortPress(header.key)}
          isSorting={
            !_.isEmpty(sortBy) && sortBy.key === header.key
              ? sortBy.type
              : false
          }
        />
      ))}
    </div>
  )
);

export const CustomTable = React.memo(
  ({
    items,
    onRowClick,
    hasSearch,
    searchValue,
    onSearchChange,
    placeholder,
    headers,
    getColor,
    sortBy,
    onHeaderSortPress,
    topMarginStyle,
    allowTwoLine,
    isManualSearch = false,
    onSearchClear = null,
    onSearchClick = null,
    isSearched = false,
    onKeyDownForSearch,
  }) => {
    return (
      <div
        className="mt-medium"
        style={{
          overflowX: "auto",
          ...topMarginStyle,
        }}
      >
        {hasSearch ? (
          <div className="custom-table-search-wrapper">
            <FontAwesomeIcon icon={faMagnifyingGlass} />
            <input
              className="custom-table-search-input"
              value={searchValue}
              placeholder={placeholder}
              onChange={onSearchChange}
              onKeyDown={onKeyDownForSearch && onKeyDownForSearch}
            />
            {isManualSearch ? (
              <div className="d-flex">
                <NofilledButton
                  label="검색하기"
                  buttonColor="blue"
                  disabled={!searchValue}
                  onClick={onSearchClick}
                  customStyle={{ width: "60px" }}
                />
                {isSearched ? (
                  <NofilledButton
                    label="초기화"
                    buttonColor="blue"
                    onClick={onSearchClear}
                    customStyle={{ width: "60px" }}
                  />
                ) : null}
              </div>
            ) : null}
          </div>
        ) : null}
        <CustomHeader
          headers={headers}
          onHeaderSortPress={onHeaderSortPress}
          sortBy={sortBy}
        />
        {items.length > 0 ? (
          (items || []).map((item, index) => (
            <CustomRow
              headers={headers}
              onRowClick={onRowClick}
              key={index}
              item={item}
              getColor={getColor}
              customBackgroundColor={item.background}
              allowTwoLine={allowTwoLine}
            />
          ))
        ) : (
          <div className="custom-table-empty">
            {"조회된 데이터가 없습니다 :("}
          </div>
        )}
      </div>
    );
  }
);

export const RetailCustomHeader = React.memo(
  ({ headers, isOrder, onHeaderSortPress, sortBy, customHeaderStyle = {} }) => (
    <div className="inventory-list-row">
      {headers.map((header, index) => (
        <RetailCustomCell
          key={index}
          width={header.width}
          value={header.label}
          isHeader
          hasSort={header.hasSort}
          onHeaderClick={() => onHeaderSortPress(header.key)}
          isSorting={
            !_.isEmpty(sortBy) && sortBy.key === header.key
              ? sortBy.type
              : false
          }
          customStyle={customHeaderStyle}
          isOrder={isOrder}
        />
      ))}
    </div>
  )
);

export const RetailCustomCell = React.memo(
  ({
    width,
    value,
    isQty,
    getColor,
    customFunc,
    isChild,
    isSorting,
    index,
    hasSort,
    onHeaderClick,
    isHeader,
    hasLeftBorder,
    customStyle,
    isOrder,
  }) => (
    <div
      onClick={isHeader && hasSort ? onHeaderClick : () => null}
      style={{
        padding: isHeader ? "10px 15px" : "15px",
        width: width,
        minWidth: isOrder ? (width ? width : "150px") : "150px",
        borderBottom: "1px solid #d1d4d8",
        borderTop: isHeader ? "0.5px solid #d1d4d8" : "none",
        minHeight: isHeader ? "auto" : "70px",
        borderLeft: hasLeftBorder && "1px solid #d1d4d8",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        ...customStyle,
      }}
      className={isHeader && hasSort ? "custom-table-header" : ""}
    >
      <div
        className={"text-14 limit-two-line line-breaker"}
        style={{
          fontWeight: isHeader ? 800 : 300,
          color: getColor
            ? getColor
            : isQty
            ? value > 0
              ? "#337ef3"
              : "#e02020"
            : "#1a283e",
        }}
      >
        {customFunc && value ? (
          customFunc(value)
        ) : isSorting ? (
          isSorting === Constants.SORT_DIRECTION.ASCEND ? (
            <div>{value}&nbsp;&#8593;</div>
          ) : (
            <div>{value}&nbsp;&#8595;</div>
          )
        ) : (
          value
        )}
      </div>
    </div>
  )
);

export const ReceiptModal = ({
  open,
  onClose,
  receiptEmail,
  onEmailChange,
  onSend,
  isSendingEmail,
}) => (
  <Modal open={open} className="max-width-550px">
    <MainModalHeaderWithCustomRight onCancel={onClose} title="영수증 발송" />
    <div className="p-md mt-mb-sm height-auto text-align-center">
      <InputWithFilledButton
        value={receiptEmail}
        onInputChange={onEmailChange}
        buttonTitle="보내기"
        buttonColor="blue"
        type="email"
        onButtonPress={onSend}
        loading={isSendingEmail}
      />
    </div>
  </Modal>
);

export const DropdownEle = React.memo(
  ({
    isSku,
    menuName,
    inputMinWidth,
    customStyle,
    label,
    value,
    data,
    onChange,
  }) => (
    <FormControl
      className={customStyle}
      style={{ minWidth: inputMinWidth }}
      variant="outlined"
    >
      <InputLabel
        style={{ color: "#1a283e", fontFamily: "Pretendard-Regular" }}
        htmlFor="location-dropdown"
      >
        {label}
      </InputLabel>
      <Select name={menuName} label={label} value={value} onChange={onChange}>
        {(data || []).map((item, index) => (
          <MenuItem
            disabled={item.disabled}
            style={
              index === 0
                ? {
                    borderTop: "none",
                    padding: "15px 10px",
                  }
                : { borderTop: "1px solid #d1d4d8", padding: "15px 10px" }
            }
            key={index}
            value={item.id}
          >
            <div className="text-14">{item.name || ""}</div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
);

export const PeriodChangeButton = React.memo(
  ({ type, selectedPeriod, label, onClick }) => (
    <Button
      className={
        type !== selectedPeriod
          ? "primary-outline-button mr-sm mb-sm"
          : "primary-button mr-sm"
      }
      color="#1a283e"
      onClick={() => onClick(type)}
    >
      {label}
    </Button>
  )
);

export const ProductListWithRadio = React.memo(
  ({ products, search, onSearchChange, onProdSelect, isChecked }) => (
    <React.Fragment>
      <div className=" mt-mb-xs">
        <Input
          placeholder={"제품 이름 검색"}
          className="width-100"
          iconPosition="left"
          name="category"
          value={search}
          onChange={onSearchChange}
        >
          <Icon name="search" />
          <input />
        </Input>
      </div>
      <div>
        <List devided="true" selection verticalAlign="middle">
          {(products || []).map((product, index) => {
            return (
              <List.Item
                key={index}
                className="ui-list-grid"
                name={product.name}
                onClick={() => onProdSelect(product.itemId)}
              >
                <List.Content floated="right">
                  <Checkbox
                    checked={isChecked === (product.id || product.itemId)}
                  />
                </List.Content>
                <List.Content className="d-i-block">
                  {product.name}
                </List.Content>
                <List.Content
                  floated="right"
                  className="d-i-block opacity-54 p-right-100"
                >
                  {numberToCurrency(product.skus[0].price)}
                </List.Content>
              </List.Item>
            );
          })}
        </List>
      </div>
    </React.Fragment>
  )
);

export const ItemListWithRadio = React.memo(
  ({
    index,
    type,
    itemId,
    onItemPress,
    title,
    subtitle,
    description,
    selectedIds,
    isDisabled,
  }) => (
    <div
      type="button"
      style={{
        cursor: "pointer",
        borderTop: index === 0 ? "1px solid #d1d4d8" : 0,
        borderBottom: "1px solid #d1d4d8",
        opacity: isDisabled ? 0.38 : 1,
      }}
      onClick={isDisabled ? () => null : () => onItemPress(itemId, type)}
      className="flex-between pl-pr-sm pt-pb-xs"
    >
      <div style={{ flex: 0.7 }}>
        <div className="h5 bold">{title}</div>
        <div className="mt-tiny">
          {subtitle ? <div className="h6">{subtitle}</div> : null}
          {description ? <div className="h6">{description}</div> : null}
        </div>
      </div>
      <div style={{ flex: 0.3, textAlign: "right" }}>
        <FontAwesomeIcon
          icon={faCircleCheck}
          className={
            (selectedIds || []).includes(itemId)
              ? "blue-color h4"
              : "light-gray-color h4"
          }
        />
      </div>
    </div>
  )
);

export const RANGES = [
  {
    label: "today",
    value: [dateFns.startOfDay(new Date()), dateFns.endOfDay(new Date())],
  },
  {
    label: "yesterday",
    value: [
      dateFns.startOfDay(dateFns.addDays(new Date(), -1)),
      dateFns.endOfDay(dateFns.addDays(new Date(), -1)),
    ],
  },
  {
    label: "last7Days",
    value: [
      dateFns.startOfDay(dateFns.subDays(new Date(), 6)),
      dateFns.endOfDay(new Date()),
    ],
  },
];

export const LOCALE = {
  sunday: "일",
  monday: "월",
  tuesday: "화",
  wednesday: "수",
  thursday: "목",
  friday: "금",
  saturday: "토",
  ok: "확인",
  today: "오늘",
  yesterday: "어제",
  last7Days: "지난 7일",
  last1Month: "지난 1달",
};

export const PeriodOptions = [
  {
    key: "today",
    text: "오늘",
  },
  {
    key: "yesterday",
    text: "어제",
  },
  {
    key: "thisWeek",
    text: "이번 주",
  },
  {
    key: "lastWeek",
    text: "지난 주",
  },
  {
    key: "thisMonth",
    text: "이번 달",
  },
  {
    key: "lastMonth",
    text: "지난 달",
  },
];

export const Locale = {
  sunday: "일",
  monday: "월",
  tuesday: "화",
  wednesday: "수",
  thursday: "목",
  friday: "금",
  saturday: "토",
  ok: "확인",
  today: "오늘",
  yesterday: "어제",
  last7Days: "지난 7일",
  last1Month: "지난 1달",
};

export const periodDates = [
  { key: "yesterday", value: "어제" },
  { key: "thisWeek", value: "이번 주" },
  { key: "lastWeek", value: "지난 주" },
  { key: "thisMonth", value: "이번 달" },
  { key: "lastMonth", value: "지난 달" },
];

export const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#1a283e",
      light: "#c6c9cf",
      dark: "#1a283e",
    },
    secondary: {
      main: "#1a283e",
    },
    red: {
      main: "#e02020",
    },
  },
});

export const BasicTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#1a283e",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#1a283e",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#1a283e",
      },
    },
    "& .MuiInputBase-root": {
      "&.MuiInput-root": {
        "&.MuiInputBase-root": {
          padding: "0 0",
          height: "100%",
        },
      },
    },
  },
})(TextField);

export const CustomTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#1a283e",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#1a283e",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#1a283e",
      },
    },
    "& .MuiInputBase-root": {
      "&.MuiInput-root": {
        "&.MuiInputBase-root": {
          padding: "0 10px",
          height: "100%",
        },
      },
    },
    "& .MuiFilledInput-root": {
      backgroundColor: "#efeff4",
      borderRadius: 0,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#1a283e",
    },
    "& .MuiFilledOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#1a283e",
      },
    },
  },
})(TextField);

export const CustomCheckbox = withStyles({
  root: {
    color: "#1a283e",
    "&$checked": {
      color: "#1a283e",
    },
    "&.MuiCheckbox-colorSecondary": {
      "&.Mui-checked": {
        color: "#1a283e",
      },
    },
    padding: "20px 20px",
  },
})(Checkbox);

export const CustomRadio = withStyles({
  root: {
    color: "#1a283e",
    "&$checked": {
      color: "#1a283e",
    },
  },
  checked: {},
})(Radio);

export const CustomLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#efeff4",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#337ef3",
  },
}))(LinearProgress);

export const CustomSpinner = withStyles({
  root: {
    color: "#1a283e",
    animationDuration: "500ms",
    alignSelf: "center",
    justifySelf: "center",
  },
})(CircularProgress);

export const CustomSwitch = withStyles({
  switchBase: {
    color: "#838b96",
    "&$checked": {
      color: "#337ef3",
    },
    "&$checked + $track": {
      backgroundColor: "#b1cefa",
    },
  },
  checked: {},
  track: {
    backgroundColor: "#d1d4d8",
  },
})(Switch);

export const MaterialTableLocalization = {
  toolbar: { searchPlaceholder: "검색" },
  pagination: {
    labelRowsSelect: "행",
    previousAriaLabel: "이전",
    nextAriaLabel: "다음",
    nextTooltip: "다음",
    previousTooltip: "이전",
  },
};

export const CustomMaterialTable = ({
  data,
  headers,
  toolbarComp,
  emptyMessage,
  showFirstLastPageButtons,
  parentChildData,
  onRowClick,
  pageSize,
  optionRowStyle,
  pageSizeOptions,
  addtionalOption,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  cellEditable,
}) => (
  <MuiThemeProvider theme={customTheme}>
    <MaterialTable
      onRowClick={onRowClick ? (event, rowData) => onRowClick(rowData) : null}
      localization={{
        body: {
          emptyDataSourceMessage: emptyMessage,
        },
        ...MaterialTableLocalization,
      }}
      components={toolbarComp}
      icons={TableIcons}
      parentChildData={parentChildData}
      style={{
        boxShadow: "none",
        zIndex: 0,
        paddingLeft: paddingLeft && paddingLeft,
        paddingRight: paddingRight && paddingRight,
        paddingTop: paddingTop && paddingTop,
        paddingBottom: paddingBottom && paddingBottom,
      }}
      columns={headers}
      data={data}
      cellEditable={cellEditable}
      options={{
        showTitle: false,
        pageSize: pageSize,
        showFirstLastPageButtons: showFirstLastPageButtons,
        pageSizeOptions: pageSizeOptions ? pageSizeOptions : [3, 10, 20],
        draggable: false,
        paginationType: "stepped",
        ...addtionalOption,
      }}
    />
  </MuiThemeProvider>
);

export const CustomOption = {
  option: (base, { data, isFocused, isSelected }) => {
    const color = chroma(data.value);
    return {
      ...base,
      paddingTop: "15px",
      paddingBottom: "15px",
      backgroundColor: isSelected
        ? color.alpha(0.3).css()
        : isFocused
        ? color.alpha(0.3).css()
        : color.alpha(1).css(),
    };
  },
  singleValue: (base, { data }) => {
    if (!_.isEmpty(data)) {
      const color = chroma(data.value);
      return {
        ...base,
        backgroundColor: data.value,
        padding: "5px 10px",
        color: "#fff",
      };
    }
  },
};

export const CustomCreatableSelectStyle = {
  container: (base) => ({
    ...base,
    width: "100%",
  }),
  control: (base) => ({
    ...base,
    padding: "0.7rem 1rem",
    backgroundColor: "#fff",
    alignContent: "center",
    color: "#1a283e",
    borderRadius: 0,
    fontWeight: 300,
    border: "none",
    height: "48px",
  }),
  valueContainer: (base, state) => ({
    ...base,
    color: "#1a283e",
    paddingLeft: 0,
    paddingRight: 0,
    zIndex: state.isFocused ? 10 : 2,
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 20,
  }),
};

export const ReactSelectCustomStyle = {
  container: (base) => ({
    ...base,
    marginRight: "15px",
    minHeight: "1.1876em",
    minWidth: "11rem",
    maxWidth: "28.5rem",
  }),
  input: (base) => ({
    ...base,
    minWidth: "11rem",
    color: "#1a283e",
  }),
  singleValue: (base) => ({
    ...base,
    color: "#1a283e",
  }),
  control: (provided, state) => ({
    ...provided,
    padding: "0.7rem 1rem",
    backgroundColor: "#fff",
    alignContent: "center",
    borderStyle: "solid",
    borderWidth: "1px",
    color: state.isFocused ? "#1a283e" : "rgba(0,0,0,0.23)",
    borderColor: "rgba(0,0,0,0.23)",
    borderRadius: "4px",
    fontWeight: 300,
    boxShadow: "none",
  }),
  valueContainer: (base) => ({
    ...base,
    color: "#1a283e",
    paddingLeft: 0,
    paddingRight: 0,
    display: "flex",
    flexWrap: "wrap",
    maxWidth: "26rem",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    display: "block",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#d1d4d8" : "#fff",
    color: state.isSelected ? "#337ef3" : "#1a283e",
    padding: "15px 10px",
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 1000,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "#1a283e",
    ":hover": {
      backgroundColor: "#1a283e",
      color: "white",
    },
  }),
};

export const ReactSelectForLabelInput = {
  container: (base) => ({
    ...base,
    height: "100%",
    width: "100%",
  }),
  input: (base) => ({
    ...base,
    minWidth: "100%",
    color: "#1a283e",
    marginLeft: 0,
    marginRight: 0,
  }),
  placeholder: (base) => ({
    ...base,
    marginLeft: 0,
    marginRight: 0,
  }),
  singleValue: (base) => ({
    ...base,
    color: "#1a283e",
    marginLeft: 0,
    marginRight: 0,
  }),
  control: (provided, state) => ({
    ...provided,
    padding: "0 20px",
    height: "100%",
    backgroundColor: "#fff",
    alignContent: "center",
    borderWidth: 0,
    color: state.isFocused ? "#1a283e" : "rgba(0,0,0,0.23)",
    borderRadius: 0,
    fontWeight: 300,
    boxShadow: "none",
  }),
  valueContainer: (base) => ({
    ...base,
    color: "#1a283e",
    paddingLeft: 0,
    paddingRight: 0,
    display: "flex",
    flexWrap: "wrap",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    display: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#89b4f8" : "#fff",
    color: state.isSelected ? "#337ef3" : "#1a283e",
    padding: "15px 10px",
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 1000,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "#1a283e",
    ":hover": {
      backgroundColor: "#1a283e",
      color: "white",
    },
  }),
};

export const CustomFieldModalBody = ({
  onSavePress,
  onOptionChange,
  options,
  selectedAttribute,
  createdAttribute,
  typeOptions,
  temp,
  onTypeChange,
  onValueChange,
  errorMessage,
  noValue,
  notEditable,
}) => {
  let _types = typeOptions.reduce((obj, t) => {
    obj[t.value] = t;
    return obj;
  }, {});
  return (
    <React.Fragment>
      <div className="mt-md">
        {errorMessage ? (
          <div className="mb-md">
            <div className="text-14 font-bold error-text error-text">
              {errorMessage}
            </div>
          </div>
        ) : null}
        <div>
          <div
            style={{
              borderTop: "1px solid #1a283e",
              borderBottom: "1px solid #d1d4d8",
            }}
            className="d-flex align-items-center width-100"
          >
            <div className="horizontal-table-cell-title-wrapper">
              <div className="horizontal-table-cell-title">{"필드 선택"}</div>
            </div>
            <CreatableSelect
              name="attribute"
              placeholder={"필드를 신규 생성 또는 선택해 주세요."}
              options={options}
              onChange={onOptionChange}
              value={createdAttribute || selectedAttribute}
              formatCreateLabel={(value) => `신규 생성하기: ${value}`}
              components={{
                DropdownIndicator: (props) => (
                  <div style={{ display: "none" }} />
                ),
                IndicatorSeparator: (props) => (
                  <div style={{ display: "none" }} />
                ),
              }}
              styles={CustomCreatableSelectStyle}
              isClearable
            />
          </div>
          {createdAttribute || selectedAttribute ? (
            <div
              style={{ borderBottom: "1px solid #d1d4d8" }}
              className="d-flex align-items-center width-100"
            >
              <div className="horizontal-table-cell-title-wrapper">
                <div className="horizontal-table-cell-title">{"필드 타입"}</div>
              </div>
              <ReactSelect
                name="type"
                isDisabled={notEditable}
                placeholder={"필드 타입을 선택해 주세요."}
                options={typeOptions}
                value={_types[temp.type]}
                onChange={onTypeChange}
                styles={CustomCreatableSelectStyle}
                isClearable
                isSearchable={false}
              />
            </div>
          ) : null}
          {!noValue && temp.type ? (
            <div
              style={{ borderBottom: "1px solid #d1d4d8" }}
              className="d-flex align-items-center width-100"
            >
              <div className="horizontal-table-cell-title-wrapper">
                <div className="horizontal-table-cell-title">{"필드 값"}</div>
              </div>
              <input
                onChange={onValueChange}
                placeholder={temp.type === "number" ? "(숫자)" : "(텍스트)"}
                className="horizontal-table-cell-value"
                type={temp.type === "number" ? "number" : "text"}
                value={temp.value}
              />
            </div>
          ) : null}
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "2.5rem",
            justifyContent: "flex-end",
          }}
        >
          <Button
            disabled={
              noValue
                ? // ? !temp.type || !temp.id
                  // : !temp.type || !temp.id || !temp.value
                  !temp.type
                : !temp.type || !temp.value
            }
            onClick={onSavePress}
            className="primary-button"
            style={{ width: "10.7rem" }}
          >
            {"저장하기"}
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export const FilledButton = React.memo(
  ({
    label,
    onClick,
    loading,
    disabled,
    buttonColor,
    customClass,
    customStyle,
    textComp,
    customTextClass,
  }) => (
    <button
      aria-disabled={disabled || loading}
      onClick={onClick}
      className={`${buttonColor}-element-button ${customClass || ""}`}
      style={customStyle}
    >
      {textComp ? (
        loading ? (
          <div className="white-loader" />
        ) : (
          textComp
        )
      ) : (
        <div className={`h6 ${customTextClass}`}>
          {loading ? <div className="primary-loader" /> : label}
        </div>
      )}
    </button>
  )
);

export const NofilledButton = React.memo(
  ({
    loading,
    label,
    buttonColor,
    disabled,
    customClass,
    customStyle,
    onClick,
    textComp,
  }) => (
    <button
      aria-disabled={disabled || loading}
      onClick={onClick}
      className={`${buttonColor}-nofill-element-button p-0 ${customClass}`}
      style={customStyle}
    >
      {textComp ? (
        loading ? (
          <div className="primary-loader" />
        ) : (
          textComp
        )
      ) : (
        <div className="h6">
          {loading ? <div className="primary-loader" /> : label}
        </div>
      )}
    </button>
  )
);

export const BasicInput = React.memo(
  ({
    value,
    name,
    customStyle,
    type,
    onChange,
    placeholder,
    onKeyDown,
    disabled,
    customClass,
  }) => (
    <input
      className={`g-input ${customClass ? customClass : ""}`}
      style={customStyle}
      value={value}
      type={type}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      onKeyDown={onKeyDown}
      disabled={disabled}
    />
  )
);

export const BasicInputWithLabel = React.memo(
  ({
    value,
    label,
    name,
    customStyle,
    type,
    onChange,
    placeholder,
    disabled,
  }) => (
    <div className="input-wrapper">
      <div className="input-label h7 bold">{label}</div>
      <input
        className="g-input"
        style={customStyle}
        value={value}
        type={type}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  )
);

export const InputWithFilledButton = React.memo(
  ({
    value,
    type,
    onInputChange,
    onButtonPress,
    placeholder,
    loading,
    isDisabled,
    buttonTitle,
    buttonColor,
    onKeyPress,
  }) => (
    <div className="d-flex align-items-center">
      <input
        className="input-with-button"
        style={{ width: "80%" }}
        value={value}
        type={type}
        placeholder={placeholder}
        onChange={onInputChange}
        onKeyPress={onKeyPress}
      />
      <FilledButton
        label={buttonTitle}
        disabled={isDisabled}
        onClick={onButtonPress}
        buttonColor={buttonColor}
        loading={loading}
        customStyle={{ borderRadius: 0, width: "20%", height: "60px" }}
      />
    </div>
  )
);

export const ReceiptPreview = React.memo(
  ({
    storeName,
    storeBusinessNumber,
    storeRepresentative,
    storePhone,
    storeAddress,
  }) => (
    <Grid.Column className="onboarding-receipt-border p-base">
      <Grid.Row className="text-align-left mb-base">
        <Grid.Column align="left" className="d-i-grid" width={10}>
          <div className="h4 bold ">{"결제영수증"}</div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="d-flex justify-space-between">
        <Grid.Column align="left" className="d-i-grid">
          <div className="h6">{storeName || "매장이름"}</div>
          <div className="h6">{storeRepresentative || "대표자"}</div>
          <div className="h6">{storeAddress || "매장주소"}</div>
        </Grid.Column>
        <Grid.Column align="right" className="d-i-grid">
          <div className="h6">{storeBusinessNumber || "사업자등록번호"}</div>
          <div className="h6">{storePhone || "매장 전화번호"}</div>
          <div className="h6">
            <div>&#8203;</div>
          </div>
        </Grid.Column>
      </Grid.Row>
      <Divider className="onboarding-receipt-border" />
      <Grid.Row className="d-flex justify-space-between">
        <Grid.Column align="left" className="d-i-grid" width={10}>
          <div className="h6">주문일시: 2022-5-31 14:15:21</div>
        </Grid.Column>
        <Grid.Column align="right" className="d-i-grid" width={6}></Grid.Column>
      </Grid.Row>
      <Divider className="onboarding-receipt-border" />

      <Grid.Row className="width-100">
        <Grid.Row>
          <Grid columns={3} className="m-0 pb-base">
            <Grid.Column width={8} className="text-align-left p-0">
              <div className="h6">아메리카노</div>
            </Grid.Column>
            <Grid.Column width={4} className="p-0">
              <div className="h6">x2</div>
            </Grid.Column>
            <Grid.Column width={4} className="text-align-right p-0">
              <div className="h6">7,000</div>
            </Grid.Column>
          </Grid>
          <Grid columns={3} className="m-0">
            <Grid.Column width={8} className="text-align-left p-0">
              <div className="h6">라떼</div>
            </Grid.Column>
            <Grid.Column width={4} className="p-0">
              <div className="h6">x2</div>
            </Grid.Column>
            <Grid.Column width={4} className="text-align-right p-0">
              <div className="h6">9,000</div>
            </Grid.Column>
          </Grid>
          <Grid columns={3} className="m-0 pb-base">
            <Grid.Column width={8} className=" p-0">
              &emsp;&emsp;
              <div className="h6 regular">샷 추가</div>
            </Grid.Column>
            <Grid.Column width={4} className="p-0">
              <div className="h6 regular">x2</div>
            </Grid.Column>
            <Grid.Column width={4} className="text-align-right p-0">
              <div className="h6 regular">1,000</div>
            </Grid.Column>
          </Grid>
        </Grid.Row>
        <Grid.Row className="mt-base p-0">
          <Grid columns={2} className="m-0">
            <Grid.Column className="text-align-left p-0">
              <div className="h6">할인</div>
            </Grid.Column>
            <Grid.Column className="text-align-right p-0">
              <div className="h6">1,000</div>
            </Grid.Column>
          </Grid>
          <Grid columns={2} className="m-0">
            <Grid.Column className="text-align-left p-0">
              <div className="h6">소계</div>
            </Grid.Column>
            <Grid.Column className="text-align-right p-0">
              <div className="h6">15,000</div>
            </Grid.Column>
          </Grid>
          <Grid columns={2} className="m-0">
            <Grid.Column className="text-align-left p-0">
              <div className="h6">부가세</div>
            </Grid.Column>
            <Grid.Column className="text-align-right p-0">
              <div className="h6">1,363</div>
            </Grid.Column>
          </Grid>
          <Grid columns={2} className="m-0">
            <Grid.Column className="text-align-left p-0">
              <div className="h6">총계(부가세 포함)</div>
            </Grid.Column>
            <Grid.Column className="text-align-right p-0">
              <div className="h6">15,000</div>
            </Grid.Column>
          </Grid>
        </Grid.Row>
      </Grid.Row>
      <Divider className="onboarding-receipt-border" />
      <Grid.Row className="d-flex justify-space-between">
        <Grid.Column align="left" className="d-i-grid" width={10}>
          <div className="h6">신한카드</div>
          <div className="h6">4518 21** **** 1234</div>
          <div className="h6">거래일시</div>
          <div className="h6">승인번호</div>
        </Grid.Column>
        <Grid.Column align="right" className="d-i-grid" width={6}>
          <div className="h6">[고객용]</div>
          <div className="h6">할부: 일시불</div>
          <div className="h6">2022-5-31 14:15:21</div>
          <div className="h6">2455 2132</div>
        </Grid.Column>
      </Grid.Row>
    </Grid.Column>
  )
);

export const PhoneAlertModal = ({ open, onClose }) => {
  const platform = getPhoneDevicePlatform();
  return (
    <Modal open={open} onClose={onClose} className="phoneAlertModal_container">
      <div style={{ textAlign: "right" }}>
        <Button id="primary-nofill-button" onClick={onClose} icon>
          <Icon className="black-color mr-0">
            <Image
              src={require("../img/graphic/close.png")}
              className="width-100"
            />
          </Icon>
        </Button>
      </div>
      <Modal.Content scrolling style={{ borderRadius: "40px" }}>
        <div className="phoneAlertModal_wrapper">
          <Image
            width={"45px"}
            height={"45px"}
            src={require("../img/brand/app_logo.png")}
          />
          <div className="phoneAlertModal_title">{"Growing Sales"}</div>
        </div>
        <div className="phoneAlertModal_desc">
          <span style={{ fontWeight: "bold" }}>{"그로잉세일즈 앱"}</span>
          {"을 이용하여 \n회원가입을 진행해 주세요."}
        </div>
        <div className="flex-center">
          <button
            className="phoneAlertModal_button"
            onClick={() => connectAppStore(platform)}
          >
            {"앱 다운로드 하기"}
          </button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export const CustomSelect = React.memo(
  ({
    value,
    required,
    name,
    customStyle,
    options,
    label,
    hasPermission,
    onChange,
    emptyValueSelectable = true,
    defaultValue = null,
  }) => (
    <FormControl style={customStyle} required={required} variant="outlined">
      <InputLabel id="permission-type">{label}</InputLabel>
      <Select
        disabled={!hasPermission}
        label={label}
        name={name}
        labelId="permission-type"
        value={value}
        onChange={onChange}
        defaultValue={defaultValue}
      >
        {emptyValueSelectable ? (
          <MenuItem value={null}>{"선택안함"}</MenuItem>
        ) : null}
        {options.map((o, idx) => (
          <MenuItem key={idx} value={o.value}>
            {o.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
);

export const LimitExcessModal = React.memo(
  ({ open, onClose, title, text, customWidthClass, customTitleClass }) => {
    return (
      <Modal
        className={customWidthClass || "max-width-300px"}
        open={open}
        onClose={onClose}
      >
        <MainModalHeaderWithSaveNoModal title={"제한알림"} onCancel={onClose} />
        <Modal.Content scrolling>
          {title ? (
            <div className={`h6 ${customTitleClass || "mb-xs bold"}`}>
              {title}
            </div>
          ) : null}
          {text ? <div>{text}</div> : null}
          <div className="flex-center mt-line">
            <FilledButton
              buttonColor="primary-outline"
              onClick={onClose}
              customStyle={{ width: "100%" }}
              label="확인"
            />
          </div>
        </Modal.Content>
      </Modal>
    );
  }
);

export const NotContractedAlertModal = React.memo(
  ({ open, onPressInquiry, onPressCancel }) => (
    <Modal open={open} className="contract-alert-modal-wrapper">
      <Modal.Header align="center">{"미등록 가맹점 알림"}</Modal.Header>
      <Modal.Content scrolling>
        <div className="h6">
          {"미등록 가맹점으로 기능 접근에 제한됩니다."}
          <br />
          {
            "해당 기능을 사용하기 위해서는 그로잉세일즈 가맹점 등록을 진행해 주세요."
          }
        </div>
      </Modal.Content>
      <div className="bottom">
        <FilledButton
          onClick={onPressInquiry}
          buttonColor="blue"
          label="등록가맹 문의"
          customStyle={{ width: "auto" }}
        />
        <FilledButton
          onClick={onPressCancel}
          buttonColor="gray"
          label="닫기"
          customStyle={{ width: "auto" }}
          customClass="ml-line"
        />
      </div>
    </Modal>
  )
);

export const RadioWithText = ({
  isChecked,
  text,
  onClick,
  value,
  customStyle,
}) => {
  return (
    <div
      className="flex-center radio-with-text-wrapper"
      style={customStyle ? customStyle : null}
      onClick={() => {
        onClick(value);
      }}
    >
      {isChecked ? (
        <Icon
          name="check circle"
          type="material-community"
          color={"blue"}
          size={"large"}
        />
      ) : (
        <Icon
          name="circle outline"
          type="material-community"
          style={{ color: "#838b96" }}
          size={"large"}
        />
      )}
      <div
        className={`ml-5 caption ${
          isChecked ? "bold blue-color" : "lightPrimary-color"
        }`}
      >
        {text}
      </div>
    </div>
  );
};

export const TriangleUpAndDownButton = React.memo(
  ({ onClick, customButtonStyle, disabled, direction }) => (
    <FilledButton
      onClick={onClick}
      disabled={disabled}
      customStyle={{
        padding: "10px",
        borderRadius: "5px",
        minHeight: "auto",
        ...customButtonStyle,
      }}
      buttonColor={"coleGray"}
      textComp={
        direction === "up" ? (
          <Icon
            name="triangle up"
            type="material-community"
            style={{ color: "#838b96", margin: 0 }}
            size={"large"}
          />
        ) : (
          <div style={{ transform: "rotate(180deg)" }}>
            <Icon
              name="triangle up"
              type="material-community"
              style={{ color: "#838b96", margin: 0 }}
              size={"large"}
            />
          </div>
        )
      }
    ></FilledButton>
  )
);

export const DeleteWarningModal = React.memo(
  ({ open, onClose, onDelete, isFetching }) => (
    <Modal open={open} className="p-sm">
      <Modal.Header>{"삭제"}</Modal.Header>
      <Modal.Content>
        <span className="label">
          {strToHtml(
            "삭제된 후에는 저장된 정보를 복원할 수 없습니다.\n삭제를 계속하시겠습니까?"
          )}
        </span>
      </Modal.Content>
      <Divider className="parent-border" />
      <Grid.Row>
        <Grid.Column className="pl-pr-0" align="right">
          <div className="flex-end">
            <FilledButton
              onClick={onClose}
              buttonColor="blue-outline"
              label={"취소"}
              customStyle={{ minHeight: "35px", marginRight: "15px" }}
            />
            <FilledButton
              onClick={onDelete}
              buttonColor="red"
              loading={isFetching}
              label={"삭제"}
              customStyle={{ minHeight: "35px" }}
            />
          </div>
        </Grid.Column>
      </Grid.Row>
    </Modal>
  )
);

const HistoryLabel = React.memo(({ label, value }) => (
  <div className="d-flex pt-pb-5">
    <div style={{ width: "30%" }} className="pl-pr-5">
      <div className="h6 bold lightPrimary-color">{label}</div>
    </div>
    <div style={{ width: "70%" }} className="pl-pr-5">
      <div className="h6 bold ">{value}</div>
    </div>
  </div>
));

export const StockGroupHistoryModal = React.memo(
  ({
    open,
    onCancel,
    onPrintPress,
    onTransferComplete,
    onTransferDelete,
    historyType,
    history,
    isCompleting,
    isDeleting,
    loadMore,
    pageItemCount,
    isEditable,
    isPrinting,
  }) => {
    switch (historyType) {
      case "transfer":
        return (
          <Modal open={open} className="p-0 width-70 min-width-450px">
            <MainModalHeaderWithCustomRight
              title={"지점 이동내역"}
              onCancel={onCancel}
            />
            <Modal.Content scrolling className="p-lg">
              <div className="flex-between mb-line">
                <FilledButton
                  loading={isPrinting}
                  disabled={isPrinting}
                  buttonColor="blue"
                  onClick={() => onPrintPress(history.id)}
                  customStyle={{ minHeight: "44px" }}
                  customClass="mb-line"
                  textComp={<FontAwesomeIcon icon={faPrint} className="h5" />}
                />
                {isEditable ? (
                  <div className="d-flex">
                    <FilledButton
                      loading={isCompleting}
                      disabled={
                        isCompleting ||
                        !Authority.can(
                          Authority.actions.update,
                          Authority.subjects.stock
                        )
                      }
                      buttonColor="blue"
                      label="완료처리"
                      customStyle={{ minHeight: "44px" }}
                      onClick={onTransferComplete}
                    />
                    <FilledButton
                      loading={isDeleting}
                      disabled={
                        isDeleting ||
                        !Authority.can(
                          Authority.actions.update,
                          Authority.subjects.stock
                        )
                      }
                      buttonColor="red-outline"
                      label="삭제하기"
                      customClass="ml-line"
                      customStyle={{ minHeight: "44px" }}
                      onClick={onTransferDelete}
                    />
                  </div>
                ) : null}
              </div>
              <div className="d-flex align-items-stretch">
                <div className="w-50">
                  <HistoryLabel label="생성일시" value={history.createdAt} />
                  <HistoryLabel label="출발지점" value={history.fromLocation} />
                  <HistoryLabel label="도착지점" value={history.toLocation} />
                  <HistoryLabel label="제품 수" value={history.skuLength} />
                  <HistoryLabel label="재고 수" value={history.totalQuantity} />
                  <HistoryLabel label="담당자" value={history.employee} />
                  <HistoryLabel label="상태" value={history.status} />
                  <HistoryLabel label="총 비용" value={history.totalCost} />
                </div>
                <div className="w-50 pl-line flex-column">
                  <textarea
                    multiline
                    disabled
                    value={history.note}
                    style={{
                      border: "1px solid #d1d4d8",
                      borderRadius: "5px",
                      height: "100%",
                      width: "100%",
                      padding: "10px",
                      flex: 1,
                    }}
                    placeholder="조정노트"
                  />
                </div>
              </div>
              <Divider className="mt-mb-small" />
              <CustomTable
                items={history.items.slice(0, pageItemCount)}
                headers={TABLE_HEADER_FOR_TRANSFER_HISTORY}
              />
              {history.items.length > pageItemCount ? (
                <div className="bg-color-white">
                  <NofilledButton
                    onClick={loadMore}
                    buttonColor={"blue"}
                    customStyle={{
                      height: "55px",
                      margin: "auto",
                    }}
                    textComp={
                      <div className="label font-bold blue-color">
                        {"더보기"}
                      </div>
                    }
                  />
                </div>
              ) : null}
            </Modal.Content>
          </Modal>
        );
      case "count":
        return (
          <Modal open={open} className="p-0 width-70 min-width-450px">
            <MainModalHeaderWithCustomRight
              title={"조정내역"}
              onCancel={onCancel}
            />
            <Modal.Content scrolling className="p-lg">
              <FilledButton
                loading={isPrinting}
                disabled={isPrinting}
                buttonColor="blue"
                onClick={() => onPrintPress(history.id)}
                customStyle={{ minHeight: "44px" }}
                customClass="mb-line"
                textComp={<FontAwesomeIcon icon={faPrint} className="h5" />}
              />
              <div className="d-flex align-items-start">
                <div className="w-50 pr-line">
                  <HistoryLabel label="실사일시" value={history.createdAt} />
                  <HistoryLabel label="실사 지점" value={history.location} />
                  <HistoryLabel
                    label="실사 제품 수"
                    value={history.skuLength}
                  />
                  <HistoryLabel label="담당자" value={history.employee} />
                  <HistoryLabel label="총 비용" value={history.totalCost} />
                </div>
                <div className="w-50">
                  <CustomTextField
                    label="조정노트"
                    rows={5}
                    multiline
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className="w-100"
                    value={history.note}
                    disabled
                  />
                </div>
              </div>
              <Divider className="mt-mb-small" />
              <CustomTable
                items={history.items.slice(0, pageItemCount)}
                headers={TABLE_HEADER_FOR_COUNT_HISTORY}
                topMarginStyle={{ marginTop: "10px" }}
              />
              {history.items.length > pageItemCount ? (
                <div className="bg-color-white">
                  <NofilledButton
                    onClick={loadMore}
                    buttonColor={"blue"}
                    customStyle={{
                      height: "55px",
                      margin: "auto",
                    }}
                    textComp={
                      <div className="label font-bold blue-color">
                        {"더보기"}
                      </div>
                    }
                  />
                </div>
              ) : null}
            </Modal.Content>
          </Modal>
        );
      default:
        return null;
    }
  }
);

export const EditableQtySmall = React.memo(
  ({
    onRemoveClick,
    onAddClick,
    quantity,
    notEditable,
    onQuantityChange,
    customStyle,
  }) => (
    <div className="editable-small-qty-wrapper" style={customStyle}>
      <FilledButton
        disabled={notEditable || !quantity}
        onClick={notEditable ? () => null : onRemoveClick}
        customStyle={{
          opacity: notEditable || !quantity ? 0.4 : 1,
          minHeight: "50px",
          mixWidth: "50px",
          padding: "0 20px",
          borderRadius: 0,
          border: `1px solid #d1d4d8`,
        }}
        buttonColor={"gray"}
        label={
          <Icon
            name="minus"
            size="small"
            style={{ margin: 0, color: "#fff" }}
          />
        }
      />
      <input
        type="number"
        value={quantity}
        onChange={onQuantityChange}
        className="h6 middle"
      />
      <FilledButton
        disabled={notEditable}
        onClick={notEditable ? () => null : onAddClick}
        customStyle={{
          opacity: notEditable ? 0.38 : 1,
          minHeight: "50px",
          minWidth: "50px",
          padding: "0 20px",
          border: `1px solid #d1d4d8`,
          borderRadius: 0,
        }}
        buttonColor={"gray"}
        label={
          <Icon name="plus" size="small" style={{ margin: 0, color: "#fff" }} />
        }
      />
    </div>
  )
);

export const CategoryFilterModal = React.memo(
  ({
    open,
    onCancel,
    onSave,
    categoriesById,
    tempCategoryFilterIds,
    onCheckPress,
    onAllCheck,
    categoryProducts,
    searchValue,
    onSearchChange,
    onKeyDownForSearch = null,
    modalTitle = null,
  }) => {
    let categories = Object.values(categoriesById || {});
    categories = searchValue
      ? categories.filter((cate) =>
          cate.name?.toLowerCase().includes(searchValue?.toLowerCase())
        )
      : categories;
    let checkStatus =
      (categories || []).length === tempCategoryFilterIds.length
        ? "all"
        : tempCategoryFilterIds.length === 0
        ? "none"
        : "partial";
    return (
      <Modal open={open} className="p-0 width-50 min-width-450px">
        <MainModalHeaderWithSave
          title={modalTitle || "카테고리 필터"}
          onCancel={onCancel}
          onSave={onSave}
        />
        <Modal.Content scrolling className="p-lg">
          <div className="d-flex">
            <div className="mb-base" style={{ marginLeft: "auto" }}>
              <div className="h7 bold blue-color">
                {`${tempCategoryFilterIds.length}개 선택됨`}
              </div>
            </div>
          </div>
          <LabelCheck
            label={"전체선택"}
            isChecked={checkStatus === "all"}
            indeterminate={checkStatus === "partial"}
            onCheckPress={onAllCheck}
          />
          <Divider />
          <div className="category-filter-search-wrapper">
            <FontAwesomeIcon icon={faMagnifyingGlass} />
            <input
              className="custom-table-search-input"
              value={searchValue}
              placeholder={"카테고리 검색"}
              onChange={onSearchChange}
              onKeyDown={onKeyDownForSearch}
            />
          </div>
          {categories.map((cate, index) => {
            let productLength = (
              categoryProducts.filter((cp) => cp.categoryId === cate.id) || []
            ).length;
            return (
              <LabelCheck
                key={`cate-${index}`}
                label={`${cate.name} (${productLength}개 제품)`}
                isChecked={tempCategoryFilterIds.includes(cate.id)}
                onCheckPress={() => onCheckPress(cate.id)}
              />
            );
          })}
        </Modal.Content>
      </Modal>
    );
  }
);

export const LabelCheck = React.memo(({ label, onCheckPress, isChecked }) => (
  <div onClick={onCheckPress} className="label-check">
    <div className="h6 bold">{label}</div>
    <SementicCheckbox checked={isChecked} />
  </div>
));

export const NeedUpdateModal = ({ open, onClose }) => {
  return (
    <Modal open={open} className="needUpdateModal_container">
      <div style={{ textAlign: "right" }}>
        <Button id="primary-nofill-button" onClick={onClose} icon>
          <Icon className="black-color mr-0">
            <Image
              src={require("../img/graphic/close.png")}
              className="width-100"
            />
          </Icon>
        </Button>
      </div>
      <Modal.Content
        scrolling
        style={{
          borderRadius: "40px",
          paddingTop: "5px",
          paddingBottom: "15px",
        }}
      >
        <div className="needUpdateModal_wrapper">
          <Image
            width={"50px"}
            height={"50px"}
            src={require("../img/brand/app_logo.png")}
          />
          <div className="h3 bold text-align-center">{"Growing Sales"}</div>
        </div>
        <div className="needUpdateModal_desc">
          <div className="h5 text-align-center">
            <b>{"새로운 대시보드 버전"}</b>
            {"이 있습니다.\n 브라우저를 새로고침해 주세요."}
          </div>
          <div className="caption text-align-center mt-5">
            {"(업데이트되지 않을 시 브라우저 캐시 삭제가 필요할 수\n 있습니다)"}
          </div>
        </div>
        <div className="flex-center">
          <FilledButton
            buttonColor={"primary"}
            customClass="needUpdateModal_button"
            onClick={refreshBrowser}
            label={<div className="h5">{"새로고침하기"}</div>}
          />
        </div>
      </Modal.Content>
    </Modal>
  );
};

export const UpdateBanner = React.memo(
  ({ onClose, leftPosition, onLinkClick, banner }) => {
    return (
      <div
        className="update-banner-container"
        style={{ top: 20, left: leftPosition }}
      >
        <div className="body">
          <div className="mb-base">
            <div className="h4 bold">{banner.title}</div>
            {banner.message ? (
              <>
                <div className="mt-base" />
                <div className="h6">{banner.message}</div>
              </>
            ) : null}
          </div>
          <div onClick={() => onLinkClick(banner.url)} className="image">
            <S3Image
              className="update-banner-image"
              imgKey={`common/web-banner/${banner.image}`}
            />
          </div>
          <div className="mt-auto d-flex justify-content-end">
            <NofilledButton
              label="닫기"
              buttonColor="blue"
              onClick={() => onClose(banner.id)}
            />
          </div>
        </div>
      </div>
    );
  }
);

export const BarcodeCouponInfoSection = React.memo(
  ({ couponRef, isPreview, coupon, discountLabel, barcode }) => {
    let canvas = document.createElement("canvas");
    JsBarcode(canvas, barcode, {
      width: 3,
      height: 80,
      margin: 5,
      displayValue: false,
      font: "Pretendard-Regular",
    });
    const thumbnailKey = coupon.image?.key;
    const imageUrl = thumbnailKey
      ? `${Constants.S3_URL}/public/${thumbnailKey}?v=${new Date().getTime()}`
      : null;
    const barcodeURL = canvas.toDataURL();
    return (
      <div
        ref={couponRef}
        className={`${!isPreview ? "barcode-coupon-wrapper" : ""}`}
      >
        <div className="flex-center">
          <div
            className={`${
              isPreview
                ? "around-coupon-image-wrapper"
                : "gifticon-image-wrapper"
            }`}
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                className="image-style"
                crossOrigin=""
                alt=""
              />
            ) : (
              <div
                className="image-style"
                style={{ backgroundColor: "#efeff4" }}
              />
            )}
          </div>
        </div>
        <div
          className={`${
            isPreview ? "column-center mt-line" : "usable-info-wrapper"
          }`}
        >
          <div className="h4 bold text-align-center">{coupon.name}</div>
          <div className="h6 blue-color bold text-align-center">
            {discountLabel}
          </div>
        </div>
        <div
          className={`${
            isPreview
              ? "pt-pb-5 pl-pr-line d-flex justify-content-center"
              : "barcode-wrapper"
          }`}
        >
          {isPreview ? (
            <Image src={barcodeURL} className="image-style" />
          ) : null}
        </div>
      </div>
    );
  }
);

const BarcodeCouponLabel = React.memo(({ label, value, index }) => (
  <div
    className="around-info-label"
    style={{
      borderTop: index === 0 ? "1px solid #d1d4d8" : "none",
    }}
  >
    <div className="left">{label}</div>
    <div className="right">{value}</div>
  </div>
));

export const BarcodeCoupon = React.memo(
  ({ coupon, getDiscountLabel, storeName }) => {
    coupon = { ...coupon, createdAt: new Date().toISOString() };
    const barcode = generateBarcode();
    const discountLabel = getDiscountLabel(coupon);
    const expiryDate = getExpiryDateOfCoupon(coupon);
    return (
      <div
        className="p-line"
        style={{ border: "1px solid #d1d4d8", borderRadius: "9px" }}
      >
        <BarcodeCouponInfoSection
          isPreview
          discountLabel={discountLabel}
          coupon={coupon}
          barcode={barcode}
        />
        <div className="mt-line">
          <BarcodeCouponLabel index={0} label="유효기간" value={expiryDate} />
          <BarcodeCouponLabel
            label="발행일"
            value={moment(coupon.createdAt).format(
              Constants.MOMENT.DATE_FORMAT
            )}
          />
          <BarcodeCouponLabel label="사용처" value={storeName} />
          <BarcodeCouponLabel label="사용방식" value={"쿠폰 바코드 제시"} />
          <BarcodeCouponLabel label="바코드 번호" value={barcode} />
        </div>
        <div className="mt-small">
          <div className="around-notice-wrapper">
            <div className="h5 bold">{"유의사항"}</div>
            <div className="mt-base" />
            <div>{strToHtml(coupon.notice || "")}</div>
          </div>
        </div>
      </div>
    );
  }
);

export const TextPopover = ({
  helperText,
  popoverId,
  anchorEl,
  open,
  onClose,
}) => (
  <Popover
    id={popoverId}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    anchorEl={anchorEl}
    open={open}
    onClose={onClose}
  >
    <div className="blue-color text-14 p-sm">{helperText}</div>
  </Popover>
);

export const QtyBig = React.memo(
  ({
    qty,
    onAdd,
    onRemove,
    onChangeText,
    editable,
    buttonEditable,
    customIconWrapperStyle = {},
    customContainerStyle = {},
  }) => (
    <div
      className="qty-big-wrapper"
      style={{
        ...customContainerStyle,
      }}
    >
      <button
        disabled={!buttonEditable}
        onClick={onRemove}
        className="remove-comp"
        style={{
          ...customIconWrapperStyle,
        }}
      >
        <Icon
          name="minus"
          size="small"
          style={{ margin: 0 }}
          className="red-color"
        />
      </button>
      <div
        style={{
          width: "100px",
        }}
      >
        <input
          value={`${qty}`}
          onChange={onChangeText}
          disabled={!editable}
          className="h5 bold text-align-center qty-input"
        />
      </div>
      <button
        disabled={!buttonEditable}
        onClick={onAdd}
        className="add-comp"
        style={{
          ...customIconWrapperStyle,
        }}
      >
        <Icon
          name="plus"
          size="small"
          style={{ margin: 0 }}
          className="blue-color"
        />
      </button>
    </div>
  )
);

export const ProgressBanner = React.memo(
  ({ title, errorMessage, onClear, isVisible, desc }) => {
    if (!isVisible) {
      return null;
    }
    return (
      <div className="progress-wrapper">
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon={faSpinner} className="blue-color h3 bold" />
          <div className="title">{title}</div>
        </div>
        {errorMessage ? (
          <div className="mt-base">
            <div className="h5 bold red-color">{`처리실패(${errorMessage})`}</div>
            <div className="h5 bold blue-color">
              {"그로잉세일즈 점주센터에 문의해 주세요."}
            </div>
            <FilledButton
              label={"확인"}
              onClick={onClear}
              buttonColor={"blue-outline"}
              customStyle={{
                minHeight: "40px",
                marginLeft: "auto",
              }}
            />
          </div>
        ) : (
          <div className="mt-base">
            <div className="h6-medium">{desc}</div>
            <div className="h6">
              {"* 처리완료 후 자동으로 해당 배너가 사라집니다."}
            </div>
          </div>
        )}
      </div>
    );
  }
);

export const UserManualComp = React.memo(({ onGuideClick, label }) => (
  <NofilledButton
    onClick={onGuideClick}
    buttonColor="blue"
    textComp={
      <div className="flex-center">
        <FontAwesomeIcon icon={faCircleQuestion} className="mr-base h5" />
        {label}
      </div>
    }
  />
));

export const HelpModal = React.memo(({ onCancel, open, content }) => {
  return (
    <Modal open={open} className="p-0 min-width-300px">
      <MainModalHeaderWithCustomRight title={""} onCancel={onCancel} />
      <Modal.Content scrolling className="p-small">
        {content}
      </Modal.Content>
    </Modal>
  );
});

export const TablePages = React.memo(
  ({
    itemsLength,
    itemsPerPage,
    onItemPerPageChange,
    PAGE_OPTIONS,
    narrowPage,
    onNarrowPagePress,
    onPageClick,
    page,
  }) => {
    let totalPage =
      itemsLength === 0 ? 0 : Math.ceil(itemsLength / itemsPerPage);
    let NARROW_ITEM_PER_PAGE = 5;
    return totalPage > 0 ? (
      <div className="flex-end mt-line mr-line">
        <Dropdown
          className="responsive-drop-down mr-base"
          selection
          name="itemsPerPage"
          style={{ minWidth: "10em" }}
          value={itemsPerPage}
          onChange={onItemPerPageChange}
          options={PAGE_OPTIONS}
        />
        {totalPage > 5 && narrowPage > 1 ? (
          <FilledButton
            buttonColor="coleGray"
            onClick={() => onNarrowPagePress("down")}
            customClass="ml-base"
            customStyle={{
              minHeight: "40px",
              width: "40px",
              padding: 0,
            }}
            textComp={<FontAwesomeIcon icon={faArrowLeft} />}
          />
        ) : null}
        {_.range(totalPage)
          .slice(
            (narrowPage - 1) * NARROW_ITEM_PER_PAGE,
            narrowPage * NARROW_ITEM_PER_PAGE
          )
          .map((p, index) => (
            <FilledButton
              key={`page-${index}`}
              onClick={() => onPageClick(p + 1)}
              buttonColor={p + 1 === page ? "primary" : "primary-outline"}
              customStyle={{
                minHeight: "40px",
                width: "40px",
                padding: 0,
                marginLeft: p + 1 !== 1 ? "10px" : 0,
              }}
              label={p + 1}
            />
          ))}
        {totalPage > 5 && narrowPage !== Math.ceil(totalPage / 5) ? (
          <FilledButton
            buttonColor="coleGray"
            onClick={() => onNarrowPagePress("up")}
            customClass="ml-mr-base"
            customStyle={{
              minHeight: "40px",
              width: "40px",
              padding: 0,
            }}
            textComp={<FontAwesomeIcon icon={faArrowRight} />}
          />
        ) : null}
      </div>
    ) : null;
  }
);

export const CustomSortModal = React.memo(
  ({
    open,
    onCancel,
    onSave,
    totalOptions,
    tempOptions,
    onCheckPress,
    onAllCheck,
    modalTitle = null,
  }) => {
    return (
      <Modal open={open} className="p-0 width-50 min-width-450px">
        <MainModalHeaderWithSave
          title={modalTitle || "테이블 맞춤설정"}
          onCancel={onCancel}
          onSave={onSave}
        />
        <Modal.Content scrolling className="p-lg">
          {(totalOptions || []).map((option, index) => {
            return (
              <LabelCheck
                key={`cate-${index}`}
                label={option.label}
                isChecked={!tempOptions.includes(option.key)}
                onCheckPress={() => onCheckPress(option.key)}
              />
            );
          })}
        </Modal.Content>
      </Modal>
    );
  }
);

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loading,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export const LoadingElement = React.memo(({}) => (
  <Lottie options={defaultOptions} height={100} width={250} />
));

export const LocationSelectModal = React.memo(
  ({ isVisible, onCancel, onSave, initLocationIds, locationsById, title }) => {
    const [tempLocationIds, setTempLocationIds] = useState(
      new Set(initLocationIds)
    );
    const [search, setSearch] = useState("");

    const getFilteredLocations = (locations) => {
      let _locations = _.cloneDeep(locations);
      _locations = [
        ..._locations.filter((loc) => tempLocationIds.has(loc.id)),
        ..._locations.filter((loc) => !tempLocationIds.has(loc.id)),
      ];
      if (search) {
        return _locations.filter((loc) =>
          loc.name.toLowerCase().includes((search || "").toLowerCase())
        );
      }
      return _locations;
    };

    const getLocationCheckStatus = (_locationIds) => {
      return getCheckStatus(locationIds.length, _locationIds.size);
    };

    const locations = getFilteredLocations(Object.values(locationsById));
    const locationIds = Object.keys(locationsById);
    const checkStatus = getLocationCheckStatus(tempLocationIds);

    const handleAllCheckClick = () => {
      if (tempLocationIds.size !== locationIds.length) {
        setTempLocationIds(new Set(locationIds));
      } else {
        setTempLocationIds(new Set());
      }
    };

    const handleSelectLocation = (locationId) => {
      if (tempLocationIds.has(locationId)) {
        tempLocationIds.delete(locationId);
        setTempLocationIds(new Set(tempLocationIds));
      } else {
        tempLocationIds.add(locationId);
        setTempLocationIds(new Set(tempLocationIds));
      }
    };

    const handleSearchChange = (e) => {
      const value = e.target.value;
      setSearch(value);
    };

    const handleLocationSave = () => {
      onSave(
        tempLocationIds,
        ["all", "none"].includes(getLocationCheckStatus(tempLocationIds))
      );
    };

    return (
      <Modal open={isVisible} className="p-0 width-50 min-width-450px">
        <MainModalHeaderWithSave
          title={title}
          onCancel={onCancel}
          onSave={handleLocationSave}
        />
        <Modal.Content scrolling className="p-lg min-height-70vh">
          <div className="d-flex">
            <div className="mb-base" style={{ marginLeft: "auto" }}>
              <div className="h7 bold blue-color">
                {`${tempLocationIds.size}개 선택됨`}
              </div>
            </div>
          </div>
          <LabelCheck
            label={"전체선택"}
            isChecked={checkStatus === "all"}
            onCheckPress={handleAllCheckClick}
          />
          <Divider />
          <div className="category-filter-search-wrapper">
            <FontAwesomeIcon icon={faMagnifyingGlass} />
            <input
              className="custom-table-search-input"
              value={search}
              placeholder={"지점 검색"}
              onChange={handleSearchChange}
            />
          </div>
          {locations.map((loc, index) => {
            return (
              <LabelCheck
                key={`cate-${index}`}
                label={`${loc.name}`}
                isChecked={tempLocationIds.has(loc.id)}
                onCheckPress={() => handleSelectLocation(loc.id)}
              />
            );
          })}
        </Modal.Content>
      </Modal>
    );
  }
);

export const SendMailModal = ({
  open,
  onClose,
  title,
  email,
  onEmailChange,
  reportName,
  onSendEmail,
  isValidEmail,
}) => {
  return (
    <Modal className="around-sku-modal" open={open} onClose={onClose}>
      <Modal.Header
        id="store-modal-header-padding"
        className="vertical-horizontal-center"
      >
        {title || "리포트 다운로드"}
        <Button
          id="primary-nofill-button"
          className="force-right-margin p-8"
          onClick={onClose}
          icon
        >
          <CloseIcon />
        </Button>
      </Modal.Header>
      <Modal.Content style={{ padding: "1.875em", maxHeight: "45rem" }}>
        <div className="p-adjusted">
          <div className="h6">
            {`${reportName} 파일이 준비되면 메일로 보내드리겠습니다.`}
            <br />
            {"파일을 전송 받으실 메일 주소를 입력해 주세요."}
            <br />
            {"데이터의 양에 따라 발송시간이 다를 수 있습니다."}
          </div>
          <Input
            className="width-100"
            placeholder={"이메일을 입력해주세요."}
            value={email}
            onChange={(evt, data) => onEmailChange(data)}
            style={{
              padding: "10px",
              border: "1px solid #1a283e",
              marginTop: "20px",
              borderRadius: "5px",
              minWidth: "250px",
            }}
          />
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              paddingRight: "30px",
            }}
          >
            <Button
              className="primary-button"
              style={{
                borderRadius: "5px",
                height: "3.2rem",
                border: "1px solid #1a283e",
                marginRight: "10px",
              }}
              onClick={onSendEmail}
              disabled={!isValidEmail(email)}
            >
              {"보내기"}
            </Button>
            <Button
              className="red-nofill-button"
              style={{
                borderRadius: "5px",
                height: "3.2rem",
                border: "1px solid #e02020",
              }}
              onClick={onClose}
            >
              {"취소"}
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export const MirrorConfigRender = React.memo(
  ({
    mirrorConfig,
    onVideoUpload,
    isUploading,
    onVideoDelete,
    onImageUpload,
    onImageDelete,
  }) => {
    const [optionValue, setOption] = useState("video");
    const videoUrl = mirrorConfig?.video?.url;
    const OPTIONS = [
      { label: "홍보영상", value: "video" },
      { label: "홍보이미지", value: "image" },
    ];
    return (
      <DashboardPanel
        leftTitle={"홍보물 디스플레이"}
        leftDesc={
          <>
            매장의 홍보가 가능한 디스플레이에서 설정하신 동영상이 게시됩니다.
            <br />
            현재 송출 가능장소: 올인원 PC 듀얼스크린
          </>
        }
        rightBody={
          <>
            <div className="d-flex align-items-center mb-small">
              {OPTIONS.map((option, index) => (
                <FilledButton
                  key={option.value}
                  buttonColor={
                    option.value === optionValue ? "lightBlue" : "coleGray"
                  }
                  customStyle={{
                    borderRadius: "12px",
                    marginLeft: index === 0 ? 0 : "15px",
                  }}
                  onClick={() => setOption(option.value)}
                  label={option.label}
                />
              ))}
            </div>
            {optionValue === "video" ? (
              <>
                <div className="h4 bold">홍보영상 업로드</div>
                <div className="flex-between mt-line align-items-start">
                  <div>
                    <label htmlFor="file" className="lightBlue-element-button">
                      {isUploading ? (
                        <div className="primary-loader" />
                      ) : (
                        <div className="h6">{"업로드"}</div>
                      )}
                    </label>
                    <input
                      type="file"
                      id="file"
                      accept="video/*"
                      style={{ display: "none" }}
                      onChange={onVideoUpload}
                    />
                  </div>
                  {mirrorConfig?.video ? (
                    <div className="flex-column align-items-end">
                      <video
                        width={"250px"}
                        height={"187.5px"}
                        style={{
                          aspectRatio: 4 / 3,
                          borderRadius: "9px",
                          objectFit: "cover",
                        }}
                        muted
                      >
                        <source src={videoUrl} type="video/mp4" />
                      </video>
                      <NofilledButton
                        buttonColor="red"
                        onClick={onVideoDelete}
                        label="영상 삭제"
                        customClass="mt-base pl-pr-base"
                      />
                    </div>
                  ) : (
                    <div className="promotion-video-frame">
                      <div className="h6 bold lightPrimary-color">
                        {"VIDEO"}
                      </div>
                    </div>
                  )}
                </div>
                <div className="bottom-divider mt-mb-small" />
                <div className="h6">
                  - 홍보영상은 <b>4:3 (가로/세로)</b>비율로 송출됩니다.
                  <br />- 비디오 파일 크기는 25MB 이하의 이미지만 업로드
                  가능합니다.
                  <br />- 파일 업로드 즉시 홍보영상이 송출되며, 송출을 원치
                  않을시 영상을 삭제해 주세요.
                </div>
              </>
            ) : (
              <>
                <div className="h4 bold">홍보이미지 업로드</div>
                <div className="h6">
                  - 홍보영상이 업로드 돼있을시 홍보영상만 송출됩니다.
                  <br />- 홍보이미지 송출을 원하실 경우, 업로드 돼 있는
                  홍보영상을 삭제해 주세요.
                </div>
                <div className="mt-line">
                  <div className="flex-start">
                    <label
                      htmlFor="file"
                      className="lightBlue-element-button"
                      style={{ width: "auto" }}
                    >
                      {isUploading ? (
                        <div className="primary-loader" />
                      ) : (
                        <div className="h6">{"업로드"}</div>
                      )}
                    </label>
                    <input
                      type="file"
                      id="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={onImageUpload}
                    />
                  </div>
                  {(mirrorConfig?.images || []).length > 0 ? (
                    <div className="flex-end mt-line">
                      {(mirrorConfig?.images || []).map((img, _index) => (
                        <div
                          key={`image-index-${img.key}`}
                          className="mirror-image-wrapper"
                        >
                          <S3Image
                            theme={{
                              photoImg: {
                                width: "100%",
                                aspectRatio: "4 / 3",
                                borderRadius: "5px",
                                objectFit: "cover",
                              },
                            }}
                            imgKey={img.key}
                            level={img.level}
                            rounded
                            wrapped
                          />
                          <NofilledButton
                            buttonColor="red"
                            onClick={() => onImageDelete(_index)}
                            label="이미지 삭제"
                            customClass="mt-base pl-pr-base"
                          />
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
                <div className="bottom-divider mt-mb-small" />
                <div className="h6">
                  - 홍보이미지는 <b>4:3 (가로/세로)</b>비율로 송출됩니다.
                  <br />- 이미지 파일 크기는 2MB 이하의 이미지만 업로드
                  가능합니다.
                  <br />- 송출 순서는 업로드 순으로 진행됩니다.
                  <br />- 최대 5개의 이미지까지 업로드 가능하며, 각 이미지는 5초
                  후 자동으로 넘어갑니다.
                  <br />- 파일 업로드 즉시 송출되며, 송출을 원치 않을시 이미지를
                  삭제해 주세요.
                </div>
              </>
            )}
          </>
        }
      />
    );
  }
);

export const PageTitle = React.memo(({ label, icon }) => (
  <div className="flex-start mb-line">
    <div className="title-icon">
      <FontAwesomeIcon icon={icon} color={"#337ef3"} className="h4" />
    </div>
    <div className="h3 bold">{label}</div>
  </div>
));

export const ExportModal = React.memo(
  ({
    open,
    onCancel,
    onExportClick,
    showDetail,
    isExporting,
    templates,
    exportOption,
    onTemplateChange,
    onExportChange,
    onTemplateCreatePress,
    getHeaderKeys,
    onTemplateEditPress,
    onTemplateDelete,
  }) => {
    let templateOptions = [
      { value: "basic", text: "기본양식" },
      ...(templates || []).map((t) => ({
        value: t.name,
        text: t.name,
      })),
    ];
    exportOption.type = exportOption.type || (!showDetail ? "order" : "item");
    exportOption.templateKey = exportOption.templateKey || "basic";
    let isTemplate =
      (templates.filter((t) => t.name === exportOption.templateKey) || [])
        .length > 0;
    let headers = getHeaderKeys();
    return (
      <Modal open={open} className="p-0 w-60 min-width-500px">
        <MainModalHeaderWithCustomRight
          title={"주문리스트 출력"}
          onCancel={onCancel}
          hasBottomDivider
          rightBtn={
            <FilledButton
              buttonColor="blue"
              label="출력하기"
              onClick={onExportClick}
              customStyle={{
                minHeight: "3.57rem",
                margin: "10px",
                minWidth: "8.57rem",
              }}
              loading={isExporting}
            />
          }
        />
        <div className="p-small" style={{ maxHeight: "60vh" }}>
          <div className="d-flex align-items-start">
            <div className="w-50 pr-line">
              <div className="h6 bold mb-tiny">{"출력 양식"}</div>
              <Dropdown
                className="responsive-drop-down"
                selection
                name="template"
                placeholder="템플릿 선택"
                value={exportOption.templateKey}
                onChange={onTemplateChange}
                options={templateOptions}
                style={{ height: "50px", marginTop: "10px" }}
              />
              <div className="d-flex align-items-center mt-line">
                <SementicCheckbox
                  label="주문별"
                  disabled={isTemplate}
                  checked={exportOption.type === "order"}
                  onChange={() => onExportChange("order", "type")}
                />
                <SementicCheckbox
                  style={{ marginLeft: "15px" }}
                  label="주문제품별"
                  disabled={isTemplate}
                  checked={exportOption.type === "item"}
                  onChange={() => onExportChange("item", "type")}
                />
              </div>
              <div className="flex-start mt-line">
                <FilledButton
                  buttonColor="ultraLightBlue"
                  label="템플릿 만들기"
                  onClick={onTemplateCreatePress}
                />
                {isTemplate ? (
                  <>
                    <FilledButton
                      buttonColor="ultraLightBlue"
                      label="템플릿 수정"
                      onClick={onTemplateEditPress}
                      customClass="ml-base pl-pr-base"
                    />
                    <FilledButton
                      buttonColor="lightRed"
                      label="템플릿 삭제"
                      onClick={onTemplateDelete}
                      customClass="ml-base pl-pr-base"
                    />
                  </>
                ) : null}
              </div>
            </div>
            <div
              className="w-50 pl-line"
              style={{
                borderLeft: "1px solid #d1d4d8",
                overflowY: "auto",
                height: "calc(60vh - 70px)",
              }}
            >
              {exportOption.templateKey === "basic" ? (
                <div className="h6">
                  (맞춤설정에서 제외한 헤더는 나오지 않습니다.)
                </div>
              ) : null}
              {(headers || []).map((h, index) => (
                <div
                  key={`selected-template-${h.key}-${index}`}
                  className="h6 bold mt-base"
                  style={{ borderBottom: "1px solid #d1d4d8" }}
                >
                  {h.label}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
);

export const TemplateCreateModal = React.memo(
  ({
    open,
    onCancel,
    onTemplateSave,
    isTemplateSaving,
    onTemplateEdit,
    editing,
    onTempSelect,
    onSelectedSelect,
    tempHeaderKey,
    tempSelectedKey,
    onHeaderAdd,
    onHeaderRemove,
    headerSearch,
    selectedSearch,
    onHeaderSearchChange,
    onSelectedSearchChange,
    onKeyOrder,
    onAllAdd,
  }) => {
    let keys =
      editing.type === "item"
        ? TEMPLATE_KEYS_FOR_ITEM
        : TEMPLATE_KEYS_FOR_ORDER;
    keys = _.sortBy(keys, "label");
    let selectedHeaders = editing.headers;
    if (headerSearch) {
      keys = keys.filter((key) => key.label?.includes(headerSearch));
    }
    if (selectedSearch) {
      selectedHeaders = selectedHeaders.filter((key) =>
        key.label?.includes(selectedSearch)
      );
    }
    let selectedIndex = editing.headers
      .map((h) => h.key)
      .indexOf(tempSelectedKey);
    return (
      <Modal open={open} className="p-0 w-60 min-width-500px">
        <MainModalHeaderWithCustomRight
          title={"템플릿 관리"}
          onCancel={onCancel}
          hasBottomDivider
          rightBtn={
            <FilledButton
              buttonColor="blue"
              label="저장하기"
              onClick={onTemplateSave}
              customStyle={{
                minHeight: "3.57rem",
                margin: "10px",
                minWidth: "8.57rem",
              }}
              loading={isTemplateSaving}
            />
          }
        />
        <div className="p-small">
          <div className="h6 bold mb-tiny">{"템플릿 이름"}</div>
          <input
            name="name"
            className="basic-input"
            style={{ minWidth: "14.28rem" }}
            value={editing?.name}
            onChange={(e) => onTemplateEdit(e.target.value, "name")}
          />
          <div className="d-flex align-items-center mt-line">
            <SementicCheckbox
              label="주문별"
              checked={editing.type === "order"}
              onChange={() => onTemplateEdit("order", "type")}
            />
            <SementicCheckbox
              style={{ marginLeft: "15px" }}
              label="주문제품별"
              checked={editing.type === "item"}
              onChange={() => onTemplateEdit("item", "type")}
            />
          </div>
          <div
            className="d-flex align-items-center mt-line"
            style={{ maxHeight: "60vh" }}
          >
            <div
              className="w-50 pr-line"
              style={{
                borderRight: "1px solid #d1d4d8",
                overflowY: "auto",
                height: "calc(60vh - 70px)",
              }}
            >
              <input
                placeholder="검색하기"
                className="basic-input"
                style={{ width: "100%" }}
                value={headerSearch}
                onChange={onHeaderSearchChange}
              />
              <div className="flex-start mt-mb-base w-100">
                <NofilledButton
                  buttonColor="blue"
                  onClick={onAllAdd}
                  label="모두 추가하기"
                />
              </div>
              {keys.map((key, index) => (
                <div
                  key={`template-${key.key}-${index}`}
                  onClick={() => onTempSelect(key.key)}
                  aria-selected={tempHeaderKey === key.key}
                  className="export-header-comp"
                >
                  {key.label}
                </div>
              ))}
            </div>
            <div className="column-center pl-pr-base">
              <FilledButton
                buttonColor="coleGray"
                onClick={onHeaderAdd}
                disabled={!tempHeaderKey}
                customStyle={{ minHeight: "40px", width: "40px", padding: 0 }}
                textComp={
                  <div className="flex-center">
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                }
              />
              <FilledButton
                buttonColor="coleGray"
                disabled={!tempSelectedKey}
                onClick={onHeaderRemove}
                customStyle={{ minHeight: "40px", width: "40px", padding: 0 }}
                textComp={
                  <div className="flex-center">
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </div>
                }
              />
            </div>
            <div
              className="w-50 pl-line flex-column align-items-start"
              style={{
                borderLeft: "1px solid #d1d4d8",
                overflowY: "auto",
                height: "calc(60vh - 70px)",
              }}
            >
              <input
                placeholder="검색하기"
                className="basic-input"
                style={{ width: "100%" }}
                value={selectedSearch}
                onChange={onSelectedSearchChange}
              />
              {selectedSearch ? null : (
                <div className="flex-center mt-mb-base w-100">
                  <FilledButton
                    disabled={
                      !tempSelectedKey ||
                      selectedIndex === editing.headers.length - 1
                    }
                    buttonColor="coleGray"
                    customStyle={{ width: "50%", minHeight: "40px" }}
                    customClass="export-header-border-left"
                    textComp={<FontAwesomeIcon icon={faChevronDown} />}
                    onClick={() => onKeyOrder("down")}
                  />
                  <FilledButton
                    disabled={!tempSelectedKey || selectedIndex === 0}
                    buttonColor="coleGray"
                    customStyle={{ width: "50%", minHeight: "40px" }}
                    customClass="export-header-border-right"
                    textComp={<FontAwesomeIcon icon={faChevronUp} />}
                    onClick={() => onKeyOrder("up")}
                  />
                </div>
              )}
              {selectedHeaders.map((header, _index) => (
                <div
                  key={`selected-${header.key}-${_index}`}
                  onClick={() => onSelectedSelect(header.key)}
                  aria-selected={tempSelectedKey === header.key}
                  className="export-header-comp"
                >
                  {header.label}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
);
