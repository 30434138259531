/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const submitCard = /* GraphQL */ `
  mutation SubmitCard(
    $email: String!
    $phone: String!
    $storeId: ID!
    $locationId: ID
  ) {
    submitCard(
      email: $email
      phone: $phone
      storeId: $storeId
      locationId: $locationId
    )
  }
`;
export const submitSms = /* GraphQL */ `
  mutation SubmitSms($storeId: ID!, $phone: String!, $filePath: String!) {
    submitSms(storeId: $storeId, phone: $phone, filePath: $filePath)
  }
`;
export const generateStore = /* GraphQL */ `
  mutation GenerateStore(
    $employee: AWSJSON
    $survey: AWSJSON
    $settings: AWSJSON
  ) {
    generateStore(employee: $employee, survey: $survey, settings: $settings) {
      id
      enterpriseId
      info {
        name
        phone
        email
        representative
        businessType
        businessNumber
        businessHours {
          sun {
            open
            close
          }
          mon {
            open
            close
          }
          tue {
            open
            close
          }
          wed {
            open
            close
          }
          thu {
            open
            close
          }
          fri {
            open
            close
          }
          sat {
            open
            close
          }
        }
        industryType
        sendNumber
        currency
        address
        zip
        logo {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        csPhone
      }
      policy {
        multiLocation
        provider {
          key
          ident
          customer
          coupon
          inventory
          locationId
        }
      }
      currency
      payments {
        card
        cardJoined
        cash
        credit
        refund
      }
      devices {
        id
        ident
        locationId
        system
        ipAddress
        brand
        model
        name
        type
        deviceType
        display {
          height
          width
        }
        protocol
        images {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        barcodeReader
        appVersion
        sysVersion
        status
        password
        msgToken
        parentId
        childId
        sleepMode
        kioskOptions {
          mode
          isActive
          tableId
          orderId
          callable
          payable
          enableDiningOption
          resetTime
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          columnSize
        }
        terminalId
        printOptions {
          port
          baudRate
          receipt
          receiptButtonHide
          bills
          refund
          salesEnd
          order
          orderBeep
          orderCount
          orderExcludeIds
          delivery
        }
        updatedAt
        createdAt
      }
      plans {
        menus {
          id
          name
          mode
          size
          menus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          pages {
            index
            parentId
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            title
            titleMl {
              en
              zh
              ja
            }
          }
          updatedAt
          createdAt
        }
        orders {
          id
          name
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          updatedAt
          createdAt
        }
      }
      taxes {
        id
        type
        name
        enabled
        rate
        method
        targetType
      }
      receipt {
        salesSlip
        orderList
        barcode
        footnote
        phone
        orderNumberHide
        orderSheetFontSize
        billsBusinessInfo
        giftFootnote
        giftReceipt
      }
      subscription {
        status
        account {
          ident
          holder
          bank
          bankCode
          number
          businessNumber
        }
        updatedAt
        createdAt
      }
      view {
        order {
          excludeColumns
          exportTemplates {
            name
            type
            headers {
              key
              label
            }
          }
        }
        itemReport {
          excludeColumns
        }
        summaryReport {
          excludeColumns
        }
        shippingOrder {
          excludeColumns
        }
        trsItemReport {
          excludeColumns
        }
      }
      settings {
        mode
        table
        diningOption
        retailOption
        orderAlarm
        orderConfirm
        addressInput
        autoPay
        autoReturnItem
        autoSoldout
        menuGridSize
        menuMode
        tableMode
        orderNotify
        orderNotifySkippable
        disablePhonePrefix
        autoCloseScreen
        orderNumberPrefix {
          pos
          kiosk
          menuOrder
        }
        orderMgmt {
          kiosk
          menuorder
          delivery
          pos
        }
        reportBasis
        orderOrigins
        beeper {
          active
          coverage
          skippable
        }
        kioskOptions {
          mode
          isActive
          tableId
          orderId
          callable
          payable
          enableDiningOption
          resetTime
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          columnSize
        }
      }
      item {
        attributes {
          name
          type
          value
        }
        actualPrice
        skuMgmt
        barcodeMgmt
        stockTrack
        variablePrice
        locationCreatable
      }
      inventory {
        attributes {
          name
          type
          value
        }
        expiryDateMgmt
        expiryDateAlertDays
      }
      customer {
        attributes {
          name
          type
          value
        }
      }
      billing {
        key
        businessNumber
        name
        email
        phone
        contanctName
        contactName
        address
        zip
        card {
          pg
          number
          name
          expMonth
          expYear
          identify
          password
          billingKey
        }
      }
      credit {
        autoCharge
        autoChargeCredit
        autoChargeQuantity
        alertCredit
      }
      kakao {
        agency
        key
        channelId
        categoryCode
        status
        updatedAt
        createdAt
      }
      review {
        status
        campaignId
        updatedAt
        createdAt
      }
      kiosk {
        status
        callable
        payable
        menus {
          title
          titleMl {
            en
            zh
            ja
          }
          index
          ids
          items {
            parentId
            badge
            type
            position
            activeOptions
          }
          parentId
        }
        multilingual
        displayImages {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        exchangeTicket
        columnSize
        discounts {
          condition
          couponId
        }
        menuDescription
        updatedAt
        createdAt
      }
      menuOrder {
        callOptions {
          index
          label
        }
        orderable
        menus {
          title
          titleMl {
            en
            zh
            ja
          }
          index
          ids
          items {
            parentId
            badge
            type
            position
            activeOptions
          }
          parentId
        }
        multilingual
        displayImages {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        columnSize
        fontSize
        notice
        resetTime
        enableCash
        boardType
      }
      kds {
        layout
        alerts {
          urgent
          warning
        }
        disabledProdIds
        orderNotify
      }
      mirror {
        images {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        video {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
      }
      loyalty {
        status
        provider
        sharePolicy
        type
        grade
        conditionType
        conditionSubType
        condition {
          minAmount
          perAmount
          minTime
          rate
          expirationPeriod
          excludeProdIds
          discountPointSavable
        }
        conditionItems {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        conditionTiers {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardType
        rewardSubType
        reward {
          requirePoint
          requireOrderAmount
          minUsePoint
          minRequireRate
          maxUsePoint
        }
        rewardItems {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardRates {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardAmounts {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardCashs {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        incentives {
          fulfill
          fulfillPoint
          fulfillCampaignId
          birth
          birthPoint
          birthCampaignId
          birthItem {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
        }
        notification {
          register
          registerCampainId
          save
          saveCampaignId
          use
          useCampaignId
          expire
          expireCampaignId
        }
      }
      market {
        status
        account {
          ident
          holder
          bank
          bankCode
          number
          businessNumber
        }
        paused
        takeoutDiscount
        creditDiscount
        takeout
        intro
        notice
        bookTable
        bookTableNote
        depositAmount
        delivery
        deliveryFee {
          start
          end
          fee
        }
        waitingTime
        itemIds
        updatedAt
        createdAt
      }
      notification {
        options {
          type
          email
          sms
        }
      }
      groups {
        items {
          id
          name
          type
          storeGroupsId
          locationGroupsId
          key
          conditions {
            key
            object
            field
            operations {
              operation
              type
              value
            }
            value
            operation
            type
          }
          count
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      location {
        id
        name
        type
        provider
        address
        zip
        phone
        logo {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        representative
        businessNumber
        billing {
          key
          businessNumber
          name
          email
          phone
          contanctName
          contactName
          address
          zip
          card {
            pg
            number
            name
            expMonth
            expYear
            identify
            password
            billingKey
          }
        }
        credit {
          autoCharge
          autoChargeCredit
          autoChargeQuantity
          alertCredit
        }
        businessHours {
          sun {
            open
            close
          }
          mon {
            open
            close
          }
          tue {
            open
            close
          }
          wed {
            open
            close
          }
          thu {
            open
            close
          }
          fri {
            open
            close
          }
          sat {
            open
            close
          }
        }
        storeId
        state
        menuPlanId
        payments {
          refund
          barcode
          barcodeVan
          barcodeTerminalId
        }
        policy {
          locationCost
          loyaltyCost
        }
        loyalty {
          status
          provider
          sharePolicy
          type
          grade
          conditionType
          conditionSubType
          condition {
            minAmount
            perAmount
            minTime
            rate
            expirationPeriod
            excludeProdIds
            discountPointSavable
          }
          conditionItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          conditionTiers {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardType
          rewardSubType
          reward {
            requirePoint
            requireOrderAmount
            minUsePoint
            minRequireRate
            maxUsePoint
          }
          rewardItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardRates {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardAmounts {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardCashs {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          incentives {
            fulfill
            fulfillPoint
            fulfillCampaignId
            birth
            birthPoint
            birthCampaignId
            birthItem {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
          }
          notification {
            register
            registerCampainId
            save
            saveCampaignId
            use
            useCampaignId
            expire
            expireCampaignId
          }
        }
        kiosk {
          status
          callable
          payable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          exchangeTicket
          columnSize
          discounts {
            condition
            couponId
          }
          menuDescription
          updatedAt
          createdAt
        }
        receipt {
          salesSlip
          orderList
          barcode
          footnote
          phone
          orderNumberHide
          orderSheetFontSize
          billsBusinessInfo
          giftFootnote
          giftReceipt
        }
        menuOrder {
          callOptions {
            index
            label
          }
          orderable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          columnSize
          fontSize
          notice
          resetTime
          enableCash
          boardType
        }
        kds {
          layout
          alerts {
            urgent
            warning
          }
          disabledProdIds
          orderNotify
        }
        mini {
          defaultItemName
        }
        orderPlanId
        menu {
          board {
            textSize
            columnSize
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            enableFavorite
          }
          list {
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            shortcuts {
              type
              title
              itemName
              itemPrice
              active
            }
          }
          navOrder
        }
        menus {
          parentId
          position
          page
          pageTitle
        }
        favMenus {
          parentId
          position
          page
          pageTitle
        }
        catMenus {
          title
          index
          ids
          parentId
        }
        market {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          paused
          takeoutDiscount
          creditDiscount
          takeout
          intro
          notice
          bookTable
          bookTableNote
          depositAmount
          delivery
          deliveryFee {
            start
            end
            fee
          }
          waitingTime
          itemIds
          updatedAt
          createdAt
        }
        waiting {
          status
          waitingOptions {
            id
            type
            name
            nameMl {
              en
              zh
              ja
            }
            items {
              id
              name
              nameMl {
                en
                zh
                ja
              }
              limitQuantity
            }
            required
            updatedAt
            createdAt
          }
          waitingMinutes
          waitingMinutesHide
          hasForeigner
          foreignerMethod
          notifications {
            created
            ready
            requested
            cancelled
          }
          notices {
            createdNotice
            createdNoticeMl {
              en
              zh
              ja
            }
            requestedNotice
            requestedNoticeMl {
              en
              zh
              ja
            }
          }
          settings {
            autoCancel
            autoCancelMinutes
          }
          updatedAt
          createdAt
        }
        view {
          order {
            excludeColumns
            exportTemplates {
              name
              type
              headers {
                key
                label
              }
            }
          }
          itemReport {
            excludeColumns
          }
          summaryReport {
            excludeColumns
          }
          shippingOrder {
            excludeColumns
          }
          trsItemReport {
            excludeColumns
          }
        }
        subscription {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          updatedAt
          createdAt
        }
        settings {
          mode
          table
          diningOption
          retailOption
          orderAlarm
          orderConfirm
          addressInput
          autoPay
          autoReturnItem
          autoSoldout
          menuGridSize
          menuMode
          tableMode
          orderNotify
          orderNotifySkippable
          disablePhonePrefix
          autoCloseScreen
          orderNumberPrefix {
            pos
            kiosk
            menuOrder
          }
          orderMgmt {
            kiosk
            menuorder
            delivery
            pos
          }
          reportBasis
          orderOrigins
          beeper {
            active
            coverage
            skippable
          }
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
        }
        devices {
          id
          ident
          locationId
          system
          ipAddress
          brand
          model
          name
          type
          deviceType
          display {
            height
            width
          }
          protocol
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          barcodeReader
          appVersion
          sysVersion
          status
          password
          msgToken
          parentId
          childId
          sleepMode
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
          terminalId
          printOptions {
            port
            baudRate
            receipt
            receiptButtonHide
            bills
            refund
            salesEnd
            order
            orderBeep
            orderCount
            orderExcludeIds
            delivery
          }
          updatedAt
          createdAt
        }
        tables {
          id
          width
          height
          name
          axisX
          axisY
          screenHeight
          screenWidth
          initialX
          initialY
          shape
          layout {
            x
            y
            w
            h
          }
          color
          orderId
          disabled
        }
        boardTables {
          title
          index
          items {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
        }
        gridTables {
          id
          name
          orderId
          index
          page
        }
        modifierSoldoutIds
        delivery {
          baemin
          coupang
          yogiyo
          enableGenProd
          updatedAt
          createdAt
        }
        createdAt
        updatedAt
        sales {
          startedAt
          endedAt
          reserves
          actualBalance
          currency
          note
          estimateBalance
          detail {
            totalSales
            totalRefund
            totalTax
            totalDiscount
            supplyAmount
            totalSalesCount
            cash {
              sales
              refund
            }
            card {
              sales
              refund
            }
            credit {
              sales
              refund
            }
            barcode {
              sales
              refund
            }
            extra {
              sales
              refund
            }
            refund {
              sales
              refund
            }
            products {
              id
              name
              quantity
              amount
            }
            origins {
              pos
              kiosk
              menuorder
              delivery
              market
            }
          }
          reservesHistories {
            type
            amount
            note
            createdEmployeeId
            createdAt
          }
        }
      }
      locations {
        items {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const cancelCampaign = /* GraphQL */ `
  mutation CancelCampaign($id: ID) {
    cancelCampaign(id: $id) {
      id
      enterpriseId
      storeId
      locationId
      type
      name
      trigger
      agency
      triggerTiming
      couponId
      description
      recipientProvider
      recipientId
      campaignRecipientId
      recipient {
        id
        name
        type
        storeGroupsId
        locationGroupsId
        key
        conditions {
          key
          object
          field
          operations {
            operation
            type
            value
          }
          value
          operation
          type
        }
        count
        createdAt
        updatedAt
      }
      campaignRecipientTagId
      campaignRecipientLocationIds
      recipientList
      recipientCount
      filter {
        type
        operation
        value
      }
      includes
      sendNow
      reserveDate
      sentAt
      title
      message
      image {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      imageType
      imageLink
      buttons {
        order
        type
        name
        linkMo
        linkPc
        target
      }
      params {
        key
        value
      }
      templateCode
      method
      tracking
      trackingLink
      block
      ads
      sender
      success
      failure
      status
      creditTransactionId
      results {
        items {
          id
          enterpriseId
          storeId
          locationId
          customerId
          campaignResultCustomerId
          customerProvider
          providerCustomerId
          linkId
          requestId
          title
          message
          phone
          msgStatus
          campaignResultsId
          requestedAt
          resultCode
          resultCodeName
          templateId
          statusCode
          method
          sendType
          sender
          sentAt
          creditTransactionId
          updatedAt
          createdAt
        }
        nextToken
      }
      updatedAt
      createdAt
      report
      system
      recipientTag {
        id
        label
        description
        color
        customerIds
        storeId
        locationId
        type
        key
        conditions {
          key
          object
          field
          operations {
            operation
            type
            value
          }
          value
          operation
          type
        }
        updatedAt
        createdAt
      }
    }
  }
`;
export const launchCampaign = /* GraphQL */ `
  mutation LaunchCampaign($id: ID) {
    launchCampaign(id: $id) {
      id
      enterpriseId
      storeId
      locationId
      type
      name
      trigger
      agency
      triggerTiming
      couponId
      description
      recipientProvider
      recipientId
      campaignRecipientId
      recipient {
        id
        name
        type
        storeGroupsId
        locationGroupsId
        key
        conditions {
          key
          object
          field
          operations {
            operation
            type
            value
          }
          value
          operation
          type
        }
        count
        createdAt
        updatedAt
      }
      campaignRecipientTagId
      campaignRecipientLocationIds
      recipientList
      recipientCount
      filter {
        type
        operation
        value
      }
      includes
      sendNow
      reserveDate
      sentAt
      title
      message
      image {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      imageType
      imageLink
      buttons {
        order
        type
        name
        linkMo
        linkPc
        target
      }
      params {
        key
        value
      }
      templateCode
      method
      tracking
      trackingLink
      block
      ads
      sender
      success
      failure
      status
      creditTransactionId
      results {
        items {
          id
          enterpriseId
          storeId
          locationId
          customerId
          campaignResultCustomerId
          customerProvider
          providerCustomerId
          linkId
          requestId
          title
          message
          phone
          msgStatus
          campaignResultsId
          requestedAt
          resultCode
          resultCodeName
          templateId
          statusCode
          method
          sendType
          sender
          sentAt
          creditTransactionId
          updatedAt
          createdAt
        }
        nextToken
      }
      updatedAt
      createdAt
      report
      system
      recipientTag {
        id
        label
        description
        color
        customerIds
        storeId
        locationId
        type
        key
        conditions {
          key
          object
          field
          operations {
            operation
            type
            value
          }
          value
          operation
          type
        }
        updatedAt
        createdAt
      }
    }
  }
`;
export const generateBillingKey = /* GraphQL */ `
  mutation GenerateBillingKey(
    $storeId: ID!
    $cardNo: String!
    $cardPw: String!
    $expMonth: String!
    $expYear: String!
    $identify: String!
  ) {
    generateBillingKey(
      storeId: $storeId
      cardNo: $cardNo
      cardPw: $cardPw
      expMonth: $expMonth
      expYear: $expYear
      identify: $identify
    ) {
      pg
      number
      name
      expMonth
      expYear
      identify
      password
      billingKey
    }
  }
`;
export const destroyBillingKey = /* GraphQL */ `
  mutation DestroyBillingKey($key: String) {
    destroyBillingKey(key: $key)
  }
`;
export const sendSms = /* GraphQL */ `
  mutation SendSms(
    $message: String!
    $method: CampaignMethod!
    $recipient: String!
    $title: String
    $sendNumber: String
    $originUrl: String
    $imageUrl: String
    $options: AWSJSON
  ) {
    sendSms(
      message: $message
      method: $method
      recipient: $recipient
      title: $title
      sendNumber: $sendNumber
      originUrl: $originUrl
      imageUrl: $imageUrl
      options: $options
    )
  }
`;
export const sendReceipt = /* GraphQL */ `
  mutation SendReceipt(
    $method: String!
    $recipient: String!
    $order: AWSJSON!
    $options: AWSJSON
  ) {
    sendReceipt(
      method: $method
      recipient: $recipient
      order: $order
      options: $options
    ) {
      id
      orderLocationId
      numberPrefix
      number
      beeper
      enterpriseId
      storeId
      customerId
      orderCustomerId
      state
      amount
      amountPaid
      origin
      subOrigin
      originId
      customerProvider
      providerCustomerId
      amountChanged
      amountReturned
      tax
      currency
      quantity
      shippingType
      diningOption
      diningState
      retailOption
      retailState
      name
      phone
      address
      zip
      receivers {
        id
        name
        phone
        zip
        address1
        address2
        addressState
        addressCity
        addressStreet
        addressFull
        note
        shippingCompanyCode
        shippingAcceptCount
        shippingStartCount
        trackingNumber
        privacyAgreedAt
      }
      tableId
      tableIds
      parentDeviceId
      deviceId
      reserveDate
      pickupDate
      shippingDate
      items {
        id
        type
        index
        category
        categories {
          id
          name
        }
        classificationIds
        productId
        modifierId
        name
        description
        amount
        price
        parentId
        referType
        referId
        campaignId
        requestId
        quantity
        sku
        tax
        taxRate
        diningOption
        diningState
        modifiers {
          id
          type
          index
          category
          categories {
            id
            name
          }
          classificationIds
          productId
          modifierId
          name
          description
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        discounts {
          id
          scope
          type
          amount
          name
        }
        discountable
        discountQuantity
        discountIncludeIds
        discountExcludeIds
        discountMethod
        discountValue
        orderedQuantity
        voidQuantity
        paidQuantity
        readyQuantity
        receivedQuantity
        inventoryReason
        point
        note
        createdAt
      }
      createdEmployeeId
      createdDeviceId
      updatedDeviceId
      shippingLocationId
      confirmedEmployeeId
      confirmedAt
      createdAt
      paidAt
      salesDate
      returnedAt
      shippedAt
      returnShippedAt
      returnSalesDate
      updatedAt
      noticeCount
      visitorCount
      totalPoint
      pointSaved
      receiptId
      receiptNumber
      receiptCount
      alarmCustomerId
      receiptCustomerId
      note
      request
      charges {
        id
        method
        extraMethod
        gateway
        amount
        tax
        taxRefundAmount
        purchaser {
          name
          number
          nation
          address
        }
        supplyAmount
        taxFreeAmount
        amountRefunded
        createdEmployeeId
        createdDeviceId
        createdAt
        creditTransactionId
        balance
        splitType
        pg
        van
        origin
        originId
        note
        brand
        name
        ident
        installment
        transactionId
        approver
        issuerCode
        acquirer
        acquirerCode
        acquirerName
        refundable
        recurring
        approvalCode
        approvalDate
        approvalNumber
        signature {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        refunds {
          id
          amount
          supplyAmount
          taxFreeAmount
          tax
          taxRefundAmount
          creditTransactionId
          approvalNumber
          approvalDate
          transactionId
          origin
          originId
          currency
          reason
          signature {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          createdEmployeeId
          createdDeviceId
          createdAt
        }
      }
      returns {
        amount
        currency
        items {
          id
          type
          index
          category
          categories {
            id
            name
          }
          classificationIds
          productId
          modifierId
          name
          description
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        refundId
        createdAt
      }
      fee
      revision
      _delta
      location {
        id
        name
        type
        provider
        address
        zip
        phone
        logo {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        representative
        businessNumber
        billing {
          key
          businessNumber
          name
          email
          phone
          contanctName
          contactName
          address
          zip
          card {
            pg
            number
            name
            expMonth
            expYear
            identify
            password
            billingKey
          }
        }
        credit {
          autoCharge
          autoChargeCredit
          autoChargeQuantity
          alertCredit
        }
        businessHours {
          sun {
            open
            close
          }
          mon {
            open
            close
          }
          tue {
            open
            close
          }
          wed {
            open
            close
          }
          thu {
            open
            close
          }
          fri {
            open
            close
          }
          sat {
            open
            close
          }
        }
        storeId
        state
        menuPlanId
        payments {
          refund
          barcode
          barcodeVan
          barcodeTerminalId
        }
        policy {
          locationCost
          loyaltyCost
        }
        loyalty {
          status
          provider
          sharePolicy
          type
          grade
          conditionType
          conditionSubType
          condition {
            minAmount
            perAmount
            minTime
            rate
            expirationPeriod
            excludeProdIds
            discountPointSavable
          }
          conditionItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          conditionTiers {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardType
          rewardSubType
          reward {
            requirePoint
            requireOrderAmount
            minUsePoint
            minRequireRate
            maxUsePoint
          }
          rewardItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardRates {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardAmounts {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardCashs {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          incentives {
            fulfill
            fulfillPoint
            fulfillCampaignId
            birth
            birthPoint
            birthCampaignId
            birthItem {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
          }
          notification {
            register
            registerCampainId
            save
            saveCampaignId
            use
            useCampaignId
            expire
            expireCampaignId
          }
        }
        kiosk {
          status
          callable
          payable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          exchangeTicket
          columnSize
          discounts {
            condition
            couponId
          }
          menuDescription
          updatedAt
          createdAt
        }
        receipt {
          salesSlip
          orderList
          barcode
          footnote
          phone
          orderNumberHide
          orderSheetFontSize
          billsBusinessInfo
          giftFootnote
          giftReceipt
        }
        menuOrder {
          callOptions {
            index
            label
          }
          orderable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          columnSize
          fontSize
          notice
          resetTime
          enableCash
          boardType
        }
        kds {
          layout
          alerts {
            urgent
            warning
          }
          disabledProdIds
          orderNotify
        }
        mini {
          defaultItemName
        }
        orderPlanId
        menu {
          board {
            textSize
            columnSize
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            enableFavorite
          }
          list {
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            shortcuts {
              type
              title
              itemName
              itemPrice
              active
            }
          }
          navOrder
        }
        menus {
          parentId
          position
          page
          pageTitle
        }
        favMenus {
          parentId
          position
          page
          pageTitle
        }
        catMenus {
          title
          index
          ids
          parentId
        }
        market {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          paused
          takeoutDiscount
          creditDiscount
          takeout
          intro
          notice
          bookTable
          bookTableNote
          depositAmount
          delivery
          deliveryFee {
            start
            end
            fee
          }
          waitingTime
          itemIds
          updatedAt
          createdAt
        }
        waiting {
          status
          waitingOptions {
            id
            type
            name
            nameMl {
              en
              zh
              ja
            }
            items {
              id
              name
              nameMl {
                en
                zh
                ja
              }
              limitQuantity
            }
            required
            updatedAt
            createdAt
          }
          waitingMinutes
          waitingMinutesHide
          hasForeigner
          foreignerMethod
          notifications {
            created
            ready
            requested
            cancelled
          }
          notices {
            createdNotice
            createdNoticeMl {
              en
              zh
              ja
            }
            requestedNotice
            requestedNoticeMl {
              en
              zh
              ja
            }
          }
          settings {
            autoCancel
            autoCancelMinutes
          }
          updatedAt
          createdAt
        }
        view {
          order {
            excludeColumns
            exportTemplates {
              name
              type
              headers {
                key
                label
              }
            }
          }
          itemReport {
            excludeColumns
          }
          summaryReport {
            excludeColumns
          }
          shippingOrder {
            excludeColumns
          }
          trsItemReport {
            excludeColumns
          }
        }
        subscription {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          updatedAt
          createdAt
        }
        settings {
          mode
          table
          diningOption
          retailOption
          orderAlarm
          orderConfirm
          addressInput
          autoPay
          autoReturnItem
          autoSoldout
          menuGridSize
          menuMode
          tableMode
          orderNotify
          orderNotifySkippable
          disablePhonePrefix
          autoCloseScreen
          orderNumberPrefix {
            pos
            kiosk
            menuOrder
          }
          orderMgmt {
            kiosk
            menuorder
            delivery
            pos
          }
          reportBasis
          orderOrigins
          beeper {
            active
            coverage
            skippable
          }
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
        }
        devices {
          id
          ident
          locationId
          system
          ipAddress
          brand
          model
          name
          type
          deviceType
          display {
            height
            width
          }
          protocol
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          barcodeReader
          appVersion
          sysVersion
          status
          password
          msgToken
          parentId
          childId
          sleepMode
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
          terminalId
          printOptions {
            port
            baudRate
            receipt
            receiptButtonHide
            bills
            refund
            salesEnd
            order
            orderBeep
            orderCount
            orderExcludeIds
            delivery
          }
          updatedAt
          createdAt
        }
        tables {
          id
          width
          height
          name
          axisX
          axisY
          screenHeight
          screenWidth
          initialX
          initialY
          shape
          layout {
            x
            y
            w
            h
          }
          color
          orderId
          disabled
        }
        boardTables {
          title
          index
          items {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
        }
        gridTables {
          id
          name
          orderId
          index
          page
        }
        modifierSoldoutIds
        delivery {
          baemin
          coupang
          yogiyo
          enableGenProd
          updatedAt
          createdAt
        }
        createdAt
        updatedAt
        sales {
          startedAt
          endedAt
          reserves
          actualBalance
          currency
          note
          estimateBalance
          detail {
            totalSales
            totalRefund
            totalTax
            totalDiscount
            supplyAmount
            totalSalesCount
            cash {
              sales
              refund
            }
            card {
              sales
              refund
            }
            credit {
              sales
              refund
            }
            barcode {
              sales
              refund
            }
            extra {
              sales
              refund
            }
            refund {
              sales
              refund
            }
            products {
              id
              name
              quantity
              amount
            }
            origins {
              pos
              kiosk
              menuorder
              delivery
              market
            }
          }
          reservesHistories {
            type
            amount
            note
            createdEmployeeId
            createdAt
          }
        }
      }
      customer {
        id
        customerId
        storeId
        sources {
          id
          type
          name
          ident
          createdAt
        }
        isFavorite
        locationId
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        favoriteLocationIds
        visitedLocationIds
        locations {
          id
          locationId
          isFavorite
          status
          note
          campaignBlockedAt
          blacklistedAt
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          subscriptions {
            id
            planId
            payStatus
            sessionId
            nextPayDate
            paidAt
            unsubscribedAt
            used
            lastUsedAt
            orderId
            unsubscribeRequestedAt
            startDate
            expireDate
            updatedAt
            createdAt
          }
          registeredAt
          salesDate
          updatedAt
          createdAt
        }
        status
        registeredAt
        campaignBlockedAt
        note
        removedAt
        updatedAt
        createdAt
        points {
          items {
            id
            locationId
            storeId
            type
            name
            amount
            origin
            provider
            providerCustomerId
            providerSyncedAt
            currency
            point
            extraPoint
            extraExpire
            items {
              type
              itemId
              name
              parentId
              point
              amount
              quantity
            }
            quantity
            note
            orderId
            referId
            orderNumberPrefix
            orderNumber
            customerId
            pointTransactionCustomerId
            expiredAt
            salesDate
            updatedAt
            createdAt
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
          }
          nextToken
        }
        credits {
          items {
            id
            locationId
            storeId
            name
            type
            amount
            origin
            note
            currency
            parentId
            orderId
            orderNumberPrefix
            orderNumber
            customerId
            creditTransactionCustomerId
            description
            salesDate
            createdAt
            updatedAt
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
          }
          nextToken
        }
        _delta
        orders {
          items {
            id
            orderLocationId
            numberPrefix
            number
            beeper
            enterpriseId
            storeId
            customerId
            orderCustomerId
            state
            amount
            amountPaid
            origin
            subOrigin
            originId
            customerProvider
            providerCustomerId
            amountChanged
            amountReturned
            tax
            currency
            quantity
            shippingType
            diningOption
            diningState
            retailOption
            retailState
            name
            phone
            address
            zip
            receivers {
              id
              name
              phone
              zip
              address1
              address2
              addressState
              addressCity
              addressStreet
              addressFull
              note
              shippingCompanyCode
              shippingAcceptCount
              shippingStartCount
              trackingNumber
              privacyAgreedAt
            }
            tableId
            tableIds
            parentDeviceId
            deviceId
            reserveDate
            pickupDate
            shippingDate
            items {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            createdEmployeeId
            createdDeviceId
            updatedDeviceId
            shippingLocationId
            confirmedEmployeeId
            confirmedAt
            createdAt
            paidAt
            salesDate
            returnedAt
            shippedAt
            returnShippedAt
            returnSalesDate
            updatedAt
            noticeCount
            visitorCount
            totalPoint
            pointSaved
            receiptId
            receiptNumber
            receiptCount
            alarmCustomerId
            receiptCustomerId
            note
            request
            charges {
              id
              method
              extraMethod
              gateway
              amount
              tax
              taxRefundAmount
              purchaser {
                name
                number
                nation
                address
              }
              supplyAmount
              taxFreeAmount
              amountRefunded
              createdEmployeeId
              createdDeviceId
              createdAt
              creditTransactionId
              balance
              splitType
              pg
              van
              origin
              originId
              note
              brand
              name
              ident
              installment
              transactionId
              approver
              issuerCode
              acquirer
              acquirerCode
              acquirerName
              refundable
              recurring
              approvalCode
              approvalDate
              approvalNumber
              signature {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              refunds {
                id
                amount
                supplyAmount
                taxFreeAmount
                tax
                taxRefundAmount
                creditTransactionId
                approvalNumber
                approvalDate
                transactionId
                origin
                originId
                currency
                reason
                createdEmployeeId
                createdDeviceId
                createdAt
              }
            }
            returns {
              amount
              currency
              items {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              refundId
              createdAt
            }
            fee
            revision
            _delta
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
            orderCustomer {
              id
              enterpriseId
              storeId
              locationId
              customerId
              provider
              providerCustomerId
              tier
              customerCreatedAt
              ident
              identType
              type
              name
              nameKeyword
              payments {
                id
                method
                pg
                number
                name
                expMonth
                expYear
                billingKey
                updatedAt
                createdAt
              }
              attributes {
                name
                type
                value
              }
              addresses {
                id
                main
                detail
                storeId
              }
              phone
              email
              birth
              agreements {
                privacy
                provider
                eula
                marketing
                updatedAt
                createdAt
              }
              ageGroup
              gender
              tags {
                tagId
                createdLocationId
              }
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              status
              registeredAt
              salesDate
              campaignBlockedAt
              blacklistedAt
              isFavorite
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              subscriptions {
                id
                planId
                payStatus
                sessionId
                nextPayDate
                paidAt
                unsubscribedAt
                used
                lastUsedAt
                orderId
                unsubscribeRequestedAt
                startDate
                expireDate
                updatedAt
                createdAt
              }
              note
              sources {
                id
                type
                name
                ident
                createdAt
              }
              removedAt
              updatedAt
              createdAt
              _delta
              timestamp
              expdate
            }
          }
          nextToken
        }
        location {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        store {
          id
          enterpriseId
          info {
            name
            phone
            email
            representative
            businessType
            businessNumber
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            industryType
            sendNumber
            currency
            address
            zip
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            csPhone
          }
          policy {
            multiLocation
            provider {
              key
              ident
              customer
              coupon
              inventory
              locationId
            }
          }
          currency
          payments {
            card
            cardJoined
            cash
            credit
            refund
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          plans {
            menus {
              id
              name
              mode
              size
              menus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              pages {
                index
                parentId
                title
              }
              updatedAt
              createdAt
            }
            orders {
              id
              name
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              updatedAt
              createdAt
            }
          }
          taxes {
            id
            type
            name
            enabled
            rate
            method
            targetType
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          item {
            attributes {
              name
              type
              value
            }
            actualPrice
            skuMgmt
            barcodeMgmt
            stockTrack
            variablePrice
            locationCreatable
          }
          inventory {
            attributes {
              name
              type
              value
            }
            expiryDateMgmt
            expiryDateAlertDays
          }
          customer {
            attributes {
              name
              type
              value
            }
          }
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          kakao {
            agency
            key
            channelId
            categoryCode
            status
            updatedAt
            createdAt
          }
          review {
            status
            campaignId
            updatedAt
            createdAt
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mirror {
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            video {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          notification {
            options {
              type
              email
              sms
            }
          }
          groups {
            items {
              id
              name
              type
              storeGroupsId
              locationGroupsId
              key
              conditions {
                key
                object
                field
                value
                operation
                type
              }
              count
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          location {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          locations {
            items {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            nextToken
          }
        }
        customer {
          id
          ident
          identType
          type
          name
          addresses {
            id
            main
            detail
            storeId
          }
          phone
          email
          birth
          agreements {
            privacy
            provider
            eula
            marketing
            updatedAt
            createdAt
          }
          ageGroup
          gender
          updatedAt
          createdAt
          stores {
            items {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
            nextToken
          }
        }
      }
      orderCustomer {
        id
        enterpriseId
        storeId
        locationId
        customerId
        provider
        providerCustomerId
        tier
        customerCreatedAt
        ident
        identType
        type
        name
        nameKeyword
        payments {
          id
          method
          pg
          number
          name
          expMonth
          expYear
          billingKey
          updatedAt
          createdAt
        }
        attributes {
          name
          type
          value
        }
        addresses {
          id
          main
          detail
          storeId
        }
        phone
        email
        birth
        agreements {
          privacy
          provider
          eula
          marketing
          updatedAt
          createdAt
        }
        ageGroup
        gender
        tags {
          tagId
          createdLocationId
        }
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        status
        registeredAt
        salesDate
        campaignBlockedAt
        blacklistedAt
        isFavorite
        locations {
          id
          locationId
          isFavorite
          status
          note
          campaignBlockedAt
          blacklistedAt
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          subscriptions {
            id
            planId
            payStatus
            sessionId
            nextPayDate
            paidAt
            unsubscribedAt
            used
            lastUsedAt
            orderId
            unsubscribeRequestedAt
            startDate
            expireDate
            updatedAt
            createdAt
          }
          registeredAt
          salesDate
          updatedAt
          createdAt
        }
        subscriptions {
          id
          planId
          payStatus
          sessionId
          nextPayDate
          paidAt
          unsubscribedAt
          used
          lastUsedAt
          orderId
          unsubscribeRequestedAt
          startDate
          expireDate
          updatedAt
          createdAt
        }
        note
        sources {
          id
          type
          name
          ident
          createdAt
        }
        removedAt
        updatedAt
        createdAt
        _delta
        timestamp
        expdate
      }
    }
  }
`;
export const sendReceiptLink = /* GraphQL */ `
  mutation SendReceiptLink(
    $recipient: String!
    $order: AWSJSON!
    $options: AWSJSON
  ) {
    sendReceiptLink(recipient: $recipient, order: $order, options: $options) {
      id
      orderLocationId
      numberPrefix
      number
      beeper
      enterpriseId
      storeId
      customerId
      orderCustomerId
      state
      amount
      amountPaid
      origin
      subOrigin
      originId
      customerProvider
      providerCustomerId
      amountChanged
      amountReturned
      tax
      currency
      quantity
      shippingType
      diningOption
      diningState
      retailOption
      retailState
      name
      phone
      address
      zip
      receivers {
        id
        name
        phone
        zip
        address1
        address2
        addressState
        addressCity
        addressStreet
        addressFull
        note
        shippingCompanyCode
        shippingAcceptCount
        shippingStartCount
        trackingNumber
        privacyAgreedAt
      }
      tableId
      tableIds
      parentDeviceId
      deviceId
      reserveDate
      pickupDate
      shippingDate
      items {
        id
        type
        index
        category
        categories {
          id
          name
        }
        classificationIds
        productId
        modifierId
        name
        description
        amount
        price
        parentId
        referType
        referId
        campaignId
        requestId
        quantity
        sku
        tax
        taxRate
        diningOption
        diningState
        modifiers {
          id
          type
          index
          category
          categories {
            id
            name
          }
          classificationIds
          productId
          modifierId
          name
          description
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        discounts {
          id
          scope
          type
          amount
          name
        }
        discountable
        discountQuantity
        discountIncludeIds
        discountExcludeIds
        discountMethod
        discountValue
        orderedQuantity
        voidQuantity
        paidQuantity
        readyQuantity
        receivedQuantity
        inventoryReason
        point
        note
        createdAt
      }
      createdEmployeeId
      createdDeviceId
      updatedDeviceId
      shippingLocationId
      confirmedEmployeeId
      confirmedAt
      createdAt
      paidAt
      salesDate
      returnedAt
      shippedAt
      returnShippedAt
      returnSalesDate
      updatedAt
      noticeCount
      visitorCount
      totalPoint
      pointSaved
      receiptId
      receiptNumber
      receiptCount
      alarmCustomerId
      receiptCustomerId
      note
      request
      charges {
        id
        method
        extraMethod
        gateway
        amount
        tax
        taxRefundAmount
        purchaser {
          name
          number
          nation
          address
        }
        supplyAmount
        taxFreeAmount
        amountRefunded
        createdEmployeeId
        createdDeviceId
        createdAt
        creditTransactionId
        balance
        splitType
        pg
        van
        origin
        originId
        note
        brand
        name
        ident
        installment
        transactionId
        approver
        issuerCode
        acquirer
        acquirerCode
        acquirerName
        refundable
        recurring
        approvalCode
        approvalDate
        approvalNumber
        signature {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        refunds {
          id
          amount
          supplyAmount
          taxFreeAmount
          tax
          taxRefundAmount
          creditTransactionId
          approvalNumber
          approvalDate
          transactionId
          origin
          originId
          currency
          reason
          signature {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          createdEmployeeId
          createdDeviceId
          createdAt
        }
      }
      returns {
        amount
        currency
        items {
          id
          type
          index
          category
          categories {
            id
            name
          }
          classificationIds
          productId
          modifierId
          name
          description
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        refundId
        createdAt
      }
      fee
      revision
      _delta
      location {
        id
        name
        type
        provider
        address
        zip
        phone
        logo {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        representative
        businessNumber
        billing {
          key
          businessNumber
          name
          email
          phone
          contanctName
          contactName
          address
          zip
          card {
            pg
            number
            name
            expMonth
            expYear
            identify
            password
            billingKey
          }
        }
        credit {
          autoCharge
          autoChargeCredit
          autoChargeQuantity
          alertCredit
        }
        businessHours {
          sun {
            open
            close
          }
          mon {
            open
            close
          }
          tue {
            open
            close
          }
          wed {
            open
            close
          }
          thu {
            open
            close
          }
          fri {
            open
            close
          }
          sat {
            open
            close
          }
        }
        storeId
        state
        menuPlanId
        payments {
          refund
          barcode
          barcodeVan
          barcodeTerminalId
        }
        policy {
          locationCost
          loyaltyCost
        }
        loyalty {
          status
          provider
          sharePolicy
          type
          grade
          conditionType
          conditionSubType
          condition {
            minAmount
            perAmount
            minTime
            rate
            expirationPeriod
            excludeProdIds
            discountPointSavable
          }
          conditionItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          conditionTiers {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardType
          rewardSubType
          reward {
            requirePoint
            requireOrderAmount
            minUsePoint
            minRequireRate
            maxUsePoint
          }
          rewardItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardRates {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardAmounts {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardCashs {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          incentives {
            fulfill
            fulfillPoint
            fulfillCampaignId
            birth
            birthPoint
            birthCampaignId
            birthItem {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
          }
          notification {
            register
            registerCampainId
            save
            saveCampaignId
            use
            useCampaignId
            expire
            expireCampaignId
          }
        }
        kiosk {
          status
          callable
          payable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          exchangeTicket
          columnSize
          discounts {
            condition
            couponId
          }
          menuDescription
          updatedAt
          createdAt
        }
        receipt {
          salesSlip
          orderList
          barcode
          footnote
          phone
          orderNumberHide
          orderSheetFontSize
          billsBusinessInfo
          giftFootnote
          giftReceipt
        }
        menuOrder {
          callOptions {
            index
            label
          }
          orderable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          columnSize
          fontSize
          notice
          resetTime
          enableCash
          boardType
        }
        kds {
          layout
          alerts {
            urgent
            warning
          }
          disabledProdIds
          orderNotify
        }
        mini {
          defaultItemName
        }
        orderPlanId
        menu {
          board {
            textSize
            columnSize
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            enableFavorite
          }
          list {
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            shortcuts {
              type
              title
              itemName
              itemPrice
              active
            }
          }
          navOrder
        }
        menus {
          parentId
          position
          page
          pageTitle
        }
        favMenus {
          parentId
          position
          page
          pageTitle
        }
        catMenus {
          title
          index
          ids
          parentId
        }
        market {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          paused
          takeoutDiscount
          creditDiscount
          takeout
          intro
          notice
          bookTable
          bookTableNote
          depositAmount
          delivery
          deliveryFee {
            start
            end
            fee
          }
          waitingTime
          itemIds
          updatedAt
          createdAt
        }
        waiting {
          status
          waitingOptions {
            id
            type
            name
            nameMl {
              en
              zh
              ja
            }
            items {
              id
              name
              nameMl {
                en
                zh
                ja
              }
              limitQuantity
            }
            required
            updatedAt
            createdAt
          }
          waitingMinutes
          waitingMinutesHide
          hasForeigner
          foreignerMethod
          notifications {
            created
            ready
            requested
            cancelled
          }
          notices {
            createdNotice
            createdNoticeMl {
              en
              zh
              ja
            }
            requestedNotice
            requestedNoticeMl {
              en
              zh
              ja
            }
          }
          settings {
            autoCancel
            autoCancelMinutes
          }
          updatedAt
          createdAt
        }
        view {
          order {
            excludeColumns
            exportTemplates {
              name
              type
              headers {
                key
                label
              }
            }
          }
          itemReport {
            excludeColumns
          }
          summaryReport {
            excludeColumns
          }
          shippingOrder {
            excludeColumns
          }
          trsItemReport {
            excludeColumns
          }
        }
        subscription {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          updatedAt
          createdAt
        }
        settings {
          mode
          table
          diningOption
          retailOption
          orderAlarm
          orderConfirm
          addressInput
          autoPay
          autoReturnItem
          autoSoldout
          menuGridSize
          menuMode
          tableMode
          orderNotify
          orderNotifySkippable
          disablePhonePrefix
          autoCloseScreen
          orderNumberPrefix {
            pos
            kiosk
            menuOrder
          }
          orderMgmt {
            kiosk
            menuorder
            delivery
            pos
          }
          reportBasis
          orderOrigins
          beeper {
            active
            coverage
            skippable
          }
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
        }
        devices {
          id
          ident
          locationId
          system
          ipAddress
          brand
          model
          name
          type
          deviceType
          display {
            height
            width
          }
          protocol
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          barcodeReader
          appVersion
          sysVersion
          status
          password
          msgToken
          parentId
          childId
          sleepMode
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
          terminalId
          printOptions {
            port
            baudRate
            receipt
            receiptButtonHide
            bills
            refund
            salesEnd
            order
            orderBeep
            orderCount
            orderExcludeIds
            delivery
          }
          updatedAt
          createdAt
        }
        tables {
          id
          width
          height
          name
          axisX
          axisY
          screenHeight
          screenWidth
          initialX
          initialY
          shape
          layout {
            x
            y
            w
            h
          }
          color
          orderId
          disabled
        }
        boardTables {
          title
          index
          items {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
        }
        gridTables {
          id
          name
          orderId
          index
          page
        }
        modifierSoldoutIds
        delivery {
          baemin
          coupang
          yogiyo
          enableGenProd
          updatedAt
          createdAt
        }
        createdAt
        updatedAt
        sales {
          startedAt
          endedAt
          reserves
          actualBalance
          currency
          note
          estimateBalance
          detail {
            totalSales
            totalRefund
            totalTax
            totalDiscount
            supplyAmount
            totalSalesCount
            cash {
              sales
              refund
            }
            card {
              sales
              refund
            }
            credit {
              sales
              refund
            }
            barcode {
              sales
              refund
            }
            extra {
              sales
              refund
            }
            refund {
              sales
              refund
            }
            products {
              id
              name
              quantity
              amount
            }
            origins {
              pos
              kiosk
              menuorder
              delivery
              market
            }
          }
          reservesHistories {
            type
            amount
            note
            createdEmployeeId
            createdAt
          }
        }
      }
      customer {
        id
        customerId
        storeId
        sources {
          id
          type
          name
          ident
          createdAt
        }
        isFavorite
        locationId
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        favoriteLocationIds
        visitedLocationIds
        locations {
          id
          locationId
          isFavorite
          status
          note
          campaignBlockedAt
          blacklistedAt
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          subscriptions {
            id
            planId
            payStatus
            sessionId
            nextPayDate
            paidAt
            unsubscribedAt
            used
            lastUsedAt
            orderId
            unsubscribeRequestedAt
            startDate
            expireDate
            updatedAt
            createdAt
          }
          registeredAt
          salesDate
          updatedAt
          createdAt
        }
        status
        registeredAt
        campaignBlockedAt
        note
        removedAt
        updatedAt
        createdAt
        points {
          items {
            id
            locationId
            storeId
            type
            name
            amount
            origin
            provider
            providerCustomerId
            providerSyncedAt
            currency
            point
            extraPoint
            extraExpire
            items {
              type
              itemId
              name
              parentId
              point
              amount
              quantity
            }
            quantity
            note
            orderId
            referId
            orderNumberPrefix
            orderNumber
            customerId
            pointTransactionCustomerId
            expiredAt
            salesDate
            updatedAt
            createdAt
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
          }
          nextToken
        }
        credits {
          items {
            id
            locationId
            storeId
            name
            type
            amount
            origin
            note
            currency
            parentId
            orderId
            orderNumberPrefix
            orderNumber
            customerId
            creditTransactionCustomerId
            description
            salesDate
            createdAt
            updatedAt
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
          }
          nextToken
        }
        _delta
        orders {
          items {
            id
            orderLocationId
            numberPrefix
            number
            beeper
            enterpriseId
            storeId
            customerId
            orderCustomerId
            state
            amount
            amountPaid
            origin
            subOrigin
            originId
            customerProvider
            providerCustomerId
            amountChanged
            amountReturned
            tax
            currency
            quantity
            shippingType
            diningOption
            diningState
            retailOption
            retailState
            name
            phone
            address
            zip
            receivers {
              id
              name
              phone
              zip
              address1
              address2
              addressState
              addressCity
              addressStreet
              addressFull
              note
              shippingCompanyCode
              shippingAcceptCount
              shippingStartCount
              trackingNumber
              privacyAgreedAt
            }
            tableId
            tableIds
            parentDeviceId
            deviceId
            reserveDate
            pickupDate
            shippingDate
            items {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            createdEmployeeId
            createdDeviceId
            updatedDeviceId
            shippingLocationId
            confirmedEmployeeId
            confirmedAt
            createdAt
            paidAt
            salesDate
            returnedAt
            shippedAt
            returnShippedAt
            returnSalesDate
            updatedAt
            noticeCount
            visitorCount
            totalPoint
            pointSaved
            receiptId
            receiptNumber
            receiptCount
            alarmCustomerId
            receiptCustomerId
            note
            request
            charges {
              id
              method
              extraMethod
              gateway
              amount
              tax
              taxRefundAmount
              purchaser {
                name
                number
                nation
                address
              }
              supplyAmount
              taxFreeAmount
              amountRefunded
              createdEmployeeId
              createdDeviceId
              createdAt
              creditTransactionId
              balance
              splitType
              pg
              van
              origin
              originId
              note
              brand
              name
              ident
              installment
              transactionId
              approver
              issuerCode
              acquirer
              acquirerCode
              acquirerName
              refundable
              recurring
              approvalCode
              approvalDate
              approvalNumber
              signature {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              refunds {
                id
                amount
                supplyAmount
                taxFreeAmount
                tax
                taxRefundAmount
                creditTransactionId
                approvalNumber
                approvalDate
                transactionId
                origin
                originId
                currency
                reason
                createdEmployeeId
                createdDeviceId
                createdAt
              }
            }
            returns {
              amount
              currency
              items {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              refundId
              createdAt
            }
            fee
            revision
            _delta
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
            orderCustomer {
              id
              enterpriseId
              storeId
              locationId
              customerId
              provider
              providerCustomerId
              tier
              customerCreatedAt
              ident
              identType
              type
              name
              nameKeyword
              payments {
                id
                method
                pg
                number
                name
                expMonth
                expYear
                billingKey
                updatedAt
                createdAt
              }
              attributes {
                name
                type
                value
              }
              addresses {
                id
                main
                detail
                storeId
              }
              phone
              email
              birth
              agreements {
                privacy
                provider
                eula
                marketing
                updatedAt
                createdAt
              }
              ageGroup
              gender
              tags {
                tagId
                createdLocationId
              }
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              status
              registeredAt
              salesDate
              campaignBlockedAt
              blacklistedAt
              isFavorite
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              subscriptions {
                id
                planId
                payStatus
                sessionId
                nextPayDate
                paidAt
                unsubscribedAt
                used
                lastUsedAt
                orderId
                unsubscribeRequestedAt
                startDate
                expireDate
                updatedAt
                createdAt
              }
              note
              sources {
                id
                type
                name
                ident
                createdAt
              }
              removedAt
              updatedAt
              createdAt
              _delta
              timestamp
              expdate
            }
          }
          nextToken
        }
        location {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        store {
          id
          enterpriseId
          info {
            name
            phone
            email
            representative
            businessType
            businessNumber
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            industryType
            sendNumber
            currency
            address
            zip
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            csPhone
          }
          policy {
            multiLocation
            provider {
              key
              ident
              customer
              coupon
              inventory
              locationId
            }
          }
          currency
          payments {
            card
            cardJoined
            cash
            credit
            refund
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          plans {
            menus {
              id
              name
              mode
              size
              menus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              pages {
                index
                parentId
                title
              }
              updatedAt
              createdAt
            }
            orders {
              id
              name
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              updatedAt
              createdAt
            }
          }
          taxes {
            id
            type
            name
            enabled
            rate
            method
            targetType
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          item {
            attributes {
              name
              type
              value
            }
            actualPrice
            skuMgmt
            barcodeMgmt
            stockTrack
            variablePrice
            locationCreatable
          }
          inventory {
            attributes {
              name
              type
              value
            }
            expiryDateMgmt
            expiryDateAlertDays
          }
          customer {
            attributes {
              name
              type
              value
            }
          }
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          kakao {
            agency
            key
            channelId
            categoryCode
            status
            updatedAt
            createdAt
          }
          review {
            status
            campaignId
            updatedAt
            createdAt
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mirror {
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            video {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          notification {
            options {
              type
              email
              sms
            }
          }
          groups {
            items {
              id
              name
              type
              storeGroupsId
              locationGroupsId
              key
              conditions {
                key
                object
                field
                value
                operation
                type
              }
              count
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          location {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          locations {
            items {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            nextToken
          }
        }
        customer {
          id
          ident
          identType
          type
          name
          addresses {
            id
            main
            detail
            storeId
          }
          phone
          email
          birth
          agreements {
            privacy
            provider
            eula
            marketing
            updatedAt
            createdAt
          }
          ageGroup
          gender
          updatedAt
          createdAt
          stores {
            items {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
            nextToken
          }
        }
      }
      orderCustomer {
        id
        enterpriseId
        storeId
        locationId
        customerId
        provider
        providerCustomerId
        tier
        customerCreatedAt
        ident
        identType
        type
        name
        nameKeyword
        payments {
          id
          method
          pg
          number
          name
          expMonth
          expYear
          billingKey
          updatedAt
          createdAt
        }
        attributes {
          name
          type
          value
        }
        addresses {
          id
          main
          detail
          storeId
        }
        phone
        email
        birth
        agreements {
          privacy
          provider
          eula
          marketing
          updatedAt
          createdAt
        }
        ageGroup
        gender
        tags {
          tagId
          createdLocationId
        }
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        status
        registeredAt
        salesDate
        campaignBlockedAt
        blacklistedAt
        isFavorite
        locations {
          id
          locationId
          isFavorite
          status
          note
          campaignBlockedAt
          blacklistedAt
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          subscriptions {
            id
            planId
            payStatus
            sessionId
            nextPayDate
            paidAt
            unsubscribedAt
            used
            lastUsedAt
            orderId
            unsubscribeRequestedAt
            startDate
            expireDate
            updatedAt
            createdAt
          }
          registeredAt
          salesDate
          updatedAt
          createdAt
        }
        subscriptions {
          id
          planId
          payStatus
          sessionId
          nextPayDate
          paidAt
          unsubscribedAt
          used
          lastUsedAt
          orderId
          unsubscribeRequestedAt
          startDate
          expireDate
          updatedAt
          createdAt
        }
        note
        sources {
          id
          type
          name
          ident
          createdAt
        }
        removedAt
        updatedAt
        createdAt
        _delta
        timestamp
        expdate
      }
    }
  }
`;
export const cancelBilling = /* GraphQL */ `
  mutation CancelBilling($storeId: ID, $date: String) {
    cancelBilling(storeId: $storeId, date: $date) {
      id
      date
      enterpriseId
      storeId
      locationId
      name
      type
      status
      failReason
      amount
      currency
      paidAt
      refundedAt
      transaction {
        cardName
        cardNumber
        cardQuota
        pg
        transactionId
        amount
        currency
        method
        purchasedAt
        requestedAt
        revokedAt
        status
        statusEn
        statusKo
        receiptId
        receiptUrl
      }
      items {
        locationId
        name
        type
        amount
        details {
          type
          name
          amount
          basePrice
          unitPrice
          usage
          rate
          referId
          period
        }
        category
        quantity
        currency
      }
      note
      updatedAt
      createdAt
    }
  }
`;
export const attachPolicy = /* GraphQL */ `
  mutation AttachPolicy($policyName: String, $target: ID) {
    attachPolicy(policyName: $policyName, target: $target)
  }
`;
export const createItem = /* GraphQL */ `
  mutation CreateItem($item: AWSJSON, $options: AWSJSON) {
    createItem(item: $item, options: $options) {
      id
      itemId
      type
      enterpriseId
      storeId
      name
      nameMl {
        en
        zh
        ja
      }
      attributes {
        name
        type
        value
      }
      displayName
      origin
      refer
      referId
      depth
      parentId
      description
      descriptionMl {
        en
        zh
        ja
      }
      thumbnail {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      variablePrice
      skus {
        type
        id
        itemId
        refer
        referId
        attributes {
          name
          type
          value
        }
        name
        nameMl {
          en
          zh
          ja
        }
        price
        cost
        unit
        unitSuffix
        barcode
        currency
        model
        locationId
        futureLocationAvailable
        locationIds
        inventories {
          id
          quantity
          cost
          locationId
          unavailableQuantity
          inReadyQuantity
          outReadyQuantity
          expiryDate
          updatedAt
          createdAt
          locations {
            id
            quantity
          }
          attributes {
            name
            type
            value
          }
        }
        locations {
          id
          isActive
          available
          price
        }
        links {
          type
          origin
          originId
          originProductId
          updatedAt
          createdAt
        }
        stock
        stockAlert
        stockAlertQty
        stockTrack
        quantity
        limitQuantity
        isActive
      }
      trackStock
      taxId
      ordering
      categoryIds
      classificationIds
      modifierIds
      productIds
      locationIds
      orderType
      defaultSkuId
      coverage
      required
      requiredQuantity
      limitQuantity
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const updateItem = /* GraphQL */ `
  mutation UpdateItem($item: AWSJSON, $options: AWSJSON) {
    updateItem(item: $item, options: $options) {
      id
      itemId
      type
      enterpriseId
      storeId
      name
      nameMl {
        en
        zh
        ja
      }
      attributes {
        name
        type
        value
      }
      displayName
      origin
      refer
      referId
      depth
      parentId
      description
      descriptionMl {
        en
        zh
        ja
      }
      thumbnail {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      variablePrice
      skus {
        type
        id
        itemId
        refer
        referId
        attributes {
          name
          type
          value
        }
        name
        nameMl {
          en
          zh
          ja
        }
        price
        cost
        unit
        unitSuffix
        barcode
        currency
        model
        locationId
        futureLocationAvailable
        locationIds
        inventories {
          id
          quantity
          cost
          locationId
          unavailableQuantity
          inReadyQuantity
          outReadyQuantity
          expiryDate
          updatedAt
          createdAt
          locations {
            id
            quantity
          }
          attributes {
            name
            type
            value
          }
        }
        locations {
          id
          isActive
          available
          price
        }
        links {
          type
          origin
          originId
          originProductId
          updatedAt
          createdAt
        }
        stock
        stockAlert
        stockAlertQty
        stockTrack
        quantity
        limitQuantity
        isActive
      }
      trackStock
      taxId
      ordering
      categoryIds
      classificationIds
      modifierIds
      productIds
      locationIds
      orderType
      defaultSkuId
      coverage
      required
      requiredQuantity
      limitQuantity
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const deleteItem = /* GraphQL */ `
  mutation DeleteItem($item: AWSJSON) {
    deleteItem(item: $item) {
      id
      itemId
      type
      enterpriseId
      storeId
      name
      nameMl {
        en
        zh
        ja
      }
      attributes {
        name
        type
        value
      }
      displayName
      origin
      refer
      referId
      depth
      parentId
      description
      descriptionMl {
        en
        zh
        ja
      }
      thumbnail {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      variablePrice
      skus {
        type
        id
        itemId
        refer
        referId
        attributes {
          name
          type
          value
        }
        name
        nameMl {
          en
          zh
          ja
        }
        price
        cost
        unit
        unitSuffix
        barcode
        currency
        model
        locationId
        futureLocationAvailable
        locationIds
        inventories {
          id
          quantity
          cost
          locationId
          unavailableQuantity
          inReadyQuantity
          outReadyQuantity
          expiryDate
          updatedAt
          createdAt
          locations {
            id
            quantity
          }
          attributes {
            name
            type
            value
          }
        }
        locations {
          id
          isActive
          available
          price
        }
        links {
          type
          origin
          originId
          originProductId
          updatedAt
          createdAt
        }
        stock
        stockAlert
        stockAlertQty
        stockTrack
        quantity
        limitQuantity
        isActive
      }
      trackStock
      taxId
      ordering
      categoryIds
      classificationIds
      modifierIds
      productIds
      locationIds
      orderType
      defaultSkuId
      coverage
      required
      requiredQuantity
      limitQuantity
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const createInventoryTransactions = /* GraphQL */ `
  mutation CreateInventoryTransactions($transactions: AWSJSON) {
    createInventoryTransactions(transactions: $transactions) {
      id
      itemId
      type
      enterpriseId
      storeId
      name
      nameMl {
        en
        zh
        ja
      }
      attributes {
        name
        type
        value
      }
      displayName
      origin
      refer
      referId
      depth
      parentId
      description
      descriptionMl {
        en
        zh
        ja
      }
      thumbnail {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      variablePrice
      skus {
        type
        id
        itemId
        refer
        referId
        attributes {
          name
          type
          value
        }
        name
        nameMl {
          en
          zh
          ja
        }
        price
        cost
        unit
        unitSuffix
        barcode
        currency
        model
        locationId
        futureLocationAvailable
        locationIds
        inventories {
          id
          quantity
          cost
          locationId
          unavailableQuantity
          inReadyQuantity
          outReadyQuantity
          expiryDate
          updatedAt
          createdAt
          locations {
            id
            quantity
          }
          attributes {
            name
            type
            value
          }
        }
        locations {
          id
          isActive
          available
          price
        }
        links {
          type
          origin
          originId
          originProductId
          updatedAt
          createdAt
        }
        stock
        stockAlert
        stockAlertQty
        stockTrack
        quantity
        limitQuantity
        isActive
      }
      trackStock
      taxId
      ordering
      categoryIds
      classificationIds
      modifierIds
      productIds
      locationIds
      orderType
      defaultSkuId
      coverage
      required
      requiredQuantity
      limitQuantity
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const createSkuInventory = /* GraphQL */ `
  mutation CreateSkuInventory($inventory: AWSJSON) {
    createSkuInventory(inventory: $inventory) {
      id
      quantity
      cost
      locationId
      unavailableQuantity
      inReadyQuantity
      outReadyQuantity
      expiryDate
      updatedAt
      createdAt
      locations {
        id
        quantity
      }
      attributes {
        name
        type
        value
      }
    }
  }
`;
export const updateSkuInventory = /* GraphQL */ `
  mutation UpdateSkuInventory($inventory: AWSJSON) {
    updateSkuInventory(inventory: $inventory) {
      id
      quantity
      cost
      locationId
      unavailableQuantity
      inReadyQuantity
      outReadyQuantity
      expiryDate
      updatedAt
      createdAt
      locations {
        id
        quantity
      }
      attributes {
        name
        type
        value
      }
    }
  }
`;
export const deleteSkuInventory = /* GraphQL */ `
  mutation DeleteSkuInventory($inventory: AWSJSON) {
    deleteSkuInventory(inventory: $inventory) {
      id
      quantity
      cost
      locationId
      unavailableQuantity
      inReadyQuantity
      outReadyQuantity
      expiryDate
      updatedAt
      createdAt
      locations {
        id
        quantity
      }
      attributes {
        name
        type
        value
      }
    }
  }
`;
export const updateSkuLocation = /* GraphQL */ `
  mutation UpdateSkuLocation($location: AWSJSON) {
    updateSkuLocation(location: $location) {
      id
      isActive
      available
      price
    }
  }
`;
export const arrangeProductLocation = /* GraphQL */ `
  mutation ArrangeProductLocation($storeId: ID, $defaults: AWSJSON) {
    arrangeProductLocation(storeId: $storeId, defaults: $defaults)
  }
`;
export const echo = /* GraphQL */ `
  mutation Echo($message: String) {
    echo(message: $message)
  }
`;
export const LS_subscribeSubscriptionPlan = /* GraphQL */ `
  mutation LS_subscribeSubscriptionPlan(
    $customerId: ID
    $planId: ID
    $locationId: ID
  ) {
    LS_subscribeSubscriptionPlan(
      customerId: $customerId
      planId: $planId
      locationId: $locationId
    ) {
      id
      planId
      payStatus
      sessionId
      nextPayDate
      paidAt
      unsubscribedAt
      used
      lastUsedAt
      orderId
      unsubscribeRequestedAt
      startDate
      expireDate
      updatedAt
      createdAt
    }
  }
`;
export const LS_cancelSubscription = /* GraphQL */ `
  mutation LS_cancelSubscription($subscriptionId: ID) {
    LS_cancelSubscription(subscriptionId: $subscriptionId) {
      id
      planId
      payStatus
      sessionId
      nextPayDate
      paidAt
      unsubscribedAt
      used
      lastUsedAt
      orderId
      unsubscribeRequestedAt
      startDate
      expireDate
      updatedAt
      createdAt
    }
  }
`;
export const LS_restoreSubscription = /* GraphQL */ `
  mutation LS_restoreSubscription($subscriptionId: ID) {
    LS_restoreSubscription(subscriptionId: $subscriptionId) {
      id
      planId
      payStatus
      sessionId
      nextPayDate
      paidAt
      unsubscribedAt
      used
      lastUsedAt
      orderId
      unsubscribeRequestedAt
      startDate
      expireDate
      updatedAt
      createdAt
    }
  }
`;
export const LS_stopSubscription = /* GraphQL */ `
  mutation LS_stopSubscription($subscriptionId: ID) {
    LS_stopSubscription(subscriptionId: $subscriptionId) {
      id
      planId
      payStatus
      sessionId
      nextPayDate
      paidAt
      unsubscribedAt
      used
      lastUsedAt
      orderId
      unsubscribeRequestedAt
      startDate
      expireDate
      updatedAt
      createdAt
    }
  }
`;
export const LS_payBillings = /* GraphQL */ `
  mutation LS_payBillings($storeId: ID!, $locationId: ID) {
    LS_payBillings(storeId: $storeId, locationId: $locationId)
  }
`;
export const LS_createCustomer = /* GraphQL */ `
  mutation LS_createCustomer($customer: AWSJSON, $profileOnly: Boolean) {
    LS_createCustomer(customer: $customer, profileOnly: $profileOnly) {
      id
      enterpriseId
      storeId
      locationId
      customerId
      provider
      providerCustomerId
      tier
      customerCreatedAt
      ident
      identType
      type
      name
      nameKeyword
      payments {
        id
        method
        pg
        number
        name
        expMonth
        expYear
        billingKey
        updatedAt
        createdAt
      }
      attributes {
        name
        type
        value
      }
      addresses {
        id
        main
        detail
        storeId
      }
      phone
      email
      birth
      agreements {
        privacy
        provider
        eula
        marketing
        updatedAt
        createdAt
      }
      ageGroup
      gender
      tags {
        tagId
        createdLocationId
      }
      point {
        used
        saved
        extra
        lastSavedAt
        lastTransactedAt
      }
      credit {
        deposit
        withdraw
        balance
        lastTransactedAt
      }
      status
      registeredAt
      salesDate
      campaignBlockedAt
      blacklistedAt
      isFavorite
      locations {
        id
        locationId
        isFavorite
        status
        note
        campaignBlockedAt
        blacklistedAt
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        subscriptions {
          id
          planId
          payStatus
          sessionId
          nextPayDate
          paidAt
          unsubscribedAt
          used
          lastUsedAt
          orderId
          unsubscribeRequestedAt
          startDate
          expireDate
          updatedAt
          createdAt
        }
        registeredAt
        salesDate
        updatedAt
        createdAt
      }
      subscriptions {
        id
        planId
        payStatus
        sessionId
        nextPayDate
        paidAt
        unsubscribedAt
        used
        lastUsedAt
        orderId
        unsubscribeRequestedAt
        startDate
        expireDate
        updatedAt
        createdAt
      }
      note
      sources {
        id
        type
        name
        ident
        createdAt
      }
      removedAt
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const LS_updateCustomer = /* GraphQL */ `
  mutation LS_updateCustomer($customer: AWSJSON, $profileOnly: Boolean) {
    LS_updateCustomer(customer: $customer, profileOnly: $profileOnly) {
      id
      enterpriseId
      storeId
      locationId
      customerId
      provider
      providerCustomerId
      tier
      customerCreatedAt
      ident
      identType
      type
      name
      nameKeyword
      payments {
        id
        method
        pg
        number
        name
        expMonth
        expYear
        billingKey
        updatedAt
        createdAt
      }
      attributes {
        name
        type
        value
      }
      addresses {
        id
        main
        detail
        storeId
      }
      phone
      email
      birth
      agreements {
        privacy
        provider
        eula
        marketing
        updatedAt
        createdAt
      }
      ageGroup
      gender
      tags {
        tagId
        createdLocationId
      }
      point {
        used
        saved
        extra
        lastSavedAt
        lastTransactedAt
      }
      credit {
        deposit
        withdraw
        balance
        lastTransactedAt
      }
      status
      registeredAt
      salesDate
      campaignBlockedAt
      blacklistedAt
      isFavorite
      locations {
        id
        locationId
        isFavorite
        status
        note
        campaignBlockedAt
        blacklistedAt
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        subscriptions {
          id
          planId
          payStatus
          sessionId
          nextPayDate
          paidAt
          unsubscribedAt
          used
          lastUsedAt
          orderId
          unsubscribeRequestedAt
          startDate
          expireDate
          updatedAt
          createdAt
        }
        registeredAt
        salesDate
        updatedAt
        createdAt
      }
      subscriptions {
        id
        planId
        payStatus
        sessionId
        nextPayDate
        paidAt
        unsubscribedAt
        used
        lastUsedAt
        orderId
        unsubscribeRequestedAt
        startDate
        expireDate
        updatedAt
        createdAt
      }
      note
      sources {
        id
        type
        name
        ident
        createdAt
      }
      removedAt
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const LS_deleteCustomer = /* GraphQL */ `
  mutation LS_deleteCustomer($customerId: ID!) {
    LS_deleteCustomer(customerId: $customerId) {
      id
      enterpriseId
      storeId
      locationId
      customerId
      provider
      providerCustomerId
      tier
      customerCreatedAt
      ident
      identType
      type
      name
      nameKeyword
      payments {
        id
        method
        pg
        number
        name
        expMonth
        expYear
        billingKey
        updatedAt
        createdAt
      }
      attributes {
        name
        type
        value
      }
      addresses {
        id
        main
        detail
        storeId
      }
      phone
      email
      birth
      agreements {
        privacy
        provider
        eula
        marketing
        updatedAt
        createdAt
      }
      ageGroup
      gender
      tags {
        tagId
        createdLocationId
      }
      point {
        used
        saved
        extra
        lastSavedAt
        lastTransactedAt
      }
      credit {
        deposit
        withdraw
        balance
        lastTransactedAt
      }
      status
      registeredAt
      salesDate
      campaignBlockedAt
      blacklistedAt
      isFavorite
      locations {
        id
        locationId
        isFavorite
        status
        note
        campaignBlockedAt
        blacklistedAt
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        subscriptions {
          id
          planId
          payStatus
          sessionId
          nextPayDate
          paidAt
          unsubscribedAt
          used
          lastUsedAt
          orderId
          unsubscribeRequestedAt
          startDate
          expireDate
          updatedAt
          createdAt
        }
        registeredAt
        salesDate
        updatedAt
        createdAt
      }
      subscriptions {
        id
        planId
        payStatus
        sessionId
        nextPayDate
        paidAt
        unsubscribedAt
        used
        lastUsedAt
        orderId
        unsubscribeRequestedAt
        startDate
        expireDate
        updatedAt
        createdAt
      }
      note
      sources {
        id
        type
        name
        ident
        createdAt
      }
      removedAt
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const LS_updateCustomerBlacklist = /* GraphQL */ `
  mutation LS_updateCustomerBlacklist(
    $storeId: ID!
    $customerId: ID!
    $active: Boolean!
  ) {
    LS_updateCustomerBlacklist(
      storeId: $storeId
      customerId: $customerId
      active: $active
    ) {
      id
      enterpriseId
      storeId
      locationId
      customerId
      provider
      providerCustomerId
      tier
      customerCreatedAt
      ident
      identType
      type
      name
      nameKeyword
      payments {
        id
        method
        pg
        number
        name
        expMonth
        expYear
        billingKey
        updatedAt
        createdAt
      }
      attributes {
        name
        type
        value
      }
      addresses {
        id
        main
        detail
        storeId
      }
      phone
      email
      birth
      agreements {
        privacy
        provider
        eula
        marketing
        updatedAt
        createdAt
      }
      ageGroup
      gender
      tags {
        tagId
        createdLocationId
      }
      point {
        used
        saved
        extra
        lastSavedAt
        lastTransactedAt
      }
      credit {
        deposit
        withdraw
        balance
        lastTransactedAt
      }
      status
      registeredAt
      salesDate
      campaignBlockedAt
      blacklistedAt
      isFavorite
      locations {
        id
        locationId
        isFavorite
        status
        note
        campaignBlockedAt
        blacklistedAt
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        subscriptions {
          id
          planId
          payStatus
          sessionId
          nextPayDate
          paidAt
          unsubscribedAt
          used
          lastUsedAt
          orderId
          unsubscribeRequestedAt
          startDate
          expireDate
          updatedAt
          createdAt
        }
        registeredAt
        salesDate
        updatedAt
        createdAt
      }
      subscriptions {
        id
        planId
        payStatus
        sessionId
        nextPayDate
        paidAt
        unsubscribedAt
        used
        lastUsedAt
        orderId
        unsubscribeRequestedAt
        startDate
        expireDate
        updatedAt
        createdAt
      }
      note
      sources {
        id
        type
        name
        ident
        createdAt
      }
      removedAt
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const LS_updateCustomerTier = /* GraphQL */ `
  mutation LS_updateCustomerTier($storeId: ID!, $customerId: ID!, $tierId: ID) {
    LS_updateCustomerTier(
      storeId: $storeId
      customerId: $customerId
      tierId: $tierId
    ) {
      id
      enterpriseId
      storeId
      locationId
      customerId
      provider
      providerCustomerId
      tier
      customerCreatedAt
      ident
      identType
      type
      name
      nameKeyword
      payments {
        id
        method
        pg
        number
        name
        expMonth
        expYear
        billingKey
        updatedAt
        createdAt
      }
      attributes {
        name
        type
        value
      }
      addresses {
        id
        main
        detail
        storeId
      }
      phone
      email
      birth
      agreements {
        privacy
        provider
        eula
        marketing
        updatedAt
        createdAt
      }
      ageGroup
      gender
      tags {
        tagId
        createdLocationId
      }
      point {
        used
        saved
        extra
        lastSavedAt
        lastTransactedAt
      }
      credit {
        deposit
        withdraw
        balance
        lastTransactedAt
      }
      status
      registeredAt
      salesDate
      campaignBlockedAt
      blacklistedAt
      isFavorite
      locations {
        id
        locationId
        isFavorite
        status
        note
        campaignBlockedAt
        blacklistedAt
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        subscriptions {
          id
          planId
          payStatus
          sessionId
          nextPayDate
          paidAt
          unsubscribedAt
          used
          lastUsedAt
          orderId
          unsubscribeRequestedAt
          startDate
          expireDate
          updatedAt
          createdAt
        }
        registeredAt
        salesDate
        updatedAt
        createdAt
      }
      subscriptions {
        id
        planId
        payStatus
        sessionId
        nextPayDate
        paidAt
        unsubscribedAt
        used
        lastUsedAt
        orderId
        unsubscribeRequestedAt
        startDate
        expireDate
        updatedAt
        createdAt
      }
      note
      sources {
        id
        type
        name
        ident
        createdAt
      }
      removedAt
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const LS_createCustomerPayment = /* GraphQL */ `
  mutation LS_createCustomerPayment($payment: AWSJSON) {
    LS_createCustomerPayment(payment: $payment) {
      id
      method
      pg
      number
      name
      expMonth
      expYear
      billingKey
      updatedAt
      createdAt
    }
  }
`;
export const LS_deleteCustomerPayment = /* GraphQL */ `
  mutation LS_deleteCustomerPayment($paymentId: ID!) {
    LS_deleteCustomerPayment(paymentId: $paymentId) {
      id
      method
      pg
      number
      name
      expMonth
      expYear
      billingKey
      updatedAt
      createdAt
    }
  }
`;
export const LS_createSubscriptionPlan = /* GraphQL */ `
  mutation LS_createSubscriptionPlan($plan: AWSJSON) {
    LS_createSubscriptionPlan(plan: $plan) {
      id
      storeId
      productId
      skuId
      name
      recurringType
      recurringValue
      price
      quantity
      status
      image {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      gracePeriod
      subscriberCount
      locations {
        planId
        locationId
        available
        subscriberCount
      }
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
      product {
        id
        type
        enterpriseId
        storeId
        origin
        refer
        referId
        name
        nameMl {
          en
          zh
          ja
        }
        description
        descriptionMl {
          en
          zh
          ja
        }
        variablePrice
        attributes {
          name
          type
          value
        }
        thumbnail {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        skus {
          type
          id
          itemId
          refer
          referId
          attributes {
            name
            type
            value
          }
          name
          nameMl {
            en
            zh
            ja
          }
          price
          cost
          unit
          unitSuffix
          barcode
          currency
          model
          locationId
          futureLocationAvailable
          locationIds
          inventories {
            id
            quantity
            cost
            locationId
            unavailableQuantity
            inReadyQuantity
            outReadyQuantity
            expiryDate
            updatedAt
            createdAt
            locations {
              id
              quantity
            }
            attributes {
              name
              type
              value
            }
          }
          locations {
            id
            isActive
            available
            price
          }
          links {
            type
            origin
            originId
            originProductId
            updatedAt
            createdAt
          }
          stock
          stockAlert
          stockAlertQty
          stockTrack
          quantity
          limitQuantity
          isActive
        }
        trackStock
        taxId
        categories {
          items {
            id
            enterpriseId
            storeId
            productId
            categoryId
            category {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        classifications {
          items {
            id
            enterpriseId
            storeId
            productId
            classificationId
            classification {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        modifiers {
          items {
            id
            enterpriseId
            storeId
            productId
            modifierId
            position
            modifier {
              id
              type
              enterpriseId
              storeId
              name
              nameMl {
                en
                zh
                ja
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              origin
              orderType
              defaultSkuId
              coverage
              required
              requiredQuantity
              limitQuantity
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        locationIds
        updatedAt
        createdAt
      }
    }
  }
`;
export const LS_updateSubscriptionPlan = /* GraphQL */ `
  mutation LS_updateSubscriptionPlan($plan: AWSJSON) {
    LS_updateSubscriptionPlan(plan: $plan) {
      id
      storeId
      productId
      skuId
      name
      recurringType
      recurringValue
      price
      quantity
      status
      image {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      gracePeriod
      subscriberCount
      locations {
        planId
        locationId
        available
        subscriberCount
      }
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
      product {
        id
        type
        enterpriseId
        storeId
        origin
        refer
        referId
        name
        nameMl {
          en
          zh
          ja
        }
        description
        descriptionMl {
          en
          zh
          ja
        }
        variablePrice
        attributes {
          name
          type
          value
        }
        thumbnail {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        skus {
          type
          id
          itemId
          refer
          referId
          attributes {
            name
            type
            value
          }
          name
          nameMl {
            en
            zh
            ja
          }
          price
          cost
          unit
          unitSuffix
          barcode
          currency
          model
          locationId
          futureLocationAvailable
          locationIds
          inventories {
            id
            quantity
            cost
            locationId
            unavailableQuantity
            inReadyQuantity
            outReadyQuantity
            expiryDate
            updatedAt
            createdAt
            locations {
              id
              quantity
            }
            attributes {
              name
              type
              value
            }
          }
          locations {
            id
            isActive
            available
            price
          }
          links {
            type
            origin
            originId
            originProductId
            updatedAt
            createdAt
          }
          stock
          stockAlert
          stockAlertQty
          stockTrack
          quantity
          limitQuantity
          isActive
        }
        trackStock
        taxId
        categories {
          items {
            id
            enterpriseId
            storeId
            productId
            categoryId
            category {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        classifications {
          items {
            id
            enterpriseId
            storeId
            productId
            classificationId
            classification {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        modifiers {
          items {
            id
            enterpriseId
            storeId
            productId
            modifierId
            position
            modifier {
              id
              type
              enterpriseId
              storeId
              name
              nameMl {
                en
                zh
                ja
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              origin
              orderType
              defaultSkuId
              coverage
              required
              requiredQuantity
              limitQuantity
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        locationIds
        updatedAt
        createdAt
      }
    }
  }
`;
export const LS_deleteSubscriptionPlan = /* GraphQL */ `
  mutation LS_deleteSubscriptionPlan($planId: ID!) {
    LS_deleteSubscriptionPlan(planId: $planId) {
      id
      storeId
      productId
      skuId
      name
      recurringType
      recurringValue
      price
      quantity
      status
      image {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      gracePeriod
      subscriberCount
      locations {
        planId
        locationId
        available
        subscriberCount
      }
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
      product {
        id
        type
        enterpriseId
        storeId
        origin
        refer
        referId
        name
        nameMl {
          en
          zh
          ja
        }
        description
        descriptionMl {
          en
          zh
          ja
        }
        variablePrice
        attributes {
          name
          type
          value
        }
        thumbnail {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        skus {
          type
          id
          itemId
          refer
          referId
          attributes {
            name
            type
            value
          }
          name
          nameMl {
            en
            zh
            ja
          }
          price
          cost
          unit
          unitSuffix
          barcode
          currency
          model
          locationId
          futureLocationAvailable
          locationIds
          inventories {
            id
            quantity
            cost
            locationId
            unavailableQuantity
            inReadyQuantity
            outReadyQuantity
            expiryDate
            updatedAt
            createdAt
            locations {
              id
              quantity
            }
            attributes {
              name
              type
              value
            }
          }
          locations {
            id
            isActive
            available
            price
          }
          links {
            type
            origin
            originId
            originProductId
            updatedAt
            createdAt
          }
          stock
          stockAlert
          stockAlertQty
          stockTrack
          quantity
          limitQuantity
          isActive
        }
        trackStock
        taxId
        categories {
          items {
            id
            enterpriseId
            storeId
            productId
            categoryId
            category {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        classifications {
          items {
            id
            enterpriseId
            storeId
            productId
            classificationId
            classification {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        modifiers {
          items {
            id
            enterpriseId
            storeId
            productId
            modifierId
            position
            modifier {
              id
              type
              enterpriseId
              storeId
              name
              nameMl {
                en
                zh
                ja
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              origin
              orderType
              defaultSkuId
              coverage
              required
              requiredQuantity
              limitQuantity
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        locationIds
        updatedAt
        createdAt
      }
    }
  }
`;
export const LS_getMenuOrderEstimation = /* GraphQL */ `
  mutation LS_getMenuOrderEstimation(
    $storeId: ID!
    $locationId: ID!
    $quantity: Int!
    $recurringType: String
  ) {
    LS_getMenuOrderEstimation(
      storeId: $storeId
      locationId: $locationId
      quantity: $quantity
      recurringType: $recurringType
    )
  }
`;
export const LS_getMenuOrderStatus = /* GraphQL */ `
  mutation LS_getMenuOrderStatus($storeId: ID!, $locationId: ID!) {
    LS_getMenuOrderStatus(storeId: $storeId, locationId: $locationId)
  }
`;
export const LS_updateMenuOrderStatus = /* GraphQL */ `
  mutation LS_updateMenuOrderStatus(
    $storeId: ID!
    $locationId: ID!
    $quantity: Int!
    $recurringType: String
  ) {
    LS_updateMenuOrderStatus(
      storeId: $storeId
      locationId: $locationId
      quantity: $quantity
      recurringType: $recurringType
    )
  }
`;
export const LS_createSupplier = /* GraphQL */ `
  mutation LS_createSupplier($supplier: SupplierInput) {
    LS_createSupplier(supplier: $supplier) {
      id
      status
      storeId
      locationId
      public
      name
      phone
      fax
      email
      note
      address
      updatedAt
      createdAt
    }
  }
`;
export const LS_updateSupplier = /* GraphQL */ `
  mutation LS_updateSupplier($supplier: SupplierInput) {
    LS_updateSupplier(supplier: $supplier) {
      id
      status
      storeId
      locationId
      public
      name
      phone
      fax
      email
      note
      address
      updatedAt
      createdAt
    }
  }
`;
export const LS_deleteSupplier = /* GraphQL */ `
  mutation LS_deleteSupplier($supplierId: ID!) {
    LS_deleteSupplier(supplierId: $supplierId) {
      id
      status
      storeId
      locationId
      public
      name
      phone
      fax
      email
      note
      address
      updatedAt
      createdAt
    }
  }
`;
export const LS_createPurchaseOrder = /* GraphQL */ `
  mutation LS_createPurchaseOrder($purchaseOrder: PurchaseOrderInput) {
    LS_createPurchaseOrder(purchaseOrder: $purchaseOrder) {
      id
      number
      supplierId
      supplyingLocationId
      status
      supplierOrigin
      inventories {
        id
        inventoryId
        productId
        skuId
        quantity
        receivedQuantity
        waitingQuantity
        cancelledQuantity
        cost
        note
      }
      storeId
      locationId
      createdByEmployeeId
      createdLocationId
      note
      extraFees {
        id
        type
        value
        name
      }
      excludeVat
      expectDate
      confirmedAt
      receivedAt
      updatedAt
      createdAt
    }
  }
`;
export const LS_updatePurchaseOrder = /* GraphQL */ `
  mutation LS_updatePurchaseOrder($purchaseOrder: PurchaseOrderInput) {
    LS_updatePurchaseOrder(purchaseOrder: $purchaseOrder) {
      id
      number
      supplierId
      supplyingLocationId
      status
      supplierOrigin
      inventories {
        id
        inventoryId
        productId
        skuId
        quantity
        receivedQuantity
        waitingQuantity
        cancelledQuantity
        cost
        note
      }
      storeId
      locationId
      createdByEmployeeId
      createdLocationId
      note
      extraFees {
        id
        type
        value
        name
      }
      excludeVat
      expectDate
      confirmedAt
      receivedAt
      updatedAt
      createdAt
    }
  }
`;
export const LS_deletePurchaseOrder = /* GraphQL */ `
  mutation LS_deletePurchaseOrder($purchaseOrderId: ID!) {
    LS_deletePurchaseOrder(purchaseOrderId: $purchaseOrderId) {
      id
      number
      supplierId
      supplyingLocationId
      status
      supplierOrigin
      inventories {
        id
        inventoryId
        productId
        skuId
        quantity
        receivedQuantity
        waitingQuantity
        cancelledQuantity
        cost
        note
      }
      storeId
      locationId
      createdByEmployeeId
      createdLocationId
      note
      extraFees {
        id
        type
        value
        name
      }
      excludeVat
      expectDate
      confirmedAt
      receivedAt
      updatedAt
      createdAt
    }
  }
`;
export const LS_createCoupon = /* GraphQL */ `
  mutation LS_createCoupon($coupon: CouponInput) {
    LS_createCoupon(coupon: $coupon) {
      id
      storeId
      enterpriseId
      locationId
      activeLocationIds
      inactiveLocationIds
      publishableLocationIds
      provider
      providerCouponId
      name
      description
      availableOrigins
      code
      type
      method
      tranCount
      generateCount
      publishCount
      limitPublishCount
      duplicatePublish
      conditionType
      conditionQuantity
      conditionAmount
      conditionItems
      conditionItemsOperator
      conditionTimes {
        sun {
          open
          close
        }
        mon {
          open
          close
        }
        tue {
          open
          close
        }
        wed {
          open
          close
        }
        thu {
          open
          close
        }
        fri {
          open
          close
        }
        sat {
          open
          close
        }
      }
      conditionTier
      discountMethod
      discountValue
      applyScope
      applyItemId
      applyCandidateItemIds
      referId
      validDateType
      validValue
      image {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      notice
      pointSavable
      startedAt
      endedAt
      updatedAt
      createdAt
      transactions {
        items {
          id
          usedAt
          state
          amount
          quantity
          refundedAt
          orderId
          customerId
          couponTransactionCustomerId
          updatedAt
          createdAt
          expiredDate
          barcode
          generateLocationId
          generatedAt
          couponTransactionsId
          enterpriseId
          storeId
          locationId
          activeLocationIds
          inactiveLocationIds
          publishableLocationIds
          provider
          providerCouponId
          providerTransactionId
          providerCustomerId
          name
          code
          type
          method
          conditionType
          conditionQuantity
          conditionAmount
          conditionItems
          conditionTimes {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          conditionTier
          discountMethod
          discountValue
          applyScope
          applyItemId
          applyCandidateItemIds
          campaignId
          startedAt
          endedAt
        }
        nextToken
      }
    }
  }
`;
export const LS_updateCoupon = /* GraphQL */ `
  mutation LS_updateCoupon($coupon: CouponInput) {
    LS_updateCoupon(coupon: $coupon) {
      id
      storeId
      enterpriseId
      locationId
      activeLocationIds
      inactiveLocationIds
      publishableLocationIds
      provider
      providerCouponId
      name
      description
      availableOrigins
      code
      type
      method
      tranCount
      generateCount
      publishCount
      limitPublishCount
      duplicatePublish
      conditionType
      conditionQuantity
      conditionAmount
      conditionItems
      conditionItemsOperator
      conditionTimes {
        sun {
          open
          close
        }
        mon {
          open
          close
        }
        tue {
          open
          close
        }
        wed {
          open
          close
        }
        thu {
          open
          close
        }
        fri {
          open
          close
        }
        sat {
          open
          close
        }
      }
      conditionTier
      discountMethod
      discountValue
      applyScope
      applyItemId
      applyCandidateItemIds
      referId
      validDateType
      validValue
      image {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      notice
      pointSavable
      startedAt
      endedAt
      updatedAt
      createdAt
      transactions {
        items {
          id
          usedAt
          state
          amount
          quantity
          refundedAt
          orderId
          customerId
          couponTransactionCustomerId
          updatedAt
          createdAt
          expiredDate
          barcode
          generateLocationId
          generatedAt
          couponTransactionsId
          enterpriseId
          storeId
          locationId
          activeLocationIds
          inactiveLocationIds
          publishableLocationIds
          provider
          providerCouponId
          providerTransactionId
          providerCustomerId
          name
          code
          type
          method
          conditionType
          conditionQuantity
          conditionAmount
          conditionItems
          conditionTimes {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          conditionTier
          discountMethod
          discountValue
          applyScope
          applyItemId
          applyCandidateItemIds
          campaignId
          startedAt
          endedAt
        }
        nextToken
      }
    }
  }
`;
export const LS_deleteCoupon = /* GraphQL */ `
  mutation LS_deleteCoupon($couponId: ID!) {
    LS_deleteCoupon(couponId: $couponId) {
      id
      storeId
      enterpriseId
      locationId
      activeLocationIds
      inactiveLocationIds
      publishableLocationIds
      provider
      providerCouponId
      name
      description
      availableOrigins
      code
      type
      method
      tranCount
      generateCount
      publishCount
      limitPublishCount
      duplicatePublish
      conditionType
      conditionQuantity
      conditionAmount
      conditionItems
      conditionItemsOperator
      conditionTimes {
        sun {
          open
          close
        }
        mon {
          open
          close
        }
        tue {
          open
          close
        }
        wed {
          open
          close
        }
        thu {
          open
          close
        }
        fri {
          open
          close
        }
        sat {
          open
          close
        }
      }
      conditionTier
      discountMethod
      discountValue
      applyScope
      applyItemId
      applyCandidateItemIds
      referId
      validDateType
      validValue
      image {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      notice
      pointSavable
      startedAt
      endedAt
      updatedAt
      createdAt
      transactions {
        items {
          id
          usedAt
          state
          amount
          quantity
          refundedAt
          orderId
          customerId
          couponTransactionCustomerId
          updatedAt
          createdAt
          expiredDate
          barcode
          generateLocationId
          generatedAt
          couponTransactionsId
          enterpriseId
          storeId
          locationId
          activeLocationIds
          inactiveLocationIds
          publishableLocationIds
          provider
          providerCouponId
          providerTransactionId
          providerCustomerId
          name
          code
          type
          method
          conditionType
          conditionQuantity
          conditionAmount
          conditionItems
          conditionTimes {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          conditionTier
          discountMethod
          discountValue
          applyScope
          applyItemId
          applyCandidateItemIds
          campaignId
          startedAt
          endedAt
        }
        nextToken
      }
    }
  }
`;
export const LS_createBarcodeCouponTransactions = /* GraphQL */ `
  mutation LS_createBarcodeCouponTransactions(
    $transactions: [CouponTransactionInput]
  ) {
    LS_createBarcodeCouponTransactions(transactions: $transactions) {
      id
      usedAt
      state
      amount
      quantity
      refundedAt
      orderId
      customerId
      couponTransactionCustomerId
      updatedAt
      createdAt
      expiredDate
      barcode
      generateLocationId
      generatedAt
      couponTransactionsId
      enterpriseId
      storeId
      locationId
      activeLocationIds
      inactiveLocationIds
      publishableLocationIds
      provider
      providerCouponId
      providerTransactionId
      providerCustomerId
      name
      code
      type
      method
      conditionType
      conditionQuantity
      conditionAmount
      conditionItems
      conditionTimes {
        sun {
          open
          close
        }
        mon {
          open
          close
        }
        tue {
          open
          close
        }
        wed {
          open
          close
        }
        thu {
          open
          close
        }
        fri {
          open
          close
        }
        sat {
          open
          close
        }
      }
      conditionTier
      discountMethod
      discountValue
      applyScope
      applyItemId
      applyCandidateItemIds
      campaignId
      startedAt
      endedAt
    }
  }
`;
export const LS_createWaiting = /* GraphQL */ `
  mutation LS_createWaiting($waiting: WaitingInput) {
    LS_createWaiting(waiting: $waiting) {
      id
      storeId
      locationId
      status
      cancelledAt
      enteredAt
      readyAt
      requestedAt
      language
      number
      phone
      email
      visitors
      counter
      msgFailReason
      optionItems {
        optionId
        optionName
        optionItemId
        optionItemName
        quantity
      }
      agreements {
        privacy
        provider
        eula
        marketing
        updatedAt
        createdAt
      }
      salesDate
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const LS_updateWaiting = /* GraphQL */ `
  mutation LS_updateWaiting($waiting: WaitingInput) {
    LS_updateWaiting(waiting: $waiting) {
      id
      storeId
      locationId
      status
      cancelledAt
      enteredAt
      readyAt
      requestedAt
      language
      number
      phone
      email
      visitors
      counter
      msgFailReason
      optionItems {
        optionId
        optionName
        optionItemId
        optionItemName
        quantity
      }
      agreements {
        privacy
        provider
        eula
        marketing
        updatedAt
        createdAt
      }
      salesDate
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const LS_updateWaitings = /* GraphQL */ `
  mutation LS_updateWaitings($waitings: [WaitingInput]) {
    LS_updateWaitings(waitings: $waitings) {
      id
      storeId
      locationId
      status
      cancelledAt
      enteredAt
      readyAt
      requestedAt
      language
      number
      phone
      email
      visitors
      counter
      msgFailReason
      optionItems {
        optionId
        optionName
        optionItemId
        optionItemName
        quantity
      }
      agreements {
        privacy
        provider
        eula
        marketing
        updatedAt
        createdAt
      }
      salesDate
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const LS_getWaitingStatus = /* GraphQL */ `
  mutation LS_getWaitingStatus(
    $storeId: ID!
    $locationId: ID!
    $salesDate: AWSDateTime
    $waitingId: ID
  ) {
    LS_getWaitingStatus(
      storeId: $storeId
      locationId: $locationId
      salesDate: $salesDate
      waitingId: $waitingId
    )
  }
`;
export const LS_chargeStoreCredit = /* GraphQL */ `
  mutation LS_chargeStoreCredit(
    $storeId: ID!
    $amount: Int!
    $quantity: Int
    $locationId: ID
  ) {
    LS_chargeStoreCredit(
      storeId: $storeId
      amount: $amount
      quantity: $quantity
      locationId: $locationId
    ) {
      id
      date
      storeId
      locationId
      name
      type
      status
      failReason
      discounts {
        name
        amount
        transactionId
      }
      amount
      currency
      paidAt
      refundedAt
      transaction {
        cardName
        cardNumber
        cardQuota
        pg
        transactionId
        amount
        currency
        method
        purchasedAt
        requestedAt
        revokedAt
        status
        statusEn
        statusKo
        receiptId
        receiptUrl
      }
      items {
        type
        name
        amount
        details {
          type
          name
          amount
          basePrice
          unitPrice
          usage
          rate
          referId
          period
        }
      }
      note
      updatedAt
      createdAt
    }
  }
`;
export const LS_getStoreCreditStatus = /* GraphQL */ `
  mutation LS_getStoreCreditStatus($storeId: ID!, $locationId: ID) {
    LS_getStoreCreditStatus(storeId: $storeId, locationId: $locationId)
  }
`;
export const LS_getStoreCreditTransactions = /* GraphQL */ `
  mutation LS_getStoreCreditTransactions(
    $storeId: ID!
    $page: Int!
    $size: Int!
    $locationId: ID
    $filters: AWSJSON
  ) {
    LS_getStoreCreditTransactions(
      storeId: $storeId
      page: $page
      size: $size
      locationId: $locationId
      filters: $filters
    ) {
      items {
        id
        type
        origin
        method
        storeId
        locationId
        referId
        name
        quantity
        price
        amount
        currency
        createdAt
        updatedAt
      }
      count
    }
  }
`;
export const LS_requestKakaoProfileToken = /* GraphQL */ `
  mutation LS_requestKakaoProfileToken($phone: String!, $channelId: String!) {
    LS_requestKakaoProfileToken(phone: $phone, channelId: $channelId)
  }
`;
export const LS_registerKakaoProfile = /* GraphQL */ `
  mutation LS_registerKakaoProfile(
    $token: String!
    $phone: String!
    $channelId: String!
    $categoryCode: String!
  ) {
    LS_registerKakaoProfile(
      token: $token
      phone: $phone
      channelId: $channelId
      categoryCode: $categoryCode
    )
  }
`;
export const LS_registerProvider = /* GraphQL */ `
  mutation LS_registerProvider(
    $storeId: ID!
    $provider: StoreProvider!
    $providerId: String!
  ) {
    LS_registerProvider(
      storeId: $storeId
      provider: $provider
      providerId: $providerId
    )
  }
`;
export const LS_getLoyaltyConfig = /* GraphQL */ `
  mutation LS_getLoyaltyConfig($storeId: ID!, $provider: StoreProvider) {
    LS_getLoyaltyConfig(storeId: $storeId, provider: $provider) {
      status
      provider
      sharePolicy
      type
      grade
      conditionType
      conditionSubType
      condition {
        minAmount
        perAmount
        minTime
        rate
        expirationPeriod
        excludeProdIds
        discountPointSavable
      }
      conditionItems {
        id
        itemId
        name
        parentId
        rate
        point
        amount
      }
      conditionTiers {
        id
        itemId
        name
        parentId
        rate
        point
        amount
      }
      rewardType
      rewardSubType
      reward {
        requirePoint
        requireOrderAmount
        minUsePoint
        minRequireRate
        maxUsePoint
      }
      rewardItems {
        id
        itemId
        name
        parentId
        rate
        point
        amount
      }
      rewardRates {
        id
        itemId
        name
        parentId
        rate
        point
        amount
      }
      rewardAmounts {
        id
        itemId
        name
        parentId
        rate
        point
        amount
      }
      rewardCashs {
        id
        itemId
        name
        parentId
        rate
        point
        amount
      }
      incentives {
        fulfill
        fulfillPoint
        fulfillCampaignId
        birth
        birthPoint
        birthCampaignId
        birthItem {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
      }
      notification {
        register
        registerCampainId
        save
        saveCampaignId
        use
        useCampaignId
        expire
        expireCampaignId
      }
    }
  }
`;
export const LS_getProviderCustomers = /* GraphQL */ `
  mutation LS_getProviderCustomers(
    $storeId: ID!
    $locationId: ID
    $filters: AWSJSON
  ) {
    LS_getProviderCustomers(
      storeId: $storeId
      locationId: $locationId
      filters: $filters
    )
  }
`;
export const LS_getProviderCustomer = /* GraphQL */ `
  mutation LS_getProviderCustomer(
    $storeId: ID!
    $customerId: ID!
    $locationId: ID
  ) {
    LS_getProviderCustomer(
      storeId: $storeId
      customerId: $customerId
      locationId: $locationId
    )
  }
`;
export const LS_getProviderCustomerGroups = /* GraphQL */ `
  mutation LS_getProviderCustomerGroups($storeId: ID!) {
    LS_getProviderCustomerGroups(storeId: $storeId)
  }
`;
export const LS_getProviderOrders = /* GraphQL */ `
  mutation LS_getProviderOrders(
    $storeId: ID!
    $page: Int
    $size: Int
    $filters: AWSJSON
  ) {
    LS_getProviderOrders(
      storeId: $storeId
      page: $page
      size: $size
      filters: $filters
    )
  }
`;
export const LS_getProviderCoupons = /* GraphQL */ `
  mutation LS_getProviderCoupons($storeId: ID!, $couponId: ID) {
    LS_getProviderCoupons(storeId: $storeId, couponId: $couponId)
  }
`;
export const LS_getProviderCouponTransactions = /* GraphQL */ `
  mutation LS_getProviderCouponTransactions($storeId: ID!, $customerId: ID) {
    LS_getProviderCouponTransactions(storeId: $storeId, customerId: $customerId)
  }
`;
export const LS_getProviderPointTransactions = /* GraphQL */ `
  mutation LS_getProviderPointTransactions(
    $storeId: ID!
    $start: AWSDateTime!
    $end: AWSDateTime!
    $customerId: ID
  ) {
    LS_getProviderPointTransactions(
      storeId: $storeId
      start: $start
      end: $end
      customerId: $customerId
    )
  }
`;
export const LS_syncProviderOrders = /* GraphQL */ `
  mutation LS_syncProviderOrders($storeId: ID!, $start: AWSDateTime) {
    LS_syncProviderOrders(storeId: $storeId, start: $start)
  }
`;
export const LS_requestProviderCouponAndPoint = /* GraphQL */ `
  mutation LS_requestProviderCouponAndPoint(
    $storeId: ID!
    $customerId: ID!
    $amount: Int
    $couponIds: [ID]
  ) {
    LS_requestProviderCouponAndPoint(
      storeId: $storeId
      customerId: $customerId
      amount: $amount
      couponIds: $couponIds
    )
  }
`;
export const LS_approveProviderRequest = /* GraphQL */ `
  mutation LS_approveProviderRequest(
    $storeId: ID!
    $customerId: ID!
    $requestId: ID!
  ) {
    LS_approveProviderRequest(
      storeId: $storeId
      customerId: $customerId
      requestId: $requestId
    )
  }
`;
export const LS_cancelProviderRequest = /* GraphQL */ `
  mutation LS_cancelProviderRequest(
    $storeId: ID!
    $customerId: ID!
    $requestId: ID!
  ) {
    LS_cancelProviderRequest(
      storeId: $storeId
      customerId: $customerId
      requestId: $requestId
    )
  }
`;
export const LS_getProviderProducts = /* GraphQL */ `
  mutation LS_getProviderProducts(
    $storeId: ID!
    $searchType: String
    $search: String
    $size: Int
    $since: String
    $filters: AWSJSON
  ) {
    LS_getProviderProducts(
      storeId: $storeId
      searchType: $searchType
      search: $search
      size: $size
      since: $since
      filters: $filters
    )
  }
`;
export const LS_linkProviderProducts = /* GraphQL */ `
  mutation LS_linkProviderProducts(
    $storeId: ID!
    $options: ItemLinkOptionsInput
    $pairs: [ItemLinkPairInput]
    $excludeKeys: [String]
    $employeeId: ID
  ) {
    LS_linkProviderProducts(
      storeId: $storeId
      options: $options
      pairs: $pairs
      excludeKeys: $excludeKeys
      employeeId: $employeeId
    )
  }
`;
export const LS_syncProviderProducts = /* GraphQL */ `
  mutation LS_syncProviderProducts(
    $storeId: ID!
    $skuIds: [ID]
    $excludeKeys: [String]
  ) {
    LS_syncProviderProducts(
      storeId: $storeId
      skuIds: $skuIds
      excludeKeys: $excludeKeys
    ) {
      id
      itemId
      type
      enterpriseId
      storeId
      name
      nameMl {
        en
        zh
        ja
      }
      attributes {
        name
        type
        value
      }
      displayName
      origin
      refer
      referId
      depth
      parentId
      description
      descriptionMl {
        en
        zh
        ja
      }
      thumbnail {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      variablePrice
      skus {
        type
        id
        itemId
        refer
        referId
        attributes {
          name
          type
          value
        }
        name
        nameMl {
          en
          zh
          ja
        }
        price
        cost
        unit
        unitSuffix
        barcode
        currency
        model
        locationId
        futureLocationAvailable
        locationIds
        inventories {
          id
          quantity
          cost
          locationId
          unavailableQuantity
          inReadyQuantity
          outReadyQuantity
          expiryDate
          updatedAt
          createdAt
          locations {
            id
            quantity
          }
          attributes {
            name
            type
            value
          }
        }
        locations {
          id
          isActive
          available
          price
        }
        links {
          type
          origin
          originId
          originProductId
          updatedAt
          createdAt
        }
        stock
        stockAlert
        stockAlertQty
        stockTrack
        quantity
        limitQuantity
        isActive
      }
      trackStock
      taxId
      ordering
      categoryIds
      classificationIds
      modifierIds
      productIds
      locationIds
      orderType
      defaultSkuId
      coverage
      required
      requiredQuantity
      limitQuantity
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const LS_unlinkProviderProducts = /* GraphQL */ `
  mutation LS_unlinkProviderProducts($storeId: ID!, $skuIds: [ID]) {
    LS_unlinkProviderProducts(storeId: $storeId, skuIds: $skuIds) {
      id
      itemId
      type
      enterpriseId
      storeId
      name
      nameMl {
        en
        zh
        ja
      }
      attributes {
        name
        type
        value
      }
      displayName
      origin
      refer
      referId
      depth
      parentId
      description
      descriptionMl {
        en
        zh
        ja
      }
      thumbnail {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      variablePrice
      skus {
        type
        id
        itemId
        refer
        referId
        attributes {
          name
          type
          value
        }
        name
        nameMl {
          en
          zh
          ja
        }
        price
        cost
        unit
        unitSuffix
        barcode
        currency
        model
        locationId
        futureLocationAvailable
        locationIds
        inventories {
          id
          quantity
          cost
          locationId
          unavailableQuantity
          inReadyQuantity
          outReadyQuantity
          expiryDate
          updatedAt
          createdAt
          locations {
            id
            quantity
          }
          attributes {
            name
            type
            value
          }
        }
        locations {
          id
          isActive
          available
          price
        }
        links {
          type
          origin
          originId
          originProductId
          updatedAt
          createdAt
        }
        stock
        stockAlert
        stockAlertQty
        stockTrack
        quantity
        limitQuantity
        isActive
      }
      trackStock
      taxId
      ordering
      categoryIds
      classificationIds
      modifierIds
      productIds
      locationIds
      orderType
      defaultSkuId
      coverage
      required
      requiredQuantity
      limitQuantity
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const LS_syncProviderInventories = /* GraphQL */ `
  mutation LS_syncProviderInventories($storeId: ID!, $employeeId: ID) {
    LS_syncProviderInventories(storeId: $storeId, employeeId: $employeeId)
  }
`;
export const LS_updateInventoryHistory = /* GraphQL */ `
  mutation LS_updateInventoryHistory(
    $historyId: ID!
    $quantity: Int!
    $cost: Int
    $expiryDate: AWSDateTime
  ) {
    LS_updateInventoryHistory(
      historyId: $historyId
      quantity: $quantity
      cost: $cost
      expiryDate: $expiryDate
    ) {
      id
      createdAt
      enterpriseId
      storeId
      productId
      skuId
      locationId
      employeeId
      inventoryId
      referId
      origin
      groupId
      groupHistoryCount
      purchaseOrderId
      totalCost
      cost
      expiryDate
      note
      reason
      transactions {
        parentId
        reason
        quantity
        prevQuantity
        createdAt
      }
      quantity
      prevQuantity
      currQuantity
      readyQuantity
      updatedAt
    }
  }
`;
export const LS_cancelAdjustments = /* GraphQL */ `
  mutation LS_cancelAdjustments($historyIds: [ID]) {
    LS_cancelAdjustments(historyIds: $historyIds) {
      id
      itemId
      type
      enterpriseId
      storeId
      name
      nameMl {
        en
        zh
        ja
      }
      attributes {
        name
        type
        value
      }
      displayName
      origin
      refer
      referId
      depth
      parentId
      description
      descriptionMl {
        en
        zh
        ja
      }
      thumbnail {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      variablePrice
      skus {
        type
        id
        itemId
        refer
        referId
        attributes {
          name
          type
          value
        }
        name
        nameMl {
          en
          zh
          ja
        }
        price
        cost
        unit
        unitSuffix
        barcode
        currency
        model
        locationId
        futureLocationAvailable
        locationIds
        inventories {
          id
          quantity
          cost
          locationId
          unavailableQuantity
          inReadyQuantity
          outReadyQuantity
          expiryDate
          updatedAt
          createdAt
          locations {
            id
            quantity
          }
          attributes {
            name
            type
            value
          }
        }
        locations {
          id
          isActive
          available
          price
        }
        links {
          type
          origin
          originId
          originProductId
          updatedAt
          createdAt
        }
        stock
        stockAlert
        stockAlertQty
        stockTrack
        quantity
        limitQuantity
        isActive
      }
      trackStock
      taxId
      ordering
      categoryIds
      classificationIds
      modifierIds
      productIds
      locationIds
      orderType
      defaultSkuId
      coverage
      required
      requiredQuantity
      limitQuantity
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const LS_adjustInventories = /* GraphQL */ `
  mutation LS_adjustInventories($adjustments: [InventoryAdjustmentInput]) {
    LS_adjustInventories(adjustments: $adjustments) {
      id
      itemId
      type
      enterpriseId
      storeId
      name
      nameMl {
        en
        zh
        ja
      }
      attributes {
        name
        type
        value
      }
      displayName
      origin
      refer
      referId
      depth
      parentId
      description
      descriptionMl {
        en
        zh
        ja
      }
      thumbnail {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      variablePrice
      skus {
        type
        id
        itemId
        refer
        referId
        attributes {
          name
          type
          value
        }
        name
        nameMl {
          en
          zh
          ja
        }
        price
        cost
        unit
        unitSuffix
        barcode
        currency
        model
        locationId
        futureLocationAvailable
        locationIds
        inventories {
          id
          quantity
          cost
          locationId
          unavailableQuantity
          inReadyQuantity
          outReadyQuantity
          expiryDate
          updatedAt
          createdAt
          locations {
            id
            quantity
          }
          attributes {
            name
            type
            value
          }
        }
        locations {
          id
          isActive
          available
          price
        }
        links {
          type
          origin
          originId
          originProductId
          updatedAt
          createdAt
        }
        stock
        stockAlert
        stockAlertQty
        stockTrack
        quantity
        limitQuantity
        isActive
      }
      trackStock
      taxId
      ordering
      categoryIds
      classificationIds
      modifierIds
      productIds
      locationIds
      orderType
      defaultSkuId
      coverage
      required
      requiredQuantity
      limitQuantity
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const LS_createInventoryCountHistory = /* GraphQL */ `
  mutation LS_createInventoryCountHistory(
    $history: InventoryCountHistoryInput
  ) {
    LS_createInventoryCountHistory(history: $history) {
      id
      storeId
      locationId
      items {
        productId
        skuId
        prevQuantity
        currQuantity
        totalCost
        inventoryHistoryId
      }
      employeeId
      note
      totalCost
      createdAt
      updatedAt
    }
  }
`;
export const LS_createInventoryTransferHistory = /* GraphQL */ `
  mutation LS_createInventoryTransferHistory(
    $history: InventoryTransferHistoryInput
  ) {
    LS_createInventoryTransferHistory(history: $history) {
      id
      storeId
      status
      fromLocationId
      toLocationId
      employeeId
      items {
        productId
        skuId
        quantity
        receivedQuantity
        totalCost
        fromInventoryHistoryId
        toInventoryHistoryId
      }
      note
      totalCost
      createdAt
      updatedAt
    }
  }
`;
export const LS_updateInventoryTransferHistory = /* GraphQL */ `
  mutation LS_updateInventoryTransferHistory(
    $history: InventoryTransferHistoryInput
  ) {
    LS_updateInventoryTransferHistory(history: $history) {
      id
      storeId
      status
      fromLocationId
      toLocationId
      employeeId
      items {
        productId
        skuId
        quantity
        receivedQuantity
        totalCost
        fromInventoryHistoryId
        toInventoryHistoryId
      }
      note
      totalCost
      createdAt
      updatedAt
    }
  }
`;
export const LS_deleteInventoryTransferHistory = /* GraphQL */ `
  mutation LS_deleteInventoryTransferHistory($historyId: ID) {
    LS_deleteInventoryTransferHistory(historyId: $historyId) {
      id
      storeId
      status
      fromLocationId
      toLocationId
      employeeId
      items {
        productId
        skuId
        quantity
        receivedQuantity
        totalCost
        fromInventoryHistoryId
        toInventoryHistoryId
      }
      note
      totalCost
      createdAt
      updatedAt
    }
  }
`;
export const createSalesOrder = /* GraphQL */ `
  mutation CreateSalesOrder($input: CreateSalesOrderInput!) {
    createSalesOrder(input: $input) {
      id
      name
      state
      method
      address1
      address2
      amount
      tax
      origin
      phone
      email
      itemName
      returnUrl
      payment
      items {
        item
        qty
      }
      orderId
      updatedAt
      createdAt
    }
  }
`;
export const updateSalesOrder = /* GraphQL */ `
  mutation UpdateSalesOrder($input: UpdateSalesOrderInput!) {
    updateSalesOrder(input: $input) {
      id
      name
      state
      method
      address1
      address2
      amount
      tax
      origin
      phone
      email
      itemName
      returnUrl
      payment
      items {
        item
        qty
      }
      orderId
      updatedAt
      createdAt
    }
  }
`;
export const deleteSalesOrder = /* GraphQL */ `
  mutation DeleteSalesOrder($input: DeleteSalesOrderInput!) {
    deleteSalesOrder(input: $input) {
      id
      name
      state
      method
      address1
      address2
      amount
      tax
      origin
      phone
      email
      itemName
      returnUrl
      payment
      items {
        item
        qty
      }
      orderId
      updatedAt
      createdAt
    }
  }
`;
export const createEnvironment = /* GraphQL */ `
  mutation CreateEnvironment($input: CreateEnvironmentInput!) {
    createEnvironment(input: $input) {
      id
      appVersion
      pcVersion
      webVersion
      inventoryDeployedAt
      credits {
        quantity
        amount
        discount
      }
      noticeDate
      updatedAt
      createdAt
    }
  }
`;
export const updateEnvironment = /* GraphQL */ `
  mutation UpdateEnvironment($input: UpdateEnvironmentInput!) {
    updateEnvironment(input: $input) {
      id
      appVersion
      pcVersion
      webVersion
      inventoryDeployedAt
      credits {
        quantity
        amount
        discount
      }
      noticeDate
      updatedAt
      createdAt
    }
  }
`;
export const deleteEnvironment = /* GraphQL */ `
  mutation DeleteEnvironment($input: DeleteEnvironmentInput!) {
    deleteEnvironment(input: $input) {
      id
      appVersion
      pcVersion
      webVersion
      inventoryDeployedAt
      credits {
        quantity
        amount
        discount
      }
      noticeDate
      updatedAt
      createdAt
    }
  }
`;
export const createLocationSettlement = /* GraphQL */ `
  mutation CreateLocationSettlement($input: CreateLocationSettlementInput!) {
    createLocationSettlement(input: $input) {
      locationId
      transactionDate
      storeId
      status
      amount
      amountSettled
      transactionAmount
      fee
      carryOrverAmount
      items {
        category
        status
        amount
        amountSettled
        fee
        brokerageRate
        brokerageFee
        cardRate
        cardFee
        cardAmount
        transactions {
          orderId
          amount
          type
          ident
          name
          approvalDate
          approvalNumber
          createdAt
        }
        carryOrverAmount
        carries {
          transactionDate
          amount
        }
        request
        response
        result
        requestSettleDate
        estimateDate
        settledAt
      }
      tryCount
      estimateDate
      requestSettleDate
      settledAt
      updatedAt
      createdAt
    }
  }
`;
export const updateLocationSettlement = /* GraphQL */ `
  mutation UpdateLocationSettlement($input: UpdateLocationSettlementInput!) {
    updateLocationSettlement(input: $input) {
      locationId
      transactionDate
      storeId
      status
      amount
      amountSettled
      transactionAmount
      fee
      carryOrverAmount
      items {
        category
        status
        amount
        amountSettled
        fee
        brokerageRate
        brokerageFee
        cardRate
        cardFee
        cardAmount
        transactions {
          orderId
          amount
          type
          ident
          name
          approvalDate
          approvalNumber
          createdAt
        }
        carryOrverAmount
        carries {
          transactionDate
          amount
        }
        request
        response
        result
        requestSettleDate
        estimateDate
        settledAt
      }
      tryCount
      estimateDate
      requestSettleDate
      settledAt
      updatedAt
      createdAt
    }
  }
`;
export const deleteLocationSettlement = /* GraphQL */ `
  mutation DeleteLocationSettlement($input: DeleteLocationSettlementInput!) {
    deleteLocationSettlement(input: $input) {
      locationId
      transactionDate
      storeId
      status
      amount
      amountSettled
      transactionAmount
      fee
      carryOrverAmount
      items {
        category
        status
        amount
        amountSettled
        fee
        brokerageRate
        brokerageFee
        cardRate
        cardFee
        cardAmount
        transactions {
          orderId
          amount
          type
          ident
          name
          approvalDate
          approvalNumber
          createdAt
        }
        carryOrverAmount
        carries {
          transactionDate
          amount
        }
        request
        response
        result
        requestSettleDate
        estimateDate
        settledAt
      }
      tryCount
      estimateDate
      requestSettleDate
      settledAt
      updatedAt
      createdAt
    }
  }
`;
export const createSettlement = /* GraphQL */ `
  mutation CreateSettlement($input: CreateSettlementInput!) {
    createSettlement(input: $input) {
      storeId
      transactedAt
      status
      enterpriseId
      locationId
      amount
      amountSettled
      estimateDate
      settledAt
      transactionAmount
      tryCount
      requestSettleDate
      request
      response
      result
      brokerageFee
      cardBrokerageFee
      cardBrokerageRate
      carryOrverAmount
      creditAmount
      creditBrokerageRate
      creditBrokerageFee
      billingStatus
      amountBilled
      billedAt
      billingHistoryId
      updatedAt
      createdAt
    }
  }
`;
export const updateSettlement = /* GraphQL */ `
  mutation UpdateSettlement($input: UpdateSettlementInput!) {
    updateSettlement(input: $input) {
      storeId
      transactedAt
      status
      enterpriseId
      locationId
      amount
      amountSettled
      estimateDate
      settledAt
      transactionAmount
      tryCount
      requestSettleDate
      request
      response
      result
      brokerageFee
      cardBrokerageFee
      cardBrokerageRate
      carryOrverAmount
      creditAmount
      creditBrokerageRate
      creditBrokerageFee
      billingStatus
      amountBilled
      billedAt
      billingHistoryId
      updatedAt
      createdAt
    }
  }
`;
export const deleteSettlement = /* GraphQL */ `
  mutation DeleteSettlement($input: DeleteSettlementInput!) {
    deleteSettlement(input: $input) {
      storeId
      transactedAt
      status
      enterpriseId
      locationId
      amount
      amountSettled
      estimateDate
      settledAt
      transactionAmount
      tryCount
      requestSettleDate
      request
      response
      result
      brokerageFee
      cardBrokerageFee
      cardBrokerageRate
      carryOrverAmount
      creditAmount
      creditBrokerageRate
      creditBrokerageFee
      billingStatus
      amountBilled
      billedAt
      billingHistoryId
      updatedAt
      createdAt
    }
  }
`;
export const createRequestHistory = /* GraphQL */ `
  mutation CreateRequestHistory($input: CreateRequestHistoryInput!) {
    createRequestHistory(input: $input) {
      id
      headers
      ident
      audience
      path
      method
      body
      params
      updatedAt
      createdAt
    }
  }
`;
export const updateRequestHistory = /* GraphQL */ `
  mutation UpdateRequestHistory($input: UpdateRequestHistoryInput!) {
    updateRequestHistory(input: $input) {
      id
      headers
      ident
      audience
      path
      method
      body
      params
      updatedAt
      createdAt
    }
  }
`;
export const deleteRequestHistory = /* GraphQL */ `
  mutation DeleteRequestHistory($input: DeleteRequestHistoryInput!) {
    deleteRequestHistory(input: $input) {
      id
      headers
      ident
      audience
      path
      method
      body
      params
      updatedAt
      createdAt
    }
  }
`;
export const createWebhookHistory = /* GraphQL */ `
  mutation CreateWebhookHistory($input: CreateWebhookHistoryInput!) {
    createWebhookHistory(input: $input) {
      id
      storeId
      event
      url
      payload
      referId
      responseCode
      retryCount
      errorMessage
      updatedAt
      createdAt
    }
  }
`;
export const updateWebhookHistory = /* GraphQL */ `
  mutation UpdateWebhookHistory($input: UpdateWebhookHistoryInput!) {
    updateWebhookHistory(input: $input) {
      id
      storeId
      event
      url
      payload
      referId
      responseCode
      retryCount
      errorMessage
      updatedAt
      createdAt
    }
  }
`;
export const deleteWebhookHistory = /* GraphQL */ `
  mutation DeleteWebhookHistory($input: DeleteWebhookHistoryInput!) {
    deleteWebhookHistory(input: $input) {
      id
      storeId
      event
      url
      payload
      referId
      responseCode
      retryCount
      errorMessage
      updatedAt
      createdAt
    }
  }
`;
export const createOrderHistory = /* GraphQL */ `
  mutation CreateOrderHistory($input: CreateOrderHistoryInput!) {
    createOrderHistory(input: $input) {
      storeId
      createdAt
      enterpriseId
      locationId
      type
      amount
      parentId
      currency
      orderHistoryOrderId
      customerStatus
      gender
      birth
      customerId
      updatedAt
      order {
        id
        orderLocationId
        numberPrefix
        number
        beeper
        enterpriseId
        storeId
        customerId
        orderCustomerId
        state
        amount
        amountPaid
        origin
        subOrigin
        originId
        customerProvider
        providerCustomerId
        amountChanged
        amountReturned
        tax
        currency
        quantity
        shippingType
        diningOption
        diningState
        retailOption
        retailState
        name
        phone
        address
        zip
        receivers {
          id
          name
          phone
          zip
          address1
          address2
          addressState
          addressCity
          addressStreet
          addressFull
          note
          shippingCompanyCode
          shippingAcceptCount
          shippingStartCount
          trackingNumber
          privacyAgreedAt
        }
        tableId
        tableIds
        parentDeviceId
        deviceId
        reserveDate
        pickupDate
        shippingDate
        items {
          id
          type
          index
          category
          categories {
            id
            name
          }
          classificationIds
          productId
          modifierId
          name
          description
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        createdEmployeeId
        createdDeviceId
        updatedDeviceId
        shippingLocationId
        confirmedEmployeeId
        confirmedAt
        createdAt
        paidAt
        salesDate
        returnedAt
        shippedAt
        returnShippedAt
        returnSalesDate
        updatedAt
        noticeCount
        visitorCount
        totalPoint
        pointSaved
        receiptId
        receiptNumber
        receiptCount
        alarmCustomerId
        receiptCustomerId
        note
        request
        charges {
          id
          method
          extraMethod
          gateway
          amount
          tax
          taxRefundAmount
          purchaser {
            name
            number
            nation
            address
          }
          supplyAmount
          taxFreeAmount
          amountRefunded
          createdEmployeeId
          createdDeviceId
          createdAt
          creditTransactionId
          balance
          splitType
          pg
          van
          origin
          originId
          note
          brand
          name
          ident
          installment
          transactionId
          approver
          issuerCode
          acquirer
          acquirerCode
          acquirerName
          refundable
          recurring
          approvalCode
          approvalDate
          approvalNumber
          signature {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          refunds {
            id
            amount
            supplyAmount
            taxFreeAmount
            tax
            taxRefundAmount
            creditTransactionId
            approvalNumber
            approvalDate
            transactionId
            origin
            originId
            currency
            reason
            signature {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            createdEmployeeId
            createdDeviceId
            createdAt
          }
        }
        returns {
          amount
          currency
          items {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          refundId
          createdAt
        }
        fee
        revision
        _delta
        location {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        customer {
          id
          customerId
          storeId
          sources {
            id
            type
            name
            ident
            createdAt
          }
          isFavorite
          locationId
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          favoriteLocationIds
          visitedLocationIds
          locations {
            id
            locationId
            isFavorite
            status
            note
            campaignBlockedAt
            blacklistedAt
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            subscriptions {
              id
              planId
              payStatus
              sessionId
              nextPayDate
              paidAt
              unsubscribedAt
              used
              lastUsedAt
              orderId
              unsubscribeRequestedAt
              startDate
              expireDate
              updatedAt
              createdAt
            }
            registeredAt
            salesDate
            updatedAt
            createdAt
          }
          status
          registeredAt
          campaignBlockedAt
          note
          removedAt
          updatedAt
          createdAt
          points {
            items {
              id
              locationId
              storeId
              type
              name
              amount
              origin
              provider
              providerCustomerId
              providerSyncedAt
              currency
              point
              extraPoint
              extraExpire
              items {
                type
                itemId
                name
                parentId
                point
                amount
                quantity
              }
              quantity
              note
              orderId
              referId
              orderNumberPrefix
              orderNumber
              customerId
              pointTransactionCustomerId
              expiredAt
              salesDate
              updatedAt
              createdAt
              customer {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
            }
            nextToken
          }
          credits {
            items {
              id
              locationId
              storeId
              name
              type
              amount
              origin
              note
              currency
              parentId
              orderId
              orderNumberPrefix
              orderNumber
              customerId
              creditTransactionCustomerId
              description
              salesDate
              createdAt
              updatedAt
              customer {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
            }
            nextToken
          }
          _delta
          orders {
            items {
              id
              orderLocationId
              numberPrefix
              number
              beeper
              enterpriseId
              storeId
              customerId
              orderCustomerId
              state
              amount
              amountPaid
              origin
              subOrigin
              originId
              customerProvider
              providerCustomerId
              amountChanged
              amountReturned
              tax
              currency
              quantity
              shippingType
              diningOption
              diningState
              retailOption
              retailState
              name
              phone
              address
              zip
              receivers {
                id
                name
                phone
                zip
                address1
                address2
                addressState
                addressCity
                addressStreet
                addressFull
                note
                shippingCompanyCode
                shippingAcceptCount
                shippingStartCount
                trackingNumber
                privacyAgreedAt
              }
              tableId
              tableIds
              parentDeviceId
              deviceId
              reserveDate
              pickupDate
              shippingDate
              items {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              createdEmployeeId
              createdDeviceId
              updatedDeviceId
              shippingLocationId
              confirmedEmployeeId
              confirmedAt
              createdAt
              paidAt
              salesDate
              returnedAt
              shippedAt
              returnShippedAt
              returnSalesDate
              updatedAt
              noticeCount
              visitorCount
              totalPoint
              pointSaved
              receiptId
              receiptNumber
              receiptCount
              alarmCustomerId
              receiptCustomerId
              note
              request
              charges {
                id
                method
                extraMethod
                gateway
                amount
                tax
                taxRefundAmount
                supplyAmount
                taxFreeAmount
                amountRefunded
                createdEmployeeId
                createdDeviceId
                createdAt
                creditTransactionId
                balance
                splitType
                pg
                van
                origin
                originId
                note
                brand
                name
                ident
                installment
                transactionId
                approver
                issuerCode
                acquirer
                acquirerCode
                acquirerName
                refundable
                recurring
                approvalCode
                approvalDate
                approvalNumber
              }
              returns {
                amount
                currency
                refundId
                createdAt
              }
              fee
              revision
              _delta
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              customer {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
              orderCustomer {
                id
                enterpriseId
                storeId
                locationId
                customerId
                provider
                providerCustomerId
                tier
                customerCreatedAt
                ident
                identType
                type
                name
                nameKeyword
                phone
                email
                birth
                ageGroup
                gender
                status
                registeredAt
                salesDate
                campaignBlockedAt
                blacklistedAt
                isFavorite
                note
                removedAt
                updatedAt
                createdAt
                _delta
                timestamp
                expdate
              }
            }
            nextToken
          }
          location {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          store {
            id
            enterpriseId
            info {
              name
              phone
              email
              representative
              businessType
              businessNumber
              industryType
              sendNumber
              currency
              address
              zip
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              csPhone
            }
            policy {
              multiLocation
              provider {
                key
                ident
                customer
                coupon
                inventory
                locationId
              }
            }
            currency
            payments {
              card
              cardJoined
              cash
              credit
              refund
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            plans {
              menus {
                id
                name
                mode
                size
                updatedAt
                createdAt
              }
              orders {
                id
                name
                updatedAt
                createdAt
              }
            }
            taxes {
              id
              type
              name
              enabled
              rate
              method
              targetType
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            item {
              attributes {
                name
                type
                value
              }
              actualPrice
              skuMgmt
              barcodeMgmt
              stockTrack
              variablePrice
              locationCreatable
            }
            inventory {
              attributes {
                name
                type
                value
              }
              expiryDateMgmt
              expiryDateAlertDays
            }
            customer {
              attributes {
                name
                type
                value
              }
            }
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            kakao {
              agency
              key
              channelId
              categoryCode
              status
              updatedAt
              createdAt
            }
            review {
              status
              campaignId
              updatedAt
              createdAt
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mirror {
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              video {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            notification {
              options {
                type
                email
                sms
              }
            }
            groups {
              items {
                id
                name
                type
                storeGroupsId
                locationGroupsId
                key
                count
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            locations {
              items {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          customer {
            id
            ident
            identType
            type
            name
            addresses {
              id
              main
              detail
              storeId
            }
            phone
            email
            birth
            agreements {
              privacy
              provider
              eula
              marketing
              updatedAt
              createdAt
            }
            ageGroup
            gender
            updatedAt
            createdAt
            stores {
              items {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
              nextToken
            }
          }
        }
        orderCustomer {
          id
          enterpriseId
          storeId
          locationId
          customerId
          provider
          providerCustomerId
          tier
          customerCreatedAt
          ident
          identType
          type
          name
          nameKeyword
          payments {
            id
            method
            pg
            number
            name
            expMonth
            expYear
            billingKey
            updatedAt
            createdAt
          }
          attributes {
            name
            type
            value
          }
          addresses {
            id
            main
            detail
            storeId
          }
          phone
          email
          birth
          agreements {
            privacy
            provider
            eula
            marketing
            updatedAt
            createdAt
          }
          ageGroup
          gender
          tags {
            tagId
            createdLocationId
          }
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          status
          registeredAt
          salesDate
          campaignBlockedAt
          blacklistedAt
          isFavorite
          locations {
            id
            locationId
            isFavorite
            status
            note
            campaignBlockedAt
            blacklistedAt
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            subscriptions {
              id
              planId
              payStatus
              sessionId
              nextPayDate
              paidAt
              unsubscribedAt
              used
              lastUsedAt
              orderId
              unsubscribeRequestedAt
              startDate
              expireDate
              updatedAt
              createdAt
            }
            registeredAt
            salesDate
            updatedAt
            createdAt
          }
          subscriptions {
            id
            planId
            payStatus
            sessionId
            nextPayDate
            paidAt
            unsubscribedAt
            used
            lastUsedAt
            orderId
            unsubscribeRequestedAt
            startDate
            expireDate
            updatedAt
            createdAt
          }
          note
          sources {
            id
            type
            name
            ident
            createdAt
          }
          removedAt
          updatedAt
          createdAt
          _delta
          timestamp
          expdate
        }
      }
    }
  }
`;
export const updateOrderHistory = /* GraphQL */ `
  mutation UpdateOrderHistory($input: UpdateOrderHistoryInput!) {
    updateOrderHistory(input: $input) {
      storeId
      createdAt
      enterpriseId
      locationId
      type
      amount
      parentId
      currency
      orderHistoryOrderId
      customerStatus
      gender
      birth
      customerId
      updatedAt
      order {
        id
        orderLocationId
        numberPrefix
        number
        beeper
        enterpriseId
        storeId
        customerId
        orderCustomerId
        state
        amount
        amountPaid
        origin
        subOrigin
        originId
        customerProvider
        providerCustomerId
        amountChanged
        amountReturned
        tax
        currency
        quantity
        shippingType
        diningOption
        diningState
        retailOption
        retailState
        name
        phone
        address
        zip
        receivers {
          id
          name
          phone
          zip
          address1
          address2
          addressState
          addressCity
          addressStreet
          addressFull
          note
          shippingCompanyCode
          shippingAcceptCount
          shippingStartCount
          trackingNumber
          privacyAgreedAt
        }
        tableId
        tableIds
        parentDeviceId
        deviceId
        reserveDate
        pickupDate
        shippingDate
        items {
          id
          type
          index
          category
          categories {
            id
            name
          }
          classificationIds
          productId
          modifierId
          name
          description
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        createdEmployeeId
        createdDeviceId
        updatedDeviceId
        shippingLocationId
        confirmedEmployeeId
        confirmedAt
        createdAt
        paidAt
        salesDate
        returnedAt
        shippedAt
        returnShippedAt
        returnSalesDate
        updatedAt
        noticeCount
        visitorCount
        totalPoint
        pointSaved
        receiptId
        receiptNumber
        receiptCount
        alarmCustomerId
        receiptCustomerId
        note
        request
        charges {
          id
          method
          extraMethod
          gateway
          amount
          tax
          taxRefundAmount
          purchaser {
            name
            number
            nation
            address
          }
          supplyAmount
          taxFreeAmount
          amountRefunded
          createdEmployeeId
          createdDeviceId
          createdAt
          creditTransactionId
          balance
          splitType
          pg
          van
          origin
          originId
          note
          brand
          name
          ident
          installment
          transactionId
          approver
          issuerCode
          acquirer
          acquirerCode
          acquirerName
          refundable
          recurring
          approvalCode
          approvalDate
          approvalNumber
          signature {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          refunds {
            id
            amount
            supplyAmount
            taxFreeAmount
            tax
            taxRefundAmount
            creditTransactionId
            approvalNumber
            approvalDate
            transactionId
            origin
            originId
            currency
            reason
            signature {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            createdEmployeeId
            createdDeviceId
            createdAt
          }
        }
        returns {
          amount
          currency
          items {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          refundId
          createdAt
        }
        fee
        revision
        _delta
        location {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        customer {
          id
          customerId
          storeId
          sources {
            id
            type
            name
            ident
            createdAt
          }
          isFavorite
          locationId
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          favoriteLocationIds
          visitedLocationIds
          locations {
            id
            locationId
            isFavorite
            status
            note
            campaignBlockedAt
            blacklistedAt
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            subscriptions {
              id
              planId
              payStatus
              sessionId
              nextPayDate
              paidAt
              unsubscribedAt
              used
              lastUsedAt
              orderId
              unsubscribeRequestedAt
              startDate
              expireDate
              updatedAt
              createdAt
            }
            registeredAt
            salesDate
            updatedAt
            createdAt
          }
          status
          registeredAt
          campaignBlockedAt
          note
          removedAt
          updatedAt
          createdAt
          points {
            items {
              id
              locationId
              storeId
              type
              name
              amount
              origin
              provider
              providerCustomerId
              providerSyncedAt
              currency
              point
              extraPoint
              extraExpire
              items {
                type
                itemId
                name
                parentId
                point
                amount
                quantity
              }
              quantity
              note
              orderId
              referId
              orderNumberPrefix
              orderNumber
              customerId
              pointTransactionCustomerId
              expiredAt
              salesDate
              updatedAt
              createdAt
              customer {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
            }
            nextToken
          }
          credits {
            items {
              id
              locationId
              storeId
              name
              type
              amount
              origin
              note
              currency
              parentId
              orderId
              orderNumberPrefix
              orderNumber
              customerId
              creditTransactionCustomerId
              description
              salesDate
              createdAt
              updatedAt
              customer {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
            }
            nextToken
          }
          _delta
          orders {
            items {
              id
              orderLocationId
              numberPrefix
              number
              beeper
              enterpriseId
              storeId
              customerId
              orderCustomerId
              state
              amount
              amountPaid
              origin
              subOrigin
              originId
              customerProvider
              providerCustomerId
              amountChanged
              amountReturned
              tax
              currency
              quantity
              shippingType
              diningOption
              diningState
              retailOption
              retailState
              name
              phone
              address
              zip
              receivers {
                id
                name
                phone
                zip
                address1
                address2
                addressState
                addressCity
                addressStreet
                addressFull
                note
                shippingCompanyCode
                shippingAcceptCount
                shippingStartCount
                trackingNumber
                privacyAgreedAt
              }
              tableId
              tableIds
              parentDeviceId
              deviceId
              reserveDate
              pickupDate
              shippingDate
              items {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              createdEmployeeId
              createdDeviceId
              updatedDeviceId
              shippingLocationId
              confirmedEmployeeId
              confirmedAt
              createdAt
              paidAt
              salesDate
              returnedAt
              shippedAt
              returnShippedAt
              returnSalesDate
              updatedAt
              noticeCount
              visitorCount
              totalPoint
              pointSaved
              receiptId
              receiptNumber
              receiptCount
              alarmCustomerId
              receiptCustomerId
              note
              request
              charges {
                id
                method
                extraMethod
                gateway
                amount
                tax
                taxRefundAmount
                supplyAmount
                taxFreeAmount
                amountRefunded
                createdEmployeeId
                createdDeviceId
                createdAt
                creditTransactionId
                balance
                splitType
                pg
                van
                origin
                originId
                note
                brand
                name
                ident
                installment
                transactionId
                approver
                issuerCode
                acquirer
                acquirerCode
                acquirerName
                refundable
                recurring
                approvalCode
                approvalDate
                approvalNumber
              }
              returns {
                amount
                currency
                refundId
                createdAt
              }
              fee
              revision
              _delta
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              customer {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
              orderCustomer {
                id
                enterpriseId
                storeId
                locationId
                customerId
                provider
                providerCustomerId
                tier
                customerCreatedAt
                ident
                identType
                type
                name
                nameKeyword
                phone
                email
                birth
                ageGroup
                gender
                status
                registeredAt
                salesDate
                campaignBlockedAt
                blacklistedAt
                isFavorite
                note
                removedAt
                updatedAt
                createdAt
                _delta
                timestamp
                expdate
              }
            }
            nextToken
          }
          location {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          store {
            id
            enterpriseId
            info {
              name
              phone
              email
              representative
              businessType
              businessNumber
              industryType
              sendNumber
              currency
              address
              zip
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              csPhone
            }
            policy {
              multiLocation
              provider {
                key
                ident
                customer
                coupon
                inventory
                locationId
              }
            }
            currency
            payments {
              card
              cardJoined
              cash
              credit
              refund
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            plans {
              menus {
                id
                name
                mode
                size
                updatedAt
                createdAt
              }
              orders {
                id
                name
                updatedAt
                createdAt
              }
            }
            taxes {
              id
              type
              name
              enabled
              rate
              method
              targetType
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            item {
              attributes {
                name
                type
                value
              }
              actualPrice
              skuMgmt
              barcodeMgmt
              stockTrack
              variablePrice
              locationCreatable
            }
            inventory {
              attributes {
                name
                type
                value
              }
              expiryDateMgmt
              expiryDateAlertDays
            }
            customer {
              attributes {
                name
                type
                value
              }
            }
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            kakao {
              agency
              key
              channelId
              categoryCode
              status
              updatedAt
              createdAt
            }
            review {
              status
              campaignId
              updatedAt
              createdAt
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mirror {
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              video {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            notification {
              options {
                type
                email
                sms
              }
            }
            groups {
              items {
                id
                name
                type
                storeGroupsId
                locationGroupsId
                key
                count
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            locations {
              items {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          customer {
            id
            ident
            identType
            type
            name
            addresses {
              id
              main
              detail
              storeId
            }
            phone
            email
            birth
            agreements {
              privacy
              provider
              eula
              marketing
              updatedAt
              createdAt
            }
            ageGroup
            gender
            updatedAt
            createdAt
            stores {
              items {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
              nextToken
            }
          }
        }
        orderCustomer {
          id
          enterpriseId
          storeId
          locationId
          customerId
          provider
          providerCustomerId
          tier
          customerCreatedAt
          ident
          identType
          type
          name
          nameKeyword
          payments {
            id
            method
            pg
            number
            name
            expMonth
            expYear
            billingKey
            updatedAt
            createdAt
          }
          attributes {
            name
            type
            value
          }
          addresses {
            id
            main
            detail
            storeId
          }
          phone
          email
          birth
          agreements {
            privacy
            provider
            eula
            marketing
            updatedAt
            createdAt
          }
          ageGroup
          gender
          tags {
            tagId
            createdLocationId
          }
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          status
          registeredAt
          salesDate
          campaignBlockedAt
          blacklistedAt
          isFavorite
          locations {
            id
            locationId
            isFavorite
            status
            note
            campaignBlockedAt
            blacklistedAt
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            subscriptions {
              id
              planId
              payStatus
              sessionId
              nextPayDate
              paidAt
              unsubscribedAt
              used
              lastUsedAt
              orderId
              unsubscribeRequestedAt
              startDate
              expireDate
              updatedAt
              createdAt
            }
            registeredAt
            salesDate
            updatedAt
            createdAt
          }
          subscriptions {
            id
            planId
            payStatus
            sessionId
            nextPayDate
            paidAt
            unsubscribedAt
            used
            lastUsedAt
            orderId
            unsubscribeRequestedAt
            startDate
            expireDate
            updatedAt
            createdAt
          }
          note
          sources {
            id
            type
            name
            ident
            createdAt
          }
          removedAt
          updatedAt
          createdAt
          _delta
          timestamp
          expdate
        }
      }
    }
  }
`;
export const deleteOrderHistory = /* GraphQL */ `
  mutation DeleteOrderHistory($input: DeleteOrderHistoryInput!) {
    deleteOrderHistory(input: $input) {
      storeId
      createdAt
      enterpriseId
      locationId
      type
      amount
      parentId
      currency
      orderHistoryOrderId
      customerStatus
      gender
      birth
      customerId
      updatedAt
      order {
        id
        orderLocationId
        numberPrefix
        number
        beeper
        enterpriseId
        storeId
        customerId
        orderCustomerId
        state
        amount
        amountPaid
        origin
        subOrigin
        originId
        customerProvider
        providerCustomerId
        amountChanged
        amountReturned
        tax
        currency
        quantity
        shippingType
        diningOption
        diningState
        retailOption
        retailState
        name
        phone
        address
        zip
        receivers {
          id
          name
          phone
          zip
          address1
          address2
          addressState
          addressCity
          addressStreet
          addressFull
          note
          shippingCompanyCode
          shippingAcceptCount
          shippingStartCount
          trackingNumber
          privacyAgreedAt
        }
        tableId
        tableIds
        parentDeviceId
        deviceId
        reserveDate
        pickupDate
        shippingDate
        items {
          id
          type
          index
          category
          categories {
            id
            name
          }
          classificationIds
          productId
          modifierId
          name
          description
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        createdEmployeeId
        createdDeviceId
        updatedDeviceId
        shippingLocationId
        confirmedEmployeeId
        confirmedAt
        createdAt
        paidAt
        salesDate
        returnedAt
        shippedAt
        returnShippedAt
        returnSalesDate
        updatedAt
        noticeCount
        visitorCount
        totalPoint
        pointSaved
        receiptId
        receiptNumber
        receiptCount
        alarmCustomerId
        receiptCustomerId
        note
        request
        charges {
          id
          method
          extraMethod
          gateway
          amount
          tax
          taxRefundAmount
          purchaser {
            name
            number
            nation
            address
          }
          supplyAmount
          taxFreeAmount
          amountRefunded
          createdEmployeeId
          createdDeviceId
          createdAt
          creditTransactionId
          balance
          splitType
          pg
          van
          origin
          originId
          note
          brand
          name
          ident
          installment
          transactionId
          approver
          issuerCode
          acquirer
          acquirerCode
          acquirerName
          refundable
          recurring
          approvalCode
          approvalDate
          approvalNumber
          signature {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          refunds {
            id
            amount
            supplyAmount
            taxFreeAmount
            tax
            taxRefundAmount
            creditTransactionId
            approvalNumber
            approvalDate
            transactionId
            origin
            originId
            currency
            reason
            signature {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            createdEmployeeId
            createdDeviceId
            createdAt
          }
        }
        returns {
          amount
          currency
          items {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          refundId
          createdAt
        }
        fee
        revision
        _delta
        location {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        customer {
          id
          customerId
          storeId
          sources {
            id
            type
            name
            ident
            createdAt
          }
          isFavorite
          locationId
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          favoriteLocationIds
          visitedLocationIds
          locations {
            id
            locationId
            isFavorite
            status
            note
            campaignBlockedAt
            blacklistedAt
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            subscriptions {
              id
              planId
              payStatus
              sessionId
              nextPayDate
              paidAt
              unsubscribedAt
              used
              lastUsedAt
              orderId
              unsubscribeRequestedAt
              startDate
              expireDate
              updatedAt
              createdAt
            }
            registeredAt
            salesDate
            updatedAt
            createdAt
          }
          status
          registeredAt
          campaignBlockedAt
          note
          removedAt
          updatedAt
          createdAt
          points {
            items {
              id
              locationId
              storeId
              type
              name
              amount
              origin
              provider
              providerCustomerId
              providerSyncedAt
              currency
              point
              extraPoint
              extraExpire
              items {
                type
                itemId
                name
                parentId
                point
                amount
                quantity
              }
              quantity
              note
              orderId
              referId
              orderNumberPrefix
              orderNumber
              customerId
              pointTransactionCustomerId
              expiredAt
              salesDate
              updatedAt
              createdAt
              customer {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
            }
            nextToken
          }
          credits {
            items {
              id
              locationId
              storeId
              name
              type
              amount
              origin
              note
              currency
              parentId
              orderId
              orderNumberPrefix
              orderNumber
              customerId
              creditTransactionCustomerId
              description
              salesDate
              createdAt
              updatedAt
              customer {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
            }
            nextToken
          }
          _delta
          orders {
            items {
              id
              orderLocationId
              numberPrefix
              number
              beeper
              enterpriseId
              storeId
              customerId
              orderCustomerId
              state
              amount
              amountPaid
              origin
              subOrigin
              originId
              customerProvider
              providerCustomerId
              amountChanged
              amountReturned
              tax
              currency
              quantity
              shippingType
              diningOption
              diningState
              retailOption
              retailState
              name
              phone
              address
              zip
              receivers {
                id
                name
                phone
                zip
                address1
                address2
                addressState
                addressCity
                addressStreet
                addressFull
                note
                shippingCompanyCode
                shippingAcceptCount
                shippingStartCount
                trackingNumber
                privacyAgreedAt
              }
              tableId
              tableIds
              parentDeviceId
              deviceId
              reserveDate
              pickupDate
              shippingDate
              items {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              createdEmployeeId
              createdDeviceId
              updatedDeviceId
              shippingLocationId
              confirmedEmployeeId
              confirmedAt
              createdAt
              paidAt
              salesDate
              returnedAt
              shippedAt
              returnShippedAt
              returnSalesDate
              updatedAt
              noticeCount
              visitorCount
              totalPoint
              pointSaved
              receiptId
              receiptNumber
              receiptCount
              alarmCustomerId
              receiptCustomerId
              note
              request
              charges {
                id
                method
                extraMethod
                gateway
                amount
                tax
                taxRefundAmount
                supplyAmount
                taxFreeAmount
                amountRefunded
                createdEmployeeId
                createdDeviceId
                createdAt
                creditTransactionId
                balance
                splitType
                pg
                van
                origin
                originId
                note
                brand
                name
                ident
                installment
                transactionId
                approver
                issuerCode
                acquirer
                acquirerCode
                acquirerName
                refundable
                recurring
                approvalCode
                approvalDate
                approvalNumber
              }
              returns {
                amount
                currency
                refundId
                createdAt
              }
              fee
              revision
              _delta
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              customer {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
              orderCustomer {
                id
                enterpriseId
                storeId
                locationId
                customerId
                provider
                providerCustomerId
                tier
                customerCreatedAt
                ident
                identType
                type
                name
                nameKeyword
                phone
                email
                birth
                ageGroup
                gender
                status
                registeredAt
                salesDate
                campaignBlockedAt
                blacklistedAt
                isFavorite
                note
                removedAt
                updatedAt
                createdAt
                _delta
                timestamp
                expdate
              }
            }
            nextToken
          }
          location {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          store {
            id
            enterpriseId
            info {
              name
              phone
              email
              representative
              businessType
              businessNumber
              industryType
              sendNumber
              currency
              address
              zip
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              csPhone
            }
            policy {
              multiLocation
              provider {
                key
                ident
                customer
                coupon
                inventory
                locationId
              }
            }
            currency
            payments {
              card
              cardJoined
              cash
              credit
              refund
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            plans {
              menus {
                id
                name
                mode
                size
                updatedAt
                createdAt
              }
              orders {
                id
                name
                updatedAt
                createdAt
              }
            }
            taxes {
              id
              type
              name
              enabled
              rate
              method
              targetType
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            item {
              attributes {
                name
                type
                value
              }
              actualPrice
              skuMgmt
              barcodeMgmt
              stockTrack
              variablePrice
              locationCreatable
            }
            inventory {
              attributes {
                name
                type
                value
              }
              expiryDateMgmt
              expiryDateAlertDays
            }
            customer {
              attributes {
                name
                type
                value
              }
            }
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            kakao {
              agency
              key
              channelId
              categoryCode
              status
              updatedAt
              createdAt
            }
            review {
              status
              campaignId
              updatedAt
              createdAt
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mirror {
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              video {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            notification {
              options {
                type
                email
                sms
              }
            }
            groups {
              items {
                id
                name
                type
                storeGroupsId
                locationGroupsId
                key
                count
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            locations {
              items {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          customer {
            id
            ident
            identType
            type
            name
            addresses {
              id
              main
              detail
              storeId
            }
            phone
            email
            birth
            agreements {
              privacy
              provider
              eula
              marketing
              updatedAt
              createdAt
            }
            ageGroup
            gender
            updatedAt
            createdAt
            stores {
              items {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
              nextToken
            }
          }
        }
        orderCustomer {
          id
          enterpriseId
          storeId
          locationId
          customerId
          provider
          providerCustomerId
          tier
          customerCreatedAt
          ident
          identType
          type
          name
          nameKeyword
          payments {
            id
            method
            pg
            number
            name
            expMonth
            expYear
            billingKey
            updatedAt
            createdAt
          }
          attributes {
            name
            type
            value
          }
          addresses {
            id
            main
            detail
            storeId
          }
          phone
          email
          birth
          agreements {
            privacy
            provider
            eula
            marketing
            updatedAt
            createdAt
          }
          ageGroup
          gender
          tags {
            tagId
            createdLocationId
          }
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          status
          registeredAt
          salesDate
          campaignBlockedAt
          blacklistedAt
          isFavorite
          locations {
            id
            locationId
            isFavorite
            status
            note
            campaignBlockedAt
            blacklistedAt
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            subscriptions {
              id
              planId
              payStatus
              sessionId
              nextPayDate
              paidAt
              unsubscribedAt
              used
              lastUsedAt
              orderId
              unsubscribeRequestedAt
              startDate
              expireDate
              updatedAt
              createdAt
            }
            registeredAt
            salesDate
            updatedAt
            createdAt
          }
          subscriptions {
            id
            planId
            payStatus
            sessionId
            nextPayDate
            paidAt
            unsubscribedAt
            used
            lastUsedAt
            orderId
            unsubscribeRequestedAt
            startDate
            expireDate
            updatedAt
            createdAt
          }
          note
          sources {
            id
            type
            name
            ident
            createdAt
          }
          removedAt
          updatedAt
          createdAt
          _delta
          timestamp
          expdate
        }
      }
    }
  }
`;
export const createCustomerCart = /* GraphQL */ `
  mutation CreateCustomerCart($input: CreateCustomerCartInput!) {
    createCustomerCart(input: $input) {
      id
      number
      storeId
      orderLocationId
      orderCustomerId
      state
      amount
      taxFreeAmount
      supplyAmount
      tax
      currency
      quantity
      diningOption
      diningState
      address {
        id
        main
        detail
        storeId
      }
      address1
      address2
      reserveDate
      salesDate
      pickupDate
      shippingDate
      items {
        id
        type
        index
        category
        categories {
          id
          name
        }
        classificationIds
        productId
        modifierId
        name
        description
        amount
        price
        parentId
        referType
        referId
        campaignId
        requestId
        quantity
        sku
        tax
        taxRate
        diningOption
        diningState
        modifiers {
          id
          type
          index
          category
          categories {
            id
            name
          }
          classificationIds
          productId
          modifierId
          name
          description
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        discounts {
          id
          scope
          type
          amount
          name
        }
        discountable
        discountQuantity
        discountIncludeIds
        discountExcludeIds
        discountMethod
        discountValue
        orderedQuantity
        voidQuantity
        paidQuantity
        readyQuantity
        receivedQuantity
        inventoryReason
        point
        note
        createdAt
      }
      createdAt
      updatedAt
      request
      visitorCount
      pointSaved
    }
  }
`;
export const updateCustomerCart = /* GraphQL */ `
  mutation UpdateCustomerCart($input: UpdateCustomerCartInput!) {
    updateCustomerCart(input: $input) {
      id
      number
      storeId
      orderLocationId
      orderCustomerId
      state
      amount
      taxFreeAmount
      supplyAmount
      tax
      currency
      quantity
      diningOption
      diningState
      address {
        id
        main
        detail
        storeId
      }
      address1
      address2
      reserveDate
      salesDate
      pickupDate
      shippingDate
      items {
        id
        type
        index
        category
        categories {
          id
          name
        }
        classificationIds
        productId
        modifierId
        name
        description
        amount
        price
        parentId
        referType
        referId
        campaignId
        requestId
        quantity
        sku
        tax
        taxRate
        diningOption
        diningState
        modifiers {
          id
          type
          index
          category
          categories {
            id
            name
          }
          classificationIds
          productId
          modifierId
          name
          description
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        discounts {
          id
          scope
          type
          amount
          name
        }
        discountable
        discountQuantity
        discountIncludeIds
        discountExcludeIds
        discountMethod
        discountValue
        orderedQuantity
        voidQuantity
        paidQuantity
        readyQuantity
        receivedQuantity
        inventoryReason
        point
        note
        createdAt
      }
      createdAt
      updatedAt
      request
      visitorCount
      pointSaved
    }
  }
`;
export const deleteCustomerCart = /* GraphQL */ `
  mutation DeleteCustomerCart($input: DeleteCustomerCartInput!) {
    deleteCustomerCart(input: $input) {
      id
      number
      storeId
      orderLocationId
      orderCustomerId
      state
      amount
      taxFreeAmount
      supplyAmount
      tax
      currency
      quantity
      diningOption
      diningState
      address {
        id
        main
        detail
        storeId
      }
      address1
      address2
      reserveDate
      salesDate
      pickupDate
      shippingDate
      items {
        id
        type
        index
        category
        categories {
          id
          name
        }
        classificationIds
        productId
        modifierId
        name
        description
        amount
        price
        parentId
        referType
        referId
        campaignId
        requestId
        quantity
        sku
        tax
        taxRate
        diningOption
        diningState
        modifiers {
          id
          type
          index
          category
          categories {
            id
            name
          }
          classificationIds
          productId
          modifierId
          name
          description
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        discounts {
          id
          scope
          type
          amount
          name
        }
        discountable
        discountQuantity
        discountIncludeIds
        discountExcludeIds
        discountMethod
        discountValue
        orderedQuantity
        voidQuantity
        paidQuantity
        readyQuantity
        receivedQuantity
        inventoryReason
        point
        note
        createdAt
      }
      createdAt
      updatedAt
      request
      visitorCount
      pointSaved
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder($input: CreateOrderInput!) {
    createOrder(input: $input) {
      id
      orderLocationId
      numberPrefix
      number
      beeper
      enterpriseId
      storeId
      customerId
      orderCustomerId
      state
      amount
      amountPaid
      origin
      subOrigin
      originId
      customerProvider
      providerCustomerId
      amountChanged
      amountReturned
      tax
      currency
      quantity
      shippingType
      diningOption
      diningState
      retailOption
      retailState
      name
      phone
      address
      zip
      receivers {
        id
        name
        phone
        zip
        address1
        address2
        addressState
        addressCity
        addressStreet
        addressFull
        note
        shippingCompanyCode
        shippingAcceptCount
        shippingStartCount
        trackingNumber
        privacyAgreedAt
      }
      tableId
      tableIds
      parentDeviceId
      deviceId
      reserveDate
      pickupDate
      shippingDate
      items {
        id
        type
        index
        category
        categories {
          id
          name
        }
        classificationIds
        productId
        modifierId
        name
        description
        amount
        price
        parentId
        referType
        referId
        campaignId
        requestId
        quantity
        sku
        tax
        taxRate
        diningOption
        diningState
        modifiers {
          id
          type
          index
          category
          categories {
            id
            name
          }
          classificationIds
          productId
          modifierId
          name
          description
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        discounts {
          id
          scope
          type
          amount
          name
        }
        discountable
        discountQuantity
        discountIncludeIds
        discountExcludeIds
        discountMethod
        discountValue
        orderedQuantity
        voidQuantity
        paidQuantity
        readyQuantity
        receivedQuantity
        inventoryReason
        point
        note
        createdAt
      }
      createdEmployeeId
      createdDeviceId
      updatedDeviceId
      shippingLocationId
      confirmedEmployeeId
      confirmedAt
      createdAt
      paidAt
      salesDate
      returnedAt
      shippedAt
      returnShippedAt
      returnSalesDate
      updatedAt
      noticeCount
      visitorCount
      totalPoint
      pointSaved
      receiptId
      receiptNumber
      receiptCount
      alarmCustomerId
      receiptCustomerId
      note
      request
      charges {
        id
        method
        extraMethod
        gateway
        amount
        tax
        taxRefundAmount
        purchaser {
          name
          number
          nation
          address
        }
        supplyAmount
        taxFreeAmount
        amountRefunded
        createdEmployeeId
        createdDeviceId
        createdAt
        creditTransactionId
        balance
        splitType
        pg
        van
        origin
        originId
        note
        brand
        name
        ident
        installment
        transactionId
        approver
        issuerCode
        acquirer
        acquirerCode
        acquirerName
        refundable
        recurring
        approvalCode
        approvalDate
        approvalNumber
        signature {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        refunds {
          id
          amount
          supplyAmount
          taxFreeAmount
          tax
          taxRefundAmount
          creditTransactionId
          approvalNumber
          approvalDate
          transactionId
          origin
          originId
          currency
          reason
          signature {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          createdEmployeeId
          createdDeviceId
          createdAt
        }
      }
      returns {
        amount
        currency
        items {
          id
          type
          index
          category
          categories {
            id
            name
          }
          classificationIds
          productId
          modifierId
          name
          description
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        refundId
        createdAt
      }
      fee
      revision
      _delta
      location {
        id
        name
        type
        provider
        address
        zip
        phone
        logo {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        representative
        businessNumber
        billing {
          key
          businessNumber
          name
          email
          phone
          contanctName
          contactName
          address
          zip
          card {
            pg
            number
            name
            expMonth
            expYear
            identify
            password
            billingKey
          }
        }
        credit {
          autoCharge
          autoChargeCredit
          autoChargeQuantity
          alertCredit
        }
        businessHours {
          sun {
            open
            close
          }
          mon {
            open
            close
          }
          tue {
            open
            close
          }
          wed {
            open
            close
          }
          thu {
            open
            close
          }
          fri {
            open
            close
          }
          sat {
            open
            close
          }
        }
        storeId
        state
        menuPlanId
        payments {
          refund
          barcode
          barcodeVan
          barcodeTerminalId
        }
        policy {
          locationCost
          loyaltyCost
        }
        loyalty {
          status
          provider
          sharePolicy
          type
          grade
          conditionType
          conditionSubType
          condition {
            minAmount
            perAmount
            minTime
            rate
            expirationPeriod
            excludeProdIds
            discountPointSavable
          }
          conditionItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          conditionTiers {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardType
          rewardSubType
          reward {
            requirePoint
            requireOrderAmount
            minUsePoint
            minRequireRate
            maxUsePoint
          }
          rewardItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardRates {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardAmounts {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardCashs {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          incentives {
            fulfill
            fulfillPoint
            fulfillCampaignId
            birth
            birthPoint
            birthCampaignId
            birthItem {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
          }
          notification {
            register
            registerCampainId
            save
            saveCampaignId
            use
            useCampaignId
            expire
            expireCampaignId
          }
        }
        kiosk {
          status
          callable
          payable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          exchangeTicket
          columnSize
          discounts {
            condition
            couponId
          }
          menuDescription
          updatedAt
          createdAt
        }
        receipt {
          salesSlip
          orderList
          barcode
          footnote
          phone
          orderNumberHide
          orderSheetFontSize
          billsBusinessInfo
          giftFootnote
          giftReceipt
        }
        menuOrder {
          callOptions {
            index
            label
          }
          orderable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          columnSize
          fontSize
          notice
          resetTime
          enableCash
          boardType
        }
        kds {
          layout
          alerts {
            urgent
            warning
          }
          disabledProdIds
          orderNotify
        }
        mini {
          defaultItemName
        }
        orderPlanId
        menu {
          board {
            textSize
            columnSize
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            enableFavorite
          }
          list {
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            shortcuts {
              type
              title
              itemName
              itemPrice
              active
            }
          }
          navOrder
        }
        menus {
          parentId
          position
          page
          pageTitle
        }
        favMenus {
          parentId
          position
          page
          pageTitle
        }
        catMenus {
          title
          index
          ids
          parentId
        }
        market {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          paused
          takeoutDiscount
          creditDiscount
          takeout
          intro
          notice
          bookTable
          bookTableNote
          depositAmount
          delivery
          deliveryFee {
            start
            end
            fee
          }
          waitingTime
          itemIds
          updatedAt
          createdAt
        }
        waiting {
          status
          waitingOptions {
            id
            type
            name
            nameMl {
              en
              zh
              ja
            }
            items {
              id
              name
              nameMl {
                en
                zh
                ja
              }
              limitQuantity
            }
            required
            updatedAt
            createdAt
          }
          waitingMinutes
          waitingMinutesHide
          hasForeigner
          foreignerMethod
          notifications {
            created
            ready
            requested
            cancelled
          }
          notices {
            createdNotice
            createdNoticeMl {
              en
              zh
              ja
            }
            requestedNotice
            requestedNoticeMl {
              en
              zh
              ja
            }
          }
          settings {
            autoCancel
            autoCancelMinutes
          }
          updatedAt
          createdAt
        }
        view {
          order {
            excludeColumns
            exportTemplates {
              name
              type
              headers {
                key
                label
              }
            }
          }
          itemReport {
            excludeColumns
          }
          summaryReport {
            excludeColumns
          }
          shippingOrder {
            excludeColumns
          }
          trsItemReport {
            excludeColumns
          }
        }
        subscription {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          updatedAt
          createdAt
        }
        settings {
          mode
          table
          diningOption
          retailOption
          orderAlarm
          orderConfirm
          addressInput
          autoPay
          autoReturnItem
          autoSoldout
          menuGridSize
          menuMode
          tableMode
          orderNotify
          orderNotifySkippable
          disablePhonePrefix
          autoCloseScreen
          orderNumberPrefix {
            pos
            kiosk
            menuOrder
          }
          orderMgmt {
            kiosk
            menuorder
            delivery
            pos
          }
          reportBasis
          orderOrigins
          beeper {
            active
            coverage
            skippable
          }
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
        }
        devices {
          id
          ident
          locationId
          system
          ipAddress
          brand
          model
          name
          type
          deviceType
          display {
            height
            width
          }
          protocol
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          barcodeReader
          appVersion
          sysVersion
          status
          password
          msgToken
          parentId
          childId
          sleepMode
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
          terminalId
          printOptions {
            port
            baudRate
            receipt
            receiptButtonHide
            bills
            refund
            salesEnd
            order
            orderBeep
            orderCount
            orderExcludeIds
            delivery
          }
          updatedAt
          createdAt
        }
        tables {
          id
          width
          height
          name
          axisX
          axisY
          screenHeight
          screenWidth
          initialX
          initialY
          shape
          layout {
            x
            y
            w
            h
          }
          color
          orderId
          disabled
        }
        boardTables {
          title
          index
          items {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
        }
        gridTables {
          id
          name
          orderId
          index
          page
        }
        modifierSoldoutIds
        delivery {
          baemin
          coupang
          yogiyo
          enableGenProd
          updatedAt
          createdAt
        }
        createdAt
        updatedAt
        sales {
          startedAt
          endedAt
          reserves
          actualBalance
          currency
          note
          estimateBalance
          detail {
            totalSales
            totalRefund
            totalTax
            totalDiscount
            supplyAmount
            totalSalesCount
            cash {
              sales
              refund
            }
            card {
              sales
              refund
            }
            credit {
              sales
              refund
            }
            barcode {
              sales
              refund
            }
            extra {
              sales
              refund
            }
            refund {
              sales
              refund
            }
            products {
              id
              name
              quantity
              amount
            }
            origins {
              pos
              kiosk
              menuorder
              delivery
              market
            }
          }
          reservesHistories {
            type
            amount
            note
            createdEmployeeId
            createdAt
          }
        }
      }
      customer {
        id
        customerId
        storeId
        sources {
          id
          type
          name
          ident
          createdAt
        }
        isFavorite
        locationId
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        favoriteLocationIds
        visitedLocationIds
        locations {
          id
          locationId
          isFavorite
          status
          note
          campaignBlockedAt
          blacklistedAt
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          subscriptions {
            id
            planId
            payStatus
            sessionId
            nextPayDate
            paidAt
            unsubscribedAt
            used
            lastUsedAt
            orderId
            unsubscribeRequestedAt
            startDate
            expireDate
            updatedAt
            createdAt
          }
          registeredAt
          salesDate
          updatedAt
          createdAt
        }
        status
        registeredAt
        campaignBlockedAt
        note
        removedAt
        updatedAt
        createdAt
        points {
          items {
            id
            locationId
            storeId
            type
            name
            amount
            origin
            provider
            providerCustomerId
            providerSyncedAt
            currency
            point
            extraPoint
            extraExpire
            items {
              type
              itemId
              name
              parentId
              point
              amount
              quantity
            }
            quantity
            note
            orderId
            referId
            orderNumberPrefix
            orderNumber
            customerId
            pointTransactionCustomerId
            expiredAt
            salesDate
            updatedAt
            createdAt
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
          }
          nextToken
        }
        credits {
          items {
            id
            locationId
            storeId
            name
            type
            amount
            origin
            note
            currency
            parentId
            orderId
            orderNumberPrefix
            orderNumber
            customerId
            creditTransactionCustomerId
            description
            salesDate
            createdAt
            updatedAt
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
          }
          nextToken
        }
        _delta
        orders {
          items {
            id
            orderLocationId
            numberPrefix
            number
            beeper
            enterpriseId
            storeId
            customerId
            orderCustomerId
            state
            amount
            amountPaid
            origin
            subOrigin
            originId
            customerProvider
            providerCustomerId
            amountChanged
            amountReturned
            tax
            currency
            quantity
            shippingType
            diningOption
            diningState
            retailOption
            retailState
            name
            phone
            address
            zip
            receivers {
              id
              name
              phone
              zip
              address1
              address2
              addressState
              addressCity
              addressStreet
              addressFull
              note
              shippingCompanyCode
              shippingAcceptCount
              shippingStartCount
              trackingNumber
              privacyAgreedAt
            }
            tableId
            tableIds
            parentDeviceId
            deviceId
            reserveDate
            pickupDate
            shippingDate
            items {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            createdEmployeeId
            createdDeviceId
            updatedDeviceId
            shippingLocationId
            confirmedEmployeeId
            confirmedAt
            createdAt
            paidAt
            salesDate
            returnedAt
            shippedAt
            returnShippedAt
            returnSalesDate
            updatedAt
            noticeCount
            visitorCount
            totalPoint
            pointSaved
            receiptId
            receiptNumber
            receiptCount
            alarmCustomerId
            receiptCustomerId
            note
            request
            charges {
              id
              method
              extraMethod
              gateway
              amount
              tax
              taxRefundAmount
              purchaser {
                name
                number
                nation
                address
              }
              supplyAmount
              taxFreeAmount
              amountRefunded
              createdEmployeeId
              createdDeviceId
              createdAt
              creditTransactionId
              balance
              splitType
              pg
              van
              origin
              originId
              note
              brand
              name
              ident
              installment
              transactionId
              approver
              issuerCode
              acquirer
              acquirerCode
              acquirerName
              refundable
              recurring
              approvalCode
              approvalDate
              approvalNumber
              signature {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              refunds {
                id
                amount
                supplyAmount
                taxFreeAmount
                tax
                taxRefundAmount
                creditTransactionId
                approvalNumber
                approvalDate
                transactionId
                origin
                originId
                currency
                reason
                createdEmployeeId
                createdDeviceId
                createdAt
              }
            }
            returns {
              amount
              currency
              items {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              refundId
              createdAt
            }
            fee
            revision
            _delta
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
            orderCustomer {
              id
              enterpriseId
              storeId
              locationId
              customerId
              provider
              providerCustomerId
              tier
              customerCreatedAt
              ident
              identType
              type
              name
              nameKeyword
              payments {
                id
                method
                pg
                number
                name
                expMonth
                expYear
                billingKey
                updatedAt
                createdAt
              }
              attributes {
                name
                type
                value
              }
              addresses {
                id
                main
                detail
                storeId
              }
              phone
              email
              birth
              agreements {
                privacy
                provider
                eula
                marketing
                updatedAt
                createdAt
              }
              ageGroup
              gender
              tags {
                tagId
                createdLocationId
              }
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              status
              registeredAt
              salesDate
              campaignBlockedAt
              blacklistedAt
              isFavorite
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              subscriptions {
                id
                planId
                payStatus
                sessionId
                nextPayDate
                paidAt
                unsubscribedAt
                used
                lastUsedAt
                orderId
                unsubscribeRequestedAt
                startDate
                expireDate
                updatedAt
                createdAt
              }
              note
              sources {
                id
                type
                name
                ident
                createdAt
              }
              removedAt
              updatedAt
              createdAt
              _delta
              timestamp
              expdate
            }
          }
          nextToken
        }
        location {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        store {
          id
          enterpriseId
          info {
            name
            phone
            email
            representative
            businessType
            businessNumber
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            industryType
            sendNumber
            currency
            address
            zip
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            csPhone
          }
          policy {
            multiLocation
            provider {
              key
              ident
              customer
              coupon
              inventory
              locationId
            }
          }
          currency
          payments {
            card
            cardJoined
            cash
            credit
            refund
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          plans {
            menus {
              id
              name
              mode
              size
              menus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              pages {
                index
                parentId
                title
              }
              updatedAt
              createdAt
            }
            orders {
              id
              name
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              updatedAt
              createdAt
            }
          }
          taxes {
            id
            type
            name
            enabled
            rate
            method
            targetType
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          item {
            attributes {
              name
              type
              value
            }
            actualPrice
            skuMgmt
            barcodeMgmt
            stockTrack
            variablePrice
            locationCreatable
          }
          inventory {
            attributes {
              name
              type
              value
            }
            expiryDateMgmt
            expiryDateAlertDays
          }
          customer {
            attributes {
              name
              type
              value
            }
          }
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          kakao {
            agency
            key
            channelId
            categoryCode
            status
            updatedAt
            createdAt
          }
          review {
            status
            campaignId
            updatedAt
            createdAt
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mirror {
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            video {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          notification {
            options {
              type
              email
              sms
            }
          }
          groups {
            items {
              id
              name
              type
              storeGroupsId
              locationGroupsId
              key
              conditions {
                key
                object
                field
                value
                operation
                type
              }
              count
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          location {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          locations {
            items {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            nextToken
          }
        }
        customer {
          id
          ident
          identType
          type
          name
          addresses {
            id
            main
            detail
            storeId
          }
          phone
          email
          birth
          agreements {
            privacy
            provider
            eula
            marketing
            updatedAt
            createdAt
          }
          ageGroup
          gender
          updatedAt
          createdAt
          stores {
            items {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
            nextToken
          }
        }
      }
      orderCustomer {
        id
        enterpriseId
        storeId
        locationId
        customerId
        provider
        providerCustomerId
        tier
        customerCreatedAt
        ident
        identType
        type
        name
        nameKeyword
        payments {
          id
          method
          pg
          number
          name
          expMonth
          expYear
          billingKey
          updatedAt
          createdAt
        }
        attributes {
          name
          type
          value
        }
        addresses {
          id
          main
          detail
          storeId
        }
        phone
        email
        birth
        agreements {
          privacy
          provider
          eula
          marketing
          updatedAt
          createdAt
        }
        ageGroup
        gender
        tags {
          tagId
          createdLocationId
        }
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        status
        registeredAt
        salesDate
        campaignBlockedAt
        blacklistedAt
        isFavorite
        locations {
          id
          locationId
          isFavorite
          status
          note
          campaignBlockedAt
          blacklistedAt
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          subscriptions {
            id
            planId
            payStatus
            sessionId
            nextPayDate
            paidAt
            unsubscribedAt
            used
            lastUsedAt
            orderId
            unsubscribeRequestedAt
            startDate
            expireDate
            updatedAt
            createdAt
          }
          registeredAt
          salesDate
          updatedAt
          createdAt
        }
        subscriptions {
          id
          planId
          payStatus
          sessionId
          nextPayDate
          paidAt
          unsubscribedAt
          used
          lastUsedAt
          orderId
          unsubscribeRequestedAt
          startDate
          expireDate
          updatedAt
          createdAt
        }
        note
        sources {
          id
          type
          name
          ident
          createdAt
        }
        removedAt
        updatedAt
        createdAt
        _delta
        timestamp
        expdate
      }
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder($input: DeleteOrderInput!) {
    deleteOrder(input: $input) {
      id
      orderLocationId
      numberPrefix
      number
      beeper
      enterpriseId
      storeId
      customerId
      orderCustomerId
      state
      amount
      amountPaid
      origin
      subOrigin
      originId
      customerProvider
      providerCustomerId
      amountChanged
      amountReturned
      tax
      currency
      quantity
      shippingType
      diningOption
      diningState
      retailOption
      retailState
      name
      phone
      address
      zip
      receivers {
        id
        name
        phone
        zip
        address1
        address2
        addressState
        addressCity
        addressStreet
        addressFull
        note
        shippingCompanyCode
        shippingAcceptCount
        shippingStartCount
        trackingNumber
        privacyAgreedAt
      }
      tableId
      tableIds
      parentDeviceId
      deviceId
      reserveDate
      pickupDate
      shippingDate
      items {
        id
        type
        index
        category
        categories {
          id
          name
        }
        classificationIds
        productId
        modifierId
        name
        description
        amount
        price
        parentId
        referType
        referId
        campaignId
        requestId
        quantity
        sku
        tax
        taxRate
        diningOption
        diningState
        modifiers {
          id
          type
          index
          category
          categories {
            id
            name
          }
          classificationIds
          productId
          modifierId
          name
          description
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        discounts {
          id
          scope
          type
          amount
          name
        }
        discountable
        discountQuantity
        discountIncludeIds
        discountExcludeIds
        discountMethod
        discountValue
        orderedQuantity
        voidQuantity
        paidQuantity
        readyQuantity
        receivedQuantity
        inventoryReason
        point
        note
        createdAt
      }
      createdEmployeeId
      createdDeviceId
      updatedDeviceId
      shippingLocationId
      confirmedEmployeeId
      confirmedAt
      createdAt
      paidAt
      salesDate
      returnedAt
      shippedAt
      returnShippedAt
      returnSalesDate
      updatedAt
      noticeCount
      visitorCount
      totalPoint
      pointSaved
      receiptId
      receiptNumber
      receiptCount
      alarmCustomerId
      receiptCustomerId
      note
      request
      charges {
        id
        method
        extraMethod
        gateway
        amount
        tax
        taxRefundAmount
        purchaser {
          name
          number
          nation
          address
        }
        supplyAmount
        taxFreeAmount
        amountRefunded
        createdEmployeeId
        createdDeviceId
        createdAt
        creditTransactionId
        balance
        splitType
        pg
        van
        origin
        originId
        note
        brand
        name
        ident
        installment
        transactionId
        approver
        issuerCode
        acquirer
        acquirerCode
        acquirerName
        refundable
        recurring
        approvalCode
        approvalDate
        approvalNumber
        signature {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        refunds {
          id
          amount
          supplyAmount
          taxFreeAmount
          tax
          taxRefundAmount
          creditTransactionId
          approvalNumber
          approvalDate
          transactionId
          origin
          originId
          currency
          reason
          signature {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          createdEmployeeId
          createdDeviceId
          createdAt
        }
      }
      returns {
        amount
        currency
        items {
          id
          type
          index
          category
          categories {
            id
            name
          }
          classificationIds
          productId
          modifierId
          name
          description
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        refundId
        createdAt
      }
      fee
      revision
      _delta
      location {
        id
        name
        type
        provider
        address
        zip
        phone
        logo {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        representative
        businessNumber
        billing {
          key
          businessNumber
          name
          email
          phone
          contanctName
          contactName
          address
          zip
          card {
            pg
            number
            name
            expMonth
            expYear
            identify
            password
            billingKey
          }
        }
        credit {
          autoCharge
          autoChargeCredit
          autoChargeQuantity
          alertCredit
        }
        businessHours {
          sun {
            open
            close
          }
          mon {
            open
            close
          }
          tue {
            open
            close
          }
          wed {
            open
            close
          }
          thu {
            open
            close
          }
          fri {
            open
            close
          }
          sat {
            open
            close
          }
        }
        storeId
        state
        menuPlanId
        payments {
          refund
          barcode
          barcodeVan
          barcodeTerminalId
        }
        policy {
          locationCost
          loyaltyCost
        }
        loyalty {
          status
          provider
          sharePolicy
          type
          grade
          conditionType
          conditionSubType
          condition {
            minAmount
            perAmount
            minTime
            rate
            expirationPeriod
            excludeProdIds
            discountPointSavable
          }
          conditionItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          conditionTiers {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardType
          rewardSubType
          reward {
            requirePoint
            requireOrderAmount
            minUsePoint
            minRequireRate
            maxUsePoint
          }
          rewardItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardRates {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardAmounts {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardCashs {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          incentives {
            fulfill
            fulfillPoint
            fulfillCampaignId
            birth
            birthPoint
            birthCampaignId
            birthItem {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
          }
          notification {
            register
            registerCampainId
            save
            saveCampaignId
            use
            useCampaignId
            expire
            expireCampaignId
          }
        }
        kiosk {
          status
          callable
          payable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          exchangeTicket
          columnSize
          discounts {
            condition
            couponId
          }
          menuDescription
          updatedAt
          createdAt
        }
        receipt {
          salesSlip
          orderList
          barcode
          footnote
          phone
          orderNumberHide
          orderSheetFontSize
          billsBusinessInfo
          giftFootnote
          giftReceipt
        }
        menuOrder {
          callOptions {
            index
            label
          }
          orderable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          columnSize
          fontSize
          notice
          resetTime
          enableCash
          boardType
        }
        kds {
          layout
          alerts {
            urgent
            warning
          }
          disabledProdIds
          orderNotify
        }
        mini {
          defaultItemName
        }
        orderPlanId
        menu {
          board {
            textSize
            columnSize
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            enableFavorite
          }
          list {
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            shortcuts {
              type
              title
              itemName
              itemPrice
              active
            }
          }
          navOrder
        }
        menus {
          parentId
          position
          page
          pageTitle
        }
        favMenus {
          parentId
          position
          page
          pageTitle
        }
        catMenus {
          title
          index
          ids
          parentId
        }
        market {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          paused
          takeoutDiscount
          creditDiscount
          takeout
          intro
          notice
          bookTable
          bookTableNote
          depositAmount
          delivery
          deliveryFee {
            start
            end
            fee
          }
          waitingTime
          itemIds
          updatedAt
          createdAt
        }
        waiting {
          status
          waitingOptions {
            id
            type
            name
            nameMl {
              en
              zh
              ja
            }
            items {
              id
              name
              nameMl {
                en
                zh
                ja
              }
              limitQuantity
            }
            required
            updatedAt
            createdAt
          }
          waitingMinutes
          waitingMinutesHide
          hasForeigner
          foreignerMethod
          notifications {
            created
            ready
            requested
            cancelled
          }
          notices {
            createdNotice
            createdNoticeMl {
              en
              zh
              ja
            }
            requestedNotice
            requestedNoticeMl {
              en
              zh
              ja
            }
          }
          settings {
            autoCancel
            autoCancelMinutes
          }
          updatedAt
          createdAt
        }
        view {
          order {
            excludeColumns
            exportTemplates {
              name
              type
              headers {
                key
                label
              }
            }
          }
          itemReport {
            excludeColumns
          }
          summaryReport {
            excludeColumns
          }
          shippingOrder {
            excludeColumns
          }
          trsItemReport {
            excludeColumns
          }
        }
        subscription {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          updatedAt
          createdAt
        }
        settings {
          mode
          table
          diningOption
          retailOption
          orderAlarm
          orderConfirm
          addressInput
          autoPay
          autoReturnItem
          autoSoldout
          menuGridSize
          menuMode
          tableMode
          orderNotify
          orderNotifySkippable
          disablePhonePrefix
          autoCloseScreen
          orderNumberPrefix {
            pos
            kiosk
            menuOrder
          }
          orderMgmt {
            kiosk
            menuorder
            delivery
            pos
          }
          reportBasis
          orderOrigins
          beeper {
            active
            coverage
            skippable
          }
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
        }
        devices {
          id
          ident
          locationId
          system
          ipAddress
          brand
          model
          name
          type
          deviceType
          display {
            height
            width
          }
          protocol
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          barcodeReader
          appVersion
          sysVersion
          status
          password
          msgToken
          parentId
          childId
          sleepMode
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
          terminalId
          printOptions {
            port
            baudRate
            receipt
            receiptButtonHide
            bills
            refund
            salesEnd
            order
            orderBeep
            orderCount
            orderExcludeIds
            delivery
          }
          updatedAt
          createdAt
        }
        tables {
          id
          width
          height
          name
          axisX
          axisY
          screenHeight
          screenWidth
          initialX
          initialY
          shape
          layout {
            x
            y
            w
            h
          }
          color
          orderId
          disabled
        }
        boardTables {
          title
          index
          items {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
        }
        gridTables {
          id
          name
          orderId
          index
          page
        }
        modifierSoldoutIds
        delivery {
          baemin
          coupang
          yogiyo
          enableGenProd
          updatedAt
          createdAt
        }
        createdAt
        updatedAt
        sales {
          startedAt
          endedAt
          reserves
          actualBalance
          currency
          note
          estimateBalance
          detail {
            totalSales
            totalRefund
            totalTax
            totalDiscount
            supplyAmount
            totalSalesCount
            cash {
              sales
              refund
            }
            card {
              sales
              refund
            }
            credit {
              sales
              refund
            }
            barcode {
              sales
              refund
            }
            extra {
              sales
              refund
            }
            refund {
              sales
              refund
            }
            products {
              id
              name
              quantity
              amount
            }
            origins {
              pos
              kiosk
              menuorder
              delivery
              market
            }
          }
          reservesHistories {
            type
            amount
            note
            createdEmployeeId
            createdAt
          }
        }
      }
      customer {
        id
        customerId
        storeId
        sources {
          id
          type
          name
          ident
          createdAt
        }
        isFavorite
        locationId
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        favoriteLocationIds
        visitedLocationIds
        locations {
          id
          locationId
          isFavorite
          status
          note
          campaignBlockedAt
          blacklistedAt
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          subscriptions {
            id
            planId
            payStatus
            sessionId
            nextPayDate
            paidAt
            unsubscribedAt
            used
            lastUsedAt
            orderId
            unsubscribeRequestedAt
            startDate
            expireDate
            updatedAt
            createdAt
          }
          registeredAt
          salesDate
          updatedAt
          createdAt
        }
        status
        registeredAt
        campaignBlockedAt
        note
        removedAt
        updatedAt
        createdAt
        points {
          items {
            id
            locationId
            storeId
            type
            name
            amount
            origin
            provider
            providerCustomerId
            providerSyncedAt
            currency
            point
            extraPoint
            extraExpire
            items {
              type
              itemId
              name
              parentId
              point
              amount
              quantity
            }
            quantity
            note
            orderId
            referId
            orderNumberPrefix
            orderNumber
            customerId
            pointTransactionCustomerId
            expiredAt
            salesDate
            updatedAt
            createdAt
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
          }
          nextToken
        }
        credits {
          items {
            id
            locationId
            storeId
            name
            type
            amount
            origin
            note
            currency
            parentId
            orderId
            orderNumberPrefix
            orderNumber
            customerId
            creditTransactionCustomerId
            description
            salesDate
            createdAt
            updatedAt
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
          }
          nextToken
        }
        _delta
        orders {
          items {
            id
            orderLocationId
            numberPrefix
            number
            beeper
            enterpriseId
            storeId
            customerId
            orderCustomerId
            state
            amount
            amountPaid
            origin
            subOrigin
            originId
            customerProvider
            providerCustomerId
            amountChanged
            amountReturned
            tax
            currency
            quantity
            shippingType
            diningOption
            diningState
            retailOption
            retailState
            name
            phone
            address
            zip
            receivers {
              id
              name
              phone
              zip
              address1
              address2
              addressState
              addressCity
              addressStreet
              addressFull
              note
              shippingCompanyCode
              shippingAcceptCount
              shippingStartCount
              trackingNumber
              privacyAgreedAt
            }
            tableId
            tableIds
            parentDeviceId
            deviceId
            reserveDate
            pickupDate
            shippingDate
            items {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            createdEmployeeId
            createdDeviceId
            updatedDeviceId
            shippingLocationId
            confirmedEmployeeId
            confirmedAt
            createdAt
            paidAt
            salesDate
            returnedAt
            shippedAt
            returnShippedAt
            returnSalesDate
            updatedAt
            noticeCount
            visitorCount
            totalPoint
            pointSaved
            receiptId
            receiptNumber
            receiptCount
            alarmCustomerId
            receiptCustomerId
            note
            request
            charges {
              id
              method
              extraMethod
              gateway
              amount
              tax
              taxRefundAmount
              purchaser {
                name
                number
                nation
                address
              }
              supplyAmount
              taxFreeAmount
              amountRefunded
              createdEmployeeId
              createdDeviceId
              createdAt
              creditTransactionId
              balance
              splitType
              pg
              van
              origin
              originId
              note
              brand
              name
              ident
              installment
              transactionId
              approver
              issuerCode
              acquirer
              acquirerCode
              acquirerName
              refundable
              recurring
              approvalCode
              approvalDate
              approvalNumber
              signature {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              refunds {
                id
                amount
                supplyAmount
                taxFreeAmount
                tax
                taxRefundAmount
                creditTransactionId
                approvalNumber
                approvalDate
                transactionId
                origin
                originId
                currency
                reason
                createdEmployeeId
                createdDeviceId
                createdAt
              }
            }
            returns {
              amount
              currency
              items {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              refundId
              createdAt
            }
            fee
            revision
            _delta
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
            orderCustomer {
              id
              enterpriseId
              storeId
              locationId
              customerId
              provider
              providerCustomerId
              tier
              customerCreatedAt
              ident
              identType
              type
              name
              nameKeyword
              payments {
                id
                method
                pg
                number
                name
                expMonth
                expYear
                billingKey
                updatedAt
                createdAt
              }
              attributes {
                name
                type
                value
              }
              addresses {
                id
                main
                detail
                storeId
              }
              phone
              email
              birth
              agreements {
                privacy
                provider
                eula
                marketing
                updatedAt
                createdAt
              }
              ageGroup
              gender
              tags {
                tagId
                createdLocationId
              }
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              status
              registeredAt
              salesDate
              campaignBlockedAt
              blacklistedAt
              isFavorite
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              subscriptions {
                id
                planId
                payStatus
                sessionId
                nextPayDate
                paidAt
                unsubscribedAt
                used
                lastUsedAt
                orderId
                unsubscribeRequestedAt
                startDate
                expireDate
                updatedAt
                createdAt
              }
              note
              sources {
                id
                type
                name
                ident
                createdAt
              }
              removedAt
              updatedAt
              createdAt
              _delta
              timestamp
              expdate
            }
          }
          nextToken
        }
        location {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        store {
          id
          enterpriseId
          info {
            name
            phone
            email
            representative
            businessType
            businessNumber
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            industryType
            sendNumber
            currency
            address
            zip
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            csPhone
          }
          policy {
            multiLocation
            provider {
              key
              ident
              customer
              coupon
              inventory
              locationId
            }
          }
          currency
          payments {
            card
            cardJoined
            cash
            credit
            refund
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          plans {
            menus {
              id
              name
              mode
              size
              menus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              pages {
                index
                parentId
                title
              }
              updatedAt
              createdAt
            }
            orders {
              id
              name
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              updatedAt
              createdAt
            }
          }
          taxes {
            id
            type
            name
            enabled
            rate
            method
            targetType
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          item {
            attributes {
              name
              type
              value
            }
            actualPrice
            skuMgmt
            barcodeMgmt
            stockTrack
            variablePrice
            locationCreatable
          }
          inventory {
            attributes {
              name
              type
              value
            }
            expiryDateMgmt
            expiryDateAlertDays
          }
          customer {
            attributes {
              name
              type
              value
            }
          }
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          kakao {
            agency
            key
            channelId
            categoryCode
            status
            updatedAt
            createdAt
          }
          review {
            status
            campaignId
            updatedAt
            createdAt
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mirror {
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            video {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          notification {
            options {
              type
              email
              sms
            }
          }
          groups {
            items {
              id
              name
              type
              storeGroupsId
              locationGroupsId
              key
              conditions {
                key
                object
                field
                value
                operation
                type
              }
              count
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          location {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          locations {
            items {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            nextToken
          }
        }
        customer {
          id
          ident
          identType
          type
          name
          addresses {
            id
            main
            detail
            storeId
          }
          phone
          email
          birth
          agreements {
            privacy
            provider
            eula
            marketing
            updatedAt
            createdAt
          }
          ageGroup
          gender
          updatedAt
          createdAt
          stores {
            items {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
            nextToken
          }
        }
      }
      orderCustomer {
        id
        enterpriseId
        storeId
        locationId
        customerId
        provider
        providerCustomerId
        tier
        customerCreatedAt
        ident
        identType
        type
        name
        nameKeyword
        payments {
          id
          method
          pg
          number
          name
          expMonth
          expYear
          billingKey
          updatedAt
          createdAt
        }
        attributes {
          name
          type
          value
        }
        addresses {
          id
          main
          detail
          storeId
        }
        phone
        email
        birth
        agreements {
          privacy
          provider
          eula
          marketing
          updatedAt
          createdAt
        }
        ageGroup
        gender
        tags {
          tagId
          createdLocationId
        }
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        status
        registeredAt
        salesDate
        campaignBlockedAt
        blacklistedAt
        isFavorite
        locations {
          id
          locationId
          isFavorite
          status
          note
          campaignBlockedAt
          blacklistedAt
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          subscriptions {
            id
            planId
            payStatus
            sessionId
            nextPayDate
            paidAt
            unsubscribedAt
            used
            lastUsedAt
            orderId
            unsubscribeRequestedAt
            startDate
            expireDate
            updatedAt
            createdAt
          }
          registeredAt
          salesDate
          updatedAt
          createdAt
        }
        subscriptions {
          id
          planId
          payStatus
          sessionId
          nextPayDate
          paidAt
          unsubscribedAt
          used
          lastUsedAt
          orderId
          unsubscribeRequestedAt
          startDate
          expireDate
          updatedAt
          createdAt
        }
        note
        sources {
          id
          type
          name
          ident
          createdAt
        }
        removedAt
        updatedAt
        createdAt
        _delta
        timestamp
        expdate
      }
    }
  }
`;
export const createLink = /* GraphQL */ `
  mutation CreateLink($input: CreateLinkInput!) {
    createLink(input: $input) {
      id
      type
      originUrl
      redirectUrl
      parentId
      sourceId
      openedAt
      updatedAt
      createdAt
    }
  }
`;
export const updateLink = /* GraphQL */ `
  mutation UpdateLink($input: UpdateLinkInput!) {
    updateLink(input: $input) {
      id
      type
      originUrl
      redirectUrl
      parentId
      sourceId
      openedAt
      updatedAt
      createdAt
    }
  }
`;
export const deleteLink = /* GraphQL */ `
  mutation DeleteLink($input: DeleteLinkInput!) {
    deleteLink(input: $input) {
      id
      type
      originUrl
      redirectUrl
      parentId
      sourceId
      openedAt
      updatedAt
      createdAt
    }
  }
`;
export const createCustomerGroup = /* GraphQL */ `
  mutation CreateCustomerGroup($input: CreateCustomerGroupInput!) {
    createCustomerGroup(input: $input) {
      id
      name
      type
      storeGroupsId
      locationGroupsId
      key
      conditions {
        key
        object
        field
        operations {
          operation
          type
          value
        }
        value
        operation
        type
      }
      count
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomerGroup = /* GraphQL */ `
  mutation UpdateCustomerGroup($input: UpdateCustomerGroupInput!) {
    updateCustomerGroup(input: $input) {
      id
      name
      type
      storeGroupsId
      locationGroupsId
      key
      conditions {
        key
        object
        field
        operations {
          operation
          type
          value
        }
        value
        operation
        type
      }
      count
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomerGroup = /* GraphQL */ `
  mutation DeleteCustomerGroup($input: DeleteCustomerGroupInput!) {
    deleteCustomerGroup(input: $input) {
      id
      name
      type
      storeGroupsId
      locationGroupsId
      key
      conditions {
        key
        object
        field
        operations {
          operation
          type
          value
        }
        value
        operation
        type
      }
      count
      createdAt
      updatedAt
    }
  }
`;
export const createReportHistory = /* GraphQL */ `
  mutation CreateReportHistory($input: CreateReportHistoryInput!) {
    createReportHistory(input: $input) {
      id
      storeId
      type
      start
      end
      results {
        id
        phone
        resultCode
        resultCodeName
      }
      requestId
      title
      message
      error
      requestedAt
      templateId
      status
      sentAt
      updatedAt
      createdAt
    }
  }
`;
export const updateReportHistory = /* GraphQL */ `
  mutation UpdateReportHistory($input: UpdateReportHistoryInput!) {
    updateReportHistory(input: $input) {
      id
      storeId
      type
      start
      end
      results {
        id
        phone
        resultCode
        resultCodeName
      }
      requestId
      title
      message
      error
      requestedAt
      templateId
      status
      sentAt
      updatedAt
      createdAt
    }
  }
`;
export const deleteReportHistory = /* GraphQL */ `
  mutation DeleteReportHistory($input: DeleteReportHistoryInput!) {
    deleteReportHistory(input: $input) {
      id
      storeId
      type
      start
      end
      results {
        id
        phone
        resultCode
        resultCodeName
      }
      requestId
      title
      message
      error
      requestedAt
      templateId
      status
      sentAt
      updatedAt
      createdAt
    }
  }
`;
export const createBugReport = /* GraphQL */ `
  mutation CreateBugReport($input: CreateBugReportInput!) {
    createBugReport(input: $input) {
      id
      storeId
      employeeId
      locationId
      category
      name
      meta
      logs
      device {
        id
        ident
        locationId
        system
        ipAddress
        brand
        model
        name
        type
        deviceType
        display {
          height
          width
        }
        protocol
        images {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        barcodeReader
        appVersion
        sysVersion
        status
        password
        msgToken
        parentId
        childId
        sleepMode
        kioskOptions {
          mode
          isActive
          tableId
          orderId
          callable
          payable
          enableDiningOption
          resetTime
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          columnSize
        }
        terminalId
        printOptions {
          port
          baudRate
          receipt
          receiptButtonHide
          bills
          refund
          salesEnd
          order
          orderBeep
          orderCount
          orderExcludeIds
          delivery
        }
        updatedAt
        createdAt
      }
      updatedAt
      createdAt
      expdate
    }
  }
`;
export const updateBugReport = /* GraphQL */ `
  mutation UpdateBugReport($input: UpdateBugReportInput!) {
    updateBugReport(input: $input) {
      id
      storeId
      employeeId
      locationId
      category
      name
      meta
      logs
      device {
        id
        ident
        locationId
        system
        ipAddress
        brand
        model
        name
        type
        deviceType
        display {
          height
          width
        }
        protocol
        images {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        barcodeReader
        appVersion
        sysVersion
        status
        password
        msgToken
        parentId
        childId
        sleepMode
        kioskOptions {
          mode
          isActive
          tableId
          orderId
          callable
          payable
          enableDiningOption
          resetTime
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          columnSize
        }
        terminalId
        printOptions {
          port
          baudRate
          receipt
          receiptButtonHide
          bills
          refund
          salesEnd
          order
          orderBeep
          orderCount
          orderExcludeIds
          delivery
        }
        updatedAt
        createdAt
      }
      updatedAt
      createdAt
      expdate
    }
  }
`;
export const deleteBugReport = /* GraphQL */ `
  mutation DeleteBugReport($input: DeleteBugReportInput!) {
    deleteBugReport(input: $input) {
      id
      storeId
      employeeId
      locationId
      category
      name
      meta
      logs
      device {
        id
        ident
        locationId
        system
        ipAddress
        brand
        model
        name
        type
        deviceType
        display {
          height
          width
        }
        protocol
        images {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        barcodeReader
        appVersion
        sysVersion
        status
        password
        msgToken
        parentId
        childId
        sleepMode
        kioskOptions {
          mode
          isActive
          tableId
          orderId
          callable
          payable
          enableDiningOption
          resetTime
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          columnSize
        }
        terminalId
        printOptions {
          port
          baudRate
          receipt
          receiptButtonHide
          bills
          refund
          salesEnd
          order
          orderBeep
          orderCount
          orderExcludeIds
          delivery
        }
        updatedAt
        createdAt
      }
      updatedAt
      createdAt
      expdate
    }
  }
`;
export const createEventHistory = /* GraphQL */ `
  mutation CreateEventHistory($input: CreateEventHistoryInput!) {
    createEventHistory(input: $input) {
      id
      storeId
      employeeId
      locationId
      category
      device {
        id
        ident
        locationId
        system
        ipAddress
        brand
        model
        name
        type
        deviceType
        display {
          height
          width
        }
        protocol
        images {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        barcodeReader
        appVersion
        sysVersion
        status
        password
        msgToken
        parentId
        childId
        sleepMode
        kioskOptions {
          mode
          isActive
          tableId
          orderId
          callable
          payable
          enableDiningOption
          resetTime
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          columnSize
        }
        terminalId
        printOptions {
          port
          baudRate
          receipt
          receiptButtonHide
          bills
          refund
          salesEnd
          order
          orderBeep
          orderCount
          orderExcludeIds
          delivery
        }
        updatedAt
        createdAt
      }
      updatedAt
      createdAt
      expdate
    }
  }
`;
export const updateEventHistory = /* GraphQL */ `
  mutation UpdateEventHistory($input: UpdateEventHistoryInput!) {
    updateEventHistory(input: $input) {
      id
      storeId
      employeeId
      locationId
      category
      device {
        id
        ident
        locationId
        system
        ipAddress
        brand
        model
        name
        type
        deviceType
        display {
          height
          width
        }
        protocol
        images {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        barcodeReader
        appVersion
        sysVersion
        status
        password
        msgToken
        parentId
        childId
        sleepMode
        kioskOptions {
          mode
          isActive
          tableId
          orderId
          callable
          payable
          enableDiningOption
          resetTime
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          columnSize
        }
        terminalId
        printOptions {
          port
          baudRate
          receipt
          receiptButtonHide
          bills
          refund
          salesEnd
          order
          orderBeep
          orderCount
          orderExcludeIds
          delivery
        }
        updatedAt
        createdAt
      }
      updatedAt
      createdAt
      expdate
    }
  }
`;
export const deleteEventHistory = /* GraphQL */ `
  mutation DeleteEventHistory($input: DeleteEventHistoryInput!) {
    deleteEventHistory(input: $input) {
      id
      storeId
      employeeId
      locationId
      category
      device {
        id
        ident
        locationId
        system
        ipAddress
        brand
        model
        name
        type
        deviceType
        display {
          height
          width
        }
        protocol
        images {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        barcodeReader
        appVersion
        sysVersion
        status
        password
        msgToken
        parentId
        childId
        sleepMode
        kioskOptions {
          mode
          isActive
          tableId
          orderId
          callable
          payable
          enableDiningOption
          resetTime
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          columnSize
        }
        terminalId
        printOptions {
          port
          baudRate
          receipt
          receiptButtonHide
          bills
          refund
          salesEnd
          order
          orderBeep
          orderCount
          orderExcludeIds
          delivery
        }
        updatedAt
        createdAt
      }
      updatedAt
      createdAt
      expdate
    }
  }
`;
export const createCouponTransaction = /* GraphQL */ `
  mutation CreateCouponTransaction($input: CreateCouponTransactionInput!) {
    createCouponTransaction(input: $input) {
      id
      usedAt
      state
      amount
      quantity
      refundedAt
      orderId
      customerId
      couponTransactionCustomerId
      updatedAt
      createdAt
      expiredDate
      barcode
      generateLocationId
      generatedAt
      couponTransactionsId
      enterpriseId
      storeId
      locationId
      activeLocationIds
      inactiveLocationIds
      publishableLocationIds
      provider
      providerCouponId
      providerTransactionId
      providerCustomerId
      name
      code
      type
      method
      conditionType
      conditionQuantity
      conditionAmount
      conditionItems
      conditionTimes {
        sun {
          open
          close
        }
        mon {
          open
          close
        }
        tue {
          open
          close
        }
        wed {
          open
          close
        }
        thu {
          open
          close
        }
        fri {
          open
          close
        }
        sat {
          open
          close
        }
      }
      conditionTier
      discountMethod
      discountValue
      applyScope
      applyItemId
      applyCandidateItemIds
      campaignId
      startedAt
      endedAt
    }
  }
`;
export const updateCouponTransaction = /* GraphQL */ `
  mutation UpdateCouponTransaction($input: UpdateCouponTransactionInput!) {
    updateCouponTransaction(input: $input) {
      id
      usedAt
      state
      amount
      quantity
      refundedAt
      orderId
      customerId
      couponTransactionCustomerId
      updatedAt
      createdAt
      expiredDate
      barcode
      generateLocationId
      generatedAt
      couponTransactionsId
      enterpriseId
      storeId
      locationId
      activeLocationIds
      inactiveLocationIds
      publishableLocationIds
      provider
      providerCouponId
      providerTransactionId
      providerCustomerId
      name
      code
      type
      method
      conditionType
      conditionQuantity
      conditionAmount
      conditionItems
      conditionTimes {
        sun {
          open
          close
        }
        mon {
          open
          close
        }
        tue {
          open
          close
        }
        wed {
          open
          close
        }
        thu {
          open
          close
        }
        fri {
          open
          close
        }
        sat {
          open
          close
        }
      }
      conditionTier
      discountMethod
      discountValue
      applyScope
      applyItemId
      applyCandidateItemIds
      campaignId
      startedAt
      endedAt
    }
  }
`;
export const deleteCouponTransaction = /* GraphQL */ `
  mutation DeleteCouponTransaction($input: DeleteCouponTransactionInput!) {
    deleteCouponTransaction(input: $input) {
      id
      usedAt
      state
      amount
      quantity
      refundedAt
      orderId
      customerId
      couponTransactionCustomerId
      updatedAt
      createdAt
      expiredDate
      barcode
      generateLocationId
      generatedAt
      couponTransactionsId
      enterpriseId
      storeId
      locationId
      activeLocationIds
      inactiveLocationIds
      publishableLocationIds
      provider
      providerCouponId
      providerTransactionId
      providerCustomerId
      name
      code
      type
      method
      conditionType
      conditionQuantity
      conditionAmount
      conditionItems
      conditionTimes {
        sun {
          open
          close
        }
        mon {
          open
          close
        }
        tue {
          open
          close
        }
        wed {
          open
          close
        }
        thu {
          open
          close
        }
        fri {
          open
          close
        }
        sat {
          open
          close
        }
      }
      conditionTier
      discountMethod
      discountValue
      applyScope
      applyItemId
      applyCandidateItemIds
      campaignId
      startedAt
      endedAt
    }
  }
`;
export const createCouponDelta = /* GraphQL */ `
  mutation CreateCouponDelta($input: CreateCouponDeltaInput!) {
    createCouponDelta(input: $input) {
      id
      storeId
      enterpriseId
      locationId
      activeLocationIds
      inactiveLocationIds
      publishableLocationIds
      name
      description
      availableOrigins
      code
      type
      method
      tranCount
      generateCount
      publishCount
      limitPublishCount
      duplicatePublish
      conditionType
      conditionQuantity
      conditionAmount
      conditionItems
      conditionItemsOperator
      conditionTimes {
        sun {
          open
          close
        }
        mon {
          open
          close
        }
        tue {
          open
          close
        }
        wed {
          open
          close
        }
        thu {
          open
          close
        }
        fri {
          open
          close
        }
        sat {
          open
          close
        }
      }
      conditionTier
      discountMethod
      discountValue
      applyScope
      applyItemId
      applyCandidateItemIds
      referId
      validDateType
      validValue
      image {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      notice
      pointSavable
      startedAt
      endedAt
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const updateCouponDelta = /* GraphQL */ `
  mutation UpdateCouponDelta($input: UpdateCouponDeltaInput!) {
    updateCouponDelta(input: $input) {
      id
      storeId
      enterpriseId
      locationId
      activeLocationIds
      inactiveLocationIds
      publishableLocationIds
      name
      description
      availableOrigins
      code
      type
      method
      tranCount
      generateCount
      publishCount
      limitPublishCount
      duplicatePublish
      conditionType
      conditionQuantity
      conditionAmount
      conditionItems
      conditionItemsOperator
      conditionTimes {
        sun {
          open
          close
        }
        mon {
          open
          close
        }
        tue {
          open
          close
        }
        wed {
          open
          close
        }
        thu {
          open
          close
        }
        fri {
          open
          close
        }
        sat {
          open
          close
        }
      }
      conditionTier
      discountMethod
      discountValue
      applyScope
      applyItemId
      applyCandidateItemIds
      referId
      validDateType
      validValue
      image {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      notice
      pointSavable
      startedAt
      endedAt
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const deleteCouponDelta = /* GraphQL */ `
  mutation DeleteCouponDelta($input: DeleteCouponDeltaInput!) {
    deleteCouponDelta(input: $input) {
      id
      storeId
      enterpriseId
      locationId
      activeLocationIds
      inactiveLocationIds
      publishableLocationIds
      name
      description
      availableOrigins
      code
      type
      method
      tranCount
      generateCount
      publishCount
      limitPublishCount
      duplicatePublish
      conditionType
      conditionQuantity
      conditionAmount
      conditionItems
      conditionItemsOperator
      conditionTimes {
        sun {
          open
          close
        }
        mon {
          open
          close
        }
        tue {
          open
          close
        }
        wed {
          open
          close
        }
        thu {
          open
          close
        }
        fri {
          open
          close
        }
        sat {
          open
          close
        }
      }
      conditionTier
      discountMethod
      discountValue
      applyScope
      applyItemId
      applyCandidateItemIds
      referId
      validDateType
      validValue
      image {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      notice
      pointSavable
      startedAt
      endedAt
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const createCampaignResult = /* GraphQL */ `
  mutation CreateCampaignResult($input: CreateCampaignResultInput!) {
    createCampaignResult(input: $input) {
      id
      enterpriseId
      storeId
      locationId
      customerId
      campaignResultCustomerId
      customerProvider
      providerCustomerId
      linkId
      requestId
      title
      message
      phone
      msgStatus
      campaignResultsId
      requestedAt
      resultCode
      resultCodeName
      templateId
      statusCode
      method
      sendType
      sender
      sentAt
      creditTransactionId
      updatedAt
      createdAt
    }
  }
`;
export const updateCampaignResult = /* GraphQL */ `
  mutation UpdateCampaignResult($input: UpdateCampaignResultInput!) {
    updateCampaignResult(input: $input) {
      id
      enterpriseId
      storeId
      locationId
      customerId
      campaignResultCustomerId
      customerProvider
      providerCustomerId
      linkId
      requestId
      title
      message
      phone
      msgStatus
      campaignResultsId
      requestedAt
      resultCode
      resultCodeName
      templateId
      statusCode
      method
      sendType
      sender
      sentAt
      creditTransactionId
      updatedAt
      createdAt
    }
  }
`;
export const deleteCampaignResult = /* GraphQL */ `
  mutation DeleteCampaignResult($input: DeleteCampaignResultInput!) {
    deleteCampaignResult(input: $input) {
      id
      enterpriseId
      storeId
      locationId
      customerId
      campaignResultCustomerId
      customerProvider
      providerCustomerId
      linkId
      requestId
      title
      message
      phone
      msgStatus
      campaignResultsId
      requestedAt
      resultCode
      resultCodeName
      templateId
      statusCode
      method
      sendType
      sender
      sentAt
      creditTransactionId
      updatedAt
      createdAt
    }
  }
`;
export const createCampaignTemplate = /* GraphQL */ `
  mutation CreateCampaignTemplate($input: CreateCampaignTemplateInput!) {
    createCampaignTemplate(input: $input) {
      id
      storeId
      locationId
      ads
      name
      title
      message
      updatedAt
      createdAt
    }
  }
`;
export const updateCampaignTemplate = /* GraphQL */ `
  mutation UpdateCampaignTemplate($input: UpdateCampaignTemplateInput!) {
    updateCampaignTemplate(input: $input) {
      id
      storeId
      locationId
      ads
      name
      title
      message
      updatedAt
      createdAt
    }
  }
`;
export const deleteCampaignTemplate = /* GraphQL */ `
  mutation DeleteCampaignTemplate($input: DeleteCampaignTemplateInput!) {
    deleteCampaignTemplate(input: $input) {
      id
      storeId
      locationId
      ads
      name
      title
      message
      updatedAt
      createdAt
    }
  }
`;
export const createCampaign = /* GraphQL */ `
  mutation CreateCampaign($input: CreateCampaignInput!) {
    createCampaign(input: $input) {
      id
      enterpriseId
      storeId
      locationId
      type
      name
      trigger
      agency
      triggerTiming
      couponId
      description
      recipientProvider
      recipientId
      campaignRecipientId
      recipient {
        id
        name
        type
        storeGroupsId
        locationGroupsId
        key
        conditions {
          key
          object
          field
          operations {
            operation
            type
            value
          }
          value
          operation
          type
        }
        count
        createdAt
        updatedAt
      }
      campaignRecipientTagId
      campaignRecipientLocationIds
      recipientList
      recipientCount
      filter {
        type
        operation
        value
      }
      includes
      sendNow
      reserveDate
      sentAt
      title
      message
      image {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      imageType
      imageLink
      buttons {
        order
        type
        name
        linkMo
        linkPc
        target
      }
      params {
        key
        value
      }
      templateCode
      method
      tracking
      trackingLink
      block
      ads
      sender
      success
      failure
      status
      creditTransactionId
      results {
        items {
          id
          enterpriseId
          storeId
          locationId
          customerId
          campaignResultCustomerId
          customerProvider
          providerCustomerId
          linkId
          requestId
          title
          message
          phone
          msgStatus
          campaignResultsId
          requestedAt
          resultCode
          resultCodeName
          templateId
          statusCode
          method
          sendType
          sender
          sentAt
          creditTransactionId
          updatedAt
          createdAt
        }
        nextToken
      }
      updatedAt
      createdAt
      report
      system
      recipientTag {
        id
        label
        description
        color
        customerIds
        storeId
        locationId
        type
        key
        conditions {
          key
          object
          field
          operations {
            operation
            type
            value
          }
          value
          operation
          type
        }
        updatedAt
        createdAt
      }
    }
  }
`;
export const updateCampaign = /* GraphQL */ `
  mutation UpdateCampaign($input: UpdateCampaignInput!) {
    updateCampaign(input: $input) {
      id
      enterpriseId
      storeId
      locationId
      type
      name
      trigger
      agency
      triggerTiming
      couponId
      description
      recipientProvider
      recipientId
      campaignRecipientId
      recipient {
        id
        name
        type
        storeGroupsId
        locationGroupsId
        key
        conditions {
          key
          object
          field
          operations {
            operation
            type
            value
          }
          value
          operation
          type
        }
        count
        createdAt
        updatedAt
      }
      campaignRecipientTagId
      campaignRecipientLocationIds
      recipientList
      recipientCount
      filter {
        type
        operation
        value
      }
      includes
      sendNow
      reserveDate
      sentAt
      title
      message
      image {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      imageType
      imageLink
      buttons {
        order
        type
        name
        linkMo
        linkPc
        target
      }
      params {
        key
        value
      }
      templateCode
      method
      tracking
      trackingLink
      block
      ads
      sender
      success
      failure
      status
      creditTransactionId
      results {
        items {
          id
          enterpriseId
          storeId
          locationId
          customerId
          campaignResultCustomerId
          customerProvider
          providerCustomerId
          linkId
          requestId
          title
          message
          phone
          msgStatus
          campaignResultsId
          requestedAt
          resultCode
          resultCodeName
          templateId
          statusCode
          method
          sendType
          sender
          sentAt
          creditTransactionId
          updatedAt
          createdAt
        }
        nextToken
      }
      updatedAt
      createdAt
      report
      system
      recipientTag {
        id
        label
        description
        color
        customerIds
        storeId
        locationId
        type
        key
        conditions {
          key
          object
          field
          operations {
            operation
            type
            value
          }
          value
          operation
          type
        }
        updatedAt
        createdAt
      }
    }
  }
`;
export const deleteCampaign = /* GraphQL */ `
  mutation DeleteCampaign($input: DeleteCampaignInput!) {
    deleteCampaign(input: $input) {
      id
      enterpriseId
      storeId
      locationId
      type
      name
      trigger
      agency
      triggerTiming
      couponId
      description
      recipientProvider
      recipientId
      campaignRecipientId
      recipient {
        id
        name
        type
        storeGroupsId
        locationGroupsId
        key
        conditions {
          key
          object
          field
          operations {
            operation
            type
            value
          }
          value
          operation
          type
        }
        count
        createdAt
        updatedAt
      }
      campaignRecipientTagId
      campaignRecipientLocationIds
      recipientList
      recipientCount
      filter {
        type
        operation
        value
      }
      includes
      sendNow
      reserveDate
      sentAt
      title
      message
      image {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      imageType
      imageLink
      buttons {
        order
        type
        name
        linkMo
        linkPc
        target
      }
      params {
        key
        value
      }
      templateCode
      method
      tracking
      trackingLink
      block
      ads
      sender
      success
      failure
      status
      creditTransactionId
      results {
        items {
          id
          enterpriseId
          storeId
          locationId
          customerId
          campaignResultCustomerId
          customerProvider
          providerCustomerId
          linkId
          requestId
          title
          message
          phone
          msgStatus
          campaignResultsId
          requestedAt
          resultCode
          resultCodeName
          templateId
          statusCode
          method
          sendType
          sender
          sentAt
          creditTransactionId
          updatedAt
          createdAt
        }
        nextToken
      }
      updatedAt
      createdAt
      report
      system
      recipientTag {
        id
        label
        description
        color
        customerIds
        storeId
        locationId
        type
        key
        conditions {
          key
          object
          field
          operations {
            operation
            type
            value
          }
          value
          operation
          type
        }
        updatedAt
        createdAt
      }
    }
  }
`;
export const createReviewCampaignResult = /* GraphQL */ `
  mutation CreateReviewCampaignResult(
    $input: CreateReviewCampaignResultInput!
  ) {
    createReviewCampaignResult(input: $input) {
      id
      campaignId
      enterpriseId
      storeId
      locationId
      orderId
      orderNumberPrefix
      orderNumber
      customerId
      storeCustomerId
      reserveDate
      sentAt
      submittedAt
      rate
      comment
      answers
      updatedAt
      createdAt
    }
  }
`;
export const deleteReviewCampaignResult = /* GraphQL */ `
  mutation DeleteReviewCampaignResult(
    $input: DeleteReviewCampaignResultInput!
  ) {
    deleteReviewCampaignResult(input: $input) {
      id
      campaignId
      enterpriseId
      storeId
      locationId
      orderId
      orderNumberPrefix
      orderNumber
      customerId
      storeCustomerId
      reserveDate
      sentAt
      submittedAt
      rate
      comment
      answers
      updatedAt
      createdAt
    }
  }
`;
export const createReviewCampaign = /* GraphQL */ `
  mutation CreateReviewCampaign($input: CreateReviewCampaignInput!) {
    createReviewCampaign(input: $input) {
      id
      status
      enterpriseId
      storeId
      locationId
      name
      delay
      trial
      limit
      basic
      rewardPoint
      templateId
      templateName
      questions
      stats
      startedAt
      endedAt
      updatedAt
      createdAt
    }
  }
`;
export const updateReviewCampaign = /* GraphQL */ `
  mutation UpdateReviewCampaign($input: UpdateReviewCampaignInput!) {
    updateReviewCampaign(input: $input) {
      id
      status
      enterpriseId
      storeId
      locationId
      name
      delay
      trial
      limit
      basic
      rewardPoint
      templateId
      templateName
      questions
      stats
      startedAt
      endedAt
      updatedAt
      createdAt
    }
  }
`;
export const deleteReviewCampaign = /* GraphQL */ `
  mutation DeleteReviewCampaign($input: DeleteReviewCampaignInput!) {
    deleteReviewCampaign(input: $input) {
      id
      status
      enterpriseId
      storeId
      locationId
      name
      delay
      trial
      limit
      basic
      rewardPoint
      templateId
      templateName
      questions
      stats
      startedAt
      endedAt
      updatedAt
      createdAt
    }
  }
`;
export const createInventoryAdjustmentTask = /* GraphQL */ `
  mutation CreateInventoryAdjustmentTask(
    $input: CreateInventoryAdjustmentTaskInput!
  ) {
    createInventoryAdjustmentTask(input: $input) {
      id
      type
      storeId
      locationId
      productId
      reason
      skuId
      quantity
      employeeId
      cost
      expiryDate
      note
      origin
      readyQuantity
      unavailableQuantity
      groupId
      referId
      syncHistoryId
      purchaseOrderId
      transferHistoryId
      countHistoryId
      historyId
      eventId
      index
      count
      doneAt
      updatedAt
      createdAt
      expdate
    }
  }
`;
export const updateInventoryAdjustmentTask = /* GraphQL */ `
  mutation UpdateInventoryAdjustmentTask(
    $input: UpdateInventoryAdjustmentTaskInput!
  ) {
    updateInventoryAdjustmentTask(input: $input) {
      id
      type
      storeId
      locationId
      productId
      reason
      skuId
      quantity
      employeeId
      cost
      expiryDate
      note
      origin
      readyQuantity
      unavailableQuantity
      groupId
      referId
      syncHistoryId
      purchaseOrderId
      transferHistoryId
      countHistoryId
      historyId
      eventId
      index
      count
      doneAt
      updatedAt
      createdAt
      expdate
    }
  }
`;
export const deleteInventoryAdjustmentTask = /* GraphQL */ `
  mutation DeleteInventoryAdjustmentTask(
    $input: DeleteInventoryAdjustmentTaskInput!
  ) {
    deleteInventoryAdjustmentTask(input: $input) {
      id
      type
      storeId
      locationId
      productId
      reason
      skuId
      quantity
      employeeId
      cost
      expiryDate
      note
      origin
      readyQuantity
      unavailableQuantity
      groupId
      referId
      syncHistoryId
      purchaseOrderId
      transferHistoryId
      countHistoryId
      historyId
      eventId
      index
      count
      doneAt
      updatedAt
      createdAt
      expdate
    }
  }
`;
export const createInventoryHistory = /* GraphQL */ `
  mutation CreateInventoryHistory($input: CreateInventoryHistoryInput!) {
    createInventoryHistory(input: $input) {
      id
      createdAt
      enterpriseId
      storeId
      productId
      skuId
      locationId
      employeeId
      inventoryId
      referId
      origin
      groupId
      groupHistoryCount
      purchaseOrderId
      totalCost
      cost
      expiryDate
      note
      reason
      transactions {
        parentId
        reason
        quantity
        prevQuantity
        createdAt
      }
      quantity
      prevQuantity
      currQuantity
      readyQuantity
      updatedAt
    }
  }
`;
export const updateInventoryHistory = /* GraphQL */ `
  mutation UpdateInventoryHistory($input: UpdateInventoryHistoryInput!) {
    updateInventoryHistory(input: $input) {
      id
      createdAt
      enterpriseId
      storeId
      productId
      skuId
      locationId
      employeeId
      inventoryId
      referId
      origin
      groupId
      groupHistoryCount
      purchaseOrderId
      totalCost
      cost
      expiryDate
      note
      reason
      transactions {
        parentId
        reason
        quantity
        prevQuantity
        createdAt
      }
      quantity
      prevQuantity
      currQuantity
      readyQuantity
      updatedAt
    }
  }
`;
export const deleteInventoryHistory = /* GraphQL */ `
  mutation DeleteInventoryHistory($input: DeleteInventoryHistoryInput!) {
    deleteInventoryHistory(input: $input) {
      id
      createdAt
      enterpriseId
      storeId
      productId
      skuId
      locationId
      employeeId
      inventoryId
      referId
      origin
      groupId
      groupHistoryCount
      purchaseOrderId
      totalCost
      cost
      expiryDate
      note
      reason
      transactions {
        parentId
        reason
        quantity
        prevQuantity
        createdAt
      }
      quantity
      prevQuantity
      currQuantity
      readyQuantity
      updatedAt
    }
  }
`;
export const createInventoryQuantity = /* GraphQL */ `
  mutation CreateInventoryQuantity($input: CreateInventoryQuantityInput!) {
    createInventoryQuantity(input: $input) {
      id
      inventoryId
      storeId
      locationId
      productId
      skuId
      quantity
      lastTransaction {
        parentId
        reason
        quantity
        prevQuantity
        createdAt
      }
      updatedAt
      createdAt
    }
  }
`;
export const updateInventoryQuantity = /* GraphQL */ `
  mutation UpdateInventoryQuantity($input: UpdateInventoryQuantityInput!) {
    updateInventoryQuantity(input: $input) {
      id
      inventoryId
      storeId
      locationId
      productId
      skuId
      quantity
      lastTransaction {
        parentId
        reason
        quantity
        prevQuantity
        createdAt
      }
      updatedAt
      createdAt
    }
  }
`;
export const deleteInventoryQuantity = /* GraphQL */ `
  mutation DeleteInventoryQuantity($input: DeleteInventoryQuantityInput!) {
    deleteInventoryQuantity(input: $input) {
      id
      inventoryId
      storeId
      locationId
      productId
      skuId
      quantity
      lastTransaction {
        parentId
        reason
        quantity
        prevQuantity
        createdAt
      }
      updatedAt
      createdAt
    }
  }
`;
export const createInventory = /* GraphQL */ `
  mutation CreateInventory($input: CreateInventoryInput!) {
    createInventory(input: $input) {
      id
      storeId
      productId
      skuId
      expiryDate
      attributes {
        name
        type
        value
      }
      updatedAt
      createdAt
    }
  }
`;
export const updateInventory = /* GraphQL */ `
  mutation UpdateInventory($input: UpdateInventoryInput!) {
    updateInventory(input: $input) {
      id
      storeId
      productId
      skuId
      expiryDate
      attributes {
        name
        type
        value
      }
      updatedAt
      createdAt
    }
  }
`;
export const deleteInventory = /* GraphQL */ `
  mutation DeleteInventory($input: DeleteInventoryInput!) {
    deleteInventory(input: $input) {
      id
      storeId
      productId
      skuId
      expiryDate
      attributes {
        name
        type
        value
      }
      updatedAt
      createdAt
    }
  }
`;
export const createItems = /* GraphQL */ `
  mutation CreateItems($input: CreateItemsInput!) {
    createItems(input: $input) {
      id
      products {
        type
        id
        itemId
        name
        description
        thumbnail {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        image
        skus {
          type
          id
          itemId
          refer
          referId
          attributes {
            name
            type
            value
          }
          name
          nameMl {
            en
            zh
            ja
          }
          price
          cost
          unit
          unitSuffix
          barcode
          currency
          model
          locationId
          futureLocationAvailable
          locationIds
          inventories {
            id
            quantity
            cost
            locationId
            unavailableQuantity
            inReadyQuantity
            outReadyQuantity
            expiryDate
            updatedAt
            createdAt
            locations {
              id
              quantity
            }
            attributes {
              name
              type
              value
            }
          }
          locations {
            id
            isActive
            available
            price
          }
          links {
            type
            origin
            originId
            originProductId
            updatedAt
            createdAt
          }
          stock
          stockAlert
          stockAlertQty
          stockTrack
          quantity
          limitQuantity
          isActive
        }
        trackStock
        taxId
        locationIds
      }
      productModifiers {
        productId
        modifierId
      }
      categoryProducts {
        categoryId
        productId
      }
      modifiers {
        type
        id
        itemId
        name
        skus {
          type
          id
          itemId
          refer
          referId
          attributes {
            name
            type
            value
          }
          name
          nameMl {
            en
            zh
            ja
          }
          price
          cost
          unit
          unitSuffix
          barcode
          currency
          model
          locationId
          futureLocationAvailable
          locationIds
          inventories {
            id
            quantity
            cost
            locationId
            unavailableQuantity
            inReadyQuantity
            outReadyQuantity
            expiryDate
            updatedAt
            createdAt
            locations {
              id
              quantity
            }
            attributes {
              name
              type
              value
            }
          }
          locations {
            id
            isActive
            available
            price
          }
          links {
            type
            origin
            originId
            originProductId
            updatedAt
            createdAt
          }
          stock
          stockAlert
          stockAlertQty
          stockTrack
          quantity
          limitQuantity
          isActive
        }
        locationIds
      }
      categories {
        type
        id
        itemId
        name
        displayName
        locationIds
      }
      updatedAt
      createdAt
    }
  }
`;
export const updateItems = /* GraphQL */ `
  mutation UpdateItems($input: UpdateItemsInput!) {
    updateItems(input: $input) {
      id
      products {
        type
        id
        itemId
        name
        description
        thumbnail {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        image
        skus {
          type
          id
          itemId
          refer
          referId
          attributes {
            name
            type
            value
          }
          name
          nameMl {
            en
            zh
            ja
          }
          price
          cost
          unit
          unitSuffix
          barcode
          currency
          model
          locationId
          futureLocationAvailable
          locationIds
          inventories {
            id
            quantity
            cost
            locationId
            unavailableQuantity
            inReadyQuantity
            outReadyQuantity
            expiryDate
            updatedAt
            createdAt
            locations {
              id
              quantity
            }
            attributes {
              name
              type
              value
            }
          }
          locations {
            id
            isActive
            available
            price
          }
          links {
            type
            origin
            originId
            originProductId
            updatedAt
            createdAt
          }
          stock
          stockAlert
          stockAlertQty
          stockTrack
          quantity
          limitQuantity
          isActive
        }
        trackStock
        taxId
        locationIds
      }
      productModifiers {
        productId
        modifierId
      }
      categoryProducts {
        categoryId
        productId
      }
      modifiers {
        type
        id
        itemId
        name
        skus {
          type
          id
          itemId
          refer
          referId
          attributes {
            name
            type
            value
          }
          name
          nameMl {
            en
            zh
            ja
          }
          price
          cost
          unit
          unitSuffix
          barcode
          currency
          model
          locationId
          futureLocationAvailable
          locationIds
          inventories {
            id
            quantity
            cost
            locationId
            unavailableQuantity
            inReadyQuantity
            outReadyQuantity
            expiryDate
            updatedAt
            createdAt
            locations {
              id
              quantity
            }
            attributes {
              name
              type
              value
            }
          }
          locations {
            id
            isActive
            available
            price
          }
          links {
            type
            origin
            originId
            originProductId
            updatedAt
            createdAt
          }
          stock
          stockAlert
          stockAlertQty
          stockTrack
          quantity
          limitQuantity
          isActive
        }
        locationIds
      }
      categories {
        type
        id
        itemId
        name
        displayName
        locationIds
      }
      updatedAt
      createdAt
    }
  }
`;
export const deleteItems = /* GraphQL */ `
  mutation DeleteItems($input: DeleteItemsInput!) {
    deleteItems(input: $input) {
      id
      products {
        type
        id
        itemId
        name
        description
        thumbnail {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        image
        skus {
          type
          id
          itemId
          refer
          referId
          attributes {
            name
            type
            value
          }
          name
          nameMl {
            en
            zh
            ja
          }
          price
          cost
          unit
          unitSuffix
          barcode
          currency
          model
          locationId
          futureLocationAvailable
          locationIds
          inventories {
            id
            quantity
            cost
            locationId
            unavailableQuantity
            inReadyQuantity
            outReadyQuantity
            expiryDate
            updatedAt
            createdAt
            locations {
              id
              quantity
            }
            attributes {
              name
              type
              value
            }
          }
          locations {
            id
            isActive
            available
            price
          }
          links {
            type
            origin
            originId
            originProductId
            updatedAt
            createdAt
          }
          stock
          stockAlert
          stockAlertQty
          stockTrack
          quantity
          limitQuantity
          isActive
        }
        trackStock
        taxId
        locationIds
      }
      productModifiers {
        productId
        modifierId
      }
      categoryProducts {
        categoryId
        productId
      }
      modifiers {
        type
        id
        itemId
        name
        skus {
          type
          id
          itemId
          refer
          referId
          attributes {
            name
            type
            value
          }
          name
          nameMl {
            en
            zh
            ja
          }
          price
          cost
          unit
          unitSuffix
          barcode
          currency
          model
          locationId
          futureLocationAvailable
          locationIds
          inventories {
            id
            quantity
            cost
            locationId
            unavailableQuantity
            inReadyQuantity
            outReadyQuantity
            expiryDate
            updatedAt
            createdAt
            locations {
              id
              quantity
            }
            attributes {
              name
              type
              value
            }
          }
          locations {
            id
            isActive
            available
            price
          }
          links {
            type
            origin
            originId
            originProductId
            updatedAt
            createdAt
          }
          stock
          stockAlert
          stockAlertQty
          stockTrack
          quantity
          limitQuantity
          isActive
        }
        locationIds
      }
      categories {
        type
        id
        itemId
        name
        displayName
        locationIds
      }
      updatedAt
      createdAt
    }
  }
`;
export const createStoreProducts = /* GraphQL */ `
  mutation CreateStoreProducts($input: CreateStoreProductsInput!) {
    createStoreProducts(input: $input) {
      id
      categories {
        items {
          type
          id
          enterpriseId
          storeId
          name
          displayName
          refer
          referId
          products {
            items {
              id
              enterpriseId
              storeId
              productId
              categoryId
              category {
                type
                id
                enterpriseId
                storeId
                name
                displayName
                refer
                referId
                locationIds
                updatedAt
                createdAt
              }
              updatedAt
              createdAt
              product {
                id
                type
                enterpriseId
                storeId
                origin
                refer
                referId
                name
                description
                variablePrice
                trackStock
                taxId
                locationIds
                updatedAt
                createdAt
              }
            }
            nextToken
          }
          locationIds
          updatedAt
          createdAt
        }
        nextToken
      }
      modifiers {
        items {
          id
          type
          enterpriseId
          storeId
          name
          nameMl {
            en
            zh
            ja
          }
          skus {
            type
            id
            itemId
            refer
            referId
            attributes {
              name
              type
              value
            }
            name
            nameMl {
              en
              zh
              ja
            }
            price
            cost
            unit
            unitSuffix
            barcode
            currency
            model
            locationId
            futureLocationAvailable
            locationIds
            inventories {
              id
              quantity
              cost
              locationId
              unavailableQuantity
              inReadyQuantity
              outReadyQuantity
              expiryDate
              updatedAt
              createdAt
              locations {
                id
                quantity
              }
              attributes {
                name
                type
                value
              }
            }
            locations {
              id
              isActive
              available
              price
            }
            links {
              type
              origin
              originId
              originProductId
              updatedAt
              createdAt
            }
            stock
            stockAlert
            stockAlertQty
            stockTrack
            quantity
            limitQuantity
            isActive
          }
          origin
          orderType
          defaultSkuId
          coverage
          required
          requiredQuantity
          limitQuantity
          products {
            items {
              id
              enterpriseId
              storeId
              productId
              modifierId
              position
              modifier {
                id
                type
                enterpriseId
                storeId
                name
                origin
                orderType
                defaultSkuId
                coverage
                required
                requiredQuantity
                limitQuantity
                locationIds
                updatedAt
                createdAt
              }
              updatedAt
              createdAt
              product {
                id
                type
                enterpriseId
                storeId
                origin
                refer
                referId
                name
                description
                variablePrice
                trackStock
                taxId
                locationIds
                updatedAt
                createdAt
              }
            }
            nextToken
          }
          locationIds
          updatedAt
          createdAt
        }
        nextToken
      }
      updatedAt
      createdAt
      products {
        items {
          id
          type
          enterpriseId
          storeId
          origin
          refer
          referId
          name
          nameMl {
            en
            zh
            ja
          }
          description
          descriptionMl {
            en
            zh
            ja
          }
          variablePrice
          attributes {
            name
            type
            value
          }
          thumbnail {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          skus {
            type
            id
            itemId
            refer
            referId
            attributes {
              name
              type
              value
            }
            name
            nameMl {
              en
              zh
              ja
            }
            price
            cost
            unit
            unitSuffix
            barcode
            currency
            model
            locationId
            futureLocationAvailable
            locationIds
            inventories {
              id
              quantity
              cost
              locationId
              unavailableQuantity
              inReadyQuantity
              outReadyQuantity
              expiryDate
              updatedAt
              createdAt
              locations {
                id
                quantity
              }
              attributes {
                name
                type
                value
              }
            }
            locations {
              id
              isActive
              available
              price
            }
            links {
              type
              origin
              originId
              originProductId
              updatedAt
              createdAt
            }
            stock
            stockAlert
            stockAlertQty
            stockTrack
            quantity
            limitQuantity
            isActive
          }
          trackStock
          taxId
          categories {
            items {
              id
              enterpriseId
              storeId
              productId
              categoryId
              category {
                type
                id
                enterpriseId
                storeId
                name
                displayName
                refer
                referId
                locationIds
                updatedAt
                createdAt
              }
              updatedAt
              createdAt
              product {
                id
                type
                enterpriseId
                storeId
                origin
                refer
                referId
                name
                description
                variablePrice
                trackStock
                taxId
                locationIds
                updatedAt
                createdAt
              }
            }
            nextToken
          }
          classifications {
            items {
              id
              enterpriseId
              storeId
              productId
              classificationId
              classification {
                type
                id
                enterpriseId
                storeId
                name
                displayName
                refer
                referId
                locationIds
                updatedAt
                createdAt
              }
              updatedAt
              createdAt
              product {
                id
                type
                enterpriseId
                storeId
                origin
                refer
                referId
                name
                description
                variablePrice
                trackStock
                taxId
                locationIds
                updatedAt
                createdAt
              }
            }
            nextToken
          }
          modifiers {
            items {
              id
              enterpriseId
              storeId
              productId
              modifierId
              position
              modifier {
                id
                type
                enterpriseId
                storeId
                name
                origin
                orderType
                defaultSkuId
                coverage
                required
                requiredQuantity
                limitQuantity
                locationIds
                updatedAt
                createdAt
              }
              updatedAt
              createdAt
              product {
                id
                type
                enterpriseId
                storeId
                origin
                refer
                referId
                name
                description
                variablePrice
                trackStock
                taxId
                locationIds
                updatedAt
                createdAt
              }
            }
            nextToken
          }
          locationIds
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const updateStoreProducts = /* GraphQL */ `
  mutation UpdateStoreProducts($input: UpdateStoreProductsInput!) {
    updateStoreProducts(input: $input) {
      id
      categories {
        items {
          type
          id
          enterpriseId
          storeId
          name
          displayName
          refer
          referId
          products {
            items {
              id
              enterpriseId
              storeId
              productId
              categoryId
              category {
                type
                id
                enterpriseId
                storeId
                name
                displayName
                refer
                referId
                locationIds
                updatedAt
                createdAt
              }
              updatedAt
              createdAt
              product {
                id
                type
                enterpriseId
                storeId
                origin
                refer
                referId
                name
                description
                variablePrice
                trackStock
                taxId
                locationIds
                updatedAt
                createdAt
              }
            }
            nextToken
          }
          locationIds
          updatedAt
          createdAt
        }
        nextToken
      }
      modifiers {
        items {
          id
          type
          enterpriseId
          storeId
          name
          nameMl {
            en
            zh
            ja
          }
          skus {
            type
            id
            itemId
            refer
            referId
            attributes {
              name
              type
              value
            }
            name
            nameMl {
              en
              zh
              ja
            }
            price
            cost
            unit
            unitSuffix
            barcode
            currency
            model
            locationId
            futureLocationAvailable
            locationIds
            inventories {
              id
              quantity
              cost
              locationId
              unavailableQuantity
              inReadyQuantity
              outReadyQuantity
              expiryDate
              updatedAt
              createdAt
              locations {
                id
                quantity
              }
              attributes {
                name
                type
                value
              }
            }
            locations {
              id
              isActive
              available
              price
            }
            links {
              type
              origin
              originId
              originProductId
              updatedAt
              createdAt
            }
            stock
            stockAlert
            stockAlertQty
            stockTrack
            quantity
            limitQuantity
            isActive
          }
          origin
          orderType
          defaultSkuId
          coverage
          required
          requiredQuantity
          limitQuantity
          products {
            items {
              id
              enterpriseId
              storeId
              productId
              modifierId
              position
              modifier {
                id
                type
                enterpriseId
                storeId
                name
                origin
                orderType
                defaultSkuId
                coverage
                required
                requiredQuantity
                limitQuantity
                locationIds
                updatedAt
                createdAt
              }
              updatedAt
              createdAt
              product {
                id
                type
                enterpriseId
                storeId
                origin
                refer
                referId
                name
                description
                variablePrice
                trackStock
                taxId
                locationIds
                updatedAt
                createdAt
              }
            }
            nextToken
          }
          locationIds
          updatedAt
          createdAt
        }
        nextToken
      }
      updatedAt
      createdAt
      products {
        items {
          id
          type
          enterpriseId
          storeId
          origin
          refer
          referId
          name
          nameMl {
            en
            zh
            ja
          }
          description
          descriptionMl {
            en
            zh
            ja
          }
          variablePrice
          attributes {
            name
            type
            value
          }
          thumbnail {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          skus {
            type
            id
            itemId
            refer
            referId
            attributes {
              name
              type
              value
            }
            name
            nameMl {
              en
              zh
              ja
            }
            price
            cost
            unit
            unitSuffix
            barcode
            currency
            model
            locationId
            futureLocationAvailable
            locationIds
            inventories {
              id
              quantity
              cost
              locationId
              unavailableQuantity
              inReadyQuantity
              outReadyQuantity
              expiryDate
              updatedAt
              createdAt
              locations {
                id
                quantity
              }
              attributes {
                name
                type
                value
              }
            }
            locations {
              id
              isActive
              available
              price
            }
            links {
              type
              origin
              originId
              originProductId
              updatedAt
              createdAt
            }
            stock
            stockAlert
            stockAlertQty
            stockTrack
            quantity
            limitQuantity
            isActive
          }
          trackStock
          taxId
          categories {
            items {
              id
              enterpriseId
              storeId
              productId
              categoryId
              category {
                type
                id
                enterpriseId
                storeId
                name
                displayName
                refer
                referId
                locationIds
                updatedAt
                createdAt
              }
              updatedAt
              createdAt
              product {
                id
                type
                enterpriseId
                storeId
                origin
                refer
                referId
                name
                description
                variablePrice
                trackStock
                taxId
                locationIds
                updatedAt
                createdAt
              }
            }
            nextToken
          }
          classifications {
            items {
              id
              enterpriseId
              storeId
              productId
              classificationId
              classification {
                type
                id
                enterpriseId
                storeId
                name
                displayName
                refer
                referId
                locationIds
                updatedAt
                createdAt
              }
              updatedAt
              createdAt
              product {
                id
                type
                enterpriseId
                storeId
                origin
                refer
                referId
                name
                description
                variablePrice
                trackStock
                taxId
                locationIds
                updatedAt
                createdAt
              }
            }
            nextToken
          }
          modifiers {
            items {
              id
              enterpriseId
              storeId
              productId
              modifierId
              position
              modifier {
                id
                type
                enterpriseId
                storeId
                name
                origin
                orderType
                defaultSkuId
                coverage
                required
                requiredQuantity
                limitQuantity
                locationIds
                updatedAt
                createdAt
              }
              updatedAt
              createdAt
              product {
                id
                type
                enterpriseId
                storeId
                origin
                refer
                referId
                name
                description
                variablePrice
                trackStock
                taxId
                locationIds
                updatedAt
                createdAt
              }
            }
            nextToken
          }
          locationIds
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const deleteStoreProducts = /* GraphQL */ `
  mutation DeleteStoreProducts($input: DeleteStoreProductsInput!) {
    deleteStoreProducts(input: $input) {
      id
      categories {
        items {
          type
          id
          enterpriseId
          storeId
          name
          displayName
          refer
          referId
          products {
            items {
              id
              enterpriseId
              storeId
              productId
              categoryId
              category {
                type
                id
                enterpriseId
                storeId
                name
                displayName
                refer
                referId
                locationIds
                updatedAt
                createdAt
              }
              updatedAt
              createdAt
              product {
                id
                type
                enterpriseId
                storeId
                origin
                refer
                referId
                name
                description
                variablePrice
                trackStock
                taxId
                locationIds
                updatedAt
                createdAt
              }
            }
            nextToken
          }
          locationIds
          updatedAt
          createdAt
        }
        nextToken
      }
      modifiers {
        items {
          id
          type
          enterpriseId
          storeId
          name
          nameMl {
            en
            zh
            ja
          }
          skus {
            type
            id
            itemId
            refer
            referId
            attributes {
              name
              type
              value
            }
            name
            nameMl {
              en
              zh
              ja
            }
            price
            cost
            unit
            unitSuffix
            barcode
            currency
            model
            locationId
            futureLocationAvailable
            locationIds
            inventories {
              id
              quantity
              cost
              locationId
              unavailableQuantity
              inReadyQuantity
              outReadyQuantity
              expiryDate
              updatedAt
              createdAt
              locations {
                id
                quantity
              }
              attributes {
                name
                type
                value
              }
            }
            locations {
              id
              isActive
              available
              price
            }
            links {
              type
              origin
              originId
              originProductId
              updatedAt
              createdAt
            }
            stock
            stockAlert
            stockAlertQty
            stockTrack
            quantity
            limitQuantity
            isActive
          }
          origin
          orderType
          defaultSkuId
          coverage
          required
          requiredQuantity
          limitQuantity
          products {
            items {
              id
              enterpriseId
              storeId
              productId
              modifierId
              position
              modifier {
                id
                type
                enterpriseId
                storeId
                name
                origin
                orderType
                defaultSkuId
                coverage
                required
                requiredQuantity
                limitQuantity
                locationIds
                updatedAt
                createdAt
              }
              updatedAt
              createdAt
              product {
                id
                type
                enterpriseId
                storeId
                origin
                refer
                referId
                name
                description
                variablePrice
                trackStock
                taxId
                locationIds
                updatedAt
                createdAt
              }
            }
            nextToken
          }
          locationIds
          updatedAt
          createdAt
        }
        nextToken
      }
      updatedAt
      createdAt
      products {
        items {
          id
          type
          enterpriseId
          storeId
          origin
          refer
          referId
          name
          nameMl {
            en
            zh
            ja
          }
          description
          descriptionMl {
            en
            zh
            ja
          }
          variablePrice
          attributes {
            name
            type
            value
          }
          thumbnail {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          skus {
            type
            id
            itemId
            refer
            referId
            attributes {
              name
              type
              value
            }
            name
            nameMl {
              en
              zh
              ja
            }
            price
            cost
            unit
            unitSuffix
            barcode
            currency
            model
            locationId
            futureLocationAvailable
            locationIds
            inventories {
              id
              quantity
              cost
              locationId
              unavailableQuantity
              inReadyQuantity
              outReadyQuantity
              expiryDate
              updatedAt
              createdAt
              locations {
                id
                quantity
              }
              attributes {
                name
                type
                value
              }
            }
            locations {
              id
              isActive
              available
              price
            }
            links {
              type
              origin
              originId
              originProductId
              updatedAt
              createdAt
            }
            stock
            stockAlert
            stockAlertQty
            stockTrack
            quantity
            limitQuantity
            isActive
          }
          trackStock
          taxId
          categories {
            items {
              id
              enterpriseId
              storeId
              productId
              categoryId
              category {
                type
                id
                enterpriseId
                storeId
                name
                displayName
                refer
                referId
                locationIds
                updatedAt
                createdAt
              }
              updatedAt
              createdAt
              product {
                id
                type
                enterpriseId
                storeId
                origin
                refer
                referId
                name
                description
                variablePrice
                trackStock
                taxId
                locationIds
                updatedAt
                createdAt
              }
            }
            nextToken
          }
          classifications {
            items {
              id
              enterpriseId
              storeId
              productId
              classificationId
              classification {
                type
                id
                enterpriseId
                storeId
                name
                displayName
                refer
                referId
                locationIds
                updatedAt
                createdAt
              }
              updatedAt
              createdAt
              product {
                id
                type
                enterpriseId
                storeId
                origin
                refer
                referId
                name
                description
                variablePrice
                trackStock
                taxId
                locationIds
                updatedAt
                createdAt
              }
            }
            nextToken
          }
          modifiers {
            items {
              id
              enterpriseId
              storeId
              productId
              modifierId
              position
              modifier {
                id
                type
                enterpriseId
                storeId
                name
                origin
                orderType
                defaultSkuId
                coverage
                required
                requiredQuantity
                limitQuantity
                locationIds
                updatedAt
                createdAt
              }
              updatedAt
              createdAt
              product {
                id
                type
                enterpriseId
                storeId
                origin
                refer
                referId
                name
                description
                variablePrice
                trackStock
                taxId
                locationIds
                updatedAt
                createdAt
              }
            }
            nextToken
          }
          locationIds
          updatedAt
          createdAt
        }
        nextToken
      }
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      id
      type
      enterpriseId
      storeId
      origin
      refer
      referId
      name
      nameMl {
        en
        zh
        ja
      }
      description
      descriptionMl {
        en
        zh
        ja
      }
      variablePrice
      attributes {
        name
        type
        value
      }
      thumbnail {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      skus {
        type
        id
        itemId
        refer
        referId
        attributes {
          name
          type
          value
        }
        name
        nameMl {
          en
          zh
          ja
        }
        price
        cost
        unit
        unitSuffix
        barcode
        currency
        model
        locationId
        futureLocationAvailable
        locationIds
        inventories {
          id
          quantity
          cost
          locationId
          unavailableQuantity
          inReadyQuantity
          outReadyQuantity
          expiryDate
          updatedAt
          createdAt
          locations {
            id
            quantity
          }
          attributes {
            name
            type
            value
          }
        }
        locations {
          id
          isActive
          available
          price
        }
        links {
          type
          origin
          originId
          originProductId
          updatedAt
          createdAt
        }
        stock
        stockAlert
        stockAlertQty
        stockTrack
        quantity
        limitQuantity
        isActive
      }
      trackStock
      taxId
      categories {
        items {
          id
          enterpriseId
          storeId
          productId
          categoryId
          category {
            type
            id
            enterpriseId
            storeId
            name
            displayName
            refer
            referId
            products {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
          updatedAt
          createdAt
          product {
            id
            type
            enterpriseId
            storeId
            origin
            refer
            referId
            name
            nameMl {
              en
              zh
              ja
            }
            description
            descriptionMl {
              en
              zh
              ja
            }
            variablePrice
            attributes {
              name
              type
              value
            }
            thumbnail {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            skus {
              type
              id
              itemId
              refer
              referId
              attributes {
                name
                type
                value
              }
              name
              nameMl {
                en
                zh
                ja
              }
              price
              cost
              unit
              unitSuffix
              barcode
              currency
              model
              locationId
              futureLocationAvailable
              locationIds
              inventories {
                id
                quantity
                cost
                locationId
                unavailableQuantity
                inReadyQuantity
                outReadyQuantity
                expiryDate
                updatedAt
                createdAt
              }
              locations {
                id
                isActive
                available
                price
              }
              links {
                type
                origin
                originId
                originProductId
                updatedAt
                createdAt
              }
              stock
              stockAlert
              stockAlertQty
              stockTrack
              quantity
              limitQuantity
              isActive
            }
            trackStock
            taxId
            categories {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            classifications {
              items {
                id
                enterpriseId
                storeId
                productId
                classificationId
                updatedAt
                createdAt
              }
              nextToken
            }
            modifiers {
              items {
                id
                enterpriseId
                storeId
                productId
                modifierId
                position
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
        }
        nextToken
      }
      classifications {
        items {
          id
          enterpriseId
          storeId
          productId
          classificationId
          classification {
            type
            id
            enterpriseId
            storeId
            name
            displayName
            refer
            referId
            products {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
          updatedAt
          createdAt
          product {
            id
            type
            enterpriseId
            storeId
            origin
            refer
            referId
            name
            nameMl {
              en
              zh
              ja
            }
            description
            descriptionMl {
              en
              zh
              ja
            }
            variablePrice
            attributes {
              name
              type
              value
            }
            thumbnail {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            skus {
              type
              id
              itemId
              refer
              referId
              attributes {
                name
                type
                value
              }
              name
              nameMl {
                en
                zh
                ja
              }
              price
              cost
              unit
              unitSuffix
              barcode
              currency
              model
              locationId
              futureLocationAvailable
              locationIds
              inventories {
                id
                quantity
                cost
                locationId
                unavailableQuantity
                inReadyQuantity
                outReadyQuantity
                expiryDate
                updatedAt
                createdAt
              }
              locations {
                id
                isActive
                available
                price
              }
              links {
                type
                origin
                originId
                originProductId
                updatedAt
                createdAt
              }
              stock
              stockAlert
              stockAlertQty
              stockTrack
              quantity
              limitQuantity
              isActive
            }
            trackStock
            taxId
            categories {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            classifications {
              items {
                id
                enterpriseId
                storeId
                productId
                classificationId
                updatedAt
                createdAt
              }
              nextToken
            }
            modifiers {
              items {
                id
                enterpriseId
                storeId
                productId
                modifierId
                position
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
        }
        nextToken
      }
      modifiers {
        items {
          id
          enterpriseId
          storeId
          productId
          modifierId
          position
          modifier {
            id
            type
            enterpriseId
            storeId
            name
            nameMl {
              en
              zh
              ja
            }
            skus {
              type
              id
              itemId
              refer
              referId
              attributes {
                name
                type
                value
              }
              name
              nameMl {
                en
                zh
                ja
              }
              price
              cost
              unit
              unitSuffix
              barcode
              currency
              model
              locationId
              futureLocationAvailable
              locationIds
              inventories {
                id
                quantity
                cost
                locationId
                unavailableQuantity
                inReadyQuantity
                outReadyQuantity
                expiryDate
                updatedAt
                createdAt
              }
              locations {
                id
                isActive
                available
                price
              }
              links {
                type
                origin
                originId
                originProductId
                updatedAt
                createdAt
              }
              stock
              stockAlert
              stockAlertQty
              stockTrack
              quantity
              limitQuantity
              isActive
            }
            origin
            orderType
            defaultSkuId
            coverage
            required
            requiredQuantity
            limitQuantity
            products {
              items {
                id
                enterpriseId
                storeId
                productId
                modifierId
                position
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
          updatedAt
          createdAt
          product {
            id
            type
            enterpriseId
            storeId
            origin
            refer
            referId
            name
            nameMl {
              en
              zh
              ja
            }
            description
            descriptionMl {
              en
              zh
              ja
            }
            variablePrice
            attributes {
              name
              type
              value
            }
            thumbnail {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            skus {
              type
              id
              itemId
              refer
              referId
              attributes {
                name
                type
                value
              }
              name
              nameMl {
                en
                zh
                ja
              }
              price
              cost
              unit
              unitSuffix
              barcode
              currency
              model
              locationId
              futureLocationAvailable
              locationIds
              inventories {
                id
                quantity
                cost
                locationId
                unavailableQuantity
                inReadyQuantity
                outReadyQuantity
                expiryDate
                updatedAt
                createdAt
              }
              locations {
                id
                isActive
                available
                price
              }
              links {
                type
                origin
                originId
                originProductId
                updatedAt
                createdAt
              }
              stock
              stockAlert
              stockAlertQty
              stockTrack
              quantity
              limitQuantity
              isActive
            }
            trackStock
            taxId
            categories {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            classifications {
              items {
                id
                enterpriseId
                storeId
                productId
                classificationId
                updatedAt
                createdAt
              }
              nextToken
            }
            modifiers {
              items {
                id
                enterpriseId
                storeId
                productId
                modifierId
                position
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
        }
        nextToken
      }
      locationIds
      updatedAt
      createdAt
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct($input: DeleteProductInput!) {
    deleteProduct(input: $input) {
      id
      type
      enterpriseId
      storeId
      origin
      refer
      referId
      name
      nameMl {
        en
        zh
        ja
      }
      description
      descriptionMl {
        en
        zh
        ja
      }
      variablePrice
      attributes {
        name
        type
        value
      }
      thumbnail {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      skus {
        type
        id
        itemId
        refer
        referId
        attributes {
          name
          type
          value
        }
        name
        nameMl {
          en
          zh
          ja
        }
        price
        cost
        unit
        unitSuffix
        barcode
        currency
        model
        locationId
        futureLocationAvailable
        locationIds
        inventories {
          id
          quantity
          cost
          locationId
          unavailableQuantity
          inReadyQuantity
          outReadyQuantity
          expiryDate
          updatedAt
          createdAt
          locations {
            id
            quantity
          }
          attributes {
            name
            type
            value
          }
        }
        locations {
          id
          isActive
          available
          price
        }
        links {
          type
          origin
          originId
          originProductId
          updatedAt
          createdAt
        }
        stock
        stockAlert
        stockAlertQty
        stockTrack
        quantity
        limitQuantity
        isActive
      }
      trackStock
      taxId
      categories {
        items {
          id
          enterpriseId
          storeId
          productId
          categoryId
          category {
            type
            id
            enterpriseId
            storeId
            name
            displayName
            refer
            referId
            products {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
          updatedAt
          createdAt
          product {
            id
            type
            enterpriseId
            storeId
            origin
            refer
            referId
            name
            nameMl {
              en
              zh
              ja
            }
            description
            descriptionMl {
              en
              zh
              ja
            }
            variablePrice
            attributes {
              name
              type
              value
            }
            thumbnail {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            skus {
              type
              id
              itemId
              refer
              referId
              attributes {
                name
                type
                value
              }
              name
              nameMl {
                en
                zh
                ja
              }
              price
              cost
              unit
              unitSuffix
              barcode
              currency
              model
              locationId
              futureLocationAvailable
              locationIds
              inventories {
                id
                quantity
                cost
                locationId
                unavailableQuantity
                inReadyQuantity
                outReadyQuantity
                expiryDate
                updatedAt
                createdAt
              }
              locations {
                id
                isActive
                available
                price
              }
              links {
                type
                origin
                originId
                originProductId
                updatedAt
                createdAt
              }
              stock
              stockAlert
              stockAlertQty
              stockTrack
              quantity
              limitQuantity
              isActive
            }
            trackStock
            taxId
            categories {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            classifications {
              items {
                id
                enterpriseId
                storeId
                productId
                classificationId
                updatedAt
                createdAt
              }
              nextToken
            }
            modifiers {
              items {
                id
                enterpriseId
                storeId
                productId
                modifierId
                position
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
        }
        nextToken
      }
      classifications {
        items {
          id
          enterpriseId
          storeId
          productId
          classificationId
          classification {
            type
            id
            enterpriseId
            storeId
            name
            displayName
            refer
            referId
            products {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
          updatedAt
          createdAt
          product {
            id
            type
            enterpriseId
            storeId
            origin
            refer
            referId
            name
            nameMl {
              en
              zh
              ja
            }
            description
            descriptionMl {
              en
              zh
              ja
            }
            variablePrice
            attributes {
              name
              type
              value
            }
            thumbnail {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            skus {
              type
              id
              itemId
              refer
              referId
              attributes {
                name
                type
                value
              }
              name
              nameMl {
                en
                zh
                ja
              }
              price
              cost
              unit
              unitSuffix
              barcode
              currency
              model
              locationId
              futureLocationAvailable
              locationIds
              inventories {
                id
                quantity
                cost
                locationId
                unavailableQuantity
                inReadyQuantity
                outReadyQuantity
                expiryDate
                updatedAt
                createdAt
              }
              locations {
                id
                isActive
                available
                price
              }
              links {
                type
                origin
                originId
                originProductId
                updatedAt
                createdAt
              }
              stock
              stockAlert
              stockAlertQty
              stockTrack
              quantity
              limitQuantity
              isActive
            }
            trackStock
            taxId
            categories {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            classifications {
              items {
                id
                enterpriseId
                storeId
                productId
                classificationId
                updatedAt
                createdAt
              }
              nextToken
            }
            modifiers {
              items {
                id
                enterpriseId
                storeId
                productId
                modifierId
                position
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
        }
        nextToken
      }
      modifiers {
        items {
          id
          enterpriseId
          storeId
          productId
          modifierId
          position
          modifier {
            id
            type
            enterpriseId
            storeId
            name
            nameMl {
              en
              zh
              ja
            }
            skus {
              type
              id
              itemId
              refer
              referId
              attributes {
                name
                type
                value
              }
              name
              nameMl {
                en
                zh
                ja
              }
              price
              cost
              unit
              unitSuffix
              barcode
              currency
              model
              locationId
              futureLocationAvailable
              locationIds
              inventories {
                id
                quantity
                cost
                locationId
                unavailableQuantity
                inReadyQuantity
                outReadyQuantity
                expiryDate
                updatedAt
                createdAt
              }
              locations {
                id
                isActive
                available
                price
              }
              links {
                type
                origin
                originId
                originProductId
                updatedAt
                createdAt
              }
              stock
              stockAlert
              stockAlertQty
              stockTrack
              quantity
              limitQuantity
              isActive
            }
            origin
            orderType
            defaultSkuId
            coverage
            required
            requiredQuantity
            limitQuantity
            products {
              items {
                id
                enterpriseId
                storeId
                productId
                modifierId
                position
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
          updatedAt
          createdAt
          product {
            id
            type
            enterpriseId
            storeId
            origin
            refer
            referId
            name
            nameMl {
              en
              zh
              ja
            }
            description
            descriptionMl {
              en
              zh
              ja
            }
            variablePrice
            attributes {
              name
              type
              value
            }
            thumbnail {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            skus {
              type
              id
              itemId
              refer
              referId
              attributes {
                name
                type
                value
              }
              name
              nameMl {
                en
                zh
                ja
              }
              price
              cost
              unit
              unitSuffix
              barcode
              currency
              model
              locationId
              futureLocationAvailable
              locationIds
              inventories {
                id
                quantity
                cost
                locationId
                unavailableQuantity
                inReadyQuantity
                outReadyQuantity
                expiryDate
                updatedAt
                createdAt
              }
              locations {
                id
                isActive
                available
                price
              }
              links {
                type
                origin
                originId
                originProductId
                updatedAt
                createdAt
              }
              stock
              stockAlert
              stockAlertQty
              stockTrack
              quantity
              limitQuantity
              isActive
            }
            trackStock
            taxId
            categories {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            classifications {
              items {
                id
                enterpriseId
                storeId
                productId
                classificationId
                updatedAt
                createdAt
              }
              nextToken
            }
            modifiers {
              items {
                id
                enterpriseId
                storeId
                productId
                modifierId
                position
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
        }
        nextToken
      }
      locationIds
      updatedAt
      createdAt
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      type
      id
      enterpriseId
      storeId
      name
      displayName
      refer
      referId
      products {
        items {
          id
          enterpriseId
          storeId
          productId
          categoryId
          category {
            type
            id
            enterpriseId
            storeId
            name
            displayName
            refer
            referId
            products {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
          updatedAt
          createdAt
          product {
            id
            type
            enterpriseId
            storeId
            origin
            refer
            referId
            name
            nameMl {
              en
              zh
              ja
            }
            description
            descriptionMl {
              en
              zh
              ja
            }
            variablePrice
            attributes {
              name
              type
              value
            }
            thumbnail {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            skus {
              type
              id
              itemId
              refer
              referId
              attributes {
                name
                type
                value
              }
              name
              nameMl {
                en
                zh
                ja
              }
              price
              cost
              unit
              unitSuffix
              barcode
              currency
              model
              locationId
              futureLocationAvailable
              locationIds
              inventories {
                id
                quantity
                cost
                locationId
                unavailableQuantity
                inReadyQuantity
                outReadyQuantity
                expiryDate
                updatedAt
                createdAt
              }
              locations {
                id
                isActive
                available
                price
              }
              links {
                type
                origin
                originId
                originProductId
                updatedAt
                createdAt
              }
              stock
              stockAlert
              stockAlertQty
              stockTrack
              quantity
              limitQuantity
              isActive
            }
            trackStock
            taxId
            categories {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            classifications {
              items {
                id
                enterpriseId
                storeId
                productId
                classificationId
                updatedAt
                createdAt
              }
              nextToken
            }
            modifiers {
              items {
                id
                enterpriseId
                storeId
                productId
                modifierId
                position
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
        }
        nextToken
      }
      locationIds
      updatedAt
      createdAt
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      type
      id
      enterpriseId
      storeId
      name
      displayName
      refer
      referId
      products {
        items {
          id
          enterpriseId
          storeId
          productId
          categoryId
          category {
            type
            id
            enterpriseId
            storeId
            name
            displayName
            refer
            referId
            products {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
          updatedAt
          createdAt
          product {
            id
            type
            enterpriseId
            storeId
            origin
            refer
            referId
            name
            nameMl {
              en
              zh
              ja
            }
            description
            descriptionMl {
              en
              zh
              ja
            }
            variablePrice
            attributes {
              name
              type
              value
            }
            thumbnail {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            skus {
              type
              id
              itemId
              refer
              referId
              attributes {
                name
                type
                value
              }
              name
              nameMl {
                en
                zh
                ja
              }
              price
              cost
              unit
              unitSuffix
              barcode
              currency
              model
              locationId
              futureLocationAvailable
              locationIds
              inventories {
                id
                quantity
                cost
                locationId
                unavailableQuantity
                inReadyQuantity
                outReadyQuantity
                expiryDate
                updatedAt
                createdAt
              }
              locations {
                id
                isActive
                available
                price
              }
              links {
                type
                origin
                originId
                originProductId
                updatedAt
                createdAt
              }
              stock
              stockAlert
              stockAlertQty
              stockTrack
              quantity
              limitQuantity
              isActive
            }
            trackStock
            taxId
            categories {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            classifications {
              items {
                id
                enterpriseId
                storeId
                productId
                classificationId
                updatedAt
                createdAt
              }
              nextToken
            }
            modifiers {
              items {
                id
                enterpriseId
                storeId
                productId
                modifierId
                position
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
        }
        nextToken
      }
      locationIds
      updatedAt
      createdAt
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory($input: DeleteCategoryInput!) {
    deleteCategory(input: $input) {
      type
      id
      enterpriseId
      storeId
      name
      displayName
      refer
      referId
      products {
        items {
          id
          enterpriseId
          storeId
          productId
          categoryId
          category {
            type
            id
            enterpriseId
            storeId
            name
            displayName
            refer
            referId
            products {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
          updatedAt
          createdAt
          product {
            id
            type
            enterpriseId
            storeId
            origin
            refer
            referId
            name
            nameMl {
              en
              zh
              ja
            }
            description
            descriptionMl {
              en
              zh
              ja
            }
            variablePrice
            attributes {
              name
              type
              value
            }
            thumbnail {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            skus {
              type
              id
              itemId
              refer
              referId
              attributes {
                name
                type
                value
              }
              name
              nameMl {
                en
                zh
                ja
              }
              price
              cost
              unit
              unitSuffix
              barcode
              currency
              model
              locationId
              futureLocationAvailable
              locationIds
              inventories {
                id
                quantity
                cost
                locationId
                unavailableQuantity
                inReadyQuantity
                outReadyQuantity
                expiryDate
                updatedAt
                createdAt
              }
              locations {
                id
                isActive
                available
                price
              }
              links {
                type
                origin
                originId
                originProductId
                updatedAt
                createdAt
              }
              stock
              stockAlert
              stockAlertQty
              stockTrack
              quantity
              limitQuantity
              isActive
            }
            trackStock
            taxId
            categories {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            classifications {
              items {
                id
                enterpriseId
                storeId
                productId
                classificationId
                updatedAt
                createdAt
              }
              nextToken
            }
            modifiers {
              items {
                id
                enterpriseId
                storeId
                productId
                modifierId
                position
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
        }
        nextToken
      }
      locationIds
      updatedAt
      createdAt
    }
  }
`;
export const createClassification = /* GraphQL */ `
  mutation CreateClassification($input: CreateClassificationInput!) {
    createClassification(input: $input) {
      type
      id
      enterpriseId
      storeId
      name
      depth
      parentId
      refer
      referId
      products {
        items {
          id
          enterpriseId
          storeId
          productId
          classificationId
          classification {
            type
            id
            enterpriseId
            storeId
            name
            displayName
            refer
            referId
            products {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
          updatedAt
          createdAt
          product {
            id
            type
            enterpriseId
            storeId
            origin
            refer
            referId
            name
            nameMl {
              en
              zh
              ja
            }
            description
            descriptionMl {
              en
              zh
              ja
            }
            variablePrice
            attributes {
              name
              type
              value
            }
            thumbnail {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            skus {
              type
              id
              itemId
              refer
              referId
              attributes {
                name
                type
                value
              }
              name
              nameMl {
                en
                zh
                ja
              }
              price
              cost
              unit
              unitSuffix
              barcode
              currency
              model
              locationId
              futureLocationAvailable
              locationIds
              inventories {
                id
                quantity
                cost
                locationId
                unavailableQuantity
                inReadyQuantity
                outReadyQuantity
                expiryDate
                updatedAt
                createdAt
              }
              locations {
                id
                isActive
                available
                price
              }
              links {
                type
                origin
                originId
                originProductId
                updatedAt
                createdAt
              }
              stock
              stockAlert
              stockAlertQty
              stockTrack
              quantity
              limitQuantity
              isActive
            }
            trackStock
            taxId
            categories {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            classifications {
              items {
                id
                enterpriseId
                storeId
                productId
                classificationId
                updatedAt
                createdAt
              }
              nextToken
            }
            modifiers {
              items {
                id
                enterpriseId
                storeId
                productId
                modifierId
                position
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
        }
        nextToken
      }
      updatedAt
      createdAt
    }
  }
`;
export const updateClassification = /* GraphQL */ `
  mutation UpdateClassification($input: UpdateClassificationInput!) {
    updateClassification(input: $input) {
      type
      id
      enterpriseId
      storeId
      name
      depth
      parentId
      refer
      referId
      products {
        items {
          id
          enterpriseId
          storeId
          productId
          classificationId
          classification {
            type
            id
            enterpriseId
            storeId
            name
            displayName
            refer
            referId
            products {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
          updatedAt
          createdAt
          product {
            id
            type
            enterpriseId
            storeId
            origin
            refer
            referId
            name
            nameMl {
              en
              zh
              ja
            }
            description
            descriptionMl {
              en
              zh
              ja
            }
            variablePrice
            attributes {
              name
              type
              value
            }
            thumbnail {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            skus {
              type
              id
              itemId
              refer
              referId
              attributes {
                name
                type
                value
              }
              name
              nameMl {
                en
                zh
                ja
              }
              price
              cost
              unit
              unitSuffix
              barcode
              currency
              model
              locationId
              futureLocationAvailable
              locationIds
              inventories {
                id
                quantity
                cost
                locationId
                unavailableQuantity
                inReadyQuantity
                outReadyQuantity
                expiryDate
                updatedAt
                createdAt
              }
              locations {
                id
                isActive
                available
                price
              }
              links {
                type
                origin
                originId
                originProductId
                updatedAt
                createdAt
              }
              stock
              stockAlert
              stockAlertQty
              stockTrack
              quantity
              limitQuantity
              isActive
            }
            trackStock
            taxId
            categories {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            classifications {
              items {
                id
                enterpriseId
                storeId
                productId
                classificationId
                updatedAt
                createdAt
              }
              nextToken
            }
            modifiers {
              items {
                id
                enterpriseId
                storeId
                productId
                modifierId
                position
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
        }
        nextToken
      }
      updatedAt
      createdAt
    }
  }
`;
export const deleteClassification = /* GraphQL */ `
  mutation DeleteClassification($input: DeleteClassificationInput!) {
    deleteClassification(input: $input) {
      type
      id
      enterpriseId
      storeId
      name
      depth
      parentId
      refer
      referId
      products {
        items {
          id
          enterpriseId
          storeId
          productId
          classificationId
          classification {
            type
            id
            enterpriseId
            storeId
            name
            displayName
            refer
            referId
            products {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
          updatedAt
          createdAt
          product {
            id
            type
            enterpriseId
            storeId
            origin
            refer
            referId
            name
            nameMl {
              en
              zh
              ja
            }
            description
            descriptionMl {
              en
              zh
              ja
            }
            variablePrice
            attributes {
              name
              type
              value
            }
            thumbnail {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            skus {
              type
              id
              itemId
              refer
              referId
              attributes {
                name
                type
                value
              }
              name
              nameMl {
                en
                zh
                ja
              }
              price
              cost
              unit
              unitSuffix
              barcode
              currency
              model
              locationId
              futureLocationAvailable
              locationIds
              inventories {
                id
                quantity
                cost
                locationId
                unavailableQuantity
                inReadyQuantity
                outReadyQuantity
                expiryDate
                updatedAt
                createdAt
              }
              locations {
                id
                isActive
                available
                price
              }
              links {
                type
                origin
                originId
                originProductId
                updatedAt
                createdAt
              }
              stock
              stockAlert
              stockAlertQty
              stockTrack
              quantity
              limitQuantity
              isActive
            }
            trackStock
            taxId
            categories {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            classifications {
              items {
                id
                enterpriseId
                storeId
                productId
                classificationId
                updatedAt
                createdAt
              }
              nextToken
            }
            modifiers {
              items {
                id
                enterpriseId
                storeId
                productId
                modifierId
                position
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
        }
        nextToken
      }
      updatedAt
      createdAt
    }
  }
`;
export const createModifier = /* GraphQL */ `
  mutation CreateModifier($input: CreateModifierInput!) {
    createModifier(input: $input) {
      id
      type
      enterpriseId
      storeId
      name
      nameMl {
        en
        zh
        ja
      }
      skus {
        type
        id
        itemId
        refer
        referId
        attributes {
          name
          type
          value
        }
        name
        nameMl {
          en
          zh
          ja
        }
        price
        cost
        unit
        unitSuffix
        barcode
        currency
        model
        locationId
        futureLocationAvailable
        locationIds
        inventories {
          id
          quantity
          cost
          locationId
          unavailableQuantity
          inReadyQuantity
          outReadyQuantity
          expiryDate
          updatedAt
          createdAt
          locations {
            id
            quantity
          }
          attributes {
            name
            type
            value
          }
        }
        locations {
          id
          isActive
          available
          price
        }
        links {
          type
          origin
          originId
          originProductId
          updatedAt
          createdAt
        }
        stock
        stockAlert
        stockAlertQty
        stockTrack
        quantity
        limitQuantity
        isActive
      }
      origin
      orderType
      defaultSkuId
      coverage
      required
      requiredQuantity
      limitQuantity
      products {
        items {
          id
          enterpriseId
          storeId
          productId
          modifierId
          position
          modifier {
            id
            type
            enterpriseId
            storeId
            name
            nameMl {
              en
              zh
              ja
            }
            skus {
              type
              id
              itemId
              refer
              referId
              attributes {
                name
                type
                value
              }
              name
              nameMl {
                en
                zh
                ja
              }
              price
              cost
              unit
              unitSuffix
              barcode
              currency
              model
              locationId
              futureLocationAvailable
              locationIds
              inventories {
                id
                quantity
                cost
                locationId
                unavailableQuantity
                inReadyQuantity
                outReadyQuantity
                expiryDate
                updatedAt
                createdAt
              }
              locations {
                id
                isActive
                available
                price
              }
              links {
                type
                origin
                originId
                originProductId
                updatedAt
                createdAt
              }
              stock
              stockAlert
              stockAlertQty
              stockTrack
              quantity
              limitQuantity
              isActive
            }
            origin
            orderType
            defaultSkuId
            coverage
            required
            requiredQuantity
            limitQuantity
            products {
              items {
                id
                enterpriseId
                storeId
                productId
                modifierId
                position
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
          updatedAt
          createdAt
          product {
            id
            type
            enterpriseId
            storeId
            origin
            refer
            referId
            name
            nameMl {
              en
              zh
              ja
            }
            description
            descriptionMl {
              en
              zh
              ja
            }
            variablePrice
            attributes {
              name
              type
              value
            }
            thumbnail {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            skus {
              type
              id
              itemId
              refer
              referId
              attributes {
                name
                type
                value
              }
              name
              nameMl {
                en
                zh
                ja
              }
              price
              cost
              unit
              unitSuffix
              barcode
              currency
              model
              locationId
              futureLocationAvailable
              locationIds
              inventories {
                id
                quantity
                cost
                locationId
                unavailableQuantity
                inReadyQuantity
                outReadyQuantity
                expiryDate
                updatedAt
                createdAt
              }
              locations {
                id
                isActive
                available
                price
              }
              links {
                type
                origin
                originId
                originProductId
                updatedAt
                createdAt
              }
              stock
              stockAlert
              stockAlertQty
              stockTrack
              quantity
              limitQuantity
              isActive
            }
            trackStock
            taxId
            categories {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            classifications {
              items {
                id
                enterpriseId
                storeId
                productId
                classificationId
                updatedAt
                createdAt
              }
              nextToken
            }
            modifiers {
              items {
                id
                enterpriseId
                storeId
                productId
                modifierId
                position
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
        }
        nextToken
      }
      locationIds
      updatedAt
      createdAt
    }
  }
`;
export const updateModifier = /* GraphQL */ `
  mutation UpdateModifier($input: UpdateModifierInput!) {
    updateModifier(input: $input) {
      id
      type
      enterpriseId
      storeId
      name
      nameMl {
        en
        zh
        ja
      }
      skus {
        type
        id
        itemId
        refer
        referId
        attributes {
          name
          type
          value
        }
        name
        nameMl {
          en
          zh
          ja
        }
        price
        cost
        unit
        unitSuffix
        barcode
        currency
        model
        locationId
        futureLocationAvailable
        locationIds
        inventories {
          id
          quantity
          cost
          locationId
          unavailableQuantity
          inReadyQuantity
          outReadyQuantity
          expiryDate
          updatedAt
          createdAt
          locations {
            id
            quantity
          }
          attributes {
            name
            type
            value
          }
        }
        locations {
          id
          isActive
          available
          price
        }
        links {
          type
          origin
          originId
          originProductId
          updatedAt
          createdAt
        }
        stock
        stockAlert
        stockAlertQty
        stockTrack
        quantity
        limitQuantity
        isActive
      }
      origin
      orderType
      defaultSkuId
      coverage
      required
      requiredQuantity
      limitQuantity
      products {
        items {
          id
          enterpriseId
          storeId
          productId
          modifierId
          position
          modifier {
            id
            type
            enterpriseId
            storeId
            name
            nameMl {
              en
              zh
              ja
            }
            skus {
              type
              id
              itemId
              refer
              referId
              attributes {
                name
                type
                value
              }
              name
              nameMl {
                en
                zh
                ja
              }
              price
              cost
              unit
              unitSuffix
              barcode
              currency
              model
              locationId
              futureLocationAvailable
              locationIds
              inventories {
                id
                quantity
                cost
                locationId
                unavailableQuantity
                inReadyQuantity
                outReadyQuantity
                expiryDate
                updatedAt
                createdAt
              }
              locations {
                id
                isActive
                available
                price
              }
              links {
                type
                origin
                originId
                originProductId
                updatedAt
                createdAt
              }
              stock
              stockAlert
              stockAlertQty
              stockTrack
              quantity
              limitQuantity
              isActive
            }
            origin
            orderType
            defaultSkuId
            coverage
            required
            requiredQuantity
            limitQuantity
            products {
              items {
                id
                enterpriseId
                storeId
                productId
                modifierId
                position
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
          updatedAt
          createdAt
          product {
            id
            type
            enterpriseId
            storeId
            origin
            refer
            referId
            name
            nameMl {
              en
              zh
              ja
            }
            description
            descriptionMl {
              en
              zh
              ja
            }
            variablePrice
            attributes {
              name
              type
              value
            }
            thumbnail {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            skus {
              type
              id
              itemId
              refer
              referId
              attributes {
                name
                type
                value
              }
              name
              nameMl {
                en
                zh
                ja
              }
              price
              cost
              unit
              unitSuffix
              barcode
              currency
              model
              locationId
              futureLocationAvailable
              locationIds
              inventories {
                id
                quantity
                cost
                locationId
                unavailableQuantity
                inReadyQuantity
                outReadyQuantity
                expiryDate
                updatedAt
                createdAt
              }
              locations {
                id
                isActive
                available
                price
              }
              links {
                type
                origin
                originId
                originProductId
                updatedAt
                createdAt
              }
              stock
              stockAlert
              stockAlertQty
              stockTrack
              quantity
              limitQuantity
              isActive
            }
            trackStock
            taxId
            categories {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            classifications {
              items {
                id
                enterpriseId
                storeId
                productId
                classificationId
                updatedAt
                createdAt
              }
              nextToken
            }
            modifiers {
              items {
                id
                enterpriseId
                storeId
                productId
                modifierId
                position
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
        }
        nextToken
      }
      locationIds
      updatedAt
      createdAt
    }
  }
`;
export const deleteModifier = /* GraphQL */ `
  mutation DeleteModifier($input: DeleteModifierInput!) {
    deleteModifier(input: $input) {
      id
      type
      enterpriseId
      storeId
      name
      nameMl {
        en
        zh
        ja
      }
      skus {
        type
        id
        itemId
        refer
        referId
        attributes {
          name
          type
          value
        }
        name
        nameMl {
          en
          zh
          ja
        }
        price
        cost
        unit
        unitSuffix
        barcode
        currency
        model
        locationId
        futureLocationAvailable
        locationIds
        inventories {
          id
          quantity
          cost
          locationId
          unavailableQuantity
          inReadyQuantity
          outReadyQuantity
          expiryDate
          updatedAt
          createdAt
          locations {
            id
            quantity
          }
          attributes {
            name
            type
            value
          }
        }
        locations {
          id
          isActive
          available
          price
        }
        links {
          type
          origin
          originId
          originProductId
          updatedAt
          createdAt
        }
        stock
        stockAlert
        stockAlertQty
        stockTrack
        quantity
        limitQuantity
        isActive
      }
      origin
      orderType
      defaultSkuId
      coverage
      required
      requiredQuantity
      limitQuantity
      products {
        items {
          id
          enterpriseId
          storeId
          productId
          modifierId
          position
          modifier {
            id
            type
            enterpriseId
            storeId
            name
            nameMl {
              en
              zh
              ja
            }
            skus {
              type
              id
              itemId
              refer
              referId
              attributes {
                name
                type
                value
              }
              name
              nameMl {
                en
                zh
                ja
              }
              price
              cost
              unit
              unitSuffix
              barcode
              currency
              model
              locationId
              futureLocationAvailable
              locationIds
              inventories {
                id
                quantity
                cost
                locationId
                unavailableQuantity
                inReadyQuantity
                outReadyQuantity
                expiryDate
                updatedAt
                createdAt
              }
              locations {
                id
                isActive
                available
                price
              }
              links {
                type
                origin
                originId
                originProductId
                updatedAt
                createdAt
              }
              stock
              stockAlert
              stockAlertQty
              stockTrack
              quantity
              limitQuantity
              isActive
            }
            origin
            orderType
            defaultSkuId
            coverage
            required
            requiredQuantity
            limitQuantity
            products {
              items {
                id
                enterpriseId
                storeId
                productId
                modifierId
                position
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
          updatedAt
          createdAt
          product {
            id
            type
            enterpriseId
            storeId
            origin
            refer
            referId
            name
            nameMl {
              en
              zh
              ja
            }
            description
            descriptionMl {
              en
              zh
              ja
            }
            variablePrice
            attributes {
              name
              type
              value
            }
            thumbnail {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            skus {
              type
              id
              itemId
              refer
              referId
              attributes {
                name
                type
                value
              }
              name
              nameMl {
                en
                zh
                ja
              }
              price
              cost
              unit
              unitSuffix
              barcode
              currency
              model
              locationId
              futureLocationAvailable
              locationIds
              inventories {
                id
                quantity
                cost
                locationId
                unavailableQuantity
                inReadyQuantity
                outReadyQuantity
                expiryDate
                updatedAt
                createdAt
              }
              locations {
                id
                isActive
                available
                price
              }
              links {
                type
                origin
                originId
                originProductId
                updatedAt
                createdAt
              }
              stock
              stockAlert
              stockAlertQty
              stockTrack
              quantity
              limitQuantity
              isActive
            }
            trackStock
            taxId
            categories {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            classifications {
              items {
                id
                enterpriseId
                storeId
                productId
                classificationId
                updatedAt
                createdAt
              }
              nextToken
            }
            modifiers {
              items {
                id
                enterpriseId
                storeId
                productId
                modifierId
                position
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
        }
        nextToken
      }
      locationIds
      updatedAt
      createdAt
    }
  }
`;
export const createProductCategory = /* GraphQL */ `
  mutation CreateProductCategory($input: CreateProductCategoryInput!) {
    createProductCategory(input: $input) {
      id
      enterpriseId
      storeId
      productId
      categoryId
      category {
        type
        id
        enterpriseId
        storeId
        name
        displayName
        refer
        referId
        products {
          items {
            id
            enterpriseId
            storeId
            productId
            categoryId
            category {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        locationIds
        updatedAt
        createdAt
      }
      updatedAt
      createdAt
      product {
        id
        type
        enterpriseId
        storeId
        origin
        refer
        referId
        name
        nameMl {
          en
          zh
          ja
        }
        description
        descriptionMl {
          en
          zh
          ja
        }
        variablePrice
        attributes {
          name
          type
          value
        }
        thumbnail {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        skus {
          type
          id
          itemId
          refer
          referId
          attributes {
            name
            type
            value
          }
          name
          nameMl {
            en
            zh
            ja
          }
          price
          cost
          unit
          unitSuffix
          barcode
          currency
          model
          locationId
          futureLocationAvailable
          locationIds
          inventories {
            id
            quantity
            cost
            locationId
            unavailableQuantity
            inReadyQuantity
            outReadyQuantity
            expiryDate
            updatedAt
            createdAt
            locations {
              id
              quantity
            }
            attributes {
              name
              type
              value
            }
          }
          locations {
            id
            isActive
            available
            price
          }
          links {
            type
            origin
            originId
            originProductId
            updatedAt
            createdAt
          }
          stock
          stockAlert
          stockAlertQty
          stockTrack
          quantity
          limitQuantity
          isActive
        }
        trackStock
        taxId
        categories {
          items {
            id
            enterpriseId
            storeId
            productId
            categoryId
            category {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        classifications {
          items {
            id
            enterpriseId
            storeId
            productId
            classificationId
            classification {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        modifiers {
          items {
            id
            enterpriseId
            storeId
            productId
            modifierId
            position
            modifier {
              id
              type
              enterpriseId
              storeId
              name
              nameMl {
                en
                zh
                ja
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              origin
              orderType
              defaultSkuId
              coverage
              required
              requiredQuantity
              limitQuantity
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        locationIds
        updatedAt
        createdAt
      }
    }
  }
`;
export const updateProductCategory = /* GraphQL */ `
  mutation UpdateProductCategory($input: UpdateProductCategoryInput!) {
    updateProductCategory(input: $input) {
      id
      enterpriseId
      storeId
      productId
      categoryId
      category {
        type
        id
        enterpriseId
        storeId
        name
        displayName
        refer
        referId
        products {
          items {
            id
            enterpriseId
            storeId
            productId
            categoryId
            category {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        locationIds
        updatedAt
        createdAt
      }
      updatedAt
      createdAt
      product {
        id
        type
        enterpriseId
        storeId
        origin
        refer
        referId
        name
        nameMl {
          en
          zh
          ja
        }
        description
        descriptionMl {
          en
          zh
          ja
        }
        variablePrice
        attributes {
          name
          type
          value
        }
        thumbnail {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        skus {
          type
          id
          itemId
          refer
          referId
          attributes {
            name
            type
            value
          }
          name
          nameMl {
            en
            zh
            ja
          }
          price
          cost
          unit
          unitSuffix
          barcode
          currency
          model
          locationId
          futureLocationAvailable
          locationIds
          inventories {
            id
            quantity
            cost
            locationId
            unavailableQuantity
            inReadyQuantity
            outReadyQuantity
            expiryDate
            updatedAt
            createdAt
            locations {
              id
              quantity
            }
            attributes {
              name
              type
              value
            }
          }
          locations {
            id
            isActive
            available
            price
          }
          links {
            type
            origin
            originId
            originProductId
            updatedAt
            createdAt
          }
          stock
          stockAlert
          stockAlertQty
          stockTrack
          quantity
          limitQuantity
          isActive
        }
        trackStock
        taxId
        categories {
          items {
            id
            enterpriseId
            storeId
            productId
            categoryId
            category {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        classifications {
          items {
            id
            enterpriseId
            storeId
            productId
            classificationId
            classification {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        modifiers {
          items {
            id
            enterpriseId
            storeId
            productId
            modifierId
            position
            modifier {
              id
              type
              enterpriseId
              storeId
              name
              nameMl {
                en
                zh
                ja
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              origin
              orderType
              defaultSkuId
              coverage
              required
              requiredQuantity
              limitQuantity
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        locationIds
        updatedAt
        createdAt
      }
    }
  }
`;
export const deleteProductCategory = /* GraphQL */ `
  mutation DeleteProductCategory($input: DeleteProductCategoryInput!) {
    deleteProductCategory(input: $input) {
      id
      enterpriseId
      storeId
      productId
      categoryId
      category {
        type
        id
        enterpriseId
        storeId
        name
        displayName
        refer
        referId
        products {
          items {
            id
            enterpriseId
            storeId
            productId
            categoryId
            category {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        locationIds
        updatedAt
        createdAt
      }
      updatedAt
      createdAt
      product {
        id
        type
        enterpriseId
        storeId
        origin
        refer
        referId
        name
        nameMl {
          en
          zh
          ja
        }
        description
        descriptionMl {
          en
          zh
          ja
        }
        variablePrice
        attributes {
          name
          type
          value
        }
        thumbnail {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        skus {
          type
          id
          itemId
          refer
          referId
          attributes {
            name
            type
            value
          }
          name
          nameMl {
            en
            zh
            ja
          }
          price
          cost
          unit
          unitSuffix
          barcode
          currency
          model
          locationId
          futureLocationAvailable
          locationIds
          inventories {
            id
            quantity
            cost
            locationId
            unavailableQuantity
            inReadyQuantity
            outReadyQuantity
            expiryDate
            updatedAt
            createdAt
            locations {
              id
              quantity
            }
            attributes {
              name
              type
              value
            }
          }
          locations {
            id
            isActive
            available
            price
          }
          links {
            type
            origin
            originId
            originProductId
            updatedAt
            createdAt
          }
          stock
          stockAlert
          stockAlertQty
          stockTrack
          quantity
          limitQuantity
          isActive
        }
        trackStock
        taxId
        categories {
          items {
            id
            enterpriseId
            storeId
            productId
            categoryId
            category {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        classifications {
          items {
            id
            enterpriseId
            storeId
            productId
            classificationId
            classification {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        modifiers {
          items {
            id
            enterpriseId
            storeId
            productId
            modifierId
            position
            modifier {
              id
              type
              enterpriseId
              storeId
              name
              nameMl {
                en
                zh
                ja
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              origin
              orderType
              defaultSkuId
              coverage
              required
              requiredQuantity
              limitQuantity
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        locationIds
        updatedAt
        createdAt
      }
    }
  }
`;
export const createProductClassification = /* GraphQL */ `
  mutation CreateProductClassification(
    $input: CreateProductClassificationInput!
  ) {
    createProductClassification(input: $input) {
      id
      enterpriseId
      storeId
      productId
      classificationId
      classification {
        type
        id
        enterpriseId
        storeId
        name
        displayName
        refer
        referId
        products {
          items {
            id
            enterpriseId
            storeId
            productId
            categoryId
            category {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        locationIds
        updatedAt
        createdAt
      }
      updatedAt
      createdAt
      product {
        id
        type
        enterpriseId
        storeId
        origin
        refer
        referId
        name
        nameMl {
          en
          zh
          ja
        }
        description
        descriptionMl {
          en
          zh
          ja
        }
        variablePrice
        attributes {
          name
          type
          value
        }
        thumbnail {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        skus {
          type
          id
          itemId
          refer
          referId
          attributes {
            name
            type
            value
          }
          name
          nameMl {
            en
            zh
            ja
          }
          price
          cost
          unit
          unitSuffix
          barcode
          currency
          model
          locationId
          futureLocationAvailable
          locationIds
          inventories {
            id
            quantity
            cost
            locationId
            unavailableQuantity
            inReadyQuantity
            outReadyQuantity
            expiryDate
            updatedAt
            createdAt
            locations {
              id
              quantity
            }
            attributes {
              name
              type
              value
            }
          }
          locations {
            id
            isActive
            available
            price
          }
          links {
            type
            origin
            originId
            originProductId
            updatedAt
            createdAt
          }
          stock
          stockAlert
          stockAlertQty
          stockTrack
          quantity
          limitQuantity
          isActive
        }
        trackStock
        taxId
        categories {
          items {
            id
            enterpriseId
            storeId
            productId
            categoryId
            category {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        classifications {
          items {
            id
            enterpriseId
            storeId
            productId
            classificationId
            classification {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        modifiers {
          items {
            id
            enterpriseId
            storeId
            productId
            modifierId
            position
            modifier {
              id
              type
              enterpriseId
              storeId
              name
              nameMl {
                en
                zh
                ja
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              origin
              orderType
              defaultSkuId
              coverage
              required
              requiredQuantity
              limitQuantity
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        locationIds
        updatedAt
        createdAt
      }
    }
  }
`;
export const updateProductClassification = /* GraphQL */ `
  mutation UpdateProductClassification(
    $input: UpdateProductClassificationInput!
  ) {
    updateProductClassification(input: $input) {
      id
      enterpriseId
      storeId
      productId
      classificationId
      classification {
        type
        id
        enterpriseId
        storeId
        name
        displayName
        refer
        referId
        products {
          items {
            id
            enterpriseId
            storeId
            productId
            categoryId
            category {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        locationIds
        updatedAt
        createdAt
      }
      updatedAt
      createdAt
      product {
        id
        type
        enterpriseId
        storeId
        origin
        refer
        referId
        name
        nameMl {
          en
          zh
          ja
        }
        description
        descriptionMl {
          en
          zh
          ja
        }
        variablePrice
        attributes {
          name
          type
          value
        }
        thumbnail {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        skus {
          type
          id
          itemId
          refer
          referId
          attributes {
            name
            type
            value
          }
          name
          nameMl {
            en
            zh
            ja
          }
          price
          cost
          unit
          unitSuffix
          barcode
          currency
          model
          locationId
          futureLocationAvailable
          locationIds
          inventories {
            id
            quantity
            cost
            locationId
            unavailableQuantity
            inReadyQuantity
            outReadyQuantity
            expiryDate
            updatedAt
            createdAt
            locations {
              id
              quantity
            }
            attributes {
              name
              type
              value
            }
          }
          locations {
            id
            isActive
            available
            price
          }
          links {
            type
            origin
            originId
            originProductId
            updatedAt
            createdAt
          }
          stock
          stockAlert
          stockAlertQty
          stockTrack
          quantity
          limitQuantity
          isActive
        }
        trackStock
        taxId
        categories {
          items {
            id
            enterpriseId
            storeId
            productId
            categoryId
            category {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        classifications {
          items {
            id
            enterpriseId
            storeId
            productId
            classificationId
            classification {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        modifiers {
          items {
            id
            enterpriseId
            storeId
            productId
            modifierId
            position
            modifier {
              id
              type
              enterpriseId
              storeId
              name
              nameMl {
                en
                zh
                ja
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              origin
              orderType
              defaultSkuId
              coverage
              required
              requiredQuantity
              limitQuantity
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        locationIds
        updatedAt
        createdAt
      }
    }
  }
`;
export const deleteProductClassification = /* GraphQL */ `
  mutation DeleteProductClassification(
    $input: DeleteProductClassificationInput!
  ) {
    deleteProductClassification(input: $input) {
      id
      enterpriseId
      storeId
      productId
      classificationId
      classification {
        type
        id
        enterpriseId
        storeId
        name
        displayName
        refer
        referId
        products {
          items {
            id
            enterpriseId
            storeId
            productId
            categoryId
            category {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        locationIds
        updatedAt
        createdAt
      }
      updatedAt
      createdAt
      product {
        id
        type
        enterpriseId
        storeId
        origin
        refer
        referId
        name
        nameMl {
          en
          zh
          ja
        }
        description
        descriptionMl {
          en
          zh
          ja
        }
        variablePrice
        attributes {
          name
          type
          value
        }
        thumbnail {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        skus {
          type
          id
          itemId
          refer
          referId
          attributes {
            name
            type
            value
          }
          name
          nameMl {
            en
            zh
            ja
          }
          price
          cost
          unit
          unitSuffix
          barcode
          currency
          model
          locationId
          futureLocationAvailable
          locationIds
          inventories {
            id
            quantity
            cost
            locationId
            unavailableQuantity
            inReadyQuantity
            outReadyQuantity
            expiryDate
            updatedAt
            createdAt
            locations {
              id
              quantity
            }
            attributes {
              name
              type
              value
            }
          }
          locations {
            id
            isActive
            available
            price
          }
          links {
            type
            origin
            originId
            originProductId
            updatedAt
            createdAt
          }
          stock
          stockAlert
          stockAlertQty
          stockTrack
          quantity
          limitQuantity
          isActive
        }
        trackStock
        taxId
        categories {
          items {
            id
            enterpriseId
            storeId
            productId
            categoryId
            category {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        classifications {
          items {
            id
            enterpriseId
            storeId
            productId
            classificationId
            classification {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        modifiers {
          items {
            id
            enterpriseId
            storeId
            productId
            modifierId
            position
            modifier {
              id
              type
              enterpriseId
              storeId
              name
              nameMl {
                en
                zh
                ja
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              origin
              orderType
              defaultSkuId
              coverage
              required
              requiredQuantity
              limitQuantity
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        locationIds
        updatedAt
        createdAt
      }
    }
  }
`;
export const createProductModifier = /* GraphQL */ `
  mutation CreateProductModifier($input: CreateProductModifierInput!) {
    createProductModifier(input: $input) {
      id
      enterpriseId
      storeId
      productId
      modifierId
      position
      modifier {
        id
        type
        enterpriseId
        storeId
        name
        nameMl {
          en
          zh
          ja
        }
        skus {
          type
          id
          itemId
          refer
          referId
          attributes {
            name
            type
            value
          }
          name
          nameMl {
            en
            zh
            ja
          }
          price
          cost
          unit
          unitSuffix
          barcode
          currency
          model
          locationId
          futureLocationAvailable
          locationIds
          inventories {
            id
            quantity
            cost
            locationId
            unavailableQuantity
            inReadyQuantity
            outReadyQuantity
            expiryDate
            updatedAt
            createdAt
            locations {
              id
              quantity
            }
            attributes {
              name
              type
              value
            }
          }
          locations {
            id
            isActive
            available
            price
          }
          links {
            type
            origin
            originId
            originProductId
            updatedAt
            createdAt
          }
          stock
          stockAlert
          stockAlertQty
          stockTrack
          quantity
          limitQuantity
          isActive
        }
        origin
        orderType
        defaultSkuId
        coverage
        required
        requiredQuantity
        limitQuantity
        products {
          items {
            id
            enterpriseId
            storeId
            productId
            modifierId
            position
            modifier {
              id
              type
              enterpriseId
              storeId
              name
              nameMl {
                en
                zh
                ja
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              origin
              orderType
              defaultSkuId
              coverage
              required
              requiredQuantity
              limitQuantity
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        locationIds
        updatedAt
        createdAt
      }
      updatedAt
      createdAt
      product {
        id
        type
        enterpriseId
        storeId
        origin
        refer
        referId
        name
        nameMl {
          en
          zh
          ja
        }
        description
        descriptionMl {
          en
          zh
          ja
        }
        variablePrice
        attributes {
          name
          type
          value
        }
        thumbnail {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        skus {
          type
          id
          itemId
          refer
          referId
          attributes {
            name
            type
            value
          }
          name
          nameMl {
            en
            zh
            ja
          }
          price
          cost
          unit
          unitSuffix
          barcode
          currency
          model
          locationId
          futureLocationAvailable
          locationIds
          inventories {
            id
            quantity
            cost
            locationId
            unavailableQuantity
            inReadyQuantity
            outReadyQuantity
            expiryDate
            updatedAt
            createdAt
            locations {
              id
              quantity
            }
            attributes {
              name
              type
              value
            }
          }
          locations {
            id
            isActive
            available
            price
          }
          links {
            type
            origin
            originId
            originProductId
            updatedAt
            createdAt
          }
          stock
          stockAlert
          stockAlertQty
          stockTrack
          quantity
          limitQuantity
          isActive
        }
        trackStock
        taxId
        categories {
          items {
            id
            enterpriseId
            storeId
            productId
            categoryId
            category {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        classifications {
          items {
            id
            enterpriseId
            storeId
            productId
            classificationId
            classification {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        modifiers {
          items {
            id
            enterpriseId
            storeId
            productId
            modifierId
            position
            modifier {
              id
              type
              enterpriseId
              storeId
              name
              nameMl {
                en
                zh
                ja
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              origin
              orderType
              defaultSkuId
              coverage
              required
              requiredQuantity
              limitQuantity
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        locationIds
        updatedAt
        createdAt
      }
    }
  }
`;
export const updateProductModifier = /* GraphQL */ `
  mutation UpdateProductModifier($input: UpdateProductModifierInput!) {
    updateProductModifier(input: $input) {
      id
      enterpriseId
      storeId
      productId
      modifierId
      position
      modifier {
        id
        type
        enterpriseId
        storeId
        name
        nameMl {
          en
          zh
          ja
        }
        skus {
          type
          id
          itemId
          refer
          referId
          attributes {
            name
            type
            value
          }
          name
          nameMl {
            en
            zh
            ja
          }
          price
          cost
          unit
          unitSuffix
          barcode
          currency
          model
          locationId
          futureLocationAvailable
          locationIds
          inventories {
            id
            quantity
            cost
            locationId
            unavailableQuantity
            inReadyQuantity
            outReadyQuantity
            expiryDate
            updatedAt
            createdAt
            locations {
              id
              quantity
            }
            attributes {
              name
              type
              value
            }
          }
          locations {
            id
            isActive
            available
            price
          }
          links {
            type
            origin
            originId
            originProductId
            updatedAt
            createdAt
          }
          stock
          stockAlert
          stockAlertQty
          stockTrack
          quantity
          limitQuantity
          isActive
        }
        origin
        orderType
        defaultSkuId
        coverage
        required
        requiredQuantity
        limitQuantity
        products {
          items {
            id
            enterpriseId
            storeId
            productId
            modifierId
            position
            modifier {
              id
              type
              enterpriseId
              storeId
              name
              nameMl {
                en
                zh
                ja
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              origin
              orderType
              defaultSkuId
              coverage
              required
              requiredQuantity
              limitQuantity
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        locationIds
        updatedAt
        createdAt
      }
      updatedAt
      createdAt
      product {
        id
        type
        enterpriseId
        storeId
        origin
        refer
        referId
        name
        nameMl {
          en
          zh
          ja
        }
        description
        descriptionMl {
          en
          zh
          ja
        }
        variablePrice
        attributes {
          name
          type
          value
        }
        thumbnail {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        skus {
          type
          id
          itemId
          refer
          referId
          attributes {
            name
            type
            value
          }
          name
          nameMl {
            en
            zh
            ja
          }
          price
          cost
          unit
          unitSuffix
          barcode
          currency
          model
          locationId
          futureLocationAvailable
          locationIds
          inventories {
            id
            quantity
            cost
            locationId
            unavailableQuantity
            inReadyQuantity
            outReadyQuantity
            expiryDate
            updatedAt
            createdAt
            locations {
              id
              quantity
            }
            attributes {
              name
              type
              value
            }
          }
          locations {
            id
            isActive
            available
            price
          }
          links {
            type
            origin
            originId
            originProductId
            updatedAt
            createdAt
          }
          stock
          stockAlert
          stockAlertQty
          stockTrack
          quantity
          limitQuantity
          isActive
        }
        trackStock
        taxId
        categories {
          items {
            id
            enterpriseId
            storeId
            productId
            categoryId
            category {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        classifications {
          items {
            id
            enterpriseId
            storeId
            productId
            classificationId
            classification {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        modifiers {
          items {
            id
            enterpriseId
            storeId
            productId
            modifierId
            position
            modifier {
              id
              type
              enterpriseId
              storeId
              name
              nameMl {
                en
                zh
                ja
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              origin
              orderType
              defaultSkuId
              coverage
              required
              requiredQuantity
              limitQuantity
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        locationIds
        updatedAt
        createdAt
      }
    }
  }
`;
export const deleteProductModifier = /* GraphQL */ `
  mutation DeleteProductModifier($input: DeleteProductModifierInput!) {
    deleteProductModifier(input: $input) {
      id
      enterpriseId
      storeId
      productId
      modifierId
      position
      modifier {
        id
        type
        enterpriseId
        storeId
        name
        nameMl {
          en
          zh
          ja
        }
        skus {
          type
          id
          itemId
          refer
          referId
          attributes {
            name
            type
            value
          }
          name
          nameMl {
            en
            zh
            ja
          }
          price
          cost
          unit
          unitSuffix
          barcode
          currency
          model
          locationId
          futureLocationAvailable
          locationIds
          inventories {
            id
            quantity
            cost
            locationId
            unavailableQuantity
            inReadyQuantity
            outReadyQuantity
            expiryDate
            updatedAt
            createdAt
            locations {
              id
              quantity
            }
            attributes {
              name
              type
              value
            }
          }
          locations {
            id
            isActive
            available
            price
          }
          links {
            type
            origin
            originId
            originProductId
            updatedAt
            createdAt
          }
          stock
          stockAlert
          stockAlertQty
          stockTrack
          quantity
          limitQuantity
          isActive
        }
        origin
        orderType
        defaultSkuId
        coverage
        required
        requiredQuantity
        limitQuantity
        products {
          items {
            id
            enterpriseId
            storeId
            productId
            modifierId
            position
            modifier {
              id
              type
              enterpriseId
              storeId
              name
              nameMl {
                en
                zh
                ja
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              origin
              orderType
              defaultSkuId
              coverage
              required
              requiredQuantity
              limitQuantity
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        locationIds
        updatedAt
        createdAt
      }
      updatedAt
      createdAt
      product {
        id
        type
        enterpriseId
        storeId
        origin
        refer
        referId
        name
        nameMl {
          en
          zh
          ja
        }
        description
        descriptionMl {
          en
          zh
          ja
        }
        variablePrice
        attributes {
          name
          type
          value
        }
        thumbnail {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        skus {
          type
          id
          itemId
          refer
          referId
          attributes {
            name
            type
            value
          }
          name
          nameMl {
            en
            zh
            ja
          }
          price
          cost
          unit
          unitSuffix
          barcode
          currency
          model
          locationId
          futureLocationAvailable
          locationIds
          inventories {
            id
            quantity
            cost
            locationId
            unavailableQuantity
            inReadyQuantity
            outReadyQuantity
            expiryDate
            updatedAt
            createdAt
            locations {
              id
              quantity
            }
            attributes {
              name
              type
              value
            }
          }
          locations {
            id
            isActive
            available
            price
          }
          links {
            type
            origin
            originId
            originProductId
            updatedAt
            createdAt
          }
          stock
          stockAlert
          stockAlertQty
          stockTrack
          quantity
          limitQuantity
          isActive
        }
        trackStock
        taxId
        categories {
          items {
            id
            enterpriseId
            storeId
            productId
            categoryId
            category {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        classifications {
          items {
            id
            enterpriseId
            storeId
            productId
            classificationId
            classification {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        modifiers {
          items {
            id
            enterpriseId
            storeId
            productId
            modifierId
            position
            modifier {
              id
              type
              enterpriseId
              storeId
              name
              nameMl {
                en
                zh
                ja
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              origin
              orderType
              defaultSkuId
              coverage
              required
              requiredQuantity
              limitQuantity
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        locationIds
        updatedAt
        createdAt
      }
    }
  }
`;
export const createSalesHistory = /* GraphQL */ `
  mutation CreateSalesHistory($input: CreateSalesHistoryInput!) {
    createSalesHistory(input: $input) {
      storeId
      startedAt
      enterpriseId
      locationId
      endedAt
      reserves
      actualBalance
      currency
      note
      estimateBalance
      cashes {
        name
        value
        count
      }
      reservesHistories {
        type
        amount
        note
        createdEmployeeId
        createdAt
      }
      detail {
        totalSales
        totalRefund
        totalTax
        totalDiscount
        supplyAmount
        totalSalesCount
        cash {
          sales
          refund
        }
        card {
          sales
          refund
        }
        credit {
          sales
          refund
        }
        barcode {
          sales
          refund
        }
        extra {
          sales
          refund
        }
        refund {
          sales
          refund
        }
        products {
          id
          name
          quantity
          amount
        }
        origins {
          pos
          kiosk
          menuorder
          delivery
          market
        }
      }
      updatedAt
      createdAt
    }
  }
`;
export const updateSalesHistory = /* GraphQL */ `
  mutation UpdateSalesHistory($input: UpdateSalesHistoryInput!) {
    updateSalesHistory(input: $input) {
      storeId
      startedAt
      enterpriseId
      locationId
      endedAt
      reserves
      actualBalance
      currency
      note
      estimateBalance
      cashes {
        name
        value
        count
      }
      reservesHistories {
        type
        amount
        note
        createdEmployeeId
        createdAt
      }
      detail {
        totalSales
        totalRefund
        totalTax
        totalDiscount
        supplyAmount
        totalSalesCount
        cash {
          sales
          refund
        }
        card {
          sales
          refund
        }
        credit {
          sales
          refund
        }
        barcode {
          sales
          refund
        }
        extra {
          sales
          refund
        }
        refund {
          sales
          refund
        }
        products {
          id
          name
          quantity
          amount
        }
        origins {
          pos
          kiosk
          menuorder
          delivery
          market
        }
      }
      updatedAt
      createdAt
    }
  }
`;
export const deleteSalesHistory = /* GraphQL */ `
  mutation DeleteSalesHistory($input: DeleteSalesHistoryInput!) {
    deleteSalesHistory(input: $input) {
      storeId
      startedAt
      enterpriseId
      locationId
      endedAt
      reserves
      actualBalance
      currency
      note
      estimateBalance
      cashes {
        name
        value
        count
      }
      reservesHistories {
        type
        amount
        note
        createdEmployeeId
        createdAt
      }
      detail {
        totalSales
        totalRefund
        totalTax
        totalDiscount
        supplyAmount
        totalSalesCount
        cash {
          sales
          refund
        }
        card {
          sales
          refund
        }
        credit {
          sales
          refund
        }
        barcode {
          sales
          refund
        }
        extra {
          sales
          refund
        }
        refund {
          sales
          refund
        }
        products {
          id
          name
          quantity
          amount
        }
        origins {
          pos
          kiosk
          menuorder
          delivery
          market
        }
      }
      updatedAt
      createdAt
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation($input: CreateLocationInput!) {
    createLocation(input: $input) {
      id
      name
      type
      provider
      address
      zip
      phone
      logo {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      representative
      businessNumber
      billing {
        key
        businessNumber
        name
        email
        phone
        contanctName
        contactName
        address
        zip
        card {
          pg
          number
          name
          expMonth
          expYear
          identify
          password
          billingKey
        }
      }
      credit {
        autoCharge
        autoChargeCredit
        autoChargeQuantity
        alertCredit
      }
      businessHours {
        sun {
          open
          close
        }
        mon {
          open
          close
        }
        tue {
          open
          close
        }
        wed {
          open
          close
        }
        thu {
          open
          close
        }
        fri {
          open
          close
        }
        sat {
          open
          close
        }
      }
      storeId
      state
      menuPlanId
      payments {
        refund
        barcode
        barcodeVan
        barcodeTerminalId
      }
      policy {
        locationCost
        loyaltyCost
      }
      loyalty {
        status
        provider
        sharePolicy
        type
        grade
        conditionType
        conditionSubType
        condition {
          minAmount
          perAmount
          minTime
          rate
          expirationPeriod
          excludeProdIds
          discountPointSavable
        }
        conditionItems {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        conditionTiers {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardType
        rewardSubType
        reward {
          requirePoint
          requireOrderAmount
          minUsePoint
          minRequireRate
          maxUsePoint
        }
        rewardItems {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardRates {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardAmounts {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardCashs {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        incentives {
          fulfill
          fulfillPoint
          fulfillCampaignId
          birth
          birthPoint
          birthCampaignId
          birthItem {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
        }
        notification {
          register
          registerCampainId
          save
          saveCampaignId
          use
          useCampaignId
          expire
          expireCampaignId
        }
      }
      kiosk {
        status
        callable
        payable
        menus {
          title
          titleMl {
            en
            zh
            ja
          }
          index
          ids
          items {
            parentId
            badge
            type
            position
            activeOptions
          }
          parentId
        }
        multilingual
        displayImages {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        exchangeTicket
        columnSize
        discounts {
          condition
          couponId
        }
        menuDescription
        updatedAt
        createdAt
      }
      receipt {
        salesSlip
        orderList
        barcode
        footnote
        phone
        orderNumberHide
        orderSheetFontSize
        billsBusinessInfo
        giftFootnote
        giftReceipt
      }
      menuOrder {
        callOptions {
          index
          label
        }
        orderable
        menus {
          title
          titleMl {
            en
            zh
            ja
          }
          index
          ids
          items {
            parentId
            badge
            type
            position
            activeOptions
          }
          parentId
        }
        multilingual
        displayImages {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        columnSize
        fontSize
        notice
        resetTime
        enableCash
        boardType
      }
      kds {
        layout
        alerts {
          urgent
          warning
        }
        disabledProdIds
        orderNotify
      }
      mini {
        defaultItemName
      }
      orderPlanId
      menu {
        board {
          textSize
          columnSize
          pages {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          favorite {
            parentId
            badge
            type
            position
            activeOptions
          }
          enableFavorite
        }
        list {
          pages {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          favorite {
            parentId
            badge
            type
            position
            activeOptions
          }
          shortcuts {
            type
            title
            itemName
            itemPrice
            active
          }
        }
        navOrder
      }
      menus {
        parentId
        position
        page
        pageTitle
      }
      favMenus {
        parentId
        position
        page
        pageTitle
      }
      catMenus {
        title
        index
        ids
        parentId
      }
      market {
        status
        account {
          ident
          holder
          bank
          bankCode
          number
          businessNumber
        }
        paused
        takeoutDiscount
        creditDiscount
        takeout
        intro
        notice
        bookTable
        bookTableNote
        depositAmount
        delivery
        deliveryFee {
          start
          end
          fee
        }
        waitingTime
        itemIds
        updatedAt
        createdAt
      }
      waiting {
        status
        waitingOptions {
          id
          type
          name
          nameMl {
            en
            zh
            ja
          }
          items {
            id
            name
            nameMl {
              en
              zh
              ja
            }
            limitQuantity
          }
          required
          updatedAt
          createdAt
        }
        waitingMinutes
        waitingMinutesHide
        hasForeigner
        foreignerMethod
        notifications {
          created
          ready
          requested
          cancelled
        }
        notices {
          createdNotice
          createdNoticeMl {
            en
            zh
            ja
          }
          requestedNotice
          requestedNoticeMl {
            en
            zh
            ja
          }
        }
        settings {
          autoCancel
          autoCancelMinutes
        }
        updatedAt
        createdAt
      }
      view {
        order {
          excludeColumns
          exportTemplates {
            name
            type
            headers {
              key
              label
            }
          }
        }
        itemReport {
          excludeColumns
        }
        summaryReport {
          excludeColumns
        }
        shippingOrder {
          excludeColumns
        }
        trsItemReport {
          excludeColumns
        }
      }
      subscription {
        status
        account {
          ident
          holder
          bank
          bankCode
          number
          businessNumber
        }
        updatedAt
        createdAt
      }
      settings {
        mode
        table
        diningOption
        retailOption
        orderAlarm
        orderConfirm
        addressInput
        autoPay
        autoReturnItem
        autoSoldout
        menuGridSize
        menuMode
        tableMode
        orderNotify
        orderNotifySkippable
        disablePhonePrefix
        autoCloseScreen
        orderNumberPrefix {
          pos
          kiosk
          menuOrder
        }
        orderMgmt {
          kiosk
          menuorder
          delivery
          pos
        }
        reportBasis
        orderOrigins
        beeper {
          active
          coverage
          skippable
        }
        kioskOptions {
          mode
          isActive
          tableId
          orderId
          callable
          payable
          enableDiningOption
          resetTime
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          columnSize
        }
      }
      devices {
        id
        ident
        locationId
        system
        ipAddress
        brand
        model
        name
        type
        deviceType
        display {
          height
          width
        }
        protocol
        images {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        barcodeReader
        appVersion
        sysVersion
        status
        password
        msgToken
        parentId
        childId
        sleepMode
        kioskOptions {
          mode
          isActive
          tableId
          orderId
          callable
          payable
          enableDiningOption
          resetTime
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          columnSize
        }
        terminalId
        printOptions {
          port
          baudRate
          receipt
          receiptButtonHide
          bills
          refund
          salesEnd
          order
          orderBeep
          orderCount
          orderExcludeIds
          delivery
        }
        updatedAt
        createdAt
      }
      tables {
        id
        width
        height
        name
        axisX
        axisY
        screenHeight
        screenWidth
        initialX
        initialY
        shape
        layout {
          x
          y
          w
          h
        }
        color
        orderId
        disabled
      }
      boardTables {
        title
        index
        items {
          id
          width
          height
          name
          axisX
          axisY
          screenHeight
          screenWidth
          initialX
          initialY
          shape
          layout {
            x
            y
            w
            h
          }
          color
          orderId
          disabled
        }
      }
      gridTables {
        id
        name
        orderId
        index
        page
      }
      modifierSoldoutIds
      delivery {
        baemin
        coupang
        yogiyo
        enableGenProd
        updatedAt
        createdAt
      }
      createdAt
      updatedAt
      sales {
        startedAt
        endedAt
        reserves
        actualBalance
        currency
        note
        estimateBalance
        detail {
          totalSales
          totalRefund
          totalTax
          totalDiscount
          supplyAmount
          totalSalesCount
          cash {
            sales
            refund
          }
          card {
            sales
            refund
          }
          credit {
            sales
            refund
          }
          barcode {
            sales
            refund
          }
          extra {
            sales
            refund
          }
          refund {
            sales
            refund
          }
          products {
            id
            name
            quantity
            amount
          }
          origins {
            pos
            kiosk
            menuorder
            delivery
            market
          }
        }
        reservesHistories {
          type
          amount
          note
          createdEmployeeId
          createdAt
        }
      }
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation($input: DeleteLocationInput!) {
    deleteLocation(input: $input) {
      id
      name
      type
      provider
      address
      zip
      phone
      logo {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      representative
      businessNumber
      billing {
        key
        businessNumber
        name
        email
        phone
        contanctName
        contactName
        address
        zip
        card {
          pg
          number
          name
          expMonth
          expYear
          identify
          password
          billingKey
        }
      }
      credit {
        autoCharge
        autoChargeCredit
        autoChargeQuantity
        alertCredit
      }
      businessHours {
        sun {
          open
          close
        }
        mon {
          open
          close
        }
        tue {
          open
          close
        }
        wed {
          open
          close
        }
        thu {
          open
          close
        }
        fri {
          open
          close
        }
        sat {
          open
          close
        }
      }
      storeId
      state
      menuPlanId
      payments {
        refund
        barcode
        barcodeVan
        barcodeTerminalId
      }
      policy {
        locationCost
        loyaltyCost
      }
      loyalty {
        status
        provider
        sharePolicy
        type
        grade
        conditionType
        conditionSubType
        condition {
          minAmount
          perAmount
          minTime
          rate
          expirationPeriod
          excludeProdIds
          discountPointSavable
        }
        conditionItems {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        conditionTiers {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardType
        rewardSubType
        reward {
          requirePoint
          requireOrderAmount
          minUsePoint
          minRequireRate
          maxUsePoint
        }
        rewardItems {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardRates {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardAmounts {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardCashs {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        incentives {
          fulfill
          fulfillPoint
          fulfillCampaignId
          birth
          birthPoint
          birthCampaignId
          birthItem {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
        }
        notification {
          register
          registerCampainId
          save
          saveCampaignId
          use
          useCampaignId
          expire
          expireCampaignId
        }
      }
      kiosk {
        status
        callable
        payable
        menus {
          title
          titleMl {
            en
            zh
            ja
          }
          index
          ids
          items {
            parentId
            badge
            type
            position
            activeOptions
          }
          parentId
        }
        multilingual
        displayImages {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        exchangeTicket
        columnSize
        discounts {
          condition
          couponId
        }
        menuDescription
        updatedAt
        createdAt
      }
      receipt {
        salesSlip
        orderList
        barcode
        footnote
        phone
        orderNumberHide
        orderSheetFontSize
        billsBusinessInfo
        giftFootnote
        giftReceipt
      }
      menuOrder {
        callOptions {
          index
          label
        }
        orderable
        menus {
          title
          titleMl {
            en
            zh
            ja
          }
          index
          ids
          items {
            parentId
            badge
            type
            position
            activeOptions
          }
          parentId
        }
        multilingual
        displayImages {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        columnSize
        fontSize
        notice
        resetTime
        enableCash
        boardType
      }
      kds {
        layout
        alerts {
          urgent
          warning
        }
        disabledProdIds
        orderNotify
      }
      mini {
        defaultItemName
      }
      orderPlanId
      menu {
        board {
          textSize
          columnSize
          pages {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          favorite {
            parentId
            badge
            type
            position
            activeOptions
          }
          enableFavorite
        }
        list {
          pages {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          favorite {
            parentId
            badge
            type
            position
            activeOptions
          }
          shortcuts {
            type
            title
            itemName
            itemPrice
            active
          }
        }
        navOrder
      }
      menus {
        parentId
        position
        page
        pageTitle
      }
      favMenus {
        parentId
        position
        page
        pageTitle
      }
      catMenus {
        title
        index
        ids
        parentId
      }
      market {
        status
        account {
          ident
          holder
          bank
          bankCode
          number
          businessNumber
        }
        paused
        takeoutDiscount
        creditDiscount
        takeout
        intro
        notice
        bookTable
        bookTableNote
        depositAmount
        delivery
        deliveryFee {
          start
          end
          fee
        }
        waitingTime
        itemIds
        updatedAt
        createdAt
      }
      waiting {
        status
        waitingOptions {
          id
          type
          name
          nameMl {
            en
            zh
            ja
          }
          items {
            id
            name
            nameMl {
              en
              zh
              ja
            }
            limitQuantity
          }
          required
          updatedAt
          createdAt
        }
        waitingMinutes
        waitingMinutesHide
        hasForeigner
        foreignerMethod
        notifications {
          created
          ready
          requested
          cancelled
        }
        notices {
          createdNotice
          createdNoticeMl {
            en
            zh
            ja
          }
          requestedNotice
          requestedNoticeMl {
            en
            zh
            ja
          }
        }
        settings {
          autoCancel
          autoCancelMinutes
        }
        updatedAt
        createdAt
      }
      view {
        order {
          excludeColumns
          exportTemplates {
            name
            type
            headers {
              key
              label
            }
          }
        }
        itemReport {
          excludeColumns
        }
        summaryReport {
          excludeColumns
        }
        shippingOrder {
          excludeColumns
        }
        trsItemReport {
          excludeColumns
        }
      }
      subscription {
        status
        account {
          ident
          holder
          bank
          bankCode
          number
          businessNumber
        }
        updatedAt
        createdAt
      }
      settings {
        mode
        table
        diningOption
        retailOption
        orderAlarm
        orderConfirm
        addressInput
        autoPay
        autoReturnItem
        autoSoldout
        menuGridSize
        menuMode
        tableMode
        orderNotify
        orderNotifySkippable
        disablePhonePrefix
        autoCloseScreen
        orderNumberPrefix {
          pos
          kiosk
          menuOrder
        }
        orderMgmt {
          kiosk
          menuorder
          delivery
          pos
        }
        reportBasis
        orderOrigins
        beeper {
          active
          coverage
          skippable
        }
        kioskOptions {
          mode
          isActive
          tableId
          orderId
          callable
          payable
          enableDiningOption
          resetTime
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          columnSize
        }
      }
      devices {
        id
        ident
        locationId
        system
        ipAddress
        brand
        model
        name
        type
        deviceType
        display {
          height
          width
        }
        protocol
        images {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        barcodeReader
        appVersion
        sysVersion
        status
        password
        msgToken
        parentId
        childId
        sleepMode
        kioskOptions {
          mode
          isActive
          tableId
          orderId
          callable
          payable
          enableDiningOption
          resetTime
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          columnSize
        }
        terminalId
        printOptions {
          port
          baudRate
          receipt
          receiptButtonHide
          bills
          refund
          salesEnd
          order
          orderBeep
          orderCount
          orderExcludeIds
          delivery
        }
        updatedAt
        createdAt
      }
      tables {
        id
        width
        height
        name
        axisX
        axisY
        screenHeight
        screenWidth
        initialX
        initialY
        shape
        layout {
          x
          y
          w
          h
        }
        color
        orderId
        disabled
      }
      boardTables {
        title
        index
        items {
          id
          width
          height
          name
          axisX
          axisY
          screenHeight
          screenWidth
          initialX
          initialY
          shape
          layout {
            x
            y
            w
            h
          }
          color
          orderId
          disabled
        }
      }
      gridTables {
        id
        name
        orderId
        index
        page
      }
      modifierSoldoutIds
      delivery {
        baemin
        coupang
        yogiyo
        enableGenProd
        updatedAt
        createdAt
      }
      createdAt
      updatedAt
      sales {
        startedAt
        endedAt
        reserves
        actualBalance
        currency
        note
        estimateBalance
        detail {
          totalSales
          totalRefund
          totalTax
          totalDiscount
          supplyAmount
          totalSalesCount
          cash {
            sales
            refund
          }
          card {
            sales
            refund
          }
          credit {
            sales
            refund
          }
          barcode {
            sales
            refund
          }
          extra {
            sales
            refund
          }
          refund {
            sales
            refund
          }
          products {
            id
            name
            quantity
            amount
          }
          origins {
            pos
            kiosk
            menuorder
            delivery
            market
          }
        }
        reservesHistories {
          type
          amount
          note
          createdEmployeeId
          createdAt
        }
      }
    }
  }
`;
export const createGlobalDelta = /* GraphQL */ `
  mutation CreateGlobalDelta($input: CreateGlobalDeltaInput!) {
    createGlobalDelta(input: $input) {
      id
      type
      storeId
      locationId
      data
      updatedAt
      createdAt
      timestamp
      expdate
    }
  }
`;
export const updateGlobalDelta = /* GraphQL */ `
  mutation UpdateGlobalDelta($input: UpdateGlobalDeltaInput!) {
    updateGlobalDelta(input: $input) {
      id
      type
      storeId
      locationId
      data
      updatedAt
      createdAt
      timestamp
      expdate
    }
  }
`;
export const deleteGlobalDelta = /* GraphQL */ `
  mutation DeleteGlobalDelta($input: DeleteGlobalDeltaInput!) {
    deleteGlobalDelta(input: $input) {
      id
      type
      storeId
      locationId
      data
      updatedAt
      createdAt
      timestamp
      expdate
    }
  }
`;
export const createBillHistory = /* GraphQL */ `
  mutation CreateBillHistory($input: CreateBillHistoryInput!) {
    createBillHistory(input: $input) {
      id
      date
      storeId
      locationId
      name
      type
      status
      failReason
      discounts {
        name
        amount
        transactionId
      }
      amount
      currency
      paidAt
      refundedAt
      transaction {
        cardName
        cardNumber
        cardQuota
        pg
        transactionId
        amount
        currency
        method
        purchasedAt
        requestedAt
        revokedAt
        status
        statusEn
        statusKo
        receiptId
        receiptUrl
      }
      items {
        type
        name
        amount
        details {
          type
          name
          amount
          basePrice
          unitPrice
          usage
          rate
          referId
          period
        }
      }
      note
      updatedAt
      createdAt
    }
  }
`;
export const updateBillHistory = /* GraphQL */ `
  mutation UpdateBillHistory($input: UpdateBillHistoryInput!) {
    updateBillHistory(input: $input) {
      id
      date
      storeId
      locationId
      name
      type
      status
      failReason
      discounts {
        name
        amount
        transactionId
      }
      amount
      currency
      paidAt
      refundedAt
      transaction {
        cardName
        cardNumber
        cardQuota
        pg
        transactionId
        amount
        currency
        method
        purchasedAt
        requestedAt
        revokedAt
        status
        statusEn
        statusKo
        receiptId
        receiptUrl
      }
      items {
        type
        name
        amount
        details {
          type
          name
          amount
          basePrice
          unitPrice
          usage
          rate
          referId
          period
        }
      }
      note
      updatedAt
      createdAt
    }
  }
`;
export const deleteBillHistory = /* GraphQL */ `
  mutation DeleteBillHistory($input: DeleteBillHistoryInput!) {
    deleteBillHistory(input: $input) {
      id
      date
      storeId
      locationId
      name
      type
      status
      failReason
      discounts {
        name
        amount
        transactionId
      }
      amount
      currency
      paidAt
      refundedAt
      transaction {
        cardName
        cardNumber
        cardQuota
        pg
        transactionId
        amount
        currency
        method
        purchasedAt
        requestedAt
        revokedAt
        status
        statusEn
        statusKo
        receiptId
        receiptUrl
      }
      items {
        type
        name
        amount
        details {
          type
          name
          amount
          basePrice
          unitPrice
          usage
          rate
          referId
          period
        }
      }
      note
      updatedAt
      createdAt
    }
  }
`;
export const createBillingHistory = /* GraphQL */ `
  mutation CreateBillingHistory($input: CreateBillingHistoryInput!) {
    createBillingHistory(input: $input) {
      id
      date
      enterpriseId
      storeId
      locationId
      name
      type
      status
      failReason
      amount
      currency
      paidAt
      refundedAt
      transaction {
        cardName
        cardNumber
        cardQuota
        pg
        transactionId
        amount
        currency
        method
        purchasedAt
        requestedAt
        revokedAt
        status
        statusEn
        statusKo
        receiptId
        receiptUrl
      }
      items {
        locationId
        name
        type
        amount
        details {
          type
          name
          amount
          basePrice
          unitPrice
          usage
          rate
          referId
          period
        }
        category
        quantity
        currency
      }
      note
      updatedAt
      createdAt
    }
  }
`;
export const updateBillingHistory = /* GraphQL */ `
  mutation UpdateBillingHistory($input: UpdateBillingHistoryInput!) {
    updateBillingHistory(input: $input) {
      id
      date
      enterpriseId
      storeId
      locationId
      name
      type
      status
      failReason
      amount
      currency
      paidAt
      refundedAt
      transaction {
        cardName
        cardNumber
        cardQuota
        pg
        transactionId
        amount
        currency
        method
        purchasedAt
        requestedAt
        revokedAt
        status
        statusEn
        statusKo
        receiptId
        receiptUrl
      }
      items {
        locationId
        name
        type
        amount
        details {
          type
          name
          amount
          basePrice
          unitPrice
          usage
          rate
          referId
          period
        }
        category
        quantity
        currency
      }
      note
      updatedAt
      createdAt
    }
  }
`;
export const deleteBillingHistory = /* GraphQL */ `
  mutation DeleteBillingHistory($input: DeleteBillingHistoryInput!) {
    deleteBillingHistory(input: $input) {
      id
      date
      enterpriseId
      storeId
      locationId
      name
      type
      status
      failReason
      amount
      currency
      paidAt
      refundedAt
      transaction {
        cardName
        cardNumber
        cardQuota
        pg
        transactionId
        amount
        currency
        method
        purchasedAt
        requestedAt
        revokedAt
        status
        statusEn
        statusKo
        receiptId
        receiptUrl
      }
      items {
        locationId
        name
        type
        amount
        details {
          type
          name
          amount
          basePrice
          unitPrice
          usage
          rate
          referId
          period
        }
        category
        quantity
        currency
      }
      note
      updatedAt
      createdAt
    }
  }
`;
export const createLabelTemplate = /* GraphQL */ `
  mutation CreateLabelTemplate($input: CreateLabelTemplateInput!) {
    createLabelTemplate(input: $input) {
      id
      storeId
      locationId
      name
      components {
        type
        format
        height
        fontSize
        maxLines
        align
        bold
      }
      pageLayout {
        horizontalCount
        width
        height
        marginRight
      }
      updatedAt
      createdAt
    }
  }
`;
export const updateLabelTemplate = /* GraphQL */ `
  mutation UpdateLabelTemplate($input: UpdateLabelTemplateInput!) {
    updateLabelTemplate(input: $input) {
      id
      storeId
      locationId
      name
      components {
        type
        format
        height
        fontSize
        maxLines
        align
        bold
      }
      pageLayout {
        horizontalCount
        width
        height
        marginRight
      }
      updatedAt
      createdAt
    }
  }
`;
export const deleteLabelTemplate = /* GraphQL */ `
  mutation DeleteLabelTemplate($input: DeleteLabelTemplateInput!) {
    deleteLabelTemplate(input: $input) {
      id
      storeId
      locationId
      name
      components {
        type
        format
        height
        fontSize
        maxLines
        align
        bold
      }
      pageLayout {
        horizontalCount
        width
        height
        marginRight
      }
      updatedAt
      createdAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      id
      enterpriseId
      storeId
      locationId
      priority
      read
      type
      title
      body
      action {
        category
        method
        args
      }
      updatedAt
      createdAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage($input: UpdateMessageInput!) {
    updateMessage(input: $input) {
      id
      enterpriseId
      storeId
      locationId
      priority
      read
      type
      title
      body
      action {
        category
        method
        args
      }
      updatedAt
      createdAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage($input: DeleteMessageInput!) {
    deleteMessage(input: $input) {
      id
      enterpriseId
      storeId
      locationId
      priority
      read
      type
      title
      body
      action {
        category
        method
        args
      }
      updatedAt
      createdAt
    }
  }
`;
export const createCafe24Auth = /* GraphQL */ `
  mutation CreateCafe24Auth($input: CreateCafe24AuthInput!) {
    createCafe24Auth(input: $input) {
      id
      storeId
      accessToken
      accessTokenExpiredAt
      refreshToken
      refreshToeknExpiredAt
      clientId
      userId
      scopes
      issuedAt
      shopNumber
      updatedAt
      createdAt
    }
  }
`;
export const updateCafe24Auth = /* GraphQL */ `
  mutation UpdateCafe24Auth($input: UpdateCafe24AuthInput!) {
    updateCafe24Auth(input: $input) {
      id
      storeId
      accessToken
      accessTokenExpiredAt
      refreshToken
      refreshToeknExpiredAt
      clientId
      userId
      scopes
      issuedAt
      shopNumber
      updatedAt
      createdAt
    }
  }
`;
export const deleteCafe24Auth = /* GraphQL */ `
  mutation DeleteCafe24Auth($input: DeleteCafe24AuthInput!) {
    deleteCafe24Auth(input: $input) {
      id
      storeId
      accessToken
      accessTokenExpiredAt
      refreshToken
      refreshToeknExpiredAt
      clientId
      userId
      scopes
      issuedAt
      shopNumber
      updatedAt
      createdAt
    }
  }
`;
export const createCafe24Event = /* GraphQL */ `
  mutation CreateCafe24Event($input: CreateCafe24EventInput!) {
    createCafe24Event(input: $input) {
      id
      number
      resource
      updatedAt
      createdAt
    }
  }
`;
export const updateCafe24Event = /* GraphQL */ `
  mutation UpdateCafe24Event($input: UpdateCafe24EventInput!) {
    updateCafe24Event(input: $input) {
      id
      number
      resource
      updatedAt
      createdAt
    }
  }
`;
export const deleteCafe24Event = /* GraphQL */ `
  mutation DeleteCafe24Event($input: DeleteCafe24EventInput!) {
    deleteCafe24Event(input: $input) {
      id
      number
      resource
      updatedAt
      createdAt
    }
  }
`;
export const createItemLinkHistory = /* GraphQL */ `
  mutation CreateItemLinkHistory($input: CreateItemLinkHistoryInput!) {
    createItemLinkHistory(input: $input) {
      id
      storeId
      type
      subType
      provider
      linkQuantity
      unlinkQuantity
      totalLinkQuantity
      employeeId
      updatedAt
      createdAt
    }
  }
`;
export const updateItemLinkHistory = /* GraphQL */ `
  mutation UpdateItemLinkHistory($input: UpdateItemLinkHistoryInput!) {
    updateItemLinkHistory(input: $input) {
      id
      storeId
      type
      subType
      provider
      linkQuantity
      unlinkQuantity
      totalLinkQuantity
      employeeId
      updatedAt
      createdAt
    }
  }
`;
export const deleteItemLinkHistory = /* GraphQL */ `
  mutation DeleteItemLinkHistory($input: DeleteItemLinkHistoryInput!) {
    deleteItemLinkHistory(input: $input) {
      id
      storeId
      type
      subType
      provider
      linkQuantity
      unlinkQuantity
      totalLinkQuantity
      employeeId
      updatedAt
      createdAt
    }
  }
`;
export const createCustomerTierUpdateHistory = /* GraphQL */ `
  mutation CreateCustomerTierUpdateHistory(
    $input: CreateCustomerTierUpdateHistoryInput!
  ) {
    createCustomerTierUpdateHistory(input: $input) {
      id
      storeId
      type
      date
      totalCount
      upCount
      downCount
      coupons {
        couponId
        count
      }
      updatedAt
      createdAt
    }
  }
`;
export const updateCustomerTierUpdateHistory = /* GraphQL */ `
  mutation UpdateCustomerTierUpdateHistory(
    $input: UpdateCustomerTierUpdateHistoryInput!
  ) {
    updateCustomerTierUpdateHistory(input: $input) {
      id
      storeId
      type
      date
      totalCount
      upCount
      downCount
      coupons {
        couponId
        count
      }
      updatedAt
      createdAt
    }
  }
`;
export const deleteCustomerTierUpdateHistory = /* GraphQL */ `
  mutation DeleteCustomerTierUpdateHistory(
    $input: DeleteCustomerTierUpdateHistoryInput!
  ) {
    deleteCustomerTierUpdateHistory(input: $input) {
      id
      storeId
      type
      date
      totalCount
      upCount
      downCount
      coupons {
        couponId
        count
      }
      updatedAt
      createdAt
    }
  }
`;
export const createStoreCafe24Config = /* GraphQL */ `
  mutation CreateStoreCafe24Config($input: CreateStoreCafe24ConfigInput!) {
    createStoreCafe24Config(input: $input) {
      id
      usableCouponIds
      autoTier {
        active
        tiers {
          tierId
          name
          coupons {
            id
          }
          minAmount
          maxAmount
        }
        interval
        intervalValue
        orderPeriod
        exceptionTierIds
        launchDate
        promotionIntervalValue
        promotionLaunchDate
      }
      updatedAt
      createdAt
    }
  }
`;
export const updateStoreCafe24Config = /* GraphQL */ `
  mutation UpdateStoreCafe24Config($input: UpdateStoreCafe24ConfigInput!) {
    updateStoreCafe24Config(input: $input) {
      id
      usableCouponIds
      autoTier {
        active
        tiers {
          tierId
          name
          coupons {
            id
          }
          minAmount
          maxAmount
        }
        interval
        intervalValue
        orderPeriod
        exceptionTierIds
        launchDate
        promotionIntervalValue
        promotionLaunchDate
      }
      updatedAt
      createdAt
    }
  }
`;
export const deleteStoreCafe24Config = /* GraphQL */ `
  mutation DeleteStoreCafe24Config($input: DeleteStoreCafe24ConfigInput!) {
    deleteStoreCafe24Config(input: $input) {
      id
      usableCouponIds
      autoTier {
        active
        tiers {
          tierId
          name
          coupons {
            id
          }
          minAmount
          maxAmount
        }
        interval
        intervalValue
        orderPeriod
        exceptionTierIds
        launchDate
        promotionIntervalValue
        promotionLaunchDate
      }
      updatedAt
      createdAt
    }
  }
`;
export const createStore = /* GraphQL */ `
  mutation CreateStore($input: CreateStoreInput!) {
    createStore(input: $input) {
      id
      enterpriseId
      info {
        name
        phone
        email
        representative
        businessType
        businessNumber
        businessHours {
          sun {
            open
            close
          }
          mon {
            open
            close
          }
          tue {
            open
            close
          }
          wed {
            open
            close
          }
          thu {
            open
            close
          }
          fri {
            open
            close
          }
          sat {
            open
            close
          }
        }
        industryType
        sendNumber
        currency
        address
        zip
        logo {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        csPhone
      }
      policy {
        multiLocation
        provider {
          key
          ident
          customer
          coupon
          inventory
          locationId
        }
      }
      currency
      payments {
        card
        cardJoined
        cash
        credit
        refund
      }
      devices {
        id
        ident
        locationId
        system
        ipAddress
        brand
        model
        name
        type
        deviceType
        display {
          height
          width
        }
        protocol
        images {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        barcodeReader
        appVersion
        sysVersion
        status
        password
        msgToken
        parentId
        childId
        sleepMode
        kioskOptions {
          mode
          isActive
          tableId
          orderId
          callable
          payable
          enableDiningOption
          resetTime
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          columnSize
        }
        terminalId
        printOptions {
          port
          baudRate
          receipt
          receiptButtonHide
          bills
          refund
          salesEnd
          order
          orderBeep
          orderCount
          orderExcludeIds
          delivery
        }
        updatedAt
        createdAt
      }
      plans {
        menus {
          id
          name
          mode
          size
          menus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          pages {
            index
            parentId
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            title
            titleMl {
              en
              zh
              ja
            }
          }
          updatedAt
          createdAt
        }
        orders {
          id
          name
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          updatedAt
          createdAt
        }
      }
      taxes {
        id
        type
        name
        enabled
        rate
        method
        targetType
      }
      receipt {
        salesSlip
        orderList
        barcode
        footnote
        phone
        orderNumberHide
        orderSheetFontSize
        billsBusinessInfo
        giftFootnote
        giftReceipt
      }
      subscription {
        status
        account {
          ident
          holder
          bank
          bankCode
          number
          businessNumber
        }
        updatedAt
        createdAt
      }
      view {
        order {
          excludeColumns
          exportTemplates {
            name
            type
            headers {
              key
              label
            }
          }
        }
        itemReport {
          excludeColumns
        }
        summaryReport {
          excludeColumns
        }
        shippingOrder {
          excludeColumns
        }
        trsItemReport {
          excludeColumns
        }
      }
      settings {
        mode
        table
        diningOption
        retailOption
        orderAlarm
        orderConfirm
        addressInput
        autoPay
        autoReturnItem
        autoSoldout
        menuGridSize
        menuMode
        tableMode
        orderNotify
        orderNotifySkippable
        disablePhonePrefix
        autoCloseScreen
        orderNumberPrefix {
          pos
          kiosk
          menuOrder
        }
        orderMgmt {
          kiosk
          menuorder
          delivery
          pos
        }
        reportBasis
        orderOrigins
        beeper {
          active
          coverage
          skippable
        }
        kioskOptions {
          mode
          isActive
          tableId
          orderId
          callable
          payable
          enableDiningOption
          resetTime
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          columnSize
        }
      }
      item {
        attributes {
          name
          type
          value
        }
        actualPrice
        skuMgmt
        barcodeMgmt
        stockTrack
        variablePrice
        locationCreatable
      }
      inventory {
        attributes {
          name
          type
          value
        }
        expiryDateMgmt
        expiryDateAlertDays
      }
      customer {
        attributes {
          name
          type
          value
        }
      }
      billing {
        key
        businessNumber
        name
        email
        phone
        contanctName
        contactName
        address
        zip
        card {
          pg
          number
          name
          expMonth
          expYear
          identify
          password
          billingKey
        }
      }
      credit {
        autoCharge
        autoChargeCredit
        autoChargeQuantity
        alertCredit
      }
      kakao {
        agency
        key
        channelId
        categoryCode
        status
        updatedAt
        createdAt
      }
      review {
        status
        campaignId
        updatedAt
        createdAt
      }
      kiosk {
        status
        callable
        payable
        menus {
          title
          titleMl {
            en
            zh
            ja
          }
          index
          ids
          items {
            parentId
            badge
            type
            position
            activeOptions
          }
          parentId
        }
        multilingual
        displayImages {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        exchangeTicket
        columnSize
        discounts {
          condition
          couponId
        }
        menuDescription
        updatedAt
        createdAt
      }
      menuOrder {
        callOptions {
          index
          label
        }
        orderable
        menus {
          title
          titleMl {
            en
            zh
            ja
          }
          index
          ids
          items {
            parentId
            badge
            type
            position
            activeOptions
          }
          parentId
        }
        multilingual
        displayImages {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        columnSize
        fontSize
        notice
        resetTime
        enableCash
        boardType
      }
      kds {
        layout
        alerts {
          urgent
          warning
        }
        disabledProdIds
        orderNotify
      }
      mirror {
        images {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        video {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
      }
      loyalty {
        status
        provider
        sharePolicy
        type
        grade
        conditionType
        conditionSubType
        condition {
          minAmount
          perAmount
          minTime
          rate
          expirationPeriod
          excludeProdIds
          discountPointSavable
        }
        conditionItems {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        conditionTiers {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardType
        rewardSubType
        reward {
          requirePoint
          requireOrderAmount
          minUsePoint
          minRequireRate
          maxUsePoint
        }
        rewardItems {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardRates {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardAmounts {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardCashs {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        incentives {
          fulfill
          fulfillPoint
          fulfillCampaignId
          birth
          birthPoint
          birthCampaignId
          birthItem {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
        }
        notification {
          register
          registerCampainId
          save
          saveCampaignId
          use
          useCampaignId
          expire
          expireCampaignId
        }
      }
      market {
        status
        account {
          ident
          holder
          bank
          bankCode
          number
          businessNumber
        }
        paused
        takeoutDiscount
        creditDiscount
        takeout
        intro
        notice
        bookTable
        bookTableNote
        depositAmount
        delivery
        deliveryFee {
          start
          end
          fee
        }
        waitingTime
        itemIds
        updatedAt
        createdAt
      }
      notification {
        options {
          type
          email
          sms
        }
      }
      groups {
        items {
          id
          name
          type
          storeGroupsId
          locationGroupsId
          key
          conditions {
            key
            object
            field
            operations {
              operation
              type
              value
            }
            value
            operation
            type
          }
          count
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      location {
        id
        name
        type
        provider
        address
        zip
        phone
        logo {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        representative
        businessNumber
        billing {
          key
          businessNumber
          name
          email
          phone
          contanctName
          contactName
          address
          zip
          card {
            pg
            number
            name
            expMonth
            expYear
            identify
            password
            billingKey
          }
        }
        credit {
          autoCharge
          autoChargeCredit
          autoChargeQuantity
          alertCredit
        }
        businessHours {
          sun {
            open
            close
          }
          mon {
            open
            close
          }
          tue {
            open
            close
          }
          wed {
            open
            close
          }
          thu {
            open
            close
          }
          fri {
            open
            close
          }
          sat {
            open
            close
          }
        }
        storeId
        state
        menuPlanId
        payments {
          refund
          barcode
          barcodeVan
          barcodeTerminalId
        }
        policy {
          locationCost
          loyaltyCost
        }
        loyalty {
          status
          provider
          sharePolicy
          type
          grade
          conditionType
          conditionSubType
          condition {
            minAmount
            perAmount
            minTime
            rate
            expirationPeriod
            excludeProdIds
            discountPointSavable
          }
          conditionItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          conditionTiers {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardType
          rewardSubType
          reward {
            requirePoint
            requireOrderAmount
            minUsePoint
            minRequireRate
            maxUsePoint
          }
          rewardItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardRates {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardAmounts {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardCashs {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          incentives {
            fulfill
            fulfillPoint
            fulfillCampaignId
            birth
            birthPoint
            birthCampaignId
            birthItem {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
          }
          notification {
            register
            registerCampainId
            save
            saveCampaignId
            use
            useCampaignId
            expire
            expireCampaignId
          }
        }
        kiosk {
          status
          callable
          payable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          exchangeTicket
          columnSize
          discounts {
            condition
            couponId
          }
          menuDescription
          updatedAt
          createdAt
        }
        receipt {
          salesSlip
          orderList
          barcode
          footnote
          phone
          orderNumberHide
          orderSheetFontSize
          billsBusinessInfo
          giftFootnote
          giftReceipt
        }
        menuOrder {
          callOptions {
            index
            label
          }
          orderable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          columnSize
          fontSize
          notice
          resetTime
          enableCash
          boardType
        }
        kds {
          layout
          alerts {
            urgent
            warning
          }
          disabledProdIds
          orderNotify
        }
        mini {
          defaultItemName
        }
        orderPlanId
        menu {
          board {
            textSize
            columnSize
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            enableFavorite
          }
          list {
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            shortcuts {
              type
              title
              itemName
              itemPrice
              active
            }
          }
          navOrder
        }
        menus {
          parentId
          position
          page
          pageTitle
        }
        favMenus {
          parentId
          position
          page
          pageTitle
        }
        catMenus {
          title
          index
          ids
          parentId
        }
        market {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          paused
          takeoutDiscount
          creditDiscount
          takeout
          intro
          notice
          bookTable
          bookTableNote
          depositAmount
          delivery
          deliveryFee {
            start
            end
            fee
          }
          waitingTime
          itemIds
          updatedAt
          createdAt
        }
        waiting {
          status
          waitingOptions {
            id
            type
            name
            nameMl {
              en
              zh
              ja
            }
            items {
              id
              name
              nameMl {
                en
                zh
                ja
              }
              limitQuantity
            }
            required
            updatedAt
            createdAt
          }
          waitingMinutes
          waitingMinutesHide
          hasForeigner
          foreignerMethod
          notifications {
            created
            ready
            requested
            cancelled
          }
          notices {
            createdNotice
            createdNoticeMl {
              en
              zh
              ja
            }
            requestedNotice
            requestedNoticeMl {
              en
              zh
              ja
            }
          }
          settings {
            autoCancel
            autoCancelMinutes
          }
          updatedAt
          createdAt
        }
        view {
          order {
            excludeColumns
            exportTemplates {
              name
              type
              headers {
                key
                label
              }
            }
          }
          itemReport {
            excludeColumns
          }
          summaryReport {
            excludeColumns
          }
          shippingOrder {
            excludeColumns
          }
          trsItemReport {
            excludeColumns
          }
        }
        subscription {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          updatedAt
          createdAt
        }
        settings {
          mode
          table
          diningOption
          retailOption
          orderAlarm
          orderConfirm
          addressInput
          autoPay
          autoReturnItem
          autoSoldout
          menuGridSize
          menuMode
          tableMode
          orderNotify
          orderNotifySkippable
          disablePhonePrefix
          autoCloseScreen
          orderNumberPrefix {
            pos
            kiosk
            menuOrder
          }
          orderMgmt {
            kiosk
            menuorder
            delivery
            pos
          }
          reportBasis
          orderOrigins
          beeper {
            active
            coverage
            skippable
          }
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
        }
        devices {
          id
          ident
          locationId
          system
          ipAddress
          brand
          model
          name
          type
          deviceType
          display {
            height
            width
          }
          protocol
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          barcodeReader
          appVersion
          sysVersion
          status
          password
          msgToken
          parentId
          childId
          sleepMode
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
          terminalId
          printOptions {
            port
            baudRate
            receipt
            receiptButtonHide
            bills
            refund
            salesEnd
            order
            orderBeep
            orderCount
            orderExcludeIds
            delivery
          }
          updatedAt
          createdAt
        }
        tables {
          id
          width
          height
          name
          axisX
          axisY
          screenHeight
          screenWidth
          initialX
          initialY
          shape
          layout {
            x
            y
            w
            h
          }
          color
          orderId
          disabled
        }
        boardTables {
          title
          index
          items {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
        }
        gridTables {
          id
          name
          orderId
          index
          page
        }
        modifierSoldoutIds
        delivery {
          baemin
          coupang
          yogiyo
          enableGenProd
          updatedAt
          createdAt
        }
        createdAt
        updatedAt
        sales {
          startedAt
          endedAt
          reserves
          actualBalance
          currency
          note
          estimateBalance
          detail {
            totalSales
            totalRefund
            totalTax
            totalDiscount
            supplyAmount
            totalSalesCount
            cash {
              sales
              refund
            }
            card {
              sales
              refund
            }
            credit {
              sales
              refund
            }
            barcode {
              sales
              refund
            }
            extra {
              sales
              refund
            }
            refund {
              sales
              refund
            }
            products {
              id
              name
              quantity
              amount
            }
            origins {
              pos
              kiosk
              menuorder
              delivery
              market
            }
          }
          reservesHistories {
            type
            amount
            note
            createdEmployeeId
            createdAt
          }
        }
      }
      locations {
        items {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteStore = /* GraphQL */ `
  mutation DeleteStore($input: DeleteStoreInput!) {
    deleteStore(input: $input) {
      id
      enterpriseId
      info {
        name
        phone
        email
        representative
        businessType
        businessNumber
        businessHours {
          sun {
            open
            close
          }
          mon {
            open
            close
          }
          tue {
            open
            close
          }
          wed {
            open
            close
          }
          thu {
            open
            close
          }
          fri {
            open
            close
          }
          sat {
            open
            close
          }
        }
        industryType
        sendNumber
        currency
        address
        zip
        logo {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        csPhone
      }
      policy {
        multiLocation
        provider {
          key
          ident
          customer
          coupon
          inventory
          locationId
        }
      }
      currency
      payments {
        card
        cardJoined
        cash
        credit
        refund
      }
      devices {
        id
        ident
        locationId
        system
        ipAddress
        brand
        model
        name
        type
        deviceType
        display {
          height
          width
        }
        protocol
        images {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        barcodeReader
        appVersion
        sysVersion
        status
        password
        msgToken
        parentId
        childId
        sleepMode
        kioskOptions {
          mode
          isActive
          tableId
          orderId
          callable
          payable
          enableDiningOption
          resetTime
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          columnSize
        }
        terminalId
        printOptions {
          port
          baudRate
          receipt
          receiptButtonHide
          bills
          refund
          salesEnd
          order
          orderBeep
          orderCount
          orderExcludeIds
          delivery
        }
        updatedAt
        createdAt
      }
      plans {
        menus {
          id
          name
          mode
          size
          menus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          pages {
            index
            parentId
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            title
            titleMl {
              en
              zh
              ja
            }
          }
          updatedAt
          createdAt
        }
        orders {
          id
          name
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          updatedAt
          createdAt
        }
      }
      taxes {
        id
        type
        name
        enabled
        rate
        method
        targetType
      }
      receipt {
        salesSlip
        orderList
        barcode
        footnote
        phone
        orderNumberHide
        orderSheetFontSize
        billsBusinessInfo
        giftFootnote
        giftReceipt
      }
      subscription {
        status
        account {
          ident
          holder
          bank
          bankCode
          number
          businessNumber
        }
        updatedAt
        createdAt
      }
      view {
        order {
          excludeColumns
          exportTemplates {
            name
            type
            headers {
              key
              label
            }
          }
        }
        itemReport {
          excludeColumns
        }
        summaryReport {
          excludeColumns
        }
        shippingOrder {
          excludeColumns
        }
        trsItemReport {
          excludeColumns
        }
      }
      settings {
        mode
        table
        diningOption
        retailOption
        orderAlarm
        orderConfirm
        addressInput
        autoPay
        autoReturnItem
        autoSoldout
        menuGridSize
        menuMode
        tableMode
        orderNotify
        orderNotifySkippable
        disablePhonePrefix
        autoCloseScreen
        orderNumberPrefix {
          pos
          kiosk
          menuOrder
        }
        orderMgmt {
          kiosk
          menuorder
          delivery
          pos
        }
        reportBasis
        orderOrigins
        beeper {
          active
          coverage
          skippable
        }
        kioskOptions {
          mode
          isActive
          tableId
          orderId
          callable
          payable
          enableDiningOption
          resetTime
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          columnSize
        }
      }
      item {
        attributes {
          name
          type
          value
        }
        actualPrice
        skuMgmt
        barcodeMgmt
        stockTrack
        variablePrice
        locationCreatable
      }
      inventory {
        attributes {
          name
          type
          value
        }
        expiryDateMgmt
        expiryDateAlertDays
      }
      customer {
        attributes {
          name
          type
          value
        }
      }
      billing {
        key
        businessNumber
        name
        email
        phone
        contanctName
        contactName
        address
        zip
        card {
          pg
          number
          name
          expMonth
          expYear
          identify
          password
          billingKey
        }
      }
      credit {
        autoCharge
        autoChargeCredit
        autoChargeQuantity
        alertCredit
      }
      kakao {
        agency
        key
        channelId
        categoryCode
        status
        updatedAt
        createdAt
      }
      review {
        status
        campaignId
        updatedAt
        createdAt
      }
      kiosk {
        status
        callable
        payable
        menus {
          title
          titleMl {
            en
            zh
            ja
          }
          index
          ids
          items {
            parentId
            badge
            type
            position
            activeOptions
          }
          parentId
        }
        multilingual
        displayImages {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        exchangeTicket
        columnSize
        discounts {
          condition
          couponId
        }
        menuDescription
        updatedAt
        createdAt
      }
      menuOrder {
        callOptions {
          index
          label
        }
        orderable
        menus {
          title
          titleMl {
            en
            zh
            ja
          }
          index
          ids
          items {
            parentId
            badge
            type
            position
            activeOptions
          }
          parentId
        }
        multilingual
        displayImages {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        columnSize
        fontSize
        notice
        resetTime
        enableCash
        boardType
      }
      kds {
        layout
        alerts {
          urgent
          warning
        }
        disabledProdIds
        orderNotify
      }
      mirror {
        images {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        video {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
      }
      loyalty {
        status
        provider
        sharePolicy
        type
        grade
        conditionType
        conditionSubType
        condition {
          minAmount
          perAmount
          minTime
          rate
          expirationPeriod
          excludeProdIds
          discountPointSavable
        }
        conditionItems {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        conditionTiers {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardType
        rewardSubType
        reward {
          requirePoint
          requireOrderAmount
          minUsePoint
          minRequireRate
          maxUsePoint
        }
        rewardItems {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardRates {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardAmounts {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardCashs {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        incentives {
          fulfill
          fulfillPoint
          fulfillCampaignId
          birth
          birthPoint
          birthCampaignId
          birthItem {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
        }
        notification {
          register
          registerCampainId
          save
          saveCampaignId
          use
          useCampaignId
          expire
          expireCampaignId
        }
      }
      market {
        status
        account {
          ident
          holder
          bank
          bankCode
          number
          businessNumber
        }
        paused
        takeoutDiscount
        creditDiscount
        takeout
        intro
        notice
        bookTable
        bookTableNote
        depositAmount
        delivery
        deliveryFee {
          start
          end
          fee
        }
        waitingTime
        itemIds
        updatedAt
        createdAt
      }
      notification {
        options {
          type
          email
          sms
        }
      }
      groups {
        items {
          id
          name
          type
          storeGroupsId
          locationGroupsId
          key
          conditions {
            key
            object
            field
            operations {
              operation
              type
              value
            }
            value
            operation
            type
          }
          count
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      location {
        id
        name
        type
        provider
        address
        zip
        phone
        logo {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        representative
        businessNumber
        billing {
          key
          businessNumber
          name
          email
          phone
          contanctName
          contactName
          address
          zip
          card {
            pg
            number
            name
            expMonth
            expYear
            identify
            password
            billingKey
          }
        }
        credit {
          autoCharge
          autoChargeCredit
          autoChargeQuantity
          alertCredit
        }
        businessHours {
          sun {
            open
            close
          }
          mon {
            open
            close
          }
          tue {
            open
            close
          }
          wed {
            open
            close
          }
          thu {
            open
            close
          }
          fri {
            open
            close
          }
          sat {
            open
            close
          }
        }
        storeId
        state
        menuPlanId
        payments {
          refund
          barcode
          barcodeVan
          barcodeTerminalId
        }
        policy {
          locationCost
          loyaltyCost
        }
        loyalty {
          status
          provider
          sharePolicy
          type
          grade
          conditionType
          conditionSubType
          condition {
            minAmount
            perAmount
            minTime
            rate
            expirationPeriod
            excludeProdIds
            discountPointSavable
          }
          conditionItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          conditionTiers {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardType
          rewardSubType
          reward {
            requirePoint
            requireOrderAmount
            minUsePoint
            minRequireRate
            maxUsePoint
          }
          rewardItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardRates {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardAmounts {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardCashs {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          incentives {
            fulfill
            fulfillPoint
            fulfillCampaignId
            birth
            birthPoint
            birthCampaignId
            birthItem {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
          }
          notification {
            register
            registerCampainId
            save
            saveCampaignId
            use
            useCampaignId
            expire
            expireCampaignId
          }
        }
        kiosk {
          status
          callable
          payable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          exchangeTicket
          columnSize
          discounts {
            condition
            couponId
          }
          menuDescription
          updatedAt
          createdAt
        }
        receipt {
          salesSlip
          orderList
          barcode
          footnote
          phone
          orderNumberHide
          orderSheetFontSize
          billsBusinessInfo
          giftFootnote
          giftReceipt
        }
        menuOrder {
          callOptions {
            index
            label
          }
          orderable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          columnSize
          fontSize
          notice
          resetTime
          enableCash
          boardType
        }
        kds {
          layout
          alerts {
            urgent
            warning
          }
          disabledProdIds
          orderNotify
        }
        mini {
          defaultItemName
        }
        orderPlanId
        menu {
          board {
            textSize
            columnSize
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            enableFavorite
          }
          list {
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            shortcuts {
              type
              title
              itemName
              itemPrice
              active
            }
          }
          navOrder
        }
        menus {
          parentId
          position
          page
          pageTitle
        }
        favMenus {
          parentId
          position
          page
          pageTitle
        }
        catMenus {
          title
          index
          ids
          parentId
        }
        market {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          paused
          takeoutDiscount
          creditDiscount
          takeout
          intro
          notice
          bookTable
          bookTableNote
          depositAmount
          delivery
          deliveryFee {
            start
            end
            fee
          }
          waitingTime
          itemIds
          updatedAt
          createdAt
        }
        waiting {
          status
          waitingOptions {
            id
            type
            name
            nameMl {
              en
              zh
              ja
            }
            items {
              id
              name
              nameMl {
                en
                zh
                ja
              }
              limitQuantity
            }
            required
            updatedAt
            createdAt
          }
          waitingMinutes
          waitingMinutesHide
          hasForeigner
          foreignerMethod
          notifications {
            created
            ready
            requested
            cancelled
          }
          notices {
            createdNotice
            createdNoticeMl {
              en
              zh
              ja
            }
            requestedNotice
            requestedNoticeMl {
              en
              zh
              ja
            }
          }
          settings {
            autoCancel
            autoCancelMinutes
          }
          updatedAt
          createdAt
        }
        view {
          order {
            excludeColumns
            exportTemplates {
              name
              type
              headers {
                key
                label
              }
            }
          }
          itemReport {
            excludeColumns
          }
          summaryReport {
            excludeColumns
          }
          shippingOrder {
            excludeColumns
          }
          trsItemReport {
            excludeColumns
          }
        }
        subscription {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          updatedAt
          createdAt
        }
        settings {
          mode
          table
          diningOption
          retailOption
          orderAlarm
          orderConfirm
          addressInput
          autoPay
          autoReturnItem
          autoSoldout
          menuGridSize
          menuMode
          tableMode
          orderNotify
          orderNotifySkippable
          disablePhonePrefix
          autoCloseScreen
          orderNumberPrefix {
            pos
            kiosk
            menuOrder
          }
          orderMgmt {
            kiosk
            menuorder
            delivery
            pos
          }
          reportBasis
          orderOrigins
          beeper {
            active
            coverage
            skippable
          }
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
        }
        devices {
          id
          ident
          locationId
          system
          ipAddress
          brand
          model
          name
          type
          deviceType
          display {
            height
            width
          }
          protocol
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          barcodeReader
          appVersion
          sysVersion
          status
          password
          msgToken
          parentId
          childId
          sleepMode
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
          terminalId
          printOptions {
            port
            baudRate
            receipt
            receiptButtonHide
            bills
            refund
            salesEnd
            order
            orderBeep
            orderCount
            orderExcludeIds
            delivery
          }
          updatedAt
          createdAt
        }
        tables {
          id
          width
          height
          name
          axisX
          axisY
          screenHeight
          screenWidth
          initialX
          initialY
          shape
          layout {
            x
            y
            w
            h
          }
          color
          orderId
          disabled
        }
        boardTables {
          title
          index
          items {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
        }
        gridTables {
          id
          name
          orderId
          index
          page
        }
        modifierSoldoutIds
        delivery {
          baemin
          coupang
          yogiyo
          enableGenProd
          updatedAt
          createdAt
        }
        createdAt
        updatedAt
        sales {
          startedAt
          endedAt
          reserves
          actualBalance
          currency
          note
          estimateBalance
          detail {
            totalSales
            totalRefund
            totalTax
            totalDiscount
            supplyAmount
            totalSalesCount
            cash {
              sales
              refund
            }
            card {
              sales
              refund
            }
            credit {
              sales
              refund
            }
            barcode {
              sales
              refund
            }
            extra {
              sales
              refund
            }
            refund {
              sales
              refund
            }
            products {
              id
              name
              quantity
              amount
            }
            origins {
              pos
              kiosk
              menuorder
              delivery
              market
            }
          }
          reservesHistories {
            type
            amount
            note
            createdEmployeeId
            createdAt
          }
        }
      }
      locations {
        items {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createEmployee = /* GraphQL */ `
  mutation CreateEmployee($input: CreateEmployeeInput!) {
    createEmployee(input: $input) {
      id
      role
      lastName
      firstName
      name
      nickname
      phone
      email
      employeeStoreId
      employeeEnterpriseId
      organizations {
        items {
          id
          role
          employeeId
          enterpriseId
          storeId
          pin
          status
          locationId
          employee {
            id
            role
            lastName
            firstName
            name
            nickname
            phone
            email
            employeeStoreId
            employeeEnterpriseId
            organizations {
              items {
                id
                role
                employeeId
                enterpriseId
                storeId
                pin
                status
                locationId
                permissions
                updatedAt
                createdAt
              }
              nextToken
            }
            survey
            updatedAt
            createdAt
            store {
              id
              enterpriseId
              info {
                name
                phone
                email
                representative
                businessType
                businessNumber
                industryType
                sendNumber
                currency
                address
                zip
                csPhone
              }
              policy {
                multiLocation
              }
              currency
              payments {
                card
                cardJoined
                cash
                credit
                refund
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              taxes {
                id
                type
                name
                enabled
                rate
                method
                targetType
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              item {
                actualPrice
                skuMgmt
                barcodeMgmt
                stockTrack
                variablePrice
                locationCreatable
              }
              inventory {
                expiryDateMgmt
                expiryDateAlertDays
              }
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              kakao {
                agency
                key
                channelId
                categoryCode
                status
                updatedAt
                createdAt
              }
              review {
                status
                campaignId
                updatedAt
                createdAt
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              groups {
                nextToken
              }
              createdAt
              updatedAt
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              locations {
                nextToken
              }
            }
          }
          permissions
          updatedAt
          createdAt
          store {
            id
            enterpriseId
            info {
              name
              phone
              email
              representative
              businessType
              businessNumber
              industryType
              sendNumber
              currency
              address
              zip
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              csPhone
            }
            policy {
              multiLocation
              provider {
                key
                ident
                customer
                coupon
                inventory
                locationId
              }
            }
            currency
            payments {
              card
              cardJoined
              cash
              credit
              refund
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            plans {
              menus {
                id
                name
                mode
                size
                updatedAt
                createdAt
              }
              orders {
                id
                name
                updatedAt
                createdAt
              }
            }
            taxes {
              id
              type
              name
              enabled
              rate
              method
              targetType
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            item {
              attributes {
                name
                type
                value
              }
              actualPrice
              skuMgmt
              barcodeMgmt
              stockTrack
              variablePrice
              locationCreatable
            }
            inventory {
              attributes {
                name
                type
                value
              }
              expiryDateMgmt
              expiryDateAlertDays
            }
            customer {
              attributes {
                name
                type
                value
              }
            }
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            kakao {
              agency
              key
              channelId
              categoryCode
              status
              updatedAt
              createdAt
            }
            review {
              status
              campaignId
              updatedAt
              createdAt
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mirror {
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              video {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            notification {
              options {
                type
                email
                sms
              }
            }
            groups {
              items {
                id
                name
                type
                storeGroupsId
                locationGroupsId
                key
                count
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            locations {
              items {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      survey
      updatedAt
      createdAt
      store {
        id
        enterpriseId
        info {
          name
          phone
          email
          representative
          businessType
          businessNumber
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          industryType
          sendNumber
          currency
          address
          zip
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          csPhone
        }
        policy {
          multiLocation
          provider {
            key
            ident
            customer
            coupon
            inventory
            locationId
          }
        }
        currency
        payments {
          card
          cardJoined
          cash
          credit
          refund
        }
        devices {
          id
          ident
          locationId
          system
          ipAddress
          brand
          model
          name
          type
          deviceType
          display {
            height
            width
          }
          protocol
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          barcodeReader
          appVersion
          sysVersion
          status
          password
          msgToken
          parentId
          childId
          sleepMode
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
          terminalId
          printOptions {
            port
            baudRate
            receipt
            receiptButtonHide
            bills
            refund
            salesEnd
            order
            orderBeep
            orderCount
            orderExcludeIds
            delivery
          }
          updatedAt
          createdAt
        }
        plans {
          menus {
            id
            name
            mode
            size
            menus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            pages {
              index
              parentId
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              title
              titleMl {
                en
                zh
                ja
              }
            }
            updatedAt
            createdAt
          }
          orders {
            id
            name
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            updatedAt
            createdAt
          }
        }
        taxes {
          id
          type
          name
          enabled
          rate
          method
          targetType
        }
        receipt {
          salesSlip
          orderList
          barcode
          footnote
          phone
          orderNumberHide
          orderSheetFontSize
          billsBusinessInfo
          giftFootnote
          giftReceipt
        }
        subscription {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          updatedAt
          createdAt
        }
        view {
          order {
            excludeColumns
            exportTemplates {
              name
              type
              headers {
                key
                label
              }
            }
          }
          itemReport {
            excludeColumns
          }
          summaryReport {
            excludeColumns
          }
          shippingOrder {
            excludeColumns
          }
          trsItemReport {
            excludeColumns
          }
        }
        settings {
          mode
          table
          diningOption
          retailOption
          orderAlarm
          orderConfirm
          addressInput
          autoPay
          autoReturnItem
          autoSoldout
          menuGridSize
          menuMode
          tableMode
          orderNotify
          orderNotifySkippable
          disablePhonePrefix
          autoCloseScreen
          orderNumberPrefix {
            pos
            kiosk
            menuOrder
          }
          orderMgmt {
            kiosk
            menuorder
            delivery
            pos
          }
          reportBasis
          orderOrigins
          beeper {
            active
            coverage
            skippable
          }
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
        }
        item {
          attributes {
            name
            type
            value
          }
          actualPrice
          skuMgmt
          barcodeMgmt
          stockTrack
          variablePrice
          locationCreatable
        }
        inventory {
          attributes {
            name
            type
            value
          }
          expiryDateMgmt
          expiryDateAlertDays
        }
        customer {
          attributes {
            name
            type
            value
          }
        }
        billing {
          key
          businessNumber
          name
          email
          phone
          contanctName
          contactName
          address
          zip
          card {
            pg
            number
            name
            expMonth
            expYear
            identify
            password
            billingKey
          }
        }
        credit {
          autoCharge
          autoChargeCredit
          autoChargeQuantity
          alertCredit
        }
        kakao {
          agency
          key
          channelId
          categoryCode
          status
          updatedAt
          createdAt
        }
        review {
          status
          campaignId
          updatedAt
          createdAt
        }
        kiosk {
          status
          callable
          payable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          exchangeTicket
          columnSize
          discounts {
            condition
            couponId
          }
          menuDescription
          updatedAt
          createdAt
        }
        menuOrder {
          callOptions {
            index
            label
          }
          orderable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          columnSize
          fontSize
          notice
          resetTime
          enableCash
          boardType
        }
        kds {
          layout
          alerts {
            urgent
            warning
          }
          disabledProdIds
          orderNotify
        }
        mirror {
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          video {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
        }
        loyalty {
          status
          provider
          sharePolicy
          type
          grade
          conditionType
          conditionSubType
          condition {
            minAmount
            perAmount
            minTime
            rate
            expirationPeriod
            excludeProdIds
            discountPointSavable
          }
          conditionItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          conditionTiers {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardType
          rewardSubType
          reward {
            requirePoint
            requireOrderAmount
            minUsePoint
            minRequireRate
            maxUsePoint
          }
          rewardItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardRates {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardAmounts {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardCashs {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          incentives {
            fulfill
            fulfillPoint
            fulfillCampaignId
            birth
            birthPoint
            birthCampaignId
            birthItem {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
          }
          notification {
            register
            registerCampainId
            save
            saveCampaignId
            use
            useCampaignId
            expire
            expireCampaignId
          }
        }
        market {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          paused
          takeoutDiscount
          creditDiscount
          takeout
          intro
          notice
          bookTable
          bookTableNote
          depositAmount
          delivery
          deliveryFee {
            start
            end
            fee
          }
          waitingTime
          itemIds
          updatedAt
          createdAt
        }
        notification {
          options {
            type
            email
            sms
          }
        }
        groups {
          items {
            id
            name
            type
            storeGroupsId
            locationGroupsId
            key
            conditions {
              key
              object
              field
              operations {
                operation
                type
                value
              }
              value
              operation
              type
            }
            count
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        location {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        locations {
          items {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateEmployee = /* GraphQL */ `
  mutation UpdateEmployee($input: UpdateEmployeeInput!) {
    updateEmployee(input: $input) {
      id
      role
      lastName
      firstName
      name
      nickname
      phone
      email
      employeeStoreId
      employeeEnterpriseId
      organizations {
        items {
          id
          role
          employeeId
          enterpriseId
          storeId
          pin
          status
          locationId
          employee {
            id
            role
            lastName
            firstName
            name
            nickname
            phone
            email
            employeeStoreId
            employeeEnterpriseId
            organizations {
              items {
                id
                role
                employeeId
                enterpriseId
                storeId
                pin
                status
                locationId
                permissions
                updatedAt
                createdAt
              }
              nextToken
            }
            survey
            updatedAt
            createdAt
            store {
              id
              enterpriseId
              info {
                name
                phone
                email
                representative
                businessType
                businessNumber
                industryType
                sendNumber
                currency
                address
                zip
                csPhone
              }
              policy {
                multiLocation
              }
              currency
              payments {
                card
                cardJoined
                cash
                credit
                refund
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              taxes {
                id
                type
                name
                enabled
                rate
                method
                targetType
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              item {
                actualPrice
                skuMgmt
                barcodeMgmt
                stockTrack
                variablePrice
                locationCreatable
              }
              inventory {
                expiryDateMgmt
                expiryDateAlertDays
              }
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              kakao {
                agency
                key
                channelId
                categoryCode
                status
                updatedAt
                createdAt
              }
              review {
                status
                campaignId
                updatedAt
                createdAt
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              groups {
                nextToken
              }
              createdAt
              updatedAt
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              locations {
                nextToken
              }
            }
          }
          permissions
          updatedAt
          createdAt
          store {
            id
            enterpriseId
            info {
              name
              phone
              email
              representative
              businessType
              businessNumber
              industryType
              sendNumber
              currency
              address
              zip
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              csPhone
            }
            policy {
              multiLocation
              provider {
                key
                ident
                customer
                coupon
                inventory
                locationId
              }
            }
            currency
            payments {
              card
              cardJoined
              cash
              credit
              refund
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            plans {
              menus {
                id
                name
                mode
                size
                updatedAt
                createdAt
              }
              orders {
                id
                name
                updatedAt
                createdAt
              }
            }
            taxes {
              id
              type
              name
              enabled
              rate
              method
              targetType
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            item {
              attributes {
                name
                type
                value
              }
              actualPrice
              skuMgmt
              barcodeMgmt
              stockTrack
              variablePrice
              locationCreatable
            }
            inventory {
              attributes {
                name
                type
                value
              }
              expiryDateMgmt
              expiryDateAlertDays
            }
            customer {
              attributes {
                name
                type
                value
              }
            }
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            kakao {
              agency
              key
              channelId
              categoryCode
              status
              updatedAt
              createdAt
            }
            review {
              status
              campaignId
              updatedAt
              createdAt
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mirror {
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              video {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            notification {
              options {
                type
                email
                sms
              }
            }
            groups {
              items {
                id
                name
                type
                storeGroupsId
                locationGroupsId
                key
                count
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            locations {
              items {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      survey
      updatedAt
      createdAt
      store {
        id
        enterpriseId
        info {
          name
          phone
          email
          representative
          businessType
          businessNumber
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          industryType
          sendNumber
          currency
          address
          zip
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          csPhone
        }
        policy {
          multiLocation
          provider {
            key
            ident
            customer
            coupon
            inventory
            locationId
          }
        }
        currency
        payments {
          card
          cardJoined
          cash
          credit
          refund
        }
        devices {
          id
          ident
          locationId
          system
          ipAddress
          brand
          model
          name
          type
          deviceType
          display {
            height
            width
          }
          protocol
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          barcodeReader
          appVersion
          sysVersion
          status
          password
          msgToken
          parentId
          childId
          sleepMode
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
          terminalId
          printOptions {
            port
            baudRate
            receipt
            receiptButtonHide
            bills
            refund
            salesEnd
            order
            orderBeep
            orderCount
            orderExcludeIds
            delivery
          }
          updatedAt
          createdAt
        }
        plans {
          menus {
            id
            name
            mode
            size
            menus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            pages {
              index
              parentId
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              title
              titleMl {
                en
                zh
                ja
              }
            }
            updatedAt
            createdAt
          }
          orders {
            id
            name
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            updatedAt
            createdAt
          }
        }
        taxes {
          id
          type
          name
          enabled
          rate
          method
          targetType
        }
        receipt {
          salesSlip
          orderList
          barcode
          footnote
          phone
          orderNumberHide
          orderSheetFontSize
          billsBusinessInfo
          giftFootnote
          giftReceipt
        }
        subscription {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          updatedAt
          createdAt
        }
        view {
          order {
            excludeColumns
            exportTemplates {
              name
              type
              headers {
                key
                label
              }
            }
          }
          itemReport {
            excludeColumns
          }
          summaryReport {
            excludeColumns
          }
          shippingOrder {
            excludeColumns
          }
          trsItemReport {
            excludeColumns
          }
        }
        settings {
          mode
          table
          diningOption
          retailOption
          orderAlarm
          orderConfirm
          addressInput
          autoPay
          autoReturnItem
          autoSoldout
          menuGridSize
          menuMode
          tableMode
          orderNotify
          orderNotifySkippable
          disablePhonePrefix
          autoCloseScreen
          orderNumberPrefix {
            pos
            kiosk
            menuOrder
          }
          orderMgmt {
            kiosk
            menuorder
            delivery
            pos
          }
          reportBasis
          orderOrigins
          beeper {
            active
            coverage
            skippable
          }
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
        }
        item {
          attributes {
            name
            type
            value
          }
          actualPrice
          skuMgmt
          barcodeMgmt
          stockTrack
          variablePrice
          locationCreatable
        }
        inventory {
          attributes {
            name
            type
            value
          }
          expiryDateMgmt
          expiryDateAlertDays
        }
        customer {
          attributes {
            name
            type
            value
          }
        }
        billing {
          key
          businessNumber
          name
          email
          phone
          contanctName
          contactName
          address
          zip
          card {
            pg
            number
            name
            expMonth
            expYear
            identify
            password
            billingKey
          }
        }
        credit {
          autoCharge
          autoChargeCredit
          autoChargeQuantity
          alertCredit
        }
        kakao {
          agency
          key
          channelId
          categoryCode
          status
          updatedAt
          createdAt
        }
        review {
          status
          campaignId
          updatedAt
          createdAt
        }
        kiosk {
          status
          callable
          payable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          exchangeTicket
          columnSize
          discounts {
            condition
            couponId
          }
          menuDescription
          updatedAt
          createdAt
        }
        menuOrder {
          callOptions {
            index
            label
          }
          orderable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          columnSize
          fontSize
          notice
          resetTime
          enableCash
          boardType
        }
        kds {
          layout
          alerts {
            urgent
            warning
          }
          disabledProdIds
          orderNotify
        }
        mirror {
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          video {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
        }
        loyalty {
          status
          provider
          sharePolicy
          type
          grade
          conditionType
          conditionSubType
          condition {
            minAmount
            perAmount
            minTime
            rate
            expirationPeriod
            excludeProdIds
            discountPointSavable
          }
          conditionItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          conditionTiers {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardType
          rewardSubType
          reward {
            requirePoint
            requireOrderAmount
            minUsePoint
            minRequireRate
            maxUsePoint
          }
          rewardItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardRates {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardAmounts {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardCashs {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          incentives {
            fulfill
            fulfillPoint
            fulfillCampaignId
            birth
            birthPoint
            birthCampaignId
            birthItem {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
          }
          notification {
            register
            registerCampainId
            save
            saveCampaignId
            use
            useCampaignId
            expire
            expireCampaignId
          }
        }
        market {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          paused
          takeoutDiscount
          creditDiscount
          takeout
          intro
          notice
          bookTable
          bookTableNote
          depositAmount
          delivery
          deliveryFee {
            start
            end
            fee
          }
          waitingTime
          itemIds
          updatedAt
          createdAt
        }
        notification {
          options {
            type
            email
            sms
          }
        }
        groups {
          items {
            id
            name
            type
            storeGroupsId
            locationGroupsId
            key
            conditions {
              key
              object
              field
              operations {
                operation
                type
                value
              }
              value
              operation
              type
            }
            count
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        location {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        locations {
          items {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteEmployee = /* GraphQL */ `
  mutation DeleteEmployee($input: DeleteEmployeeInput!) {
    deleteEmployee(input: $input) {
      id
      role
      lastName
      firstName
      name
      nickname
      phone
      email
      employeeStoreId
      employeeEnterpriseId
      organizations {
        items {
          id
          role
          employeeId
          enterpriseId
          storeId
          pin
          status
          locationId
          employee {
            id
            role
            lastName
            firstName
            name
            nickname
            phone
            email
            employeeStoreId
            employeeEnterpriseId
            organizations {
              items {
                id
                role
                employeeId
                enterpriseId
                storeId
                pin
                status
                locationId
                permissions
                updatedAt
                createdAt
              }
              nextToken
            }
            survey
            updatedAt
            createdAt
            store {
              id
              enterpriseId
              info {
                name
                phone
                email
                representative
                businessType
                businessNumber
                industryType
                sendNumber
                currency
                address
                zip
                csPhone
              }
              policy {
                multiLocation
              }
              currency
              payments {
                card
                cardJoined
                cash
                credit
                refund
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              taxes {
                id
                type
                name
                enabled
                rate
                method
                targetType
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              item {
                actualPrice
                skuMgmt
                barcodeMgmt
                stockTrack
                variablePrice
                locationCreatable
              }
              inventory {
                expiryDateMgmt
                expiryDateAlertDays
              }
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              kakao {
                agency
                key
                channelId
                categoryCode
                status
                updatedAt
                createdAt
              }
              review {
                status
                campaignId
                updatedAt
                createdAt
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              groups {
                nextToken
              }
              createdAt
              updatedAt
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              locations {
                nextToken
              }
            }
          }
          permissions
          updatedAt
          createdAt
          store {
            id
            enterpriseId
            info {
              name
              phone
              email
              representative
              businessType
              businessNumber
              industryType
              sendNumber
              currency
              address
              zip
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              csPhone
            }
            policy {
              multiLocation
              provider {
                key
                ident
                customer
                coupon
                inventory
                locationId
              }
            }
            currency
            payments {
              card
              cardJoined
              cash
              credit
              refund
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            plans {
              menus {
                id
                name
                mode
                size
                updatedAt
                createdAt
              }
              orders {
                id
                name
                updatedAt
                createdAt
              }
            }
            taxes {
              id
              type
              name
              enabled
              rate
              method
              targetType
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            item {
              attributes {
                name
                type
                value
              }
              actualPrice
              skuMgmt
              barcodeMgmt
              stockTrack
              variablePrice
              locationCreatable
            }
            inventory {
              attributes {
                name
                type
                value
              }
              expiryDateMgmt
              expiryDateAlertDays
            }
            customer {
              attributes {
                name
                type
                value
              }
            }
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            kakao {
              agency
              key
              channelId
              categoryCode
              status
              updatedAt
              createdAt
            }
            review {
              status
              campaignId
              updatedAt
              createdAt
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mirror {
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              video {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            notification {
              options {
                type
                email
                sms
              }
            }
            groups {
              items {
                id
                name
                type
                storeGroupsId
                locationGroupsId
                key
                count
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            locations {
              items {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
        nextToken
      }
      survey
      updatedAt
      createdAt
      store {
        id
        enterpriseId
        info {
          name
          phone
          email
          representative
          businessType
          businessNumber
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          industryType
          sendNumber
          currency
          address
          zip
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          csPhone
        }
        policy {
          multiLocation
          provider {
            key
            ident
            customer
            coupon
            inventory
            locationId
          }
        }
        currency
        payments {
          card
          cardJoined
          cash
          credit
          refund
        }
        devices {
          id
          ident
          locationId
          system
          ipAddress
          brand
          model
          name
          type
          deviceType
          display {
            height
            width
          }
          protocol
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          barcodeReader
          appVersion
          sysVersion
          status
          password
          msgToken
          parentId
          childId
          sleepMode
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
          terminalId
          printOptions {
            port
            baudRate
            receipt
            receiptButtonHide
            bills
            refund
            salesEnd
            order
            orderBeep
            orderCount
            orderExcludeIds
            delivery
          }
          updatedAt
          createdAt
        }
        plans {
          menus {
            id
            name
            mode
            size
            menus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            pages {
              index
              parentId
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              title
              titleMl {
                en
                zh
                ja
              }
            }
            updatedAt
            createdAt
          }
          orders {
            id
            name
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            updatedAt
            createdAt
          }
        }
        taxes {
          id
          type
          name
          enabled
          rate
          method
          targetType
        }
        receipt {
          salesSlip
          orderList
          barcode
          footnote
          phone
          orderNumberHide
          orderSheetFontSize
          billsBusinessInfo
          giftFootnote
          giftReceipt
        }
        subscription {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          updatedAt
          createdAt
        }
        view {
          order {
            excludeColumns
            exportTemplates {
              name
              type
              headers {
                key
                label
              }
            }
          }
          itemReport {
            excludeColumns
          }
          summaryReport {
            excludeColumns
          }
          shippingOrder {
            excludeColumns
          }
          trsItemReport {
            excludeColumns
          }
        }
        settings {
          mode
          table
          diningOption
          retailOption
          orderAlarm
          orderConfirm
          addressInput
          autoPay
          autoReturnItem
          autoSoldout
          menuGridSize
          menuMode
          tableMode
          orderNotify
          orderNotifySkippable
          disablePhonePrefix
          autoCloseScreen
          orderNumberPrefix {
            pos
            kiosk
            menuOrder
          }
          orderMgmt {
            kiosk
            menuorder
            delivery
            pos
          }
          reportBasis
          orderOrigins
          beeper {
            active
            coverage
            skippable
          }
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
        }
        item {
          attributes {
            name
            type
            value
          }
          actualPrice
          skuMgmt
          barcodeMgmt
          stockTrack
          variablePrice
          locationCreatable
        }
        inventory {
          attributes {
            name
            type
            value
          }
          expiryDateMgmt
          expiryDateAlertDays
        }
        customer {
          attributes {
            name
            type
            value
          }
        }
        billing {
          key
          businessNumber
          name
          email
          phone
          contanctName
          contactName
          address
          zip
          card {
            pg
            number
            name
            expMonth
            expYear
            identify
            password
            billingKey
          }
        }
        credit {
          autoCharge
          autoChargeCredit
          autoChargeQuantity
          alertCredit
        }
        kakao {
          agency
          key
          channelId
          categoryCode
          status
          updatedAt
          createdAt
        }
        review {
          status
          campaignId
          updatedAt
          createdAt
        }
        kiosk {
          status
          callable
          payable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          exchangeTicket
          columnSize
          discounts {
            condition
            couponId
          }
          menuDescription
          updatedAt
          createdAt
        }
        menuOrder {
          callOptions {
            index
            label
          }
          orderable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          columnSize
          fontSize
          notice
          resetTime
          enableCash
          boardType
        }
        kds {
          layout
          alerts {
            urgent
            warning
          }
          disabledProdIds
          orderNotify
        }
        mirror {
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          video {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
        }
        loyalty {
          status
          provider
          sharePolicy
          type
          grade
          conditionType
          conditionSubType
          condition {
            minAmount
            perAmount
            minTime
            rate
            expirationPeriod
            excludeProdIds
            discountPointSavable
          }
          conditionItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          conditionTiers {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardType
          rewardSubType
          reward {
            requirePoint
            requireOrderAmount
            minUsePoint
            minRequireRate
            maxUsePoint
          }
          rewardItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardRates {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardAmounts {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardCashs {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          incentives {
            fulfill
            fulfillPoint
            fulfillCampaignId
            birth
            birthPoint
            birthCampaignId
            birthItem {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
          }
          notification {
            register
            registerCampainId
            save
            saveCampaignId
            use
            useCampaignId
            expire
            expireCampaignId
          }
        }
        market {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          paused
          takeoutDiscount
          creditDiscount
          takeout
          intro
          notice
          bookTable
          bookTableNote
          depositAmount
          delivery
          deliveryFee {
            start
            end
            fee
          }
          waitingTime
          itemIds
          updatedAt
          createdAt
        }
        notification {
          options {
            type
            email
            sms
          }
        }
        groups {
          items {
            id
            name
            type
            storeGroupsId
            locationGroupsId
            key
            conditions {
              key
              object
              field
              operations {
                operation
                type
                value
              }
              value
              operation
              type
            }
            count
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        location {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        locations {
          items {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      id
      role
      employeeId
      enterpriseId
      storeId
      pin
      status
      locationId
      employee {
        id
        role
        lastName
        firstName
        name
        nickname
        phone
        email
        employeeStoreId
        employeeEnterpriseId
        organizations {
          items {
            id
            role
            employeeId
            enterpriseId
            storeId
            pin
            status
            locationId
            employee {
              id
              role
              lastName
              firstName
              name
              nickname
              phone
              email
              employeeStoreId
              employeeEnterpriseId
              organizations {
                nextToken
              }
              survey
              updatedAt
              createdAt
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
            }
            permissions
            updatedAt
            createdAt
            store {
              id
              enterpriseId
              info {
                name
                phone
                email
                representative
                businessType
                businessNumber
                industryType
                sendNumber
                currency
                address
                zip
                csPhone
              }
              policy {
                multiLocation
              }
              currency
              payments {
                card
                cardJoined
                cash
                credit
                refund
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              taxes {
                id
                type
                name
                enabled
                rate
                method
                targetType
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              item {
                actualPrice
                skuMgmt
                barcodeMgmt
                stockTrack
                variablePrice
                locationCreatable
              }
              inventory {
                expiryDateMgmt
                expiryDateAlertDays
              }
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              kakao {
                agency
                key
                channelId
                categoryCode
                status
                updatedAt
                createdAt
              }
              review {
                status
                campaignId
                updatedAt
                createdAt
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              groups {
                nextToken
              }
              createdAt
              updatedAt
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              locations {
                nextToken
              }
            }
          }
          nextToken
        }
        survey
        updatedAt
        createdAt
        store {
          id
          enterpriseId
          info {
            name
            phone
            email
            representative
            businessType
            businessNumber
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            industryType
            sendNumber
            currency
            address
            zip
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            csPhone
          }
          policy {
            multiLocation
            provider {
              key
              ident
              customer
              coupon
              inventory
              locationId
            }
          }
          currency
          payments {
            card
            cardJoined
            cash
            credit
            refund
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          plans {
            menus {
              id
              name
              mode
              size
              menus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              pages {
                index
                parentId
                title
              }
              updatedAt
              createdAt
            }
            orders {
              id
              name
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              updatedAt
              createdAt
            }
          }
          taxes {
            id
            type
            name
            enabled
            rate
            method
            targetType
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          item {
            attributes {
              name
              type
              value
            }
            actualPrice
            skuMgmt
            barcodeMgmt
            stockTrack
            variablePrice
            locationCreatable
          }
          inventory {
            attributes {
              name
              type
              value
            }
            expiryDateMgmt
            expiryDateAlertDays
          }
          customer {
            attributes {
              name
              type
              value
            }
          }
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          kakao {
            agency
            key
            channelId
            categoryCode
            status
            updatedAt
            createdAt
          }
          review {
            status
            campaignId
            updatedAt
            createdAt
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mirror {
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            video {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          notification {
            options {
              type
              email
              sms
            }
          }
          groups {
            items {
              id
              name
              type
              storeGroupsId
              locationGroupsId
              key
              conditions {
                key
                object
                field
                value
                operation
                type
              }
              count
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          location {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          locations {
            items {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            nextToken
          }
        }
      }
      permissions
      updatedAt
      createdAt
      store {
        id
        enterpriseId
        info {
          name
          phone
          email
          representative
          businessType
          businessNumber
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          industryType
          sendNumber
          currency
          address
          zip
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          csPhone
        }
        policy {
          multiLocation
          provider {
            key
            ident
            customer
            coupon
            inventory
            locationId
          }
        }
        currency
        payments {
          card
          cardJoined
          cash
          credit
          refund
        }
        devices {
          id
          ident
          locationId
          system
          ipAddress
          brand
          model
          name
          type
          deviceType
          display {
            height
            width
          }
          protocol
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          barcodeReader
          appVersion
          sysVersion
          status
          password
          msgToken
          parentId
          childId
          sleepMode
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
          terminalId
          printOptions {
            port
            baudRate
            receipt
            receiptButtonHide
            bills
            refund
            salesEnd
            order
            orderBeep
            orderCount
            orderExcludeIds
            delivery
          }
          updatedAt
          createdAt
        }
        plans {
          menus {
            id
            name
            mode
            size
            menus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            pages {
              index
              parentId
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              title
              titleMl {
                en
                zh
                ja
              }
            }
            updatedAt
            createdAt
          }
          orders {
            id
            name
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            updatedAt
            createdAt
          }
        }
        taxes {
          id
          type
          name
          enabled
          rate
          method
          targetType
        }
        receipt {
          salesSlip
          orderList
          barcode
          footnote
          phone
          orderNumberHide
          orderSheetFontSize
          billsBusinessInfo
          giftFootnote
          giftReceipt
        }
        subscription {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          updatedAt
          createdAt
        }
        view {
          order {
            excludeColumns
            exportTemplates {
              name
              type
              headers {
                key
                label
              }
            }
          }
          itemReport {
            excludeColumns
          }
          summaryReport {
            excludeColumns
          }
          shippingOrder {
            excludeColumns
          }
          trsItemReport {
            excludeColumns
          }
        }
        settings {
          mode
          table
          diningOption
          retailOption
          orderAlarm
          orderConfirm
          addressInput
          autoPay
          autoReturnItem
          autoSoldout
          menuGridSize
          menuMode
          tableMode
          orderNotify
          orderNotifySkippable
          disablePhonePrefix
          autoCloseScreen
          orderNumberPrefix {
            pos
            kiosk
            menuOrder
          }
          orderMgmt {
            kiosk
            menuorder
            delivery
            pos
          }
          reportBasis
          orderOrigins
          beeper {
            active
            coverage
            skippable
          }
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
        }
        item {
          attributes {
            name
            type
            value
          }
          actualPrice
          skuMgmt
          barcodeMgmt
          stockTrack
          variablePrice
          locationCreatable
        }
        inventory {
          attributes {
            name
            type
            value
          }
          expiryDateMgmt
          expiryDateAlertDays
        }
        customer {
          attributes {
            name
            type
            value
          }
        }
        billing {
          key
          businessNumber
          name
          email
          phone
          contanctName
          contactName
          address
          zip
          card {
            pg
            number
            name
            expMonth
            expYear
            identify
            password
            billingKey
          }
        }
        credit {
          autoCharge
          autoChargeCredit
          autoChargeQuantity
          alertCredit
        }
        kakao {
          agency
          key
          channelId
          categoryCode
          status
          updatedAt
          createdAt
        }
        review {
          status
          campaignId
          updatedAt
          createdAt
        }
        kiosk {
          status
          callable
          payable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          exchangeTicket
          columnSize
          discounts {
            condition
            couponId
          }
          menuDescription
          updatedAt
          createdAt
        }
        menuOrder {
          callOptions {
            index
            label
          }
          orderable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          columnSize
          fontSize
          notice
          resetTime
          enableCash
          boardType
        }
        kds {
          layout
          alerts {
            urgent
            warning
          }
          disabledProdIds
          orderNotify
        }
        mirror {
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          video {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
        }
        loyalty {
          status
          provider
          sharePolicy
          type
          grade
          conditionType
          conditionSubType
          condition {
            minAmount
            perAmount
            minTime
            rate
            expirationPeriod
            excludeProdIds
            discountPointSavable
          }
          conditionItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          conditionTiers {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardType
          rewardSubType
          reward {
            requirePoint
            requireOrderAmount
            minUsePoint
            minRequireRate
            maxUsePoint
          }
          rewardItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardRates {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardAmounts {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardCashs {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          incentives {
            fulfill
            fulfillPoint
            fulfillCampaignId
            birth
            birthPoint
            birthCampaignId
            birthItem {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
          }
          notification {
            register
            registerCampainId
            save
            saveCampaignId
            use
            useCampaignId
            expire
            expireCampaignId
          }
        }
        market {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          paused
          takeoutDiscount
          creditDiscount
          takeout
          intro
          notice
          bookTable
          bookTableNote
          depositAmount
          delivery
          deliveryFee {
            start
            end
            fee
          }
          waitingTime
          itemIds
          updatedAt
          createdAt
        }
        notification {
          options {
            type
            email
            sms
          }
        }
        groups {
          items {
            id
            name
            type
            storeGroupsId
            locationGroupsId
            key
            conditions {
              key
              object
              field
              operations {
                operation
                type
                value
              }
              value
              operation
              type
            }
            count
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        location {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        locations {
          items {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      id
      role
      employeeId
      enterpriseId
      storeId
      pin
      status
      locationId
      employee {
        id
        role
        lastName
        firstName
        name
        nickname
        phone
        email
        employeeStoreId
        employeeEnterpriseId
        organizations {
          items {
            id
            role
            employeeId
            enterpriseId
            storeId
            pin
            status
            locationId
            employee {
              id
              role
              lastName
              firstName
              name
              nickname
              phone
              email
              employeeStoreId
              employeeEnterpriseId
              organizations {
                nextToken
              }
              survey
              updatedAt
              createdAt
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
            }
            permissions
            updatedAt
            createdAt
            store {
              id
              enterpriseId
              info {
                name
                phone
                email
                representative
                businessType
                businessNumber
                industryType
                sendNumber
                currency
                address
                zip
                csPhone
              }
              policy {
                multiLocation
              }
              currency
              payments {
                card
                cardJoined
                cash
                credit
                refund
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              taxes {
                id
                type
                name
                enabled
                rate
                method
                targetType
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              item {
                actualPrice
                skuMgmt
                barcodeMgmt
                stockTrack
                variablePrice
                locationCreatable
              }
              inventory {
                expiryDateMgmt
                expiryDateAlertDays
              }
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              kakao {
                agency
                key
                channelId
                categoryCode
                status
                updatedAt
                createdAt
              }
              review {
                status
                campaignId
                updatedAt
                createdAt
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              groups {
                nextToken
              }
              createdAt
              updatedAt
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              locations {
                nextToken
              }
            }
          }
          nextToken
        }
        survey
        updatedAt
        createdAt
        store {
          id
          enterpriseId
          info {
            name
            phone
            email
            representative
            businessType
            businessNumber
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            industryType
            sendNumber
            currency
            address
            zip
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            csPhone
          }
          policy {
            multiLocation
            provider {
              key
              ident
              customer
              coupon
              inventory
              locationId
            }
          }
          currency
          payments {
            card
            cardJoined
            cash
            credit
            refund
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          plans {
            menus {
              id
              name
              mode
              size
              menus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              pages {
                index
                parentId
                title
              }
              updatedAt
              createdAt
            }
            orders {
              id
              name
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              updatedAt
              createdAt
            }
          }
          taxes {
            id
            type
            name
            enabled
            rate
            method
            targetType
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          item {
            attributes {
              name
              type
              value
            }
            actualPrice
            skuMgmt
            barcodeMgmt
            stockTrack
            variablePrice
            locationCreatable
          }
          inventory {
            attributes {
              name
              type
              value
            }
            expiryDateMgmt
            expiryDateAlertDays
          }
          customer {
            attributes {
              name
              type
              value
            }
          }
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          kakao {
            agency
            key
            channelId
            categoryCode
            status
            updatedAt
            createdAt
          }
          review {
            status
            campaignId
            updatedAt
            createdAt
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mirror {
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            video {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          notification {
            options {
              type
              email
              sms
            }
          }
          groups {
            items {
              id
              name
              type
              storeGroupsId
              locationGroupsId
              key
              conditions {
                key
                object
                field
                value
                operation
                type
              }
              count
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          location {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          locations {
            items {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            nextToken
          }
        }
      }
      permissions
      updatedAt
      createdAt
      store {
        id
        enterpriseId
        info {
          name
          phone
          email
          representative
          businessType
          businessNumber
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          industryType
          sendNumber
          currency
          address
          zip
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          csPhone
        }
        policy {
          multiLocation
          provider {
            key
            ident
            customer
            coupon
            inventory
            locationId
          }
        }
        currency
        payments {
          card
          cardJoined
          cash
          credit
          refund
        }
        devices {
          id
          ident
          locationId
          system
          ipAddress
          brand
          model
          name
          type
          deviceType
          display {
            height
            width
          }
          protocol
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          barcodeReader
          appVersion
          sysVersion
          status
          password
          msgToken
          parentId
          childId
          sleepMode
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
          terminalId
          printOptions {
            port
            baudRate
            receipt
            receiptButtonHide
            bills
            refund
            salesEnd
            order
            orderBeep
            orderCount
            orderExcludeIds
            delivery
          }
          updatedAt
          createdAt
        }
        plans {
          menus {
            id
            name
            mode
            size
            menus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            pages {
              index
              parentId
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              title
              titleMl {
                en
                zh
                ja
              }
            }
            updatedAt
            createdAt
          }
          orders {
            id
            name
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            updatedAt
            createdAt
          }
        }
        taxes {
          id
          type
          name
          enabled
          rate
          method
          targetType
        }
        receipt {
          salesSlip
          orderList
          barcode
          footnote
          phone
          orderNumberHide
          orderSheetFontSize
          billsBusinessInfo
          giftFootnote
          giftReceipt
        }
        subscription {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          updatedAt
          createdAt
        }
        view {
          order {
            excludeColumns
            exportTemplates {
              name
              type
              headers {
                key
                label
              }
            }
          }
          itemReport {
            excludeColumns
          }
          summaryReport {
            excludeColumns
          }
          shippingOrder {
            excludeColumns
          }
          trsItemReport {
            excludeColumns
          }
        }
        settings {
          mode
          table
          diningOption
          retailOption
          orderAlarm
          orderConfirm
          addressInput
          autoPay
          autoReturnItem
          autoSoldout
          menuGridSize
          menuMode
          tableMode
          orderNotify
          orderNotifySkippable
          disablePhonePrefix
          autoCloseScreen
          orderNumberPrefix {
            pos
            kiosk
            menuOrder
          }
          orderMgmt {
            kiosk
            menuorder
            delivery
            pos
          }
          reportBasis
          orderOrigins
          beeper {
            active
            coverage
            skippable
          }
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
        }
        item {
          attributes {
            name
            type
            value
          }
          actualPrice
          skuMgmt
          barcodeMgmt
          stockTrack
          variablePrice
          locationCreatable
        }
        inventory {
          attributes {
            name
            type
            value
          }
          expiryDateMgmt
          expiryDateAlertDays
        }
        customer {
          attributes {
            name
            type
            value
          }
        }
        billing {
          key
          businessNumber
          name
          email
          phone
          contanctName
          contactName
          address
          zip
          card {
            pg
            number
            name
            expMonth
            expYear
            identify
            password
            billingKey
          }
        }
        credit {
          autoCharge
          autoChargeCredit
          autoChargeQuantity
          alertCredit
        }
        kakao {
          agency
          key
          channelId
          categoryCode
          status
          updatedAt
          createdAt
        }
        review {
          status
          campaignId
          updatedAt
          createdAt
        }
        kiosk {
          status
          callable
          payable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          exchangeTicket
          columnSize
          discounts {
            condition
            couponId
          }
          menuDescription
          updatedAt
          createdAt
        }
        menuOrder {
          callOptions {
            index
            label
          }
          orderable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          columnSize
          fontSize
          notice
          resetTime
          enableCash
          boardType
        }
        kds {
          layout
          alerts {
            urgent
            warning
          }
          disabledProdIds
          orderNotify
        }
        mirror {
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          video {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
        }
        loyalty {
          status
          provider
          sharePolicy
          type
          grade
          conditionType
          conditionSubType
          condition {
            minAmount
            perAmount
            minTime
            rate
            expirationPeriod
            excludeProdIds
            discountPointSavable
          }
          conditionItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          conditionTiers {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardType
          rewardSubType
          reward {
            requirePoint
            requireOrderAmount
            minUsePoint
            minRequireRate
            maxUsePoint
          }
          rewardItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardRates {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardAmounts {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardCashs {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          incentives {
            fulfill
            fulfillPoint
            fulfillCampaignId
            birth
            birthPoint
            birthCampaignId
            birthItem {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
          }
          notification {
            register
            registerCampainId
            save
            saveCampaignId
            use
            useCampaignId
            expire
            expireCampaignId
          }
        }
        market {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          paused
          takeoutDiscount
          creditDiscount
          takeout
          intro
          notice
          bookTable
          bookTableNote
          depositAmount
          delivery
          deliveryFee {
            start
            end
            fee
          }
          waitingTime
          itemIds
          updatedAt
          createdAt
        }
        notification {
          options {
            type
            email
            sms
          }
        }
        groups {
          items {
            id
            name
            type
            storeGroupsId
            locationGroupsId
            key
            conditions {
              key
              object
              field
              operations {
                operation
                type
                value
              }
              value
              operation
              type
            }
            count
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        location {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        locations {
          items {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization($input: DeleteOrganizationInput!) {
    deleteOrganization(input: $input) {
      id
      role
      employeeId
      enterpriseId
      storeId
      pin
      status
      locationId
      employee {
        id
        role
        lastName
        firstName
        name
        nickname
        phone
        email
        employeeStoreId
        employeeEnterpriseId
        organizations {
          items {
            id
            role
            employeeId
            enterpriseId
            storeId
            pin
            status
            locationId
            employee {
              id
              role
              lastName
              firstName
              name
              nickname
              phone
              email
              employeeStoreId
              employeeEnterpriseId
              organizations {
                nextToken
              }
              survey
              updatedAt
              createdAt
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
            }
            permissions
            updatedAt
            createdAt
            store {
              id
              enterpriseId
              info {
                name
                phone
                email
                representative
                businessType
                businessNumber
                industryType
                sendNumber
                currency
                address
                zip
                csPhone
              }
              policy {
                multiLocation
              }
              currency
              payments {
                card
                cardJoined
                cash
                credit
                refund
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              taxes {
                id
                type
                name
                enabled
                rate
                method
                targetType
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              item {
                actualPrice
                skuMgmt
                barcodeMgmt
                stockTrack
                variablePrice
                locationCreatable
              }
              inventory {
                expiryDateMgmt
                expiryDateAlertDays
              }
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              kakao {
                agency
                key
                channelId
                categoryCode
                status
                updatedAt
                createdAt
              }
              review {
                status
                campaignId
                updatedAt
                createdAt
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              groups {
                nextToken
              }
              createdAt
              updatedAt
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              locations {
                nextToken
              }
            }
          }
          nextToken
        }
        survey
        updatedAt
        createdAt
        store {
          id
          enterpriseId
          info {
            name
            phone
            email
            representative
            businessType
            businessNumber
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            industryType
            sendNumber
            currency
            address
            zip
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            csPhone
          }
          policy {
            multiLocation
            provider {
              key
              ident
              customer
              coupon
              inventory
              locationId
            }
          }
          currency
          payments {
            card
            cardJoined
            cash
            credit
            refund
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          plans {
            menus {
              id
              name
              mode
              size
              menus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              pages {
                index
                parentId
                title
              }
              updatedAt
              createdAt
            }
            orders {
              id
              name
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              updatedAt
              createdAt
            }
          }
          taxes {
            id
            type
            name
            enabled
            rate
            method
            targetType
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          item {
            attributes {
              name
              type
              value
            }
            actualPrice
            skuMgmt
            barcodeMgmt
            stockTrack
            variablePrice
            locationCreatable
          }
          inventory {
            attributes {
              name
              type
              value
            }
            expiryDateMgmt
            expiryDateAlertDays
          }
          customer {
            attributes {
              name
              type
              value
            }
          }
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          kakao {
            agency
            key
            channelId
            categoryCode
            status
            updatedAt
            createdAt
          }
          review {
            status
            campaignId
            updatedAt
            createdAt
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mirror {
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            video {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          notification {
            options {
              type
              email
              sms
            }
          }
          groups {
            items {
              id
              name
              type
              storeGroupsId
              locationGroupsId
              key
              conditions {
                key
                object
                field
                value
                operation
                type
              }
              count
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          location {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          locations {
            items {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            nextToken
          }
        }
      }
      permissions
      updatedAt
      createdAt
      store {
        id
        enterpriseId
        info {
          name
          phone
          email
          representative
          businessType
          businessNumber
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          industryType
          sendNumber
          currency
          address
          zip
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          csPhone
        }
        policy {
          multiLocation
          provider {
            key
            ident
            customer
            coupon
            inventory
            locationId
          }
        }
        currency
        payments {
          card
          cardJoined
          cash
          credit
          refund
        }
        devices {
          id
          ident
          locationId
          system
          ipAddress
          brand
          model
          name
          type
          deviceType
          display {
            height
            width
          }
          protocol
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          barcodeReader
          appVersion
          sysVersion
          status
          password
          msgToken
          parentId
          childId
          sleepMode
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
          terminalId
          printOptions {
            port
            baudRate
            receipt
            receiptButtonHide
            bills
            refund
            salesEnd
            order
            orderBeep
            orderCount
            orderExcludeIds
            delivery
          }
          updatedAt
          createdAt
        }
        plans {
          menus {
            id
            name
            mode
            size
            menus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            pages {
              index
              parentId
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              title
              titleMl {
                en
                zh
                ja
              }
            }
            updatedAt
            createdAt
          }
          orders {
            id
            name
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            updatedAt
            createdAt
          }
        }
        taxes {
          id
          type
          name
          enabled
          rate
          method
          targetType
        }
        receipt {
          salesSlip
          orderList
          barcode
          footnote
          phone
          orderNumberHide
          orderSheetFontSize
          billsBusinessInfo
          giftFootnote
          giftReceipt
        }
        subscription {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          updatedAt
          createdAt
        }
        view {
          order {
            excludeColumns
            exportTemplates {
              name
              type
              headers {
                key
                label
              }
            }
          }
          itemReport {
            excludeColumns
          }
          summaryReport {
            excludeColumns
          }
          shippingOrder {
            excludeColumns
          }
          trsItemReport {
            excludeColumns
          }
        }
        settings {
          mode
          table
          diningOption
          retailOption
          orderAlarm
          orderConfirm
          addressInput
          autoPay
          autoReturnItem
          autoSoldout
          menuGridSize
          menuMode
          tableMode
          orderNotify
          orderNotifySkippable
          disablePhonePrefix
          autoCloseScreen
          orderNumberPrefix {
            pos
            kiosk
            menuOrder
          }
          orderMgmt {
            kiosk
            menuorder
            delivery
            pos
          }
          reportBasis
          orderOrigins
          beeper {
            active
            coverage
            skippable
          }
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
        }
        item {
          attributes {
            name
            type
            value
          }
          actualPrice
          skuMgmt
          barcodeMgmt
          stockTrack
          variablePrice
          locationCreatable
        }
        inventory {
          attributes {
            name
            type
            value
          }
          expiryDateMgmt
          expiryDateAlertDays
        }
        customer {
          attributes {
            name
            type
            value
          }
        }
        billing {
          key
          businessNumber
          name
          email
          phone
          contanctName
          contactName
          address
          zip
          card {
            pg
            number
            name
            expMonth
            expYear
            identify
            password
            billingKey
          }
        }
        credit {
          autoCharge
          autoChargeCredit
          autoChargeQuantity
          alertCredit
        }
        kakao {
          agency
          key
          channelId
          categoryCode
          status
          updatedAt
          createdAt
        }
        review {
          status
          campaignId
          updatedAt
          createdAt
        }
        kiosk {
          status
          callable
          payable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          exchangeTicket
          columnSize
          discounts {
            condition
            couponId
          }
          menuDescription
          updatedAt
          createdAt
        }
        menuOrder {
          callOptions {
            index
            label
          }
          orderable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          columnSize
          fontSize
          notice
          resetTime
          enableCash
          boardType
        }
        kds {
          layout
          alerts {
            urgent
            warning
          }
          disabledProdIds
          orderNotify
        }
        mirror {
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          video {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
        }
        loyalty {
          status
          provider
          sharePolicy
          type
          grade
          conditionType
          conditionSubType
          condition {
            minAmount
            perAmount
            minTime
            rate
            expirationPeriod
            excludeProdIds
            discountPointSavable
          }
          conditionItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          conditionTiers {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardType
          rewardSubType
          reward {
            requirePoint
            requireOrderAmount
            minUsePoint
            minRequireRate
            maxUsePoint
          }
          rewardItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardRates {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardAmounts {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardCashs {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          incentives {
            fulfill
            fulfillPoint
            fulfillCampaignId
            birth
            birthPoint
            birthCampaignId
            birthItem {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
          }
          notification {
            register
            registerCampainId
            save
            saveCampaignId
            use
            useCampaignId
            expire
            expireCampaignId
          }
        }
        market {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          paused
          takeoutDiscount
          creditDiscount
          takeout
          intro
          notice
          bookTable
          bookTableNote
          depositAmount
          delivery
          deliveryFee {
            start
            end
            fee
          }
          waitingTime
          itemIds
          updatedAt
          createdAt
        }
        notification {
          options {
            type
            email
            sms
          }
        }
        groups {
          items {
            id
            name
            type
            storeGroupsId
            locationGroupsId
            key
            conditions {
              key
              object
              field
              operations {
                operation
                type
                value
              }
              value
              operation
              type
            }
            count
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        location {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        locations {
          items {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createRentalContract = /* GraphQL */ `
  mutation CreateRentalContract($input: CreateRentalContractInput!) {
    createRentalContract(input: $input) {
      id
      type
      storeId
      locationId
      businessOperatorId
      months
      quantity
      amount
      startDate
      endDate
      extraFees {
        name
        amount
        billedAt
        createdAt
      }
      updatedAt
      createdAt
      businessOperator {
        id
        businessNumber
        name
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateRentalContract = /* GraphQL */ `
  mutation UpdateRentalContract($input: UpdateRentalContractInput!) {
    updateRentalContract(input: $input) {
      id
      type
      storeId
      locationId
      businessOperatorId
      months
      quantity
      amount
      startDate
      endDate
      extraFees {
        name
        amount
        billedAt
        createdAt
      }
      updatedAt
      createdAt
      businessOperator {
        id
        businessNumber
        name
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteRentalContract = /* GraphQL */ `
  mutation DeleteRentalContract($input: DeleteRentalContractInput!) {
    deleteRentalContract(input: $input) {
      id
      type
      storeId
      locationId
      businessOperatorId
      months
      quantity
      amount
      startDate
      endDate
      extraFees {
        name
        amount
        billedAt
        createdAt
      }
      updatedAt
      createdAt
      businessOperator {
        id
        businessNumber
        name
        createdAt
        updatedAt
      }
    }
  }
`;
export const updatePointTransaction = /* GraphQL */ `
  mutation UpdatePointTransaction($input: UpdatePointTransactionInput!) {
    updatePointTransaction(input: $input) {
      id
      locationId
      storeId
      type
      name
      amount
      origin
      provider
      providerCustomerId
      providerSyncedAt
      currency
      point
      extraPoint
      extraExpire
      items {
        type
        itemId
        name
        parentId
        point
        amount
        quantity
      }
      quantity
      note
      orderId
      referId
      orderNumberPrefix
      orderNumber
      customerId
      pointTransactionCustomerId
      expiredAt
      salesDate
      updatedAt
      createdAt
      customer {
        id
        customerId
        storeId
        sources {
          id
          type
          name
          ident
          createdAt
        }
        isFavorite
        locationId
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        favoriteLocationIds
        visitedLocationIds
        locations {
          id
          locationId
          isFavorite
          status
          note
          campaignBlockedAt
          blacklistedAt
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          subscriptions {
            id
            planId
            payStatus
            sessionId
            nextPayDate
            paidAt
            unsubscribedAt
            used
            lastUsedAt
            orderId
            unsubscribeRequestedAt
            startDate
            expireDate
            updatedAt
            createdAt
          }
          registeredAt
          salesDate
          updatedAt
          createdAt
        }
        status
        registeredAt
        campaignBlockedAt
        note
        removedAt
        updatedAt
        createdAt
        points {
          items {
            id
            locationId
            storeId
            type
            name
            amount
            origin
            provider
            providerCustomerId
            providerSyncedAt
            currency
            point
            extraPoint
            extraExpire
            items {
              type
              itemId
              name
              parentId
              point
              amount
              quantity
            }
            quantity
            note
            orderId
            referId
            orderNumberPrefix
            orderNumber
            customerId
            pointTransactionCustomerId
            expiredAt
            salesDate
            updatedAt
            createdAt
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
          }
          nextToken
        }
        credits {
          items {
            id
            locationId
            storeId
            name
            type
            amount
            origin
            note
            currency
            parentId
            orderId
            orderNumberPrefix
            orderNumber
            customerId
            creditTransactionCustomerId
            description
            salesDate
            createdAt
            updatedAt
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
          }
          nextToken
        }
        _delta
        orders {
          items {
            id
            orderLocationId
            numberPrefix
            number
            beeper
            enterpriseId
            storeId
            customerId
            orderCustomerId
            state
            amount
            amountPaid
            origin
            subOrigin
            originId
            customerProvider
            providerCustomerId
            amountChanged
            amountReturned
            tax
            currency
            quantity
            shippingType
            diningOption
            diningState
            retailOption
            retailState
            name
            phone
            address
            zip
            receivers {
              id
              name
              phone
              zip
              address1
              address2
              addressState
              addressCity
              addressStreet
              addressFull
              note
              shippingCompanyCode
              shippingAcceptCount
              shippingStartCount
              trackingNumber
              privacyAgreedAt
            }
            tableId
            tableIds
            parentDeviceId
            deviceId
            reserveDate
            pickupDate
            shippingDate
            items {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            createdEmployeeId
            createdDeviceId
            updatedDeviceId
            shippingLocationId
            confirmedEmployeeId
            confirmedAt
            createdAt
            paidAt
            salesDate
            returnedAt
            shippedAt
            returnShippedAt
            returnSalesDate
            updatedAt
            noticeCount
            visitorCount
            totalPoint
            pointSaved
            receiptId
            receiptNumber
            receiptCount
            alarmCustomerId
            receiptCustomerId
            note
            request
            charges {
              id
              method
              extraMethod
              gateway
              amount
              tax
              taxRefundAmount
              purchaser {
                name
                number
                nation
                address
              }
              supplyAmount
              taxFreeAmount
              amountRefunded
              createdEmployeeId
              createdDeviceId
              createdAt
              creditTransactionId
              balance
              splitType
              pg
              van
              origin
              originId
              note
              brand
              name
              ident
              installment
              transactionId
              approver
              issuerCode
              acquirer
              acquirerCode
              acquirerName
              refundable
              recurring
              approvalCode
              approvalDate
              approvalNumber
              signature {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              refunds {
                id
                amount
                supplyAmount
                taxFreeAmount
                tax
                taxRefundAmount
                creditTransactionId
                approvalNumber
                approvalDate
                transactionId
                origin
                originId
                currency
                reason
                createdEmployeeId
                createdDeviceId
                createdAt
              }
            }
            returns {
              amount
              currency
              items {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              refundId
              createdAt
            }
            fee
            revision
            _delta
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
            orderCustomer {
              id
              enterpriseId
              storeId
              locationId
              customerId
              provider
              providerCustomerId
              tier
              customerCreatedAt
              ident
              identType
              type
              name
              nameKeyword
              payments {
                id
                method
                pg
                number
                name
                expMonth
                expYear
                billingKey
                updatedAt
                createdAt
              }
              attributes {
                name
                type
                value
              }
              addresses {
                id
                main
                detail
                storeId
              }
              phone
              email
              birth
              agreements {
                privacy
                provider
                eula
                marketing
                updatedAt
                createdAt
              }
              ageGroup
              gender
              tags {
                tagId
                createdLocationId
              }
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              status
              registeredAt
              salesDate
              campaignBlockedAt
              blacklistedAt
              isFavorite
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              subscriptions {
                id
                planId
                payStatus
                sessionId
                nextPayDate
                paidAt
                unsubscribedAt
                used
                lastUsedAt
                orderId
                unsubscribeRequestedAt
                startDate
                expireDate
                updatedAt
                createdAt
              }
              note
              sources {
                id
                type
                name
                ident
                createdAt
              }
              removedAt
              updatedAt
              createdAt
              _delta
              timestamp
              expdate
            }
          }
          nextToken
        }
        location {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        store {
          id
          enterpriseId
          info {
            name
            phone
            email
            representative
            businessType
            businessNumber
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            industryType
            sendNumber
            currency
            address
            zip
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            csPhone
          }
          policy {
            multiLocation
            provider {
              key
              ident
              customer
              coupon
              inventory
              locationId
            }
          }
          currency
          payments {
            card
            cardJoined
            cash
            credit
            refund
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          plans {
            menus {
              id
              name
              mode
              size
              menus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              pages {
                index
                parentId
                title
              }
              updatedAt
              createdAt
            }
            orders {
              id
              name
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              updatedAt
              createdAt
            }
          }
          taxes {
            id
            type
            name
            enabled
            rate
            method
            targetType
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          item {
            attributes {
              name
              type
              value
            }
            actualPrice
            skuMgmt
            barcodeMgmt
            stockTrack
            variablePrice
            locationCreatable
          }
          inventory {
            attributes {
              name
              type
              value
            }
            expiryDateMgmt
            expiryDateAlertDays
          }
          customer {
            attributes {
              name
              type
              value
            }
          }
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          kakao {
            agency
            key
            channelId
            categoryCode
            status
            updatedAt
            createdAt
          }
          review {
            status
            campaignId
            updatedAt
            createdAt
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mirror {
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            video {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          notification {
            options {
              type
              email
              sms
            }
          }
          groups {
            items {
              id
              name
              type
              storeGroupsId
              locationGroupsId
              key
              conditions {
                key
                object
                field
                value
                operation
                type
              }
              count
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          location {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          locations {
            items {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            nextToken
          }
        }
        customer {
          id
          ident
          identType
          type
          name
          addresses {
            id
            main
            detail
            storeId
          }
          phone
          email
          birth
          agreements {
            privacy
            provider
            eula
            marketing
            updatedAt
            createdAt
          }
          ageGroup
          gender
          updatedAt
          createdAt
          stores {
            items {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const deletePointTransaction = /* GraphQL */ `
  mutation DeletePointTransaction($input: DeletePointTransactionInput!) {
    deletePointTransaction(input: $input) {
      id
      locationId
      storeId
      type
      name
      amount
      origin
      provider
      providerCustomerId
      providerSyncedAt
      currency
      point
      extraPoint
      extraExpire
      items {
        type
        itemId
        name
        parentId
        point
        amount
        quantity
      }
      quantity
      note
      orderId
      referId
      orderNumberPrefix
      orderNumber
      customerId
      pointTransactionCustomerId
      expiredAt
      salesDate
      updatedAt
      createdAt
      customer {
        id
        customerId
        storeId
        sources {
          id
          type
          name
          ident
          createdAt
        }
        isFavorite
        locationId
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        favoriteLocationIds
        visitedLocationIds
        locations {
          id
          locationId
          isFavorite
          status
          note
          campaignBlockedAt
          blacklistedAt
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          subscriptions {
            id
            planId
            payStatus
            sessionId
            nextPayDate
            paidAt
            unsubscribedAt
            used
            lastUsedAt
            orderId
            unsubscribeRequestedAt
            startDate
            expireDate
            updatedAt
            createdAt
          }
          registeredAt
          salesDate
          updatedAt
          createdAt
        }
        status
        registeredAt
        campaignBlockedAt
        note
        removedAt
        updatedAt
        createdAt
        points {
          items {
            id
            locationId
            storeId
            type
            name
            amount
            origin
            provider
            providerCustomerId
            providerSyncedAt
            currency
            point
            extraPoint
            extraExpire
            items {
              type
              itemId
              name
              parentId
              point
              amount
              quantity
            }
            quantity
            note
            orderId
            referId
            orderNumberPrefix
            orderNumber
            customerId
            pointTransactionCustomerId
            expiredAt
            salesDate
            updatedAt
            createdAt
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
          }
          nextToken
        }
        credits {
          items {
            id
            locationId
            storeId
            name
            type
            amount
            origin
            note
            currency
            parentId
            orderId
            orderNumberPrefix
            orderNumber
            customerId
            creditTransactionCustomerId
            description
            salesDate
            createdAt
            updatedAt
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
          }
          nextToken
        }
        _delta
        orders {
          items {
            id
            orderLocationId
            numberPrefix
            number
            beeper
            enterpriseId
            storeId
            customerId
            orderCustomerId
            state
            amount
            amountPaid
            origin
            subOrigin
            originId
            customerProvider
            providerCustomerId
            amountChanged
            amountReturned
            tax
            currency
            quantity
            shippingType
            diningOption
            diningState
            retailOption
            retailState
            name
            phone
            address
            zip
            receivers {
              id
              name
              phone
              zip
              address1
              address2
              addressState
              addressCity
              addressStreet
              addressFull
              note
              shippingCompanyCode
              shippingAcceptCount
              shippingStartCount
              trackingNumber
              privacyAgreedAt
            }
            tableId
            tableIds
            parentDeviceId
            deviceId
            reserveDate
            pickupDate
            shippingDate
            items {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            createdEmployeeId
            createdDeviceId
            updatedDeviceId
            shippingLocationId
            confirmedEmployeeId
            confirmedAt
            createdAt
            paidAt
            salesDate
            returnedAt
            shippedAt
            returnShippedAt
            returnSalesDate
            updatedAt
            noticeCount
            visitorCount
            totalPoint
            pointSaved
            receiptId
            receiptNumber
            receiptCount
            alarmCustomerId
            receiptCustomerId
            note
            request
            charges {
              id
              method
              extraMethod
              gateway
              amount
              tax
              taxRefundAmount
              purchaser {
                name
                number
                nation
                address
              }
              supplyAmount
              taxFreeAmount
              amountRefunded
              createdEmployeeId
              createdDeviceId
              createdAt
              creditTransactionId
              balance
              splitType
              pg
              van
              origin
              originId
              note
              brand
              name
              ident
              installment
              transactionId
              approver
              issuerCode
              acquirer
              acquirerCode
              acquirerName
              refundable
              recurring
              approvalCode
              approvalDate
              approvalNumber
              signature {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              refunds {
                id
                amount
                supplyAmount
                taxFreeAmount
                tax
                taxRefundAmount
                creditTransactionId
                approvalNumber
                approvalDate
                transactionId
                origin
                originId
                currency
                reason
                createdEmployeeId
                createdDeviceId
                createdAt
              }
            }
            returns {
              amount
              currency
              items {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              refundId
              createdAt
            }
            fee
            revision
            _delta
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
            orderCustomer {
              id
              enterpriseId
              storeId
              locationId
              customerId
              provider
              providerCustomerId
              tier
              customerCreatedAt
              ident
              identType
              type
              name
              nameKeyword
              payments {
                id
                method
                pg
                number
                name
                expMonth
                expYear
                billingKey
                updatedAt
                createdAt
              }
              attributes {
                name
                type
                value
              }
              addresses {
                id
                main
                detail
                storeId
              }
              phone
              email
              birth
              agreements {
                privacy
                provider
                eula
                marketing
                updatedAt
                createdAt
              }
              ageGroup
              gender
              tags {
                tagId
                createdLocationId
              }
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              status
              registeredAt
              salesDate
              campaignBlockedAt
              blacklistedAt
              isFavorite
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              subscriptions {
                id
                planId
                payStatus
                sessionId
                nextPayDate
                paidAt
                unsubscribedAt
                used
                lastUsedAt
                orderId
                unsubscribeRequestedAt
                startDate
                expireDate
                updatedAt
                createdAt
              }
              note
              sources {
                id
                type
                name
                ident
                createdAt
              }
              removedAt
              updatedAt
              createdAt
              _delta
              timestamp
              expdate
            }
          }
          nextToken
        }
        location {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        store {
          id
          enterpriseId
          info {
            name
            phone
            email
            representative
            businessType
            businessNumber
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            industryType
            sendNumber
            currency
            address
            zip
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            csPhone
          }
          policy {
            multiLocation
            provider {
              key
              ident
              customer
              coupon
              inventory
              locationId
            }
          }
          currency
          payments {
            card
            cardJoined
            cash
            credit
            refund
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          plans {
            menus {
              id
              name
              mode
              size
              menus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              pages {
                index
                parentId
                title
              }
              updatedAt
              createdAt
            }
            orders {
              id
              name
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              updatedAt
              createdAt
            }
          }
          taxes {
            id
            type
            name
            enabled
            rate
            method
            targetType
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          item {
            attributes {
              name
              type
              value
            }
            actualPrice
            skuMgmt
            barcodeMgmt
            stockTrack
            variablePrice
            locationCreatable
          }
          inventory {
            attributes {
              name
              type
              value
            }
            expiryDateMgmt
            expiryDateAlertDays
          }
          customer {
            attributes {
              name
              type
              value
            }
          }
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          kakao {
            agency
            key
            channelId
            categoryCode
            status
            updatedAt
            createdAt
          }
          review {
            status
            campaignId
            updatedAt
            createdAt
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mirror {
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            video {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          notification {
            options {
              type
              email
              sms
            }
          }
          groups {
            items {
              id
              name
              type
              storeGroupsId
              locationGroupsId
              key
              conditions {
                key
                object
                field
                value
                operation
                type
              }
              count
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          location {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          locations {
            items {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            nextToken
          }
        }
        customer {
          id
          ident
          identType
          type
          name
          addresses {
            id
            main
            detail
            storeId
          }
          phone
          email
          birth
          agreements {
            privacy
            provider
            eula
            marketing
            updatedAt
            createdAt
          }
          ageGroup
          gender
          updatedAt
          createdAt
          stores {
            items {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const createCustomerHistory = /* GraphQL */ `
  mutation CreateCustomerHistory($input: CreateCustomerHistoryInput!) {
    createCustomerHistory(input: $input) {
      storeId
      createdAt
      locationId
      name
      phone
      birth
      gender
      status
      customerId
      storeCustomerId
      updatedAt
    }
  }
`;
export const updateCustomerHistory = /* GraphQL */ `
  mutation UpdateCustomerHistory($input: UpdateCustomerHistoryInput!) {
    updateCustomerHistory(input: $input) {
      storeId
      createdAt
      locationId
      name
      phone
      birth
      gender
      status
      customerId
      storeCustomerId
      updatedAt
    }
  }
`;
export const deleteCustomerHistory = /* GraphQL */ `
  mutation DeleteCustomerHistory($input: DeleteCustomerHistoryInput!) {
    deleteCustomerHistory(input: $input) {
      storeId
      createdAt
      locationId
      name
      phone
      birth
      gender
      status
      customerId
      storeCustomerId
      updatedAt
    }
  }
`;
export const updateCreditTransaction = /* GraphQL */ `
  mutation UpdateCreditTransaction($input: UpdateCreditTransactionInput!) {
    updateCreditTransaction(input: $input) {
      id
      locationId
      storeId
      name
      type
      amount
      origin
      note
      currency
      parentId
      orderId
      orderNumberPrefix
      orderNumber
      customerId
      creditTransactionCustomerId
      description
      salesDate
      createdAt
      updatedAt
      customer {
        id
        customerId
        storeId
        sources {
          id
          type
          name
          ident
          createdAt
        }
        isFavorite
        locationId
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        favoriteLocationIds
        visitedLocationIds
        locations {
          id
          locationId
          isFavorite
          status
          note
          campaignBlockedAt
          blacklistedAt
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          subscriptions {
            id
            planId
            payStatus
            sessionId
            nextPayDate
            paidAt
            unsubscribedAt
            used
            lastUsedAt
            orderId
            unsubscribeRequestedAt
            startDate
            expireDate
            updatedAt
            createdAt
          }
          registeredAt
          salesDate
          updatedAt
          createdAt
        }
        status
        registeredAt
        campaignBlockedAt
        note
        removedAt
        updatedAt
        createdAt
        points {
          items {
            id
            locationId
            storeId
            type
            name
            amount
            origin
            provider
            providerCustomerId
            providerSyncedAt
            currency
            point
            extraPoint
            extraExpire
            items {
              type
              itemId
              name
              parentId
              point
              amount
              quantity
            }
            quantity
            note
            orderId
            referId
            orderNumberPrefix
            orderNumber
            customerId
            pointTransactionCustomerId
            expiredAt
            salesDate
            updatedAt
            createdAt
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
          }
          nextToken
        }
        credits {
          items {
            id
            locationId
            storeId
            name
            type
            amount
            origin
            note
            currency
            parentId
            orderId
            orderNumberPrefix
            orderNumber
            customerId
            creditTransactionCustomerId
            description
            salesDate
            createdAt
            updatedAt
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
          }
          nextToken
        }
        _delta
        orders {
          items {
            id
            orderLocationId
            numberPrefix
            number
            beeper
            enterpriseId
            storeId
            customerId
            orderCustomerId
            state
            amount
            amountPaid
            origin
            subOrigin
            originId
            customerProvider
            providerCustomerId
            amountChanged
            amountReturned
            tax
            currency
            quantity
            shippingType
            diningOption
            diningState
            retailOption
            retailState
            name
            phone
            address
            zip
            receivers {
              id
              name
              phone
              zip
              address1
              address2
              addressState
              addressCity
              addressStreet
              addressFull
              note
              shippingCompanyCode
              shippingAcceptCount
              shippingStartCount
              trackingNumber
              privacyAgreedAt
            }
            tableId
            tableIds
            parentDeviceId
            deviceId
            reserveDate
            pickupDate
            shippingDate
            items {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            createdEmployeeId
            createdDeviceId
            updatedDeviceId
            shippingLocationId
            confirmedEmployeeId
            confirmedAt
            createdAt
            paidAt
            salesDate
            returnedAt
            shippedAt
            returnShippedAt
            returnSalesDate
            updatedAt
            noticeCount
            visitorCount
            totalPoint
            pointSaved
            receiptId
            receiptNumber
            receiptCount
            alarmCustomerId
            receiptCustomerId
            note
            request
            charges {
              id
              method
              extraMethod
              gateway
              amount
              tax
              taxRefundAmount
              purchaser {
                name
                number
                nation
                address
              }
              supplyAmount
              taxFreeAmount
              amountRefunded
              createdEmployeeId
              createdDeviceId
              createdAt
              creditTransactionId
              balance
              splitType
              pg
              van
              origin
              originId
              note
              brand
              name
              ident
              installment
              transactionId
              approver
              issuerCode
              acquirer
              acquirerCode
              acquirerName
              refundable
              recurring
              approvalCode
              approvalDate
              approvalNumber
              signature {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              refunds {
                id
                amount
                supplyAmount
                taxFreeAmount
                tax
                taxRefundAmount
                creditTransactionId
                approvalNumber
                approvalDate
                transactionId
                origin
                originId
                currency
                reason
                createdEmployeeId
                createdDeviceId
                createdAt
              }
            }
            returns {
              amount
              currency
              items {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              refundId
              createdAt
            }
            fee
            revision
            _delta
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
            orderCustomer {
              id
              enterpriseId
              storeId
              locationId
              customerId
              provider
              providerCustomerId
              tier
              customerCreatedAt
              ident
              identType
              type
              name
              nameKeyword
              payments {
                id
                method
                pg
                number
                name
                expMonth
                expYear
                billingKey
                updatedAt
                createdAt
              }
              attributes {
                name
                type
                value
              }
              addresses {
                id
                main
                detail
                storeId
              }
              phone
              email
              birth
              agreements {
                privacy
                provider
                eula
                marketing
                updatedAt
                createdAt
              }
              ageGroup
              gender
              tags {
                tagId
                createdLocationId
              }
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              status
              registeredAt
              salesDate
              campaignBlockedAt
              blacklistedAt
              isFavorite
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              subscriptions {
                id
                planId
                payStatus
                sessionId
                nextPayDate
                paidAt
                unsubscribedAt
                used
                lastUsedAt
                orderId
                unsubscribeRequestedAt
                startDate
                expireDate
                updatedAt
                createdAt
              }
              note
              sources {
                id
                type
                name
                ident
                createdAt
              }
              removedAt
              updatedAt
              createdAt
              _delta
              timestamp
              expdate
            }
          }
          nextToken
        }
        location {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        store {
          id
          enterpriseId
          info {
            name
            phone
            email
            representative
            businessType
            businessNumber
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            industryType
            sendNumber
            currency
            address
            zip
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            csPhone
          }
          policy {
            multiLocation
            provider {
              key
              ident
              customer
              coupon
              inventory
              locationId
            }
          }
          currency
          payments {
            card
            cardJoined
            cash
            credit
            refund
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          plans {
            menus {
              id
              name
              mode
              size
              menus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              pages {
                index
                parentId
                title
              }
              updatedAt
              createdAt
            }
            orders {
              id
              name
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              updatedAt
              createdAt
            }
          }
          taxes {
            id
            type
            name
            enabled
            rate
            method
            targetType
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          item {
            attributes {
              name
              type
              value
            }
            actualPrice
            skuMgmt
            barcodeMgmt
            stockTrack
            variablePrice
            locationCreatable
          }
          inventory {
            attributes {
              name
              type
              value
            }
            expiryDateMgmt
            expiryDateAlertDays
          }
          customer {
            attributes {
              name
              type
              value
            }
          }
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          kakao {
            agency
            key
            channelId
            categoryCode
            status
            updatedAt
            createdAt
          }
          review {
            status
            campaignId
            updatedAt
            createdAt
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mirror {
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            video {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          notification {
            options {
              type
              email
              sms
            }
          }
          groups {
            items {
              id
              name
              type
              storeGroupsId
              locationGroupsId
              key
              conditions {
                key
                object
                field
                value
                operation
                type
              }
              count
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          location {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          locations {
            items {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            nextToken
          }
        }
        customer {
          id
          ident
          identType
          type
          name
          addresses {
            id
            main
            detail
            storeId
          }
          phone
          email
          birth
          agreements {
            privacy
            provider
            eula
            marketing
            updatedAt
            createdAt
          }
          ageGroup
          gender
          updatedAt
          createdAt
          stores {
            items {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const deleteCreditTransaction = /* GraphQL */ `
  mutation DeleteCreditTransaction($input: DeleteCreditTransactionInput!) {
    deleteCreditTransaction(input: $input) {
      id
      locationId
      storeId
      name
      type
      amount
      origin
      note
      currency
      parentId
      orderId
      orderNumberPrefix
      orderNumber
      customerId
      creditTransactionCustomerId
      description
      salesDate
      createdAt
      updatedAt
      customer {
        id
        customerId
        storeId
        sources {
          id
          type
          name
          ident
          createdAt
        }
        isFavorite
        locationId
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        favoriteLocationIds
        visitedLocationIds
        locations {
          id
          locationId
          isFavorite
          status
          note
          campaignBlockedAt
          blacklistedAt
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          subscriptions {
            id
            planId
            payStatus
            sessionId
            nextPayDate
            paidAt
            unsubscribedAt
            used
            lastUsedAt
            orderId
            unsubscribeRequestedAt
            startDate
            expireDate
            updatedAt
            createdAt
          }
          registeredAt
          salesDate
          updatedAt
          createdAt
        }
        status
        registeredAt
        campaignBlockedAt
        note
        removedAt
        updatedAt
        createdAt
        points {
          items {
            id
            locationId
            storeId
            type
            name
            amount
            origin
            provider
            providerCustomerId
            providerSyncedAt
            currency
            point
            extraPoint
            extraExpire
            items {
              type
              itemId
              name
              parentId
              point
              amount
              quantity
            }
            quantity
            note
            orderId
            referId
            orderNumberPrefix
            orderNumber
            customerId
            pointTransactionCustomerId
            expiredAt
            salesDate
            updatedAt
            createdAt
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
          }
          nextToken
        }
        credits {
          items {
            id
            locationId
            storeId
            name
            type
            amount
            origin
            note
            currency
            parentId
            orderId
            orderNumberPrefix
            orderNumber
            customerId
            creditTransactionCustomerId
            description
            salesDate
            createdAt
            updatedAt
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
          }
          nextToken
        }
        _delta
        orders {
          items {
            id
            orderLocationId
            numberPrefix
            number
            beeper
            enterpriseId
            storeId
            customerId
            orderCustomerId
            state
            amount
            amountPaid
            origin
            subOrigin
            originId
            customerProvider
            providerCustomerId
            amountChanged
            amountReturned
            tax
            currency
            quantity
            shippingType
            diningOption
            diningState
            retailOption
            retailState
            name
            phone
            address
            zip
            receivers {
              id
              name
              phone
              zip
              address1
              address2
              addressState
              addressCity
              addressStreet
              addressFull
              note
              shippingCompanyCode
              shippingAcceptCount
              shippingStartCount
              trackingNumber
              privacyAgreedAt
            }
            tableId
            tableIds
            parentDeviceId
            deviceId
            reserveDate
            pickupDate
            shippingDate
            items {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            createdEmployeeId
            createdDeviceId
            updatedDeviceId
            shippingLocationId
            confirmedEmployeeId
            confirmedAt
            createdAt
            paidAt
            salesDate
            returnedAt
            shippedAt
            returnShippedAt
            returnSalesDate
            updatedAt
            noticeCount
            visitorCount
            totalPoint
            pointSaved
            receiptId
            receiptNumber
            receiptCount
            alarmCustomerId
            receiptCustomerId
            note
            request
            charges {
              id
              method
              extraMethod
              gateway
              amount
              tax
              taxRefundAmount
              purchaser {
                name
                number
                nation
                address
              }
              supplyAmount
              taxFreeAmount
              amountRefunded
              createdEmployeeId
              createdDeviceId
              createdAt
              creditTransactionId
              balance
              splitType
              pg
              van
              origin
              originId
              note
              brand
              name
              ident
              installment
              transactionId
              approver
              issuerCode
              acquirer
              acquirerCode
              acquirerName
              refundable
              recurring
              approvalCode
              approvalDate
              approvalNumber
              signature {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              refunds {
                id
                amount
                supplyAmount
                taxFreeAmount
                tax
                taxRefundAmount
                creditTransactionId
                approvalNumber
                approvalDate
                transactionId
                origin
                originId
                currency
                reason
                createdEmployeeId
                createdDeviceId
                createdAt
              }
            }
            returns {
              amount
              currency
              items {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              refundId
              createdAt
            }
            fee
            revision
            _delta
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
            orderCustomer {
              id
              enterpriseId
              storeId
              locationId
              customerId
              provider
              providerCustomerId
              tier
              customerCreatedAt
              ident
              identType
              type
              name
              nameKeyword
              payments {
                id
                method
                pg
                number
                name
                expMonth
                expYear
                billingKey
                updatedAt
                createdAt
              }
              attributes {
                name
                type
                value
              }
              addresses {
                id
                main
                detail
                storeId
              }
              phone
              email
              birth
              agreements {
                privacy
                provider
                eula
                marketing
                updatedAt
                createdAt
              }
              ageGroup
              gender
              tags {
                tagId
                createdLocationId
              }
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              status
              registeredAt
              salesDate
              campaignBlockedAt
              blacklistedAt
              isFavorite
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              subscriptions {
                id
                planId
                payStatus
                sessionId
                nextPayDate
                paidAt
                unsubscribedAt
                used
                lastUsedAt
                orderId
                unsubscribeRequestedAt
                startDate
                expireDate
                updatedAt
                createdAt
              }
              note
              sources {
                id
                type
                name
                ident
                createdAt
              }
              removedAt
              updatedAt
              createdAt
              _delta
              timestamp
              expdate
            }
          }
          nextToken
        }
        location {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        store {
          id
          enterpriseId
          info {
            name
            phone
            email
            representative
            businessType
            businessNumber
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            industryType
            sendNumber
            currency
            address
            zip
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            csPhone
          }
          policy {
            multiLocation
            provider {
              key
              ident
              customer
              coupon
              inventory
              locationId
            }
          }
          currency
          payments {
            card
            cardJoined
            cash
            credit
            refund
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          plans {
            menus {
              id
              name
              mode
              size
              menus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              pages {
                index
                parentId
                title
              }
              updatedAt
              createdAt
            }
            orders {
              id
              name
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              updatedAt
              createdAt
            }
          }
          taxes {
            id
            type
            name
            enabled
            rate
            method
            targetType
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          item {
            attributes {
              name
              type
              value
            }
            actualPrice
            skuMgmt
            barcodeMgmt
            stockTrack
            variablePrice
            locationCreatable
          }
          inventory {
            attributes {
              name
              type
              value
            }
            expiryDateMgmt
            expiryDateAlertDays
          }
          customer {
            attributes {
              name
              type
              value
            }
          }
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          kakao {
            agency
            key
            channelId
            categoryCode
            status
            updatedAt
            createdAt
          }
          review {
            status
            campaignId
            updatedAt
            createdAt
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mirror {
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            video {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          notification {
            options {
              type
              email
              sms
            }
          }
          groups {
            items {
              id
              name
              type
              storeGroupsId
              locationGroupsId
              key
              conditions {
                key
                object
                field
                value
                operation
                type
              }
              count
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          location {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          locations {
            items {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            nextToken
          }
        }
        customer {
          id
          ident
          identType
          type
          name
          addresses {
            id
            main
            detail
            storeId
          }
          phone
          email
          birth
          agreements {
            privacy
            provider
            eula
            marketing
            updatedAt
            createdAt
          }
          ageGroup
          gender
          updatedAt
          createdAt
          stores {
            items {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const createStoreCustomer = /* GraphQL */ `
  mutation CreateStoreCustomer($input: CreateStoreCustomerInput!) {
    createStoreCustomer(input: $input) {
      id
      customerId
      storeId
      sources {
        id
        type
        name
        ident
        createdAt
      }
      isFavorite
      locationId
      point {
        used
        saved
        extra
        lastSavedAt
        lastTransactedAt
      }
      credit {
        deposit
        withdraw
        balance
        lastTransactedAt
      }
      favoriteLocationIds
      visitedLocationIds
      locations {
        id
        locationId
        isFavorite
        status
        note
        campaignBlockedAt
        blacklistedAt
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        subscriptions {
          id
          planId
          payStatus
          sessionId
          nextPayDate
          paidAt
          unsubscribedAt
          used
          lastUsedAt
          orderId
          unsubscribeRequestedAt
          startDate
          expireDate
          updatedAt
          createdAt
        }
        registeredAt
        salesDate
        updatedAt
        createdAt
      }
      status
      registeredAt
      campaignBlockedAt
      note
      removedAt
      updatedAt
      createdAt
      points {
        items {
          id
          locationId
          storeId
          type
          name
          amount
          origin
          provider
          providerCustomerId
          providerSyncedAt
          currency
          point
          extraPoint
          extraExpire
          items {
            type
            itemId
            name
            parentId
            point
            amount
            quantity
          }
          quantity
          note
          orderId
          referId
          orderNumberPrefix
          orderNumber
          customerId
          pointTransactionCustomerId
          expiredAt
          salesDate
          updatedAt
          createdAt
          customer {
            id
            customerId
            storeId
            sources {
              id
              type
              name
              ident
              createdAt
            }
            isFavorite
            locationId
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            favoriteLocationIds
            visitedLocationIds
            locations {
              id
              locationId
              isFavorite
              status
              note
              campaignBlockedAt
              blacklistedAt
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              subscriptions {
                id
                planId
                payStatus
                sessionId
                nextPayDate
                paidAt
                unsubscribedAt
                used
                lastUsedAt
                orderId
                unsubscribeRequestedAt
                startDate
                expireDate
                updatedAt
                createdAt
              }
              registeredAt
              salesDate
              updatedAt
              createdAt
            }
            status
            registeredAt
            campaignBlockedAt
            note
            removedAt
            updatedAt
            createdAt
            points {
              items {
                id
                locationId
                storeId
                type
                name
                amount
                origin
                provider
                providerCustomerId
                providerSyncedAt
                currency
                point
                extraPoint
                extraExpire
                quantity
                note
                orderId
                referId
                orderNumberPrefix
                orderNumber
                customerId
                pointTransactionCustomerId
                expiredAt
                salesDate
                updatedAt
                createdAt
              }
              nextToken
            }
            credits {
              items {
                id
                locationId
                storeId
                name
                type
                amount
                origin
                note
                currency
                parentId
                orderId
                orderNumberPrefix
                orderNumber
                customerId
                creditTransactionCustomerId
                description
                salesDate
                createdAt
                updatedAt
              }
              nextToken
            }
            _delta
            orders {
              items {
                id
                orderLocationId
                numberPrefix
                number
                beeper
                enterpriseId
                storeId
                customerId
                orderCustomerId
                state
                amount
                amountPaid
                origin
                subOrigin
                originId
                customerProvider
                providerCustomerId
                amountChanged
                amountReturned
                tax
                currency
                quantity
                shippingType
                diningOption
                diningState
                retailOption
                retailState
                name
                phone
                address
                zip
                tableId
                tableIds
                parentDeviceId
                deviceId
                reserveDate
                pickupDate
                shippingDate
                createdEmployeeId
                createdDeviceId
                updatedDeviceId
                shippingLocationId
                confirmedEmployeeId
                confirmedAt
                createdAt
                paidAt
                salesDate
                returnedAt
                shippedAt
                returnShippedAt
                returnSalesDate
                updatedAt
                noticeCount
                visitorCount
                totalPoint
                pointSaved
                receiptId
                receiptNumber
                receiptCount
                alarmCustomerId
                receiptCustomerId
                note
                request
                fee
                revision
                _delta
              }
              nextToken
            }
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            store {
              id
              enterpriseId
              info {
                name
                phone
                email
                representative
                businessType
                businessNumber
                industryType
                sendNumber
                currency
                address
                zip
                csPhone
              }
              policy {
                multiLocation
              }
              currency
              payments {
                card
                cardJoined
                cash
                credit
                refund
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              taxes {
                id
                type
                name
                enabled
                rate
                method
                targetType
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              item {
                actualPrice
                skuMgmt
                barcodeMgmt
                stockTrack
                variablePrice
                locationCreatable
              }
              inventory {
                expiryDateMgmt
                expiryDateAlertDays
              }
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              kakao {
                agency
                key
                channelId
                categoryCode
                status
                updatedAt
                createdAt
              }
              review {
                status
                campaignId
                updatedAt
                createdAt
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              groups {
                nextToken
              }
              createdAt
              updatedAt
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              locations {
                nextToken
              }
            }
            customer {
              id
              ident
              identType
              type
              name
              addresses {
                id
                main
                detail
                storeId
              }
              phone
              email
              birth
              agreements {
                privacy
                provider
                eula
                marketing
                updatedAt
                createdAt
              }
              ageGroup
              gender
              updatedAt
              createdAt
              stores {
                nextToken
              }
            }
          }
        }
        nextToken
      }
      credits {
        items {
          id
          locationId
          storeId
          name
          type
          amount
          origin
          note
          currency
          parentId
          orderId
          orderNumberPrefix
          orderNumber
          customerId
          creditTransactionCustomerId
          description
          salesDate
          createdAt
          updatedAt
          customer {
            id
            customerId
            storeId
            sources {
              id
              type
              name
              ident
              createdAt
            }
            isFavorite
            locationId
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            favoriteLocationIds
            visitedLocationIds
            locations {
              id
              locationId
              isFavorite
              status
              note
              campaignBlockedAt
              blacklistedAt
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              subscriptions {
                id
                planId
                payStatus
                sessionId
                nextPayDate
                paidAt
                unsubscribedAt
                used
                lastUsedAt
                orderId
                unsubscribeRequestedAt
                startDate
                expireDate
                updatedAt
                createdAt
              }
              registeredAt
              salesDate
              updatedAt
              createdAt
            }
            status
            registeredAt
            campaignBlockedAt
            note
            removedAt
            updatedAt
            createdAt
            points {
              items {
                id
                locationId
                storeId
                type
                name
                amount
                origin
                provider
                providerCustomerId
                providerSyncedAt
                currency
                point
                extraPoint
                extraExpire
                quantity
                note
                orderId
                referId
                orderNumberPrefix
                orderNumber
                customerId
                pointTransactionCustomerId
                expiredAt
                salesDate
                updatedAt
                createdAt
              }
              nextToken
            }
            credits {
              items {
                id
                locationId
                storeId
                name
                type
                amount
                origin
                note
                currency
                parentId
                orderId
                orderNumberPrefix
                orderNumber
                customerId
                creditTransactionCustomerId
                description
                salesDate
                createdAt
                updatedAt
              }
              nextToken
            }
            _delta
            orders {
              items {
                id
                orderLocationId
                numberPrefix
                number
                beeper
                enterpriseId
                storeId
                customerId
                orderCustomerId
                state
                amount
                amountPaid
                origin
                subOrigin
                originId
                customerProvider
                providerCustomerId
                amountChanged
                amountReturned
                tax
                currency
                quantity
                shippingType
                diningOption
                diningState
                retailOption
                retailState
                name
                phone
                address
                zip
                tableId
                tableIds
                parentDeviceId
                deviceId
                reserveDate
                pickupDate
                shippingDate
                createdEmployeeId
                createdDeviceId
                updatedDeviceId
                shippingLocationId
                confirmedEmployeeId
                confirmedAt
                createdAt
                paidAt
                salesDate
                returnedAt
                shippedAt
                returnShippedAt
                returnSalesDate
                updatedAt
                noticeCount
                visitorCount
                totalPoint
                pointSaved
                receiptId
                receiptNumber
                receiptCount
                alarmCustomerId
                receiptCustomerId
                note
                request
                fee
                revision
                _delta
              }
              nextToken
            }
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            store {
              id
              enterpriseId
              info {
                name
                phone
                email
                representative
                businessType
                businessNumber
                industryType
                sendNumber
                currency
                address
                zip
                csPhone
              }
              policy {
                multiLocation
              }
              currency
              payments {
                card
                cardJoined
                cash
                credit
                refund
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              taxes {
                id
                type
                name
                enabled
                rate
                method
                targetType
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              item {
                actualPrice
                skuMgmt
                barcodeMgmt
                stockTrack
                variablePrice
                locationCreatable
              }
              inventory {
                expiryDateMgmt
                expiryDateAlertDays
              }
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              kakao {
                agency
                key
                channelId
                categoryCode
                status
                updatedAt
                createdAt
              }
              review {
                status
                campaignId
                updatedAt
                createdAt
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              groups {
                nextToken
              }
              createdAt
              updatedAt
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              locations {
                nextToken
              }
            }
            customer {
              id
              ident
              identType
              type
              name
              addresses {
                id
                main
                detail
                storeId
              }
              phone
              email
              birth
              agreements {
                privacy
                provider
                eula
                marketing
                updatedAt
                createdAt
              }
              ageGroup
              gender
              updatedAt
              createdAt
              stores {
                nextToken
              }
            }
          }
        }
        nextToken
      }
      _delta
      orders {
        items {
          id
          orderLocationId
          numberPrefix
          number
          beeper
          enterpriseId
          storeId
          customerId
          orderCustomerId
          state
          amount
          amountPaid
          origin
          subOrigin
          originId
          customerProvider
          providerCustomerId
          amountChanged
          amountReturned
          tax
          currency
          quantity
          shippingType
          diningOption
          diningState
          retailOption
          retailState
          name
          phone
          address
          zip
          receivers {
            id
            name
            phone
            zip
            address1
            address2
            addressState
            addressCity
            addressStreet
            addressFull
            note
            shippingCompanyCode
            shippingAcceptCount
            shippingStartCount
            trackingNumber
            privacyAgreedAt
          }
          tableId
          tableIds
          parentDeviceId
          deviceId
          reserveDate
          pickupDate
          shippingDate
          items {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          createdEmployeeId
          createdDeviceId
          updatedDeviceId
          shippingLocationId
          confirmedEmployeeId
          confirmedAt
          createdAt
          paidAt
          salesDate
          returnedAt
          shippedAt
          returnShippedAt
          returnSalesDate
          updatedAt
          noticeCount
          visitorCount
          totalPoint
          pointSaved
          receiptId
          receiptNumber
          receiptCount
          alarmCustomerId
          receiptCustomerId
          note
          request
          charges {
            id
            method
            extraMethod
            gateway
            amount
            tax
            taxRefundAmount
            purchaser {
              name
              number
              nation
              address
            }
            supplyAmount
            taxFreeAmount
            amountRefunded
            createdEmployeeId
            createdDeviceId
            createdAt
            creditTransactionId
            balance
            splitType
            pg
            van
            origin
            originId
            note
            brand
            name
            ident
            installment
            transactionId
            approver
            issuerCode
            acquirer
            acquirerCode
            acquirerName
            refundable
            recurring
            approvalCode
            approvalDate
            approvalNumber
            signature {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            refunds {
              id
              amount
              supplyAmount
              taxFreeAmount
              tax
              taxRefundAmount
              creditTransactionId
              approvalNumber
              approvalDate
              transactionId
              origin
              originId
              currency
              reason
              signature {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              createdEmployeeId
              createdDeviceId
              createdAt
            }
          }
          returns {
            amount
            currency
            items {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            refundId
            createdAt
          }
          fee
          revision
          _delta
          location {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          customer {
            id
            customerId
            storeId
            sources {
              id
              type
              name
              ident
              createdAt
            }
            isFavorite
            locationId
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            favoriteLocationIds
            visitedLocationIds
            locations {
              id
              locationId
              isFavorite
              status
              note
              campaignBlockedAt
              blacklistedAt
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              subscriptions {
                id
                planId
                payStatus
                sessionId
                nextPayDate
                paidAt
                unsubscribedAt
                used
                lastUsedAt
                orderId
                unsubscribeRequestedAt
                startDate
                expireDate
                updatedAt
                createdAt
              }
              registeredAt
              salesDate
              updatedAt
              createdAt
            }
            status
            registeredAt
            campaignBlockedAt
            note
            removedAt
            updatedAt
            createdAt
            points {
              items {
                id
                locationId
                storeId
                type
                name
                amount
                origin
                provider
                providerCustomerId
                providerSyncedAt
                currency
                point
                extraPoint
                extraExpire
                quantity
                note
                orderId
                referId
                orderNumberPrefix
                orderNumber
                customerId
                pointTransactionCustomerId
                expiredAt
                salesDate
                updatedAt
                createdAt
              }
              nextToken
            }
            credits {
              items {
                id
                locationId
                storeId
                name
                type
                amount
                origin
                note
                currency
                parentId
                orderId
                orderNumberPrefix
                orderNumber
                customerId
                creditTransactionCustomerId
                description
                salesDate
                createdAt
                updatedAt
              }
              nextToken
            }
            _delta
            orders {
              items {
                id
                orderLocationId
                numberPrefix
                number
                beeper
                enterpriseId
                storeId
                customerId
                orderCustomerId
                state
                amount
                amountPaid
                origin
                subOrigin
                originId
                customerProvider
                providerCustomerId
                amountChanged
                amountReturned
                tax
                currency
                quantity
                shippingType
                diningOption
                diningState
                retailOption
                retailState
                name
                phone
                address
                zip
                tableId
                tableIds
                parentDeviceId
                deviceId
                reserveDate
                pickupDate
                shippingDate
                createdEmployeeId
                createdDeviceId
                updatedDeviceId
                shippingLocationId
                confirmedEmployeeId
                confirmedAt
                createdAt
                paidAt
                salesDate
                returnedAt
                shippedAt
                returnShippedAt
                returnSalesDate
                updatedAt
                noticeCount
                visitorCount
                totalPoint
                pointSaved
                receiptId
                receiptNumber
                receiptCount
                alarmCustomerId
                receiptCustomerId
                note
                request
                fee
                revision
                _delta
              }
              nextToken
            }
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            store {
              id
              enterpriseId
              info {
                name
                phone
                email
                representative
                businessType
                businessNumber
                industryType
                sendNumber
                currency
                address
                zip
                csPhone
              }
              policy {
                multiLocation
              }
              currency
              payments {
                card
                cardJoined
                cash
                credit
                refund
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              taxes {
                id
                type
                name
                enabled
                rate
                method
                targetType
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              item {
                actualPrice
                skuMgmt
                barcodeMgmt
                stockTrack
                variablePrice
                locationCreatable
              }
              inventory {
                expiryDateMgmt
                expiryDateAlertDays
              }
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              kakao {
                agency
                key
                channelId
                categoryCode
                status
                updatedAt
                createdAt
              }
              review {
                status
                campaignId
                updatedAt
                createdAt
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              groups {
                nextToken
              }
              createdAt
              updatedAt
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              locations {
                nextToken
              }
            }
            customer {
              id
              ident
              identType
              type
              name
              addresses {
                id
                main
                detail
                storeId
              }
              phone
              email
              birth
              agreements {
                privacy
                provider
                eula
                marketing
                updatedAt
                createdAt
              }
              ageGroup
              gender
              updatedAt
              createdAt
              stores {
                nextToken
              }
            }
          }
          orderCustomer {
            id
            enterpriseId
            storeId
            locationId
            customerId
            provider
            providerCustomerId
            tier
            customerCreatedAt
            ident
            identType
            type
            name
            nameKeyword
            payments {
              id
              method
              pg
              number
              name
              expMonth
              expYear
              billingKey
              updatedAt
              createdAt
            }
            attributes {
              name
              type
              value
            }
            addresses {
              id
              main
              detail
              storeId
            }
            phone
            email
            birth
            agreements {
              privacy
              provider
              eula
              marketing
              updatedAt
              createdAt
            }
            ageGroup
            gender
            tags {
              tagId
              createdLocationId
            }
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            status
            registeredAt
            salesDate
            campaignBlockedAt
            blacklistedAt
            isFavorite
            locations {
              id
              locationId
              isFavorite
              status
              note
              campaignBlockedAt
              blacklistedAt
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              subscriptions {
                id
                planId
                payStatus
                sessionId
                nextPayDate
                paidAt
                unsubscribedAt
                used
                lastUsedAt
                orderId
                unsubscribeRequestedAt
                startDate
                expireDate
                updatedAt
                createdAt
              }
              registeredAt
              salesDate
              updatedAt
              createdAt
            }
            subscriptions {
              id
              planId
              payStatus
              sessionId
              nextPayDate
              paidAt
              unsubscribedAt
              used
              lastUsedAt
              orderId
              unsubscribeRequestedAt
              startDate
              expireDate
              updatedAt
              createdAt
            }
            note
            sources {
              id
              type
              name
              ident
              createdAt
            }
            removedAt
            updatedAt
            createdAt
            _delta
            timestamp
            expdate
          }
        }
        nextToken
      }
      location {
        id
        name
        type
        provider
        address
        zip
        phone
        logo {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        representative
        businessNumber
        billing {
          key
          businessNumber
          name
          email
          phone
          contanctName
          contactName
          address
          zip
          card {
            pg
            number
            name
            expMonth
            expYear
            identify
            password
            billingKey
          }
        }
        credit {
          autoCharge
          autoChargeCredit
          autoChargeQuantity
          alertCredit
        }
        businessHours {
          sun {
            open
            close
          }
          mon {
            open
            close
          }
          tue {
            open
            close
          }
          wed {
            open
            close
          }
          thu {
            open
            close
          }
          fri {
            open
            close
          }
          sat {
            open
            close
          }
        }
        storeId
        state
        menuPlanId
        payments {
          refund
          barcode
          barcodeVan
          barcodeTerminalId
        }
        policy {
          locationCost
          loyaltyCost
        }
        loyalty {
          status
          provider
          sharePolicy
          type
          grade
          conditionType
          conditionSubType
          condition {
            minAmount
            perAmount
            minTime
            rate
            expirationPeriod
            excludeProdIds
            discountPointSavable
          }
          conditionItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          conditionTiers {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardType
          rewardSubType
          reward {
            requirePoint
            requireOrderAmount
            minUsePoint
            minRequireRate
            maxUsePoint
          }
          rewardItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardRates {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardAmounts {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardCashs {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          incentives {
            fulfill
            fulfillPoint
            fulfillCampaignId
            birth
            birthPoint
            birthCampaignId
            birthItem {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
          }
          notification {
            register
            registerCampainId
            save
            saveCampaignId
            use
            useCampaignId
            expire
            expireCampaignId
          }
        }
        kiosk {
          status
          callable
          payable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          exchangeTicket
          columnSize
          discounts {
            condition
            couponId
          }
          menuDescription
          updatedAt
          createdAt
        }
        receipt {
          salesSlip
          orderList
          barcode
          footnote
          phone
          orderNumberHide
          orderSheetFontSize
          billsBusinessInfo
          giftFootnote
          giftReceipt
        }
        menuOrder {
          callOptions {
            index
            label
          }
          orderable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          columnSize
          fontSize
          notice
          resetTime
          enableCash
          boardType
        }
        kds {
          layout
          alerts {
            urgent
            warning
          }
          disabledProdIds
          orderNotify
        }
        mini {
          defaultItemName
        }
        orderPlanId
        menu {
          board {
            textSize
            columnSize
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            enableFavorite
          }
          list {
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            shortcuts {
              type
              title
              itemName
              itemPrice
              active
            }
          }
          navOrder
        }
        menus {
          parentId
          position
          page
          pageTitle
        }
        favMenus {
          parentId
          position
          page
          pageTitle
        }
        catMenus {
          title
          index
          ids
          parentId
        }
        market {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          paused
          takeoutDiscount
          creditDiscount
          takeout
          intro
          notice
          bookTable
          bookTableNote
          depositAmount
          delivery
          deliveryFee {
            start
            end
            fee
          }
          waitingTime
          itemIds
          updatedAt
          createdAt
        }
        waiting {
          status
          waitingOptions {
            id
            type
            name
            nameMl {
              en
              zh
              ja
            }
            items {
              id
              name
              nameMl {
                en
                zh
                ja
              }
              limitQuantity
            }
            required
            updatedAt
            createdAt
          }
          waitingMinutes
          waitingMinutesHide
          hasForeigner
          foreignerMethod
          notifications {
            created
            ready
            requested
            cancelled
          }
          notices {
            createdNotice
            createdNoticeMl {
              en
              zh
              ja
            }
            requestedNotice
            requestedNoticeMl {
              en
              zh
              ja
            }
          }
          settings {
            autoCancel
            autoCancelMinutes
          }
          updatedAt
          createdAt
        }
        view {
          order {
            excludeColumns
            exportTemplates {
              name
              type
              headers {
                key
                label
              }
            }
          }
          itemReport {
            excludeColumns
          }
          summaryReport {
            excludeColumns
          }
          shippingOrder {
            excludeColumns
          }
          trsItemReport {
            excludeColumns
          }
        }
        subscription {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          updatedAt
          createdAt
        }
        settings {
          mode
          table
          diningOption
          retailOption
          orderAlarm
          orderConfirm
          addressInput
          autoPay
          autoReturnItem
          autoSoldout
          menuGridSize
          menuMode
          tableMode
          orderNotify
          orderNotifySkippable
          disablePhonePrefix
          autoCloseScreen
          orderNumberPrefix {
            pos
            kiosk
            menuOrder
          }
          orderMgmt {
            kiosk
            menuorder
            delivery
            pos
          }
          reportBasis
          orderOrigins
          beeper {
            active
            coverage
            skippable
          }
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
        }
        devices {
          id
          ident
          locationId
          system
          ipAddress
          brand
          model
          name
          type
          deviceType
          display {
            height
            width
          }
          protocol
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          barcodeReader
          appVersion
          sysVersion
          status
          password
          msgToken
          parentId
          childId
          sleepMode
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
          terminalId
          printOptions {
            port
            baudRate
            receipt
            receiptButtonHide
            bills
            refund
            salesEnd
            order
            orderBeep
            orderCount
            orderExcludeIds
            delivery
          }
          updatedAt
          createdAt
        }
        tables {
          id
          width
          height
          name
          axisX
          axisY
          screenHeight
          screenWidth
          initialX
          initialY
          shape
          layout {
            x
            y
            w
            h
          }
          color
          orderId
          disabled
        }
        boardTables {
          title
          index
          items {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
        }
        gridTables {
          id
          name
          orderId
          index
          page
        }
        modifierSoldoutIds
        delivery {
          baemin
          coupang
          yogiyo
          enableGenProd
          updatedAt
          createdAt
        }
        createdAt
        updatedAt
        sales {
          startedAt
          endedAt
          reserves
          actualBalance
          currency
          note
          estimateBalance
          detail {
            totalSales
            totalRefund
            totalTax
            totalDiscount
            supplyAmount
            totalSalesCount
            cash {
              sales
              refund
            }
            card {
              sales
              refund
            }
            credit {
              sales
              refund
            }
            barcode {
              sales
              refund
            }
            extra {
              sales
              refund
            }
            refund {
              sales
              refund
            }
            products {
              id
              name
              quantity
              amount
            }
            origins {
              pos
              kiosk
              menuorder
              delivery
              market
            }
          }
          reservesHistories {
            type
            amount
            note
            createdEmployeeId
            createdAt
          }
        }
      }
      store {
        id
        enterpriseId
        info {
          name
          phone
          email
          representative
          businessType
          businessNumber
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          industryType
          sendNumber
          currency
          address
          zip
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          csPhone
        }
        policy {
          multiLocation
          provider {
            key
            ident
            customer
            coupon
            inventory
            locationId
          }
        }
        currency
        payments {
          card
          cardJoined
          cash
          credit
          refund
        }
        devices {
          id
          ident
          locationId
          system
          ipAddress
          brand
          model
          name
          type
          deviceType
          display {
            height
            width
          }
          protocol
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          barcodeReader
          appVersion
          sysVersion
          status
          password
          msgToken
          parentId
          childId
          sleepMode
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
          terminalId
          printOptions {
            port
            baudRate
            receipt
            receiptButtonHide
            bills
            refund
            salesEnd
            order
            orderBeep
            orderCount
            orderExcludeIds
            delivery
          }
          updatedAt
          createdAt
        }
        plans {
          menus {
            id
            name
            mode
            size
            menus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            pages {
              index
              parentId
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              title
              titleMl {
                en
                zh
                ja
              }
            }
            updatedAt
            createdAt
          }
          orders {
            id
            name
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            updatedAt
            createdAt
          }
        }
        taxes {
          id
          type
          name
          enabled
          rate
          method
          targetType
        }
        receipt {
          salesSlip
          orderList
          barcode
          footnote
          phone
          orderNumberHide
          orderSheetFontSize
          billsBusinessInfo
          giftFootnote
          giftReceipt
        }
        subscription {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          updatedAt
          createdAt
        }
        view {
          order {
            excludeColumns
            exportTemplates {
              name
              type
              headers {
                key
                label
              }
            }
          }
          itemReport {
            excludeColumns
          }
          summaryReport {
            excludeColumns
          }
          shippingOrder {
            excludeColumns
          }
          trsItemReport {
            excludeColumns
          }
        }
        settings {
          mode
          table
          diningOption
          retailOption
          orderAlarm
          orderConfirm
          addressInput
          autoPay
          autoReturnItem
          autoSoldout
          menuGridSize
          menuMode
          tableMode
          orderNotify
          orderNotifySkippable
          disablePhonePrefix
          autoCloseScreen
          orderNumberPrefix {
            pos
            kiosk
            menuOrder
          }
          orderMgmt {
            kiosk
            menuorder
            delivery
            pos
          }
          reportBasis
          orderOrigins
          beeper {
            active
            coverage
            skippable
          }
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
        }
        item {
          attributes {
            name
            type
            value
          }
          actualPrice
          skuMgmt
          barcodeMgmt
          stockTrack
          variablePrice
          locationCreatable
        }
        inventory {
          attributes {
            name
            type
            value
          }
          expiryDateMgmt
          expiryDateAlertDays
        }
        customer {
          attributes {
            name
            type
            value
          }
        }
        billing {
          key
          businessNumber
          name
          email
          phone
          contanctName
          contactName
          address
          zip
          card {
            pg
            number
            name
            expMonth
            expYear
            identify
            password
            billingKey
          }
        }
        credit {
          autoCharge
          autoChargeCredit
          autoChargeQuantity
          alertCredit
        }
        kakao {
          agency
          key
          channelId
          categoryCode
          status
          updatedAt
          createdAt
        }
        review {
          status
          campaignId
          updatedAt
          createdAt
        }
        kiosk {
          status
          callable
          payable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          exchangeTicket
          columnSize
          discounts {
            condition
            couponId
          }
          menuDescription
          updatedAt
          createdAt
        }
        menuOrder {
          callOptions {
            index
            label
          }
          orderable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          columnSize
          fontSize
          notice
          resetTime
          enableCash
          boardType
        }
        kds {
          layout
          alerts {
            urgent
            warning
          }
          disabledProdIds
          orderNotify
        }
        mirror {
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          video {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
        }
        loyalty {
          status
          provider
          sharePolicy
          type
          grade
          conditionType
          conditionSubType
          condition {
            minAmount
            perAmount
            minTime
            rate
            expirationPeriod
            excludeProdIds
            discountPointSavable
          }
          conditionItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          conditionTiers {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardType
          rewardSubType
          reward {
            requirePoint
            requireOrderAmount
            minUsePoint
            minRequireRate
            maxUsePoint
          }
          rewardItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardRates {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardAmounts {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardCashs {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          incentives {
            fulfill
            fulfillPoint
            fulfillCampaignId
            birth
            birthPoint
            birthCampaignId
            birthItem {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
          }
          notification {
            register
            registerCampainId
            save
            saveCampaignId
            use
            useCampaignId
            expire
            expireCampaignId
          }
        }
        market {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          paused
          takeoutDiscount
          creditDiscount
          takeout
          intro
          notice
          bookTable
          bookTableNote
          depositAmount
          delivery
          deliveryFee {
            start
            end
            fee
          }
          waitingTime
          itemIds
          updatedAt
          createdAt
        }
        notification {
          options {
            type
            email
            sms
          }
        }
        groups {
          items {
            id
            name
            type
            storeGroupsId
            locationGroupsId
            key
            conditions {
              key
              object
              field
              operations {
                operation
                type
                value
              }
              value
              operation
              type
            }
            count
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        location {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        locations {
          items {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          nextToken
        }
      }
      customer {
        id
        ident
        identType
        type
        name
        addresses {
          id
          main
          detail
          storeId
        }
        phone
        email
        birth
        agreements {
          privacy
          provider
          eula
          marketing
          updatedAt
          createdAt
        }
        ageGroup
        gender
        updatedAt
        createdAt
        stores {
          items {
            id
            customerId
            storeId
            sources {
              id
              type
              name
              ident
              createdAt
            }
            isFavorite
            locationId
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            favoriteLocationIds
            visitedLocationIds
            locations {
              id
              locationId
              isFavorite
              status
              note
              campaignBlockedAt
              blacklistedAt
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              subscriptions {
                id
                planId
                payStatus
                sessionId
                nextPayDate
                paidAt
                unsubscribedAt
                used
                lastUsedAt
                orderId
                unsubscribeRequestedAt
                startDate
                expireDate
                updatedAt
                createdAt
              }
              registeredAt
              salesDate
              updatedAt
              createdAt
            }
            status
            registeredAt
            campaignBlockedAt
            note
            removedAt
            updatedAt
            createdAt
            points {
              items {
                id
                locationId
                storeId
                type
                name
                amount
                origin
                provider
                providerCustomerId
                providerSyncedAt
                currency
                point
                extraPoint
                extraExpire
                quantity
                note
                orderId
                referId
                orderNumberPrefix
                orderNumber
                customerId
                pointTransactionCustomerId
                expiredAt
                salesDate
                updatedAt
                createdAt
              }
              nextToken
            }
            credits {
              items {
                id
                locationId
                storeId
                name
                type
                amount
                origin
                note
                currency
                parentId
                orderId
                orderNumberPrefix
                orderNumber
                customerId
                creditTransactionCustomerId
                description
                salesDate
                createdAt
                updatedAt
              }
              nextToken
            }
            _delta
            orders {
              items {
                id
                orderLocationId
                numberPrefix
                number
                beeper
                enterpriseId
                storeId
                customerId
                orderCustomerId
                state
                amount
                amountPaid
                origin
                subOrigin
                originId
                customerProvider
                providerCustomerId
                amountChanged
                amountReturned
                tax
                currency
                quantity
                shippingType
                diningOption
                diningState
                retailOption
                retailState
                name
                phone
                address
                zip
                tableId
                tableIds
                parentDeviceId
                deviceId
                reserveDate
                pickupDate
                shippingDate
                createdEmployeeId
                createdDeviceId
                updatedDeviceId
                shippingLocationId
                confirmedEmployeeId
                confirmedAt
                createdAt
                paidAt
                salesDate
                returnedAt
                shippedAt
                returnShippedAt
                returnSalesDate
                updatedAt
                noticeCount
                visitorCount
                totalPoint
                pointSaved
                receiptId
                receiptNumber
                receiptCount
                alarmCustomerId
                receiptCustomerId
                note
                request
                fee
                revision
                _delta
              }
              nextToken
            }
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            store {
              id
              enterpriseId
              info {
                name
                phone
                email
                representative
                businessType
                businessNumber
                industryType
                sendNumber
                currency
                address
                zip
                csPhone
              }
              policy {
                multiLocation
              }
              currency
              payments {
                card
                cardJoined
                cash
                credit
                refund
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              taxes {
                id
                type
                name
                enabled
                rate
                method
                targetType
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              item {
                actualPrice
                skuMgmt
                barcodeMgmt
                stockTrack
                variablePrice
                locationCreatable
              }
              inventory {
                expiryDateMgmt
                expiryDateAlertDays
              }
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              kakao {
                agency
                key
                channelId
                categoryCode
                status
                updatedAt
                createdAt
              }
              review {
                status
                campaignId
                updatedAt
                createdAt
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              groups {
                nextToken
              }
              createdAt
              updatedAt
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              locations {
                nextToken
              }
            }
            customer {
              id
              ident
              identType
              type
              name
              addresses {
                id
                main
                detail
                storeId
              }
              phone
              email
              birth
              agreements {
                privacy
                provider
                eula
                marketing
                updatedAt
                createdAt
              }
              ageGroup
              gender
              updatedAt
              createdAt
              stores {
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteStoreCustomer = /* GraphQL */ `
  mutation DeleteStoreCustomer($input: DeleteStoreCustomerInput!) {
    deleteStoreCustomer(input: $input) {
      id
      customerId
      storeId
      sources {
        id
        type
        name
        ident
        createdAt
      }
      isFavorite
      locationId
      point {
        used
        saved
        extra
        lastSavedAt
        lastTransactedAt
      }
      credit {
        deposit
        withdraw
        balance
        lastTransactedAt
      }
      favoriteLocationIds
      visitedLocationIds
      locations {
        id
        locationId
        isFavorite
        status
        note
        campaignBlockedAt
        blacklistedAt
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        subscriptions {
          id
          planId
          payStatus
          sessionId
          nextPayDate
          paidAt
          unsubscribedAt
          used
          lastUsedAt
          orderId
          unsubscribeRequestedAt
          startDate
          expireDate
          updatedAt
          createdAt
        }
        registeredAt
        salesDate
        updatedAt
        createdAt
      }
      status
      registeredAt
      campaignBlockedAt
      note
      removedAt
      updatedAt
      createdAt
      points {
        items {
          id
          locationId
          storeId
          type
          name
          amount
          origin
          provider
          providerCustomerId
          providerSyncedAt
          currency
          point
          extraPoint
          extraExpire
          items {
            type
            itemId
            name
            parentId
            point
            amount
            quantity
          }
          quantity
          note
          orderId
          referId
          orderNumberPrefix
          orderNumber
          customerId
          pointTransactionCustomerId
          expiredAt
          salesDate
          updatedAt
          createdAt
          customer {
            id
            customerId
            storeId
            sources {
              id
              type
              name
              ident
              createdAt
            }
            isFavorite
            locationId
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            favoriteLocationIds
            visitedLocationIds
            locations {
              id
              locationId
              isFavorite
              status
              note
              campaignBlockedAt
              blacklistedAt
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              subscriptions {
                id
                planId
                payStatus
                sessionId
                nextPayDate
                paidAt
                unsubscribedAt
                used
                lastUsedAt
                orderId
                unsubscribeRequestedAt
                startDate
                expireDate
                updatedAt
                createdAt
              }
              registeredAt
              salesDate
              updatedAt
              createdAt
            }
            status
            registeredAt
            campaignBlockedAt
            note
            removedAt
            updatedAt
            createdAt
            points {
              items {
                id
                locationId
                storeId
                type
                name
                amount
                origin
                provider
                providerCustomerId
                providerSyncedAt
                currency
                point
                extraPoint
                extraExpire
                quantity
                note
                orderId
                referId
                orderNumberPrefix
                orderNumber
                customerId
                pointTransactionCustomerId
                expiredAt
                salesDate
                updatedAt
                createdAt
              }
              nextToken
            }
            credits {
              items {
                id
                locationId
                storeId
                name
                type
                amount
                origin
                note
                currency
                parentId
                orderId
                orderNumberPrefix
                orderNumber
                customerId
                creditTransactionCustomerId
                description
                salesDate
                createdAt
                updatedAt
              }
              nextToken
            }
            _delta
            orders {
              items {
                id
                orderLocationId
                numberPrefix
                number
                beeper
                enterpriseId
                storeId
                customerId
                orderCustomerId
                state
                amount
                amountPaid
                origin
                subOrigin
                originId
                customerProvider
                providerCustomerId
                amountChanged
                amountReturned
                tax
                currency
                quantity
                shippingType
                diningOption
                diningState
                retailOption
                retailState
                name
                phone
                address
                zip
                tableId
                tableIds
                parentDeviceId
                deviceId
                reserveDate
                pickupDate
                shippingDate
                createdEmployeeId
                createdDeviceId
                updatedDeviceId
                shippingLocationId
                confirmedEmployeeId
                confirmedAt
                createdAt
                paidAt
                salesDate
                returnedAt
                shippedAt
                returnShippedAt
                returnSalesDate
                updatedAt
                noticeCount
                visitorCount
                totalPoint
                pointSaved
                receiptId
                receiptNumber
                receiptCount
                alarmCustomerId
                receiptCustomerId
                note
                request
                fee
                revision
                _delta
              }
              nextToken
            }
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            store {
              id
              enterpriseId
              info {
                name
                phone
                email
                representative
                businessType
                businessNumber
                industryType
                sendNumber
                currency
                address
                zip
                csPhone
              }
              policy {
                multiLocation
              }
              currency
              payments {
                card
                cardJoined
                cash
                credit
                refund
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              taxes {
                id
                type
                name
                enabled
                rate
                method
                targetType
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              item {
                actualPrice
                skuMgmt
                barcodeMgmt
                stockTrack
                variablePrice
                locationCreatable
              }
              inventory {
                expiryDateMgmt
                expiryDateAlertDays
              }
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              kakao {
                agency
                key
                channelId
                categoryCode
                status
                updatedAt
                createdAt
              }
              review {
                status
                campaignId
                updatedAt
                createdAt
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              groups {
                nextToken
              }
              createdAt
              updatedAt
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              locations {
                nextToken
              }
            }
            customer {
              id
              ident
              identType
              type
              name
              addresses {
                id
                main
                detail
                storeId
              }
              phone
              email
              birth
              agreements {
                privacy
                provider
                eula
                marketing
                updatedAt
                createdAt
              }
              ageGroup
              gender
              updatedAt
              createdAt
              stores {
                nextToken
              }
            }
          }
        }
        nextToken
      }
      credits {
        items {
          id
          locationId
          storeId
          name
          type
          amount
          origin
          note
          currency
          parentId
          orderId
          orderNumberPrefix
          orderNumber
          customerId
          creditTransactionCustomerId
          description
          salesDate
          createdAt
          updatedAt
          customer {
            id
            customerId
            storeId
            sources {
              id
              type
              name
              ident
              createdAt
            }
            isFavorite
            locationId
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            favoriteLocationIds
            visitedLocationIds
            locations {
              id
              locationId
              isFavorite
              status
              note
              campaignBlockedAt
              blacklistedAt
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              subscriptions {
                id
                planId
                payStatus
                sessionId
                nextPayDate
                paidAt
                unsubscribedAt
                used
                lastUsedAt
                orderId
                unsubscribeRequestedAt
                startDate
                expireDate
                updatedAt
                createdAt
              }
              registeredAt
              salesDate
              updatedAt
              createdAt
            }
            status
            registeredAt
            campaignBlockedAt
            note
            removedAt
            updatedAt
            createdAt
            points {
              items {
                id
                locationId
                storeId
                type
                name
                amount
                origin
                provider
                providerCustomerId
                providerSyncedAt
                currency
                point
                extraPoint
                extraExpire
                quantity
                note
                orderId
                referId
                orderNumberPrefix
                orderNumber
                customerId
                pointTransactionCustomerId
                expiredAt
                salesDate
                updatedAt
                createdAt
              }
              nextToken
            }
            credits {
              items {
                id
                locationId
                storeId
                name
                type
                amount
                origin
                note
                currency
                parentId
                orderId
                orderNumberPrefix
                orderNumber
                customerId
                creditTransactionCustomerId
                description
                salesDate
                createdAt
                updatedAt
              }
              nextToken
            }
            _delta
            orders {
              items {
                id
                orderLocationId
                numberPrefix
                number
                beeper
                enterpriseId
                storeId
                customerId
                orderCustomerId
                state
                amount
                amountPaid
                origin
                subOrigin
                originId
                customerProvider
                providerCustomerId
                amountChanged
                amountReturned
                tax
                currency
                quantity
                shippingType
                diningOption
                diningState
                retailOption
                retailState
                name
                phone
                address
                zip
                tableId
                tableIds
                parentDeviceId
                deviceId
                reserveDate
                pickupDate
                shippingDate
                createdEmployeeId
                createdDeviceId
                updatedDeviceId
                shippingLocationId
                confirmedEmployeeId
                confirmedAt
                createdAt
                paidAt
                salesDate
                returnedAt
                shippedAt
                returnShippedAt
                returnSalesDate
                updatedAt
                noticeCount
                visitorCount
                totalPoint
                pointSaved
                receiptId
                receiptNumber
                receiptCount
                alarmCustomerId
                receiptCustomerId
                note
                request
                fee
                revision
                _delta
              }
              nextToken
            }
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            store {
              id
              enterpriseId
              info {
                name
                phone
                email
                representative
                businessType
                businessNumber
                industryType
                sendNumber
                currency
                address
                zip
                csPhone
              }
              policy {
                multiLocation
              }
              currency
              payments {
                card
                cardJoined
                cash
                credit
                refund
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              taxes {
                id
                type
                name
                enabled
                rate
                method
                targetType
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              item {
                actualPrice
                skuMgmt
                barcodeMgmt
                stockTrack
                variablePrice
                locationCreatable
              }
              inventory {
                expiryDateMgmt
                expiryDateAlertDays
              }
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              kakao {
                agency
                key
                channelId
                categoryCode
                status
                updatedAt
                createdAt
              }
              review {
                status
                campaignId
                updatedAt
                createdAt
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              groups {
                nextToken
              }
              createdAt
              updatedAt
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              locations {
                nextToken
              }
            }
            customer {
              id
              ident
              identType
              type
              name
              addresses {
                id
                main
                detail
                storeId
              }
              phone
              email
              birth
              agreements {
                privacy
                provider
                eula
                marketing
                updatedAt
                createdAt
              }
              ageGroup
              gender
              updatedAt
              createdAt
              stores {
                nextToken
              }
            }
          }
        }
        nextToken
      }
      _delta
      orders {
        items {
          id
          orderLocationId
          numberPrefix
          number
          beeper
          enterpriseId
          storeId
          customerId
          orderCustomerId
          state
          amount
          amountPaid
          origin
          subOrigin
          originId
          customerProvider
          providerCustomerId
          amountChanged
          amountReturned
          tax
          currency
          quantity
          shippingType
          diningOption
          diningState
          retailOption
          retailState
          name
          phone
          address
          zip
          receivers {
            id
            name
            phone
            zip
            address1
            address2
            addressState
            addressCity
            addressStreet
            addressFull
            note
            shippingCompanyCode
            shippingAcceptCount
            shippingStartCount
            trackingNumber
            privacyAgreedAt
          }
          tableId
          tableIds
          parentDeviceId
          deviceId
          reserveDate
          pickupDate
          shippingDate
          items {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          createdEmployeeId
          createdDeviceId
          updatedDeviceId
          shippingLocationId
          confirmedEmployeeId
          confirmedAt
          createdAt
          paidAt
          salesDate
          returnedAt
          shippedAt
          returnShippedAt
          returnSalesDate
          updatedAt
          noticeCount
          visitorCount
          totalPoint
          pointSaved
          receiptId
          receiptNumber
          receiptCount
          alarmCustomerId
          receiptCustomerId
          note
          request
          charges {
            id
            method
            extraMethod
            gateway
            amount
            tax
            taxRefundAmount
            purchaser {
              name
              number
              nation
              address
            }
            supplyAmount
            taxFreeAmount
            amountRefunded
            createdEmployeeId
            createdDeviceId
            createdAt
            creditTransactionId
            balance
            splitType
            pg
            van
            origin
            originId
            note
            brand
            name
            ident
            installment
            transactionId
            approver
            issuerCode
            acquirer
            acquirerCode
            acquirerName
            refundable
            recurring
            approvalCode
            approvalDate
            approvalNumber
            signature {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            refunds {
              id
              amount
              supplyAmount
              taxFreeAmount
              tax
              taxRefundAmount
              creditTransactionId
              approvalNumber
              approvalDate
              transactionId
              origin
              originId
              currency
              reason
              signature {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              createdEmployeeId
              createdDeviceId
              createdAt
            }
          }
          returns {
            amount
            currency
            items {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            refundId
            createdAt
          }
          fee
          revision
          _delta
          location {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          customer {
            id
            customerId
            storeId
            sources {
              id
              type
              name
              ident
              createdAt
            }
            isFavorite
            locationId
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            favoriteLocationIds
            visitedLocationIds
            locations {
              id
              locationId
              isFavorite
              status
              note
              campaignBlockedAt
              blacklistedAt
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              subscriptions {
                id
                planId
                payStatus
                sessionId
                nextPayDate
                paidAt
                unsubscribedAt
                used
                lastUsedAt
                orderId
                unsubscribeRequestedAt
                startDate
                expireDate
                updatedAt
                createdAt
              }
              registeredAt
              salesDate
              updatedAt
              createdAt
            }
            status
            registeredAt
            campaignBlockedAt
            note
            removedAt
            updatedAt
            createdAt
            points {
              items {
                id
                locationId
                storeId
                type
                name
                amount
                origin
                provider
                providerCustomerId
                providerSyncedAt
                currency
                point
                extraPoint
                extraExpire
                quantity
                note
                orderId
                referId
                orderNumberPrefix
                orderNumber
                customerId
                pointTransactionCustomerId
                expiredAt
                salesDate
                updatedAt
                createdAt
              }
              nextToken
            }
            credits {
              items {
                id
                locationId
                storeId
                name
                type
                amount
                origin
                note
                currency
                parentId
                orderId
                orderNumberPrefix
                orderNumber
                customerId
                creditTransactionCustomerId
                description
                salesDate
                createdAt
                updatedAt
              }
              nextToken
            }
            _delta
            orders {
              items {
                id
                orderLocationId
                numberPrefix
                number
                beeper
                enterpriseId
                storeId
                customerId
                orderCustomerId
                state
                amount
                amountPaid
                origin
                subOrigin
                originId
                customerProvider
                providerCustomerId
                amountChanged
                amountReturned
                tax
                currency
                quantity
                shippingType
                diningOption
                diningState
                retailOption
                retailState
                name
                phone
                address
                zip
                tableId
                tableIds
                parentDeviceId
                deviceId
                reserveDate
                pickupDate
                shippingDate
                createdEmployeeId
                createdDeviceId
                updatedDeviceId
                shippingLocationId
                confirmedEmployeeId
                confirmedAt
                createdAt
                paidAt
                salesDate
                returnedAt
                shippedAt
                returnShippedAt
                returnSalesDate
                updatedAt
                noticeCount
                visitorCount
                totalPoint
                pointSaved
                receiptId
                receiptNumber
                receiptCount
                alarmCustomerId
                receiptCustomerId
                note
                request
                fee
                revision
                _delta
              }
              nextToken
            }
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            store {
              id
              enterpriseId
              info {
                name
                phone
                email
                representative
                businessType
                businessNumber
                industryType
                sendNumber
                currency
                address
                zip
                csPhone
              }
              policy {
                multiLocation
              }
              currency
              payments {
                card
                cardJoined
                cash
                credit
                refund
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              taxes {
                id
                type
                name
                enabled
                rate
                method
                targetType
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              item {
                actualPrice
                skuMgmt
                barcodeMgmt
                stockTrack
                variablePrice
                locationCreatable
              }
              inventory {
                expiryDateMgmt
                expiryDateAlertDays
              }
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              kakao {
                agency
                key
                channelId
                categoryCode
                status
                updatedAt
                createdAt
              }
              review {
                status
                campaignId
                updatedAt
                createdAt
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              groups {
                nextToken
              }
              createdAt
              updatedAt
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              locations {
                nextToken
              }
            }
            customer {
              id
              ident
              identType
              type
              name
              addresses {
                id
                main
                detail
                storeId
              }
              phone
              email
              birth
              agreements {
                privacy
                provider
                eula
                marketing
                updatedAt
                createdAt
              }
              ageGroup
              gender
              updatedAt
              createdAt
              stores {
                nextToken
              }
            }
          }
          orderCustomer {
            id
            enterpriseId
            storeId
            locationId
            customerId
            provider
            providerCustomerId
            tier
            customerCreatedAt
            ident
            identType
            type
            name
            nameKeyword
            payments {
              id
              method
              pg
              number
              name
              expMonth
              expYear
              billingKey
              updatedAt
              createdAt
            }
            attributes {
              name
              type
              value
            }
            addresses {
              id
              main
              detail
              storeId
            }
            phone
            email
            birth
            agreements {
              privacy
              provider
              eula
              marketing
              updatedAt
              createdAt
            }
            ageGroup
            gender
            tags {
              tagId
              createdLocationId
            }
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            status
            registeredAt
            salesDate
            campaignBlockedAt
            blacklistedAt
            isFavorite
            locations {
              id
              locationId
              isFavorite
              status
              note
              campaignBlockedAt
              blacklistedAt
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              subscriptions {
                id
                planId
                payStatus
                sessionId
                nextPayDate
                paidAt
                unsubscribedAt
                used
                lastUsedAt
                orderId
                unsubscribeRequestedAt
                startDate
                expireDate
                updatedAt
                createdAt
              }
              registeredAt
              salesDate
              updatedAt
              createdAt
            }
            subscriptions {
              id
              planId
              payStatus
              sessionId
              nextPayDate
              paidAt
              unsubscribedAt
              used
              lastUsedAt
              orderId
              unsubscribeRequestedAt
              startDate
              expireDate
              updatedAt
              createdAt
            }
            note
            sources {
              id
              type
              name
              ident
              createdAt
            }
            removedAt
            updatedAt
            createdAt
            _delta
            timestamp
            expdate
          }
        }
        nextToken
      }
      location {
        id
        name
        type
        provider
        address
        zip
        phone
        logo {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        representative
        businessNumber
        billing {
          key
          businessNumber
          name
          email
          phone
          contanctName
          contactName
          address
          zip
          card {
            pg
            number
            name
            expMonth
            expYear
            identify
            password
            billingKey
          }
        }
        credit {
          autoCharge
          autoChargeCredit
          autoChargeQuantity
          alertCredit
        }
        businessHours {
          sun {
            open
            close
          }
          mon {
            open
            close
          }
          tue {
            open
            close
          }
          wed {
            open
            close
          }
          thu {
            open
            close
          }
          fri {
            open
            close
          }
          sat {
            open
            close
          }
        }
        storeId
        state
        menuPlanId
        payments {
          refund
          barcode
          barcodeVan
          barcodeTerminalId
        }
        policy {
          locationCost
          loyaltyCost
        }
        loyalty {
          status
          provider
          sharePolicy
          type
          grade
          conditionType
          conditionSubType
          condition {
            minAmount
            perAmount
            minTime
            rate
            expirationPeriod
            excludeProdIds
            discountPointSavable
          }
          conditionItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          conditionTiers {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardType
          rewardSubType
          reward {
            requirePoint
            requireOrderAmount
            minUsePoint
            minRequireRate
            maxUsePoint
          }
          rewardItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardRates {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardAmounts {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardCashs {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          incentives {
            fulfill
            fulfillPoint
            fulfillCampaignId
            birth
            birthPoint
            birthCampaignId
            birthItem {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
          }
          notification {
            register
            registerCampainId
            save
            saveCampaignId
            use
            useCampaignId
            expire
            expireCampaignId
          }
        }
        kiosk {
          status
          callable
          payable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          exchangeTicket
          columnSize
          discounts {
            condition
            couponId
          }
          menuDescription
          updatedAt
          createdAt
        }
        receipt {
          salesSlip
          orderList
          barcode
          footnote
          phone
          orderNumberHide
          orderSheetFontSize
          billsBusinessInfo
          giftFootnote
          giftReceipt
        }
        menuOrder {
          callOptions {
            index
            label
          }
          orderable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          columnSize
          fontSize
          notice
          resetTime
          enableCash
          boardType
        }
        kds {
          layout
          alerts {
            urgent
            warning
          }
          disabledProdIds
          orderNotify
        }
        mini {
          defaultItemName
        }
        orderPlanId
        menu {
          board {
            textSize
            columnSize
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            enableFavorite
          }
          list {
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            shortcuts {
              type
              title
              itemName
              itemPrice
              active
            }
          }
          navOrder
        }
        menus {
          parentId
          position
          page
          pageTitle
        }
        favMenus {
          parentId
          position
          page
          pageTitle
        }
        catMenus {
          title
          index
          ids
          parentId
        }
        market {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          paused
          takeoutDiscount
          creditDiscount
          takeout
          intro
          notice
          bookTable
          bookTableNote
          depositAmount
          delivery
          deliveryFee {
            start
            end
            fee
          }
          waitingTime
          itemIds
          updatedAt
          createdAt
        }
        waiting {
          status
          waitingOptions {
            id
            type
            name
            nameMl {
              en
              zh
              ja
            }
            items {
              id
              name
              nameMl {
                en
                zh
                ja
              }
              limitQuantity
            }
            required
            updatedAt
            createdAt
          }
          waitingMinutes
          waitingMinutesHide
          hasForeigner
          foreignerMethod
          notifications {
            created
            ready
            requested
            cancelled
          }
          notices {
            createdNotice
            createdNoticeMl {
              en
              zh
              ja
            }
            requestedNotice
            requestedNoticeMl {
              en
              zh
              ja
            }
          }
          settings {
            autoCancel
            autoCancelMinutes
          }
          updatedAt
          createdAt
        }
        view {
          order {
            excludeColumns
            exportTemplates {
              name
              type
              headers {
                key
                label
              }
            }
          }
          itemReport {
            excludeColumns
          }
          summaryReport {
            excludeColumns
          }
          shippingOrder {
            excludeColumns
          }
          trsItemReport {
            excludeColumns
          }
        }
        subscription {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          updatedAt
          createdAt
        }
        settings {
          mode
          table
          diningOption
          retailOption
          orderAlarm
          orderConfirm
          addressInput
          autoPay
          autoReturnItem
          autoSoldout
          menuGridSize
          menuMode
          tableMode
          orderNotify
          orderNotifySkippable
          disablePhonePrefix
          autoCloseScreen
          orderNumberPrefix {
            pos
            kiosk
            menuOrder
          }
          orderMgmt {
            kiosk
            menuorder
            delivery
            pos
          }
          reportBasis
          orderOrigins
          beeper {
            active
            coverage
            skippable
          }
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
        }
        devices {
          id
          ident
          locationId
          system
          ipAddress
          brand
          model
          name
          type
          deviceType
          display {
            height
            width
          }
          protocol
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          barcodeReader
          appVersion
          sysVersion
          status
          password
          msgToken
          parentId
          childId
          sleepMode
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
          terminalId
          printOptions {
            port
            baudRate
            receipt
            receiptButtonHide
            bills
            refund
            salesEnd
            order
            orderBeep
            orderCount
            orderExcludeIds
            delivery
          }
          updatedAt
          createdAt
        }
        tables {
          id
          width
          height
          name
          axisX
          axisY
          screenHeight
          screenWidth
          initialX
          initialY
          shape
          layout {
            x
            y
            w
            h
          }
          color
          orderId
          disabled
        }
        boardTables {
          title
          index
          items {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
        }
        gridTables {
          id
          name
          orderId
          index
          page
        }
        modifierSoldoutIds
        delivery {
          baemin
          coupang
          yogiyo
          enableGenProd
          updatedAt
          createdAt
        }
        createdAt
        updatedAt
        sales {
          startedAt
          endedAt
          reserves
          actualBalance
          currency
          note
          estimateBalance
          detail {
            totalSales
            totalRefund
            totalTax
            totalDiscount
            supplyAmount
            totalSalesCount
            cash {
              sales
              refund
            }
            card {
              sales
              refund
            }
            credit {
              sales
              refund
            }
            barcode {
              sales
              refund
            }
            extra {
              sales
              refund
            }
            refund {
              sales
              refund
            }
            products {
              id
              name
              quantity
              amount
            }
            origins {
              pos
              kiosk
              menuorder
              delivery
              market
            }
          }
          reservesHistories {
            type
            amount
            note
            createdEmployeeId
            createdAt
          }
        }
      }
      store {
        id
        enterpriseId
        info {
          name
          phone
          email
          representative
          businessType
          businessNumber
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          industryType
          sendNumber
          currency
          address
          zip
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          csPhone
        }
        policy {
          multiLocation
          provider {
            key
            ident
            customer
            coupon
            inventory
            locationId
          }
        }
        currency
        payments {
          card
          cardJoined
          cash
          credit
          refund
        }
        devices {
          id
          ident
          locationId
          system
          ipAddress
          brand
          model
          name
          type
          deviceType
          display {
            height
            width
          }
          protocol
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          barcodeReader
          appVersion
          sysVersion
          status
          password
          msgToken
          parentId
          childId
          sleepMode
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
          terminalId
          printOptions {
            port
            baudRate
            receipt
            receiptButtonHide
            bills
            refund
            salesEnd
            order
            orderBeep
            orderCount
            orderExcludeIds
            delivery
          }
          updatedAt
          createdAt
        }
        plans {
          menus {
            id
            name
            mode
            size
            menus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            pages {
              index
              parentId
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              title
              titleMl {
                en
                zh
                ja
              }
            }
            updatedAt
            createdAt
          }
          orders {
            id
            name
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            updatedAt
            createdAt
          }
        }
        taxes {
          id
          type
          name
          enabled
          rate
          method
          targetType
        }
        receipt {
          salesSlip
          orderList
          barcode
          footnote
          phone
          orderNumberHide
          orderSheetFontSize
          billsBusinessInfo
          giftFootnote
          giftReceipt
        }
        subscription {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          updatedAt
          createdAt
        }
        view {
          order {
            excludeColumns
            exportTemplates {
              name
              type
              headers {
                key
                label
              }
            }
          }
          itemReport {
            excludeColumns
          }
          summaryReport {
            excludeColumns
          }
          shippingOrder {
            excludeColumns
          }
          trsItemReport {
            excludeColumns
          }
        }
        settings {
          mode
          table
          diningOption
          retailOption
          orderAlarm
          orderConfirm
          addressInput
          autoPay
          autoReturnItem
          autoSoldout
          menuGridSize
          menuMode
          tableMode
          orderNotify
          orderNotifySkippable
          disablePhonePrefix
          autoCloseScreen
          orderNumberPrefix {
            pos
            kiosk
            menuOrder
          }
          orderMgmt {
            kiosk
            menuorder
            delivery
            pos
          }
          reportBasis
          orderOrigins
          beeper {
            active
            coverage
            skippable
          }
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
        }
        item {
          attributes {
            name
            type
            value
          }
          actualPrice
          skuMgmt
          barcodeMgmt
          stockTrack
          variablePrice
          locationCreatable
        }
        inventory {
          attributes {
            name
            type
            value
          }
          expiryDateMgmt
          expiryDateAlertDays
        }
        customer {
          attributes {
            name
            type
            value
          }
        }
        billing {
          key
          businessNumber
          name
          email
          phone
          contanctName
          contactName
          address
          zip
          card {
            pg
            number
            name
            expMonth
            expYear
            identify
            password
            billingKey
          }
        }
        credit {
          autoCharge
          autoChargeCredit
          autoChargeQuantity
          alertCredit
        }
        kakao {
          agency
          key
          channelId
          categoryCode
          status
          updatedAt
          createdAt
        }
        review {
          status
          campaignId
          updatedAt
          createdAt
        }
        kiosk {
          status
          callable
          payable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          exchangeTicket
          columnSize
          discounts {
            condition
            couponId
          }
          menuDescription
          updatedAt
          createdAt
        }
        menuOrder {
          callOptions {
            index
            label
          }
          orderable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          columnSize
          fontSize
          notice
          resetTime
          enableCash
          boardType
        }
        kds {
          layout
          alerts {
            urgent
            warning
          }
          disabledProdIds
          orderNotify
        }
        mirror {
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          video {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
        }
        loyalty {
          status
          provider
          sharePolicy
          type
          grade
          conditionType
          conditionSubType
          condition {
            minAmount
            perAmount
            minTime
            rate
            expirationPeriod
            excludeProdIds
            discountPointSavable
          }
          conditionItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          conditionTiers {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardType
          rewardSubType
          reward {
            requirePoint
            requireOrderAmount
            minUsePoint
            minRequireRate
            maxUsePoint
          }
          rewardItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardRates {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardAmounts {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardCashs {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          incentives {
            fulfill
            fulfillPoint
            fulfillCampaignId
            birth
            birthPoint
            birthCampaignId
            birthItem {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
          }
          notification {
            register
            registerCampainId
            save
            saveCampaignId
            use
            useCampaignId
            expire
            expireCampaignId
          }
        }
        market {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          paused
          takeoutDiscount
          creditDiscount
          takeout
          intro
          notice
          bookTable
          bookTableNote
          depositAmount
          delivery
          deliveryFee {
            start
            end
            fee
          }
          waitingTime
          itemIds
          updatedAt
          createdAt
        }
        notification {
          options {
            type
            email
            sms
          }
        }
        groups {
          items {
            id
            name
            type
            storeGroupsId
            locationGroupsId
            key
            conditions {
              key
              object
              field
              operations {
                operation
                type
                value
              }
              value
              operation
              type
            }
            count
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        location {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        locations {
          items {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          nextToken
        }
      }
      customer {
        id
        ident
        identType
        type
        name
        addresses {
          id
          main
          detail
          storeId
        }
        phone
        email
        birth
        agreements {
          privacy
          provider
          eula
          marketing
          updatedAt
          createdAt
        }
        ageGroup
        gender
        updatedAt
        createdAt
        stores {
          items {
            id
            customerId
            storeId
            sources {
              id
              type
              name
              ident
              createdAt
            }
            isFavorite
            locationId
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            favoriteLocationIds
            visitedLocationIds
            locations {
              id
              locationId
              isFavorite
              status
              note
              campaignBlockedAt
              blacklistedAt
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              subscriptions {
                id
                planId
                payStatus
                sessionId
                nextPayDate
                paidAt
                unsubscribedAt
                used
                lastUsedAt
                orderId
                unsubscribeRequestedAt
                startDate
                expireDate
                updatedAt
                createdAt
              }
              registeredAt
              salesDate
              updatedAt
              createdAt
            }
            status
            registeredAt
            campaignBlockedAt
            note
            removedAt
            updatedAt
            createdAt
            points {
              items {
                id
                locationId
                storeId
                type
                name
                amount
                origin
                provider
                providerCustomerId
                providerSyncedAt
                currency
                point
                extraPoint
                extraExpire
                quantity
                note
                orderId
                referId
                orderNumberPrefix
                orderNumber
                customerId
                pointTransactionCustomerId
                expiredAt
                salesDate
                updatedAt
                createdAt
              }
              nextToken
            }
            credits {
              items {
                id
                locationId
                storeId
                name
                type
                amount
                origin
                note
                currency
                parentId
                orderId
                orderNumberPrefix
                orderNumber
                customerId
                creditTransactionCustomerId
                description
                salesDate
                createdAt
                updatedAt
              }
              nextToken
            }
            _delta
            orders {
              items {
                id
                orderLocationId
                numberPrefix
                number
                beeper
                enterpriseId
                storeId
                customerId
                orderCustomerId
                state
                amount
                amountPaid
                origin
                subOrigin
                originId
                customerProvider
                providerCustomerId
                amountChanged
                amountReturned
                tax
                currency
                quantity
                shippingType
                diningOption
                diningState
                retailOption
                retailState
                name
                phone
                address
                zip
                tableId
                tableIds
                parentDeviceId
                deviceId
                reserveDate
                pickupDate
                shippingDate
                createdEmployeeId
                createdDeviceId
                updatedDeviceId
                shippingLocationId
                confirmedEmployeeId
                confirmedAt
                createdAt
                paidAt
                salesDate
                returnedAt
                shippedAt
                returnShippedAt
                returnSalesDate
                updatedAt
                noticeCount
                visitorCount
                totalPoint
                pointSaved
                receiptId
                receiptNumber
                receiptCount
                alarmCustomerId
                receiptCustomerId
                note
                request
                fee
                revision
                _delta
              }
              nextToken
            }
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            store {
              id
              enterpriseId
              info {
                name
                phone
                email
                representative
                businessType
                businessNumber
                industryType
                sendNumber
                currency
                address
                zip
                csPhone
              }
              policy {
                multiLocation
              }
              currency
              payments {
                card
                cardJoined
                cash
                credit
                refund
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              taxes {
                id
                type
                name
                enabled
                rate
                method
                targetType
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              item {
                actualPrice
                skuMgmt
                barcodeMgmt
                stockTrack
                variablePrice
                locationCreatable
              }
              inventory {
                expiryDateMgmt
                expiryDateAlertDays
              }
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              kakao {
                agency
                key
                channelId
                categoryCode
                status
                updatedAt
                createdAt
              }
              review {
                status
                campaignId
                updatedAt
                createdAt
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              groups {
                nextToken
              }
              createdAt
              updatedAt
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              locations {
                nextToken
              }
            }
            customer {
              id
              ident
              identType
              type
              name
              addresses {
                id
                main
                detail
                storeId
              }
              phone
              email
              birth
              agreements {
                privacy
                provider
                eula
                marketing
                updatedAt
                createdAt
              }
              ageGroup
              gender
              updatedAt
              createdAt
              stores {
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      id
      ident
      identType
      type
      name
      addresses {
        id
        main
        detail
        storeId
      }
      phone
      email
      birth
      agreements {
        privacy
        provider
        eula
        marketing
        updatedAt
        createdAt
      }
      ageGroup
      gender
      updatedAt
      createdAt
      stores {
        items {
          id
          customerId
          storeId
          sources {
            id
            type
            name
            ident
            createdAt
          }
          isFavorite
          locationId
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          favoriteLocationIds
          visitedLocationIds
          locations {
            id
            locationId
            isFavorite
            status
            note
            campaignBlockedAt
            blacklistedAt
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            subscriptions {
              id
              planId
              payStatus
              sessionId
              nextPayDate
              paidAt
              unsubscribedAt
              used
              lastUsedAt
              orderId
              unsubscribeRequestedAt
              startDate
              expireDate
              updatedAt
              createdAt
            }
            registeredAt
            salesDate
            updatedAt
            createdAt
          }
          status
          registeredAt
          campaignBlockedAt
          note
          removedAt
          updatedAt
          createdAt
          points {
            items {
              id
              locationId
              storeId
              type
              name
              amount
              origin
              provider
              providerCustomerId
              providerSyncedAt
              currency
              point
              extraPoint
              extraExpire
              items {
                type
                itemId
                name
                parentId
                point
                amount
                quantity
              }
              quantity
              note
              orderId
              referId
              orderNumberPrefix
              orderNumber
              customerId
              pointTransactionCustomerId
              expiredAt
              salesDate
              updatedAt
              createdAt
              customer {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
            }
            nextToken
          }
          credits {
            items {
              id
              locationId
              storeId
              name
              type
              amount
              origin
              note
              currency
              parentId
              orderId
              orderNumberPrefix
              orderNumber
              customerId
              creditTransactionCustomerId
              description
              salesDate
              createdAt
              updatedAt
              customer {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
            }
            nextToken
          }
          _delta
          orders {
            items {
              id
              orderLocationId
              numberPrefix
              number
              beeper
              enterpriseId
              storeId
              customerId
              orderCustomerId
              state
              amount
              amountPaid
              origin
              subOrigin
              originId
              customerProvider
              providerCustomerId
              amountChanged
              amountReturned
              tax
              currency
              quantity
              shippingType
              diningOption
              diningState
              retailOption
              retailState
              name
              phone
              address
              zip
              receivers {
                id
                name
                phone
                zip
                address1
                address2
                addressState
                addressCity
                addressStreet
                addressFull
                note
                shippingCompanyCode
                shippingAcceptCount
                shippingStartCount
                trackingNumber
                privacyAgreedAt
              }
              tableId
              tableIds
              parentDeviceId
              deviceId
              reserveDate
              pickupDate
              shippingDate
              items {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              createdEmployeeId
              createdDeviceId
              updatedDeviceId
              shippingLocationId
              confirmedEmployeeId
              confirmedAt
              createdAt
              paidAt
              salesDate
              returnedAt
              shippedAt
              returnShippedAt
              returnSalesDate
              updatedAt
              noticeCount
              visitorCount
              totalPoint
              pointSaved
              receiptId
              receiptNumber
              receiptCount
              alarmCustomerId
              receiptCustomerId
              note
              request
              charges {
                id
                method
                extraMethod
                gateway
                amount
                tax
                taxRefundAmount
                supplyAmount
                taxFreeAmount
                amountRefunded
                createdEmployeeId
                createdDeviceId
                createdAt
                creditTransactionId
                balance
                splitType
                pg
                van
                origin
                originId
                note
                brand
                name
                ident
                installment
                transactionId
                approver
                issuerCode
                acquirer
                acquirerCode
                acquirerName
                refundable
                recurring
                approvalCode
                approvalDate
                approvalNumber
              }
              returns {
                amount
                currency
                refundId
                createdAt
              }
              fee
              revision
              _delta
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              customer {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
              orderCustomer {
                id
                enterpriseId
                storeId
                locationId
                customerId
                provider
                providerCustomerId
                tier
                customerCreatedAt
                ident
                identType
                type
                name
                nameKeyword
                phone
                email
                birth
                ageGroup
                gender
                status
                registeredAt
                salesDate
                campaignBlockedAt
                blacklistedAt
                isFavorite
                note
                removedAt
                updatedAt
                createdAt
                _delta
                timestamp
                expdate
              }
            }
            nextToken
          }
          location {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          store {
            id
            enterpriseId
            info {
              name
              phone
              email
              representative
              businessType
              businessNumber
              industryType
              sendNumber
              currency
              address
              zip
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              csPhone
            }
            policy {
              multiLocation
              provider {
                key
                ident
                customer
                coupon
                inventory
                locationId
              }
            }
            currency
            payments {
              card
              cardJoined
              cash
              credit
              refund
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            plans {
              menus {
                id
                name
                mode
                size
                updatedAt
                createdAt
              }
              orders {
                id
                name
                updatedAt
                createdAt
              }
            }
            taxes {
              id
              type
              name
              enabled
              rate
              method
              targetType
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            item {
              attributes {
                name
                type
                value
              }
              actualPrice
              skuMgmt
              barcodeMgmt
              stockTrack
              variablePrice
              locationCreatable
            }
            inventory {
              attributes {
                name
                type
                value
              }
              expiryDateMgmt
              expiryDateAlertDays
            }
            customer {
              attributes {
                name
                type
                value
              }
            }
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            kakao {
              agency
              key
              channelId
              categoryCode
              status
              updatedAt
              createdAt
            }
            review {
              status
              campaignId
              updatedAt
              createdAt
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mirror {
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              video {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            notification {
              options {
                type
                email
                sms
              }
            }
            groups {
              items {
                id
                name
                type
                storeGroupsId
                locationGroupsId
                key
                count
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            locations {
              items {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          customer {
            id
            ident
            identType
            type
            name
            addresses {
              id
              main
              detail
              storeId
            }
            phone
            email
            birth
            agreements {
              privacy
              provider
              eula
              marketing
              updatedAt
              createdAt
            }
            ageGroup
            gender
            updatedAt
            createdAt
            stores {
              items {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer($input: DeleteCustomerInput!) {
    deleteCustomer(input: $input) {
      id
      ident
      identType
      type
      name
      addresses {
        id
        main
        detail
        storeId
      }
      phone
      email
      birth
      agreements {
        privacy
        provider
        eula
        marketing
        updatedAt
        createdAt
      }
      ageGroup
      gender
      updatedAt
      createdAt
      stores {
        items {
          id
          customerId
          storeId
          sources {
            id
            type
            name
            ident
            createdAt
          }
          isFavorite
          locationId
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          favoriteLocationIds
          visitedLocationIds
          locations {
            id
            locationId
            isFavorite
            status
            note
            campaignBlockedAt
            blacklistedAt
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            subscriptions {
              id
              planId
              payStatus
              sessionId
              nextPayDate
              paidAt
              unsubscribedAt
              used
              lastUsedAt
              orderId
              unsubscribeRequestedAt
              startDate
              expireDate
              updatedAt
              createdAt
            }
            registeredAt
            salesDate
            updatedAt
            createdAt
          }
          status
          registeredAt
          campaignBlockedAt
          note
          removedAt
          updatedAt
          createdAt
          points {
            items {
              id
              locationId
              storeId
              type
              name
              amount
              origin
              provider
              providerCustomerId
              providerSyncedAt
              currency
              point
              extraPoint
              extraExpire
              items {
                type
                itemId
                name
                parentId
                point
                amount
                quantity
              }
              quantity
              note
              orderId
              referId
              orderNumberPrefix
              orderNumber
              customerId
              pointTransactionCustomerId
              expiredAt
              salesDate
              updatedAt
              createdAt
              customer {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
            }
            nextToken
          }
          credits {
            items {
              id
              locationId
              storeId
              name
              type
              amount
              origin
              note
              currency
              parentId
              orderId
              orderNumberPrefix
              orderNumber
              customerId
              creditTransactionCustomerId
              description
              salesDate
              createdAt
              updatedAt
              customer {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
            }
            nextToken
          }
          _delta
          orders {
            items {
              id
              orderLocationId
              numberPrefix
              number
              beeper
              enterpriseId
              storeId
              customerId
              orderCustomerId
              state
              amount
              amountPaid
              origin
              subOrigin
              originId
              customerProvider
              providerCustomerId
              amountChanged
              amountReturned
              tax
              currency
              quantity
              shippingType
              diningOption
              diningState
              retailOption
              retailState
              name
              phone
              address
              zip
              receivers {
                id
                name
                phone
                zip
                address1
                address2
                addressState
                addressCity
                addressStreet
                addressFull
                note
                shippingCompanyCode
                shippingAcceptCount
                shippingStartCount
                trackingNumber
                privacyAgreedAt
              }
              tableId
              tableIds
              parentDeviceId
              deviceId
              reserveDate
              pickupDate
              shippingDate
              items {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              createdEmployeeId
              createdDeviceId
              updatedDeviceId
              shippingLocationId
              confirmedEmployeeId
              confirmedAt
              createdAt
              paidAt
              salesDate
              returnedAt
              shippedAt
              returnShippedAt
              returnSalesDate
              updatedAt
              noticeCount
              visitorCount
              totalPoint
              pointSaved
              receiptId
              receiptNumber
              receiptCount
              alarmCustomerId
              receiptCustomerId
              note
              request
              charges {
                id
                method
                extraMethod
                gateway
                amount
                tax
                taxRefundAmount
                supplyAmount
                taxFreeAmount
                amountRefunded
                createdEmployeeId
                createdDeviceId
                createdAt
                creditTransactionId
                balance
                splitType
                pg
                van
                origin
                originId
                note
                brand
                name
                ident
                installment
                transactionId
                approver
                issuerCode
                acquirer
                acquirerCode
                acquirerName
                refundable
                recurring
                approvalCode
                approvalDate
                approvalNumber
              }
              returns {
                amount
                currency
                refundId
                createdAt
              }
              fee
              revision
              _delta
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              customer {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
              orderCustomer {
                id
                enterpriseId
                storeId
                locationId
                customerId
                provider
                providerCustomerId
                tier
                customerCreatedAt
                ident
                identType
                type
                name
                nameKeyword
                phone
                email
                birth
                ageGroup
                gender
                status
                registeredAt
                salesDate
                campaignBlockedAt
                blacklistedAt
                isFavorite
                note
                removedAt
                updatedAt
                createdAt
                _delta
                timestamp
                expdate
              }
            }
            nextToken
          }
          location {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          store {
            id
            enterpriseId
            info {
              name
              phone
              email
              representative
              businessType
              businessNumber
              industryType
              sendNumber
              currency
              address
              zip
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              csPhone
            }
            policy {
              multiLocation
              provider {
                key
                ident
                customer
                coupon
                inventory
                locationId
              }
            }
            currency
            payments {
              card
              cardJoined
              cash
              credit
              refund
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            plans {
              menus {
                id
                name
                mode
                size
                updatedAt
                createdAt
              }
              orders {
                id
                name
                updatedAt
                createdAt
              }
            }
            taxes {
              id
              type
              name
              enabled
              rate
              method
              targetType
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            item {
              attributes {
                name
                type
                value
              }
              actualPrice
              skuMgmt
              barcodeMgmt
              stockTrack
              variablePrice
              locationCreatable
            }
            inventory {
              attributes {
                name
                type
                value
              }
              expiryDateMgmt
              expiryDateAlertDays
            }
            customer {
              attributes {
                name
                type
                value
              }
            }
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            kakao {
              agency
              key
              channelId
              categoryCode
              status
              updatedAt
              createdAt
            }
            review {
              status
              campaignId
              updatedAt
              createdAt
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mirror {
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              video {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            notification {
              options {
                type
                email
                sms
              }
            }
            groups {
              items {
                id
                name
                type
                storeGroupsId
                locationGroupsId
                key
                count
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            locations {
              items {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          customer {
            id
            ident
            identType
            type
            name
            addresses {
              id
              main
              detail
              storeId
            }
            phone
            email
            birth
            agreements {
              privacy
              provider
              eula
              marketing
              updatedAt
              createdAt
            }
            ageGroup
            gender
            updatedAt
            createdAt
            stores {
              items {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createSalesServiceHistory = /* GraphQL */ `
  mutation CreateSalesServiceHistory($input: CreateSalesServiceHistoryInput!) {
    createSalesServiceHistory(input: $input) {
      storeId
      type
      status
      locationId
      quantity
      referId
      updatedAt
      createdAt
    }
  }
`;
export const updateSalesServiceHistory = /* GraphQL */ `
  mutation UpdateSalesServiceHistory($input: UpdateSalesServiceHistoryInput!) {
    updateSalesServiceHistory(input: $input) {
      storeId
      type
      status
      locationId
      quantity
      referId
      updatedAt
      createdAt
    }
  }
`;
export const deleteSalesServiceHistory = /* GraphQL */ `
  mutation DeleteSalesServiceHistory($input: DeleteSalesServiceHistoryInput!) {
    deleteSalesServiceHistory(input: $input) {
      storeId
      type
      status
      locationId
      quantity
      referId
      updatedAt
      createdAt
    }
  }
`;
export const createLoyaltyHistory = /* GraphQL */ `
  mutation CreateLoyaltyHistory($input: CreateLoyaltyHistoryInput!) {
    createLoyaltyHistory(input: $input) {
      storeId
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateLoyaltyHistory = /* GraphQL */ `
  mutation UpdateLoyaltyHistory($input: UpdateLoyaltyHistoryInput!) {
    updateLoyaltyHistory(input: $input) {
      storeId
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteLoyaltyHistory = /* GraphQL */ `
  mutation DeleteLoyaltyHistory($input: DeleteLoyaltyHistoryInput!) {
    deleteLoyaltyHistory(input: $input) {
      storeId
      status
      createdAt
      updatedAt
    }
  }
`;
export const createConfirmation = /* GraphQL */ `
  mutation CreateConfirmation($input: CreateConfirmationInput!) {
    createConfirmation(input: $input) {
      phone
      code
      expiredAt
      createdAt
      updatedAt
    }
  }
`;
export const updateConfirmation = /* GraphQL */ `
  mutation UpdateConfirmation($input: UpdateConfirmationInput!) {
    updateConfirmation(input: $input) {
      phone
      code
      expiredAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteConfirmation = /* GraphQL */ `
  mutation DeleteConfirmation($input: DeleteConfirmationInput!) {
    deleteConfirmation(input: $input) {
      phone
      code
      expiredAt
      createdAt
      updatedAt
    }
  }
`;
export const createArticle = /* GraphQL */ `
  mutation CreateArticle($input: CreateArticleInput!) {
    createArticle(input: $input) {
      id
      category
      createdAt
      parentId
      url
      title
      description
      updatedAt
    }
  }
`;
export const updateArticle = /* GraphQL */ `
  mutation UpdateArticle($input: UpdateArticleInput!) {
    updateArticle(input: $input) {
      id
      category
      createdAt
      parentId
      url
      title
      description
      updatedAt
    }
  }
`;
export const deleteArticle = /* GraphQL */ `
  mutation DeleteArticle($input: DeleteArticleInput!) {
    deleteArticle(input: $input) {
      id
      category
      createdAt
      parentId
      url
      title
      description
      updatedAt
    }
  }
`;
export const createNotice = /* GraphQL */ `
  mutation CreateNotice($input: CreateNoticeInput!) {
    createNotice(input: $input) {
      id
      enterpriseId
      storeId
      status
      title
      description
      attachment {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      parentId
      updatedAt
      createdAt
    }
  }
`;
export const updateNotice = /* GraphQL */ `
  mutation UpdateNotice($input: UpdateNoticeInput!) {
    updateNotice(input: $input) {
      id
      enterpriseId
      storeId
      status
      title
      description
      attachment {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      parentId
      updatedAt
      createdAt
    }
  }
`;
export const deleteNotice = /* GraphQL */ `
  mutation DeleteNotice($input: DeleteNoticeInput!) {
    deleteNotice(input: $input) {
      id
      enterpriseId
      storeId
      status
      title
      description
      attachment {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      parentId
      updatedAt
      createdAt
    }
  }
`;
export const createNoticeCampaignResult = /* GraphQL */ `
  mutation CreateNoticeCampaignResult(
    $input: CreateNoticeCampaignResultInput!
  ) {
    createNoticeCampaignResult(input: $input) {
      id
      recipientStoreId
      recipientLocationId
      campaignId
      noticeId
      notice {
        id
        enterpriseId
        storeId
        status
        title
        description
        attachment {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        parentId
        updatedAt
        createdAt
      }
      read
      updatedAt
      createdAt
    }
  }
`;
export const updateNoticeCampaignResult = /* GraphQL */ `
  mutation UpdateNoticeCampaignResult(
    $input: UpdateNoticeCampaignResultInput!
  ) {
    updateNoticeCampaignResult(input: $input) {
      id
      recipientStoreId
      recipientLocationId
      campaignId
      noticeId
      notice {
        id
        enterpriseId
        storeId
        status
        title
        description
        attachment {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        parentId
        updatedAt
        createdAt
      }
      read
      updatedAt
      createdAt
    }
  }
`;
export const deleteNoticeCampaignResult = /* GraphQL */ `
  mutation DeleteNoticeCampaignResult(
    $input: DeleteNoticeCampaignResultInput!
  ) {
    deleteNoticeCampaignResult(input: $input) {
      id
      recipientStoreId
      recipientLocationId
      campaignId
      noticeId
      notice {
        id
        enterpriseId
        storeId
        status
        title
        description
        attachment {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        parentId
        updatedAt
        createdAt
      }
      read
      updatedAt
      createdAt
    }
  }
`;
export const createNoticeCampaign = /* GraphQL */ `
  mutation CreateNoticeCampaign($input: CreateNoticeCampaignInput!) {
    createNoticeCampaign(input: $input) {
      id
      enterpriseId
      storeId
      status
      title
      reserveDate
      sendNow
      noticeId
      notice {
        id
        enterpriseId
        storeId
        status
        title
        description
        attachment {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        parentId
        updatedAt
        createdAt
      }
      locationIds
      updatedAt
      createdAt
    }
  }
`;
export const updateNoticeCampaign = /* GraphQL */ `
  mutation UpdateNoticeCampaign($input: UpdateNoticeCampaignInput!) {
    updateNoticeCampaign(input: $input) {
      id
      enterpriseId
      storeId
      status
      title
      reserveDate
      sendNow
      noticeId
      notice {
        id
        enterpriseId
        storeId
        status
        title
        description
        attachment {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        parentId
        updatedAt
        createdAt
      }
      locationIds
      updatedAt
      createdAt
    }
  }
`;
export const deleteNoticeCampaign = /* GraphQL */ `
  mutation DeleteNoticeCampaign($input: DeleteNoticeCampaignInput!) {
    deleteNoticeCampaign(input: $input) {
      id
      enterpriseId
      storeId
      status
      title
      reserveDate
      sendNow
      noticeId
      notice {
        id
        enterpriseId
        storeId
        status
        title
        description
        attachment {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        parentId
        updatedAt
        createdAt
      }
      locationIds
      updatedAt
      createdAt
    }
  }
`;
export const createOnoffConfig = /* GraphQL */ `
  mutation CreateOnoffConfig($input: CreateOnoffConfigInput!) {
    createOnoffConfig(input: $input) {
      storeId
      stage
      logo {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      tierBenefits {
        id
        description
      }
      theme {
        primaryColor
        secondaryColor
        templateCode
      }
      item {
        stockInfo
      }
      banners {
        position
        url
        image {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
      }
      updatedAt
      createdAt
    }
  }
`;
export const updateOnoffConfig = /* GraphQL */ `
  mutation UpdateOnoffConfig($input: UpdateOnoffConfigInput!) {
    updateOnoffConfig(input: $input) {
      storeId
      stage
      logo {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      tierBenefits {
        id
        description
      }
      theme {
        primaryColor
        secondaryColor
        templateCode
      }
      item {
        stockInfo
      }
      banners {
        position
        url
        image {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
      }
      updatedAt
      createdAt
    }
  }
`;
export const deleteOnoffConfig = /* GraphQL */ `
  mutation DeleteOnoffConfig($input: DeleteOnoffConfigInput!) {
    deleteOnoffConfig(input: $input) {
      storeId
      stage
      logo {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      tierBenefits {
        id
        description
      }
      theme {
        primaryColor
        secondaryColor
        templateCode
      }
      item {
        stockInfo
      }
      banners {
        position
        url
        image {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
      }
      updatedAt
      createdAt
    }
  }
`;
export const createPaymentTerminal = /* GraphQL */ `
  mutation CreatePaymentTerminal($input: CreatePaymentTerminalInput!) {
    createPaymentTerminal(input: $input) {
      id
      businessNumber
      businessOperatorId
      storeId
      locationId
      serial
      model
      brand
      updatedAt
      createdAt
    }
  }
`;
export const updatePaymentTerminal = /* GraphQL */ `
  mutation UpdatePaymentTerminal($input: UpdatePaymentTerminalInput!) {
    updatePaymentTerminal(input: $input) {
      id
      businessNumber
      businessOperatorId
      storeId
      locationId
      serial
      model
      brand
      updatedAt
      createdAt
    }
  }
`;
export const deletePaymentTerminal = /* GraphQL */ `
  mutation DeletePaymentTerminal($input: DeletePaymentTerminalInput!) {
    deletePaymentTerminal(input: $input) {
      id
      businessNumber
      businessOperatorId
      storeId
      locationId
      serial
      model
      brand
      updatedAt
      createdAt
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      id
      orderLocationId
      numberPrefix
      number
      beeper
      enterpriseId
      storeId
      customerId
      orderCustomerId
      state
      amount
      amountPaid
      origin
      subOrigin
      originId
      customerProvider
      providerCustomerId
      amountChanged
      amountReturned
      tax
      currency
      quantity
      shippingType
      diningOption
      diningState
      retailOption
      retailState
      name
      phone
      address
      zip
      receivers {
        id
        name
        phone
        zip
        address1
        address2
        addressState
        addressCity
        addressStreet
        addressFull
        note
        shippingCompanyCode
        shippingAcceptCount
        shippingStartCount
        trackingNumber
        privacyAgreedAt
      }
      tableId
      tableIds
      parentDeviceId
      deviceId
      reserveDate
      pickupDate
      shippingDate
      items {
        id
        type
        index
        category
        categories {
          id
          name
        }
        classificationIds
        productId
        modifierId
        name
        description
        amount
        price
        parentId
        referType
        referId
        campaignId
        requestId
        quantity
        sku
        tax
        taxRate
        diningOption
        diningState
        modifiers {
          id
          type
          index
          category
          categories {
            id
            name
          }
          classificationIds
          productId
          modifierId
          name
          description
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        discounts {
          id
          scope
          type
          amount
          name
        }
        discountable
        discountQuantity
        discountIncludeIds
        discountExcludeIds
        discountMethod
        discountValue
        orderedQuantity
        voidQuantity
        paidQuantity
        readyQuantity
        receivedQuantity
        inventoryReason
        point
        note
        createdAt
      }
      createdEmployeeId
      createdDeviceId
      updatedDeviceId
      shippingLocationId
      confirmedEmployeeId
      confirmedAt
      createdAt
      paidAt
      salesDate
      returnedAt
      shippedAt
      returnShippedAt
      returnSalesDate
      updatedAt
      noticeCount
      visitorCount
      totalPoint
      pointSaved
      receiptId
      receiptNumber
      receiptCount
      alarmCustomerId
      receiptCustomerId
      note
      request
      charges {
        id
        method
        extraMethod
        gateway
        amount
        tax
        taxRefundAmount
        purchaser {
          name
          number
          nation
          address
        }
        supplyAmount
        taxFreeAmount
        amountRefunded
        createdEmployeeId
        createdDeviceId
        createdAt
        creditTransactionId
        balance
        splitType
        pg
        van
        origin
        originId
        note
        brand
        name
        ident
        installment
        transactionId
        approver
        issuerCode
        acquirer
        acquirerCode
        acquirerName
        refundable
        recurring
        approvalCode
        approvalDate
        approvalNumber
        signature {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        refunds {
          id
          amount
          supplyAmount
          taxFreeAmount
          tax
          taxRefundAmount
          creditTransactionId
          approvalNumber
          approvalDate
          transactionId
          origin
          originId
          currency
          reason
          signature {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          createdEmployeeId
          createdDeviceId
          createdAt
        }
      }
      returns {
        amount
        currency
        items {
          id
          type
          index
          category
          categories {
            id
            name
          }
          classificationIds
          productId
          modifierId
          name
          description
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        refundId
        createdAt
      }
      fee
      revision
      _delta
      location {
        id
        name
        type
        provider
        address
        zip
        phone
        logo {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        representative
        businessNumber
        billing {
          key
          businessNumber
          name
          email
          phone
          contanctName
          contactName
          address
          zip
          card {
            pg
            number
            name
            expMonth
            expYear
            identify
            password
            billingKey
          }
        }
        credit {
          autoCharge
          autoChargeCredit
          autoChargeQuantity
          alertCredit
        }
        businessHours {
          sun {
            open
            close
          }
          mon {
            open
            close
          }
          tue {
            open
            close
          }
          wed {
            open
            close
          }
          thu {
            open
            close
          }
          fri {
            open
            close
          }
          sat {
            open
            close
          }
        }
        storeId
        state
        menuPlanId
        payments {
          refund
          barcode
          barcodeVan
          barcodeTerminalId
        }
        policy {
          locationCost
          loyaltyCost
        }
        loyalty {
          status
          provider
          sharePolicy
          type
          grade
          conditionType
          conditionSubType
          condition {
            minAmount
            perAmount
            minTime
            rate
            expirationPeriod
            excludeProdIds
            discountPointSavable
          }
          conditionItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          conditionTiers {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardType
          rewardSubType
          reward {
            requirePoint
            requireOrderAmount
            minUsePoint
            minRequireRate
            maxUsePoint
          }
          rewardItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardRates {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardAmounts {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardCashs {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          incentives {
            fulfill
            fulfillPoint
            fulfillCampaignId
            birth
            birthPoint
            birthCampaignId
            birthItem {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
          }
          notification {
            register
            registerCampainId
            save
            saveCampaignId
            use
            useCampaignId
            expire
            expireCampaignId
          }
        }
        kiosk {
          status
          callable
          payable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          exchangeTicket
          columnSize
          discounts {
            condition
            couponId
          }
          menuDescription
          updatedAt
          createdAt
        }
        receipt {
          salesSlip
          orderList
          barcode
          footnote
          phone
          orderNumberHide
          orderSheetFontSize
          billsBusinessInfo
          giftFootnote
          giftReceipt
        }
        menuOrder {
          callOptions {
            index
            label
          }
          orderable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          columnSize
          fontSize
          notice
          resetTime
          enableCash
          boardType
        }
        kds {
          layout
          alerts {
            urgent
            warning
          }
          disabledProdIds
          orderNotify
        }
        mini {
          defaultItemName
        }
        orderPlanId
        menu {
          board {
            textSize
            columnSize
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            enableFavorite
          }
          list {
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            shortcuts {
              type
              title
              itemName
              itemPrice
              active
            }
          }
          navOrder
        }
        menus {
          parentId
          position
          page
          pageTitle
        }
        favMenus {
          parentId
          position
          page
          pageTitle
        }
        catMenus {
          title
          index
          ids
          parentId
        }
        market {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          paused
          takeoutDiscount
          creditDiscount
          takeout
          intro
          notice
          bookTable
          bookTableNote
          depositAmount
          delivery
          deliveryFee {
            start
            end
            fee
          }
          waitingTime
          itemIds
          updatedAt
          createdAt
        }
        waiting {
          status
          waitingOptions {
            id
            type
            name
            nameMl {
              en
              zh
              ja
            }
            items {
              id
              name
              nameMl {
                en
                zh
                ja
              }
              limitQuantity
            }
            required
            updatedAt
            createdAt
          }
          waitingMinutes
          waitingMinutesHide
          hasForeigner
          foreignerMethod
          notifications {
            created
            ready
            requested
            cancelled
          }
          notices {
            createdNotice
            createdNoticeMl {
              en
              zh
              ja
            }
            requestedNotice
            requestedNoticeMl {
              en
              zh
              ja
            }
          }
          settings {
            autoCancel
            autoCancelMinutes
          }
          updatedAt
          createdAt
        }
        view {
          order {
            excludeColumns
            exportTemplates {
              name
              type
              headers {
                key
                label
              }
            }
          }
          itemReport {
            excludeColumns
          }
          summaryReport {
            excludeColumns
          }
          shippingOrder {
            excludeColumns
          }
          trsItemReport {
            excludeColumns
          }
        }
        subscription {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          updatedAt
          createdAt
        }
        settings {
          mode
          table
          diningOption
          retailOption
          orderAlarm
          orderConfirm
          addressInput
          autoPay
          autoReturnItem
          autoSoldout
          menuGridSize
          menuMode
          tableMode
          orderNotify
          orderNotifySkippable
          disablePhonePrefix
          autoCloseScreen
          orderNumberPrefix {
            pos
            kiosk
            menuOrder
          }
          orderMgmt {
            kiosk
            menuorder
            delivery
            pos
          }
          reportBasis
          orderOrigins
          beeper {
            active
            coverage
            skippable
          }
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
        }
        devices {
          id
          ident
          locationId
          system
          ipAddress
          brand
          model
          name
          type
          deviceType
          display {
            height
            width
          }
          protocol
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          barcodeReader
          appVersion
          sysVersion
          status
          password
          msgToken
          parentId
          childId
          sleepMode
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
          terminalId
          printOptions {
            port
            baudRate
            receipt
            receiptButtonHide
            bills
            refund
            salesEnd
            order
            orderBeep
            orderCount
            orderExcludeIds
            delivery
          }
          updatedAt
          createdAt
        }
        tables {
          id
          width
          height
          name
          axisX
          axisY
          screenHeight
          screenWidth
          initialX
          initialY
          shape
          layout {
            x
            y
            w
            h
          }
          color
          orderId
          disabled
        }
        boardTables {
          title
          index
          items {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
        }
        gridTables {
          id
          name
          orderId
          index
          page
        }
        modifierSoldoutIds
        delivery {
          baemin
          coupang
          yogiyo
          enableGenProd
          updatedAt
          createdAt
        }
        createdAt
        updatedAt
        sales {
          startedAt
          endedAt
          reserves
          actualBalance
          currency
          note
          estimateBalance
          detail {
            totalSales
            totalRefund
            totalTax
            totalDiscount
            supplyAmount
            totalSalesCount
            cash {
              sales
              refund
            }
            card {
              sales
              refund
            }
            credit {
              sales
              refund
            }
            barcode {
              sales
              refund
            }
            extra {
              sales
              refund
            }
            refund {
              sales
              refund
            }
            products {
              id
              name
              quantity
              amount
            }
            origins {
              pos
              kiosk
              menuorder
              delivery
              market
            }
          }
          reservesHistories {
            type
            amount
            note
            createdEmployeeId
            createdAt
          }
        }
      }
      customer {
        id
        customerId
        storeId
        sources {
          id
          type
          name
          ident
          createdAt
        }
        isFavorite
        locationId
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        favoriteLocationIds
        visitedLocationIds
        locations {
          id
          locationId
          isFavorite
          status
          note
          campaignBlockedAt
          blacklistedAt
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          subscriptions {
            id
            planId
            payStatus
            sessionId
            nextPayDate
            paidAt
            unsubscribedAt
            used
            lastUsedAt
            orderId
            unsubscribeRequestedAt
            startDate
            expireDate
            updatedAt
            createdAt
          }
          registeredAt
          salesDate
          updatedAt
          createdAt
        }
        status
        registeredAt
        campaignBlockedAt
        note
        removedAt
        updatedAt
        createdAt
        points {
          items {
            id
            locationId
            storeId
            type
            name
            amount
            origin
            provider
            providerCustomerId
            providerSyncedAt
            currency
            point
            extraPoint
            extraExpire
            items {
              type
              itemId
              name
              parentId
              point
              amount
              quantity
            }
            quantity
            note
            orderId
            referId
            orderNumberPrefix
            orderNumber
            customerId
            pointTransactionCustomerId
            expiredAt
            salesDate
            updatedAt
            createdAt
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
          }
          nextToken
        }
        credits {
          items {
            id
            locationId
            storeId
            name
            type
            amount
            origin
            note
            currency
            parentId
            orderId
            orderNumberPrefix
            orderNumber
            customerId
            creditTransactionCustomerId
            description
            salesDate
            createdAt
            updatedAt
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
          }
          nextToken
        }
        _delta
        orders {
          items {
            id
            orderLocationId
            numberPrefix
            number
            beeper
            enterpriseId
            storeId
            customerId
            orderCustomerId
            state
            amount
            amountPaid
            origin
            subOrigin
            originId
            customerProvider
            providerCustomerId
            amountChanged
            amountReturned
            tax
            currency
            quantity
            shippingType
            diningOption
            diningState
            retailOption
            retailState
            name
            phone
            address
            zip
            receivers {
              id
              name
              phone
              zip
              address1
              address2
              addressState
              addressCity
              addressStreet
              addressFull
              note
              shippingCompanyCode
              shippingAcceptCount
              shippingStartCount
              trackingNumber
              privacyAgreedAt
            }
            tableId
            tableIds
            parentDeviceId
            deviceId
            reserveDate
            pickupDate
            shippingDate
            items {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            createdEmployeeId
            createdDeviceId
            updatedDeviceId
            shippingLocationId
            confirmedEmployeeId
            confirmedAt
            createdAt
            paidAt
            salesDate
            returnedAt
            shippedAt
            returnShippedAt
            returnSalesDate
            updatedAt
            noticeCount
            visitorCount
            totalPoint
            pointSaved
            receiptId
            receiptNumber
            receiptCount
            alarmCustomerId
            receiptCustomerId
            note
            request
            charges {
              id
              method
              extraMethod
              gateway
              amount
              tax
              taxRefundAmount
              purchaser {
                name
                number
                nation
                address
              }
              supplyAmount
              taxFreeAmount
              amountRefunded
              createdEmployeeId
              createdDeviceId
              createdAt
              creditTransactionId
              balance
              splitType
              pg
              van
              origin
              originId
              note
              brand
              name
              ident
              installment
              transactionId
              approver
              issuerCode
              acquirer
              acquirerCode
              acquirerName
              refundable
              recurring
              approvalCode
              approvalDate
              approvalNumber
              signature {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              refunds {
                id
                amount
                supplyAmount
                taxFreeAmount
                tax
                taxRefundAmount
                creditTransactionId
                approvalNumber
                approvalDate
                transactionId
                origin
                originId
                currency
                reason
                createdEmployeeId
                createdDeviceId
                createdAt
              }
            }
            returns {
              amount
              currency
              items {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              refundId
              createdAt
            }
            fee
            revision
            _delta
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
            orderCustomer {
              id
              enterpriseId
              storeId
              locationId
              customerId
              provider
              providerCustomerId
              tier
              customerCreatedAt
              ident
              identType
              type
              name
              nameKeyword
              payments {
                id
                method
                pg
                number
                name
                expMonth
                expYear
                billingKey
                updatedAt
                createdAt
              }
              attributes {
                name
                type
                value
              }
              addresses {
                id
                main
                detail
                storeId
              }
              phone
              email
              birth
              agreements {
                privacy
                provider
                eula
                marketing
                updatedAt
                createdAt
              }
              ageGroup
              gender
              tags {
                tagId
                createdLocationId
              }
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              status
              registeredAt
              salesDate
              campaignBlockedAt
              blacklistedAt
              isFavorite
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              subscriptions {
                id
                planId
                payStatus
                sessionId
                nextPayDate
                paidAt
                unsubscribedAt
                used
                lastUsedAt
                orderId
                unsubscribeRequestedAt
                startDate
                expireDate
                updatedAt
                createdAt
              }
              note
              sources {
                id
                type
                name
                ident
                createdAt
              }
              removedAt
              updatedAt
              createdAt
              _delta
              timestamp
              expdate
            }
          }
          nextToken
        }
        location {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        store {
          id
          enterpriseId
          info {
            name
            phone
            email
            representative
            businessType
            businessNumber
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            industryType
            sendNumber
            currency
            address
            zip
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            csPhone
          }
          policy {
            multiLocation
            provider {
              key
              ident
              customer
              coupon
              inventory
              locationId
            }
          }
          currency
          payments {
            card
            cardJoined
            cash
            credit
            refund
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          plans {
            menus {
              id
              name
              mode
              size
              menus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              pages {
                index
                parentId
                title
              }
              updatedAt
              createdAt
            }
            orders {
              id
              name
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              updatedAt
              createdAt
            }
          }
          taxes {
            id
            type
            name
            enabled
            rate
            method
            targetType
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          item {
            attributes {
              name
              type
              value
            }
            actualPrice
            skuMgmt
            barcodeMgmt
            stockTrack
            variablePrice
            locationCreatable
          }
          inventory {
            attributes {
              name
              type
              value
            }
            expiryDateMgmt
            expiryDateAlertDays
          }
          customer {
            attributes {
              name
              type
              value
            }
          }
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          kakao {
            agency
            key
            channelId
            categoryCode
            status
            updatedAt
            createdAt
          }
          review {
            status
            campaignId
            updatedAt
            createdAt
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mirror {
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            video {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          notification {
            options {
              type
              email
              sms
            }
          }
          groups {
            items {
              id
              name
              type
              storeGroupsId
              locationGroupsId
              key
              conditions {
                key
                object
                field
                value
                operation
                type
              }
              count
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          location {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          locations {
            items {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            nextToken
          }
        }
        customer {
          id
          ident
          identType
          type
          name
          addresses {
            id
            main
            detail
            storeId
          }
          phone
          email
          birth
          agreements {
            privacy
            provider
            eula
            marketing
            updatedAt
            createdAt
          }
          ageGroup
          gender
          updatedAt
          createdAt
          stores {
            items {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
            nextToken
          }
        }
      }
      orderCustomer {
        id
        enterpriseId
        storeId
        locationId
        customerId
        provider
        providerCustomerId
        tier
        customerCreatedAt
        ident
        identType
        type
        name
        nameKeyword
        payments {
          id
          method
          pg
          number
          name
          expMonth
          expYear
          billingKey
          updatedAt
          createdAt
        }
        attributes {
          name
          type
          value
        }
        addresses {
          id
          main
          detail
          storeId
        }
        phone
        email
        birth
        agreements {
          privacy
          provider
          eula
          marketing
          updatedAt
          createdAt
        }
        ageGroup
        gender
        tags {
          tagId
          createdLocationId
        }
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        status
        registeredAt
        salesDate
        campaignBlockedAt
        blacklistedAt
        isFavorite
        locations {
          id
          locationId
          isFavorite
          status
          note
          campaignBlockedAt
          blacklistedAt
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          subscriptions {
            id
            planId
            payStatus
            sessionId
            nextPayDate
            paidAt
            unsubscribedAt
            used
            lastUsedAt
            orderId
            unsubscribeRequestedAt
            startDate
            expireDate
            updatedAt
            createdAt
          }
          registeredAt
          salesDate
          updatedAt
          createdAt
        }
        subscriptions {
          id
          planId
          payStatus
          sessionId
          nextPayDate
          paidAt
          unsubscribedAt
          used
          lastUsedAt
          orderId
          unsubscribeRequestedAt
          startDate
          expireDate
          updatedAt
          createdAt
        }
        note
        sources {
          id
          type
          name
          ident
          createdAt
        }
        removedAt
        updatedAt
        createdAt
        _delta
        timestamp
        expdate
      }
    }
  }
`;
export const createOrderDelta = /* GraphQL */ `
  mutation CreateOrderDelta($input: CreateOrderDeltaInput!) {
    createOrderDelta(input: $input) {
      id
      orderLocationId
      numberPrefix
      number
      beeper
      enterpriseId
      storeId
      customerId
      orderCustomerId
      state
      amount
      amountPaid
      origin
      subOrigin
      originId
      customerProvider
      providerCustomerId
      amountChanged
      amountReturned
      tax
      currency
      quantity
      shippingType
      diningOption
      diningState
      retailOption
      retailState
      name
      phone
      address
      zip
      receivers {
        id
        name
        phone
        zip
        address1
        address2
        addressState
        addressCity
        addressStreet
        addressFull
        note
        shippingCompanyCode
        shippingAcceptCount
        shippingStartCount
        trackingNumber
        privacyAgreedAt
      }
      tableId
      tableIds
      parentDeviceId
      deviceId
      reserveDate
      pickupDate
      shippingDate
      items {
        id
        type
        index
        category
        categories {
          id
          name
        }
        classificationIds
        productId
        modifierId
        name
        description
        amount
        price
        parentId
        referType
        referId
        campaignId
        requestId
        quantity
        sku
        tax
        taxRate
        diningOption
        diningState
        modifiers {
          id
          type
          index
          category
          categories {
            id
            name
          }
          classificationIds
          productId
          modifierId
          name
          description
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        discounts {
          id
          scope
          type
          amount
          name
        }
        discountable
        discountQuantity
        discountIncludeIds
        discountExcludeIds
        discountMethod
        discountValue
        orderedQuantity
        voidQuantity
        paidQuantity
        readyQuantity
        receivedQuantity
        inventoryReason
        point
        note
        createdAt
      }
      createdEmployeeId
      createdDeviceId
      updatedDeviceId
      shippingLocationId
      confirmedEmployeeId
      confirmedAt
      createdAt
      paidAt
      salesDate
      returnedAt
      shippedAt
      returnShippedAt
      returnSalesDate
      updatedAt
      noticeCount
      visitorCount
      totalPoint
      pointSaved
      receiptId
      receiptNumber
      receiptCount
      alarmCustomerId
      receiptCustomerId
      request
      note
      charges {
        id
        method
        extraMethod
        gateway
        amount
        tax
        taxRefundAmount
        purchaser {
          name
          number
          nation
          address
        }
        supplyAmount
        taxFreeAmount
        amountRefunded
        createdEmployeeId
        createdDeviceId
        createdAt
        creditTransactionId
        balance
        splitType
        pg
        van
        origin
        originId
        note
        brand
        name
        ident
        installment
        transactionId
        approver
        issuerCode
        acquirer
        acquirerCode
        acquirerName
        refundable
        recurring
        approvalCode
        approvalDate
        approvalNumber
        signature {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        refunds {
          id
          amount
          supplyAmount
          taxFreeAmount
          tax
          taxRefundAmount
          creditTransactionId
          approvalNumber
          approvalDate
          transactionId
          origin
          originId
          currency
          reason
          signature {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          createdEmployeeId
          createdDeviceId
          createdAt
        }
      }
      returns {
        amount
        currency
        items {
          id
          type
          index
          category
          categories {
            id
            name
          }
          classificationIds
          productId
          modifierId
          name
          description
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        refundId
        createdAt
      }
      fee
      revision
      _delta
      timestamp
      expdate
    }
  }
`;
export const updateOrderDelta = /* GraphQL */ `
  mutation UpdateOrderDelta($input: UpdateOrderDeltaInput!) {
    updateOrderDelta(input: $input) {
      id
      orderLocationId
      numberPrefix
      number
      beeper
      enterpriseId
      storeId
      customerId
      orderCustomerId
      state
      amount
      amountPaid
      origin
      subOrigin
      originId
      customerProvider
      providerCustomerId
      amountChanged
      amountReturned
      tax
      currency
      quantity
      shippingType
      diningOption
      diningState
      retailOption
      retailState
      name
      phone
      address
      zip
      receivers {
        id
        name
        phone
        zip
        address1
        address2
        addressState
        addressCity
        addressStreet
        addressFull
        note
        shippingCompanyCode
        shippingAcceptCount
        shippingStartCount
        trackingNumber
        privacyAgreedAt
      }
      tableId
      tableIds
      parentDeviceId
      deviceId
      reserveDate
      pickupDate
      shippingDate
      items {
        id
        type
        index
        category
        categories {
          id
          name
        }
        classificationIds
        productId
        modifierId
        name
        description
        amount
        price
        parentId
        referType
        referId
        campaignId
        requestId
        quantity
        sku
        tax
        taxRate
        diningOption
        diningState
        modifiers {
          id
          type
          index
          category
          categories {
            id
            name
          }
          classificationIds
          productId
          modifierId
          name
          description
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        discounts {
          id
          scope
          type
          amount
          name
        }
        discountable
        discountQuantity
        discountIncludeIds
        discountExcludeIds
        discountMethod
        discountValue
        orderedQuantity
        voidQuantity
        paidQuantity
        readyQuantity
        receivedQuantity
        inventoryReason
        point
        note
        createdAt
      }
      createdEmployeeId
      createdDeviceId
      updatedDeviceId
      shippingLocationId
      confirmedEmployeeId
      confirmedAt
      createdAt
      paidAt
      salesDate
      returnedAt
      shippedAt
      returnShippedAt
      returnSalesDate
      updatedAt
      noticeCount
      visitorCount
      totalPoint
      pointSaved
      receiptId
      receiptNumber
      receiptCount
      alarmCustomerId
      receiptCustomerId
      request
      note
      charges {
        id
        method
        extraMethod
        gateway
        amount
        tax
        taxRefundAmount
        purchaser {
          name
          number
          nation
          address
        }
        supplyAmount
        taxFreeAmount
        amountRefunded
        createdEmployeeId
        createdDeviceId
        createdAt
        creditTransactionId
        balance
        splitType
        pg
        van
        origin
        originId
        note
        brand
        name
        ident
        installment
        transactionId
        approver
        issuerCode
        acquirer
        acquirerCode
        acquirerName
        refundable
        recurring
        approvalCode
        approvalDate
        approvalNumber
        signature {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        refunds {
          id
          amount
          supplyAmount
          taxFreeAmount
          tax
          taxRefundAmount
          creditTransactionId
          approvalNumber
          approvalDate
          transactionId
          origin
          originId
          currency
          reason
          signature {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          createdEmployeeId
          createdDeviceId
          createdAt
        }
      }
      returns {
        amount
        currency
        items {
          id
          type
          index
          category
          categories {
            id
            name
          }
          classificationIds
          productId
          modifierId
          name
          description
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        refundId
        createdAt
      }
      fee
      revision
      _delta
      timestamp
      expdate
    }
  }
`;
export const deleteOrderDelta = /* GraphQL */ `
  mutation DeleteOrderDelta($input: DeleteOrderDeltaInput!) {
    deleteOrderDelta(input: $input) {
      id
      orderLocationId
      numberPrefix
      number
      beeper
      enterpriseId
      storeId
      customerId
      orderCustomerId
      state
      amount
      amountPaid
      origin
      subOrigin
      originId
      customerProvider
      providerCustomerId
      amountChanged
      amountReturned
      tax
      currency
      quantity
      shippingType
      diningOption
      diningState
      retailOption
      retailState
      name
      phone
      address
      zip
      receivers {
        id
        name
        phone
        zip
        address1
        address2
        addressState
        addressCity
        addressStreet
        addressFull
        note
        shippingCompanyCode
        shippingAcceptCount
        shippingStartCount
        trackingNumber
        privacyAgreedAt
      }
      tableId
      tableIds
      parentDeviceId
      deviceId
      reserveDate
      pickupDate
      shippingDate
      items {
        id
        type
        index
        category
        categories {
          id
          name
        }
        classificationIds
        productId
        modifierId
        name
        description
        amount
        price
        parentId
        referType
        referId
        campaignId
        requestId
        quantity
        sku
        tax
        taxRate
        diningOption
        diningState
        modifiers {
          id
          type
          index
          category
          categories {
            id
            name
          }
          classificationIds
          productId
          modifierId
          name
          description
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        discounts {
          id
          scope
          type
          amount
          name
        }
        discountable
        discountQuantity
        discountIncludeIds
        discountExcludeIds
        discountMethod
        discountValue
        orderedQuantity
        voidQuantity
        paidQuantity
        readyQuantity
        receivedQuantity
        inventoryReason
        point
        note
        createdAt
      }
      createdEmployeeId
      createdDeviceId
      updatedDeviceId
      shippingLocationId
      confirmedEmployeeId
      confirmedAt
      createdAt
      paidAt
      salesDate
      returnedAt
      shippedAt
      returnShippedAt
      returnSalesDate
      updatedAt
      noticeCount
      visitorCount
      totalPoint
      pointSaved
      receiptId
      receiptNumber
      receiptCount
      alarmCustomerId
      receiptCustomerId
      request
      note
      charges {
        id
        method
        extraMethod
        gateway
        amount
        tax
        taxRefundAmount
        purchaser {
          name
          number
          nation
          address
        }
        supplyAmount
        taxFreeAmount
        amountRefunded
        createdEmployeeId
        createdDeviceId
        createdAt
        creditTransactionId
        balance
        splitType
        pg
        van
        origin
        originId
        note
        brand
        name
        ident
        installment
        transactionId
        approver
        issuerCode
        acquirer
        acquirerCode
        acquirerName
        refundable
        recurring
        approvalCode
        approvalDate
        approvalNumber
        signature {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        refunds {
          id
          amount
          supplyAmount
          taxFreeAmount
          tax
          taxRefundAmount
          creditTransactionId
          approvalNumber
          approvalDate
          transactionId
          origin
          originId
          currency
          reason
          signature {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          createdEmployeeId
          createdDeviceId
          createdAt
        }
      }
      returns {
        amount
        currency
        items {
          id
          type
          index
          category
          categories {
            id
            name
          }
          classificationIds
          productId
          modifierId
          name
          description
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        refundId
        createdAt
      }
      fee
      revision
      _delta
      timestamp
      expdate
    }
  }
`;
export const createOrderTicketDelta = /* GraphQL */ `
  mutation CreateOrderTicketDelta($input: CreateOrderTicketDeltaInput!) {
    createOrderTicketDelta(input: $input) {
      id
      storeId
      locationId
      orderId
      items {
        id
        diningState
      }
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
      order {
        id
        orderLocationId
        numberPrefix
        number
        beeper
        enterpriseId
        storeId
        customerId
        orderCustomerId
        state
        amount
        amountPaid
        origin
        subOrigin
        originId
        customerProvider
        providerCustomerId
        amountChanged
        amountReturned
        tax
        currency
        quantity
        shippingType
        diningOption
        diningState
        retailOption
        retailState
        name
        phone
        address
        zip
        receivers {
          id
          name
          phone
          zip
          address1
          address2
          addressState
          addressCity
          addressStreet
          addressFull
          note
          shippingCompanyCode
          shippingAcceptCount
          shippingStartCount
          trackingNumber
          privacyAgreedAt
        }
        tableId
        tableIds
        parentDeviceId
        deviceId
        reserveDate
        pickupDate
        shippingDate
        items {
          id
          type
          index
          category
          categories {
            id
            name
          }
          classificationIds
          productId
          modifierId
          name
          description
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        createdEmployeeId
        createdDeviceId
        updatedDeviceId
        shippingLocationId
        confirmedEmployeeId
        confirmedAt
        createdAt
        paidAt
        salesDate
        returnedAt
        shippedAt
        returnShippedAt
        returnSalesDate
        updatedAt
        noticeCount
        visitorCount
        totalPoint
        pointSaved
        receiptId
        receiptNumber
        receiptCount
        alarmCustomerId
        receiptCustomerId
        note
        request
        charges {
          id
          method
          extraMethod
          gateway
          amount
          tax
          taxRefundAmount
          purchaser {
            name
            number
            nation
            address
          }
          supplyAmount
          taxFreeAmount
          amountRefunded
          createdEmployeeId
          createdDeviceId
          createdAt
          creditTransactionId
          balance
          splitType
          pg
          van
          origin
          originId
          note
          brand
          name
          ident
          installment
          transactionId
          approver
          issuerCode
          acquirer
          acquirerCode
          acquirerName
          refundable
          recurring
          approvalCode
          approvalDate
          approvalNumber
          signature {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          refunds {
            id
            amount
            supplyAmount
            taxFreeAmount
            tax
            taxRefundAmount
            creditTransactionId
            approvalNumber
            approvalDate
            transactionId
            origin
            originId
            currency
            reason
            signature {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            createdEmployeeId
            createdDeviceId
            createdAt
          }
        }
        returns {
          amount
          currency
          items {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          refundId
          createdAt
        }
        fee
        revision
        _delta
        location {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        customer {
          id
          customerId
          storeId
          sources {
            id
            type
            name
            ident
            createdAt
          }
          isFavorite
          locationId
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          favoriteLocationIds
          visitedLocationIds
          locations {
            id
            locationId
            isFavorite
            status
            note
            campaignBlockedAt
            blacklistedAt
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            subscriptions {
              id
              planId
              payStatus
              sessionId
              nextPayDate
              paidAt
              unsubscribedAt
              used
              lastUsedAt
              orderId
              unsubscribeRequestedAt
              startDate
              expireDate
              updatedAt
              createdAt
            }
            registeredAt
            salesDate
            updatedAt
            createdAt
          }
          status
          registeredAt
          campaignBlockedAt
          note
          removedAt
          updatedAt
          createdAt
          points {
            items {
              id
              locationId
              storeId
              type
              name
              amount
              origin
              provider
              providerCustomerId
              providerSyncedAt
              currency
              point
              extraPoint
              extraExpire
              items {
                type
                itemId
                name
                parentId
                point
                amount
                quantity
              }
              quantity
              note
              orderId
              referId
              orderNumberPrefix
              orderNumber
              customerId
              pointTransactionCustomerId
              expiredAt
              salesDate
              updatedAt
              createdAt
              customer {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
            }
            nextToken
          }
          credits {
            items {
              id
              locationId
              storeId
              name
              type
              amount
              origin
              note
              currency
              parentId
              orderId
              orderNumberPrefix
              orderNumber
              customerId
              creditTransactionCustomerId
              description
              salesDate
              createdAt
              updatedAt
              customer {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
            }
            nextToken
          }
          _delta
          orders {
            items {
              id
              orderLocationId
              numberPrefix
              number
              beeper
              enterpriseId
              storeId
              customerId
              orderCustomerId
              state
              amount
              amountPaid
              origin
              subOrigin
              originId
              customerProvider
              providerCustomerId
              amountChanged
              amountReturned
              tax
              currency
              quantity
              shippingType
              diningOption
              diningState
              retailOption
              retailState
              name
              phone
              address
              zip
              receivers {
                id
                name
                phone
                zip
                address1
                address2
                addressState
                addressCity
                addressStreet
                addressFull
                note
                shippingCompanyCode
                shippingAcceptCount
                shippingStartCount
                trackingNumber
                privacyAgreedAt
              }
              tableId
              tableIds
              parentDeviceId
              deviceId
              reserveDate
              pickupDate
              shippingDate
              items {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              createdEmployeeId
              createdDeviceId
              updatedDeviceId
              shippingLocationId
              confirmedEmployeeId
              confirmedAt
              createdAt
              paidAt
              salesDate
              returnedAt
              shippedAt
              returnShippedAt
              returnSalesDate
              updatedAt
              noticeCount
              visitorCount
              totalPoint
              pointSaved
              receiptId
              receiptNumber
              receiptCount
              alarmCustomerId
              receiptCustomerId
              note
              request
              charges {
                id
                method
                extraMethod
                gateway
                amount
                tax
                taxRefundAmount
                supplyAmount
                taxFreeAmount
                amountRefunded
                createdEmployeeId
                createdDeviceId
                createdAt
                creditTransactionId
                balance
                splitType
                pg
                van
                origin
                originId
                note
                brand
                name
                ident
                installment
                transactionId
                approver
                issuerCode
                acquirer
                acquirerCode
                acquirerName
                refundable
                recurring
                approvalCode
                approvalDate
                approvalNumber
              }
              returns {
                amount
                currency
                refundId
                createdAt
              }
              fee
              revision
              _delta
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              customer {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
              orderCustomer {
                id
                enterpriseId
                storeId
                locationId
                customerId
                provider
                providerCustomerId
                tier
                customerCreatedAt
                ident
                identType
                type
                name
                nameKeyword
                phone
                email
                birth
                ageGroup
                gender
                status
                registeredAt
                salesDate
                campaignBlockedAt
                blacklistedAt
                isFavorite
                note
                removedAt
                updatedAt
                createdAt
                _delta
                timestamp
                expdate
              }
            }
            nextToken
          }
          location {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          store {
            id
            enterpriseId
            info {
              name
              phone
              email
              representative
              businessType
              businessNumber
              industryType
              sendNumber
              currency
              address
              zip
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              csPhone
            }
            policy {
              multiLocation
              provider {
                key
                ident
                customer
                coupon
                inventory
                locationId
              }
            }
            currency
            payments {
              card
              cardJoined
              cash
              credit
              refund
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            plans {
              menus {
                id
                name
                mode
                size
                updatedAt
                createdAt
              }
              orders {
                id
                name
                updatedAt
                createdAt
              }
            }
            taxes {
              id
              type
              name
              enabled
              rate
              method
              targetType
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            item {
              attributes {
                name
                type
                value
              }
              actualPrice
              skuMgmt
              barcodeMgmt
              stockTrack
              variablePrice
              locationCreatable
            }
            inventory {
              attributes {
                name
                type
                value
              }
              expiryDateMgmt
              expiryDateAlertDays
            }
            customer {
              attributes {
                name
                type
                value
              }
            }
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            kakao {
              agency
              key
              channelId
              categoryCode
              status
              updatedAt
              createdAt
            }
            review {
              status
              campaignId
              updatedAt
              createdAt
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mirror {
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              video {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            notification {
              options {
                type
                email
                sms
              }
            }
            groups {
              items {
                id
                name
                type
                storeGroupsId
                locationGroupsId
                key
                count
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            locations {
              items {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          customer {
            id
            ident
            identType
            type
            name
            addresses {
              id
              main
              detail
              storeId
            }
            phone
            email
            birth
            agreements {
              privacy
              provider
              eula
              marketing
              updatedAt
              createdAt
            }
            ageGroup
            gender
            updatedAt
            createdAt
            stores {
              items {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
              nextToken
            }
          }
        }
        orderCustomer {
          id
          enterpriseId
          storeId
          locationId
          customerId
          provider
          providerCustomerId
          tier
          customerCreatedAt
          ident
          identType
          type
          name
          nameKeyword
          payments {
            id
            method
            pg
            number
            name
            expMonth
            expYear
            billingKey
            updatedAt
            createdAt
          }
          attributes {
            name
            type
            value
          }
          addresses {
            id
            main
            detail
            storeId
          }
          phone
          email
          birth
          agreements {
            privacy
            provider
            eula
            marketing
            updatedAt
            createdAt
          }
          ageGroup
          gender
          tags {
            tagId
            createdLocationId
          }
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          status
          registeredAt
          salesDate
          campaignBlockedAt
          blacklistedAt
          isFavorite
          locations {
            id
            locationId
            isFavorite
            status
            note
            campaignBlockedAt
            blacklistedAt
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            subscriptions {
              id
              planId
              payStatus
              sessionId
              nextPayDate
              paidAt
              unsubscribedAt
              used
              lastUsedAt
              orderId
              unsubscribeRequestedAt
              startDate
              expireDate
              updatedAt
              createdAt
            }
            registeredAt
            salesDate
            updatedAt
            createdAt
          }
          subscriptions {
            id
            planId
            payStatus
            sessionId
            nextPayDate
            paidAt
            unsubscribedAt
            used
            lastUsedAt
            orderId
            unsubscribeRequestedAt
            startDate
            expireDate
            updatedAt
            createdAt
          }
          note
          sources {
            id
            type
            name
            ident
            createdAt
          }
          removedAt
          updatedAt
          createdAt
          _delta
          timestamp
          expdate
        }
      }
    }
  }
`;
export const updateOrderTicketDelta = /* GraphQL */ `
  mutation UpdateOrderTicketDelta($input: UpdateOrderTicketDeltaInput!) {
    updateOrderTicketDelta(input: $input) {
      id
      storeId
      locationId
      orderId
      items {
        id
        diningState
      }
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
      order {
        id
        orderLocationId
        numberPrefix
        number
        beeper
        enterpriseId
        storeId
        customerId
        orderCustomerId
        state
        amount
        amountPaid
        origin
        subOrigin
        originId
        customerProvider
        providerCustomerId
        amountChanged
        amountReturned
        tax
        currency
        quantity
        shippingType
        diningOption
        diningState
        retailOption
        retailState
        name
        phone
        address
        zip
        receivers {
          id
          name
          phone
          zip
          address1
          address2
          addressState
          addressCity
          addressStreet
          addressFull
          note
          shippingCompanyCode
          shippingAcceptCount
          shippingStartCount
          trackingNumber
          privacyAgreedAt
        }
        tableId
        tableIds
        parentDeviceId
        deviceId
        reserveDate
        pickupDate
        shippingDate
        items {
          id
          type
          index
          category
          categories {
            id
            name
          }
          classificationIds
          productId
          modifierId
          name
          description
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        createdEmployeeId
        createdDeviceId
        updatedDeviceId
        shippingLocationId
        confirmedEmployeeId
        confirmedAt
        createdAt
        paidAt
        salesDate
        returnedAt
        shippedAt
        returnShippedAt
        returnSalesDate
        updatedAt
        noticeCount
        visitorCount
        totalPoint
        pointSaved
        receiptId
        receiptNumber
        receiptCount
        alarmCustomerId
        receiptCustomerId
        note
        request
        charges {
          id
          method
          extraMethod
          gateway
          amount
          tax
          taxRefundAmount
          purchaser {
            name
            number
            nation
            address
          }
          supplyAmount
          taxFreeAmount
          amountRefunded
          createdEmployeeId
          createdDeviceId
          createdAt
          creditTransactionId
          balance
          splitType
          pg
          van
          origin
          originId
          note
          brand
          name
          ident
          installment
          transactionId
          approver
          issuerCode
          acquirer
          acquirerCode
          acquirerName
          refundable
          recurring
          approvalCode
          approvalDate
          approvalNumber
          signature {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          refunds {
            id
            amount
            supplyAmount
            taxFreeAmount
            tax
            taxRefundAmount
            creditTransactionId
            approvalNumber
            approvalDate
            transactionId
            origin
            originId
            currency
            reason
            signature {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            createdEmployeeId
            createdDeviceId
            createdAt
          }
        }
        returns {
          amount
          currency
          items {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          refundId
          createdAt
        }
        fee
        revision
        _delta
        location {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        customer {
          id
          customerId
          storeId
          sources {
            id
            type
            name
            ident
            createdAt
          }
          isFavorite
          locationId
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          favoriteLocationIds
          visitedLocationIds
          locations {
            id
            locationId
            isFavorite
            status
            note
            campaignBlockedAt
            blacklistedAt
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            subscriptions {
              id
              planId
              payStatus
              sessionId
              nextPayDate
              paidAt
              unsubscribedAt
              used
              lastUsedAt
              orderId
              unsubscribeRequestedAt
              startDate
              expireDate
              updatedAt
              createdAt
            }
            registeredAt
            salesDate
            updatedAt
            createdAt
          }
          status
          registeredAt
          campaignBlockedAt
          note
          removedAt
          updatedAt
          createdAt
          points {
            items {
              id
              locationId
              storeId
              type
              name
              amount
              origin
              provider
              providerCustomerId
              providerSyncedAt
              currency
              point
              extraPoint
              extraExpire
              items {
                type
                itemId
                name
                parentId
                point
                amount
                quantity
              }
              quantity
              note
              orderId
              referId
              orderNumberPrefix
              orderNumber
              customerId
              pointTransactionCustomerId
              expiredAt
              salesDate
              updatedAt
              createdAt
              customer {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
            }
            nextToken
          }
          credits {
            items {
              id
              locationId
              storeId
              name
              type
              amount
              origin
              note
              currency
              parentId
              orderId
              orderNumberPrefix
              orderNumber
              customerId
              creditTransactionCustomerId
              description
              salesDate
              createdAt
              updatedAt
              customer {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
            }
            nextToken
          }
          _delta
          orders {
            items {
              id
              orderLocationId
              numberPrefix
              number
              beeper
              enterpriseId
              storeId
              customerId
              orderCustomerId
              state
              amount
              amountPaid
              origin
              subOrigin
              originId
              customerProvider
              providerCustomerId
              amountChanged
              amountReturned
              tax
              currency
              quantity
              shippingType
              diningOption
              diningState
              retailOption
              retailState
              name
              phone
              address
              zip
              receivers {
                id
                name
                phone
                zip
                address1
                address2
                addressState
                addressCity
                addressStreet
                addressFull
                note
                shippingCompanyCode
                shippingAcceptCount
                shippingStartCount
                trackingNumber
                privacyAgreedAt
              }
              tableId
              tableIds
              parentDeviceId
              deviceId
              reserveDate
              pickupDate
              shippingDate
              items {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              createdEmployeeId
              createdDeviceId
              updatedDeviceId
              shippingLocationId
              confirmedEmployeeId
              confirmedAt
              createdAt
              paidAt
              salesDate
              returnedAt
              shippedAt
              returnShippedAt
              returnSalesDate
              updatedAt
              noticeCount
              visitorCount
              totalPoint
              pointSaved
              receiptId
              receiptNumber
              receiptCount
              alarmCustomerId
              receiptCustomerId
              note
              request
              charges {
                id
                method
                extraMethod
                gateway
                amount
                tax
                taxRefundAmount
                supplyAmount
                taxFreeAmount
                amountRefunded
                createdEmployeeId
                createdDeviceId
                createdAt
                creditTransactionId
                balance
                splitType
                pg
                van
                origin
                originId
                note
                brand
                name
                ident
                installment
                transactionId
                approver
                issuerCode
                acquirer
                acquirerCode
                acquirerName
                refundable
                recurring
                approvalCode
                approvalDate
                approvalNumber
              }
              returns {
                amount
                currency
                refundId
                createdAt
              }
              fee
              revision
              _delta
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              customer {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
              orderCustomer {
                id
                enterpriseId
                storeId
                locationId
                customerId
                provider
                providerCustomerId
                tier
                customerCreatedAt
                ident
                identType
                type
                name
                nameKeyword
                phone
                email
                birth
                ageGroup
                gender
                status
                registeredAt
                salesDate
                campaignBlockedAt
                blacklistedAt
                isFavorite
                note
                removedAt
                updatedAt
                createdAt
                _delta
                timestamp
                expdate
              }
            }
            nextToken
          }
          location {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          store {
            id
            enterpriseId
            info {
              name
              phone
              email
              representative
              businessType
              businessNumber
              industryType
              sendNumber
              currency
              address
              zip
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              csPhone
            }
            policy {
              multiLocation
              provider {
                key
                ident
                customer
                coupon
                inventory
                locationId
              }
            }
            currency
            payments {
              card
              cardJoined
              cash
              credit
              refund
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            plans {
              menus {
                id
                name
                mode
                size
                updatedAt
                createdAt
              }
              orders {
                id
                name
                updatedAt
                createdAt
              }
            }
            taxes {
              id
              type
              name
              enabled
              rate
              method
              targetType
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            item {
              attributes {
                name
                type
                value
              }
              actualPrice
              skuMgmt
              barcodeMgmt
              stockTrack
              variablePrice
              locationCreatable
            }
            inventory {
              attributes {
                name
                type
                value
              }
              expiryDateMgmt
              expiryDateAlertDays
            }
            customer {
              attributes {
                name
                type
                value
              }
            }
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            kakao {
              agency
              key
              channelId
              categoryCode
              status
              updatedAt
              createdAt
            }
            review {
              status
              campaignId
              updatedAt
              createdAt
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mirror {
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              video {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            notification {
              options {
                type
                email
                sms
              }
            }
            groups {
              items {
                id
                name
                type
                storeGroupsId
                locationGroupsId
                key
                count
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            locations {
              items {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          customer {
            id
            ident
            identType
            type
            name
            addresses {
              id
              main
              detail
              storeId
            }
            phone
            email
            birth
            agreements {
              privacy
              provider
              eula
              marketing
              updatedAt
              createdAt
            }
            ageGroup
            gender
            updatedAt
            createdAt
            stores {
              items {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
              nextToken
            }
          }
        }
        orderCustomer {
          id
          enterpriseId
          storeId
          locationId
          customerId
          provider
          providerCustomerId
          tier
          customerCreatedAt
          ident
          identType
          type
          name
          nameKeyword
          payments {
            id
            method
            pg
            number
            name
            expMonth
            expYear
            billingKey
            updatedAt
            createdAt
          }
          attributes {
            name
            type
            value
          }
          addresses {
            id
            main
            detail
            storeId
          }
          phone
          email
          birth
          agreements {
            privacy
            provider
            eula
            marketing
            updatedAt
            createdAt
          }
          ageGroup
          gender
          tags {
            tagId
            createdLocationId
          }
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          status
          registeredAt
          salesDate
          campaignBlockedAt
          blacklistedAt
          isFavorite
          locations {
            id
            locationId
            isFavorite
            status
            note
            campaignBlockedAt
            blacklistedAt
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            subscriptions {
              id
              planId
              payStatus
              sessionId
              nextPayDate
              paidAt
              unsubscribedAt
              used
              lastUsedAt
              orderId
              unsubscribeRequestedAt
              startDate
              expireDate
              updatedAt
              createdAt
            }
            registeredAt
            salesDate
            updatedAt
            createdAt
          }
          subscriptions {
            id
            planId
            payStatus
            sessionId
            nextPayDate
            paidAt
            unsubscribedAt
            used
            lastUsedAt
            orderId
            unsubscribeRequestedAt
            startDate
            expireDate
            updatedAt
            createdAt
          }
          note
          sources {
            id
            type
            name
            ident
            createdAt
          }
          removedAt
          updatedAt
          createdAt
          _delta
          timestamp
          expdate
        }
      }
    }
  }
`;
export const deleteOrderTicketDelta = /* GraphQL */ `
  mutation DeleteOrderTicketDelta($input: DeleteOrderTicketDeltaInput!) {
    deleteOrderTicketDelta(input: $input) {
      id
      storeId
      locationId
      orderId
      items {
        id
        diningState
      }
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
      order {
        id
        orderLocationId
        numberPrefix
        number
        beeper
        enterpriseId
        storeId
        customerId
        orderCustomerId
        state
        amount
        amountPaid
        origin
        subOrigin
        originId
        customerProvider
        providerCustomerId
        amountChanged
        amountReturned
        tax
        currency
        quantity
        shippingType
        diningOption
        diningState
        retailOption
        retailState
        name
        phone
        address
        zip
        receivers {
          id
          name
          phone
          zip
          address1
          address2
          addressState
          addressCity
          addressStreet
          addressFull
          note
          shippingCompanyCode
          shippingAcceptCount
          shippingStartCount
          trackingNumber
          privacyAgreedAt
        }
        tableId
        tableIds
        parentDeviceId
        deviceId
        reserveDate
        pickupDate
        shippingDate
        items {
          id
          type
          index
          category
          categories {
            id
            name
          }
          classificationIds
          productId
          modifierId
          name
          description
          amount
          price
          parentId
          referType
          referId
          campaignId
          requestId
          quantity
          sku
          tax
          taxRate
          diningOption
          diningState
          modifiers {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          discounts {
            id
            scope
            type
            amount
            name
          }
          discountable
          discountQuantity
          discountIncludeIds
          discountExcludeIds
          discountMethod
          discountValue
          orderedQuantity
          voidQuantity
          paidQuantity
          readyQuantity
          receivedQuantity
          inventoryReason
          point
          note
          createdAt
        }
        createdEmployeeId
        createdDeviceId
        updatedDeviceId
        shippingLocationId
        confirmedEmployeeId
        confirmedAt
        createdAt
        paidAt
        salesDate
        returnedAt
        shippedAt
        returnShippedAt
        returnSalesDate
        updatedAt
        noticeCount
        visitorCount
        totalPoint
        pointSaved
        receiptId
        receiptNumber
        receiptCount
        alarmCustomerId
        receiptCustomerId
        note
        request
        charges {
          id
          method
          extraMethod
          gateway
          amount
          tax
          taxRefundAmount
          purchaser {
            name
            number
            nation
            address
          }
          supplyAmount
          taxFreeAmount
          amountRefunded
          createdEmployeeId
          createdDeviceId
          createdAt
          creditTransactionId
          balance
          splitType
          pg
          van
          origin
          originId
          note
          brand
          name
          ident
          installment
          transactionId
          approver
          issuerCode
          acquirer
          acquirerCode
          acquirerName
          refundable
          recurring
          approvalCode
          approvalDate
          approvalNumber
          signature {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          refunds {
            id
            amount
            supplyAmount
            taxFreeAmount
            tax
            taxRefundAmount
            creditTransactionId
            approvalNumber
            approvalDate
            transactionId
            origin
            originId
            currency
            reason
            signature {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            createdEmployeeId
            createdDeviceId
            createdAt
          }
        }
        returns {
          amount
          currency
          items {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          refundId
          createdAt
        }
        fee
        revision
        _delta
        location {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        customer {
          id
          customerId
          storeId
          sources {
            id
            type
            name
            ident
            createdAt
          }
          isFavorite
          locationId
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          favoriteLocationIds
          visitedLocationIds
          locations {
            id
            locationId
            isFavorite
            status
            note
            campaignBlockedAt
            blacklistedAt
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            subscriptions {
              id
              planId
              payStatus
              sessionId
              nextPayDate
              paidAt
              unsubscribedAt
              used
              lastUsedAt
              orderId
              unsubscribeRequestedAt
              startDate
              expireDate
              updatedAt
              createdAt
            }
            registeredAt
            salesDate
            updatedAt
            createdAt
          }
          status
          registeredAt
          campaignBlockedAt
          note
          removedAt
          updatedAt
          createdAt
          points {
            items {
              id
              locationId
              storeId
              type
              name
              amount
              origin
              provider
              providerCustomerId
              providerSyncedAt
              currency
              point
              extraPoint
              extraExpire
              items {
                type
                itemId
                name
                parentId
                point
                amount
                quantity
              }
              quantity
              note
              orderId
              referId
              orderNumberPrefix
              orderNumber
              customerId
              pointTransactionCustomerId
              expiredAt
              salesDate
              updatedAt
              createdAt
              customer {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
            }
            nextToken
          }
          credits {
            items {
              id
              locationId
              storeId
              name
              type
              amount
              origin
              note
              currency
              parentId
              orderId
              orderNumberPrefix
              orderNumber
              customerId
              creditTransactionCustomerId
              description
              salesDate
              createdAt
              updatedAt
              customer {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
            }
            nextToken
          }
          _delta
          orders {
            items {
              id
              orderLocationId
              numberPrefix
              number
              beeper
              enterpriseId
              storeId
              customerId
              orderCustomerId
              state
              amount
              amountPaid
              origin
              subOrigin
              originId
              customerProvider
              providerCustomerId
              amountChanged
              amountReturned
              tax
              currency
              quantity
              shippingType
              diningOption
              diningState
              retailOption
              retailState
              name
              phone
              address
              zip
              receivers {
                id
                name
                phone
                zip
                address1
                address2
                addressState
                addressCity
                addressStreet
                addressFull
                note
                shippingCompanyCode
                shippingAcceptCount
                shippingStartCount
                trackingNumber
                privacyAgreedAt
              }
              tableId
              tableIds
              parentDeviceId
              deviceId
              reserveDate
              pickupDate
              shippingDate
              items {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              createdEmployeeId
              createdDeviceId
              updatedDeviceId
              shippingLocationId
              confirmedEmployeeId
              confirmedAt
              createdAt
              paidAt
              salesDate
              returnedAt
              shippedAt
              returnShippedAt
              returnSalesDate
              updatedAt
              noticeCount
              visitorCount
              totalPoint
              pointSaved
              receiptId
              receiptNumber
              receiptCount
              alarmCustomerId
              receiptCustomerId
              note
              request
              charges {
                id
                method
                extraMethod
                gateway
                amount
                tax
                taxRefundAmount
                supplyAmount
                taxFreeAmount
                amountRefunded
                createdEmployeeId
                createdDeviceId
                createdAt
                creditTransactionId
                balance
                splitType
                pg
                van
                origin
                originId
                note
                brand
                name
                ident
                installment
                transactionId
                approver
                issuerCode
                acquirer
                acquirerCode
                acquirerName
                refundable
                recurring
                approvalCode
                approvalDate
                approvalNumber
              }
              returns {
                amount
                currency
                refundId
                createdAt
              }
              fee
              revision
              _delta
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              customer {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
              orderCustomer {
                id
                enterpriseId
                storeId
                locationId
                customerId
                provider
                providerCustomerId
                tier
                customerCreatedAt
                ident
                identType
                type
                name
                nameKeyword
                phone
                email
                birth
                ageGroup
                gender
                status
                registeredAt
                salesDate
                campaignBlockedAt
                blacklistedAt
                isFavorite
                note
                removedAt
                updatedAt
                createdAt
                _delta
                timestamp
                expdate
              }
            }
            nextToken
          }
          location {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          store {
            id
            enterpriseId
            info {
              name
              phone
              email
              representative
              businessType
              businessNumber
              industryType
              sendNumber
              currency
              address
              zip
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              csPhone
            }
            policy {
              multiLocation
              provider {
                key
                ident
                customer
                coupon
                inventory
                locationId
              }
            }
            currency
            payments {
              card
              cardJoined
              cash
              credit
              refund
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            plans {
              menus {
                id
                name
                mode
                size
                updatedAt
                createdAt
              }
              orders {
                id
                name
                updatedAt
                createdAt
              }
            }
            taxes {
              id
              type
              name
              enabled
              rate
              method
              targetType
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            item {
              attributes {
                name
                type
                value
              }
              actualPrice
              skuMgmt
              barcodeMgmt
              stockTrack
              variablePrice
              locationCreatable
            }
            inventory {
              attributes {
                name
                type
                value
              }
              expiryDateMgmt
              expiryDateAlertDays
            }
            customer {
              attributes {
                name
                type
                value
              }
            }
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            kakao {
              agency
              key
              channelId
              categoryCode
              status
              updatedAt
              createdAt
            }
            review {
              status
              campaignId
              updatedAt
              createdAt
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mirror {
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              video {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            notification {
              options {
                type
                email
                sms
              }
            }
            groups {
              items {
                id
                name
                type
                storeGroupsId
                locationGroupsId
                key
                count
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            locations {
              items {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          customer {
            id
            ident
            identType
            type
            name
            addresses {
              id
              main
              detail
              storeId
            }
            phone
            email
            birth
            agreements {
              privacy
              provider
              eula
              marketing
              updatedAt
              createdAt
            }
            ageGroup
            gender
            updatedAt
            createdAt
            stores {
              items {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
              nextToken
            }
          }
        }
        orderCustomer {
          id
          enterpriseId
          storeId
          locationId
          customerId
          provider
          providerCustomerId
          tier
          customerCreatedAt
          ident
          identType
          type
          name
          nameKeyword
          payments {
            id
            method
            pg
            number
            name
            expMonth
            expYear
            billingKey
            updatedAt
            createdAt
          }
          attributes {
            name
            type
            value
          }
          addresses {
            id
            main
            detail
            storeId
          }
          phone
          email
          birth
          agreements {
            privacy
            provider
            eula
            marketing
            updatedAt
            createdAt
          }
          ageGroup
          gender
          tags {
            tagId
            createdLocationId
          }
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          status
          registeredAt
          salesDate
          campaignBlockedAt
          blacklistedAt
          isFavorite
          locations {
            id
            locationId
            isFavorite
            status
            note
            campaignBlockedAt
            blacklistedAt
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            subscriptions {
              id
              planId
              payStatus
              sessionId
              nextPayDate
              paidAt
              unsubscribedAt
              used
              lastUsedAt
              orderId
              unsubscribeRequestedAt
              startDate
              expireDate
              updatedAt
              createdAt
            }
            registeredAt
            salesDate
            updatedAt
            createdAt
          }
          subscriptions {
            id
            planId
            payStatus
            sessionId
            nextPayDate
            paidAt
            unsubscribedAt
            used
            lastUsedAt
            orderId
            unsubscribeRequestedAt
            startDate
            expireDate
            updatedAt
            createdAt
          }
          note
          sources {
            id
            type
            name
            ident
            createdAt
          }
          removedAt
          updatedAt
          createdAt
          _delta
          timestamp
          expdate
        }
      }
    }
  }
`;
export const createCustomerTag = /* GraphQL */ `
  mutation CreateCustomerTag($input: CreateCustomerTagInput!) {
    createCustomerTag(input: $input) {
      id
      label
      description
      color
      customerIds
      storeId
      locationId
      type
      key
      conditions {
        key
        object
        field
        operations {
          operation
          type
          value
        }
        value
        operation
        type
      }
      updatedAt
      createdAt
    }
  }
`;
export const updateCustomerTag = /* GraphQL */ `
  mutation UpdateCustomerTag($input: UpdateCustomerTagInput!) {
    updateCustomerTag(input: $input) {
      id
      label
      description
      color
      customerIds
      storeId
      locationId
      type
      key
      conditions {
        key
        object
        field
        operations {
          operation
          type
          value
        }
        value
        operation
        type
      }
      updatedAt
      createdAt
    }
  }
`;
export const deleteCustomerTag = /* GraphQL */ `
  mutation DeleteCustomerTag($input: DeleteCustomerTagInput!) {
    deleteCustomerTag(input: $input) {
      id
      label
      description
      color
      customerIds
      storeId
      locationId
      type
      key
      conditions {
        key
        object
        field
        operations {
          operation
          type
          value
        }
        value
        operation
        type
      }
      updatedAt
      createdAt
    }
  }
`;
export const createCustomerTagDelta = /* GraphQL */ `
  mutation CreateCustomerTagDelta($input: CreateCustomerTagDeltaInput!) {
    createCustomerTagDelta(input: $input) {
      id
      type
      label
      description
      color
      storeId
      locationId
      conditions {
        key
        object
        field
        operations {
          operation
          type
          value
        }
        value
        operation
        type
      }
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const updateCustomerTagDelta = /* GraphQL */ `
  mutation UpdateCustomerTagDelta($input: UpdateCustomerTagDeltaInput!) {
    updateCustomerTagDelta(input: $input) {
      id
      type
      label
      description
      color
      storeId
      locationId
      conditions {
        key
        object
        field
        operations {
          operation
          type
          value
        }
        value
        operation
        type
      }
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const deleteCustomerTagDelta = /* GraphQL */ `
  mutation DeleteCustomerTagDelta($input: DeleteCustomerTagDeltaInput!) {
    deleteCustomerTagDelta(input: $input) {
      id
      type
      label
      description
      color
      storeId
      locationId
      conditions {
        key
        object
        field
        operations {
          operation
          type
          value
        }
        value
        operation
        type
      }
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const createCoupon = /* GraphQL */ `
  mutation CreateCoupon($input: CreateCouponInput!) {
    createCoupon(input: $input) {
      id
      storeId
      enterpriseId
      locationId
      activeLocationIds
      inactiveLocationIds
      publishableLocationIds
      provider
      providerCouponId
      name
      description
      availableOrigins
      code
      type
      method
      tranCount
      generateCount
      publishCount
      limitPublishCount
      duplicatePublish
      conditionType
      conditionQuantity
      conditionAmount
      conditionItems
      conditionItemsOperator
      conditionTimes {
        sun {
          open
          close
        }
        mon {
          open
          close
        }
        tue {
          open
          close
        }
        wed {
          open
          close
        }
        thu {
          open
          close
        }
        fri {
          open
          close
        }
        sat {
          open
          close
        }
      }
      conditionTier
      discountMethod
      discountValue
      applyScope
      applyItemId
      applyCandidateItemIds
      referId
      validDateType
      validValue
      image {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      notice
      pointSavable
      startedAt
      endedAt
      updatedAt
      createdAt
      transactions {
        items {
          id
          usedAt
          state
          amount
          quantity
          refundedAt
          orderId
          customerId
          couponTransactionCustomerId
          updatedAt
          createdAt
          expiredDate
          barcode
          generateLocationId
          generatedAt
          couponTransactionsId
          enterpriseId
          storeId
          locationId
          activeLocationIds
          inactiveLocationIds
          publishableLocationIds
          provider
          providerCouponId
          providerTransactionId
          providerCustomerId
          name
          code
          type
          method
          conditionType
          conditionQuantity
          conditionAmount
          conditionItems
          conditionTimes {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          conditionTier
          discountMethod
          discountValue
          applyScope
          applyItemId
          applyCandidateItemIds
          campaignId
          startedAt
          endedAt
        }
        nextToken
      }
    }
  }
`;
export const updateCoupon = /* GraphQL */ `
  mutation UpdateCoupon($input: UpdateCouponInput!) {
    updateCoupon(input: $input) {
      id
      storeId
      enterpriseId
      locationId
      activeLocationIds
      inactiveLocationIds
      publishableLocationIds
      provider
      providerCouponId
      name
      description
      availableOrigins
      code
      type
      method
      tranCount
      generateCount
      publishCount
      limitPublishCount
      duplicatePublish
      conditionType
      conditionQuantity
      conditionAmount
      conditionItems
      conditionItemsOperator
      conditionTimes {
        sun {
          open
          close
        }
        mon {
          open
          close
        }
        tue {
          open
          close
        }
        wed {
          open
          close
        }
        thu {
          open
          close
        }
        fri {
          open
          close
        }
        sat {
          open
          close
        }
      }
      conditionTier
      discountMethod
      discountValue
      applyScope
      applyItemId
      applyCandidateItemIds
      referId
      validDateType
      validValue
      image {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      notice
      pointSavable
      startedAt
      endedAt
      updatedAt
      createdAt
      transactions {
        items {
          id
          usedAt
          state
          amount
          quantity
          refundedAt
          orderId
          customerId
          couponTransactionCustomerId
          updatedAt
          createdAt
          expiredDate
          barcode
          generateLocationId
          generatedAt
          couponTransactionsId
          enterpriseId
          storeId
          locationId
          activeLocationIds
          inactiveLocationIds
          publishableLocationIds
          provider
          providerCouponId
          providerTransactionId
          providerCustomerId
          name
          code
          type
          method
          conditionType
          conditionQuantity
          conditionAmount
          conditionItems
          conditionTimes {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          conditionTier
          discountMethod
          discountValue
          applyScope
          applyItemId
          applyCandidateItemIds
          campaignId
          startedAt
          endedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteCoupon = /* GraphQL */ `
  mutation DeleteCoupon($input: DeleteCouponInput!) {
    deleteCoupon(input: $input) {
      id
      storeId
      enterpriseId
      locationId
      activeLocationIds
      inactiveLocationIds
      publishableLocationIds
      provider
      providerCouponId
      name
      description
      availableOrigins
      code
      type
      method
      tranCount
      generateCount
      publishCount
      limitPublishCount
      duplicatePublish
      conditionType
      conditionQuantity
      conditionAmount
      conditionItems
      conditionItemsOperator
      conditionTimes {
        sun {
          open
          close
        }
        mon {
          open
          close
        }
        tue {
          open
          close
        }
        wed {
          open
          close
        }
        thu {
          open
          close
        }
        fri {
          open
          close
        }
        sat {
          open
          close
        }
      }
      conditionTier
      discountMethod
      discountValue
      applyScope
      applyItemId
      applyCandidateItemIds
      referId
      validDateType
      validValue
      image {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      notice
      pointSavable
      startedAt
      endedAt
      updatedAt
      createdAt
      transactions {
        items {
          id
          usedAt
          state
          amount
          quantity
          refundedAt
          orderId
          customerId
          couponTransactionCustomerId
          updatedAt
          createdAt
          expiredDate
          barcode
          generateLocationId
          generatedAt
          couponTransactionsId
          enterpriseId
          storeId
          locationId
          activeLocationIds
          inactiveLocationIds
          publishableLocationIds
          provider
          providerCouponId
          providerTransactionId
          providerCustomerId
          name
          code
          type
          method
          conditionType
          conditionQuantity
          conditionAmount
          conditionItems
          conditionTimes {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          conditionTier
          discountMethod
          discountValue
          applyScope
          applyItemId
          applyCandidateItemIds
          campaignId
          startedAt
          endedAt
        }
        nextToken
      }
    }
  }
`;
export const updateReviewCampaignResult = /* GraphQL */ `
  mutation UpdateReviewCampaignResult(
    $input: UpdateReviewCampaignResultInput!
  ) {
    updateReviewCampaignResult(input: $input) {
      id
      campaignId
      enterpriseId
      storeId
      locationId
      orderId
      orderNumberPrefix
      orderNumber
      customerId
      storeCustomerId
      reserveDate
      sentAt
      submittedAt
      rate
      comment
      answers
      updatedAt
      createdAt
    }
  }
`;
export const createPurchaseOrder = /* GraphQL */ `
  mutation CreatePurchaseOrder($input: CreatePurchaseOrderInput!) {
    createPurchaseOrder(input: $input) {
      id
      number
      supplierId
      supplyingLocationId
      status
      supplierOrigin
      inventories {
        id
        inventoryId
        productId
        skuId
        quantity
        receivedQuantity
        waitingQuantity
        cancelledQuantity
        cost
        note
      }
      storeId
      locationId
      createdByEmployeeId
      createdLocationId
      note
      extraFees {
        id
        type
        value
        name
      }
      excludeVat
      expectDate
      confirmedAt
      receivedAt
      updatedAt
      createdAt
    }
  }
`;
export const updatePurchaseOrder = /* GraphQL */ `
  mutation UpdatePurchaseOrder($input: UpdatePurchaseOrderInput!) {
    updatePurchaseOrder(input: $input) {
      id
      number
      supplierId
      supplyingLocationId
      status
      supplierOrigin
      inventories {
        id
        inventoryId
        productId
        skuId
        quantity
        receivedQuantity
        waitingQuantity
        cancelledQuantity
        cost
        note
      }
      storeId
      locationId
      createdByEmployeeId
      createdLocationId
      note
      extraFees {
        id
        type
        value
        name
      }
      excludeVat
      expectDate
      confirmedAt
      receivedAt
      updatedAt
      createdAt
    }
  }
`;
export const deletePurchaseOrder = /* GraphQL */ `
  mutation DeletePurchaseOrder($input: DeletePurchaseOrderInput!) {
    deletePurchaseOrder(input: $input) {
      id
      number
      supplierId
      supplyingLocationId
      status
      supplierOrigin
      inventories {
        id
        inventoryId
        productId
        skuId
        quantity
        receivedQuantity
        waitingQuantity
        cancelledQuantity
        cost
        note
      }
      storeId
      locationId
      createdByEmployeeId
      createdLocationId
      note
      extraFees {
        id
        type
        value
        name
      }
      excludeVat
      expectDate
      confirmedAt
      receivedAt
      updatedAt
      createdAt
    }
  }
`;
export const createStoreSupplier = /* GraphQL */ `
  mutation CreateStoreSupplier($input: CreateStoreSupplierInput!) {
    createStoreSupplier(input: $input) {
      id
      storeId
      name
      phone
      fax
      email
      note
      address
      updatedAt
      createdAt
    }
  }
`;
export const updateStoreSupplier = /* GraphQL */ `
  mutation UpdateStoreSupplier($input: UpdateStoreSupplierInput!) {
    updateStoreSupplier(input: $input) {
      id
      storeId
      name
      phone
      fax
      email
      note
      address
      updatedAt
      createdAt
    }
  }
`;
export const deleteStoreSupplier = /* GraphQL */ `
  mutation DeleteStoreSupplier($input: DeleteStoreSupplierInput!) {
    deleteStoreSupplier(input: $input) {
      id
      storeId
      name
      phone
      fax
      email
      note
      address
      updatedAt
      createdAt
    }
  }
`;
export const createItemDelta = /* GraphQL */ `
  mutation CreateItemDelta($input: CreateItemDeltaInput!) {
    createItemDelta(input: $input) {
      id
      itemId
      type
      enterpriseId
      storeId
      name
      nameMl {
        en
        zh
        ja
      }
      attributes {
        name
        type
        value
      }
      displayName
      origin
      refer
      referId
      depth
      parentId
      description
      descriptionMl {
        en
        zh
        ja
      }
      thumbnail {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      variablePrice
      skus {
        type
        id
        itemId
        refer
        referId
        attributes {
          name
          type
          value
        }
        name
        nameMl {
          en
          zh
          ja
        }
        price
        cost
        unit
        unitSuffix
        barcode
        currency
        model
        locationId
        futureLocationAvailable
        locationIds
        inventories {
          id
          quantity
          cost
          locationId
          unavailableQuantity
          inReadyQuantity
          outReadyQuantity
          expiryDate
          updatedAt
          createdAt
          locations {
            id
            quantity
          }
          attributes {
            name
            type
            value
          }
        }
        locations {
          id
          isActive
          available
          price
        }
        links {
          type
          origin
          originId
          originProductId
          updatedAt
          createdAt
        }
        stock
        stockAlert
        stockAlertQty
        stockTrack
        quantity
        limitQuantity
        isActive
      }
      trackStock
      taxId
      ordering
      categoryIds
      classificationIds
      modifierIds
      productIds
      locationIds
      orderType
      defaultSkuId
      coverage
      required
      requiredQuantity
      limitQuantity
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const updateItemDelta = /* GraphQL */ `
  mutation UpdateItemDelta($input: UpdateItemDeltaInput!) {
    updateItemDelta(input: $input) {
      id
      itemId
      type
      enterpriseId
      storeId
      name
      nameMl {
        en
        zh
        ja
      }
      attributes {
        name
        type
        value
      }
      displayName
      origin
      refer
      referId
      depth
      parentId
      description
      descriptionMl {
        en
        zh
        ja
      }
      thumbnail {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      variablePrice
      skus {
        type
        id
        itemId
        refer
        referId
        attributes {
          name
          type
          value
        }
        name
        nameMl {
          en
          zh
          ja
        }
        price
        cost
        unit
        unitSuffix
        barcode
        currency
        model
        locationId
        futureLocationAvailable
        locationIds
        inventories {
          id
          quantity
          cost
          locationId
          unavailableQuantity
          inReadyQuantity
          outReadyQuantity
          expiryDate
          updatedAt
          createdAt
          locations {
            id
            quantity
          }
          attributes {
            name
            type
            value
          }
        }
        locations {
          id
          isActive
          available
          price
        }
        links {
          type
          origin
          originId
          originProductId
          updatedAt
          createdAt
        }
        stock
        stockAlert
        stockAlertQty
        stockTrack
        quantity
        limitQuantity
        isActive
      }
      trackStock
      taxId
      ordering
      categoryIds
      classificationIds
      modifierIds
      productIds
      locationIds
      orderType
      defaultSkuId
      coverage
      required
      requiredQuantity
      limitQuantity
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const deleteItemDelta = /* GraphQL */ `
  mutation DeleteItemDelta($input: DeleteItemDeltaInput!) {
    deleteItemDelta(input: $input) {
      id
      itemId
      type
      enterpriseId
      storeId
      name
      nameMl {
        en
        zh
        ja
      }
      attributes {
        name
        type
        value
      }
      displayName
      origin
      refer
      referId
      depth
      parentId
      description
      descriptionMl {
        en
        zh
        ja
      }
      thumbnail {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      variablePrice
      skus {
        type
        id
        itemId
        refer
        referId
        attributes {
          name
          type
          value
        }
        name
        nameMl {
          en
          zh
          ja
        }
        price
        cost
        unit
        unitSuffix
        barcode
        currency
        model
        locationId
        futureLocationAvailable
        locationIds
        inventories {
          id
          quantity
          cost
          locationId
          unavailableQuantity
          inReadyQuantity
          outReadyQuantity
          expiryDate
          updatedAt
          createdAt
          locations {
            id
            quantity
          }
          attributes {
            name
            type
            value
          }
        }
        locations {
          id
          isActive
          available
          price
        }
        links {
          type
          origin
          originId
          originProductId
          updatedAt
          createdAt
        }
        stock
        stockAlert
        stockAlertQty
        stockTrack
        quantity
        limitQuantity
        isActive
      }
      trackStock
      taxId
      ordering
      categoryIds
      classificationIds
      modifierIds
      productIds
      locationIds
      orderType
      defaultSkuId
      coverage
      required
      requiredQuantity
      limitQuantity
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      id
      type
      enterpriseId
      storeId
      origin
      refer
      referId
      name
      nameMl {
        en
        zh
        ja
      }
      description
      descriptionMl {
        en
        zh
        ja
      }
      variablePrice
      attributes {
        name
        type
        value
      }
      thumbnail {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      skus {
        type
        id
        itemId
        refer
        referId
        attributes {
          name
          type
          value
        }
        name
        nameMl {
          en
          zh
          ja
        }
        price
        cost
        unit
        unitSuffix
        barcode
        currency
        model
        locationId
        futureLocationAvailable
        locationIds
        inventories {
          id
          quantity
          cost
          locationId
          unavailableQuantity
          inReadyQuantity
          outReadyQuantity
          expiryDate
          updatedAt
          createdAt
          locations {
            id
            quantity
          }
          attributes {
            name
            type
            value
          }
        }
        locations {
          id
          isActive
          available
          price
        }
        links {
          type
          origin
          originId
          originProductId
          updatedAt
          createdAt
        }
        stock
        stockAlert
        stockAlertQty
        stockTrack
        quantity
        limitQuantity
        isActive
      }
      trackStock
      taxId
      categories {
        items {
          id
          enterpriseId
          storeId
          productId
          categoryId
          category {
            type
            id
            enterpriseId
            storeId
            name
            displayName
            refer
            referId
            products {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
          updatedAt
          createdAt
          product {
            id
            type
            enterpriseId
            storeId
            origin
            refer
            referId
            name
            nameMl {
              en
              zh
              ja
            }
            description
            descriptionMl {
              en
              zh
              ja
            }
            variablePrice
            attributes {
              name
              type
              value
            }
            thumbnail {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            skus {
              type
              id
              itemId
              refer
              referId
              attributes {
                name
                type
                value
              }
              name
              nameMl {
                en
                zh
                ja
              }
              price
              cost
              unit
              unitSuffix
              barcode
              currency
              model
              locationId
              futureLocationAvailable
              locationIds
              inventories {
                id
                quantity
                cost
                locationId
                unavailableQuantity
                inReadyQuantity
                outReadyQuantity
                expiryDate
                updatedAt
                createdAt
              }
              locations {
                id
                isActive
                available
                price
              }
              links {
                type
                origin
                originId
                originProductId
                updatedAt
                createdAt
              }
              stock
              stockAlert
              stockAlertQty
              stockTrack
              quantity
              limitQuantity
              isActive
            }
            trackStock
            taxId
            categories {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            classifications {
              items {
                id
                enterpriseId
                storeId
                productId
                classificationId
                updatedAt
                createdAt
              }
              nextToken
            }
            modifiers {
              items {
                id
                enterpriseId
                storeId
                productId
                modifierId
                position
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
        }
        nextToken
      }
      classifications {
        items {
          id
          enterpriseId
          storeId
          productId
          classificationId
          classification {
            type
            id
            enterpriseId
            storeId
            name
            displayName
            refer
            referId
            products {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
          updatedAt
          createdAt
          product {
            id
            type
            enterpriseId
            storeId
            origin
            refer
            referId
            name
            nameMl {
              en
              zh
              ja
            }
            description
            descriptionMl {
              en
              zh
              ja
            }
            variablePrice
            attributes {
              name
              type
              value
            }
            thumbnail {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            skus {
              type
              id
              itemId
              refer
              referId
              attributes {
                name
                type
                value
              }
              name
              nameMl {
                en
                zh
                ja
              }
              price
              cost
              unit
              unitSuffix
              barcode
              currency
              model
              locationId
              futureLocationAvailable
              locationIds
              inventories {
                id
                quantity
                cost
                locationId
                unavailableQuantity
                inReadyQuantity
                outReadyQuantity
                expiryDate
                updatedAt
                createdAt
              }
              locations {
                id
                isActive
                available
                price
              }
              links {
                type
                origin
                originId
                originProductId
                updatedAt
                createdAt
              }
              stock
              stockAlert
              stockAlertQty
              stockTrack
              quantity
              limitQuantity
              isActive
            }
            trackStock
            taxId
            categories {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            classifications {
              items {
                id
                enterpriseId
                storeId
                productId
                classificationId
                updatedAt
                createdAt
              }
              nextToken
            }
            modifiers {
              items {
                id
                enterpriseId
                storeId
                productId
                modifierId
                position
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
        }
        nextToken
      }
      modifiers {
        items {
          id
          enterpriseId
          storeId
          productId
          modifierId
          position
          modifier {
            id
            type
            enterpriseId
            storeId
            name
            nameMl {
              en
              zh
              ja
            }
            skus {
              type
              id
              itemId
              refer
              referId
              attributes {
                name
                type
                value
              }
              name
              nameMl {
                en
                zh
                ja
              }
              price
              cost
              unit
              unitSuffix
              barcode
              currency
              model
              locationId
              futureLocationAvailable
              locationIds
              inventories {
                id
                quantity
                cost
                locationId
                unavailableQuantity
                inReadyQuantity
                outReadyQuantity
                expiryDate
                updatedAt
                createdAt
              }
              locations {
                id
                isActive
                available
                price
              }
              links {
                type
                origin
                originId
                originProductId
                updatedAt
                createdAt
              }
              stock
              stockAlert
              stockAlertQty
              stockTrack
              quantity
              limitQuantity
              isActive
            }
            origin
            orderType
            defaultSkuId
            coverage
            required
            requiredQuantity
            limitQuantity
            products {
              items {
                id
                enterpriseId
                storeId
                productId
                modifierId
                position
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
          updatedAt
          createdAt
          product {
            id
            type
            enterpriseId
            storeId
            origin
            refer
            referId
            name
            nameMl {
              en
              zh
              ja
            }
            description
            descriptionMl {
              en
              zh
              ja
            }
            variablePrice
            attributes {
              name
              type
              value
            }
            thumbnail {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            skus {
              type
              id
              itemId
              refer
              referId
              attributes {
                name
                type
                value
              }
              name
              nameMl {
                en
                zh
                ja
              }
              price
              cost
              unit
              unitSuffix
              barcode
              currency
              model
              locationId
              futureLocationAvailable
              locationIds
              inventories {
                id
                quantity
                cost
                locationId
                unavailableQuantity
                inReadyQuantity
                outReadyQuantity
                expiryDate
                updatedAt
                createdAt
              }
              locations {
                id
                isActive
                available
                price
              }
              links {
                type
                origin
                originId
                originProductId
                updatedAt
                createdAt
              }
              stock
              stockAlert
              stockAlertQty
              stockTrack
              quantity
              limitQuantity
              isActive
            }
            trackStock
            taxId
            categories {
              items {
                id
                enterpriseId
                storeId
                productId
                categoryId
                updatedAt
                createdAt
              }
              nextToken
            }
            classifications {
              items {
                id
                enterpriseId
                storeId
                productId
                classificationId
                updatedAt
                createdAt
              }
              nextToken
            }
            modifiers {
              items {
                id
                enterpriseId
                storeId
                productId
                modifierId
                position
                updatedAt
                createdAt
              }
              nextToken
            }
            locationIds
            updatedAt
            createdAt
          }
        }
        nextToken
      }
      locationIds
      updatedAt
      createdAt
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation($input: UpdateLocationInput!) {
    updateLocation(input: $input) {
      id
      name
      type
      provider
      address
      zip
      phone
      logo {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      representative
      businessNumber
      billing {
        key
        businessNumber
        name
        email
        phone
        contanctName
        contactName
        address
        zip
        card {
          pg
          number
          name
          expMonth
          expYear
          identify
          password
          billingKey
        }
      }
      credit {
        autoCharge
        autoChargeCredit
        autoChargeQuantity
        alertCredit
      }
      businessHours {
        sun {
          open
          close
        }
        mon {
          open
          close
        }
        tue {
          open
          close
        }
        wed {
          open
          close
        }
        thu {
          open
          close
        }
        fri {
          open
          close
        }
        sat {
          open
          close
        }
      }
      storeId
      state
      menuPlanId
      payments {
        refund
        barcode
        barcodeVan
        barcodeTerminalId
      }
      policy {
        locationCost
        loyaltyCost
      }
      loyalty {
        status
        provider
        sharePolicy
        type
        grade
        conditionType
        conditionSubType
        condition {
          minAmount
          perAmount
          minTime
          rate
          expirationPeriod
          excludeProdIds
          discountPointSavable
        }
        conditionItems {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        conditionTiers {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardType
        rewardSubType
        reward {
          requirePoint
          requireOrderAmount
          minUsePoint
          minRequireRate
          maxUsePoint
        }
        rewardItems {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardRates {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardAmounts {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardCashs {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        incentives {
          fulfill
          fulfillPoint
          fulfillCampaignId
          birth
          birthPoint
          birthCampaignId
          birthItem {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
        }
        notification {
          register
          registerCampainId
          save
          saveCampaignId
          use
          useCampaignId
          expire
          expireCampaignId
        }
      }
      kiosk {
        status
        callable
        payable
        menus {
          title
          titleMl {
            en
            zh
            ja
          }
          index
          ids
          items {
            parentId
            badge
            type
            position
            activeOptions
          }
          parentId
        }
        multilingual
        displayImages {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        exchangeTicket
        columnSize
        discounts {
          condition
          couponId
        }
        menuDescription
        updatedAt
        createdAt
      }
      receipt {
        salesSlip
        orderList
        barcode
        footnote
        phone
        orderNumberHide
        orderSheetFontSize
        billsBusinessInfo
        giftFootnote
        giftReceipt
      }
      menuOrder {
        callOptions {
          index
          label
        }
        orderable
        menus {
          title
          titleMl {
            en
            zh
            ja
          }
          index
          ids
          items {
            parentId
            badge
            type
            position
            activeOptions
          }
          parentId
        }
        multilingual
        displayImages {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        columnSize
        fontSize
        notice
        resetTime
        enableCash
        boardType
      }
      kds {
        layout
        alerts {
          urgent
          warning
        }
        disabledProdIds
        orderNotify
      }
      mini {
        defaultItemName
      }
      orderPlanId
      menu {
        board {
          textSize
          columnSize
          pages {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          favorite {
            parentId
            badge
            type
            position
            activeOptions
          }
          enableFavorite
        }
        list {
          pages {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          favorite {
            parentId
            badge
            type
            position
            activeOptions
          }
          shortcuts {
            type
            title
            itemName
            itemPrice
            active
          }
        }
        navOrder
      }
      menus {
        parentId
        position
        page
        pageTitle
      }
      favMenus {
        parentId
        position
        page
        pageTitle
      }
      catMenus {
        title
        index
        ids
        parentId
      }
      market {
        status
        account {
          ident
          holder
          bank
          bankCode
          number
          businessNumber
        }
        paused
        takeoutDiscount
        creditDiscount
        takeout
        intro
        notice
        bookTable
        bookTableNote
        depositAmount
        delivery
        deliveryFee {
          start
          end
          fee
        }
        waitingTime
        itemIds
        updatedAt
        createdAt
      }
      waiting {
        status
        waitingOptions {
          id
          type
          name
          nameMl {
            en
            zh
            ja
          }
          items {
            id
            name
            nameMl {
              en
              zh
              ja
            }
            limitQuantity
          }
          required
          updatedAt
          createdAt
        }
        waitingMinutes
        waitingMinutesHide
        hasForeigner
        foreignerMethod
        notifications {
          created
          ready
          requested
          cancelled
        }
        notices {
          createdNotice
          createdNoticeMl {
            en
            zh
            ja
          }
          requestedNotice
          requestedNoticeMl {
            en
            zh
            ja
          }
        }
        settings {
          autoCancel
          autoCancelMinutes
        }
        updatedAt
        createdAt
      }
      view {
        order {
          excludeColumns
          exportTemplates {
            name
            type
            headers {
              key
              label
            }
          }
        }
        itemReport {
          excludeColumns
        }
        summaryReport {
          excludeColumns
        }
        shippingOrder {
          excludeColumns
        }
        trsItemReport {
          excludeColumns
        }
      }
      subscription {
        status
        account {
          ident
          holder
          bank
          bankCode
          number
          businessNumber
        }
        updatedAt
        createdAt
      }
      settings {
        mode
        table
        diningOption
        retailOption
        orderAlarm
        orderConfirm
        addressInput
        autoPay
        autoReturnItem
        autoSoldout
        menuGridSize
        menuMode
        tableMode
        orderNotify
        orderNotifySkippable
        disablePhonePrefix
        autoCloseScreen
        orderNumberPrefix {
          pos
          kiosk
          menuOrder
        }
        orderMgmt {
          kiosk
          menuorder
          delivery
          pos
        }
        reportBasis
        orderOrigins
        beeper {
          active
          coverage
          skippable
        }
        kioskOptions {
          mode
          isActive
          tableId
          orderId
          callable
          payable
          enableDiningOption
          resetTime
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          columnSize
        }
      }
      devices {
        id
        ident
        locationId
        system
        ipAddress
        brand
        model
        name
        type
        deviceType
        display {
          height
          width
        }
        protocol
        images {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        barcodeReader
        appVersion
        sysVersion
        status
        password
        msgToken
        parentId
        childId
        sleepMode
        kioskOptions {
          mode
          isActive
          tableId
          orderId
          callable
          payable
          enableDiningOption
          resetTime
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          columnSize
        }
        terminalId
        printOptions {
          port
          baudRate
          receipt
          receiptButtonHide
          bills
          refund
          salesEnd
          order
          orderBeep
          orderCount
          orderExcludeIds
          delivery
        }
        updatedAt
        createdAt
      }
      tables {
        id
        width
        height
        name
        axisX
        axisY
        screenHeight
        screenWidth
        initialX
        initialY
        shape
        layout {
          x
          y
          w
          h
        }
        color
        orderId
        disabled
      }
      boardTables {
        title
        index
        items {
          id
          width
          height
          name
          axisX
          axisY
          screenHeight
          screenWidth
          initialX
          initialY
          shape
          layout {
            x
            y
            w
            h
          }
          color
          orderId
          disabled
        }
      }
      gridTables {
        id
        name
        orderId
        index
        page
      }
      modifierSoldoutIds
      delivery {
        baemin
        coupang
        yogiyo
        enableGenProd
        updatedAt
        createdAt
      }
      createdAt
      updatedAt
      sales {
        startedAt
        endedAt
        reserves
        actualBalance
        currency
        note
        estimateBalance
        detail {
          totalSales
          totalRefund
          totalTax
          totalDiscount
          supplyAmount
          totalSalesCount
          cash {
            sales
            refund
          }
          card {
            sales
            refund
          }
          credit {
            sales
            refund
          }
          barcode {
            sales
            refund
          }
          extra {
            sales
            refund
          }
          refund {
            sales
            refund
          }
          products {
            id
            name
            quantity
            amount
          }
          origins {
            pos
            kiosk
            menuorder
            delivery
            market
          }
        }
        reservesHistories {
          type
          amount
          note
          createdEmployeeId
          createdAt
        }
      }
    }
  }
`;
export const createLocationDeviceDelta = /* GraphQL */ `
  mutation CreateLocationDeviceDelta($input: CreateLocationDeviceDeltaInput!) {
    createLocationDeviceDelta(input: $input) {
      id
      ident
      storeId
      locationId
      system
      ipAddress
      brand
      model
      name
      type
      deviceType
      display {
        height
        width
      }
      protocol
      images {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      barcodeReader
      appVersion
      sysVersion
      status
      password
      msgToken
      msgTokenStatus
      parentId
      childId
      sleepMode
      kioskOptions {
        mode
        isActive
        tableId
        orderId
        callable
        payable
        enableDiningOption
        resetTime
        menus {
          title
          titleMl {
            en
            zh
            ja
          }
          index
          ids
          items {
            parentId
            badge
            type
            position
            activeOptions
          }
          parentId
        }
        columnSize
      }
      menuOrderOptions {
        tableId
        orderId
      }
      terminalId
      orderOptions {
        cycleNumber
      }
      printOptions {
        port
        baudRate
        receipt
        receiptButtonHide
        bills
        refund
        salesEnd
        order
        orderBeep
        orderCount
        orderExcludeIds
        delivery
      }
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const updateLocationDeviceDelta = /* GraphQL */ `
  mutation UpdateLocationDeviceDelta($input: UpdateLocationDeviceDeltaInput!) {
    updateLocationDeviceDelta(input: $input) {
      id
      ident
      storeId
      locationId
      system
      ipAddress
      brand
      model
      name
      type
      deviceType
      display {
        height
        width
      }
      protocol
      images {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      barcodeReader
      appVersion
      sysVersion
      status
      password
      msgToken
      msgTokenStatus
      parentId
      childId
      sleepMode
      kioskOptions {
        mode
        isActive
        tableId
        orderId
        callable
        payable
        enableDiningOption
        resetTime
        menus {
          title
          titleMl {
            en
            zh
            ja
          }
          index
          ids
          items {
            parentId
            badge
            type
            position
            activeOptions
          }
          parentId
        }
        columnSize
      }
      menuOrderOptions {
        tableId
        orderId
      }
      terminalId
      orderOptions {
        cycleNumber
      }
      printOptions {
        port
        baudRate
        receipt
        receiptButtonHide
        bills
        refund
        salesEnd
        order
        orderBeep
        orderCount
        orderExcludeIds
        delivery
      }
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const deleteLocationDeviceDelta = /* GraphQL */ `
  mutation DeleteLocationDeviceDelta($input: DeleteLocationDeviceDeltaInput!) {
    deleteLocationDeviceDelta(input: $input) {
      id
      ident
      storeId
      locationId
      system
      ipAddress
      brand
      model
      name
      type
      deviceType
      display {
        height
        width
      }
      protocol
      images {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      barcodeReader
      appVersion
      sysVersion
      status
      password
      msgToken
      msgTokenStatus
      parentId
      childId
      sleepMode
      kioskOptions {
        mode
        isActive
        tableId
        orderId
        callable
        payable
        enableDiningOption
        resetTime
        menus {
          title
          titleMl {
            en
            zh
            ja
          }
          index
          ids
          items {
            parentId
            badge
            type
            position
            activeOptions
          }
          parentId
        }
        columnSize
      }
      menuOrderOptions {
        tableId
        orderId
      }
      terminalId
      orderOptions {
        cycleNumber
      }
      printOptions {
        port
        baudRate
        receipt
        receiptButtonHide
        bills
        refund
        salesEnd
        order
        orderBeep
        orderCount
        orderExcludeIds
        delivery
      }
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const createBillingPolicy = /* GraphQL */ `
  mutation CreateBillingPolicy($input: CreateBillingPolicyInput!) {
    createBillingPolicy(input: $input) {
      id
      exemption
      exemptionLocations
      marketBrokerageRate
      subscriptionBrokerageRate
      locationFee
      note
      updatedAt
      createdAt
    }
  }
`;
export const updateBillingPolicy = /* GraphQL */ `
  mutation UpdateBillingPolicy($input: UpdateBillingPolicyInput!) {
    updateBillingPolicy(input: $input) {
      id
      exemption
      exemptionLocations
      marketBrokerageRate
      subscriptionBrokerageRate
      locationFee
      note
      updatedAt
      createdAt
    }
  }
`;
export const deleteBillingPolicy = /* GraphQL */ `
  mutation DeleteBillingPolicy($input: DeleteBillingPolicyInput!) {
    deleteBillingPolicy(input: $input) {
      id
      exemption
      exemptionLocations
      marketBrokerageRate
      subscriptionBrokerageRate
      locationFee
      note
      updatedAt
      createdAt
    }
  }
`;
export const createLocationContract = /* GraphQL */ `
  mutation CreateLocationContract($input: CreateLocationContractInput!) {
    createLocationContract(input: $input) {
      id
      businessOperatorId
      menuOrderExemptionQuantity
      menuOrderQuantity
      menuOrderStartedAt
      menuOrderRecurringType
      taxRefund
      note
      updatedAt
      createdAt
    }
  }
`;
export const updateLocationContract = /* GraphQL */ `
  mutation UpdateLocationContract($input: UpdateLocationContractInput!) {
    updateLocationContract(input: $input) {
      id
      businessOperatorId
      menuOrderExemptionQuantity
      menuOrderQuantity
      menuOrderStartedAt
      menuOrderRecurringType
      taxRefund
      note
      updatedAt
      createdAt
    }
  }
`;
export const deleteLocationContract = /* GraphQL */ `
  mutation DeleteLocationContract($input: DeleteLocationContractInput!) {
    deleteLocationContract(input: $input) {
      id
      businessOperatorId
      menuOrderExemptionQuantity
      menuOrderQuantity
      menuOrderStartedAt
      menuOrderRecurringType
      taxRefund
      note
      updatedAt
      createdAt
    }
  }
`;
export const createStoreContract = /* GraphQL */ `
  mutation CreateStoreContract($input: CreateStoreContractInput!) {
    createStoreContract(input: $input) {
      id
      businessOperatorId
      menuOrderExemptionQuantity
      menuOrderQuantity
      menuOrderStartedAt
      menuOrderRecurringType
      taxRefund
      note
      locationFee
      locationFeeExemptionIds
      updatedAt
      createdAt
    }
  }
`;
export const updateStoreContract = /* GraphQL */ `
  mutation UpdateStoreContract($input: UpdateStoreContractInput!) {
    updateStoreContract(input: $input) {
      id
      businessOperatorId
      menuOrderExemptionQuantity
      menuOrderQuantity
      menuOrderStartedAt
      menuOrderRecurringType
      taxRefund
      note
      locationFee
      locationFeeExemptionIds
      updatedAt
      createdAt
    }
  }
`;
export const deleteStoreContract = /* GraphQL */ `
  mutation DeleteStoreContract($input: DeleteStoreContractInput!) {
    deleteStoreContract(input: $input) {
      id
      businessOperatorId
      menuOrderExemptionQuantity
      menuOrderQuantity
      menuOrderStartedAt
      menuOrderRecurringType
      taxRefund
      note
      locationFee
      locationFeeExemptionIds
      updatedAt
      createdAt
    }
  }
`;
export const createSubscriptionEvent = /* GraphQL */ `
  mutation CreateSubscriptionEvent($input: CreateSubscriptionEventInput!) {
    createSubscriptionEvent(input: $input) {
      id
      storeId
      locationId
      subscriptionId
      sessionId
      planId
      price
      amount
      currency
      type
      customerId
      storeCustomerId
      orderId
      orderNumberPrefix
      orderNumber
      startDate
      endDate
      updatedAt
      createdAt
      location {
        id
        name
        type
        provider
        address
        zip
        phone
        logo {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        representative
        businessNumber
        billing {
          key
          businessNumber
          name
          email
          phone
          contanctName
          contactName
          address
          zip
          card {
            pg
            number
            name
            expMonth
            expYear
            identify
            password
            billingKey
          }
        }
        credit {
          autoCharge
          autoChargeCredit
          autoChargeQuantity
          alertCredit
        }
        businessHours {
          sun {
            open
            close
          }
          mon {
            open
            close
          }
          tue {
            open
            close
          }
          wed {
            open
            close
          }
          thu {
            open
            close
          }
          fri {
            open
            close
          }
          sat {
            open
            close
          }
        }
        storeId
        state
        menuPlanId
        payments {
          refund
          barcode
          barcodeVan
          barcodeTerminalId
        }
        policy {
          locationCost
          loyaltyCost
        }
        loyalty {
          status
          provider
          sharePolicy
          type
          grade
          conditionType
          conditionSubType
          condition {
            minAmount
            perAmount
            minTime
            rate
            expirationPeriod
            excludeProdIds
            discountPointSavable
          }
          conditionItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          conditionTiers {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardType
          rewardSubType
          reward {
            requirePoint
            requireOrderAmount
            minUsePoint
            minRequireRate
            maxUsePoint
          }
          rewardItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardRates {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardAmounts {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardCashs {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          incentives {
            fulfill
            fulfillPoint
            fulfillCampaignId
            birth
            birthPoint
            birthCampaignId
            birthItem {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
          }
          notification {
            register
            registerCampainId
            save
            saveCampaignId
            use
            useCampaignId
            expire
            expireCampaignId
          }
        }
        kiosk {
          status
          callable
          payable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          exchangeTicket
          columnSize
          discounts {
            condition
            couponId
          }
          menuDescription
          updatedAt
          createdAt
        }
        receipt {
          salesSlip
          orderList
          barcode
          footnote
          phone
          orderNumberHide
          orderSheetFontSize
          billsBusinessInfo
          giftFootnote
          giftReceipt
        }
        menuOrder {
          callOptions {
            index
            label
          }
          orderable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          columnSize
          fontSize
          notice
          resetTime
          enableCash
          boardType
        }
        kds {
          layout
          alerts {
            urgent
            warning
          }
          disabledProdIds
          orderNotify
        }
        mini {
          defaultItemName
        }
        orderPlanId
        menu {
          board {
            textSize
            columnSize
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            enableFavorite
          }
          list {
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            shortcuts {
              type
              title
              itemName
              itemPrice
              active
            }
          }
          navOrder
        }
        menus {
          parentId
          position
          page
          pageTitle
        }
        favMenus {
          parentId
          position
          page
          pageTitle
        }
        catMenus {
          title
          index
          ids
          parentId
        }
        market {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          paused
          takeoutDiscount
          creditDiscount
          takeout
          intro
          notice
          bookTable
          bookTableNote
          depositAmount
          delivery
          deliveryFee {
            start
            end
            fee
          }
          waitingTime
          itemIds
          updatedAt
          createdAt
        }
        waiting {
          status
          waitingOptions {
            id
            type
            name
            nameMl {
              en
              zh
              ja
            }
            items {
              id
              name
              nameMl {
                en
                zh
                ja
              }
              limitQuantity
            }
            required
            updatedAt
            createdAt
          }
          waitingMinutes
          waitingMinutesHide
          hasForeigner
          foreignerMethod
          notifications {
            created
            ready
            requested
            cancelled
          }
          notices {
            createdNotice
            createdNoticeMl {
              en
              zh
              ja
            }
            requestedNotice
            requestedNoticeMl {
              en
              zh
              ja
            }
          }
          settings {
            autoCancel
            autoCancelMinutes
          }
          updatedAt
          createdAt
        }
        view {
          order {
            excludeColumns
            exportTemplates {
              name
              type
              headers {
                key
                label
              }
            }
          }
          itemReport {
            excludeColumns
          }
          summaryReport {
            excludeColumns
          }
          shippingOrder {
            excludeColumns
          }
          trsItemReport {
            excludeColumns
          }
        }
        subscription {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          updatedAt
          createdAt
        }
        settings {
          mode
          table
          diningOption
          retailOption
          orderAlarm
          orderConfirm
          addressInput
          autoPay
          autoReturnItem
          autoSoldout
          menuGridSize
          menuMode
          tableMode
          orderNotify
          orderNotifySkippable
          disablePhonePrefix
          autoCloseScreen
          orderNumberPrefix {
            pos
            kiosk
            menuOrder
          }
          orderMgmt {
            kiosk
            menuorder
            delivery
            pos
          }
          reportBasis
          orderOrigins
          beeper {
            active
            coverage
            skippable
          }
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
        }
        devices {
          id
          ident
          locationId
          system
          ipAddress
          brand
          model
          name
          type
          deviceType
          display {
            height
            width
          }
          protocol
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          barcodeReader
          appVersion
          sysVersion
          status
          password
          msgToken
          parentId
          childId
          sleepMode
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
          terminalId
          printOptions {
            port
            baudRate
            receipt
            receiptButtonHide
            bills
            refund
            salesEnd
            order
            orderBeep
            orderCount
            orderExcludeIds
            delivery
          }
          updatedAt
          createdAt
        }
        tables {
          id
          width
          height
          name
          axisX
          axisY
          screenHeight
          screenWidth
          initialX
          initialY
          shape
          layout {
            x
            y
            w
            h
          }
          color
          orderId
          disabled
        }
        boardTables {
          title
          index
          items {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
        }
        gridTables {
          id
          name
          orderId
          index
          page
        }
        modifierSoldoutIds
        delivery {
          baemin
          coupang
          yogiyo
          enableGenProd
          updatedAt
          createdAt
        }
        createdAt
        updatedAt
        sales {
          startedAt
          endedAt
          reserves
          actualBalance
          currency
          note
          estimateBalance
          detail {
            totalSales
            totalRefund
            totalTax
            totalDiscount
            supplyAmount
            totalSalesCount
            cash {
              sales
              refund
            }
            card {
              sales
              refund
            }
            credit {
              sales
              refund
            }
            barcode {
              sales
              refund
            }
            extra {
              sales
              refund
            }
            refund {
              sales
              refund
            }
            products {
              id
              name
              quantity
              amount
            }
            origins {
              pos
              kiosk
              menuorder
              delivery
              market
            }
          }
          reservesHistories {
            type
            amount
            note
            createdEmployeeId
            createdAt
          }
        }
      }
      plan {
        id
        storeId
        productId
        skuId
        name
        recurringType
        recurringValue
        price
        quantity
        status
        image {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        gracePeriod
        subscriberCount
        locations {
          planId
          locationId
          available
          subscriberCount
        }
        updatedAt
        createdAt
        _delta
        timestamp
        expdate
        product {
          id
          type
          enterpriseId
          storeId
          origin
          refer
          referId
          name
          nameMl {
            en
            zh
            ja
          }
          description
          descriptionMl {
            en
            zh
            ja
          }
          variablePrice
          attributes {
            name
            type
            value
          }
          thumbnail {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          skus {
            type
            id
            itemId
            refer
            referId
            attributes {
              name
              type
              value
            }
            name
            nameMl {
              en
              zh
              ja
            }
            price
            cost
            unit
            unitSuffix
            barcode
            currency
            model
            locationId
            futureLocationAvailable
            locationIds
            inventories {
              id
              quantity
              cost
              locationId
              unavailableQuantity
              inReadyQuantity
              outReadyQuantity
              expiryDate
              updatedAt
              createdAt
              locations {
                id
                quantity
              }
              attributes {
                name
                type
                value
              }
            }
            locations {
              id
              isActive
              available
              price
            }
            links {
              type
              origin
              originId
              originProductId
              updatedAt
              createdAt
            }
            stock
            stockAlert
            stockAlertQty
            stockTrack
            quantity
            limitQuantity
            isActive
          }
          trackStock
          taxId
          categories {
            items {
              id
              enterpriseId
              storeId
              productId
              categoryId
              category {
                type
                id
                enterpriseId
                storeId
                name
                displayName
                refer
                referId
                locationIds
                updatedAt
                createdAt
              }
              updatedAt
              createdAt
              product {
                id
                type
                enterpriseId
                storeId
                origin
                refer
                referId
                name
                description
                variablePrice
                trackStock
                taxId
                locationIds
                updatedAt
                createdAt
              }
            }
            nextToken
          }
          classifications {
            items {
              id
              enterpriseId
              storeId
              productId
              classificationId
              classification {
                type
                id
                enterpriseId
                storeId
                name
                displayName
                refer
                referId
                locationIds
                updatedAt
                createdAt
              }
              updatedAt
              createdAt
              product {
                id
                type
                enterpriseId
                storeId
                origin
                refer
                referId
                name
                description
                variablePrice
                trackStock
                taxId
                locationIds
                updatedAt
                createdAt
              }
            }
            nextToken
          }
          modifiers {
            items {
              id
              enterpriseId
              storeId
              productId
              modifierId
              position
              modifier {
                id
                type
                enterpriseId
                storeId
                name
                origin
                orderType
                defaultSkuId
                coverage
                required
                requiredQuantity
                limitQuantity
                locationIds
                updatedAt
                createdAt
              }
              updatedAt
              createdAt
              product {
                id
                type
                enterpriseId
                storeId
                origin
                refer
                referId
                name
                description
                variablePrice
                trackStock
                taxId
                locationIds
                updatedAt
                createdAt
              }
            }
            nextToken
          }
          locationIds
          updatedAt
          createdAt
        }
      }
      store {
        id
        enterpriseId
        info {
          name
          phone
          email
          representative
          businessType
          businessNumber
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          industryType
          sendNumber
          currency
          address
          zip
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          csPhone
        }
        policy {
          multiLocation
          provider {
            key
            ident
            customer
            coupon
            inventory
            locationId
          }
        }
        currency
        payments {
          card
          cardJoined
          cash
          credit
          refund
        }
        devices {
          id
          ident
          locationId
          system
          ipAddress
          brand
          model
          name
          type
          deviceType
          display {
            height
            width
          }
          protocol
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          barcodeReader
          appVersion
          sysVersion
          status
          password
          msgToken
          parentId
          childId
          sleepMode
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
          terminalId
          printOptions {
            port
            baudRate
            receipt
            receiptButtonHide
            bills
            refund
            salesEnd
            order
            orderBeep
            orderCount
            orderExcludeIds
            delivery
          }
          updatedAt
          createdAt
        }
        plans {
          menus {
            id
            name
            mode
            size
            menus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            pages {
              index
              parentId
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              title
              titleMl {
                en
                zh
                ja
              }
            }
            updatedAt
            createdAt
          }
          orders {
            id
            name
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            updatedAt
            createdAt
          }
        }
        taxes {
          id
          type
          name
          enabled
          rate
          method
          targetType
        }
        receipt {
          salesSlip
          orderList
          barcode
          footnote
          phone
          orderNumberHide
          orderSheetFontSize
          billsBusinessInfo
          giftFootnote
          giftReceipt
        }
        subscription {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          updatedAt
          createdAt
        }
        view {
          order {
            excludeColumns
            exportTemplates {
              name
              type
              headers {
                key
                label
              }
            }
          }
          itemReport {
            excludeColumns
          }
          summaryReport {
            excludeColumns
          }
          shippingOrder {
            excludeColumns
          }
          trsItemReport {
            excludeColumns
          }
        }
        settings {
          mode
          table
          diningOption
          retailOption
          orderAlarm
          orderConfirm
          addressInput
          autoPay
          autoReturnItem
          autoSoldout
          menuGridSize
          menuMode
          tableMode
          orderNotify
          orderNotifySkippable
          disablePhonePrefix
          autoCloseScreen
          orderNumberPrefix {
            pos
            kiosk
            menuOrder
          }
          orderMgmt {
            kiosk
            menuorder
            delivery
            pos
          }
          reportBasis
          orderOrigins
          beeper {
            active
            coverage
            skippable
          }
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
        }
        item {
          attributes {
            name
            type
            value
          }
          actualPrice
          skuMgmt
          barcodeMgmt
          stockTrack
          variablePrice
          locationCreatable
        }
        inventory {
          attributes {
            name
            type
            value
          }
          expiryDateMgmt
          expiryDateAlertDays
        }
        customer {
          attributes {
            name
            type
            value
          }
        }
        billing {
          key
          businessNumber
          name
          email
          phone
          contanctName
          contactName
          address
          zip
          card {
            pg
            number
            name
            expMonth
            expYear
            identify
            password
            billingKey
          }
        }
        credit {
          autoCharge
          autoChargeCredit
          autoChargeQuantity
          alertCredit
        }
        kakao {
          agency
          key
          channelId
          categoryCode
          status
          updatedAt
          createdAt
        }
        review {
          status
          campaignId
          updatedAt
          createdAt
        }
        kiosk {
          status
          callable
          payable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          exchangeTicket
          columnSize
          discounts {
            condition
            couponId
          }
          menuDescription
          updatedAt
          createdAt
        }
        menuOrder {
          callOptions {
            index
            label
          }
          orderable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          columnSize
          fontSize
          notice
          resetTime
          enableCash
          boardType
        }
        kds {
          layout
          alerts {
            urgent
            warning
          }
          disabledProdIds
          orderNotify
        }
        mirror {
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          video {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
        }
        loyalty {
          status
          provider
          sharePolicy
          type
          grade
          conditionType
          conditionSubType
          condition {
            minAmount
            perAmount
            minTime
            rate
            expirationPeriod
            excludeProdIds
            discountPointSavable
          }
          conditionItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          conditionTiers {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardType
          rewardSubType
          reward {
            requirePoint
            requireOrderAmount
            minUsePoint
            minRequireRate
            maxUsePoint
          }
          rewardItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardRates {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardAmounts {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardCashs {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          incentives {
            fulfill
            fulfillPoint
            fulfillCampaignId
            birth
            birthPoint
            birthCampaignId
            birthItem {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
          }
          notification {
            register
            registerCampainId
            save
            saveCampaignId
            use
            useCampaignId
            expire
            expireCampaignId
          }
        }
        market {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          paused
          takeoutDiscount
          creditDiscount
          takeout
          intro
          notice
          bookTable
          bookTableNote
          depositAmount
          delivery
          deliveryFee {
            start
            end
            fee
          }
          waitingTime
          itemIds
          updatedAt
          createdAt
        }
        notification {
          options {
            type
            email
            sms
          }
        }
        groups {
          items {
            id
            name
            type
            storeGroupsId
            locationGroupsId
            key
            conditions {
              key
              object
              field
              operations {
                operation
                type
                value
              }
              value
              operation
              type
            }
            count
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        location {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        locations {
          items {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateSubscriptionEvent = /* GraphQL */ `
  mutation UpdateSubscriptionEvent($input: UpdateSubscriptionEventInput!) {
    updateSubscriptionEvent(input: $input) {
      id
      storeId
      locationId
      subscriptionId
      sessionId
      planId
      price
      amount
      currency
      type
      customerId
      storeCustomerId
      orderId
      orderNumberPrefix
      orderNumber
      startDate
      endDate
      updatedAt
      createdAt
      location {
        id
        name
        type
        provider
        address
        zip
        phone
        logo {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        representative
        businessNumber
        billing {
          key
          businessNumber
          name
          email
          phone
          contanctName
          contactName
          address
          zip
          card {
            pg
            number
            name
            expMonth
            expYear
            identify
            password
            billingKey
          }
        }
        credit {
          autoCharge
          autoChargeCredit
          autoChargeQuantity
          alertCredit
        }
        businessHours {
          sun {
            open
            close
          }
          mon {
            open
            close
          }
          tue {
            open
            close
          }
          wed {
            open
            close
          }
          thu {
            open
            close
          }
          fri {
            open
            close
          }
          sat {
            open
            close
          }
        }
        storeId
        state
        menuPlanId
        payments {
          refund
          barcode
          barcodeVan
          barcodeTerminalId
        }
        policy {
          locationCost
          loyaltyCost
        }
        loyalty {
          status
          provider
          sharePolicy
          type
          grade
          conditionType
          conditionSubType
          condition {
            minAmount
            perAmount
            minTime
            rate
            expirationPeriod
            excludeProdIds
            discountPointSavable
          }
          conditionItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          conditionTiers {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardType
          rewardSubType
          reward {
            requirePoint
            requireOrderAmount
            minUsePoint
            minRequireRate
            maxUsePoint
          }
          rewardItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardRates {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardAmounts {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardCashs {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          incentives {
            fulfill
            fulfillPoint
            fulfillCampaignId
            birth
            birthPoint
            birthCampaignId
            birthItem {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
          }
          notification {
            register
            registerCampainId
            save
            saveCampaignId
            use
            useCampaignId
            expire
            expireCampaignId
          }
        }
        kiosk {
          status
          callable
          payable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          exchangeTicket
          columnSize
          discounts {
            condition
            couponId
          }
          menuDescription
          updatedAt
          createdAt
        }
        receipt {
          salesSlip
          orderList
          barcode
          footnote
          phone
          orderNumberHide
          orderSheetFontSize
          billsBusinessInfo
          giftFootnote
          giftReceipt
        }
        menuOrder {
          callOptions {
            index
            label
          }
          orderable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          columnSize
          fontSize
          notice
          resetTime
          enableCash
          boardType
        }
        kds {
          layout
          alerts {
            urgent
            warning
          }
          disabledProdIds
          orderNotify
        }
        mini {
          defaultItemName
        }
        orderPlanId
        menu {
          board {
            textSize
            columnSize
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            enableFavorite
          }
          list {
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            shortcuts {
              type
              title
              itemName
              itemPrice
              active
            }
          }
          navOrder
        }
        menus {
          parentId
          position
          page
          pageTitle
        }
        favMenus {
          parentId
          position
          page
          pageTitle
        }
        catMenus {
          title
          index
          ids
          parentId
        }
        market {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          paused
          takeoutDiscount
          creditDiscount
          takeout
          intro
          notice
          bookTable
          bookTableNote
          depositAmount
          delivery
          deliveryFee {
            start
            end
            fee
          }
          waitingTime
          itemIds
          updatedAt
          createdAt
        }
        waiting {
          status
          waitingOptions {
            id
            type
            name
            nameMl {
              en
              zh
              ja
            }
            items {
              id
              name
              nameMl {
                en
                zh
                ja
              }
              limitQuantity
            }
            required
            updatedAt
            createdAt
          }
          waitingMinutes
          waitingMinutesHide
          hasForeigner
          foreignerMethod
          notifications {
            created
            ready
            requested
            cancelled
          }
          notices {
            createdNotice
            createdNoticeMl {
              en
              zh
              ja
            }
            requestedNotice
            requestedNoticeMl {
              en
              zh
              ja
            }
          }
          settings {
            autoCancel
            autoCancelMinutes
          }
          updatedAt
          createdAt
        }
        view {
          order {
            excludeColumns
            exportTemplates {
              name
              type
              headers {
                key
                label
              }
            }
          }
          itemReport {
            excludeColumns
          }
          summaryReport {
            excludeColumns
          }
          shippingOrder {
            excludeColumns
          }
          trsItemReport {
            excludeColumns
          }
        }
        subscription {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          updatedAt
          createdAt
        }
        settings {
          mode
          table
          diningOption
          retailOption
          orderAlarm
          orderConfirm
          addressInput
          autoPay
          autoReturnItem
          autoSoldout
          menuGridSize
          menuMode
          tableMode
          orderNotify
          orderNotifySkippable
          disablePhonePrefix
          autoCloseScreen
          orderNumberPrefix {
            pos
            kiosk
            menuOrder
          }
          orderMgmt {
            kiosk
            menuorder
            delivery
            pos
          }
          reportBasis
          orderOrigins
          beeper {
            active
            coverage
            skippable
          }
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
        }
        devices {
          id
          ident
          locationId
          system
          ipAddress
          brand
          model
          name
          type
          deviceType
          display {
            height
            width
          }
          protocol
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          barcodeReader
          appVersion
          sysVersion
          status
          password
          msgToken
          parentId
          childId
          sleepMode
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
          terminalId
          printOptions {
            port
            baudRate
            receipt
            receiptButtonHide
            bills
            refund
            salesEnd
            order
            orderBeep
            orderCount
            orderExcludeIds
            delivery
          }
          updatedAt
          createdAt
        }
        tables {
          id
          width
          height
          name
          axisX
          axisY
          screenHeight
          screenWidth
          initialX
          initialY
          shape
          layout {
            x
            y
            w
            h
          }
          color
          orderId
          disabled
        }
        boardTables {
          title
          index
          items {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
        }
        gridTables {
          id
          name
          orderId
          index
          page
        }
        modifierSoldoutIds
        delivery {
          baemin
          coupang
          yogiyo
          enableGenProd
          updatedAt
          createdAt
        }
        createdAt
        updatedAt
        sales {
          startedAt
          endedAt
          reserves
          actualBalance
          currency
          note
          estimateBalance
          detail {
            totalSales
            totalRefund
            totalTax
            totalDiscount
            supplyAmount
            totalSalesCount
            cash {
              sales
              refund
            }
            card {
              sales
              refund
            }
            credit {
              sales
              refund
            }
            barcode {
              sales
              refund
            }
            extra {
              sales
              refund
            }
            refund {
              sales
              refund
            }
            products {
              id
              name
              quantity
              amount
            }
            origins {
              pos
              kiosk
              menuorder
              delivery
              market
            }
          }
          reservesHistories {
            type
            amount
            note
            createdEmployeeId
            createdAt
          }
        }
      }
      plan {
        id
        storeId
        productId
        skuId
        name
        recurringType
        recurringValue
        price
        quantity
        status
        image {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        gracePeriod
        subscriberCount
        locations {
          planId
          locationId
          available
          subscriberCount
        }
        updatedAt
        createdAt
        _delta
        timestamp
        expdate
        product {
          id
          type
          enterpriseId
          storeId
          origin
          refer
          referId
          name
          nameMl {
            en
            zh
            ja
          }
          description
          descriptionMl {
            en
            zh
            ja
          }
          variablePrice
          attributes {
            name
            type
            value
          }
          thumbnail {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          skus {
            type
            id
            itemId
            refer
            referId
            attributes {
              name
              type
              value
            }
            name
            nameMl {
              en
              zh
              ja
            }
            price
            cost
            unit
            unitSuffix
            barcode
            currency
            model
            locationId
            futureLocationAvailable
            locationIds
            inventories {
              id
              quantity
              cost
              locationId
              unavailableQuantity
              inReadyQuantity
              outReadyQuantity
              expiryDate
              updatedAt
              createdAt
              locations {
                id
                quantity
              }
              attributes {
                name
                type
                value
              }
            }
            locations {
              id
              isActive
              available
              price
            }
            links {
              type
              origin
              originId
              originProductId
              updatedAt
              createdAt
            }
            stock
            stockAlert
            stockAlertQty
            stockTrack
            quantity
            limitQuantity
            isActive
          }
          trackStock
          taxId
          categories {
            items {
              id
              enterpriseId
              storeId
              productId
              categoryId
              category {
                type
                id
                enterpriseId
                storeId
                name
                displayName
                refer
                referId
                locationIds
                updatedAt
                createdAt
              }
              updatedAt
              createdAt
              product {
                id
                type
                enterpriseId
                storeId
                origin
                refer
                referId
                name
                description
                variablePrice
                trackStock
                taxId
                locationIds
                updatedAt
                createdAt
              }
            }
            nextToken
          }
          classifications {
            items {
              id
              enterpriseId
              storeId
              productId
              classificationId
              classification {
                type
                id
                enterpriseId
                storeId
                name
                displayName
                refer
                referId
                locationIds
                updatedAt
                createdAt
              }
              updatedAt
              createdAt
              product {
                id
                type
                enterpriseId
                storeId
                origin
                refer
                referId
                name
                description
                variablePrice
                trackStock
                taxId
                locationIds
                updatedAt
                createdAt
              }
            }
            nextToken
          }
          modifiers {
            items {
              id
              enterpriseId
              storeId
              productId
              modifierId
              position
              modifier {
                id
                type
                enterpriseId
                storeId
                name
                origin
                orderType
                defaultSkuId
                coverage
                required
                requiredQuantity
                limitQuantity
                locationIds
                updatedAt
                createdAt
              }
              updatedAt
              createdAt
              product {
                id
                type
                enterpriseId
                storeId
                origin
                refer
                referId
                name
                description
                variablePrice
                trackStock
                taxId
                locationIds
                updatedAt
                createdAt
              }
            }
            nextToken
          }
          locationIds
          updatedAt
          createdAt
        }
      }
      store {
        id
        enterpriseId
        info {
          name
          phone
          email
          representative
          businessType
          businessNumber
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          industryType
          sendNumber
          currency
          address
          zip
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          csPhone
        }
        policy {
          multiLocation
          provider {
            key
            ident
            customer
            coupon
            inventory
            locationId
          }
        }
        currency
        payments {
          card
          cardJoined
          cash
          credit
          refund
        }
        devices {
          id
          ident
          locationId
          system
          ipAddress
          brand
          model
          name
          type
          deviceType
          display {
            height
            width
          }
          protocol
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          barcodeReader
          appVersion
          sysVersion
          status
          password
          msgToken
          parentId
          childId
          sleepMode
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
          terminalId
          printOptions {
            port
            baudRate
            receipt
            receiptButtonHide
            bills
            refund
            salesEnd
            order
            orderBeep
            orderCount
            orderExcludeIds
            delivery
          }
          updatedAt
          createdAt
        }
        plans {
          menus {
            id
            name
            mode
            size
            menus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            pages {
              index
              parentId
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              title
              titleMl {
                en
                zh
                ja
              }
            }
            updatedAt
            createdAt
          }
          orders {
            id
            name
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            updatedAt
            createdAt
          }
        }
        taxes {
          id
          type
          name
          enabled
          rate
          method
          targetType
        }
        receipt {
          salesSlip
          orderList
          barcode
          footnote
          phone
          orderNumberHide
          orderSheetFontSize
          billsBusinessInfo
          giftFootnote
          giftReceipt
        }
        subscription {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          updatedAt
          createdAt
        }
        view {
          order {
            excludeColumns
            exportTemplates {
              name
              type
              headers {
                key
                label
              }
            }
          }
          itemReport {
            excludeColumns
          }
          summaryReport {
            excludeColumns
          }
          shippingOrder {
            excludeColumns
          }
          trsItemReport {
            excludeColumns
          }
        }
        settings {
          mode
          table
          diningOption
          retailOption
          orderAlarm
          orderConfirm
          addressInput
          autoPay
          autoReturnItem
          autoSoldout
          menuGridSize
          menuMode
          tableMode
          orderNotify
          orderNotifySkippable
          disablePhonePrefix
          autoCloseScreen
          orderNumberPrefix {
            pos
            kiosk
            menuOrder
          }
          orderMgmt {
            kiosk
            menuorder
            delivery
            pos
          }
          reportBasis
          orderOrigins
          beeper {
            active
            coverage
            skippable
          }
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
        }
        item {
          attributes {
            name
            type
            value
          }
          actualPrice
          skuMgmt
          barcodeMgmt
          stockTrack
          variablePrice
          locationCreatable
        }
        inventory {
          attributes {
            name
            type
            value
          }
          expiryDateMgmt
          expiryDateAlertDays
        }
        customer {
          attributes {
            name
            type
            value
          }
        }
        billing {
          key
          businessNumber
          name
          email
          phone
          contanctName
          contactName
          address
          zip
          card {
            pg
            number
            name
            expMonth
            expYear
            identify
            password
            billingKey
          }
        }
        credit {
          autoCharge
          autoChargeCredit
          autoChargeQuantity
          alertCredit
        }
        kakao {
          agency
          key
          channelId
          categoryCode
          status
          updatedAt
          createdAt
        }
        review {
          status
          campaignId
          updatedAt
          createdAt
        }
        kiosk {
          status
          callable
          payable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          exchangeTicket
          columnSize
          discounts {
            condition
            couponId
          }
          menuDescription
          updatedAt
          createdAt
        }
        menuOrder {
          callOptions {
            index
            label
          }
          orderable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          columnSize
          fontSize
          notice
          resetTime
          enableCash
          boardType
        }
        kds {
          layout
          alerts {
            urgent
            warning
          }
          disabledProdIds
          orderNotify
        }
        mirror {
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          video {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
        }
        loyalty {
          status
          provider
          sharePolicy
          type
          grade
          conditionType
          conditionSubType
          condition {
            minAmount
            perAmount
            minTime
            rate
            expirationPeriod
            excludeProdIds
            discountPointSavable
          }
          conditionItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          conditionTiers {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardType
          rewardSubType
          reward {
            requirePoint
            requireOrderAmount
            minUsePoint
            minRequireRate
            maxUsePoint
          }
          rewardItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardRates {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardAmounts {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardCashs {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          incentives {
            fulfill
            fulfillPoint
            fulfillCampaignId
            birth
            birthPoint
            birthCampaignId
            birthItem {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
          }
          notification {
            register
            registerCampainId
            save
            saveCampaignId
            use
            useCampaignId
            expire
            expireCampaignId
          }
        }
        market {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          paused
          takeoutDiscount
          creditDiscount
          takeout
          intro
          notice
          bookTable
          bookTableNote
          depositAmount
          delivery
          deliveryFee {
            start
            end
            fee
          }
          waitingTime
          itemIds
          updatedAt
          createdAt
        }
        notification {
          options {
            type
            email
            sms
          }
        }
        groups {
          items {
            id
            name
            type
            storeGroupsId
            locationGroupsId
            key
            conditions {
              key
              object
              field
              operations {
                operation
                type
                value
              }
              value
              operation
              type
            }
            count
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        location {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        locations {
          items {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteSubscriptionEvent = /* GraphQL */ `
  mutation DeleteSubscriptionEvent($input: DeleteSubscriptionEventInput!) {
    deleteSubscriptionEvent(input: $input) {
      id
      storeId
      locationId
      subscriptionId
      sessionId
      planId
      price
      amount
      currency
      type
      customerId
      storeCustomerId
      orderId
      orderNumberPrefix
      orderNumber
      startDate
      endDate
      updatedAt
      createdAt
      location {
        id
        name
        type
        provider
        address
        zip
        phone
        logo {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        representative
        businessNumber
        billing {
          key
          businessNumber
          name
          email
          phone
          contanctName
          contactName
          address
          zip
          card {
            pg
            number
            name
            expMonth
            expYear
            identify
            password
            billingKey
          }
        }
        credit {
          autoCharge
          autoChargeCredit
          autoChargeQuantity
          alertCredit
        }
        businessHours {
          sun {
            open
            close
          }
          mon {
            open
            close
          }
          tue {
            open
            close
          }
          wed {
            open
            close
          }
          thu {
            open
            close
          }
          fri {
            open
            close
          }
          sat {
            open
            close
          }
        }
        storeId
        state
        menuPlanId
        payments {
          refund
          barcode
          barcodeVan
          barcodeTerminalId
        }
        policy {
          locationCost
          loyaltyCost
        }
        loyalty {
          status
          provider
          sharePolicy
          type
          grade
          conditionType
          conditionSubType
          condition {
            minAmount
            perAmount
            minTime
            rate
            expirationPeriod
            excludeProdIds
            discountPointSavable
          }
          conditionItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          conditionTiers {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardType
          rewardSubType
          reward {
            requirePoint
            requireOrderAmount
            minUsePoint
            minRequireRate
            maxUsePoint
          }
          rewardItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardRates {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardAmounts {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardCashs {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          incentives {
            fulfill
            fulfillPoint
            fulfillCampaignId
            birth
            birthPoint
            birthCampaignId
            birthItem {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
          }
          notification {
            register
            registerCampainId
            save
            saveCampaignId
            use
            useCampaignId
            expire
            expireCampaignId
          }
        }
        kiosk {
          status
          callable
          payable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          exchangeTicket
          columnSize
          discounts {
            condition
            couponId
          }
          menuDescription
          updatedAt
          createdAt
        }
        receipt {
          salesSlip
          orderList
          barcode
          footnote
          phone
          orderNumberHide
          orderSheetFontSize
          billsBusinessInfo
          giftFootnote
          giftReceipt
        }
        menuOrder {
          callOptions {
            index
            label
          }
          orderable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          columnSize
          fontSize
          notice
          resetTime
          enableCash
          boardType
        }
        kds {
          layout
          alerts {
            urgent
            warning
          }
          disabledProdIds
          orderNotify
        }
        mini {
          defaultItemName
        }
        orderPlanId
        menu {
          board {
            textSize
            columnSize
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            enableFavorite
          }
          list {
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            shortcuts {
              type
              title
              itemName
              itemPrice
              active
            }
          }
          navOrder
        }
        menus {
          parentId
          position
          page
          pageTitle
        }
        favMenus {
          parentId
          position
          page
          pageTitle
        }
        catMenus {
          title
          index
          ids
          parentId
        }
        market {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          paused
          takeoutDiscount
          creditDiscount
          takeout
          intro
          notice
          bookTable
          bookTableNote
          depositAmount
          delivery
          deliveryFee {
            start
            end
            fee
          }
          waitingTime
          itemIds
          updatedAt
          createdAt
        }
        waiting {
          status
          waitingOptions {
            id
            type
            name
            nameMl {
              en
              zh
              ja
            }
            items {
              id
              name
              nameMl {
                en
                zh
                ja
              }
              limitQuantity
            }
            required
            updatedAt
            createdAt
          }
          waitingMinutes
          waitingMinutesHide
          hasForeigner
          foreignerMethod
          notifications {
            created
            ready
            requested
            cancelled
          }
          notices {
            createdNotice
            createdNoticeMl {
              en
              zh
              ja
            }
            requestedNotice
            requestedNoticeMl {
              en
              zh
              ja
            }
          }
          settings {
            autoCancel
            autoCancelMinutes
          }
          updatedAt
          createdAt
        }
        view {
          order {
            excludeColumns
            exportTemplates {
              name
              type
              headers {
                key
                label
              }
            }
          }
          itemReport {
            excludeColumns
          }
          summaryReport {
            excludeColumns
          }
          shippingOrder {
            excludeColumns
          }
          trsItemReport {
            excludeColumns
          }
        }
        subscription {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          updatedAt
          createdAt
        }
        settings {
          mode
          table
          diningOption
          retailOption
          orderAlarm
          orderConfirm
          addressInput
          autoPay
          autoReturnItem
          autoSoldout
          menuGridSize
          menuMode
          tableMode
          orderNotify
          orderNotifySkippable
          disablePhonePrefix
          autoCloseScreen
          orderNumberPrefix {
            pos
            kiosk
            menuOrder
          }
          orderMgmt {
            kiosk
            menuorder
            delivery
            pos
          }
          reportBasis
          orderOrigins
          beeper {
            active
            coverage
            skippable
          }
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
        }
        devices {
          id
          ident
          locationId
          system
          ipAddress
          brand
          model
          name
          type
          deviceType
          display {
            height
            width
          }
          protocol
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          barcodeReader
          appVersion
          sysVersion
          status
          password
          msgToken
          parentId
          childId
          sleepMode
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
          terminalId
          printOptions {
            port
            baudRate
            receipt
            receiptButtonHide
            bills
            refund
            salesEnd
            order
            orderBeep
            orderCount
            orderExcludeIds
            delivery
          }
          updatedAt
          createdAt
        }
        tables {
          id
          width
          height
          name
          axisX
          axisY
          screenHeight
          screenWidth
          initialX
          initialY
          shape
          layout {
            x
            y
            w
            h
          }
          color
          orderId
          disabled
        }
        boardTables {
          title
          index
          items {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
        }
        gridTables {
          id
          name
          orderId
          index
          page
        }
        modifierSoldoutIds
        delivery {
          baemin
          coupang
          yogiyo
          enableGenProd
          updatedAt
          createdAt
        }
        createdAt
        updatedAt
        sales {
          startedAt
          endedAt
          reserves
          actualBalance
          currency
          note
          estimateBalance
          detail {
            totalSales
            totalRefund
            totalTax
            totalDiscount
            supplyAmount
            totalSalesCount
            cash {
              sales
              refund
            }
            card {
              sales
              refund
            }
            credit {
              sales
              refund
            }
            barcode {
              sales
              refund
            }
            extra {
              sales
              refund
            }
            refund {
              sales
              refund
            }
            products {
              id
              name
              quantity
              amount
            }
            origins {
              pos
              kiosk
              menuorder
              delivery
              market
            }
          }
          reservesHistories {
            type
            amount
            note
            createdEmployeeId
            createdAt
          }
        }
      }
      plan {
        id
        storeId
        productId
        skuId
        name
        recurringType
        recurringValue
        price
        quantity
        status
        image {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        gracePeriod
        subscriberCount
        locations {
          planId
          locationId
          available
          subscriberCount
        }
        updatedAt
        createdAt
        _delta
        timestamp
        expdate
        product {
          id
          type
          enterpriseId
          storeId
          origin
          refer
          referId
          name
          nameMl {
            en
            zh
            ja
          }
          description
          descriptionMl {
            en
            zh
            ja
          }
          variablePrice
          attributes {
            name
            type
            value
          }
          thumbnail {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          skus {
            type
            id
            itemId
            refer
            referId
            attributes {
              name
              type
              value
            }
            name
            nameMl {
              en
              zh
              ja
            }
            price
            cost
            unit
            unitSuffix
            barcode
            currency
            model
            locationId
            futureLocationAvailable
            locationIds
            inventories {
              id
              quantity
              cost
              locationId
              unavailableQuantity
              inReadyQuantity
              outReadyQuantity
              expiryDate
              updatedAt
              createdAt
              locations {
                id
                quantity
              }
              attributes {
                name
                type
                value
              }
            }
            locations {
              id
              isActive
              available
              price
            }
            links {
              type
              origin
              originId
              originProductId
              updatedAt
              createdAt
            }
            stock
            stockAlert
            stockAlertQty
            stockTrack
            quantity
            limitQuantity
            isActive
          }
          trackStock
          taxId
          categories {
            items {
              id
              enterpriseId
              storeId
              productId
              categoryId
              category {
                type
                id
                enterpriseId
                storeId
                name
                displayName
                refer
                referId
                locationIds
                updatedAt
                createdAt
              }
              updatedAt
              createdAt
              product {
                id
                type
                enterpriseId
                storeId
                origin
                refer
                referId
                name
                description
                variablePrice
                trackStock
                taxId
                locationIds
                updatedAt
                createdAt
              }
            }
            nextToken
          }
          classifications {
            items {
              id
              enterpriseId
              storeId
              productId
              classificationId
              classification {
                type
                id
                enterpriseId
                storeId
                name
                displayName
                refer
                referId
                locationIds
                updatedAt
                createdAt
              }
              updatedAt
              createdAt
              product {
                id
                type
                enterpriseId
                storeId
                origin
                refer
                referId
                name
                description
                variablePrice
                trackStock
                taxId
                locationIds
                updatedAt
                createdAt
              }
            }
            nextToken
          }
          modifiers {
            items {
              id
              enterpriseId
              storeId
              productId
              modifierId
              position
              modifier {
                id
                type
                enterpriseId
                storeId
                name
                origin
                orderType
                defaultSkuId
                coverage
                required
                requiredQuantity
                limitQuantity
                locationIds
                updatedAt
                createdAt
              }
              updatedAt
              createdAt
              product {
                id
                type
                enterpriseId
                storeId
                origin
                refer
                referId
                name
                description
                variablePrice
                trackStock
                taxId
                locationIds
                updatedAt
                createdAt
              }
            }
            nextToken
          }
          locationIds
          updatedAt
          createdAt
        }
      }
      store {
        id
        enterpriseId
        info {
          name
          phone
          email
          representative
          businessType
          businessNumber
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          industryType
          sendNumber
          currency
          address
          zip
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          csPhone
        }
        policy {
          multiLocation
          provider {
            key
            ident
            customer
            coupon
            inventory
            locationId
          }
        }
        currency
        payments {
          card
          cardJoined
          cash
          credit
          refund
        }
        devices {
          id
          ident
          locationId
          system
          ipAddress
          brand
          model
          name
          type
          deviceType
          display {
            height
            width
          }
          protocol
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          barcodeReader
          appVersion
          sysVersion
          status
          password
          msgToken
          parentId
          childId
          sleepMode
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
          terminalId
          printOptions {
            port
            baudRate
            receipt
            receiptButtonHide
            bills
            refund
            salesEnd
            order
            orderBeep
            orderCount
            orderExcludeIds
            delivery
          }
          updatedAt
          createdAt
        }
        plans {
          menus {
            id
            name
            mode
            size
            menus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            pages {
              index
              parentId
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              title
              titleMl {
                en
                zh
                ja
              }
            }
            updatedAt
            createdAt
          }
          orders {
            id
            name
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            updatedAt
            createdAt
          }
        }
        taxes {
          id
          type
          name
          enabled
          rate
          method
          targetType
        }
        receipt {
          salesSlip
          orderList
          barcode
          footnote
          phone
          orderNumberHide
          orderSheetFontSize
          billsBusinessInfo
          giftFootnote
          giftReceipt
        }
        subscription {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          updatedAt
          createdAt
        }
        view {
          order {
            excludeColumns
            exportTemplates {
              name
              type
              headers {
                key
                label
              }
            }
          }
          itemReport {
            excludeColumns
          }
          summaryReport {
            excludeColumns
          }
          shippingOrder {
            excludeColumns
          }
          trsItemReport {
            excludeColumns
          }
        }
        settings {
          mode
          table
          diningOption
          retailOption
          orderAlarm
          orderConfirm
          addressInput
          autoPay
          autoReturnItem
          autoSoldout
          menuGridSize
          menuMode
          tableMode
          orderNotify
          orderNotifySkippable
          disablePhonePrefix
          autoCloseScreen
          orderNumberPrefix {
            pos
            kiosk
            menuOrder
          }
          orderMgmt {
            kiosk
            menuorder
            delivery
            pos
          }
          reportBasis
          orderOrigins
          beeper {
            active
            coverage
            skippable
          }
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
        }
        item {
          attributes {
            name
            type
            value
          }
          actualPrice
          skuMgmt
          barcodeMgmt
          stockTrack
          variablePrice
          locationCreatable
        }
        inventory {
          attributes {
            name
            type
            value
          }
          expiryDateMgmt
          expiryDateAlertDays
        }
        customer {
          attributes {
            name
            type
            value
          }
        }
        billing {
          key
          businessNumber
          name
          email
          phone
          contanctName
          contactName
          address
          zip
          card {
            pg
            number
            name
            expMonth
            expYear
            identify
            password
            billingKey
          }
        }
        credit {
          autoCharge
          autoChargeCredit
          autoChargeQuantity
          alertCredit
        }
        kakao {
          agency
          key
          channelId
          categoryCode
          status
          updatedAt
          createdAt
        }
        review {
          status
          campaignId
          updatedAt
          createdAt
        }
        kiosk {
          status
          callable
          payable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          exchangeTicket
          columnSize
          discounts {
            condition
            couponId
          }
          menuDescription
          updatedAt
          createdAt
        }
        menuOrder {
          callOptions {
            index
            label
          }
          orderable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          columnSize
          fontSize
          notice
          resetTime
          enableCash
          boardType
        }
        kds {
          layout
          alerts {
            urgent
            warning
          }
          disabledProdIds
          orderNotify
        }
        mirror {
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          video {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
        }
        loyalty {
          status
          provider
          sharePolicy
          type
          grade
          conditionType
          conditionSubType
          condition {
            minAmount
            perAmount
            minTime
            rate
            expirationPeriod
            excludeProdIds
            discountPointSavable
          }
          conditionItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          conditionTiers {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardType
          rewardSubType
          reward {
            requirePoint
            requireOrderAmount
            minUsePoint
            minRequireRate
            maxUsePoint
          }
          rewardItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardRates {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardAmounts {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardCashs {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          incentives {
            fulfill
            fulfillPoint
            fulfillCampaignId
            birth
            birthPoint
            birthCampaignId
            birthItem {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
          }
          notification {
            register
            registerCampainId
            save
            saveCampaignId
            use
            useCampaignId
            expire
            expireCampaignId
          }
        }
        market {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          paused
          takeoutDiscount
          creditDiscount
          takeout
          intro
          notice
          bookTable
          bookTableNote
          depositAmount
          delivery
          deliveryFee {
            start
            end
            fee
          }
          waitingTime
          itemIds
          updatedAt
          createdAt
        }
        notification {
          options {
            type
            email
            sms
          }
        }
        groups {
          items {
            id
            name
            type
            storeGroupsId
            locationGroupsId
            key
            conditions {
              key
              object
              field
              operations {
                operation
                type
                value
              }
              value
              operation
              type
            }
            count
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        location {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        locations {
          items {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createSubscriptionPlanDelta = /* GraphQL */ `
  mutation CreateSubscriptionPlanDelta(
    $input: CreateSubscriptionPlanDeltaInput!
  ) {
    createSubscriptionPlanDelta(input: $input) {
      id
      storeId
      productId
      skuId
      name
      recurringType
      recurringValue
      price
      quantity
      status
      image {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      gracePeriod
      subscriberCount
      locations {
        planId
        locationId
        available
        subscriberCount
      }
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
      product {
        id
        type
        enterpriseId
        storeId
        origin
        refer
        referId
        name
        nameMl {
          en
          zh
          ja
        }
        description
        descriptionMl {
          en
          zh
          ja
        }
        variablePrice
        attributes {
          name
          type
          value
        }
        thumbnail {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        skus {
          type
          id
          itemId
          refer
          referId
          attributes {
            name
            type
            value
          }
          name
          nameMl {
            en
            zh
            ja
          }
          price
          cost
          unit
          unitSuffix
          barcode
          currency
          model
          locationId
          futureLocationAvailable
          locationIds
          inventories {
            id
            quantity
            cost
            locationId
            unavailableQuantity
            inReadyQuantity
            outReadyQuantity
            expiryDate
            updatedAt
            createdAt
            locations {
              id
              quantity
            }
            attributes {
              name
              type
              value
            }
          }
          locations {
            id
            isActive
            available
            price
          }
          links {
            type
            origin
            originId
            originProductId
            updatedAt
            createdAt
          }
          stock
          stockAlert
          stockAlertQty
          stockTrack
          quantity
          limitQuantity
          isActive
        }
        trackStock
        taxId
        categories {
          items {
            id
            enterpriseId
            storeId
            productId
            categoryId
            category {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        classifications {
          items {
            id
            enterpriseId
            storeId
            productId
            classificationId
            classification {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        modifiers {
          items {
            id
            enterpriseId
            storeId
            productId
            modifierId
            position
            modifier {
              id
              type
              enterpriseId
              storeId
              name
              nameMl {
                en
                zh
                ja
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              origin
              orderType
              defaultSkuId
              coverage
              required
              requiredQuantity
              limitQuantity
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        locationIds
        updatedAt
        createdAt
      }
    }
  }
`;
export const updateSubscriptionPlanDelta = /* GraphQL */ `
  mutation UpdateSubscriptionPlanDelta(
    $input: UpdateSubscriptionPlanDeltaInput!
  ) {
    updateSubscriptionPlanDelta(input: $input) {
      id
      storeId
      productId
      skuId
      name
      recurringType
      recurringValue
      price
      quantity
      status
      image {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      gracePeriod
      subscriberCount
      locations {
        planId
        locationId
        available
        subscriberCount
      }
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
      product {
        id
        type
        enterpriseId
        storeId
        origin
        refer
        referId
        name
        nameMl {
          en
          zh
          ja
        }
        description
        descriptionMl {
          en
          zh
          ja
        }
        variablePrice
        attributes {
          name
          type
          value
        }
        thumbnail {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        skus {
          type
          id
          itemId
          refer
          referId
          attributes {
            name
            type
            value
          }
          name
          nameMl {
            en
            zh
            ja
          }
          price
          cost
          unit
          unitSuffix
          barcode
          currency
          model
          locationId
          futureLocationAvailable
          locationIds
          inventories {
            id
            quantity
            cost
            locationId
            unavailableQuantity
            inReadyQuantity
            outReadyQuantity
            expiryDate
            updatedAt
            createdAt
            locations {
              id
              quantity
            }
            attributes {
              name
              type
              value
            }
          }
          locations {
            id
            isActive
            available
            price
          }
          links {
            type
            origin
            originId
            originProductId
            updatedAt
            createdAt
          }
          stock
          stockAlert
          stockAlertQty
          stockTrack
          quantity
          limitQuantity
          isActive
        }
        trackStock
        taxId
        categories {
          items {
            id
            enterpriseId
            storeId
            productId
            categoryId
            category {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        classifications {
          items {
            id
            enterpriseId
            storeId
            productId
            classificationId
            classification {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        modifiers {
          items {
            id
            enterpriseId
            storeId
            productId
            modifierId
            position
            modifier {
              id
              type
              enterpriseId
              storeId
              name
              nameMl {
                en
                zh
                ja
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              origin
              orderType
              defaultSkuId
              coverage
              required
              requiredQuantity
              limitQuantity
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        locationIds
        updatedAt
        createdAt
      }
    }
  }
`;
export const deleteSubscriptionPlanDelta = /* GraphQL */ `
  mutation DeleteSubscriptionPlanDelta(
    $input: DeleteSubscriptionPlanDeltaInput!
  ) {
    deleteSubscriptionPlanDelta(input: $input) {
      id
      storeId
      productId
      skuId
      name
      recurringType
      recurringValue
      price
      quantity
      status
      image {
        key
        bucket
        region
        level
        url
        xsmall
        small
      }
      gracePeriod
      subscriberCount
      locations {
        planId
        locationId
        available
        subscriberCount
      }
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
      product {
        id
        type
        enterpriseId
        storeId
        origin
        refer
        referId
        name
        nameMl {
          en
          zh
          ja
        }
        description
        descriptionMl {
          en
          zh
          ja
        }
        variablePrice
        attributes {
          name
          type
          value
        }
        thumbnail {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        skus {
          type
          id
          itemId
          refer
          referId
          attributes {
            name
            type
            value
          }
          name
          nameMl {
            en
            zh
            ja
          }
          price
          cost
          unit
          unitSuffix
          barcode
          currency
          model
          locationId
          futureLocationAvailable
          locationIds
          inventories {
            id
            quantity
            cost
            locationId
            unavailableQuantity
            inReadyQuantity
            outReadyQuantity
            expiryDate
            updatedAt
            createdAt
            locations {
              id
              quantity
            }
            attributes {
              name
              type
              value
            }
          }
          locations {
            id
            isActive
            available
            price
          }
          links {
            type
            origin
            originId
            originProductId
            updatedAt
            createdAt
          }
          stock
          stockAlert
          stockAlertQty
          stockTrack
          quantity
          limitQuantity
          isActive
        }
        trackStock
        taxId
        categories {
          items {
            id
            enterpriseId
            storeId
            productId
            categoryId
            category {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        classifications {
          items {
            id
            enterpriseId
            storeId
            productId
            classificationId
            classification {
              type
              id
              enterpriseId
              storeId
              name
              displayName
              refer
              referId
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        modifiers {
          items {
            id
            enterpriseId
            storeId
            productId
            modifierId
            position
            modifier {
              id
              type
              enterpriseId
              storeId
              name
              nameMl {
                en
                zh
                ja
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              origin
              orderType
              defaultSkuId
              coverage
              required
              requiredQuantity
              limitQuantity
              products {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
            updatedAt
            createdAt
            product {
              id
              type
              enterpriseId
              storeId
              origin
              refer
              referId
              name
              nameMl {
                en
                zh
                ja
              }
              description
              descriptionMl {
                en
                zh
                ja
              }
              variablePrice
              attributes {
                name
                type
                value
              }
              thumbnail {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              skus {
                type
                id
                itemId
                refer
                referId
                name
                price
                cost
                unit
                unitSuffix
                barcode
                currency
                model
                locationId
                futureLocationAvailable
                locationIds
                stock
                stockAlert
                stockAlertQty
                stockTrack
                quantity
                limitQuantity
                isActive
              }
              trackStock
              taxId
              categories {
                nextToken
              }
              classifications {
                nextToken
              }
              modifiers {
                nextToken
              }
              locationIds
              updatedAt
              createdAt
            }
          }
          nextToken
        }
        locationIds
        updatedAt
        createdAt
      }
    }
  }
`;
export const createStoreCreditTransaction = /* GraphQL */ `
  mutation CreateStoreCreditTransaction(
    $input: CreateStoreCreditTransactionInput!
  ) {
    createStoreCreditTransaction(input: $input) {
      id
      type
      origin
      method
      storeId
      locationId
      referId
      name
      quantity
      price
      amount
      currency
      createdAt
      updatedAt
    }
  }
`;
export const updateStoreCreditTransaction = /* GraphQL */ `
  mutation UpdateStoreCreditTransaction(
    $input: UpdateStoreCreditTransactionInput!
  ) {
    updateStoreCreditTransaction(input: $input) {
      id
      type
      origin
      method
      storeId
      locationId
      referId
      name
      quantity
      price
      amount
      currency
      createdAt
      updatedAt
    }
  }
`;
export const deleteStoreCreditTransaction = /* GraphQL */ `
  mutation DeleteStoreCreditTransaction(
    $input: DeleteStoreCreditTransactionInput!
  ) {
    deleteStoreCreditTransaction(input: $input) {
      id
      type
      origin
      method
      storeId
      locationId
      referId
      name
      quantity
      price
      amount
      currency
      createdAt
      updatedAt
    }
  }
`;
export const updateStore = /* GraphQL */ `
  mutation UpdateStore($input: UpdateStoreInput!) {
    updateStore(input: $input) {
      id
      enterpriseId
      info {
        name
        phone
        email
        representative
        businessType
        businessNumber
        businessHours {
          sun {
            open
            close
          }
          mon {
            open
            close
          }
          tue {
            open
            close
          }
          wed {
            open
            close
          }
          thu {
            open
            close
          }
          fri {
            open
            close
          }
          sat {
            open
            close
          }
        }
        industryType
        sendNumber
        currency
        address
        zip
        logo {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        csPhone
      }
      policy {
        multiLocation
        provider {
          key
          ident
          customer
          coupon
          inventory
          locationId
        }
      }
      currency
      payments {
        card
        cardJoined
        cash
        credit
        refund
      }
      devices {
        id
        ident
        locationId
        system
        ipAddress
        brand
        model
        name
        type
        deviceType
        display {
          height
          width
        }
        protocol
        images {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        barcodeReader
        appVersion
        sysVersion
        status
        password
        msgToken
        parentId
        childId
        sleepMode
        kioskOptions {
          mode
          isActive
          tableId
          orderId
          callable
          payable
          enableDiningOption
          resetTime
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          columnSize
        }
        terminalId
        printOptions {
          port
          baudRate
          receipt
          receiptButtonHide
          bills
          refund
          salesEnd
          order
          orderBeep
          orderCount
          orderExcludeIds
          delivery
        }
        updatedAt
        createdAt
      }
      plans {
        menus {
          id
          name
          mode
          size
          menus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          pages {
            index
            parentId
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            title
            titleMl {
              en
              zh
              ja
            }
          }
          updatedAt
          createdAt
        }
        orders {
          id
          name
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          updatedAt
          createdAt
        }
      }
      taxes {
        id
        type
        name
        enabled
        rate
        method
        targetType
      }
      receipt {
        salesSlip
        orderList
        barcode
        footnote
        phone
        orderNumberHide
        orderSheetFontSize
        billsBusinessInfo
        giftFootnote
        giftReceipt
      }
      subscription {
        status
        account {
          ident
          holder
          bank
          bankCode
          number
          businessNumber
        }
        updatedAt
        createdAt
      }
      view {
        order {
          excludeColumns
          exportTemplates {
            name
            type
            headers {
              key
              label
            }
          }
        }
        itemReport {
          excludeColumns
        }
        summaryReport {
          excludeColumns
        }
        shippingOrder {
          excludeColumns
        }
        trsItemReport {
          excludeColumns
        }
      }
      settings {
        mode
        table
        diningOption
        retailOption
        orderAlarm
        orderConfirm
        addressInput
        autoPay
        autoReturnItem
        autoSoldout
        menuGridSize
        menuMode
        tableMode
        orderNotify
        orderNotifySkippable
        disablePhonePrefix
        autoCloseScreen
        orderNumberPrefix {
          pos
          kiosk
          menuOrder
        }
        orderMgmt {
          kiosk
          menuorder
          delivery
          pos
        }
        reportBasis
        orderOrigins
        beeper {
          active
          coverage
          skippable
        }
        kioskOptions {
          mode
          isActive
          tableId
          orderId
          callable
          payable
          enableDiningOption
          resetTime
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          columnSize
        }
      }
      item {
        attributes {
          name
          type
          value
        }
        actualPrice
        skuMgmt
        barcodeMgmt
        stockTrack
        variablePrice
        locationCreatable
      }
      inventory {
        attributes {
          name
          type
          value
        }
        expiryDateMgmt
        expiryDateAlertDays
      }
      customer {
        attributes {
          name
          type
          value
        }
      }
      billing {
        key
        businessNumber
        name
        email
        phone
        contanctName
        contactName
        address
        zip
        card {
          pg
          number
          name
          expMonth
          expYear
          identify
          password
          billingKey
        }
      }
      credit {
        autoCharge
        autoChargeCredit
        autoChargeQuantity
        alertCredit
      }
      kakao {
        agency
        key
        channelId
        categoryCode
        status
        updatedAt
        createdAt
      }
      review {
        status
        campaignId
        updatedAt
        createdAt
      }
      kiosk {
        status
        callable
        payable
        menus {
          title
          titleMl {
            en
            zh
            ja
          }
          index
          ids
          items {
            parentId
            badge
            type
            position
            activeOptions
          }
          parentId
        }
        multilingual
        displayImages {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        exchangeTicket
        columnSize
        discounts {
          condition
          couponId
        }
        menuDescription
        updatedAt
        createdAt
      }
      menuOrder {
        callOptions {
          index
          label
        }
        orderable
        menus {
          title
          titleMl {
            en
            zh
            ja
          }
          index
          ids
          items {
            parentId
            badge
            type
            position
            activeOptions
          }
          parentId
        }
        multilingual
        displayImages {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        columnSize
        fontSize
        notice
        resetTime
        enableCash
        boardType
      }
      kds {
        layout
        alerts {
          urgent
          warning
        }
        disabledProdIds
        orderNotify
      }
      mirror {
        images {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        video {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
      }
      loyalty {
        status
        provider
        sharePolicy
        type
        grade
        conditionType
        conditionSubType
        condition {
          minAmount
          perAmount
          minTime
          rate
          expirationPeriod
          excludeProdIds
          discountPointSavable
        }
        conditionItems {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        conditionTiers {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardType
        rewardSubType
        reward {
          requirePoint
          requireOrderAmount
          minUsePoint
          minRequireRate
          maxUsePoint
        }
        rewardItems {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardRates {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardAmounts {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        rewardCashs {
          id
          itemId
          name
          parentId
          rate
          point
          amount
        }
        incentives {
          fulfill
          fulfillPoint
          fulfillCampaignId
          birth
          birthPoint
          birthCampaignId
          birthItem {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
        }
        notification {
          register
          registerCampainId
          save
          saveCampaignId
          use
          useCampaignId
          expire
          expireCampaignId
        }
      }
      market {
        status
        account {
          ident
          holder
          bank
          bankCode
          number
          businessNumber
        }
        paused
        takeoutDiscount
        creditDiscount
        takeout
        intro
        notice
        bookTable
        bookTableNote
        depositAmount
        delivery
        deliveryFee {
          start
          end
          fee
        }
        waitingTime
        itemIds
        updatedAt
        createdAt
      }
      notification {
        options {
          type
          email
          sms
        }
      }
      groups {
        items {
          id
          name
          type
          storeGroupsId
          locationGroupsId
          key
          conditions {
            key
            object
            field
            operations {
              operation
              type
              value
            }
            value
            operation
            type
          }
          count
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      location {
        id
        name
        type
        provider
        address
        zip
        phone
        logo {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        representative
        businessNumber
        billing {
          key
          businessNumber
          name
          email
          phone
          contanctName
          contactName
          address
          zip
          card {
            pg
            number
            name
            expMonth
            expYear
            identify
            password
            billingKey
          }
        }
        credit {
          autoCharge
          autoChargeCredit
          autoChargeQuantity
          alertCredit
        }
        businessHours {
          sun {
            open
            close
          }
          mon {
            open
            close
          }
          tue {
            open
            close
          }
          wed {
            open
            close
          }
          thu {
            open
            close
          }
          fri {
            open
            close
          }
          sat {
            open
            close
          }
        }
        storeId
        state
        menuPlanId
        payments {
          refund
          barcode
          barcodeVan
          barcodeTerminalId
        }
        policy {
          locationCost
          loyaltyCost
        }
        loyalty {
          status
          provider
          sharePolicy
          type
          grade
          conditionType
          conditionSubType
          condition {
            minAmount
            perAmount
            minTime
            rate
            expirationPeriod
            excludeProdIds
            discountPointSavable
          }
          conditionItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          conditionTiers {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardType
          rewardSubType
          reward {
            requirePoint
            requireOrderAmount
            minUsePoint
            minRequireRate
            maxUsePoint
          }
          rewardItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardRates {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardAmounts {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardCashs {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          incentives {
            fulfill
            fulfillPoint
            fulfillCampaignId
            birth
            birthPoint
            birthCampaignId
            birthItem {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
          }
          notification {
            register
            registerCampainId
            save
            saveCampaignId
            use
            useCampaignId
            expire
            expireCampaignId
          }
        }
        kiosk {
          status
          callable
          payable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          exchangeTicket
          columnSize
          discounts {
            condition
            couponId
          }
          menuDescription
          updatedAt
          createdAt
        }
        receipt {
          salesSlip
          orderList
          barcode
          footnote
          phone
          orderNumberHide
          orderSheetFontSize
          billsBusinessInfo
          giftFootnote
          giftReceipt
        }
        menuOrder {
          callOptions {
            index
            label
          }
          orderable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          columnSize
          fontSize
          notice
          resetTime
          enableCash
          boardType
        }
        kds {
          layout
          alerts {
            urgent
            warning
          }
          disabledProdIds
          orderNotify
        }
        mini {
          defaultItemName
        }
        orderPlanId
        menu {
          board {
            textSize
            columnSize
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            enableFavorite
          }
          list {
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            shortcuts {
              type
              title
              itemName
              itemPrice
              active
            }
          }
          navOrder
        }
        menus {
          parentId
          position
          page
          pageTitle
        }
        favMenus {
          parentId
          position
          page
          pageTitle
        }
        catMenus {
          title
          index
          ids
          parentId
        }
        market {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          paused
          takeoutDiscount
          creditDiscount
          takeout
          intro
          notice
          bookTable
          bookTableNote
          depositAmount
          delivery
          deliveryFee {
            start
            end
            fee
          }
          waitingTime
          itemIds
          updatedAt
          createdAt
        }
        waiting {
          status
          waitingOptions {
            id
            type
            name
            nameMl {
              en
              zh
              ja
            }
            items {
              id
              name
              nameMl {
                en
                zh
                ja
              }
              limitQuantity
            }
            required
            updatedAt
            createdAt
          }
          waitingMinutes
          waitingMinutesHide
          hasForeigner
          foreignerMethod
          notifications {
            created
            ready
            requested
            cancelled
          }
          notices {
            createdNotice
            createdNoticeMl {
              en
              zh
              ja
            }
            requestedNotice
            requestedNoticeMl {
              en
              zh
              ja
            }
          }
          settings {
            autoCancel
            autoCancelMinutes
          }
          updatedAt
          createdAt
        }
        view {
          order {
            excludeColumns
            exportTemplates {
              name
              type
              headers {
                key
                label
              }
            }
          }
          itemReport {
            excludeColumns
          }
          summaryReport {
            excludeColumns
          }
          shippingOrder {
            excludeColumns
          }
          trsItemReport {
            excludeColumns
          }
        }
        subscription {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          updatedAt
          createdAt
        }
        settings {
          mode
          table
          diningOption
          retailOption
          orderAlarm
          orderConfirm
          addressInput
          autoPay
          autoReturnItem
          autoSoldout
          menuGridSize
          menuMode
          tableMode
          orderNotify
          orderNotifySkippable
          disablePhonePrefix
          autoCloseScreen
          orderNumberPrefix {
            pos
            kiosk
            menuOrder
          }
          orderMgmt {
            kiosk
            menuorder
            delivery
            pos
          }
          reportBasis
          orderOrigins
          beeper {
            active
            coverage
            skippable
          }
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
        }
        devices {
          id
          ident
          locationId
          system
          ipAddress
          brand
          model
          name
          type
          deviceType
          display {
            height
            width
          }
          protocol
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          barcodeReader
          appVersion
          sysVersion
          status
          password
          msgToken
          parentId
          childId
          sleepMode
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
          terminalId
          printOptions {
            port
            baudRate
            receipt
            receiptButtonHide
            bills
            refund
            salesEnd
            order
            orderBeep
            orderCount
            orderExcludeIds
            delivery
          }
          updatedAt
          createdAt
        }
        tables {
          id
          width
          height
          name
          axisX
          axisY
          screenHeight
          screenWidth
          initialX
          initialY
          shape
          layout {
            x
            y
            w
            h
          }
          color
          orderId
          disabled
        }
        boardTables {
          title
          index
          items {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
        }
        gridTables {
          id
          name
          orderId
          index
          page
        }
        modifierSoldoutIds
        delivery {
          baemin
          coupang
          yogiyo
          enableGenProd
          updatedAt
          createdAt
        }
        createdAt
        updatedAt
        sales {
          startedAt
          endedAt
          reserves
          actualBalance
          currency
          note
          estimateBalance
          detail {
            totalSales
            totalRefund
            totalTax
            totalDiscount
            supplyAmount
            totalSalesCount
            cash {
              sales
              refund
            }
            card {
              sales
              refund
            }
            credit {
              sales
              refund
            }
            barcode {
              sales
              refund
            }
            extra {
              sales
              refund
            }
            refund {
              sales
              refund
            }
            products {
              id
              name
              quantity
              amount
            }
            origins {
              pos
              kiosk
              menuorder
              delivery
              market
            }
          }
          reservesHistories {
            type
            amount
            note
            createdEmployeeId
            createdAt
          }
        }
      }
      locations {
        items {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createStoreStatus = /* GraphQL */ `
  mutation CreateStoreStatus($input: CreateStoreStatusInput!) {
    createStoreStatus(input: $input) {
      id
      item {
        func
        progress
        errorMessage
        updatedAt
        createdAt
      }
      updatedAt
      createdAt
    }
  }
`;
export const updateStoreStatus = /* GraphQL */ `
  mutation UpdateStoreStatus($input: UpdateStoreStatusInput!) {
    updateStoreStatus(input: $input) {
      id
      item {
        func
        progress
        errorMessage
        updatedAt
        createdAt
      }
      updatedAt
      createdAt
    }
  }
`;
export const deleteStoreStatus = /* GraphQL */ `
  mutation DeleteStoreStatus($input: DeleteStoreStatusInput!) {
    deleteStoreStatus(input: $input) {
      id
      item {
        func
        progress
        errorMessage
        updatedAt
        createdAt
      }
      updatedAt
      createdAt
    }
  }
`;
export const createRequestProgressEvent = /* GraphQL */ `
  mutation CreateRequestProgressEvent(
    $input: CreateRequestProgressEventInput!
  ) {
    createRequestProgressEvent(input: $input) {
      id
      origin
      originId
      func
      params
      status
      progress
      errorMessage
      doneAt
      updatedAt
      createdAt
    }
  }
`;
export const updateRequestProgressEvent = /* GraphQL */ `
  mutation UpdateRequestProgressEvent(
    $input: UpdateRequestProgressEventInput!
  ) {
    updateRequestProgressEvent(input: $input) {
      id
      origin
      originId
      func
      params
      status
      progress
      errorMessage
      doneAt
      updatedAt
      createdAt
    }
  }
`;
export const deleteRequestProgressEvent = /* GraphQL */ `
  mutation DeleteRequestProgressEvent(
    $input: DeleteRequestProgressEventInput!
  ) {
    deleteRequestProgressEvent(input: $input) {
      id
      origin
      originId
      func
      params
      status
      progress
      errorMessage
      doneAt
      updatedAt
      createdAt
    }
  }
`;
export const createLocationStats = /* GraphQL */ `
  mutation CreateLocationStats($input: CreateLocationStatsInput!) {
    createLocationStats(input: $input) {
      id
      sales {
        date
        yesterday
        today
      }
      updatedAt
      createdAt
    }
  }
`;
export const updateLocationStats = /* GraphQL */ `
  mutation UpdateLocationStats($input: UpdateLocationStatsInput!) {
    updateLocationStats(input: $input) {
      id
      sales {
        date
        yesterday
        today
      }
      updatedAt
      createdAt
    }
  }
`;
export const deleteLocationStats = /* GraphQL */ `
  mutation DeleteLocationStats($input: DeleteLocationStatsInput!) {
    deleteLocationStats(input: $input) {
      id
      sales {
        date
        yesterday
        today
      }
      updatedAt
      createdAt
    }
  }
`;
export const createBusinessOperator = /* GraphQL */ `
  mutation CreateBusinessOperator($input: CreateBusinessOperatorInput!) {
    createBusinessOperator(input: $input) {
      id
      businessNumber
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateBusinessOperator = /* GraphQL */ `
  mutation UpdateBusinessOperator($input: UpdateBusinessOperatorInput!) {
    updateBusinessOperator(input: $input) {
      id
      businessNumber
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteBusinessOperator = /* GraphQL */ `
  mutation DeleteBusinessOperator($input: DeleteBusinessOperatorInput!) {
    deleteBusinessOperator(input: $input) {
      id
      businessNumber
      name
      createdAt
      updatedAt
    }
  }
`;
export const createPointTransaction = /* GraphQL */ `
  mutation CreatePointTransaction($input: CreatePointTransactionInput!) {
    createPointTransaction(input: $input) {
      id
      locationId
      storeId
      type
      name
      amount
      origin
      provider
      providerCustomerId
      providerSyncedAt
      currency
      point
      extraPoint
      extraExpire
      items {
        type
        itemId
        name
        parentId
        point
        amount
        quantity
      }
      quantity
      note
      orderId
      referId
      orderNumberPrefix
      orderNumber
      customerId
      pointTransactionCustomerId
      expiredAt
      salesDate
      updatedAt
      createdAt
      customer {
        id
        customerId
        storeId
        sources {
          id
          type
          name
          ident
          createdAt
        }
        isFavorite
        locationId
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        favoriteLocationIds
        visitedLocationIds
        locations {
          id
          locationId
          isFavorite
          status
          note
          campaignBlockedAt
          blacklistedAt
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          subscriptions {
            id
            planId
            payStatus
            sessionId
            nextPayDate
            paidAt
            unsubscribedAt
            used
            lastUsedAt
            orderId
            unsubscribeRequestedAt
            startDate
            expireDate
            updatedAt
            createdAt
          }
          registeredAt
          salesDate
          updatedAt
          createdAt
        }
        status
        registeredAt
        campaignBlockedAt
        note
        removedAt
        updatedAt
        createdAt
        points {
          items {
            id
            locationId
            storeId
            type
            name
            amount
            origin
            provider
            providerCustomerId
            providerSyncedAt
            currency
            point
            extraPoint
            extraExpire
            items {
              type
              itemId
              name
              parentId
              point
              amount
              quantity
            }
            quantity
            note
            orderId
            referId
            orderNumberPrefix
            orderNumber
            customerId
            pointTransactionCustomerId
            expiredAt
            salesDate
            updatedAt
            createdAt
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
          }
          nextToken
        }
        credits {
          items {
            id
            locationId
            storeId
            name
            type
            amount
            origin
            note
            currency
            parentId
            orderId
            orderNumberPrefix
            orderNumber
            customerId
            creditTransactionCustomerId
            description
            salesDate
            createdAt
            updatedAt
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
          }
          nextToken
        }
        _delta
        orders {
          items {
            id
            orderLocationId
            numberPrefix
            number
            beeper
            enterpriseId
            storeId
            customerId
            orderCustomerId
            state
            amount
            amountPaid
            origin
            subOrigin
            originId
            customerProvider
            providerCustomerId
            amountChanged
            amountReturned
            tax
            currency
            quantity
            shippingType
            diningOption
            diningState
            retailOption
            retailState
            name
            phone
            address
            zip
            receivers {
              id
              name
              phone
              zip
              address1
              address2
              addressState
              addressCity
              addressStreet
              addressFull
              note
              shippingCompanyCode
              shippingAcceptCount
              shippingStartCount
              trackingNumber
              privacyAgreedAt
            }
            tableId
            tableIds
            parentDeviceId
            deviceId
            reserveDate
            pickupDate
            shippingDate
            items {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            createdEmployeeId
            createdDeviceId
            updatedDeviceId
            shippingLocationId
            confirmedEmployeeId
            confirmedAt
            createdAt
            paidAt
            salesDate
            returnedAt
            shippedAt
            returnShippedAt
            returnSalesDate
            updatedAt
            noticeCount
            visitorCount
            totalPoint
            pointSaved
            receiptId
            receiptNumber
            receiptCount
            alarmCustomerId
            receiptCustomerId
            note
            request
            charges {
              id
              method
              extraMethod
              gateway
              amount
              tax
              taxRefundAmount
              purchaser {
                name
                number
                nation
                address
              }
              supplyAmount
              taxFreeAmount
              amountRefunded
              createdEmployeeId
              createdDeviceId
              createdAt
              creditTransactionId
              balance
              splitType
              pg
              van
              origin
              originId
              note
              brand
              name
              ident
              installment
              transactionId
              approver
              issuerCode
              acquirer
              acquirerCode
              acquirerName
              refundable
              recurring
              approvalCode
              approvalDate
              approvalNumber
              signature {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              refunds {
                id
                amount
                supplyAmount
                taxFreeAmount
                tax
                taxRefundAmount
                creditTransactionId
                approvalNumber
                approvalDate
                transactionId
                origin
                originId
                currency
                reason
                createdEmployeeId
                createdDeviceId
                createdAt
              }
            }
            returns {
              amount
              currency
              items {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              refundId
              createdAt
            }
            fee
            revision
            _delta
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
            orderCustomer {
              id
              enterpriseId
              storeId
              locationId
              customerId
              provider
              providerCustomerId
              tier
              customerCreatedAt
              ident
              identType
              type
              name
              nameKeyword
              payments {
                id
                method
                pg
                number
                name
                expMonth
                expYear
                billingKey
                updatedAt
                createdAt
              }
              attributes {
                name
                type
                value
              }
              addresses {
                id
                main
                detail
                storeId
              }
              phone
              email
              birth
              agreements {
                privacy
                provider
                eula
                marketing
                updatedAt
                createdAt
              }
              ageGroup
              gender
              tags {
                tagId
                createdLocationId
              }
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              status
              registeredAt
              salesDate
              campaignBlockedAt
              blacklistedAt
              isFavorite
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              subscriptions {
                id
                planId
                payStatus
                sessionId
                nextPayDate
                paidAt
                unsubscribedAt
                used
                lastUsedAt
                orderId
                unsubscribeRequestedAt
                startDate
                expireDate
                updatedAt
                createdAt
              }
              note
              sources {
                id
                type
                name
                ident
                createdAt
              }
              removedAt
              updatedAt
              createdAt
              _delta
              timestamp
              expdate
            }
          }
          nextToken
        }
        location {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        store {
          id
          enterpriseId
          info {
            name
            phone
            email
            representative
            businessType
            businessNumber
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            industryType
            sendNumber
            currency
            address
            zip
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            csPhone
          }
          policy {
            multiLocation
            provider {
              key
              ident
              customer
              coupon
              inventory
              locationId
            }
          }
          currency
          payments {
            card
            cardJoined
            cash
            credit
            refund
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          plans {
            menus {
              id
              name
              mode
              size
              menus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              pages {
                index
                parentId
                title
              }
              updatedAt
              createdAt
            }
            orders {
              id
              name
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              updatedAt
              createdAt
            }
          }
          taxes {
            id
            type
            name
            enabled
            rate
            method
            targetType
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          item {
            attributes {
              name
              type
              value
            }
            actualPrice
            skuMgmt
            barcodeMgmt
            stockTrack
            variablePrice
            locationCreatable
          }
          inventory {
            attributes {
              name
              type
              value
            }
            expiryDateMgmt
            expiryDateAlertDays
          }
          customer {
            attributes {
              name
              type
              value
            }
          }
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          kakao {
            agency
            key
            channelId
            categoryCode
            status
            updatedAt
            createdAt
          }
          review {
            status
            campaignId
            updatedAt
            createdAt
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mirror {
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            video {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          notification {
            options {
              type
              email
              sms
            }
          }
          groups {
            items {
              id
              name
              type
              storeGroupsId
              locationGroupsId
              key
              conditions {
                key
                object
                field
                value
                operation
                type
              }
              count
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          location {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          locations {
            items {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            nextToken
          }
        }
        customer {
          id
          ident
          identType
          type
          name
          addresses {
            id
            main
            detail
            storeId
          }
          phone
          email
          birth
          agreements {
            privacy
            provider
            eula
            marketing
            updatedAt
            createdAt
          }
          ageGroup
          gender
          updatedAt
          createdAt
          stores {
            items {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const createCreditTransaction = /* GraphQL */ `
  mutation CreateCreditTransaction($input: CreateCreditTransactionInput!) {
    createCreditTransaction(input: $input) {
      id
      locationId
      storeId
      name
      type
      amount
      origin
      note
      currency
      parentId
      orderId
      orderNumberPrefix
      orderNumber
      customerId
      creditTransactionCustomerId
      description
      salesDate
      createdAt
      updatedAt
      customer {
        id
        customerId
        storeId
        sources {
          id
          type
          name
          ident
          createdAt
        }
        isFavorite
        locationId
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        favoriteLocationIds
        visitedLocationIds
        locations {
          id
          locationId
          isFavorite
          status
          note
          campaignBlockedAt
          blacklistedAt
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          subscriptions {
            id
            planId
            payStatus
            sessionId
            nextPayDate
            paidAt
            unsubscribedAt
            used
            lastUsedAt
            orderId
            unsubscribeRequestedAt
            startDate
            expireDate
            updatedAt
            createdAt
          }
          registeredAt
          salesDate
          updatedAt
          createdAt
        }
        status
        registeredAt
        campaignBlockedAt
        note
        removedAt
        updatedAt
        createdAt
        points {
          items {
            id
            locationId
            storeId
            type
            name
            amount
            origin
            provider
            providerCustomerId
            providerSyncedAt
            currency
            point
            extraPoint
            extraExpire
            items {
              type
              itemId
              name
              parentId
              point
              amount
              quantity
            }
            quantity
            note
            orderId
            referId
            orderNumberPrefix
            orderNumber
            customerId
            pointTransactionCustomerId
            expiredAt
            salesDate
            updatedAt
            createdAt
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
          }
          nextToken
        }
        credits {
          items {
            id
            locationId
            storeId
            name
            type
            amount
            origin
            note
            currency
            parentId
            orderId
            orderNumberPrefix
            orderNumber
            customerId
            creditTransactionCustomerId
            description
            salesDate
            createdAt
            updatedAt
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
          }
          nextToken
        }
        _delta
        orders {
          items {
            id
            orderLocationId
            numberPrefix
            number
            beeper
            enterpriseId
            storeId
            customerId
            orderCustomerId
            state
            amount
            amountPaid
            origin
            subOrigin
            originId
            customerProvider
            providerCustomerId
            amountChanged
            amountReturned
            tax
            currency
            quantity
            shippingType
            diningOption
            diningState
            retailOption
            retailState
            name
            phone
            address
            zip
            receivers {
              id
              name
              phone
              zip
              address1
              address2
              addressState
              addressCity
              addressStreet
              addressFull
              note
              shippingCompanyCode
              shippingAcceptCount
              shippingStartCount
              trackingNumber
              privacyAgreedAt
            }
            tableId
            tableIds
            parentDeviceId
            deviceId
            reserveDate
            pickupDate
            shippingDate
            items {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            createdEmployeeId
            createdDeviceId
            updatedDeviceId
            shippingLocationId
            confirmedEmployeeId
            confirmedAt
            createdAt
            paidAt
            salesDate
            returnedAt
            shippedAt
            returnShippedAt
            returnSalesDate
            updatedAt
            noticeCount
            visitorCount
            totalPoint
            pointSaved
            receiptId
            receiptNumber
            receiptCount
            alarmCustomerId
            receiptCustomerId
            note
            request
            charges {
              id
              method
              extraMethod
              gateway
              amount
              tax
              taxRefundAmount
              purchaser {
                name
                number
                nation
                address
              }
              supplyAmount
              taxFreeAmount
              amountRefunded
              createdEmployeeId
              createdDeviceId
              createdAt
              creditTransactionId
              balance
              splitType
              pg
              van
              origin
              originId
              note
              brand
              name
              ident
              installment
              transactionId
              approver
              issuerCode
              acquirer
              acquirerCode
              acquirerName
              refundable
              recurring
              approvalCode
              approvalDate
              approvalNumber
              signature {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              refunds {
                id
                amount
                supplyAmount
                taxFreeAmount
                tax
                taxRefundAmount
                creditTransactionId
                approvalNumber
                approvalDate
                transactionId
                origin
                originId
                currency
                reason
                createdEmployeeId
                createdDeviceId
                createdAt
              }
            }
            returns {
              amount
              currency
              items {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              refundId
              createdAt
            }
            fee
            revision
            _delta
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            customer {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
            orderCustomer {
              id
              enterpriseId
              storeId
              locationId
              customerId
              provider
              providerCustomerId
              tier
              customerCreatedAt
              ident
              identType
              type
              name
              nameKeyword
              payments {
                id
                method
                pg
                number
                name
                expMonth
                expYear
                billingKey
                updatedAt
                createdAt
              }
              attributes {
                name
                type
                value
              }
              addresses {
                id
                main
                detail
                storeId
              }
              phone
              email
              birth
              agreements {
                privacy
                provider
                eula
                marketing
                updatedAt
                createdAt
              }
              ageGroup
              gender
              tags {
                tagId
                createdLocationId
              }
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              status
              registeredAt
              salesDate
              campaignBlockedAt
              blacklistedAt
              isFavorite
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              subscriptions {
                id
                planId
                payStatus
                sessionId
                nextPayDate
                paidAt
                unsubscribedAt
                used
                lastUsedAt
                orderId
                unsubscribeRequestedAt
                startDate
                expireDate
                updatedAt
                createdAt
              }
              note
              sources {
                id
                type
                name
                ident
                createdAt
              }
              removedAt
              updatedAt
              createdAt
              _delta
              timestamp
              expdate
            }
          }
          nextToken
        }
        location {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        store {
          id
          enterpriseId
          info {
            name
            phone
            email
            representative
            businessType
            businessNumber
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            industryType
            sendNumber
            currency
            address
            zip
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            csPhone
          }
          policy {
            multiLocation
            provider {
              key
              ident
              customer
              coupon
              inventory
              locationId
            }
          }
          currency
          payments {
            card
            cardJoined
            cash
            credit
            refund
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          plans {
            menus {
              id
              name
              mode
              size
              menus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              pages {
                index
                parentId
                title
              }
              updatedAt
              createdAt
            }
            orders {
              id
              name
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              updatedAt
              createdAt
            }
          }
          taxes {
            id
            type
            name
            enabled
            rate
            method
            targetType
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          item {
            attributes {
              name
              type
              value
            }
            actualPrice
            skuMgmt
            barcodeMgmt
            stockTrack
            variablePrice
            locationCreatable
          }
          inventory {
            attributes {
              name
              type
              value
            }
            expiryDateMgmt
            expiryDateAlertDays
          }
          customer {
            attributes {
              name
              type
              value
            }
          }
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          kakao {
            agency
            key
            channelId
            categoryCode
            status
            updatedAt
            createdAt
          }
          review {
            status
            campaignId
            updatedAt
            createdAt
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mirror {
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            video {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          notification {
            options {
              type
              email
              sms
            }
          }
          groups {
            items {
              id
              name
              type
              storeGroupsId
              locationGroupsId
              key
              conditions {
                key
                object
                field
                value
                operation
                type
              }
              count
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
          location {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          locations {
            items {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            nextToken
          }
        }
        customer {
          id
          ident
          identType
          type
          name
          addresses {
            id
            main
            detail
            storeId
          }
          phone
          email
          birth
          agreements {
            privacy
            provider
            eula
            marketing
            updatedAt
            createdAt
          }
          ageGroup
          gender
          updatedAt
          createdAt
          stores {
            items {
              id
              customerId
              storeId
              sources {
                id
                type
                name
                ident
                createdAt
              }
              isFavorite
              locationId
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              favoriteLocationIds
              visitedLocationIds
              locations {
                id
                locationId
                isFavorite
                status
                note
                campaignBlockedAt
                blacklistedAt
                registeredAt
                salesDate
                updatedAt
                createdAt
              }
              status
              registeredAt
              campaignBlockedAt
              note
              removedAt
              updatedAt
              createdAt
              points {
                nextToken
              }
              credits {
                nextToken
              }
              _delta
              orders {
                nextToken
              }
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              store {
                id
                enterpriseId
                currency
                createdAt
                updatedAt
              }
              customer {
                id
                ident
                identType
                type
                name
                phone
                email
                birth
                ageGroup
                gender
                updatedAt
                createdAt
              }
            }
            nextToken
          }
        }
      }
    }
  }
`;
export const updateStoreCustomer = /* GraphQL */ `
  mutation UpdateStoreCustomer($input: UpdateStoreCustomerInput!) {
    updateStoreCustomer(input: $input) {
      id
      customerId
      storeId
      sources {
        id
        type
        name
        ident
        createdAt
      }
      isFavorite
      locationId
      point {
        used
        saved
        extra
        lastSavedAt
        lastTransactedAt
      }
      credit {
        deposit
        withdraw
        balance
        lastTransactedAt
      }
      favoriteLocationIds
      visitedLocationIds
      locations {
        id
        locationId
        isFavorite
        status
        note
        campaignBlockedAt
        blacklistedAt
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        subscriptions {
          id
          planId
          payStatus
          sessionId
          nextPayDate
          paidAt
          unsubscribedAt
          used
          lastUsedAt
          orderId
          unsubscribeRequestedAt
          startDate
          expireDate
          updatedAt
          createdAt
        }
        registeredAt
        salesDate
        updatedAt
        createdAt
      }
      status
      registeredAt
      campaignBlockedAt
      note
      removedAt
      updatedAt
      createdAt
      points {
        items {
          id
          locationId
          storeId
          type
          name
          amount
          origin
          provider
          providerCustomerId
          providerSyncedAt
          currency
          point
          extraPoint
          extraExpire
          items {
            type
            itemId
            name
            parentId
            point
            amount
            quantity
          }
          quantity
          note
          orderId
          referId
          orderNumberPrefix
          orderNumber
          customerId
          pointTransactionCustomerId
          expiredAt
          salesDate
          updatedAt
          createdAt
          customer {
            id
            customerId
            storeId
            sources {
              id
              type
              name
              ident
              createdAt
            }
            isFavorite
            locationId
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            favoriteLocationIds
            visitedLocationIds
            locations {
              id
              locationId
              isFavorite
              status
              note
              campaignBlockedAt
              blacklistedAt
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              subscriptions {
                id
                planId
                payStatus
                sessionId
                nextPayDate
                paidAt
                unsubscribedAt
                used
                lastUsedAt
                orderId
                unsubscribeRequestedAt
                startDate
                expireDate
                updatedAt
                createdAt
              }
              registeredAt
              salesDate
              updatedAt
              createdAt
            }
            status
            registeredAt
            campaignBlockedAt
            note
            removedAt
            updatedAt
            createdAt
            points {
              items {
                id
                locationId
                storeId
                type
                name
                amount
                origin
                provider
                providerCustomerId
                providerSyncedAt
                currency
                point
                extraPoint
                extraExpire
                quantity
                note
                orderId
                referId
                orderNumberPrefix
                orderNumber
                customerId
                pointTransactionCustomerId
                expiredAt
                salesDate
                updatedAt
                createdAt
              }
              nextToken
            }
            credits {
              items {
                id
                locationId
                storeId
                name
                type
                amount
                origin
                note
                currency
                parentId
                orderId
                orderNumberPrefix
                orderNumber
                customerId
                creditTransactionCustomerId
                description
                salesDate
                createdAt
                updatedAt
              }
              nextToken
            }
            _delta
            orders {
              items {
                id
                orderLocationId
                numberPrefix
                number
                beeper
                enterpriseId
                storeId
                customerId
                orderCustomerId
                state
                amount
                amountPaid
                origin
                subOrigin
                originId
                customerProvider
                providerCustomerId
                amountChanged
                amountReturned
                tax
                currency
                quantity
                shippingType
                diningOption
                diningState
                retailOption
                retailState
                name
                phone
                address
                zip
                tableId
                tableIds
                parentDeviceId
                deviceId
                reserveDate
                pickupDate
                shippingDate
                createdEmployeeId
                createdDeviceId
                updatedDeviceId
                shippingLocationId
                confirmedEmployeeId
                confirmedAt
                createdAt
                paidAt
                salesDate
                returnedAt
                shippedAt
                returnShippedAt
                returnSalesDate
                updatedAt
                noticeCount
                visitorCount
                totalPoint
                pointSaved
                receiptId
                receiptNumber
                receiptCount
                alarmCustomerId
                receiptCustomerId
                note
                request
                fee
                revision
                _delta
              }
              nextToken
            }
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            store {
              id
              enterpriseId
              info {
                name
                phone
                email
                representative
                businessType
                businessNumber
                industryType
                sendNumber
                currency
                address
                zip
                csPhone
              }
              policy {
                multiLocation
              }
              currency
              payments {
                card
                cardJoined
                cash
                credit
                refund
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              taxes {
                id
                type
                name
                enabled
                rate
                method
                targetType
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              item {
                actualPrice
                skuMgmt
                barcodeMgmt
                stockTrack
                variablePrice
                locationCreatable
              }
              inventory {
                expiryDateMgmt
                expiryDateAlertDays
              }
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              kakao {
                agency
                key
                channelId
                categoryCode
                status
                updatedAt
                createdAt
              }
              review {
                status
                campaignId
                updatedAt
                createdAt
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              groups {
                nextToken
              }
              createdAt
              updatedAt
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              locations {
                nextToken
              }
            }
            customer {
              id
              ident
              identType
              type
              name
              addresses {
                id
                main
                detail
                storeId
              }
              phone
              email
              birth
              agreements {
                privacy
                provider
                eula
                marketing
                updatedAt
                createdAt
              }
              ageGroup
              gender
              updatedAt
              createdAt
              stores {
                nextToken
              }
            }
          }
        }
        nextToken
      }
      credits {
        items {
          id
          locationId
          storeId
          name
          type
          amount
          origin
          note
          currency
          parentId
          orderId
          orderNumberPrefix
          orderNumber
          customerId
          creditTransactionCustomerId
          description
          salesDate
          createdAt
          updatedAt
          customer {
            id
            customerId
            storeId
            sources {
              id
              type
              name
              ident
              createdAt
            }
            isFavorite
            locationId
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            favoriteLocationIds
            visitedLocationIds
            locations {
              id
              locationId
              isFavorite
              status
              note
              campaignBlockedAt
              blacklistedAt
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              subscriptions {
                id
                planId
                payStatus
                sessionId
                nextPayDate
                paidAt
                unsubscribedAt
                used
                lastUsedAt
                orderId
                unsubscribeRequestedAt
                startDate
                expireDate
                updatedAt
                createdAt
              }
              registeredAt
              salesDate
              updatedAt
              createdAt
            }
            status
            registeredAt
            campaignBlockedAt
            note
            removedAt
            updatedAt
            createdAt
            points {
              items {
                id
                locationId
                storeId
                type
                name
                amount
                origin
                provider
                providerCustomerId
                providerSyncedAt
                currency
                point
                extraPoint
                extraExpire
                quantity
                note
                orderId
                referId
                orderNumberPrefix
                orderNumber
                customerId
                pointTransactionCustomerId
                expiredAt
                salesDate
                updatedAt
                createdAt
              }
              nextToken
            }
            credits {
              items {
                id
                locationId
                storeId
                name
                type
                amount
                origin
                note
                currency
                parentId
                orderId
                orderNumberPrefix
                orderNumber
                customerId
                creditTransactionCustomerId
                description
                salesDate
                createdAt
                updatedAt
              }
              nextToken
            }
            _delta
            orders {
              items {
                id
                orderLocationId
                numberPrefix
                number
                beeper
                enterpriseId
                storeId
                customerId
                orderCustomerId
                state
                amount
                amountPaid
                origin
                subOrigin
                originId
                customerProvider
                providerCustomerId
                amountChanged
                amountReturned
                tax
                currency
                quantity
                shippingType
                diningOption
                diningState
                retailOption
                retailState
                name
                phone
                address
                zip
                tableId
                tableIds
                parentDeviceId
                deviceId
                reserveDate
                pickupDate
                shippingDate
                createdEmployeeId
                createdDeviceId
                updatedDeviceId
                shippingLocationId
                confirmedEmployeeId
                confirmedAt
                createdAt
                paidAt
                salesDate
                returnedAt
                shippedAt
                returnShippedAt
                returnSalesDate
                updatedAt
                noticeCount
                visitorCount
                totalPoint
                pointSaved
                receiptId
                receiptNumber
                receiptCount
                alarmCustomerId
                receiptCustomerId
                note
                request
                fee
                revision
                _delta
              }
              nextToken
            }
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            store {
              id
              enterpriseId
              info {
                name
                phone
                email
                representative
                businessType
                businessNumber
                industryType
                sendNumber
                currency
                address
                zip
                csPhone
              }
              policy {
                multiLocation
              }
              currency
              payments {
                card
                cardJoined
                cash
                credit
                refund
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              taxes {
                id
                type
                name
                enabled
                rate
                method
                targetType
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              item {
                actualPrice
                skuMgmt
                barcodeMgmt
                stockTrack
                variablePrice
                locationCreatable
              }
              inventory {
                expiryDateMgmt
                expiryDateAlertDays
              }
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              kakao {
                agency
                key
                channelId
                categoryCode
                status
                updatedAt
                createdAt
              }
              review {
                status
                campaignId
                updatedAt
                createdAt
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              groups {
                nextToken
              }
              createdAt
              updatedAt
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              locations {
                nextToken
              }
            }
            customer {
              id
              ident
              identType
              type
              name
              addresses {
                id
                main
                detail
                storeId
              }
              phone
              email
              birth
              agreements {
                privacy
                provider
                eula
                marketing
                updatedAt
                createdAt
              }
              ageGroup
              gender
              updatedAt
              createdAt
              stores {
                nextToken
              }
            }
          }
        }
        nextToken
      }
      _delta
      orders {
        items {
          id
          orderLocationId
          numberPrefix
          number
          beeper
          enterpriseId
          storeId
          customerId
          orderCustomerId
          state
          amount
          amountPaid
          origin
          subOrigin
          originId
          customerProvider
          providerCustomerId
          amountChanged
          amountReturned
          tax
          currency
          quantity
          shippingType
          diningOption
          diningState
          retailOption
          retailState
          name
          phone
          address
          zip
          receivers {
            id
            name
            phone
            zip
            address1
            address2
            addressState
            addressCity
            addressStreet
            addressFull
            note
            shippingCompanyCode
            shippingAcceptCount
            shippingStartCount
            trackingNumber
            privacyAgreedAt
          }
          tableId
          tableIds
          parentDeviceId
          deviceId
          reserveDate
          pickupDate
          shippingDate
          items {
            id
            type
            index
            category
            categories {
              id
              name
            }
            classificationIds
            productId
            modifierId
            name
            description
            amount
            price
            parentId
            referType
            referId
            campaignId
            requestId
            quantity
            sku
            tax
            taxRate
            diningOption
            diningState
            modifiers {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            discounts {
              id
              scope
              type
              amount
              name
            }
            discountable
            discountQuantity
            discountIncludeIds
            discountExcludeIds
            discountMethod
            discountValue
            orderedQuantity
            voidQuantity
            paidQuantity
            readyQuantity
            receivedQuantity
            inventoryReason
            point
            note
            createdAt
          }
          createdEmployeeId
          createdDeviceId
          updatedDeviceId
          shippingLocationId
          confirmedEmployeeId
          confirmedAt
          createdAt
          paidAt
          salesDate
          returnedAt
          shippedAt
          returnShippedAt
          returnSalesDate
          updatedAt
          noticeCount
          visitorCount
          totalPoint
          pointSaved
          receiptId
          receiptNumber
          receiptCount
          alarmCustomerId
          receiptCustomerId
          note
          request
          charges {
            id
            method
            extraMethod
            gateway
            amount
            tax
            taxRefundAmount
            purchaser {
              name
              number
              nation
              address
            }
            supplyAmount
            taxFreeAmount
            amountRefunded
            createdEmployeeId
            createdDeviceId
            createdAt
            creditTransactionId
            balance
            splitType
            pg
            van
            origin
            originId
            note
            brand
            name
            ident
            installment
            transactionId
            approver
            issuerCode
            acquirer
            acquirerCode
            acquirerName
            refundable
            recurring
            approvalCode
            approvalDate
            approvalNumber
            signature {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            refunds {
              id
              amount
              supplyAmount
              taxFreeAmount
              tax
              taxRefundAmount
              creditTransactionId
              approvalNumber
              approvalDate
              transactionId
              origin
              originId
              currency
              reason
              signature {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              createdEmployeeId
              createdDeviceId
              createdAt
            }
          }
          returns {
            amount
            currency
            items {
              id
              type
              index
              category
              categories {
                id
                name
              }
              classificationIds
              productId
              modifierId
              name
              description
              amount
              price
              parentId
              referType
              referId
              campaignId
              requestId
              quantity
              sku
              tax
              taxRate
              diningOption
              diningState
              modifiers {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              discounts {
                id
                scope
                type
                amount
                name
              }
              discountable
              discountQuantity
              discountIncludeIds
              discountExcludeIds
              discountMethod
              discountValue
              orderedQuantity
              voidQuantity
              paidQuantity
              readyQuantity
              receivedQuantity
              inventoryReason
              point
              note
              createdAt
            }
            refundId
            createdAt
          }
          fee
          revision
          _delta
          location {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          customer {
            id
            customerId
            storeId
            sources {
              id
              type
              name
              ident
              createdAt
            }
            isFavorite
            locationId
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            favoriteLocationIds
            visitedLocationIds
            locations {
              id
              locationId
              isFavorite
              status
              note
              campaignBlockedAt
              blacklistedAt
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              subscriptions {
                id
                planId
                payStatus
                sessionId
                nextPayDate
                paidAt
                unsubscribedAt
                used
                lastUsedAt
                orderId
                unsubscribeRequestedAt
                startDate
                expireDate
                updatedAt
                createdAt
              }
              registeredAt
              salesDate
              updatedAt
              createdAt
            }
            status
            registeredAt
            campaignBlockedAt
            note
            removedAt
            updatedAt
            createdAt
            points {
              items {
                id
                locationId
                storeId
                type
                name
                amount
                origin
                provider
                providerCustomerId
                providerSyncedAt
                currency
                point
                extraPoint
                extraExpire
                quantity
                note
                orderId
                referId
                orderNumberPrefix
                orderNumber
                customerId
                pointTransactionCustomerId
                expiredAt
                salesDate
                updatedAt
                createdAt
              }
              nextToken
            }
            credits {
              items {
                id
                locationId
                storeId
                name
                type
                amount
                origin
                note
                currency
                parentId
                orderId
                orderNumberPrefix
                orderNumber
                customerId
                creditTransactionCustomerId
                description
                salesDate
                createdAt
                updatedAt
              }
              nextToken
            }
            _delta
            orders {
              items {
                id
                orderLocationId
                numberPrefix
                number
                beeper
                enterpriseId
                storeId
                customerId
                orderCustomerId
                state
                amount
                amountPaid
                origin
                subOrigin
                originId
                customerProvider
                providerCustomerId
                amountChanged
                amountReturned
                tax
                currency
                quantity
                shippingType
                diningOption
                diningState
                retailOption
                retailState
                name
                phone
                address
                zip
                tableId
                tableIds
                parentDeviceId
                deviceId
                reserveDate
                pickupDate
                shippingDate
                createdEmployeeId
                createdDeviceId
                updatedDeviceId
                shippingLocationId
                confirmedEmployeeId
                confirmedAt
                createdAt
                paidAt
                salesDate
                returnedAt
                shippedAt
                returnShippedAt
                returnSalesDate
                updatedAt
                noticeCount
                visitorCount
                totalPoint
                pointSaved
                receiptId
                receiptNumber
                receiptCount
                alarmCustomerId
                receiptCustomerId
                note
                request
                fee
                revision
                _delta
              }
              nextToken
            }
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            store {
              id
              enterpriseId
              info {
                name
                phone
                email
                representative
                businessType
                businessNumber
                industryType
                sendNumber
                currency
                address
                zip
                csPhone
              }
              policy {
                multiLocation
              }
              currency
              payments {
                card
                cardJoined
                cash
                credit
                refund
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              taxes {
                id
                type
                name
                enabled
                rate
                method
                targetType
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              item {
                actualPrice
                skuMgmt
                barcodeMgmt
                stockTrack
                variablePrice
                locationCreatable
              }
              inventory {
                expiryDateMgmt
                expiryDateAlertDays
              }
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              kakao {
                agency
                key
                channelId
                categoryCode
                status
                updatedAt
                createdAt
              }
              review {
                status
                campaignId
                updatedAt
                createdAt
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              groups {
                nextToken
              }
              createdAt
              updatedAt
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              locations {
                nextToken
              }
            }
            customer {
              id
              ident
              identType
              type
              name
              addresses {
                id
                main
                detail
                storeId
              }
              phone
              email
              birth
              agreements {
                privacy
                provider
                eula
                marketing
                updatedAt
                createdAt
              }
              ageGroup
              gender
              updatedAt
              createdAt
              stores {
                nextToken
              }
            }
          }
          orderCustomer {
            id
            enterpriseId
            storeId
            locationId
            customerId
            provider
            providerCustomerId
            tier
            customerCreatedAt
            ident
            identType
            type
            name
            nameKeyword
            payments {
              id
              method
              pg
              number
              name
              expMonth
              expYear
              billingKey
              updatedAt
              createdAt
            }
            attributes {
              name
              type
              value
            }
            addresses {
              id
              main
              detail
              storeId
            }
            phone
            email
            birth
            agreements {
              privacy
              provider
              eula
              marketing
              updatedAt
              createdAt
            }
            ageGroup
            gender
            tags {
              tagId
              createdLocationId
            }
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            status
            registeredAt
            salesDate
            campaignBlockedAt
            blacklistedAt
            isFavorite
            locations {
              id
              locationId
              isFavorite
              status
              note
              campaignBlockedAt
              blacklistedAt
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              subscriptions {
                id
                planId
                payStatus
                sessionId
                nextPayDate
                paidAt
                unsubscribedAt
                used
                lastUsedAt
                orderId
                unsubscribeRequestedAt
                startDate
                expireDate
                updatedAt
                createdAt
              }
              registeredAt
              salesDate
              updatedAt
              createdAt
            }
            subscriptions {
              id
              planId
              payStatus
              sessionId
              nextPayDate
              paidAt
              unsubscribedAt
              used
              lastUsedAt
              orderId
              unsubscribeRequestedAt
              startDate
              expireDate
              updatedAt
              createdAt
            }
            note
            sources {
              id
              type
              name
              ident
              createdAt
            }
            removedAt
            updatedAt
            createdAt
            _delta
            timestamp
            expdate
          }
        }
        nextToken
      }
      location {
        id
        name
        type
        provider
        address
        zip
        phone
        logo {
          key
          bucket
          region
          level
          url
          xsmall
          small
        }
        representative
        businessNumber
        billing {
          key
          businessNumber
          name
          email
          phone
          contanctName
          contactName
          address
          zip
          card {
            pg
            number
            name
            expMonth
            expYear
            identify
            password
            billingKey
          }
        }
        credit {
          autoCharge
          autoChargeCredit
          autoChargeQuantity
          alertCredit
        }
        businessHours {
          sun {
            open
            close
          }
          mon {
            open
            close
          }
          tue {
            open
            close
          }
          wed {
            open
            close
          }
          thu {
            open
            close
          }
          fri {
            open
            close
          }
          sat {
            open
            close
          }
        }
        storeId
        state
        menuPlanId
        payments {
          refund
          barcode
          barcodeVan
          barcodeTerminalId
        }
        policy {
          locationCost
          loyaltyCost
        }
        loyalty {
          status
          provider
          sharePolicy
          type
          grade
          conditionType
          conditionSubType
          condition {
            minAmount
            perAmount
            minTime
            rate
            expirationPeriod
            excludeProdIds
            discountPointSavable
          }
          conditionItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          conditionTiers {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardType
          rewardSubType
          reward {
            requirePoint
            requireOrderAmount
            minUsePoint
            minRequireRate
            maxUsePoint
          }
          rewardItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardRates {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardAmounts {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardCashs {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          incentives {
            fulfill
            fulfillPoint
            fulfillCampaignId
            birth
            birthPoint
            birthCampaignId
            birthItem {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
          }
          notification {
            register
            registerCampainId
            save
            saveCampaignId
            use
            useCampaignId
            expire
            expireCampaignId
          }
        }
        kiosk {
          status
          callable
          payable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          exchangeTicket
          columnSize
          discounts {
            condition
            couponId
          }
          menuDescription
          updatedAt
          createdAt
        }
        receipt {
          salesSlip
          orderList
          barcode
          footnote
          phone
          orderNumberHide
          orderSheetFontSize
          billsBusinessInfo
          giftFootnote
          giftReceipt
        }
        menuOrder {
          callOptions {
            index
            label
          }
          orderable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          columnSize
          fontSize
          notice
          resetTime
          enableCash
          boardType
        }
        kds {
          layout
          alerts {
            urgent
            warning
          }
          disabledProdIds
          orderNotify
        }
        mini {
          defaultItemName
        }
        orderPlanId
        menu {
          board {
            textSize
            columnSize
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            enableFavorite
          }
          list {
            pages {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            favorite {
              parentId
              badge
              type
              position
              activeOptions
            }
            shortcuts {
              type
              title
              itemName
              itemPrice
              active
            }
          }
          navOrder
        }
        menus {
          parentId
          position
          page
          pageTitle
        }
        favMenus {
          parentId
          position
          page
          pageTitle
        }
        catMenus {
          title
          index
          ids
          parentId
        }
        market {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          paused
          takeoutDiscount
          creditDiscount
          takeout
          intro
          notice
          bookTable
          bookTableNote
          depositAmount
          delivery
          deliveryFee {
            start
            end
            fee
          }
          waitingTime
          itemIds
          updatedAt
          createdAt
        }
        waiting {
          status
          waitingOptions {
            id
            type
            name
            nameMl {
              en
              zh
              ja
            }
            items {
              id
              name
              nameMl {
                en
                zh
                ja
              }
              limitQuantity
            }
            required
            updatedAt
            createdAt
          }
          waitingMinutes
          waitingMinutesHide
          hasForeigner
          foreignerMethod
          notifications {
            created
            ready
            requested
            cancelled
          }
          notices {
            createdNotice
            createdNoticeMl {
              en
              zh
              ja
            }
            requestedNotice
            requestedNoticeMl {
              en
              zh
              ja
            }
          }
          settings {
            autoCancel
            autoCancelMinutes
          }
          updatedAt
          createdAt
        }
        view {
          order {
            excludeColumns
            exportTemplates {
              name
              type
              headers {
                key
                label
              }
            }
          }
          itemReport {
            excludeColumns
          }
          summaryReport {
            excludeColumns
          }
          shippingOrder {
            excludeColumns
          }
          trsItemReport {
            excludeColumns
          }
        }
        subscription {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          updatedAt
          createdAt
        }
        settings {
          mode
          table
          diningOption
          retailOption
          orderAlarm
          orderConfirm
          addressInput
          autoPay
          autoReturnItem
          autoSoldout
          menuGridSize
          menuMode
          tableMode
          orderNotify
          orderNotifySkippable
          disablePhonePrefix
          autoCloseScreen
          orderNumberPrefix {
            pos
            kiosk
            menuOrder
          }
          orderMgmt {
            kiosk
            menuorder
            delivery
            pos
          }
          reportBasis
          orderOrigins
          beeper {
            active
            coverage
            skippable
          }
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
        }
        devices {
          id
          ident
          locationId
          system
          ipAddress
          brand
          model
          name
          type
          deviceType
          display {
            height
            width
          }
          protocol
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          barcodeReader
          appVersion
          sysVersion
          status
          password
          msgToken
          parentId
          childId
          sleepMode
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
          terminalId
          printOptions {
            port
            baudRate
            receipt
            receiptButtonHide
            bills
            refund
            salesEnd
            order
            orderBeep
            orderCount
            orderExcludeIds
            delivery
          }
          updatedAt
          createdAt
        }
        tables {
          id
          width
          height
          name
          axisX
          axisY
          screenHeight
          screenWidth
          initialX
          initialY
          shape
          layout {
            x
            y
            w
            h
          }
          color
          orderId
          disabled
        }
        boardTables {
          title
          index
          items {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
        }
        gridTables {
          id
          name
          orderId
          index
          page
        }
        modifierSoldoutIds
        delivery {
          baemin
          coupang
          yogiyo
          enableGenProd
          updatedAt
          createdAt
        }
        createdAt
        updatedAt
        sales {
          startedAt
          endedAt
          reserves
          actualBalance
          currency
          note
          estimateBalance
          detail {
            totalSales
            totalRefund
            totalTax
            totalDiscount
            supplyAmount
            totalSalesCount
            cash {
              sales
              refund
            }
            card {
              sales
              refund
            }
            credit {
              sales
              refund
            }
            barcode {
              sales
              refund
            }
            extra {
              sales
              refund
            }
            refund {
              sales
              refund
            }
            products {
              id
              name
              quantity
              amount
            }
            origins {
              pos
              kiosk
              menuorder
              delivery
              market
            }
          }
          reservesHistories {
            type
            amount
            note
            createdEmployeeId
            createdAt
          }
        }
      }
      store {
        id
        enterpriseId
        info {
          name
          phone
          email
          representative
          businessType
          businessNumber
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          industryType
          sendNumber
          currency
          address
          zip
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          csPhone
        }
        policy {
          multiLocation
          provider {
            key
            ident
            customer
            coupon
            inventory
            locationId
          }
        }
        currency
        payments {
          card
          cardJoined
          cash
          credit
          refund
        }
        devices {
          id
          ident
          locationId
          system
          ipAddress
          brand
          model
          name
          type
          deviceType
          display {
            height
            width
          }
          protocol
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          barcodeReader
          appVersion
          sysVersion
          status
          password
          msgToken
          parentId
          childId
          sleepMode
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
          terminalId
          printOptions {
            port
            baudRate
            receipt
            receiptButtonHide
            bills
            refund
            salesEnd
            order
            orderBeep
            orderCount
            orderExcludeIds
            delivery
          }
          updatedAt
          createdAt
        }
        plans {
          menus {
            id
            name
            mode
            size
            menus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            pages {
              index
              parentId
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              title
              titleMl {
                en
                zh
                ja
              }
            }
            updatedAt
            createdAt
          }
          orders {
            id
            name
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            updatedAt
            createdAt
          }
        }
        taxes {
          id
          type
          name
          enabled
          rate
          method
          targetType
        }
        receipt {
          salesSlip
          orderList
          barcode
          footnote
          phone
          orderNumberHide
          orderSheetFontSize
          billsBusinessInfo
          giftFootnote
          giftReceipt
        }
        subscription {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          updatedAt
          createdAt
        }
        view {
          order {
            excludeColumns
            exportTemplates {
              name
              type
              headers {
                key
                label
              }
            }
          }
          itemReport {
            excludeColumns
          }
          summaryReport {
            excludeColumns
          }
          shippingOrder {
            excludeColumns
          }
          trsItemReport {
            excludeColumns
          }
        }
        settings {
          mode
          table
          diningOption
          retailOption
          orderAlarm
          orderConfirm
          addressInput
          autoPay
          autoReturnItem
          autoSoldout
          menuGridSize
          menuMode
          tableMode
          orderNotify
          orderNotifySkippable
          disablePhonePrefix
          autoCloseScreen
          orderNumberPrefix {
            pos
            kiosk
            menuOrder
          }
          orderMgmt {
            kiosk
            menuorder
            delivery
            pos
          }
          reportBasis
          orderOrigins
          beeper {
            active
            coverage
            skippable
          }
          kioskOptions {
            mode
            isActive
            tableId
            orderId
            callable
            payable
            enableDiningOption
            resetTime
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            columnSize
          }
        }
        item {
          attributes {
            name
            type
            value
          }
          actualPrice
          skuMgmt
          barcodeMgmt
          stockTrack
          variablePrice
          locationCreatable
        }
        inventory {
          attributes {
            name
            type
            value
          }
          expiryDateMgmt
          expiryDateAlertDays
        }
        customer {
          attributes {
            name
            type
            value
          }
        }
        billing {
          key
          businessNumber
          name
          email
          phone
          contanctName
          contactName
          address
          zip
          card {
            pg
            number
            name
            expMonth
            expYear
            identify
            password
            billingKey
          }
        }
        credit {
          autoCharge
          autoChargeCredit
          autoChargeQuantity
          alertCredit
        }
        kakao {
          agency
          key
          channelId
          categoryCode
          status
          updatedAt
          createdAt
        }
        review {
          status
          campaignId
          updatedAt
          createdAt
        }
        kiosk {
          status
          callable
          payable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          exchangeTicket
          columnSize
          discounts {
            condition
            couponId
          }
          menuDescription
          updatedAt
          createdAt
        }
        menuOrder {
          callOptions {
            index
            label
          }
          orderable
          menus {
            title
            titleMl {
              en
              zh
              ja
            }
            index
            ids
            items {
              parentId
              badge
              type
              position
              activeOptions
            }
            parentId
          }
          multilingual
          displayImages {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          columnSize
          fontSize
          notice
          resetTime
          enableCash
          boardType
        }
        kds {
          layout
          alerts {
            urgent
            warning
          }
          disabledProdIds
          orderNotify
        }
        mirror {
          images {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          video {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
        }
        loyalty {
          status
          provider
          sharePolicy
          type
          grade
          conditionType
          conditionSubType
          condition {
            minAmount
            perAmount
            minTime
            rate
            expirationPeriod
            excludeProdIds
            discountPointSavable
          }
          conditionItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          conditionTiers {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardType
          rewardSubType
          reward {
            requirePoint
            requireOrderAmount
            minUsePoint
            minRequireRate
            maxUsePoint
          }
          rewardItems {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardRates {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardAmounts {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          rewardCashs {
            id
            itemId
            name
            parentId
            rate
            point
            amount
          }
          incentives {
            fulfill
            fulfillPoint
            fulfillCampaignId
            birth
            birthPoint
            birthCampaignId
            birthItem {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
          }
          notification {
            register
            registerCampainId
            save
            saveCampaignId
            use
            useCampaignId
            expire
            expireCampaignId
          }
        }
        market {
          status
          account {
            ident
            holder
            bank
            bankCode
            number
            businessNumber
          }
          paused
          takeoutDiscount
          creditDiscount
          takeout
          intro
          notice
          bookTable
          bookTableNote
          depositAmount
          delivery
          deliveryFee {
            start
            end
            fee
          }
          waitingTime
          itemIds
          updatedAt
          createdAt
        }
        notification {
          options {
            type
            email
            sms
          }
        }
        groups {
          items {
            id
            name
            type
            storeGroupsId
            locationGroupsId
            key
            conditions {
              key
              object
              field
              operations {
                operation
                type
                value
              }
              value
              operation
              type
            }
            count
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
        location {
          id
          name
          type
          provider
          address
          zip
          phone
          logo {
            key
            bucket
            region
            level
            url
            xsmall
            small
          }
          representative
          businessNumber
          billing {
            key
            businessNumber
            name
            email
            phone
            contanctName
            contactName
            address
            zip
            card {
              pg
              number
              name
              expMonth
              expYear
              identify
              password
              billingKey
            }
          }
          credit {
            autoCharge
            autoChargeCredit
            autoChargeQuantity
            alertCredit
          }
          businessHours {
            sun {
              open
              close
            }
            mon {
              open
              close
            }
            tue {
              open
              close
            }
            wed {
              open
              close
            }
            thu {
              open
              close
            }
            fri {
              open
              close
            }
            sat {
              open
              close
            }
          }
          storeId
          state
          menuPlanId
          payments {
            refund
            barcode
            barcodeVan
            barcodeTerminalId
          }
          policy {
            locationCost
            loyaltyCost
          }
          loyalty {
            status
            provider
            sharePolicy
            type
            grade
            conditionType
            conditionSubType
            condition {
              minAmount
              perAmount
              minTime
              rate
              expirationPeriod
              excludeProdIds
              discountPointSavable
            }
            conditionItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            conditionTiers {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardType
            rewardSubType
            reward {
              requirePoint
              requireOrderAmount
              minUsePoint
              minRequireRate
              maxUsePoint
            }
            rewardItems {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardRates {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardAmounts {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            rewardCashs {
              id
              itemId
              name
              parentId
              rate
              point
              amount
            }
            incentives {
              fulfill
              fulfillPoint
              fulfillCampaignId
              birth
              birthPoint
              birthCampaignId
              birthItem {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
            }
            notification {
              register
              registerCampainId
              save
              saveCampaignId
              use
              useCampaignId
              expire
              expireCampaignId
            }
          }
          kiosk {
            status
            callable
            payable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            exchangeTicket
            columnSize
            discounts {
              condition
              couponId
            }
            menuDescription
            updatedAt
            createdAt
          }
          receipt {
            salesSlip
            orderList
            barcode
            footnote
            phone
            orderNumberHide
            orderSheetFontSize
            billsBusinessInfo
            giftFootnote
            giftReceipt
          }
          menuOrder {
            callOptions {
              index
              label
            }
            orderable
            menus {
              title
              titleMl {
                en
                zh
                ja
              }
              index
              ids
              items {
                parentId
                badge
                type
                position
                activeOptions
              }
              parentId
            }
            multilingual
            displayImages {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            columnSize
            fontSize
            notice
            resetTime
            enableCash
            boardType
          }
          kds {
            layout
            alerts {
              urgent
              warning
            }
            disabledProdIds
            orderNotify
          }
          mini {
            defaultItemName
          }
          orderPlanId
          menu {
            board {
              textSize
              columnSize
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              enableFavorite
            }
            list {
              pages {
                title
                index
                ids
                parentId
              }
              favorite {
                parentId
                badge
                type
                position
                activeOptions
              }
              shortcuts {
                type
                title
                itemName
                itemPrice
                active
              }
            }
            navOrder
          }
          menus {
            parentId
            position
            page
            pageTitle
          }
          favMenus {
            parentId
            position
            page
            pageTitle
          }
          catMenus {
            title
            index
            ids
            parentId
          }
          market {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            paused
            takeoutDiscount
            creditDiscount
            takeout
            intro
            notice
            bookTable
            bookTableNote
            depositAmount
            delivery
            deliveryFee {
              start
              end
              fee
            }
            waitingTime
            itemIds
            updatedAt
            createdAt
          }
          waiting {
            status
            waitingOptions {
              id
              type
              name
              nameMl {
                en
                zh
                ja
              }
              items {
                id
                name
                limitQuantity
              }
              required
              updatedAt
              createdAt
            }
            waitingMinutes
            waitingMinutesHide
            hasForeigner
            foreignerMethod
            notifications {
              created
              ready
              requested
              cancelled
            }
            notices {
              createdNotice
              createdNoticeMl {
                en
                zh
                ja
              }
              requestedNotice
              requestedNoticeMl {
                en
                zh
                ja
              }
            }
            settings {
              autoCancel
              autoCancelMinutes
            }
            updatedAt
            createdAt
          }
          view {
            order {
              excludeColumns
              exportTemplates {
                name
                type
              }
            }
            itemReport {
              excludeColumns
            }
            summaryReport {
              excludeColumns
            }
            shippingOrder {
              excludeColumns
            }
            trsItemReport {
              excludeColumns
            }
          }
          subscription {
            status
            account {
              ident
              holder
              bank
              bankCode
              number
              businessNumber
            }
            updatedAt
            createdAt
          }
          settings {
            mode
            table
            diningOption
            retailOption
            orderAlarm
            orderConfirm
            addressInput
            autoPay
            autoReturnItem
            autoSoldout
            menuGridSize
            menuMode
            tableMode
            orderNotify
            orderNotifySkippable
            disablePhonePrefix
            autoCloseScreen
            orderNumberPrefix {
              pos
              kiosk
              menuOrder
            }
            orderMgmt {
              kiosk
              menuorder
              delivery
              pos
            }
            reportBasis
            orderOrigins
            beeper {
              active
              coverage
              skippable
            }
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
          }
          devices {
            id
            ident
            locationId
            system
            ipAddress
            brand
            model
            name
            type
            deviceType
            display {
              height
              width
            }
            protocol
            images {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            barcodeReader
            appVersion
            sysVersion
            status
            password
            msgToken
            parentId
            childId
            sleepMode
            kioskOptions {
              mode
              isActive
              tableId
              orderId
              callable
              payable
              enableDiningOption
              resetTime
              menus {
                title
                index
                ids
                parentId
              }
              columnSize
            }
            terminalId
            printOptions {
              port
              baudRate
              receipt
              receiptButtonHide
              bills
              refund
              salesEnd
              order
              orderBeep
              orderCount
              orderExcludeIds
              delivery
            }
            updatedAt
            createdAt
          }
          tables {
            id
            width
            height
            name
            axisX
            axisY
            screenHeight
            screenWidth
            initialX
            initialY
            shape
            layout {
              x
              y
              w
              h
            }
            color
            orderId
            disabled
          }
          boardTables {
            title
            index
            items {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
          }
          gridTables {
            id
            name
            orderId
            index
            page
          }
          modifierSoldoutIds
          delivery {
            baemin
            coupang
            yogiyo
            enableGenProd
            updatedAt
            createdAt
          }
          createdAt
          updatedAt
          sales {
            startedAt
            endedAt
            reserves
            actualBalance
            currency
            note
            estimateBalance
            detail {
              totalSales
              totalRefund
              totalTax
              totalDiscount
              supplyAmount
              totalSalesCount
              cash {
                sales
                refund
              }
              card {
                sales
                refund
              }
              credit {
                sales
                refund
              }
              barcode {
                sales
                refund
              }
              extra {
                sales
                refund
              }
              refund {
                sales
                refund
              }
              products {
                id
                name
                quantity
                amount
              }
              origins {
                pos
                kiosk
                menuorder
                delivery
                market
              }
            }
            reservesHistories {
              type
              amount
              note
              createdEmployeeId
              createdAt
            }
          }
        }
        locations {
          items {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          nextToken
        }
      }
      customer {
        id
        ident
        identType
        type
        name
        addresses {
          id
          main
          detail
          storeId
        }
        phone
        email
        birth
        agreements {
          privacy
          provider
          eula
          marketing
          updatedAt
          createdAt
        }
        ageGroup
        gender
        updatedAt
        createdAt
        stores {
          items {
            id
            customerId
            storeId
            sources {
              id
              type
              name
              ident
              createdAt
            }
            isFavorite
            locationId
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            favoriteLocationIds
            visitedLocationIds
            locations {
              id
              locationId
              isFavorite
              status
              note
              campaignBlockedAt
              blacklistedAt
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              subscriptions {
                id
                planId
                payStatus
                sessionId
                nextPayDate
                paidAt
                unsubscribedAt
                used
                lastUsedAt
                orderId
                unsubscribeRequestedAt
                startDate
                expireDate
                updatedAt
                createdAt
              }
              registeredAt
              salesDate
              updatedAt
              createdAt
            }
            status
            registeredAt
            campaignBlockedAt
            note
            removedAt
            updatedAt
            createdAt
            points {
              items {
                id
                locationId
                storeId
                type
                name
                amount
                origin
                provider
                providerCustomerId
                providerSyncedAt
                currency
                point
                extraPoint
                extraExpire
                quantity
                note
                orderId
                referId
                orderNumberPrefix
                orderNumber
                customerId
                pointTransactionCustomerId
                expiredAt
                salesDate
                updatedAt
                createdAt
              }
              nextToken
            }
            credits {
              items {
                id
                locationId
                storeId
                name
                type
                amount
                origin
                note
                currency
                parentId
                orderId
                orderNumberPrefix
                orderNumber
                customerId
                creditTransactionCustomerId
                description
                salesDate
                createdAt
                updatedAt
              }
              nextToken
            }
            _delta
            orders {
              items {
                id
                orderLocationId
                numberPrefix
                number
                beeper
                enterpriseId
                storeId
                customerId
                orderCustomerId
                state
                amount
                amountPaid
                origin
                subOrigin
                originId
                customerProvider
                providerCustomerId
                amountChanged
                amountReturned
                tax
                currency
                quantity
                shippingType
                diningOption
                diningState
                retailOption
                retailState
                name
                phone
                address
                zip
                tableId
                tableIds
                parentDeviceId
                deviceId
                reserveDate
                pickupDate
                shippingDate
                createdEmployeeId
                createdDeviceId
                updatedDeviceId
                shippingLocationId
                confirmedEmployeeId
                confirmedAt
                createdAt
                paidAt
                salesDate
                returnedAt
                shippedAt
                returnShippedAt
                returnSalesDate
                updatedAt
                noticeCount
                visitorCount
                totalPoint
                pointSaved
                receiptId
                receiptNumber
                receiptCount
                alarmCustomerId
                receiptCustomerId
                note
                request
                fee
                revision
                _delta
              }
              nextToken
            }
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            store {
              id
              enterpriseId
              info {
                name
                phone
                email
                representative
                businessType
                businessNumber
                industryType
                sendNumber
                currency
                address
                zip
                csPhone
              }
              policy {
                multiLocation
              }
              currency
              payments {
                card
                cardJoined
                cash
                credit
                refund
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              taxes {
                id
                type
                name
                enabled
                rate
                method
                targetType
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              item {
                actualPrice
                skuMgmt
                barcodeMgmt
                stockTrack
                variablePrice
                locationCreatable
              }
              inventory {
                expiryDateMgmt
                expiryDateAlertDays
              }
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              kakao {
                agency
                key
                channelId
                categoryCode
                status
                updatedAt
                createdAt
              }
              review {
                status
                campaignId
                updatedAt
                createdAt
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              groups {
                nextToken
              }
              createdAt
              updatedAt
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              locations {
                nextToken
              }
            }
            customer {
              id
              ident
              identType
              type
              name
              addresses {
                id
                main
                detail
                storeId
              }
              phone
              email
              birth
              agreements {
                privacy
                provider
                eula
                marketing
                updatedAt
                createdAt
              }
              ageGroup
              gender
              updatedAt
              createdAt
              stores {
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      id
      ident
      identType
      type
      name
      addresses {
        id
        main
        detail
        storeId
      }
      phone
      email
      birth
      agreements {
        privacy
        provider
        eula
        marketing
        updatedAt
        createdAt
      }
      ageGroup
      gender
      updatedAt
      createdAt
      stores {
        items {
          id
          customerId
          storeId
          sources {
            id
            type
            name
            ident
            createdAt
          }
          isFavorite
          locationId
          point {
            used
            saved
            extra
            lastSavedAt
            lastTransactedAt
          }
          credit {
            deposit
            withdraw
            balance
            lastTransactedAt
          }
          favoriteLocationIds
          visitedLocationIds
          locations {
            id
            locationId
            isFavorite
            status
            note
            campaignBlockedAt
            blacklistedAt
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            subscriptions {
              id
              planId
              payStatus
              sessionId
              nextPayDate
              paidAt
              unsubscribedAt
              used
              lastUsedAt
              orderId
              unsubscribeRequestedAt
              startDate
              expireDate
              updatedAt
              createdAt
            }
            registeredAt
            salesDate
            updatedAt
            createdAt
          }
          status
          registeredAt
          campaignBlockedAt
          note
          removedAt
          updatedAt
          createdAt
          points {
            items {
              id
              locationId
              storeId
              type
              name
              amount
              origin
              provider
              providerCustomerId
              providerSyncedAt
              currency
              point
              extraPoint
              extraExpire
              items {
                type
                itemId
                name
                parentId
                point
                amount
                quantity
              }
              quantity
              note
              orderId
              referId
              orderNumberPrefix
              orderNumber
              customerId
              pointTransactionCustomerId
              expiredAt
              salesDate
              updatedAt
              createdAt
              customer {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
            }
            nextToken
          }
          credits {
            items {
              id
              locationId
              storeId
              name
              type
              amount
              origin
              note
              currency
              parentId
              orderId
              orderNumberPrefix
              orderNumber
              customerId
              creditTransactionCustomerId
              description
              salesDate
              createdAt
              updatedAt
              customer {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
            }
            nextToken
          }
          _delta
          orders {
            items {
              id
              orderLocationId
              numberPrefix
              number
              beeper
              enterpriseId
              storeId
              customerId
              orderCustomerId
              state
              amount
              amountPaid
              origin
              subOrigin
              originId
              customerProvider
              providerCustomerId
              amountChanged
              amountReturned
              tax
              currency
              quantity
              shippingType
              diningOption
              diningState
              retailOption
              retailState
              name
              phone
              address
              zip
              receivers {
                id
                name
                phone
                zip
                address1
                address2
                addressState
                addressCity
                addressStreet
                addressFull
                note
                shippingCompanyCode
                shippingAcceptCount
                shippingStartCount
                trackingNumber
                privacyAgreedAt
              }
              tableId
              tableIds
              parentDeviceId
              deviceId
              reserveDate
              pickupDate
              shippingDate
              items {
                id
                type
                index
                category
                classificationIds
                productId
                modifierId
                name
                description
                amount
                price
                parentId
                referType
                referId
                campaignId
                requestId
                quantity
                sku
                tax
                taxRate
                diningOption
                diningState
                discountable
                discountQuantity
                discountIncludeIds
                discountExcludeIds
                discountMethod
                discountValue
                orderedQuantity
                voidQuantity
                paidQuantity
                readyQuantity
                receivedQuantity
                inventoryReason
                point
                note
                createdAt
              }
              createdEmployeeId
              createdDeviceId
              updatedDeviceId
              shippingLocationId
              confirmedEmployeeId
              confirmedAt
              createdAt
              paidAt
              salesDate
              returnedAt
              shippedAt
              returnShippedAt
              returnSalesDate
              updatedAt
              noticeCount
              visitorCount
              totalPoint
              pointSaved
              receiptId
              receiptNumber
              receiptCount
              alarmCustomerId
              receiptCustomerId
              note
              request
              charges {
                id
                method
                extraMethod
                gateway
                amount
                tax
                taxRefundAmount
                supplyAmount
                taxFreeAmount
                amountRefunded
                createdEmployeeId
                createdDeviceId
                createdAt
                creditTransactionId
                balance
                splitType
                pg
                van
                origin
                originId
                note
                brand
                name
                ident
                installment
                transactionId
                approver
                issuerCode
                acquirer
                acquirerCode
                acquirerName
                refundable
                recurring
                approvalCode
                approvalDate
                approvalNumber
              }
              returns {
                amount
                currency
                refundId
                createdAt
              }
              fee
              revision
              _delta
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              customer {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
              orderCustomer {
                id
                enterpriseId
                storeId
                locationId
                customerId
                provider
                providerCustomerId
                tier
                customerCreatedAt
                ident
                identType
                type
                name
                nameKeyword
                phone
                email
                birth
                ageGroup
                gender
                status
                registeredAt
                salesDate
                campaignBlockedAt
                blacklistedAt
                isFavorite
                note
                removedAt
                updatedAt
                createdAt
                _delta
                timestamp
                expdate
              }
            }
            nextToken
          }
          location {
            id
            name
            type
            provider
            address
            zip
            phone
            logo {
              key
              bucket
              region
              level
              url
              xsmall
              small
            }
            representative
            businessNumber
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            businessHours {
              sun {
                open
                close
              }
              mon {
                open
                close
              }
              tue {
                open
                close
              }
              wed {
                open
                close
              }
              thu {
                open
                close
              }
              fri {
                open
                close
              }
              sat {
                open
                close
              }
            }
            storeId
            state
            menuPlanId
            payments {
              refund
              barcode
              barcodeVan
              barcodeTerminalId
            }
            policy {
              locationCost
              loyaltyCost
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mini {
              defaultItemName
            }
            orderPlanId
            menu {
              board {
                textSize
                columnSize
                enableFavorite
              }
              navOrder
            }
            menus {
              parentId
              position
              page
              pageTitle
            }
            favMenus {
              parentId
              position
              page
              pageTitle
            }
            catMenus {
              title
              index
              ids
              parentId
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            waiting {
              status
              waitingOptions {
                id
                type
                name
                required
                updatedAt
                createdAt
              }
              waitingMinutes
              waitingMinutesHide
              hasForeigner
              foreignerMethod
              notifications {
                created
                ready
                requested
                cancelled
              }
              notices {
                createdNotice
                requestedNotice
              }
              settings {
                autoCancel
                autoCancelMinutes
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            tables {
              id
              width
              height
              name
              axisX
              axisY
              screenHeight
              screenWidth
              initialX
              initialY
              shape
              layout {
                x
                y
                w
                h
              }
              color
              orderId
              disabled
            }
            boardTables {
              title
              index
              items {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
            }
            gridTables {
              id
              name
              orderId
              index
              page
            }
            modifierSoldoutIds
            delivery {
              baemin
              coupang
              yogiyo
              enableGenProd
              updatedAt
              createdAt
            }
            createdAt
            updatedAt
            sales {
              startedAt
              endedAt
              reserves
              actualBalance
              currency
              note
              estimateBalance
              detail {
                totalSales
                totalRefund
                totalTax
                totalDiscount
                supplyAmount
                totalSalesCount
              }
              reservesHistories {
                type
                amount
                note
                createdEmployeeId
                createdAt
              }
            }
          }
          store {
            id
            enterpriseId
            info {
              name
              phone
              email
              representative
              businessType
              businessNumber
              industryType
              sendNumber
              currency
              address
              zip
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              csPhone
            }
            policy {
              multiLocation
              provider {
                key
                ident
                customer
                coupon
                inventory
                locationId
              }
            }
            currency
            payments {
              card
              cardJoined
              cash
              credit
              refund
            }
            devices {
              id
              ident
              locationId
              system
              ipAddress
              brand
              model
              name
              type
              deviceType
              display {
                height
                width
              }
              protocol
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              barcodeReader
              appVersion
              sysVersion
              status
              password
              msgToken
              parentId
              childId
              sleepMode
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
              terminalId
              printOptions {
                port
                baudRate
                receipt
                receiptButtonHide
                bills
                refund
                salesEnd
                order
                orderBeep
                orderCount
                orderExcludeIds
                delivery
              }
              updatedAt
              createdAt
            }
            plans {
              menus {
                id
                name
                mode
                size
                updatedAt
                createdAt
              }
              orders {
                id
                name
                updatedAt
                createdAt
              }
            }
            taxes {
              id
              type
              name
              enabled
              rate
              method
              targetType
            }
            receipt {
              salesSlip
              orderList
              barcode
              footnote
              phone
              orderNumberHide
              orderSheetFontSize
              billsBusinessInfo
              giftFootnote
              giftReceipt
            }
            subscription {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              updatedAt
              createdAt
            }
            view {
              order {
                excludeColumns
              }
              itemReport {
                excludeColumns
              }
              summaryReport {
                excludeColumns
              }
              shippingOrder {
                excludeColumns
              }
              trsItemReport {
                excludeColumns
              }
            }
            settings {
              mode
              table
              diningOption
              retailOption
              orderAlarm
              orderConfirm
              addressInput
              autoPay
              autoReturnItem
              autoSoldout
              menuGridSize
              menuMode
              tableMode
              orderNotify
              orderNotifySkippable
              disablePhonePrefix
              autoCloseScreen
              orderNumberPrefix {
                pos
                kiosk
                menuOrder
              }
              orderMgmt {
                kiosk
                menuorder
                delivery
                pos
              }
              reportBasis
              orderOrigins
              beeper {
                active
                coverage
                skippable
              }
              kioskOptions {
                mode
                isActive
                tableId
                orderId
                callable
                payable
                enableDiningOption
                resetTime
                columnSize
              }
            }
            item {
              attributes {
                name
                type
                value
              }
              actualPrice
              skuMgmt
              barcodeMgmt
              stockTrack
              variablePrice
              locationCreatable
            }
            inventory {
              attributes {
                name
                type
                value
              }
              expiryDateMgmt
              expiryDateAlertDays
            }
            customer {
              attributes {
                name
                type
                value
              }
            }
            billing {
              key
              businessNumber
              name
              email
              phone
              contanctName
              contactName
              address
              zip
              card {
                pg
                number
                name
                expMonth
                expYear
                identify
                password
                billingKey
              }
            }
            credit {
              autoCharge
              autoChargeCredit
              autoChargeQuantity
              alertCredit
            }
            kakao {
              agency
              key
              channelId
              categoryCode
              status
              updatedAt
              createdAt
            }
            review {
              status
              campaignId
              updatedAt
              createdAt
            }
            kiosk {
              status
              callable
              payable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              exchangeTicket
              columnSize
              discounts {
                condition
                couponId
              }
              menuDescription
              updatedAt
              createdAt
            }
            menuOrder {
              callOptions {
                index
                label
              }
              orderable
              menus {
                title
                index
                ids
                parentId
              }
              multilingual
              displayImages {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              columnSize
              fontSize
              notice
              resetTime
              enableCash
              boardType
            }
            kds {
              layout
              alerts {
                urgent
                warning
              }
              disabledProdIds
              orderNotify
            }
            mirror {
              images {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              video {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
            }
            loyalty {
              status
              provider
              sharePolicy
              type
              grade
              conditionType
              conditionSubType
              condition {
                minAmount
                perAmount
                minTime
                rate
                expirationPeriod
                excludeProdIds
                discountPointSavable
              }
              conditionItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              conditionTiers {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardType
              rewardSubType
              reward {
                requirePoint
                requireOrderAmount
                minUsePoint
                minRequireRate
                maxUsePoint
              }
              rewardItems {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardRates {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardAmounts {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              rewardCashs {
                id
                itemId
                name
                parentId
                rate
                point
                amount
              }
              incentives {
                fulfill
                fulfillPoint
                fulfillCampaignId
                birth
                birthPoint
                birthCampaignId
              }
              notification {
                register
                registerCampainId
                save
                saveCampaignId
                use
                useCampaignId
                expire
                expireCampaignId
              }
            }
            market {
              status
              account {
                ident
                holder
                bank
                bankCode
                number
                businessNumber
              }
              paused
              takeoutDiscount
              creditDiscount
              takeout
              intro
              notice
              bookTable
              bookTableNote
              depositAmount
              delivery
              deliveryFee {
                start
                end
                fee
              }
              waitingTime
              itemIds
              updatedAt
              createdAt
            }
            notification {
              options {
                type
                email
                sms
              }
            }
            groups {
              items {
                id
                name
                type
                storeGroupsId
                locationGroupsId
                key
                count
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            locations {
              items {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          customer {
            id
            ident
            identType
            type
            name
            addresses {
              id
              main
              detail
              storeId
            }
            phone
            email
            birth
            agreements {
              privacy
              provider
              eula
              marketing
              updatedAt
              createdAt
            }
            ageGroup
            gender
            updatedAt
            createdAt
            stores {
              items {
                id
                customerId
                storeId
                isFavorite
                locationId
                favoriteLocationIds
                visitedLocationIds
                status
                registeredAt
                campaignBlockedAt
                note
                removedAt
                updatedAt
                createdAt
                _delta
              }
              nextToken
            }
          }
        }
        nextToken
      }
    }
  }
`;
export const createStoreCustomerDelta = /* GraphQL */ `
  mutation CreateStoreCustomerDelta($input: CreateStoreCustomerDeltaInput!) {
    createStoreCustomerDelta(input: $input) {
      id
      customerId
      storeId
      locationId
      point {
        used
        saved
        extra
        lastSavedAt
        lastTransactedAt
      }
      credit {
        deposit
        withdraw
        balance
        lastTransactedAt
      }
      status
      registeredAt
      campaignBlockedAt
      isFavorite
      favoriteLocationIds
      visitedLocationIds
      locations {
        id
        locationId
        isFavorite
        status
        note
        campaignBlockedAt
        blacklistedAt
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        subscriptions {
          id
          planId
          payStatus
          sessionId
          nextPayDate
          paidAt
          unsubscribedAt
          used
          lastUsedAt
          orderId
          unsubscribeRequestedAt
          startDate
          expireDate
          updatedAt
          createdAt
        }
        registeredAt
        salesDate
        updatedAt
        createdAt
      }
      note
      sources {
        id
        type
        name
        ident
        createdAt
      }
      removedAt
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
      customer {
        id
        ident
        identType
        type
        name
        addresses {
          id
          main
          detail
          storeId
        }
        phone
        email
        birth
        agreements {
          privacy
          provider
          eula
          marketing
          updatedAt
          createdAt
        }
        ageGroup
        gender
        updatedAt
        createdAt
        stores {
          items {
            id
            customerId
            storeId
            sources {
              id
              type
              name
              ident
              createdAt
            }
            isFavorite
            locationId
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            favoriteLocationIds
            visitedLocationIds
            locations {
              id
              locationId
              isFavorite
              status
              note
              campaignBlockedAt
              blacklistedAt
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              subscriptions {
                id
                planId
                payStatus
                sessionId
                nextPayDate
                paidAt
                unsubscribedAt
                used
                lastUsedAt
                orderId
                unsubscribeRequestedAt
                startDate
                expireDate
                updatedAt
                createdAt
              }
              registeredAt
              salesDate
              updatedAt
              createdAt
            }
            status
            registeredAt
            campaignBlockedAt
            note
            removedAt
            updatedAt
            createdAt
            points {
              items {
                id
                locationId
                storeId
                type
                name
                amount
                origin
                provider
                providerCustomerId
                providerSyncedAt
                currency
                point
                extraPoint
                extraExpire
                quantity
                note
                orderId
                referId
                orderNumberPrefix
                orderNumber
                customerId
                pointTransactionCustomerId
                expiredAt
                salesDate
                updatedAt
                createdAt
              }
              nextToken
            }
            credits {
              items {
                id
                locationId
                storeId
                name
                type
                amount
                origin
                note
                currency
                parentId
                orderId
                orderNumberPrefix
                orderNumber
                customerId
                creditTransactionCustomerId
                description
                salesDate
                createdAt
                updatedAt
              }
              nextToken
            }
            _delta
            orders {
              items {
                id
                orderLocationId
                numberPrefix
                number
                beeper
                enterpriseId
                storeId
                customerId
                orderCustomerId
                state
                amount
                amountPaid
                origin
                subOrigin
                originId
                customerProvider
                providerCustomerId
                amountChanged
                amountReturned
                tax
                currency
                quantity
                shippingType
                diningOption
                diningState
                retailOption
                retailState
                name
                phone
                address
                zip
                tableId
                tableIds
                parentDeviceId
                deviceId
                reserveDate
                pickupDate
                shippingDate
                createdEmployeeId
                createdDeviceId
                updatedDeviceId
                shippingLocationId
                confirmedEmployeeId
                confirmedAt
                createdAt
                paidAt
                salesDate
                returnedAt
                shippedAt
                returnShippedAt
                returnSalesDate
                updatedAt
                noticeCount
                visitorCount
                totalPoint
                pointSaved
                receiptId
                receiptNumber
                receiptCount
                alarmCustomerId
                receiptCustomerId
                note
                request
                fee
                revision
                _delta
              }
              nextToken
            }
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            store {
              id
              enterpriseId
              info {
                name
                phone
                email
                representative
                businessType
                businessNumber
                industryType
                sendNumber
                currency
                address
                zip
                csPhone
              }
              policy {
                multiLocation
              }
              currency
              payments {
                card
                cardJoined
                cash
                credit
                refund
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              taxes {
                id
                type
                name
                enabled
                rate
                method
                targetType
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              item {
                actualPrice
                skuMgmt
                barcodeMgmt
                stockTrack
                variablePrice
                locationCreatable
              }
              inventory {
                expiryDateMgmt
                expiryDateAlertDays
              }
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              kakao {
                agency
                key
                channelId
                categoryCode
                status
                updatedAt
                createdAt
              }
              review {
                status
                campaignId
                updatedAt
                createdAt
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              groups {
                nextToken
              }
              createdAt
              updatedAt
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              locations {
                nextToken
              }
            }
            customer {
              id
              ident
              identType
              type
              name
              addresses {
                id
                main
                detail
                storeId
              }
              phone
              email
              birth
              agreements {
                privacy
                provider
                eula
                marketing
                updatedAt
                createdAt
              }
              ageGroup
              gender
              updatedAt
              createdAt
              stores {
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const updateStoreCustomerDelta = /* GraphQL */ `
  mutation UpdateStoreCustomerDelta($input: UpdateStoreCustomerDeltaInput!) {
    updateStoreCustomerDelta(input: $input) {
      id
      customerId
      storeId
      locationId
      point {
        used
        saved
        extra
        lastSavedAt
        lastTransactedAt
      }
      credit {
        deposit
        withdraw
        balance
        lastTransactedAt
      }
      status
      registeredAt
      campaignBlockedAt
      isFavorite
      favoriteLocationIds
      visitedLocationIds
      locations {
        id
        locationId
        isFavorite
        status
        note
        campaignBlockedAt
        blacklistedAt
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        subscriptions {
          id
          planId
          payStatus
          sessionId
          nextPayDate
          paidAt
          unsubscribedAt
          used
          lastUsedAt
          orderId
          unsubscribeRequestedAt
          startDate
          expireDate
          updatedAt
          createdAt
        }
        registeredAt
        salesDate
        updatedAt
        createdAt
      }
      note
      sources {
        id
        type
        name
        ident
        createdAt
      }
      removedAt
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
      customer {
        id
        ident
        identType
        type
        name
        addresses {
          id
          main
          detail
          storeId
        }
        phone
        email
        birth
        agreements {
          privacy
          provider
          eula
          marketing
          updatedAt
          createdAt
        }
        ageGroup
        gender
        updatedAt
        createdAt
        stores {
          items {
            id
            customerId
            storeId
            sources {
              id
              type
              name
              ident
              createdAt
            }
            isFavorite
            locationId
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            favoriteLocationIds
            visitedLocationIds
            locations {
              id
              locationId
              isFavorite
              status
              note
              campaignBlockedAt
              blacklistedAt
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              subscriptions {
                id
                planId
                payStatus
                sessionId
                nextPayDate
                paidAt
                unsubscribedAt
                used
                lastUsedAt
                orderId
                unsubscribeRequestedAt
                startDate
                expireDate
                updatedAt
                createdAt
              }
              registeredAt
              salesDate
              updatedAt
              createdAt
            }
            status
            registeredAt
            campaignBlockedAt
            note
            removedAt
            updatedAt
            createdAt
            points {
              items {
                id
                locationId
                storeId
                type
                name
                amount
                origin
                provider
                providerCustomerId
                providerSyncedAt
                currency
                point
                extraPoint
                extraExpire
                quantity
                note
                orderId
                referId
                orderNumberPrefix
                orderNumber
                customerId
                pointTransactionCustomerId
                expiredAt
                salesDate
                updatedAt
                createdAt
              }
              nextToken
            }
            credits {
              items {
                id
                locationId
                storeId
                name
                type
                amount
                origin
                note
                currency
                parentId
                orderId
                orderNumberPrefix
                orderNumber
                customerId
                creditTransactionCustomerId
                description
                salesDate
                createdAt
                updatedAt
              }
              nextToken
            }
            _delta
            orders {
              items {
                id
                orderLocationId
                numberPrefix
                number
                beeper
                enterpriseId
                storeId
                customerId
                orderCustomerId
                state
                amount
                amountPaid
                origin
                subOrigin
                originId
                customerProvider
                providerCustomerId
                amountChanged
                amountReturned
                tax
                currency
                quantity
                shippingType
                diningOption
                diningState
                retailOption
                retailState
                name
                phone
                address
                zip
                tableId
                tableIds
                parentDeviceId
                deviceId
                reserveDate
                pickupDate
                shippingDate
                createdEmployeeId
                createdDeviceId
                updatedDeviceId
                shippingLocationId
                confirmedEmployeeId
                confirmedAt
                createdAt
                paidAt
                salesDate
                returnedAt
                shippedAt
                returnShippedAt
                returnSalesDate
                updatedAt
                noticeCount
                visitorCount
                totalPoint
                pointSaved
                receiptId
                receiptNumber
                receiptCount
                alarmCustomerId
                receiptCustomerId
                note
                request
                fee
                revision
                _delta
              }
              nextToken
            }
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            store {
              id
              enterpriseId
              info {
                name
                phone
                email
                representative
                businessType
                businessNumber
                industryType
                sendNumber
                currency
                address
                zip
                csPhone
              }
              policy {
                multiLocation
              }
              currency
              payments {
                card
                cardJoined
                cash
                credit
                refund
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              taxes {
                id
                type
                name
                enabled
                rate
                method
                targetType
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              item {
                actualPrice
                skuMgmt
                barcodeMgmt
                stockTrack
                variablePrice
                locationCreatable
              }
              inventory {
                expiryDateMgmt
                expiryDateAlertDays
              }
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              kakao {
                agency
                key
                channelId
                categoryCode
                status
                updatedAt
                createdAt
              }
              review {
                status
                campaignId
                updatedAt
                createdAt
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              groups {
                nextToken
              }
              createdAt
              updatedAt
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              locations {
                nextToken
              }
            }
            customer {
              id
              ident
              identType
              type
              name
              addresses {
                id
                main
                detail
                storeId
              }
              phone
              email
              birth
              agreements {
                privacy
                provider
                eula
                marketing
                updatedAt
                createdAt
              }
              ageGroup
              gender
              updatedAt
              createdAt
              stores {
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteStoreCustomerDelta = /* GraphQL */ `
  mutation DeleteStoreCustomerDelta($input: DeleteStoreCustomerDeltaInput!) {
    deleteStoreCustomerDelta(input: $input) {
      id
      customerId
      storeId
      locationId
      point {
        used
        saved
        extra
        lastSavedAt
        lastTransactedAt
      }
      credit {
        deposit
        withdraw
        balance
        lastTransactedAt
      }
      status
      registeredAt
      campaignBlockedAt
      isFavorite
      favoriteLocationIds
      visitedLocationIds
      locations {
        id
        locationId
        isFavorite
        status
        note
        campaignBlockedAt
        blacklistedAt
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        subscriptions {
          id
          planId
          payStatus
          sessionId
          nextPayDate
          paidAt
          unsubscribedAt
          used
          lastUsedAt
          orderId
          unsubscribeRequestedAt
          startDate
          expireDate
          updatedAt
          createdAt
        }
        registeredAt
        salesDate
        updatedAt
        createdAt
      }
      note
      sources {
        id
        type
        name
        ident
        createdAt
      }
      removedAt
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
      customer {
        id
        ident
        identType
        type
        name
        addresses {
          id
          main
          detail
          storeId
        }
        phone
        email
        birth
        agreements {
          privacy
          provider
          eula
          marketing
          updatedAt
          createdAt
        }
        ageGroup
        gender
        updatedAt
        createdAt
        stores {
          items {
            id
            customerId
            storeId
            sources {
              id
              type
              name
              ident
              createdAt
            }
            isFavorite
            locationId
            point {
              used
              saved
              extra
              lastSavedAt
              lastTransactedAt
            }
            credit {
              deposit
              withdraw
              balance
              lastTransactedAt
            }
            favoriteLocationIds
            visitedLocationIds
            locations {
              id
              locationId
              isFavorite
              status
              note
              campaignBlockedAt
              blacklistedAt
              point {
                used
                saved
                extra
                lastSavedAt
                lastTransactedAt
              }
              credit {
                deposit
                withdraw
                balance
                lastTransactedAt
              }
              subscriptions {
                id
                planId
                payStatus
                sessionId
                nextPayDate
                paidAt
                unsubscribedAt
                used
                lastUsedAt
                orderId
                unsubscribeRequestedAt
                startDate
                expireDate
                updatedAt
                createdAt
              }
              registeredAt
              salesDate
              updatedAt
              createdAt
            }
            status
            registeredAt
            campaignBlockedAt
            note
            removedAt
            updatedAt
            createdAt
            points {
              items {
                id
                locationId
                storeId
                type
                name
                amount
                origin
                provider
                providerCustomerId
                providerSyncedAt
                currency
                point
                extraPoint
                extraExpire
                quantity
                note
                orderId
                referId
                orderNumberPrefix
                orderNumber
                customerId
                pointTransactionCustomerId
                expiredAt
                salesDate
                updatedAt
                createdAt
              }
              nextToken
            }
            credits {
              items {
                id
                locationId
                storeId
                name
                type
                amount
                origin
                note
                currency
                parentId
                orderId
                orderNumberPrefix
                orderNumber
                customerId
                creditTransactionCustomerId
                description
                salesDate
                createdAt
                updatedAt
              }
              nextToken
            }
            _delta
            orders {
              items {
                id
                orderLocationId
                numberPrefix
                number
                beeper
                enterpriseId
                storeId
                customerId
                orderCustomerId
                state
                amount
                amountPaid
                origin
                subOrigin
                originId
                customerProvider
                providerCustomerId
                amountChanged
                amountReturned
                tax
                currency
                quantity
                shippingType
                diningOption
                diningState
                retailOption
                retailState
                name
                phone
                address
                zip
                tableId
                tableIds
                parentDeviceId
                deviceId
                reserveDate
                pickupDate
                shippingDate
                createdEmployeeId
                createdDeviceId
                updatedDeviceId
                shippingLocationId
                confirmedEmployeeId
                confirmedAt
                createdAt
                paidAt
                salesDate
                returnedAt
                shippedAt
                returnShippedAt
                returnSalesDate
                updatedAt
                noticeCount
                visitorCount
                totalPoint
                pointSaved
                receiptId
                receiptNumber
                receiptCount
                alarmCustomerId
                receiptCustomerId
                note
                request
                fee
                revision
                _delta
              }
              nextToken
            }
            location {
              id
              name
              type
              provider
              address
              zip
              phone
              logo {
                key
                bucket
                region
                level
                url
                xsmall
                small
              }
              representative
              businessNumber
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              storeId
              state
              menuPlanId
              payments {
                refund
                barcode
                barcodeVan
                barcodeTerminalId
              }
              policy {
                locationCost
                loyaltyCost
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              mini {
                defaultItemName
              }
              orderPlanId
              menu {
                navOrder
              }
              menus {
                parentId
                position
                page
                pageTitle
              }
              favMenus {
                parentId
                position
                page
                pageTitle
              }
              catMenus {
                title
                index
                ids
                parentId
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              waiting {
                status
                waitingMinutes
                waitingMinutesHide
                hasForeigner
                foreignerMethod
                updatedAt
                createdAt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              tables {
                id
                width
                height
                name
                axisX
                axisY
                screenHeight
                screenWidth
                initialX
                initialY
                shape
                color
                orderId
                disabled
              }
              boardTables {
                title
                index
              }
              gridTables {
                id
                name
                orderId
                index
                page
              }
              modifierSoldoutIds
              delivery {
                baemin
                coupang
                yogiyo
                enableGenProd
                updatedAt
                createdAt
              }
              createdAt
              updatedAt
              sales {
                startedAt
                endedAt
                reserves
                actualBalance
                currency
                note
                estimateBalance
              }
            }
            store {
              id
              enterpriseId
              info {
                name
                phone
                email
                representative
                businessType
                businessNumber
                industryType
                sendNumber
                currency
                address
                zip
                csPhone
              }
              policy {
                multiLocation
              }
              currency
              payments {
                card
                cardJoined
                cash
                credit
                refund
              }
              devices {
                id
                ident
                locationId
                system
                ipAddress
                brand
                model
                name
                type
                deviceType
                protocol
                barcodeReader
                appVersion
                sysVersion
                status
                password
                msgToken
                parentId
                childId
                sleepMode
                terminalId
                updatedAt
                createdAt
              }
              taxes {
                id
                type
                name
                enabled
                rate
                method
                targetType
              }
              receipt {
                salesSlip
                orderList
                barcode
                footnote
                phone
                orderNumberHide
                orderSheetFontSize
                billsBusinessInfo
                giftFootnote
                giftReceipt
              }
              subscription {
                status
                updatedAt
                createdAt
              }
              settings {
                mode
                table
                diningOption
                retailOption
                orderAlarm
                orderConfirm
                addressInput
                autoPay
                autoReturnItem
                autoSoldout
                menuGridSize
                menuMode
                tableMode
                orderNotify
                orderNotifySkippable
                disablePhonePrefix
                autoCloseScreen
                reportBasis
                orderOrigins
              }
              item {
                actualPrice
                skuMgmt
                barcodeMgmt
                stockTrack
                variablePrice
                locationCreatable
              }
              inventory {
                expiryDateMgmt
                expiryDateAlertDays
              }
              billing {
                key
                businessNumber
                name
                email
                phone
                contanctName
                contactName
                address
                zip
              }
              credit {
                autoCharge
                autoChargeCredit
                autoChargeQuantity
                alertCredit
              }
              kakao {
                agency
                key
                channelId
                categoryCode
                status
                updatedAt
                createdAt
              }
              review {
                status
                campaignId
                updatedAt
                createdAt
              }
              kiosk {
                status
                callable
                payable
                multilingual
                exchangeTicket
                columnSize
                menuDescription
                updatedAt
                createdAt
              }
              menuOrder {
                orderable
                multilingual
                columnSize
                fontSize
                notice
                resetTime
                enableCash
                boardType
              }
              kds {
                layout
                disabledProdIds
                orderNotify
              }
              loyalty {
                status
                provider
                sharePolicy
                type
                grade
                conditionType
                conditionSubType
                rewardType
                rewardSubType
              }
              market {
                status
                paused
                takeoutDiscount
                creditDiscount
                takeout
                intro
                notice
                bookTable
                bookTableNote
                depositAmount
                delivery
                waitingTime
                itemIds
                updatedAt
                createdAt
              }
              groups {
                nextToken
              }
              createdAt
              updatedAt
              location {
                id
                name
                type
                provider
                address
                zip
                phone
                representative
                businessNumber
                storeId
                state
                menuPlanId
                orderPlanId
                modifierSoldoutIds
                createdAt
                updatedAt
              }
              locations {
                nextToken
              }
            }
            customer {
              id
              ident
              identType
              type
              name
              addresses {
                id
                main
                detail
                storeId
              }
              phone
              email
              birth
              agreements {
                privacy
                provider
                eula
                marketing
                updatedAt
                createdAt
              }
              ageGroup
              gender
              updatedAt
              createdAt
              stores {
                nextToken
              }
            }
          }
          nextToken
        }
      }
    }
  }
`;
export const createLocationCustomerDelta = /* GraphQL */ `
  mutation CreateLocationCustomerDelta(
    $input: CreateLocationCustomerDeltaInput!
  ) {
    createLocationCustomerDelta(input: $input) {
      id
      enterpriseId
      storeId
      locationId
      customerId
      provider
      providerCustomerId
      tier
      customerCreatedAt
      ident
      identType
      type
      name
      nameKeyword
      payments {
        id
        method
        pg
        number
        name
        expMonth
        expYear
        billingKey
        updatedAt
        createdAt
      }
      attributes {
        name
        type
        value
      }
      addresses {
        id
        main
        detail
        storeId
      }
      phone
      email
      birth
      agreements {
        privacy
        provider
        eula
        marketing
        updatedAt
        createdAt
      }
      ageGroup
      gender
      tags {
        tagId
        createdLocationId
      }
      point {
        used
        saved
        extra
        lastSavedAt
        lastTransactedAt
      }
      credit {
        deposit
        withdraw
        balance
        lastTransactedAt
      }
      status
      registeredAt
      salesDate
      campaignBlockedAt
      blacklistedAt
      isFavorite
      locations {
        id
        locationId
        isFavorite
        status
        note
        campaignBlockedAt
        blacklistedAt
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        subscriptions {
          id
          planId
          payStatus
          sessionId
          nextPayDate
          paidAt
          unsubscribedAt
          used
          lastUsedAt
          orderId
          unsubscribeRequestedAt
          startDate
          expireDate
          updatedAt
          createdAt
        }
        registeredAt
        salesDate
        updatedAt
        createdAt
      }
      subscriptions {
        id
        planId
        payStatus
        sessionId
        nextPayDate
        paidAt
        unsubscribedAt
        used
        lastUsedAt
        orderId
        unsubscribeRequestedAt
        startDate
        expireDate
        updatedAt
        createdAt
      }
      note
      sources {
        id
        type
        name
        ident
        createdAt
      }
      removedAt
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const updateLocationCustomerDelta = /* GraphQL */ `
  mutation UpdateLocationCustomerDelta(
    $input: UpdateLocationCustomerDeltaInput!
  ) {
    updateLocationCustomerDelta(input: $input) {
      id
      enterpriseId
      storeId
      locationId
      customerId
      provider
      providerCustomerId
      tier
      customerCreatedAt
      ident
      identType
      type
      name
      nameKeyword
      payments {
        id
        method
        pg
        number
        name
        expMonth
        expYear
        billingKey
        updatedAt
        createdAt
      }
      attributes {
        name
        type
        value
      }
      addresses {
        id
        main
        detail
        storeId
      }
      phone
      email
      birth
      agreements {
        privacy
        provider
        eula
        marketing
        updatedAt
        createdAt
      }
      ageGroup
      gender
      tags {
        tagId
        createdLocationId
      }
      point {
        used
        saved
        extra
        lastSavedAt
        lastTransactedAt
      }
      credit {
        deposit
        withdraw
        balance
        lastTransactedAt
      }
      status
      registeredAt
      salesDate
      campaignBlockedAt
      blacklistedAt
      isFavorite
      locations {
        id
        locationId
        isFavorite
        status
        note
        campaignBlockedAt
        blacklistedAt
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        subscriptions {
          id
          planId
          payStatus
          sessionId
          nextPayDate
          paidAt
          unsubscribedAt
          used
          lastUsedAt
          orderId
          unsubscribeRequestedAt
          startDate
          expireDate
          updatedAt
          createdAt
        }
        registeredAt
        salesDate
        updatedAt
        createdAt
      }
      subscriptions {
        id
        planId
        payStatus
        sessionId
        nextPayDate
        paidAt
        unsubscribedAt
        used
        lastUsedAt
        orderId
        unsubscribeRequestedAt
        startDate
        expireDate
        updatedAt
        createdAt
      }
      note
      sources {
        id
        type
        name
        ident
        createdAt
      }
      removedAt
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const deleteLocationCustomerDelta = /* GraphQL */ `
  mutation DeleteLocationCustomerDelta(
    $input: DeleteLocationCustomerDeltaInput!
  ) {
    deleteLocationCustomerDelta(input: $input) {
      id
      enterpriseId
      storeId
      locationId
      customerId
      provider
      providerCustomerId
      tier
      customerCreatedAt
      ident
      identType
      type
      name
      nameKeyword
      payments {
        id
        method
        pg
        number
        name
        expMonth
        expYear
        billingKey
        updatedAt
        createdAt
      }
      attributes {
        name
        type
        value
      }
      addresses {
        id
        main
        detail
        storeId
      }
      phone
      email
      birth
      agreements {
        privacy
        provider
        eula
        marketing
        updatedAt
        createdAt
      }
      ageGroup
      gender
      tags {
        tagId
        createdLocationId
      }
      point {
        used
        saved
        extra
        lastSavedAt
        lastTransactedAt
      }
      credit {
        deposit
        withdraw
        balance
        lastTransactedAt
      }
      status
      registeredAt
      salesDate
      campaignBlockedAt
      blacklistedAt
      isFavorite
      locations {
        id
        locationId
        isFavorite
        status
        note
        campaignBlockedAt
        blacklistedAt
        point {
          used
          saved
          extra
          lastSavedAt
          lastTransactedAt
        }
        credit {
          deposit
          withdraw
          balance
          lastTransactedAt
        }
        subscriptions {
          id
          planId
          payStatus
          sessionId
          nextPayDate
          paidAt
          unsubscribedAt
          used
          lastUsedAt
          orderId
          unsubscribeRequestedAt
          startDate
          expireDate
          updatedAt
          createdAt
        }
        registeredAt
        salesDate
        updatedAt
        createdAt
      }
      subscriptions {
        id
        planId
        payStatus
        sessionId
        nextPayDate
        paidAt
        unsubscribedAt
        used
        lastUsedAt
        orderId
        unsubscribeRequestedAt
        startDate
        expireDate
        updatedAt
        createdAt
      }
      note
      sources {
        id
        type
        name
        ident
        createdAt
      }
      removedAt
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const createAlertEventHistory = /* GraphQL */ `
  mutation CreateAlertEventHistory($input: CreateAlertEventHistoryInput!) {
    createAlertEventHistory(input: $input) {
      id
      type
      parentId
      subParentId
      storeId
      locationId
      title
      message
      updatedAt
      createdAt
    }
  }
`;
export const updateAlertEventHistory = /* GraphQL */ `
  mutation UpdateAlertEventHistory($input: UpdateAlertEventHistoryInput!) {
    updateAlertEventHistory(input: $input) {
      id
      type
      parentId
      subParentId
      storeId
      locationId
      title
      message
      updatedAt
      createdAt
    }
  }
`;
export const deleteAlertEventHistory = /* GraphQL */ `
  mutation DeleteAlertEventHistory($input: DeleteAlertEventHistoryInput!) {
    deleteAlertEventHistory(input: $input) {
      id
      type
      parentId
      subParentId
      storeId
      locationId
      title
      message
      updatedAt
      createdAt
    }
  }
`;
export const createCustomerRequestDelta = /* GraphQL */ `
  mutation CreateCustomerRequestDelta(
    $input: CreateCustomerRequestDeltaInput!
  ) {
    createCustomerRequestDelta(input: $input) {
      id
      storeId
      locationId
      messages
      orderId
      tableId
      parentDeviceId
      childDeviceId
      confirmedAt
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const updateCustomerRequestDelta = /* GraphQL */ `
  mutation UpdateCustomerRequestDelta(
    $input: UpdateCustomerRequestDeltaInput!
  ) {
    updateCustomerRequestDelta(input: $input) {
      id
      storeId
      locationId
      messages
      orderId
      tableId
      parentDeviceId
      childDeviceId
      confirmedAt
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const deleteCustomerRequestDelta = /* GraphQL */ `
  mutation DeleteCustomerRequestDelta(
    $input: DeleteCustomerRequestDeltaInput!
  ) {
    deleteCustomerRequestDelta(input: $input) {
      id
      storeId
      locationId
      messages
      orderId
      tableId
      parentDeviceId
      childDeviceId
      confirmedAt
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const createWaitingDelta = /* GraphQL */ `
  mutation CreateWaitingDelta($input: CreateWaitingDeltaInput!) {
    createWaitingDelta(input: $input) {
      id
      storeId
      locationId
      status
      cancelledAt
      enteredAt
      readyAt
      requestedAt
      language
      number
      phone
      email
      visitors
      counter
      msgFailReason
      optionItems {
        optionId
        optionName
        optionItemId
        optionItemName
        quantity
      }
      agreements {
        privacy
        provider
        eula
        marketing
        updatedAt
        createdAt
      }
      salesDate
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const updateWaitingDelta = /* GraphQL */ `
  mutation UpdateWaitingDelta($input: UpdateWaitingDeltaInput!) {
    updateWaitingDelta(input: $input) {
      id
      storeId
      locationId
      status
      cancelledAt
      enteredAt
      readyAt
      requestedAt
      language
      number
      phone
      email
      visitors
      counter
      msgFailReason
      optionItems {
        optionId
        optionName
        optionItemId
        optionItemName
        quantity
      }
      agreements {
        privacy
        provider
        eula
        marketing
        updatedAt
        createdAt
      }
      salesDate
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
export const deleteWaitingDelta = /* GraphQL */ `
  mutation DeleteWaitingDelta($input: DeleteWaitingDeltaInput!) {
    deleteWaitingDelta(input: $input) {
      id
      storeId
      locationId
      status
      cancelledAt
      enteredAt
      readyAt
      requestedAt
      language
      number
      phone
      email
      visitors
      counter
      msgFailReason
      optionItems {
        optionId
        optionName
        optionItemId
        optionItemName
        quantity
      }
      agreements {
        privacy
        provider
        eula
        marketing
        updatedAt
        createdAt
      }
      salesDate
      updatedAt
      createdAt
      _delta
      timestamp
      expdate
    }
  }
`;
