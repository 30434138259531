// Local
export const SET_SIGNUP_STATE = "SET_SIGNUP_STATE";
export const SET_NARROW_WIDTH = "SET_NARROW_WIDTH";
export const SET_DROPOUT_SUCCESS = "SET_DROPOUT_SUCCESS";
export const SET_DROPOUT_FAILURE = "SET_DROPOUT_FAILURE";
export const GET_REQUEST_PROGRESS_EVENTS = "GET_REQUEST_PROGRESS_EVENTS";
export const RECEIVE_REQUEST_PROGRESS_EVENTS =
  "RECEIVE_REQUEST_PROGRESS_EVENTS";
export const RECEIVE_REQUEST_PROGRESS_EVENT = "RECEIVE_REQUEST_PROGRESS_EVENT";
export const CLEAR_REQUEST_PROGRESS_EVENT = "CLEAR_REQUEST_PROGRESS_EVENT";
export const OPEN_CUSTOMER_INFO = "OPEN_CUSTOMER_INFO";
export const CLOSE_CUSTOMER_INFO = "CLOSE_CUSTOMER_INFO";
export const SHOW_AUTHORIZE_MODAL = "SHOW_AUTHORIZE_MODAL";
export const CLOSE_AUTHORIZE_MODAL = "CLOSE_AUTHORIZE_MODAL";
export const SHOW_MODAL = "SHOW_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const UPDATE_LOCATION_ENVIRONMENT = "UPDATE_LOCATION_ENVIRONMENT";
export const SET_ENVIRONMENT = "SET_ENVIRONMENT";

// Editing
export const SET_EDITING_LOCATION = "SET_EDITING_LOCATION";
export const SET_EDITING_PLAN = "SET_EDITING_PLAN";
export const SET_EDITING_EMPLOYEE = "SET_EDITING_EMPLOYEE";
export const SET_EDITING_NOTICE = "SET_EDITING_NOTICE";

// Employee
export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const UPDATE_ORGANIZATION_EMPLOYEE = "UPDATE_ORGANIZATION_EMPLOYEE";
export const DELETE_ORGANIZATION_EMPLOYEE = "DELETE_ORGANIZATION_EMPLOYEE";
export const SET_CURRENT_EMPLOYEE = "SET_CURRENT_EMPLOYEE";
export const SET_CURRENT_ORGANIZATION = "SET_CURRENT_ORGANIZATION";
export const SET_EMPLOYEE_STATUS_SUCCESS = "SET_EMPLOYEE_STATUS_SUCCESS";
export const SET_EMPLOYEE_STATUS_FAILURE = "SET_EMPLOYEE_STATUS_FAILURE";
export const GET_ORGNIZATIONS = "GET_ORGNIZATIONS";
export const RECEIVE_ORGANIZATIONS = "RECEIVE_ORGANIZATIONS";
export const RECEIVE_EMPLOYEE = "RECEIVE_EMPLOYEE";
export const RECEIVE_EMPLOYEES = "RECEIVE_EMPLOYEES";

// Store
export const INIT_STORE = "INIT_STORE";
export const GENERATE_STORE = "GENERATE_STORE";
export const GET_STORE_REQUEST = "GET_STORE_REQUEST";
export const GET_STORE_SUCCESS = "GET_STORE_SUCCESS";
export const GET_STORE_FAILURE = "GET_STORE_FAILURE";
export const GET_STORE_DEVICES = "GET_STORE_DEVICES";
export const RECEIVE_STORE_DEVICES = "RECEIVE_STORE_DEVICES";
export const UPDATE_STORE_REQUEST = "UPDATE_STORE_REQUEST";
export const REGISTER_OR_UPDATE_ACCOUNT = "REGISTER_OR_UPDATE_ACCOUNT";
export const UPDATE_STORE_LOYALTY_REQUEST = "UPDATE_STORE_LOYALTY_REQUEST";
export const UPDATE_STORE_SUCCESS = "UPDATE_STORE_SUCCESS";
export const UPDATE_STORE_FAILURE = "UPDATE_STORE_FAILURE";
export const GET_BILLING_COST_REQUEST = "GET_BILLING_COST_REQUEST";
export const GET_BILLING_COST_SUCCESS = "GET_BILLING_COST_SUCCESS";
export const GET_BILLING_COST_FAILURE = "GET_BILLING_COST_FAILURE";
export const RECEIVE_BILLING_COST = "RECEIVE_BILLING_COST";
export const RECEIVE_STORE_STATUS = "RECEIVE_STORE_STATUS";
export const RECEIVE_RECEIPT_STORE = "RECEIVE_RECEIPT_STORE";
export const CLEAR_RECEIPT_STORE = "CLEAR_RECEIPT_STORE";
export const UPDATE_MIRROR_CONFIG = "UPDATE_MIRROR_CONFIG";

export const INIT_BILLING_HISTORIES = "INIT_BILLING_HISTORIES";
export const RECEIVE_BILLING_HISTORY = "RECEIVE_BILLING_HISTORY";
export const GET_BILLING_HISTORIES_REQUEST = "GET_BILLING_HISTORIES_REQUEST";
export const GET_BILLING_HISTORIES_SUCCESS = "GET_BILLING_HISTORIES_SUCCESS";
export const GET_BILLING_HISTORIES_FAILURE = "GET_BILLING_HISTORIES_FAILURE";

export const PAY_FAILED_BILLINGS = "PAY_FAILED_BILLINGS";

export const LAUNCH_MULTI_LOCATION = "LAUNCH_MULTI_LOCATION";
export const CREATE_OR_UPDATE_MENU_PLAN = "CREATE_OR_UPDATE_MENU_PLAN";
export const CREATE_OR_UPDATE_ORDER_PLAN = "CREATE_OR_UPDATE_ORDER_PLAN";
export const DELETE_MENU_PLAN = "DELETE_MENU_PLAN";
export const DELETE_ORDER_PLAN = "DELETE_ORDER_PLAN";

export const INVITE_EMPLOYEE = "INVITE_EMPLOYEE";
export const CONFIRM_INVITE_EMPLOYEE = "CONFIRM_INVITE_EMPLOYEE";

export const GET_POINT_TRANSACTIONS = "GET_POINT_TRANSACTIONS";
export const RECEIVE_POINT_TRANSACTIONS = "RECEIVE_POINT_TRANSACTIONS";
export const GET_POINT_TRANSACTIONS_SUCCESS = "GET_POINT_TRANSACTIONS_SUCCESS";
export const GET_POINT_TRANSACTIONS_FAILURE = "GET_POINT_TRANSACTIONS_FAILURE";

export const ACTIVE_KIOSK_MODE = "ACTIVE_KIOSK_MODE";
export const INACTIVE_KIOSK_MODE = "INACTIVE_KIOSK_MODE";
export const UPDATE_RECEIPT_CONFIG = "UPDATE_RECEIPT_CONFIG";
export const UPDATE_VIEW_CONFIG = "UPDATE_VIEW_CONFIG";

export const RECEIVE_STORE_CONTRACT = "RECEIVE_STORE_CONTRACT";

export const GET_LABEL_TEMPLATES = "GET_LABEL_TEMPLATES";
export const CREATE_OR_UPDATE_LABEL_TEMPLATE =
  "CREATE_OR_UPDATE_LABEL_TEMPLATE";
export const DELETE_LABEL_TEMPLATE = "DELETE_LABEL_TEMPLATE";
export const RECEIVE_LABEL_TEMPLATE = "RECEIVE_LABEL_TEMPLATE";
export const RECEIVE_LABEL_TEMPLATES = "RECEIVE_LABEL_TEMPLATES";
export const RECEIVE_DELETED_LABEL_TEMPLATE = "RECEIVE_DELETED_LABEL_TEMPLATE";
export const SET_TEMPLATE_STATUS_SUCCESS = "SET_TEMPLATE_STATUS_SUCCESS";
export const SET_TEMPLATE_STATUS_FAILURE = "SET_TEMPLATE_STATUS_FAILURE";
export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const CONVERT_POS_MODE = "CONVERT_POS_MODE";
export const SYNC_EXTERNAL_LOYALTY = "SYNC_EXTERNAL_LOYALTY";
export const CHARGE_CREDIT = "CHARGE_CREDIT";
export const GET_CREDIT_STATUS = "GET_CREDIT_STATUS";
export const RECEIVE_CREDIT_STATUS = "RECEIVE_CREDIT_STATUS";
export const UPDATE_CREDIT_CONFIG = "UPDATE_CREDIT_CONFIG";
export const GET_CREDIT_HISTORIES = "GET_CREDIT_HISTORIES";
export const RECEIVE_CREDIT_HISTORIES = "RECEIVE_CREDIT_HISTORIES";
export const CLEAR_CREDIT_HISTORIES = "CLEAR_CREDIT_HISTORIES";

// Location
export const CREATE_OR_UPDATE_LOCATION = "CREATE_OR_UPDATE_LOCATION";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const RECEIVE_LOCATION = "RECEIVE_LOCATION";
export const SYNC_MENU_BOARD = "SYNC__MENU_BOARD";
export const SYNC_MENU_BOARD_SUCCESS = "SYNC_MENU_BOARD_SUCCESS";
export const SYNC_MENU_BOARD_FAILURE = "SYNC_MENU_BOARD_FAILURE";

// Campaign
export const GET_CAMPAIGNS = "GET_CAMPAIGNS";
export const GET_CAMPAIGNS_SUCCESS = "GET_CAMPAIGNS_SUCCESS";
export const GET_CAMPAIGNS_FAILURE = "GET_CAMPAIGNS_FAILURE";
export const RECEIVE_CAMPAIGNS = "RECEIVE_CAMPAIGNS";

export const RECEIVE_CAMPAIGN = "RECEIVE_CAMPAIGN";
export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN";
export const CREATE_OR_UPDATE_CAMPAIGN_SUCCESS =
  "CREATE_OR_UPDATE_CAMPAIGN_SUCCESS";
export const CREATE_OR_UPDATE_CAMPAIGN_FAILURE =
  "CREATE_OR_UPDATE_CAMPAIGN_FAILURE";

export const GET_CAMPAIGN = "GET_CAMPAIGN";
export const GET_CAMPAIGN_WITH_REPORT = "GET_CAMPAIGN_WITH_REPORT";
export const GET_CAMPAIGN_SUCCESS = "GET_CAMPAIGN_SUCCESS";
export const GET_CAMPAIGN_FAILURE = "GET_CAMPAIGN_FAILURE";

export const CANCEL_CAMPAIGN = "CANCEL_CAMPAIGN";
export const CANCEL_CAMPAIGN_SUCCESS = "CANCEL_CAMPAIGN_SUCCESS";
export const CANCEL_CAMPAIGN_FAILURE = "CANCEL_CAMPAIGN_FAILURE";

export const LAUNCH_CAMPAIGN = "LAUNCH_CAMPAIGN";
export const LAUNCH_CAMPAIGN_SUCCESS = "LAUNCH_CAMPAIGN_SUCCESS";
export const LAUNCH_CAMPAIGN_FAILURE = "LAUNCH_CAMPAIGN_FAILURE";
export const SEND_BARCODE_COUPON_CAMPAIGN = "SEND_BARCODE_COUPON_CAMPAIGN";

export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN";
export const DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS";
export const DELETE_CAMPAIGN_FAILURE = "DELETE_CAMPAIGN_FAILURE";
export const RECEIVE_DELETED_CAMPAIGN = "RECEIVE_DELETED_CAMPAIGN";
export const GET_EXPECTED_RECIPIENT_NUMBER = "GET_EXPECTED_RECIPIENT_NUMBER";
export const SET_EXPECTED_RECIPIENT_NUMBER = "SET_EXPECTED_RECIPIENT_NUMBER";

// Review Campagin
export const ACTIVE_REVIEW_CAMPAIGN = "ACTIVE_REVIEW_CAMPAIGN";
export const INACTIVE_REVIEW_CAMPAIGN = "INACTIVE_REVIEW_CAMPAIGN";
export const CREATE_OR_UPDATE_REVIEW_CAMPAIGN =
  "CREATE_OR_UPDATE_REVIEW_CAMPAIGN";
export const DELETE_REVIEW_CAMPAIGN = "DELETE_REVIEW_CAMPAIGN";
export const LAUNCH_REVIEW_CAMPAIGN = "LAUNCH_REVIEW_CAMPAIGN";
export const GET_REVIEW_CAMPAIGNS = "GET_REVIEW_CAMPAIGNS";
export const SET_REVIEW_CAMPAIGN_SUCCESS = "SET_REVIEW_CAMPAIGN_SUCCESS";
export const SET_REVIEW_CAMPAIGN_FAILURE = "SET_REVIEW_CAMPAIGN_FAILURE";

export const RECEIVE_REVIEW_CAMPAIGN = "RECEIVE_REVIEW_CAMPAIGN";
export const RECEIVE_REVIEW_CAMPAIGNS = "RECEIVE_REVIEW_CAMPAIGNS";
export const RECEIVE_DELETED_REVIEW_CAMPAIGN =
  "RECEIVE_DELETED_REVIEW_CAMPAIGN";
export const GET_REVIEW_CAMPAIGN_RESULTS = "GET_REVIEW_CAMPAIGN_RESULTS";
export const RECEIVE_REVIEW_CAMPAIGN_RESULTS =
  "RECEIVE_REVIEW_CAMPAIGN_RESULTS";
export const DOWNLOAD_REVIEW_CAMPAIGN = "DOWNLOAD_REVIEW_CAMPAIGN";

// Coupon
export const GET_COUPON = "GET_COUPON";
export const GET_BARCODE_COUPON = "GET_BARCODE_COUPON";
export const GET_BARCODE_COUPONS = "GET_BARCODE_COUPONS";
export const GET_BARCODE_COUPOON_TRANSACTION_BY_PHONE =
  "GET_BARCODE_COUPOON_TRANSACTION_BY_PHONE";
export const ISSUE_BARCODE_COUPON_FOR_PROVIDER_CUSTOMER =
  "ISSUE_BARCODE_COUPON_FOR_PROVIDER_CUSTOMER";
export const ISSUE_BARCODE_COUPON_WITH_STORE_CUSTOMER_ID =
  "ISSUE_BARCODE_COUPON_WITH_STORE_CUSTOMER_ID";
export const GET_COUPONS = "GET_COUPONS";
export const GET_COUPONS_FOR_PUBLISH = "GET_COUPONS_FOR_PUBLISH";
export const CREATE_COUPON = "CREATE_COUPON";
export const UPDATE_COUPON = "UPDATE_COUPON";
export const DELETE_COUPON = "DELETE_COUPON";
export const DELETE_COUPONS = "DELETE_COUPONS";
export const SET_COUPON_STATUS_SUCCESS = "SET_COUPON_STATUS_SUCCESS";
export const SET_COUPON_STATUS_FAILURE = "SET_COUPON_STATUS_FAILURE";
export const UPDATE_STORE_MENUPLAN = "UPDATE_STORE_MENUPLAN";
export const RECEIVE_COUPONS = "RECEIVE_COUPONS";
export const RECEIVE_COUPON = "RECEIVE_COUPON";
export const RECEIVE_BARCODE_COUPONS = "RECEIVE_BARCODE_COUPONS";
export const RECEIVE_DELETED_COUPON = "RECEIVE_DELETED_COUPON";
export const CREATE_COUPON_TRANSACTIONS = "CREATE_COUPON_TRANSACTIONS";
export const RECEIVE_BARCODE_COUPON_TRANSACTION =
  "RECEIVE_BARCODE_COUPON_TRANSACTION";
export const CLEAR_BARCODE_COUPON_TRANSACTION =
  "CLEAR_BARCODE_COUPON_TRANSACTION";
export const RECEIVE_BARCODE_COUPON_TRANSACTIONS =
  "RECEIVE_BARCODE_COUPON_TRANSACTIONS";
export const DOWNLOAD_CSV_FOR_TRANSACTIONS = "DOWNLOAD_CSV_FOR_TRANSACTIONS";
export const GET_BARCODE_COUPON_TRANSACTION = "GET_BARCODE_COUPON_TRANSACTION";
export const GET_PROVIDER_COUPONS = "GET_PROVIDER_COUPONS";
export const RECEIVE_PROVIDER_COUPONS = "RECEIVE_PROVIDER_COUPONS";
export const RECEIVE_PROVIDER_COUPON = "RECEIVE_PROVIDER_COUPON";

// item
export const GET_ITEMS = "GET_ITEMS";
export const GET_PRODUCT = "GET_PRODUCT";
export const RECEIVE_ITEMS = "RECEIVE_ITEMS";
export const RECEIVE_ITEM = "RECEIVE_ITEM";
export const GET_ITEMS_SUCCESS = "GET_ITEMS_SUCCESS";
export const GET_ITEMS_FAILURE = "GET_ITEMS_FAILURE";

export const CREATE_OR_UPDATE_PRODUCT = "CREATE_OR_UPDATE_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const CREATE_OR_UPDATE_CATEGORY = "CREATE_OR_UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const CREATE_OR_UPDATE_MODIFIER = "CREATE_OR_UPDATE_MODIFIER";
export const DELETE_MODIFIER = "DELETE_MODIFIER";
export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";
export const DOWNLOAD_INVENTORY_HISTORIES = "DOWNLOAD_INVENTORY_HISTORIES";
export const GET_INVENTORY_HISTORIES_BY_STORE =
  "GET_INVENTORY_HISTORIES_BY_STORE";
export const GET_INVENTORY_HISTORIES_BY_LOCATION =
  "GET_INVENTORY_HISTORIES_BY_LOCATION";
export const GET_INVENTORY_HISTORIES_BY_GROUP_ID =
  "GET_INVENTORY_HISTORIES_BY_GROUP_ID";
export const GET_SKU_INVENTORY_HISTORIES = "GET_SKU_INVENTORY_HISTORIES";
export const GET_INVENTORY_HISTORY_SUMMARY = "GET_INVENTORY_HISTORY_SUMMARY";
export const SET_INVENTORY_STATUS_SUCCESS = "SET_INVENTORY_STATUS_SUCCESS";
export const SET_INVENTORY_STATUS_FAILURE = "SET_INVENTORY_STATUS_FAILURE";
export const RECEIVE_INVENTORY_HISTORIES = "RECEIVE_INVENTORY_HISTORIES";
export const RECEIVE_INVENTORY_HISTORY = "RECEIVE_INVENTORY_HISTORY";
export const RECEIVE_SKU_INVENTORY_HISTORIES =
  "RECEIVE_SKU_INVENTORY_HISTORIES";
export const RECEIVE_INVENTORY_HISTORY_SUMMARY =
  "RECEIVE_INVENTORY_HISTORY_SUMMARY";
export const CLEAR_INVENTORY_HISTORIES = "CLEAR_INVENTORY_HISTORIES";
export const CLEAR_INVENTORY_HISTORY_SUMMARY =
  "CLEAR_INVENTORY_HISTORY_SUMMARY";
export const RECEIVE_ITEM_INVENTORY_HISTORIES =
  "RECEIVE_ITEM_INVENTORY_HISTORIES";
export const UPDATE_ITEMS_SUCCESS = "UPDATE_ITEMS_SUCCESS";
export const UPDATE_ITEMS_FAILURE = "UPDATE_ITEMS_FAILURE";
export const ADJUST_INVENTORIES = "ADJUST_INVENTORIES";
export const UPDATE_INVENTORY_HISTORY = "UPDATE_INVENTORY_HISTORY";
export const REFRESH_INVENTORY_HISTORY = "REFRESH_INVENTORY_HISTORY";
export const UPDATE_SKU_LOCATIONS = "UPDATE_SKU_LOCATIONS";
export const CREATE_INVENTORY_TRANSFER_HISTORY =
  "CREATE_INVENTORY_TRANSFER_HISTORY";
export const UPDATE_INVENTORY_TRANSFER_HISTORY =
  "UPDATE_INVENTORY_TRANSFER_HISTORY";
export const DELETE_INVENTORY_TRANSFER_HISTORY =
  "DELETE_INVENTORY_TRANSFER_HISTORY";
export const COMPLETE_INVENTORY_TRANSFER_HISTORY =
  "COMPLETE_INVENTORY_TRANSFER_HISTORY";
export const RECEIVE_INVENTORY_TRANSFER_HISTORY =
  "RECEIVE_INVENTORY_TRANSFER_HISTORY";
export const RECEIVE_INVENTORY_TRANSFER_HISTORIES =
  "RECEIVE_INVENTORY_TRANSFER_HISTORIES";
export const RECEIVE_DELETED_TRANSFER_HISTORY =
  "RECEIVE_DELETED_TRANSFER_HISTORY";
export const GET_INVENTORY_TRANSFER_HISTORIES =
  "GET_INVENTORY_TRANSFER_HISTORIES";
export const GET_INVENTORY_COUNT_HISTORIES = "GET_INVENTORY_COUNT_HISTORIES";
export const RECEIVE_COUNT_HISTORIES = "RECEIVE_COUNT_HISTORIES";

export const CREATE_SKU_INVENTORY = "CREATE_SKU_INVENTORY";
export const UPDATE_SKU_INVENTORY = "UPDATE_SKU_INVENTORY";
export const DELETE_SKU_INVENTORY = "DELETE_SKU_INVENTORY";

export const GENERATE_INVENTORY_TEMPLATE = "GENERATE_INVENTORY_TEMPLATE";
export const UPLOAD_INVENTORY_TEMPLATE = "UPLOAD_INVENTORY_TEMPLATE";
export const SYNC_INVENTORY_TEMPLATE = "SYNC_INVENTORY_TEMPLATE";
export const UPLOAD_ITEM_TEMPLATE = "UPLOAD_ITEM_TEMPLATE";
export const SYNC_ITEM_TEMPLATE = "SYNC_ITEM_TEMPLATE";
export const INIT_GENERATE_INVENTORY_TEMPLATE =
  "INIT_GENERATE_INVENTORY_TEMPLATE";
export const PARSE_ITEM_TEMPLATE_SUCCESS = "PARSE_ITEM_TEMPLATE_SUCCESS";
export const SYNC_ITEM_TEMPLATE_SUCCESS = "SYNC_ITEM_TEMPLATE_SUCCESS";
export const GENERATE_ITEM_TEMPLATE_SUCCESS = "GENERATE_ITEM_TEMPLATE_SUCCESS";
export const PARSE_ITEM_TEMPLATE_FAILURE = "PARSE_ITEM_TEMPLATE_FAILURE";
export const SYNC_ITEM_TEMPLATE_FAILURE = "SYNC_ITEM_TEMPLATE_FAILURE";
export const GENERATE_ITEM_TEMPLATE_FAILURE = "GENERATE_ITEM_TEMPLATE_FAILURE";
export const RESET_ERROR = "RESET_ERROR";
export const UPDATE_ACTIVE_CHANGED_PRODUCTS = "UPDATE_ACTIVE_CHANGED_PRODUCTS";
export const SHARE_HISTORY_PDF = "SHARE_HISTORY_PDF";
export const SHARE_GROUP_HISTORY_PDF = "SHARE_GROUP_HISTORY_PDF";
export const SHARE_PURCHASE_ORDER_PDF = "SHARE_PURCHASE_ORDER_PDF";
export const SET_SHARE_PDF_SUCCESS = "SET_SHARE_PDF_SUCCESS";
export const SET_SHARE_PDF_FAILURE = "SET_SHARE_PDF_FAILURE";

// Customer
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_PROVIDER_CUSTOMER = "GET_PROVIDER_CUSTOMER";
export const GET_CUSTOMERS_BY_TAG = "GET_CUSTOMERS_BY_TAG";
export const CLEAR_CUSTOMERS_BY_TAG = "CLEAR_CUSTOMERS_BY_TAG";
export const GET_CUSTOMERS_STATS = "GET_CUSTOMERS_STATS";
export const GET_CORPORATE_CUSTOMERS = "GET_CORPORATE_CUSTOMERS";
export const GET_REGISTERED_CUSTOMER_COUNT = "GET_REGISTERED_CUSTOMER_COUNT";
export const RECEIVE_CUSTOMERS = "RECEIVE_CUSTOMERS";
export const CLEAR_CUSTOMERS = "CLEAR_CUSTOMERS";
export const RECEIVE_CUSTOMERS_BY_TAG = "RECEIVE_CUSTOMERS_BY_TAG";
export const SEARCH_PROVIDER_CUSTOMER = "SEARCH_PROVIDER_CUSTOMER";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_FAILURE = "GET_CUSTOMERS_FAILURE";

export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS";
export const CREATE_CUSTOMER_FAILURE = "CREATE_CUSTOMER_FAILURE";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_FAILURE = "UPDATE_CUSTOMER_FAILURE";

export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_FAILURE = "DELETE_CUSTOMER_FAILURE";
export const RECEIVE_DELETED_CUSTOMER = "RECEIVE_DELETED_CUSTOMER";

export const GET_CUSTOMERS_STATS_SUCCESS = "GET_CUSTOMERS_STATS_SUCCESS";
export const GET_CUSTOMERS_STATS_FAILURE = "GET_CUSTOMERS_STATS_FAILURE";
export const GET_REGISTERED_CUSTOMER_COUNT_SUCCESS =
  "GET_REGISTERED_CUSTOMER_COUNT_SUCCESS";
export const GET_REGISTERED_CUSTOMER_COUNT_FAILURE =
  "GET_REGISTERED_CUSTOMER_COUNT_FAILURE";

export const GET_CUSTOMER = "GET_CUSTOMER";
export const RECEIVE_CUSTOMER = "RECEIVE_CUSTOMER";
export const RETRIEVE_CUSTOMER = "RETRIEVE_CUSTOMER";
export const RETRIEVE_CUSTOMER_WITH_POINT = "RETRIEVE_CUSTOMER_WITH_POINT";
export const RETRIEVE_CUSTOMER_WITH_ORDER = "RETRIEVE_CUSTOMER_WITH_ORDER";
export const RETRIEVE_CUSTOMER_WITH_CREDIT = "RETRIEVE_CUSTOMER_WITH_CREDIT";
export const RETRIEVE_CUSTOMER_WITH_COUPON = "RETRIEVE_CUSTOMER_WITH_COUPON";
export const RECEIVE_RETRIEVED_CUSTOMER = "RECEIVE_RETRIEVED_CUSTOMER";
export const CLEAR_RETRIEVED_CUSTOMER = "CLEAR_RETRIEVED_CUSTOMER";
export const RECEIVE_PROVIDER_CUSTOMER = "RECEIVE_PROVIDER_CUSTOMER";
export const RECEIVE_PROVIDER_CUSTOMERS = "RECEIVE_PROVIDER_CUSTOMERS";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";
export const GET_CUSTOMER_FAILURE = "GET_CUSTOMER_FAILURE";

export const GET_CUSTOMER_GROUPS = "GET_CUSTOMER_GROUPS";
export const GET_CUSTOMER_GROUPS_WITH_COUNT = "GET_CUSTOMER_GROUPS_WITH_COUNT";
export const RECEIVE_CUSTOMER_GROUPS = "RECEIVE_CUSTOMER_GROUPS";
export const GET_CUSTOMER_GROUPS_SUCCESS = "GET_CUSTOMER_GROUPS_SUCCESS";
export const GET_CUSTOMER_GROUPS_FAILURE = "GET_CUSTOMER_GROUPS_FAILURE";

export const RECIEVE_CUSTOMERS_STATS = "RECIEVE_CUSTOMERS_STATS";

export const CREATE_OR_UPDATE_CUSTOMER_GROUP =
  "CREATE_OR_UPDATE_CUSTOMER_GROUP ";
export const DELETE_CUSTOMER_GROUP = "DELETE_CUSTOMER_GROUP";

export const CREATE_CUSTOMER_PAYMENT = "CREATE_CUSTOMER_PAYMENT";
export const DELETE_CUSTOMER_PAYMENT = "DELETE_CUSTOMER_PAYMENT";

export const SIGN_OUT = "SIGN_OUT";
export const DROPOUT_ACCOUNT = "DROPOUT_ACCOUNT";

// Tag
export const CREATE_OR_UPDATE_CUSTOMER_TAG = "CREATE_OR_UPDATE_CUSTOMER_TAG";
export const UPDATE_CUSTOMER_TAGS = "UPDATE_CUSTOMER_TAGS";
export const DELETE_CUSTOMER_TAG = "DELETE_CUSTOMER_TAG";
export const CREATE_OR_UPDATE_STORE_ATTRIBUTES =
  "CREATE_OR_UPDATE_STORE_ATTRIBUTES";
export const GET_CUSTOMER_TAGS = "GET_CUSTOMER_TAGS";
export const GET_CUSTOMER_TAGS_WITH_COUNT = "GET_CUSTOMER_TAGS_WITH_COUNT";
export const RECEIVE_CUSTOMER_TAG = "RECEIVE_CUSTOMER_TAG";
export const RECEIVE_CUSTOMER_TAGS = "RECEIVE_CUSTOMER_TAGS";
export const RECEIVE_DELETED_CUSTOMER_TAG = "RECEIVE_DELETED_CUSTOMER_TAG";
export const SET_CUSTOMER_TAG_STATUS_SUCCESS =
  "SET_CUSTOMER_TAG_STATUS_SUCCESS";
export const SET_CUSTOMER_TAG_STATUS_FAILURE =
  "SET_CUSTOMER_TAG_STATUS_FAILURE";

// Report
export const GET_SALES_REPORT = "GET_SALES_REPORT";
export const RECEIVE_SALES_REPORT = "RECEIVE_SALES_REPORT";
export const GET_SALES_REPORT_SUCCESS = "GET_SALES_REPORT_SUCCESS";
export const GET_SALES_REPORT_FAILURE = "GET_SALES_REPORT_FAILURE";

export const GET_ITEMS_REPORT = "GET_ITEMS_REPORT";
export const RECEIVE_ITEMS_REPORT = "RECEIVE_ITEMS_REPORT";
export const SET_ITEMS_REPORT_SUCCESS = "SET_ITEMS_REPORT_SUCCESS";
export const SET_ITEMS_REPORT_FAILURE = "SET_ITEMS_REPORT_FAILURE";

export const GET_SALES_HISTORIES = "GET_SALES_HISTORIES";
export const RECEIVE_SALES_HISTORIES = "RECEIVE_SALES_HISTORIES";
export const GET_SALES_HISTORIES_SUCCESS = "GET_SALES_HISTORIES_SUCCESS";
export const GET_SALES_HISTORIES_FAILURE = "GET_SALES_HISTORIES_FAILURE";

export const GET_LOYALTY_SUMMARY = "GET_LOYALTY_SUMMARY";
export const RECEIVE_LOYALTY_SUMMARY = "RECEIVE_LOYALTY_SUMMARY";
export const GET_LOYALTY_SUMMARY_SUCCESS = "GET_LOYALTY_SUMMARY_SUCCESS";
export const GET_LOYALTY_SUMMARY_FAILURE = "GET_LOYALTY_SUMMARY_FAILURE";

export const GET_SALES_SUMMARY = "GET_SALES_SUMMARY";
export const RECEIVE_SALES_SUMMARY = "RECEIVE_SALES_SUMMARY";
export const GET_SALES_SUMMARY_SUCCESS = "GET_SALES_SUMMARY_SUCCESS";
export const GET_SALES_SUMMARY_FAILURE = "GET_SALES_SUMMARY_FAILURE";

export const GET_POINT_REPORT = "GET_POINT_REPORT";
export const RECEIVE_POINT_REPORT = "RECEIVE_POINT_REPORT";
export const GET_POINT_REPORT_SUCCESS = "GET_POINT_REPORT_SUCCESS";
export const GET_POINT_REPORT_FAILURE = "GET_POINT_REPORT_FAILURE";

export const GET_ORDER_REPORT = "GET_ORDER_REPORT";
export const RECEIVE_ORDER_REPORT = "RECEIVE_ORDER_REPORT";
export const RECEIVE_CUSTOMER_REPORT = "RECEIVE_CUSTOMER_REPORT";
export const GET_ORDER_REPORT_SUCCESS = "GET_ORDER_REPORT_SUCCESS";
export const GET_ORDER_REPORT_FAILURE = "GET_ORDER_REPORT_FAILURE";

export const GET_CHANNEL_ORDER_REPORT = "GET_CHANNEL_ORDER_REPORT";
export const RECEIVE_CHANNEL_ORDER_REPORT = "RECEIVE_CHANNEL_ORDER_REPORT";
export const SET_CHANNEL_ORDER_REPORT_SUCCESS =
  "SET_CHANNEL_ORDER_REPORT_SUCCESS";
export const SET_CHANNEL_ORDER_REPORT_FAILURE =
  "SET_CHANNEL_ORDER_REPORT_FAILURE";

export const GET_DELIVERY_REPORT = "GET_DELIVERY_REPORT";
export const RECEIVE_DELIVERY_REPORT = "RECEIVE_DELIVERY_REPORT";
export const GET_DELIVERY_REPORT_SUCCESS = "GET_DELIVERY_REPORT_SUCCESS";
export const GET_DELIVERY_REPORT_FAILURE = "GET_DELIVERY_REPORT_FAILURE";

export const GET_TRS_REPORT = "GET_TRS_REPORT";
export const SET_TRS_REPORT_SUCCESS = "SET_TRS_REPORT_SUCCESS";
export const SET_TRS_REPORT_FAILURE = "SET_TRS_REPORT_FAILURE";
export const RECEIVE_TRS_REPORT = "RECEIVE_TRS_REPORT";
export const RECEIVE_TRS_ITEM_REPORT = "RECEIVE_TRS_ITEM_REPORT";

export const GET_REVIEW_REPORT = "GET_REVIEW_REPORT";
export const RECEIVE_REVIEW_REPORT = "RECEIVE_REVIEW_REPORT";
export const GET_REVIEW_REPORT_SUCCESS = "GET_REVIEW_REPORT_SUCCESS";
export const GET_REVIEW_REPORT_FAILURE = "GET_REVIEW_REPORT_FAILURE";

export const GET_LOYALTY_REPORT1 = "GET_LOYALTY_REPORT1";
export const RECEIVE_LOYALTY_REPORT1 = "RECEIVE_LOYALTY_REPORT1";
export const GET_LOYALTY_REPORT1_SUCCESS = "GET_LOYALTY_REPORT1_SUCCESS";
export const GET_LOYALTY_REPORT1_FAILURE = "GET_LOYALTY_REPORT1_FAILURE";
export const GET_INVENTORY_DATE_REPORT = "GET_INVENTORY_DATE_REPORT";
export const GET_INVENTORY_ITEM_REPORT = "GET_INVENTORY_ITEM_REPORT";
export const GET_INVENTORY_TURNOVER_REPORT = "GET_INVENTORY_TURNOVER_REPORT";
export const GET_INVENTORY_ASSET_REPORT = "GET_INVENTORY_ASSET_REPORT";
export const SET_INVENTORY_REPORT_STATUS_SUCCESS =
  "SET_INVENTORY_REPORT_STATUS_SUCCESS";
export const SET_INVENTORY_REPORT_STATUS_FAILURE =
  "SET_INVENTORY_REPORT_STATUS_FAILURE";
export const RECEIVE_INVENTORY_REPORT = "RECEIVE_INVENTORY_REPORT";

// Order
export const GET_ORDER = "GET_ORDER";
export const GET_ORDER_WITH_TRANSACTIONS = "GET_ORDER_WITH_TRANSACTIONS";
export const RECEIVE_ORDER = "RECEIVE_ORDER";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_FAILURE = "GET_ORDER_FAILURE";
export const GET_ORDER_HISTORIES = "GET_ORDER_HISTORIES";
export const GET_ALL_ORDER_HISTORIES = "GET_ALL_ORDER_HISTORIES";
export const RECEIVE_ORDER_HISTORIES = "RECEIVE_ORDER_HISTORIES";
export const GET_SHIPPING_ORDERS = "GET_SHIPPING_ORDERS";
export const GET_ORDER_HISTORIES_SUCCESS = "GET_ORDER_HISTORIES_SUCCESS";
export const GET_ORDER_HISTORIES_FAILURE = "GET_ORDER_HISTORIES_FAILURE";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const UPDATE_ORDERS = "UPDATE_ORDERS";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAILURE = "UPDATE_ORDER_FAILURE";
export const GET_ORDERS_BY_STORE = "GET_ORDERS_BY_STORE";
export const RECEIVE_ORDERS = "RECEIVE_ORDERS";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS ";
export const GET_ORDERS_FAILURE = "GET_ORDERS_FAILURE";
export const UPDATE_ORDER_FROM_RECEIPT = "UPDATE_ORDER_FROM_RECEIPT";
export const GET_PROVIDER_ORDERS = "GET_PROVIDER_ORDERS";
export const CLEAR_PROVIDER_ORDERS = "CLEAR_PROVIDER_ORDERS";
export const RECEIVE_PROVIDER_ORDERS = "RECEIVE_PROVIDER_ORDERS";
export const SEND_SHIPPING_NOTICE = "SEND_SHIPPING_NOTICE";

// Notice
export const GET_NOTICES = "GET_NOTICES";
export const RECEIVE_NOTICE = "RECEIVE_NOTICE";
export const RECEIVE_NOTICES = "RECEIVE_NOTICES";
export const SET_ARTICLE_STATUS_SUCCESS = "SET_ARTICLE_STATUS_SUCCESS";
export const SET_ARTICLE_STATUS_FAILURE = "SET_ARTICLE_STATUS_FAILURE";
export const CREATE_OR_UPDATE_NOTICE = "CREATE_OR_UPDATE_NOTICE";
export const DELETE_NOTICE = "DELETE_NOTICE";
export const RECEIVE_DELETE_NOTICE = "RECEIVE_DELETE_NOTICE";
export const GET_ARCHIVED_NOTICES = "GET_ARCHIVED_NOTICES";
export const RECEIVE_ARCHIVED_NOTICES = "RECEIVE_ARCHIVED_NOTICES";

//Notice Campaign
export const GET_NOTICE_CAMPAIGNS = "GET_NOTICE_CAMPAIGNS";
export const CREATE_OR_UPDATE_NOTICE_CAMPAIGN =
  "CREATE_OR_UPDATE_NOTICE_CAMPAIGN";
export const RECEIVE_NOTICE_CAMPAIGN = "RECEIVE_NOTICE_CAMPAIGN";
export const RECEIVE_NOTICE_CAMPAIGNS = "RECEIVE_NOTICE_CAMPAIGNS";
export const DELETE_NOTICE_CAMPAIGN = "DELETE_NOTICE_CAMPAIGN";
export const RECEIVE_DELETED_NOTICE_CAMPAIGN =
  "RECEIVE_DELETED_NOTICE_CAMPAIGN";

// Around market
export const SIGNUP_MARKET = "SIGNUP_MARKET";
export const SIGNIN_MARKET = "SIGNIN_MARKET";
export const RESET_MARKET_PASSWORD = "RESET_MARKET_PASSWORD";
export const SIGNOUT_MARKET = "SIGNOUT_MARKET";
export const SET_MARKET_STATUS_SUCCESS = "SET_MARKET_STATUS_SUCCESS";
export const SET_MARKET_STATUS_FAILURE = "SET_MARKET_STATUS_FAILURE";
export const SET_MARKET_STORE_STATUS_SUCCESS =
  "SET_MARKET_STORE_STATUS_SUCCESS";
export const SET_MARKET_STORE_STATUS_FAILURE =
  "SET_MARKET_STORE_STATUS_FAILURE";
export const SET_MARKET_ORDER_STATUS_SUCCESS =
  "SET_MARKET_ORDER_STATUS_SUCCESS";
export const SET_MARKET_ORDER_STATUS_FAILURE =
  "SET_MARKET_ORDER_STATUS_FAILURE";
export const SET_STORE_FAVORITE = "SET_STORE_FAVORITE";
export const GET_ORDER_STORE = "GET_ORDER_STORE";
export const GET_USER = "GET_USER";
export const CLEAR_USER = "CLEAR_USER";
export const UPDATE_USER = "UPDATE_USER";
export const RECEIVE_STORE = "RECEIVE_STORE";
export const CLEAR_STORE = "CLEAR_STORE";
export const RECEIVE_USER = "RECEIVE_USER";
export const RECEIVE_PRODUCTS = "RECEIVE_PRODUCTS";
export const RECEIVE_POINT_STATS = "RECEIVE_POINT_STATS";
export const SET_USER_STATUS_SUCCESS = "SET_USER_STATUS_SUCCESS";
export const SET_USER_STATUS_FAILURE = "SET_USER_STATUS_FAILURE";
export const LOAD_STORE = "LOAD_STORE";
export const ADD_ITEM_TO_CART = "ADD_ITEM_TO_CART";
export const SET_ORDER_TYPE = "SET_ORDER_TYPE";
export const ADD_ITEMS_TO_CART = "ADD_ITEMS_TO_CART";
export const REMOVE_ITEM_FROM_CART = "REMOVE_ITEM_FROM_CART";
export const DUPLICATE_ORDER_TO_CART = "DUPLICATE_ORDER_TO_CART";
export const RECEIVE_CART = "RECEIVE_CART";
export const CLEAR_CART = "CLEAR_CART";
export const DELETE_CART = "DELETE_CART";
export const GET_CART = "GET_CART";
export const RECEIVE_PAID_ORDER = "RECEIVE_PAID_ORDER";
export const GET_ORDERS_BY_CUSTOMER = "GET_ORDERS_BY_CUSTOMER";
export const PAY_ORDER = "PAY_ORDER";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const SEND_RECEIPT_LINK = "SEND_RECEIPT_LINK";
export const CANCEL_ORDER = "CANCEL_ORDER";
export const SET_MARKET_AUTHENTICATED = "SET_MARKET_AUTHENTICATED";
export const RECEIVE_CHECKOUT = "RECEIVE_CHECKOUT";
export const GET_ORDER_BY_APIKEY = "GET_ORDER_BY_APIKEY";

// Subscription
export const GET_SUBSCRIPTION_PLANS = "GET_SUBSCRIPTION_PLANS";
export const GET_SUBSCRIPTION_PLAN = "GET_SUBSCRIPTION_PLAN";
export const GET_SUBSCRIPTION_PLANS_BY_IDS = "GET_SUBSCRIPTION_PLANS_BY_IDS";
export const RECEIVE_SUBSCRIPTION_PLANS = "RECEIVE_SUBSCRIPTION_PLANS";
export const RECEIVE_SUBSCRIPTION_PLAN = "RECEIVE_SUBSCRIPTION_PLAN";
export const CREATE_SUBSCRIPTION_PLAN = "CREATE_SUBSCRIPTION_PLAN";
export const UPDATE_SUBSCRIPTION_PLAN = "UPDATE_SUBSCRIPTION_PLAN";
export const DELETE_SUBSCRIPTION_PLAN = "DELETE_SUBSCRIPTION_PLAN";
export const SET_SUBSCRIPTION_STATUS_SUCCESS =
  "SET_SUBSCRIPTION_STATUS_SUCCESS";
export const SET_SUBSCRIPTION_STATUS_FAILURE =
  "SET_SUBSCRIPTION_STATUS_FAILURE";
export const SET_EDITING_SUBSCRIPTION_PLAN = "SET_EDITING_SUBSCRIPTION_PLAN";
export const CLEAR_EDITING_SUBSCRIPTION_PLAN =
  "CLEAR_EDITING_SUBSCRIPTION_PLAN";
export const SUBSCRIBE_SUBSCRIPTION_PLAN = "SUBSCRIBE_SUBSCRIPTION_PLAN";
export const STOP_SUBSCRIPTION = "STOP_SUBSCRIPTION";
export const RESTORE_SUBSCRIPTION = "RESTORE_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const GET_SUBSCRIPTION_EVENTS = "GET_SUBSCRIPTION_EVENTS";
export const RECEIVE_SUBSCRIPTION_EVENTS = "RECEIVE_SUBSCRIPTION_EVENTS";
export const SET_SUBSCRIPTION_EDITING = "SET_SUBSCRIPTION_EDITING";
export const RECEIVE_SUBSCRIPTION_REPORT = "RECEIVE_SUBSCRIPTION_REPORT";
export const SET_SUBSCRIPTION_REPORT_SUCCESS =
  "SET_SUBSCRIPTION_REPORT_SUCCESS";
export const SET_SUBSCRIPTION_REPORT_FAILURE =
  "SET_SUBSCRIPTION_REPORT_FAILURE";
export const GET_SUBSCRIPTION_REPORT = "GET_SUBSCRIPTION_REPORT";

// Settlements
export const GET_SETTLEMENTS = "GET_SETTLEMENTS";
export const RECEIVE_SETTLEMENTS = "RECEIVE_SETTLEMENTS";
export const SET_SETTLEMENTS_STATUS_SUCCESS = "SET_SETTLEMENTS_STATUS_SUCCESS";
export const SET_SETTLEMENTS_STATUS_FAILURE = "SET_SETTLEMENTS_STATUS_FAILURE";

// Terminals
export const GET_TERMINALS = "GET_TERMINALS";
export const CREATE_OR_UPDATE_TERMINAL = "CREATE_OR_UPDATE_TERMINAL";
export const DELETE_TERMINAL = "DELETE_TERMINAL";
export const RECEIVE_DELETED_TERMINAL = "RECEIVE_DELETED_TERMINAL";
export const SET_TERMINAL_STATUS_FAILURE = "SET_TERMINAL_STATUS_FAILURE";
export const SET_TERMINAL_STATUS_SUCCESS = "SET_TERMINAL_STATUS_SUCCESS";
export const RECEIVE_TERMINALS = "RECEIVE_TERMINALS";
export const FIND_EMPLOYEE_BY_EMAIL = "FIND_EMPLOYEE_BY_EMAIL";
export const RECEIVE_SEARCHED_MGMT_STORES = "RECEIVE_SEARCHED_MGMT_STORES";
export const GET_MGMT_CONTRACT = "GET_MGMT_CONTRACT";
export const RECEIVE_MGMT_CONTRACT = "RECEIVE_MGMT_CONTRACT";
export const RECEIVE_MGMT_LOCATIONS = "RECEIVE_MGMT_LOCATIONS";
export const CLEAR_MGMT_LOCATIONS = "CLEAR_MGMT_LOCATIONS";
export const SET_MGMT_STATUS_SUCCESS = "SET_MGMT_STATUS_SUCCESS";
export const SET_MGMT_STATUS_FAILURE = "SET_MGMT_STATUS_FAILURE";
export const RECEIVE_OPERATOR = "RECEIVE_OPERATOR";
export const RECEIVE_CONTRACT = "RECEIVE_CONTRACT";
export const CLEAR_CONTRACT = "CLEAR_CONTRACT";
export const CLEAR_OPERATOR = "CLEAR_OPERATOR";
export const CLEAR_ALL_CONTRACT = "CLEAR_ALL_CONTRACT";
export const CREATE_OR_UPDATE_CONTRACT = "CREATE_OR_UPDATE_CONTRACT";
export const RECEIVE_UPDATED_CONTRACT = "RECEIVE_UPDATED_CONTRACT";
export const CREATE_OR_UPDATE_RENTAL_CONTRACT =
  "CREATE_OR_UPDATE_RENTAL_CONTRACT";
export const RECEIVE_RENTALS = "RECEIVE_RENTALS";
export const RECEIVE_CONTRACT_TERMINAL = "RECEIVE_CONTRACT_TERMINAL";
export const RECEIVE_DELETED_CONTRACT_TERMINAL_ID =
  "RECEIVE_DELETED_CONTRACT_TERMINAL_ID";
export const FIND_STORES_BY_OPERATOR_ID = "FIND_STORES_BY_OPERATOR_ID";
export const RECEIVE_SEARCHED_STORES_BY_OPERATOR_ID =
  "RECEIVE_SEARCHED_STORES_BY_OPERATOR_ID";

//PurhcaseOrder

export const GET_PURCHASE_ORDERS = "GET_PURCHASE_ORDERS";
export const GET_PURCHASE_ORDER_BY_ID = "GET_PURCHASE_ORDER_BY_ID";
export const GET_EMPLOYEE_BY_PURCHASE_ORDER_ID =
  "GET_EMPLOYEE_BY_PURCHASE_ORDER_ID";
export const RECEIVE_PURCHASE_ORDERS = "RECEIVE_PURCHASE_ORDERS";
export const RECEIVE_PURCHASE_ORDER = "RECEIVE_PURCHASE_ORDER";
export const SET_PURCHASE_ORDER_STATUS_SUCCESS =
  "SET_PURCHASE_ORDER_STATUS_SUCCESS";
export const SET_PURCHASE_ORDER_STATUS_FAILURE =
  "SET_PURCHASE_ORDER_STATUS_FAILURE";
export const CREATE_OR_UPDATE_PURCHASE_ORDER =
  "CREATE_OR_UPDATE_PURCHASE_ORDER";
export const DELETE_PURCHASE_ORDER = "DELETE_PURCHASE_ORDER";
export const RECEIVE_DELETED_PURCHASE_ORDER = "RECEIVE_DELETED_PURCHASE_ORDER";

// Supplier

export const GET_SUPPLIERS = "GET_SUPPLIERS";
export const RECEIVE_SUPPLIERS = "RECEIVE_SUPPLIERS";
export const RECEIVE_SUPPLIER = "RECEIVE_SUPPLIER";
export const SET_SUPPLIER_STATUS_SUCCESS = "SET_SUPPLIER_STATUS_SUCCESS";
export const SET_SUPPLIER_STATUS_FAILURE = "SET_SUPPLIER_STATUS_FAILURE";
export const CREATE_OR_UPDATE_SUPPLIER = "CREATE_OR_UPDATE_SUPPLIER";
export const DELETE_SUPPLIER = "DELETE_SUPPLIER";
export const RECEIVE_DELETED_SUPPLIER = "RECEIVE_DELETED_SUPPLIER";

// Cafe24 Config
export const GET_PROVIDER_CONFIG = "GET_PROVIDER_CONFIG";
export const UPDATE_PROVIDER_CONFIG = "UPDATE_PROVIDER_CONFIG";
export const SET_PROVIDER_STATUS_SUCCESS = "SET_PROVIDER_STATUS_SUCCESS";
export const SET_PROVIDER_STATUS_FAILURE = "SET_PROVIDER_STATUS_FAILURE";
export const RECEIVE_PROVIDER_CONFIG = "RECEIVE_PROVIDER_CONFIG";
export const GET_PROVIDER_CUSTOMER_GROUPS = "GET_PROVIDER_CUSTOMER_GROUPS";
export const RECEIVE_PROVIDER_CUSTOMER_GROUPS =
  "RECEIVE_PROVIDER_CUSTOMER_GROUPS";
export const CLEAR_PROVIDER_CUSTOMER_GROUPS = "CLEAR_PROVIDER_CUSTOMER_GROUPS";
export const GET_TIER_UPDATE_HISTORIES = "GET_TIER_UPDATE_HISTORIES";
export const RECEIVE_TIER_UPDATE_HISTORIES = "RECEIVE_TIER_UPDATE_HISTORIES";
export const CLEAR_TIER_UPDATE_HISTORIES = "CLEAR_TIER_UPDATE_HISTORIES";
export const GET_PROVIDER_PRODUCTS = "GET_PROVIDER_PRODUCTS";
export const LINK_PROVIDER_PRODUCTS = "LINK_PROVIDER_PRODUCTS";
export const SYNC_PROVIDER_INVENTORIES = "SYNC_PROVIDER_INVENTORIES";
export const RECEIVE_PROVIDER_PRODUCTS = "RECEIVE_PROVIDER_PRODUCTS";
export const CLEAR_PROVIDER_PRODUCTS = "CLEAR_PROVIDER_PRODUCTS";
export const UPDATE_STORE_PROVIDER = "UPDATE_STORE_PROVIDER";
export const GET_INVENTORY_SYNC_HISTORIES = "GET_INVENTORY_SYNC_HISTORIES";
export const RECEIVE_INVENTORY_SYNC_HISTORIES =
  "RECEIVE_INVENTORY_SYNC_HISTORIES";
export const CLEAR_INVENTORY_SYNC_HISTORIES = "CLEAR_INVENTORY_SYNC_HISTORIES";
export const UNLINK_PROVIDER_PRODUCTS = "UNLINK_PROVIDER_PRODUCTS";
export const GET_PROVIDER_PRODUCTS_LINK_HISTORIES =
  "GET_PROVIDER_PRODUCTS_LINK_HISTORIES";
export const RECEIVE_LINK_HISTORIES = "RECEIVE_LINK_HISTORIES";
export const CLEAR_LINK_HISTORIES = "CLEAR_LINK_HISTORIES";
export const SYNC_PROVIDER_PRODUCTS = "SYNC_PROVIDER_PRODUCTS";
export const GET_DRAFT_ONOFF_CONFIG = "GET_DRAFT_ONOFF_CONFIG";
export const GET_LIVE_ONOFF_CONFIG = "GET_LIVE_ONOFF_CONFIG";
export const RECEIVE_DRAFT_ONOFF_CONFIG = "RECEIVE_DRAFT_ONOFF_CONFIG";
export const RECEIVE_LIVE_ONOFF_CONFIG = "RECEIVE_LIVE_ONOFF_CONFIG";
export const CREATE_OR_UPDATE_ONOFF_CONFIG = "CREATE_OR_UPDATE_ONOFF_CONFIG";
export const CLEAR_DRAFT_ONOFF_CONFIG = "CLEAR_DRAFT_ONOFF_CONFIG";
export const DELETE_DRAFT_ONOFF_CONFIG = "DELETE_DRAFT_ONOFF_CONFIG";
export const GET_CHERRY_PICKERS = "GET_CHERRY_PICKERS";
export const RECEIVE_PICKERS = "RECEIVE_PICKERS";
export const CLEAR_PICKERS = "CLEAR_PICKERS";
export const GET_BLACKLIST_CUSTOMERS = "GET_BLACKLIST_CUSTOMERS";
export const RECEIVE_BLACKLIST_CUSTOMERS = "RECEIVE_BLACKLIST_CUSTOMERS";
export const CLEAR_BLACKLIST_CUSTOMERS = "CLEAR_BLACKLIST_CUSTOMERS";
export const GET_CUSTOMER_TIER_HISTORIES = "GET_CUSTOMER_TIER_HISTORIES";
export const RECEIVE_CUSTOMER_TIER_HISTORIES =
  "RECEIVE_CUSTOMER_TIER_HISTORIES";
export const CLEAR_CUSTOMER_TIER_HISTORIES = "CLEAR_CUSTOMER_TIER_HISTORIES";
export const UPDATE_CUSTOMER_TIER = "UPDATE_CUSTOMER_TIER";
export const UPDATE_CUSTOMER_BLACKLIST = "UPDATE_CUSTOMER_BLACKLIST";

// Waiting config
export const GET_WAITING = "GET_WAITING";
export const CANCEL_WAITING = "CANCEL_WAITING";
export const RECEIVE_WAITING = "RECEIVE_WAITING";
export const RECEIVE_WAITING_INFO = "RECEIVE_WAITING_INFO";
export const GET_WAITING_INFO = "GET_WAITING_INFO";
export const CLEAR_WAITING = "CLEAR_WAITING";
export const SET_WAITING_STATUS_SUCCESS = "SET_WAITING_STATUS_SUCCESS";
export const SET_WAITING_STATUS_FAILURE = "SET_WAITING_STATUS_FAILURE";

// Local
export function setSignUpState(email, password) {
  return {
    type: SET_SIGNUP_STATE,
    email,
    password,
  };
}

export function setNarrowWidth(isNarrow) {
  return {
    type: SET_NARROW_WIDTH,
    isNarrow,
  };
}

export function setDropoutSuccess() {
  return {
    type: SET_DROPOUT_SUCCESS,
  };
}

export function setDropoutFailure(error) {
  return {
    type: SET_DROPOUT_FAILURE,
    error,
  };
}

export function getRequestProgressEvents(originId) {
  return {
    type: GET_REQUEST_PROGRESS_EVENTS,
    originId,
  };
}

export function receiveRequestProgressEvents(events) {
  return {
    type: RECEIVE_REQUEST_PROGRESS_EVENTS,
    events,
  };
}

export function receiveRequestProgressEvent(event) {
  return {
    type: RECEIVE_REQUEST_PROGRESS_EVENT,
    event,
  };
}

export function clearRequestProgressEvent(id) {
  return {
    type: CLEAR_REQUEST_PROGRESS_EVENT,
    id,
  };
}

export function openCustomerInfo() {
  return {
    type: OPEN_CUSTOMER_INFO,
  };
}

export function closeCustomerInfo() {
  return {
    type: CLOSE_CUSTOMER_INFO,
  };
}

export function showAuthorizeModal(view) {
  return {
    type: SHOW_AUTHORIZE_MODAL,
    view,
  };
}

export function closeAuthorizeMoal() {
  return {
    type: CLOSE_AUTHORIZE_MODAL,
  };
}

export function showModal(view, param) {
  return {
    type: SHOW_MODAL,
    view,
    param,
  };
}

export function closeModal() {
  return {
    type: CLOSE_MODAL,
  };
}

export function updateLocationEnvironment(environment) {
  return {
    type: UPDATE_LOCATION_ENVIRONMENT,
    environment,
  };
}

export function setEnvironment(environment) {
  return {
    type: SET_ENVIRONMENT,
    environment,
  };
}

// Editing
export function setEditingLocation(location) {
  return {
    type: SET_EDITING_LOCATION,
    location,
  };
}

export function setEditingEmployee(employee) {
  return {
    type: SET_EDITING_EMPLOYEE,
    employee,
  };
}

export function setEditingPlan(plan) {
  return {
    type: SET_EDITING_PLAN,
    plan,
  };
}

export function setEditingNotice(notice) {
  return {
    type: SET_EDITING_NOTICE,
    notice,
  };
}

// User
export function updateUser(user, incentiveCustomerId = null) {
  return {
    type: UPDATE_USER,
    user,
    incentiveCustomerId,
  };
}

export function getUser(id) {
  return {
    type: GET_USER,
    id,
  };
}

export function clearUser() {
  return {
    type: CLEAR_USER,
  };
}

export function receiveProducts(products) {
  return {
    type: RECEIVE_PRODUCTS,
    products,
  };
}

export function receivePointStats(stats) {
  return {
    type: RECEIVE_POINT_STATS,
    stats,
  };
}

export function receiveUser(user) {
  return {
    type: RECEIVE_USER,
    user,
  };
}

export function setUserStatusSuccess() {
  return {
    type: SET_USER_STATUS_SUCCESS,
  };
}

export function setUserStatusFailure(error) {
  return {
    type: SET_USER_STATUS_FAILURE,
    error,
  };
}

// Employee
export function setEmployeeStatusSuccess() {
  return {
    type: SET_EMPLOYEE_STATUS_SUCCESS,
  };
}

export function setEmployeeStatusFailure(error) {
  return {
    type: SET_EMPLOYEE_STATUS_FAILURE,
    error,
  };
}

export function updateEmployee(employee) {
  return {
    type: UPDATE_EMPLOYEE,
    employee,
  };
}

export function updateOrganizationEmployee(employee) {
  return {
    type: UPDATE_ORGANIZATION_EMPLOYEE,
    employee,
  };
}

export function deleteOrganizationEmployee(employee) {
  return {
    type: DELETE_ORGANIZATION_EMPLOYEE,
    employee,
  };
}

export function setCurrentEmployee(employee) {
  return {
    type: SET_CURRENT_EMPLOYEE,
    employee,
  };
}

export function receiveEmployee(employee) {
  return {
    type: RECEIVE_EMPLOYEE,
    employee,
  };
}

export function receiveEmployees(employees) {
  return {
    type: RECEIVE_EMPLOYEES,
    employees,
  };
}

export function setCurrentOrganization(organization) {
  return {
    type: SET_CURRENT_ORGANIZATION,
    organization,
  };
}

export function getOrganizations(storeId) {
  return {
    type: GET_ORGNIZATIONS,
    storeId,
  };
}

export function receiveOrganizations(organizations) {
  return {
    type: RECEIVE_ORGANIZATIONS,
    organizations,
  };
}

// Store
export function initStore(store) {
  return {
    type: INIT_STORE,
    store,
  };
}

export function generateStore(employee, survey, settings) {
  return {
    type: GENERATE_STORE,
    employee,
    survey,
    settings,
  };
}
export function getStore(storeId, withStats = false) {
  return {
    type: GET_STORE_REQUEST,
    storeId,
    withStats,
  };
}

export function updateStore(updater) {
  return {
    type: UPDATE_STORE_REQUEST,
    updater,
  };
}

export function registerOrUpdateAccount(account) {
  return {
    type: REGISTER_OR_UPDATE_ACCOUNT,
    account,
  };
}

export function updateStoreLoyalty(loyalty) {
  return {
    type: UPDATE_STORE_LOYALTY_REQUEST,
    loyalty,
  };
}

export function initBillingHistories(billingHistories) {
  return {
    type: INIT_BILLING_HISTORIES,
    billingHistories,
  };
}

export function receiveBillinghistory(history) {
  return {
    type: RECEIVE_BILLING_HISTORY,
    history,
  };
}

export function getBillingHistories(storeId, locationId = null) {
  return {
    type: GET_BILLING_HISTORIES_REQUEST,
    storeId,
    locationId,
  };
}

export function payFailedBillings(storeId, locationId = null) {
  return {
    type: PAY_FAILED_BILLINGS,
    storeId,
    locationId,
  };
}

export function setGetBillingHistoriesSuccess() {
  return {
    type: GET_BILLING_HISTORIES_SUCCESS,
  };
}

export function setGetBillingHistoriesFailure(error) {
  return {
    type: GET_BILLING_HISTORIES_FAILURE,
    error,
  };
}

export function setStoreSuccess() {
  return {
    type: GET_STORE_SUCCESS,
  };
}

export function setStoreFailure(error) {
  return {
    type: GET_STORE_FAILURE,
    error,
  };
}

export function getStoreDevices(storeId) {
  return {
    type: GET_STORE_DEVICES,
    storeId,
  };
}

export function receiveStoreDevices(devices) {
  return {
    type: RECEIVE_STORE_DEVICES,
    devices,
  };
}

export function getBillingCost(storeId, locationId) {
  return {
    type: GET_BILLING_COST_REQUEST,
    storeId,
    locationId,
  };
}

export function receiveStoreStatus(status) {
  return {
    type: RECEIVE_STORE_STATUS,
    status,
  };
}

export function receiveReceiptStore(store, location) {
  return {
    type: RECEIVE_RECEIPT_STORE,
    store,
    location,
  };
}

export function clearReceiptStore() {
  return {
    type: CLEAR_RECEIPT_STORE,
  };
}

export function updateMirrorConfig(config) {
  return {
    type: UPDATE_MIRROR_CONFIG,
    config,
  };
}

export function receiveBillingCost(cost) {
  return {
    type: RECEIVE_BILLING_COST,
    cost,
  };
}

export function setGetBillingCostSucess() {
  return {
    type: GET_BILLING_COST_SUCCESS,
  };
}

export function setGetBillingCostFailure(error) {
  return {
    type: GET_BILLING_COST_FAILURE,
    error,
  };
}

export function receiveCampaigns(campaigns) {
  return {
    type: RECEIVE_CAMPAIGNS,
    campaigns,
  };
}

export function createOrUpdateLocation(updater) {
  return {
    type: CREATE_OR_UPDATE_LOCATION,
    updater,
  };
}

export function updateLocation(location) {
  return {
    type: UPDATE_LOCATION,
    location,
  };
}

export function receiveLocation(location) {
  return {
    type: RECEIVE_LOCATION,
    location,
  };
}

export function syncMenuBoard(menu, locationInfo) {
  return {
    type: SYNC_MENU_BOARD,
    menu,
    locationInfo,
  };
}

export function syncMenuBoardSuccess() {
  return {
    type: SYNC_MENU_BOARD_SUCCESS,
  };
}

export function syncMenuBoardFailure(error) {
  return {
    type: SYNC_MENU_BOARD_FAILURE,
    error,
  };
}

export function getCampaigns(
  storeId,
  locationId,
  startDate = null,
  endDate = null,
  filters = {}
) {
  return {
    type: GET_CAMPAIGNS,
    storeId,
    locationId,
    startDate,
    endDate,
    filters,
  };
}

export function setGetCampaignsSuccess() {
  return {
    type: GET_CAMPAIGNS_SUCCESS,
  };
}

export function setGetCampaignsFailure(error) {
  return {
    type: GET_CAMPAIGNS_FAILURE,
    error,
  };
}

export function getCampaign(campaignId) {
  return {
    type: GET_CAMPAIGN,
    campaignId,
  };
}

export function getCampaignWithReport(campaignId, start = null, end = null) {
  return {
    type: GET_CAMPAIGN_WITH_REPORT,
    campaignId,
    start,
    end,
  };
}

export function setGetCampaignSuccess() {
  return {
    type: GET_CAMPAIGN_SUCCESS,
  };
}

export function setGetCampaignFailure(error) {
  return {
    type: GET_CAMPAIGN_FAILURE,
    error,
  };
}

export function createCampaign(campaign) {
  return {
    type: CREATE_CAMPAIGN,
    campaign,
  };
}

export function updateCampaign(campaign) {
  return {
    type: UPDATE_CAMPAIGN,
    campaign,
  };
}

export function receiveCampaign(campaign) {
  return {
    type: RECEIVE_CAMPAIGN,
    campaign,
  };
}

export function setCreateOrUpdateCampaignSuccess() {
  return {
    type: CREATE_OR_UPDATE_CAMPAIGN_SUCCESS,
  };
}

export function setCreateOrUpdateCampaignFailure(error) {
  return {
    type: CREATE_OR_UPDATE_CAMPAIGN_FAILURE,
    error,
  };
}

export function cancelCampaign(campaignId) {
  return {
    type: CANCEL_CAMPAIGN,
    campaignId,
  };
}
export function setCancelCampaignSuccess() {
  return {
    type: CANCEL_CAMPAIGN_SUCCESS,
  };
}

export function setCancelCampaignFailure(error) {
  return {
    type: CANCEL_CAMPAIGN_FAILURE,
    error,
  };
}

export function launchCampaign(campaign) {
  return {
    type: LAUNCH_CAMPAIGN,
    campaign,
  };
}
export function setLaunchCampaignSuccess() {
  return {
    type: LAUNCH_CAMPAIGN_SUCCESS,
  };
}

export function setLaunchCampaignFailure(error) {
  return {
    type: LAUNCH_CAMPAIGN_FAILURE,
    error,
  };
}

export function deleteCampaign(campaignId) {
  return {
    type: DELETE_CAMPAIGN,
    campaignId,
  };
}

export function sendBarcodeCouponCampaign(campaign) {
  return {
    type: SEND_BARCODE_COUPON_CAMPAIGN,
    campaign,
  };
}

export function receiveDeletedCampaign(campaignId) {
  return {
    type: RECEIVE_DELETED_CAMPAIGN,
    campaignId,
  };
}

export function setDeleteCampaignSuccess() {
  return {
    type: DELETE_CAMPAIGN_SUCCESS,
  };
}

export function setDeleteCampaignFailure(error) {
  return {
    type: DELETE_CAMPAIGN_FAILURE,
    error,
  };
}

export function getExpectedRecipientNumber(
  storeId,
  locationId,
  locationIds,
  tagId,
  includes = null,
  filter = null
) {
  return {
    type: GET_EXPECTED_RECIPIENT_NUMBER,
    storeId,
    locationId,
    locationIds,
    tagId,
    includes,
    filter,
  };
}

export function setExpectedRecipientNumber(count) {
  return {
    type: SET_EXPECTED_RECIPIENT_NUMBER,
    count,
  };
}

// Review Campaign
export function createOrUpdateReviewCampaign(campaign) {
  return {
    type: CREATE_OR_UPDATE_REVIEW_CAMPAIGN,
    campaign,
  };
}

export function lanunchReviewCampaign(campaign) {
  return {
    type: LAUNCH_REVIEW_CAMPAIGN,
    campaign,
  };
}

export function deleteReviewCampaign(campaign) {
  return {
    type: DELETE_REVIEW_CAMPAIGN,
    campaign,
  };
}

export function getReviewCampaigns(storeId) {
  return {
    type: GET_REVIEW_CAMPAIGNS,
    storeId,
  };
}

export function setReviewCampaignSuccess() {
  return {
    type: SET_REVIEW_CAMPAIGN_SUCCESS,
  };
}

export function setReviewCampaignFailure(error) {
  return {
    type: SET_REVIEW_CAMPAIGN_FAILURE,
    error,
  };
}

export function receiveReviewCampaign(campaign) {
  return {
    type: RECEIVE_REVIEW_CAMPAIGN,
    campaign,
  };
}

export function receiveReviewCampaigns(campaigns) {
  return {
    type: RECEIVE_REVIEW_CAMPAIGNS,
    campaigns,
  };
}

export function receiveDeletedReviewCampaign(campaignId) {
  return {
    type: RECEIVE_DELETED_REVIEW_CAMPAIGN,
    campaignId,
  };
}

export function getReviewCampaignResults(storeId, locationId, start, end) {
  return {
    type: GET_REVIEW_CAMPAIGN_RESULTS,
    storeId,
    locationId,
    start,
    end,
  };
}

export function receiveReviewCampaignResults(results) {
  return {
    type: RECEIVE_REVIEW_CAMPAIGN_RESULTS,
    results,
  };
}

export function downloadReviewCampaign(campaign) {
  return {
    type: DOWNLOAD_REVIEW_CAMPAIGN,
    campaign,
  };
}

// Coupon

export function getCoupon(couponId) {
  return {
    type: GET_COUPON,
    couponId,
  };
}

export function getBarcodeCoupon(couponId, locationId) {
  return {
    type: GET_BARCODE_COUPON,
    couponId,
    locationId,
  };
}

export function getBarcodeCoupons(storeId, override = false) {
  return {
    type: GET_BARCODE_COUPONS,
    storeId,
    override,
  };
}

export function getBarcodeCouponTransactionByPhone(
  storeId,
  locationId,
  couponId,
  phone
) {
  return {
    type: GET_BARCODE_COUPOON_TRANSACTION_BY_PHONE,
    storeId,
    locationId,
    couponId,
    phone,
  };
}

export function issueBarcodeCouponForProviderCustomer(
  customer,
  phone,
  couponId
) {
  return {
    type: ISSUE_BARCODE_COUPON_FOR_PROVIDER_CUSTOMER,
    customer,
    phone,
    couponId,
  };
}

export function issueBarcodeCouponWithStoreCustomerId(
  storeCustomerId,
  couponId,
  locationId,
  campaignId
) {
  return {
    type: ISSUE_BARCODE_COUPON_WITH_STORE_CUSTOMER_ID,
    storeCustomerId,
    couponId,
    locationId,
    campaignId,
  };
}

export function getCoupons(storeId) {
  return {
    type: GET_COUPONS,
    storeId,
  };
}

export function getCouponsForPublish(storeId) {
  return {
    type: GET_COUPONS_FOR_PUBLISH,
    storeId,
  };
}

export function createCoupon(coupon) {
  return {
    type: CREATE_COUPON,
    coupon,
  };
}

export function updateCoupon(coupon) {
  return {
    type: UPDATE_COUPON,
    coupon,
  };
}

export function deleteCoupon(coupon) {
  return {
    type: DELETE_COUPON,
    coupon,
  };
}

export function deleteCoupons(coupons) {
  return {
    type: DELETE_COUPONS,
    coupons,
  };
}

export function receiveCoupons(coupons) {
  return {
    type: RECEIVE_COUPONS,
    coupons,
  };
}

export function receiveCoupon(coupon) {
  return {
    type: RECEIVE_COUPON,
    coupon,
  };
}

export function receiveBarcodeCoupons(coupons, override) {
  return {
    type: RECEIVE_BARCODE_COUPONS,
    coupons,
    override,
  };
}

export function receiveDeletedCoupon(couponId) {
  return {
    type: RECEIVE_DELETED_COUPON,
    couponId,
  };
}

export function createCouponTransactions(coupon, transactions) {
  return {
    type: CREATE_COUPON_TRANSACTIONS,
    coupon,
    transactions,
  };
}

export function receiveBarcodeCouponTransaction(transaction) {
  return {
    type: RECEIVE_BARCODE_COUPON_TRANSACTION,
    transaction,
  };
}

export function clearBarcodeCouponTransaction() {
  return {
    type: CLEAR_BARCODE_COUPON_TRANSACTION,
  };
}

export function receiveBarcodeCouponTransactions(
  transactions,
  nextToken = null
) {
  return {
    type: RECEIVE_BARCODE_COUPON_TRANSACTIONS,
    transactions,
    nextToken,
  };
}

export function downloadCsvForTransactions(coupon) {
  return {
    type: DOWNLOAD_CSV_FOR_TRANSACTIONS,
    coupon,
  };
}

export function getBarcodeCouponTransaction(transactionId) {
  return {
    type: GET_BARCODE_COUPON_TRANSACTION,
    transactionId,
  };
}

export function getProviderCoupons(storeId) {
  return {
    type: GET_PROVIDER_COUPONS,
    storeId,
  };
}

export function receiveProviderCoupons(coupons) {
  return {
    type: RECEIVE_PROVIDER_COUPONS,
    coupons,
  };
}

export function receiveProviderCoupon(coupon) {
  return {
    type: RECEIVE_PROVIDER_COUPON,
    coupon,
  };
}

export function setCouponStatusSuccess() {
  return {
    type: SET_COUPON_STATUS_SUCCESS,
  };
}

export function setCouponStatusFailure(error) {
  return {
    type: SET_COUPON_STATUS_FAILURE,
    error,
  };
}

export function updateStoreMenuPlan(menuPlanId) {
  return {
    type: UPDATE_STORE_MENUPLAN,
    menuPlanId,
  };
}

export function createOrUpdateProduct(
  product,
  histories = null,
  modifierOrdering = false
) {
  return {
    type: CREATE_OR_UPDATE_PRODUCT,
    product,
    histories,
    modifierOrdering,
  };
}

export function deleteProduct(id) {
  return {
    type: DELETE_PRODUCT,
    id,
  };
}

export function createOrUpdateCategory(category) {
  return {
    type: CREATE_OR_UPDATE_CATEGORY,
    category,
  };
}

export function deleteCategory(id) {
  return {
    type: DELETE_CATEGORY,
    id,
  };
}

export function createOrUpdateModifier(modifier, soldoutIds) {
  return {
    type: CREATE_OR_UPDATE_MODIFIER,
    modifier,
    soldoutIds,
  };
}

export function deleteModifier(id) {
  return {
    type: DELETE_MODIFIER,
    id,
  };
}

export function updateProducts(products) {
  return {
    type: UPDATE_PRODUCTS,
    products,
  };
}

export function getInventoryHistoriesByStore(
  page,
  size,
  historyType,
  filters,
  override = true
) {
  return {
    type: GET_INVENTORY_HISTORIES_BY_STORE,
    page,
    size,
    historyType,
    filters,
    override,
  };
}

export function downloadInventoryHistories(historyType, filters) {
  return {
    type: DOWNLOAD_INVENTORY_HISTORIES,
    historyType,
    filters,
  };
}

export function getInventoryHistoriesByLocation(locationId, start, end) {
  return {
    type: GET_INVENTORY_HISTORIES_BY_LOCATION,
    locationId,
    start,
    end,
  };
}

export function getInventoryHistoriesByGroupId(
  history,
  page,
  size,
  filters = null
) {
  return {
    type: GET_INVENTORY_HISTORIES_BY_GROUP_ID,
    history,
    page,
    size,
    filters,
  };
}

export function getSkuInventoryHistories(startDate, endDate, skuId, filters) {
  return {
    type: GET_SKU_INVENTORY_HISTORIES,
    startDate,
    endDate,
    skuId,
    filters,
  };
}

export function getInventoryHistorySummary(startDate, endDate, filters) {
  return {
    type: GET_INVENTORY_HISTORY_SUMMARY,
    startDate,
    endDate,
    filters,
  };
}

export function setInventoryStatusSuccess() {
  return {
    type: SET_INVENTORY_STATUS_SUCCESS,
  };
}

export function setInventoryStatusFailure(error) {
  return {
    type: SET_INVENTORY_STATUS_FAILURE,
    error,
  };
}

export function receiveInventoryHistories(list, count, override) {
  return {
    type: RECEIVE_INVENTORY_HISTORIES,
    list,
    count,
    override,
  };
}

export function receiveInventoryHistory(history, count) {
  return {
    type: RECEIVE_INVENTORY_HISTORY,
    history,
    count,
  };
}

export function receiveSkuInventoryHistories(list, prevQuantity) {
  return {
    type: RECEIVE_SKU_INVENTORY_HISTORIES,
    list,
    prevQuantity,
  };
}

export function receiveInventoryHistorySummary(list) {
  return {
    type: RECEIVE_INVENTORY_HISTORY_SUMMARY,
    list,
  };
}

export function clearInventoryHistories() {
  return {
    type: CLEAR_INVENTORY_HISTORIES,
  };
}

export function clearInventoryHistorySummary() {
  return {
    type: CLEAR_INVENTORY_HISTORY_SUMMARY,
  };
}

export function receiveItemInventoryHistories(histories) {
  return {
    type: RECEIVE_ITEM_INVENTORY_HISTORIES,
    histories,
  };
}

export function setUpdateItemsSuccess() {
  return {
    type: UPDATE_ITEMS_SUCCESS,
  };
}

export function setUpdateItemsFailure(error) {
  return {
    type: UPDATE_ITEMS_FAILURE,
    error,
  };
}

export function adjustInventories(adjustments) {
  return {
    type: ADJUST_INVENTORIES,
    adjustments,
  };
}

export function updateInventoryHistory(historyId, quantity, cost, expiryDate) {
  return {
    type: UPDATE_INVENTORY_HISTORY,
    historyId,
    quantity,
    cost,
    expiryDate,
  };
}

export function refreshInventoryHistory(page, size, historyType, filters) {
  return {
    type: REFRESH_INVENTORY_HISTORY,
    page,
    size,
    historyType,
    filters,
  };
}

export function updateSkuLocations(locations) {
  return {
    type: UPDATE_SKU_LOCATIONS,
    locations,
  };
}

export function createInventoryTransferHistory(history) {
  return {
    type: CREATE_INVENTORY_TRANSFER_HISTORY,
    history,
  };
}

export function updateInventoryTransferHistory(history) {
  return {
    type: UPDATE_INVENTORY_TRANSFER_HISTORY,
    history,
  };
}

export function deleteInventoryTransferHistory(historyId) {
  return {
    type: DELETE_INVENTORY_TRANSFER_HISTORY,
    historyId,
  };
}

export function completeInventoryTransferHistory(history) {
  return {
    type: COMPLETE_INVENTORY_TRANSFER_HISTORY,
    history,
  };
}

export function receiveInventoryTransferHistories(
  list,
  count,
  override = true
) {
  return {
    type: RECEIVE_INVENTORY_TRANSFER_HISTORIES,
    list,
    count,
    override,
  };
}

export function receiveDeletedTransferHistory(history) {
  return {
    type: RECEIVE_DELETED_TRANSFER_HISTORY,
    history,
  };
}

export function getInventoryTransferHistories(
  page,
  size,
  filters,
  override = true
) {
  return {
    type: GET_INVENTORY_TRANSFER_HISTORIES,
    page,
    size,
    filters,
    override,
  };
}

export function getInventoryCountHistories(
  page,
  size,
  filters,
  overide = true
) {
  return {
    type: GET_INVENTORY_COUNT_HISTORIES,
    page,
    size,
    filters,
    overide,
  };
}

export function receiveCountHistories(list, count, overide = true) {
  return {
    type: RECEIVE_COUNT_HISTORIES,
    list,
    count,
    overide,
  };
}

export function receiveInventoryTransferHistory(history) {
  return {
    type: RECEIVE_INVENTORY_TRANSFER_HISTORY,
    history,
  };
}

export function createSkuInventory(productId, skuId, inventory) {
  return {
    type: CREATE_SKU_INVENTORY,
    productId,
    skuId,
    inventory,
  };
}

export function updateSkuInventory(inventory) {
  return {
    type: UPDATE_SKU_INVENTORY,
    inventory,
  };
}

export function deleteSkuInventory(inventory) {
  return {
    type: DELETE_SKU_INVENTORY,
    inventory,
  };
}

export function uploadItemTemplate(storeId, key, locationId, excelType) {
  return {
    type: UPLOAD_ITEM_TEMPLATE,
    storeId,
    key,
    locationId,
    excelType,
  };
}

export function syncItemTemplate(storeId, excelType, key = null) {
  return {
    type: SYNC_ITEM_TEMPLATE,
    storeId,
    key,
    excelType,
  };
}

export function uploadInventoryTemplate(
  storeId,
  employeeId,
  key,
  reason,
  standardType
) {
  return {
    type: UPLOAD_INVENTORY_TEMPLATE,
    storeId,
    employeeId,
    key,
    standardType,
    reason,
  };
}

export function generateInventoryTemplate(
  storeId,
  locationId,
  reason,
  standardType
) {
  return {
    type: GENERATE_INVENTORY_TEMPLATE,
    storeId,
    locationId,
    standardType,
    reason,
  };
}

export function syncInventoryTemplate(
  storeId,
  reason,
  standardType,
  key = null
) {
  return {
    type: SYNC_INVENTORY_TEMPLATE,
    storeId,
    standardType,
    reason,
    key,
  };
}

export function parseItemTemplateSuccess(data) {
  return {
    type: PARSE_ITEM_TEMPLATE_SUCCESS,
    data,
  };
}

export function syncItemTemplateSuccess() {
  return {
    type: SYNC_ITEM_TEMPLATE_SUCCESS,
  };
}

export function initGenerateInventoryTemplate() {
  return {
    type: INIT_GENERATE_INVENTORY_TEMPLATE,
  };
}

export function generateItemTemplateSuccess() {
  return {
    type: GENERATE_ITEM_TEMPLATE_SUCCESS,
  };
}

export function parseItemTemplateFailure(error) {
  return {
    type: PARSE_ITEM_TEMPLATE_FAILURE,
    error: error,
  };
}

export function syncItemTemplateFailure(error) {
  return {
    type: SYNC_ITEM_TEMPLATE_FAILURE,
    error: error,
  };
}

export function generateItemTemplateFailure(error) {
  return {
    type: GENERATE_ITEM_TEMPLATE_FAILURE,
    error: error,
  };
}

export function resetError() {
  return {
    type: RESET_ERROR,
  };
}

export function getItems(storeId) {
  return {
    type: GET_ITEMS,
    storeId,
  };
}

export function getProduct(productId) {
  return {
    type: GET_PRODUCT,
    productId,
  };
}

export function setGetItemsSuccess() {
  return {
    type: GET_ITEMS_SUCCESS,
  };
}

export function setGetItemsFailure(error) {
  return {
    type: GET_ITEMS_FAILURE,
    error,
  };
}

export function receiveItems(items) {
  return {
    type: RECEIVE_ITEMS,
    items,
  };
}

export function receiveItem(item) {
  return {
    type: RECEIVE_ITEM,
    item,
  };
}

export function updateActiveChangedProducts(products) {
  return {
    type: UPDATE_ACTIVE_CHANGED_PRODUCTS,
    products,
  };
}

export function shareHistoryPdf(history, _type) {
  return {
    type: SHARE_HISTORY_PDF,
    history,
    _type,
  };
}

export function shareGroupHistoryPdf(history, _type) {
  return {
    type: SHARE_GROUP_HISTORY_PDF,
    history,
    _type,
  };
}

export function sharePurchaseOrderPdf(purchaseOrder) {
  return {
    type: SHARE_PURCHASE_ORDER_PDF,
    purchaseOrder,
  };
}

export function setSharePdfSuccess() {
  return {
    type: SET_SHARE_PDF_SUCCESS,
  };
}

export function setSharePdfFailure(error) {
  return {
    type: SET_SHARE_PDF_FAILURE,
    error,
  };
}

export function lanunchMultiLocation(storeId) {
  return {
    type: LAUNCH_MULTI_LOCATION,
    storeId,
  };
}

export function createOrUpdateMenuPlan(plan, isApply) {
  return {
    type: CREATE_OR_UPDATE_MENU_PLAN,
    plan,
    isApply,
  };
}

export function deleteMenuPlan(plan) {
  return {
    type: DELETE_MENU_PLAN,
    plan,
  };
}

export function createOrUpdateOrderPlan(plan, isApply) {
  return {
    type: CREATE_OR_UPDATE_ORDER_PLAN,
    plan,
    isApply,
  };
}

export function deleteOrderPlan(plan) {
  return {
    type: DELETE_ORDER_PLAN,
    plan,
  };
}

export function inviteEmployee(employee, resend = false) {
  return {
    type: INVITE_EMPLOYEE,
    employee,
    resend,
  };
}

export function confirmInviteEmployee(user) {
  return {
    type: CONFIRM_INVITE_EMPLOYEE,
    user,
  };
}

export function getPointTransactions(storeId, start, end, nextToken = null) {
  return {
    type: GET_POINT_TRANSACTIONS,
    storeId,
    start,
    end,
    nextToken,
  };
}

export function receivePointTransactions(transactions) {
  return {
    type: RECEIVE_POINT_TRANSACTIONS,
    transactions,
  };
}

export function setGetPointTransactionsSuccess() {
  return {
    type: GET_POINT_TRANSACTIONS_SUCCESS,
  };
}

export function setGetPointTransactionsFailure(error) {
  return {
    type: GET_POINT_TRANSACTIONS_FAILURE,
    error,
  };
}

export function activeKioskMode() {
  return {
    type: ACTIVE_KIOSK_MODE,
  };
}

export function inactiveKioskMode() {
  return {
    type: INACTIVE_KIOSK_MODE,
  };
}

export function updateReceiptConfig(config) {
  return {
    type: UPDATE_RECEIPT_CONFIG,
    config,
  };
}

export function updateViewConfig(config) {
  return {
    type: UPDATE_VIEW_CONFIG,
    config,
  };
}

export function receiveStoreContract(storeContract) {
  return {
    type: RECEIVE_STORE_CONTRACT,
    storeContract,
  };
}

export function getLabelTemplates(storeId) {
  return {
    type: GET_LABEL_TEMPLATES,
    storeId,
  };
}

export function createOrUpdateLabelTemplate(template) {
  return {
    type: CREATE_OR_UPDATE_LABEL_TEMPLATE,
    template,
  };
}

export function deleteLabelTemplate(id) {
  return {
    type: DELETE_LABEL_TEMPLATE,
    id,
  };
}

export function receiveLabelTemplate(template) {
  return {
    type: RECEIVE_LABEL_TEMPLATE,
    template,
  };
}

export function receiveLabelTemplates(templates) {
  return {
    type: RECEIVE_LABEL_TEMPLATES,
    templates,
  };
}

export function receiveDeletedLabelTemplate(id) {
  return {
    type: RECEIVE_DELETED_LABEL_TEMPLATE,
    id,
  };
}

export function setTemplateStatusSuccess() {
  return {
    type: SET_TEMPLATE_STATUS_SUCCESS,
  };
}

export function setTemplateStatusFailure(error) {
  return {
    type: SET_TEMPLATE_STATUS_FAILURE,
    error,
  };
}

export function updateSettings(settings) {
  return {
    type: UPDATE_SETTINGS,
    settings,
  };
}

export function convertPosMode(newMode) {
  return {
    type: CONVERT_POS_MODE,
    newMode,
  };
}

export function syncExternalLoyalty(storeId) {
  return {
    type: SYNC_EXTERNAL_LOYALTY,
    storeId,
  };
}

export function chargeCredit(amount, quantity) {
  return {
    type: CHARGE_CREDIT,
    amount,
    quantity,
  };
}

export function getCreditStatus() {
  return {
    type: GET_CREDIT_STATUS,
  };
}

export function receiveCreditStatus(credit) {
  return {
    type: RECEIVE_CREDIT_STATUS,
    credit,
  };
}

export function updateCreditConfig(config) {
  return {
    type: UPDATE_CREDIT_CONFIG,
    config,
  };
}

export function getCreditHistories(page, size, filters, overide) {
  return {
    type: GET_CREDIT_HISTORIES,
    page,
    size,
    filters,
    overide,
  };
}

export function receiveCreditHistories(items, count, overide) {
  return {
    type: RECEIVE_CREDIT_HISTORIES,
    items,
    count,
    overide,
  };
}

export function clearCreditHistories() {
  return {
    type: CLEAR_CREDIT_HISTORIES,
  };
}

export function activeReviewCampaign() {
  return {
    type: ACTIVE_REVIEW_CAMPAIGN,
  };
}

export function inactiveReviewCampaign() {
  return {
    type: INACTIVE_REVIEW_CAMPAIGN,
  };
}

export function getLoyaltySummary(storeId) {
  return {
    type: GET_LOYALTY_SUMMARY,
    storeId,
  };
}

export function receiveLoyaltySummary(summary) {
  return {
    type: RECEIVE_LOYALTY_SUMMARY,
    summary,
  };
}

export function setGetLoyaltySummarySuccess() {
  return {
    type: GET_LOYALTY_SUMMARY_SUCCESS,
  };
}

export function setGetLoyaltySummaryFailure(error) {
  return {
    type: GET_LOYALTY_SUMMARY_FAILURE,
    error,
  };
}

export function getSalesSummary(storeId) {
  return {
    type: GET_SALES_SUMMARY,
    storeId,
  };
}

export function receiveSalesSummary(summary) {
  return {
    type: RECEIVE_SALES_SUMMARY,
    summary,
  };
}

export function setGetSalesSummarySuccess() {
  return {
    type: GET_SALES_SUMMARY_SUCCESS,
  };
}

export function setGetSalesSummaryFailure(error) {
  return {
    type: GET_SALES_SUMMARY_FAILURE,
    error,
  };
}

export function getOrderReport(
  storeId,
  locationId,
  start,
  end,
  item = null,
  basis
) {
  return {
    type: GET_ORDER_REPORT,
    storeId,
    locationId,
    start,
    end,
    item,
    basis,
  };
}

export function receiveOrderReport(report) {
  return {
    type: RECEIVE_ORDER_REPORT,
    report,
  };
}

export function receiveCustomerReport(report) {
  return {
    type: RECEIVE_CUSTOMER_REPORT,
    report,
  };
}

export function setGetOrderReportSuccess() {
  return {
    type: GET_ORDER_REPORT_SUCCESS,
  };
}

export function setGetOrderReportFailure(error) {
  return {
    type: GET_ORDER_REPORT_FAILURE,
    error,
  };
}

export function getChannelOrderReport(
  storeId,
  locationId,
  start,
  end,
  item = null,
  basis,
  origin
) {
  return {
    type: GET_CHANNEL_ORDER_REPORT,
    storeId,
    locationId,
    start,
    end,
    item,
    basis,
    origin,
  };
}

export function receiveChannelOrderReport(report) {
  return {
    type: RECEIVE_CHANNEL_ORDER_REPORT,
    report,
  };
}

export function setChannelOrderReportSuccess() {
  return {
    type: SET_CHANNEL_ORDER_REPORT_SUCCESS,
  };
}

export function setChannelOrderReportFailure(error) {
  return {
    type: SET_CHANNEL_ORDER_REPORT_FAILURE,
    error,
  };
}

export function getDeliveryReport(
  storeId,
  locationId,
  start,
  end,
  item = null,
  basis
) {
  return {
    type: GET_DELIVERY_REPORT,
    storeId,
    locationId,
    start,
    end,
    item,
    basis,
  };
}

export function receiveDeliveryReport(report) {
  return {
    type: RECEIVE_DELIVERY_REPORT,
    report,
  };
}

export function setGetDeliveryReportSuccess() {
  return {
    type: GET_DELIVERY_REPORT_SUCCESS,
  };
}

export function setGetDeliveryReportFailure(error) {
  return {
    type: GET_DELIVERY_REPORT_FAILURE,
    error,
  };
}

export function getTrsReport(storeId, locationId, start, end) {
  return {
    type: GET_TRS_REPORT,
    storeId,
    locationId,
    start,
    end,
  };
}

export function receiveTrsReport(report) {
  return {
    type: RECEIVE_TRS_REPORT,
    report,
  };
}

export function receiveTrsItemReport(report) {
  return {
    type: RECEIVE_TRS_ITEM_REPORT,
    report,
  };
}

export function setTrsReportSuccess() {
  return {
    type: SET_TRS_REPORT_SUCCESS,
  };
}

export function setTrsReportFailure(error) {
  return {
    type: SET_TRS_REPORT_FAILURE,
    error,
  };
}

export function getPointReport(storeId, locationId, start, end, basis) {
  return {
    type: GET_POINT_REPORT,
    storeId,
    locationId,
    start,
    end,
    basis,
  };
}

export function receivePointReport(report) {
  return {
    type: RECEIVE_POINT_REPORT,
    report,
  };
}

export function setGetPointReportSuccess() {
  return {
    type: GET_POINT_REPORT_SUCCESS,
  };
}

export function setGetPointReportFailure(error) {
  return {
    type: GET_POINT_REPORT_FAILURE,
    error,
  };
}

export function getLoyaltyReport1(storeId, locationId, start, end, basis) {
  return {
    type: GET_LOYALTY_REPORT1,
    storeId,
    locationId,
    start,
    end,
    basis,
  };
}

export function receiveLoyaltyReport1(report) {
  return {
    type: RECEIVE_LOYALTY_REPORT1,
    report,
  };
}

export function setLoyaltyReport1Success() {
  return {
    type: GET_LOYALTY_REPORT1_SUCCESS,
  };
}

export function setLoyaltyReport1Failure(error) {
  return {
    type: GET_LOYALTY_REPORT1_FAILURE,
    error,
  };
}

export function getReviewReport(storeId, locationId, start, end) {
  return {
    type: GET_REVIEW_REPORT,
    storeId,
    locationId,
    start,
    end,
  };
}

export function receiveReviewReport(report) {
  return {
    type: RECEIVE_REVIEW_REPORT,
    report,
  };
}

export function setGetReviewReportSuccess() {
  return {
    type: GET_REVIEW_REPORT_SUCCESS,
  };
}

export function setGetReviewReportFailure(error) {
  return {
    type: GET_REVIEW_REPORT_FAILURE,
    error,
  };
}

export function getInventoryDateReport(storeId, locationId, start, end) {
  return {
    type: GET_INVENTORY_DATE_REPORT,
    storeId,
    locationId,
    start,
    end,
  };
}

export function getInventoryItemReport(storeId, locationId, start, end) {
  return {
    type: GET_INVENTORY_ITEM_REPORT,
    storeId,
    locationId,
    start,
    end,
  };
}

export function getInventoryTurnoverReport(storeId, locationId, start, end) {
  return {
    type: GET_INVENTORY_TURNOVER_REPORT,
    storeId,
    locationId,
    start,
    end,
  };
}

export function getInventoryAssetReport(storeId, locationId, start, end) {
  return {
    type: GET_INVENTORY_ASSET_REPORT,
    storeId,
    locationId,
    start,
    end,
  };
}

export function setInventoryReportStatusSuccess() {
  return {
    type: SET_INVENTORY_REPORT_STATUS_SUCCESS,
  };
}

export function setInventoryReportStatusFailure(error) {
  return {
    type: SET_INVENTORY_REPORT_STATUS_FAILURE,
    error,
  };
}

export function receiveInventoryReport(report) {
  return {
    type: RECEIVE_INVENTORY_REPORT,
    report,
  };
}

export function getCustomers(
  page,
  size,
  sort,
  filters = null,
  search = null,
  override = true,
  filterLocationId
) {
  return {
    type: GET_CUSTOMERS,
    page,
    size,
    sort,
    filters,
    search,
    filterLocationId,
    override,
  };
}

export function getProviderCustomer(
  storeId,
  locationId,
  filter = null,
  withOrders = false
) {
  return {
    type: GET_PROVIDER_CUSTOMER,
    storeId,
    locationId,
    filter,
    withOrders,
  };
}

export function getCustomersByTag(
  storeId,
  page,
  size,
  sort = null,
  filters = null,
  search = null,
  override = false
) {
  return {
    type: GET_CUSTOMERS_BY_TAG,
    storeId,
    page,
    size,
    sort,
    filters,
    search,
    override,
  };
}

export function clearCustomersByTag() {
  return {
    type: CLEAR_CUSTOMERS_BY_TAG,
  };
}

export function getCustomersStats(
  storeId,
  page,
  size,
  filters = null,
  search = null,
  override = true
) {
  return {
    type: GET_CUSTOMERS_STATS,
    storeId,
    page,
    size,
    filters,
    search,
    override,
  };
}

export function getCorporateCustomers(
  storeId,
  page,
  size,
  filters = null,
  search = null
) {
  return {
    type: GET_CORPORATE_CUSTOMERS,
    storeId,
    page,
    size,
    filters,
    search,
  };
}

export function getRegisteredCustomerCount(storeId, locationId) {
  return {
    type: GET_REGISTERED_CUSTOMER_COUNT,
    storeId,
    locationId,
  };
}

export function receiveCustomers(customers, count, override = true) {
  return {
    type: RECEIVE_CUSTOMERS,
    customers,
    count,
    override,
  };
}

export function clearCustomers() {
  return {
    type: CLEAR_CUSTOMERS,
  };
}

export function receiveCustomersByTag(customers, count, override) {
  return {
    type: RECEIVE_CUSTOMERS_BY_TAG,
    customers,
    count,
    override,
  };
}

export function searchProviderCustomer(searchText) {
  return {
    type: SEARCH_PROVIDER_CUSTOMER,
    searchText: searchText,
  };
}

export function setGetCustomersSuccess() {
  return {
    type: GET_CUSTOMERS_SUCCESS,
  };
}

export function setGetCustomersFailure(error) {
  return {
    type: GET_CUSTOMERS_FAILURE,
    error,
  };
}

export function getCustomer(id, locationId = null) {
  return {
    type: GET_CUSTOMER,
    id,
    locationId,
  };
}

export function receiveCustomer(customer) {
  return {
    type: RECEIVE_CUSTOMER,
    customer,
  };
}

export function retrieveCustomer(id, withStat = true) {
  return {
    type: RETRIEVE_CUSTOMER,
    id,
    withStat,
  };
}

export function retrieveCustomerWithPoint(start, end) {
  return {
    type: RETRIEVE_CUSTOMER_WITH_POINT,
    start,
    end,
  };
}

export function retrieveCustomerWithOrder(start, end) {
  return {
    type: RETRIEVE_CUSTOMER_WITH_ORDER,
    start,
    end,
  };
}

export function retrieveCustomerWithCredit(start, end) {
  return {
    type: RETRIEVE_CUSTOMER_WITH_CREDIT,
    start,
    end,
  };
}

export function retrieveCustomerWithCoupon() {
  return {
    type: RETRIEVE_CUSTOMER_WITH_COUPON,
  };
}

export function receiveRetrievedCustomer(customer) {
  return {
    type: RECEIVE_RETRIEVED_CUSTOMER,
    customer,
  };
}

export function clearRetrievedCustomer() {
  return {
    type: CLEAR_RETRIEVED_CUSTOMER,
  };
}

export function receiveProviderCustomer(customer) {
  return {
    type: RECEIVE_PROVIDER_CUSTOMER,
    customer,
  };
}

export function receiveProviderCustomers(customers, override = false) {
  return {
    type: RECEIVE_PROVIDER_CUSTOMERS,
    customers,
    override,
  };
}

export function setGetCustomerSuccess() {
  return {
    type: GET_CUSTOMER_SUCCESS,
  };
}

export function setGetCustomerFailure(error) {
  return {
    type: GET_CUSTOMER_FAILURE,
    error,
  };
}

export function receiveCustomersStats(customers, count, override) {
  return {
    type: RECIEVE_CUSTOMERS_STATS,
    customers,
    count,
    override,
  };
}

export function createOrUpdateCustomerGroup(group) {
  return {
    type: CREATE_OR_UPDATE_CUSTOMER_GROUP,
    group,
  };
}

export function deleteCustomerGroup(groupId) {
  return {
    type: DELETE_CUSTOMER_GROUP,
    groupId,
  };
}

export function getCustomerGroups(storeId, locationId) {
  return {
    type: GET_CUSTOMER_GROUPS,
    storeId,
    locationId,
  };
}

export function getCustomerGroupsWithCount(storeId, locationId) {
  return {
    type: GET_CUSTOMER_GROUPS_WITH_COUNT,
    storeId,
    locationId,
  };
}

export function receiveCustomerGroups(groups) {
  return {
    type: RECEIVE_CUSTOMER_GROUPS,
    groups,
  };
}

export function setGetCustomerGroupsSuccess() {
  return {
    type: GET_CUSTOMER_GROUPS_SUCCESS,
  };
}

export function setGetCustomerGroupsFailure(error) {
  return {
    type: GET_CUSTOMER_GROUPS_FAILURE,
    error,
  };
}

export function createOrUpdateCustomerTag(tag) {
  return {
    type: CREATE_OR_UPDATE_CUSTOMER_TAG,
    tag,
  };
}

export function updateCustomerTags(tags) {
  return {
    type: UPDATE_CUSTOMER_TAGS,
    tags,
  };
}

export function deleteCustomerTag(tagId) {
  return {
    type: DELETE_CUSTOMER_TAG,
    tagId,
  };
}

export function createOrUpdateStoreAttributes(attributes) {
  return {
    type: CREATE_OR_UPDATE_STORE_ATTRIBUTES,
    attributes,
  };
}

export function getCustomerTags(storeId, locationId = null, override = false) {
  return {
    type: GET_CUSTOMER_TAGS,
    storeId,
    locationId,
    override,
  };
}

export function getCustomerTagsWithCount(storeId, locationId = null) {
  return {
    type: GET_CUSTOMER_TAGS_WITH_COUNT,
    storeId,
    locationId,
  };
}

export function receiveCustomerTags(tags, override) {
  return {
    type: RECEIVE_CUSTOMER_TAGS,
    tags,
    override,
  };
}

export function receiveCustomerTag(tag) {
  return {
    type: RECEIVE_CUSTOMER_TAG,
    tag,
  };
}

export function receiveDeletedCustomerTag(tagId) {
  return {
    type: RECEIVE_DELETED_CUSTOMER_TAG,
    tagId,
  };
}

export function setCustomerTagStatusSuccess() {
  return {
    type: SET_CUSTOMER_TAG_STATUS_SUCCESS,
  };
}

export function setCustomerTagStatusFailure(error) {
  return {
    type: SET_CUSTOMER_TAG_STATUS_FAILURE,
    error,
  };
}

export function createCustomer(customer) {
  return {
    type: CREATE_CUSTOMER,
    customer,
  };
}

export function setCreateCustomerSuccess() {
  return {
    type: CREATE_CUSTOMER_SUCCESS,
  };
}

export function setCreateCustomerFailure(error) {
  return {
    type: CREATE_CUSTOMER_FAILURE,
    error,
  };
}

export function updateCustomer(customer) {
  return {
    type: UPDATE_CUSTOMER,
    customer,
  };
}

export function setUpdateCustomerSuccess() {
  return {
    type: UPDATE_CUSTOMER_SUCCESS,
  };
}

export function setUpdateCustomerFailure(error) {
  return {
    type: UPDATE_CUSTOMER_FAILURE,
    error,
  };
}

export function deleteCustomer(customer) {
  return {
    type: DELETE_CUSTOMER,
    customer,
  };
}

export function setDeleteCustomerSuccess() {
  return {
    type: DELETE_CUSTOMER_SUCCESS,
  };
}

export function setDeleteCustomerFailure(error) {
  return {
    type: DELETE_CUSTOMER_FAILURE,
    error,
  };
}

export function setGetCustomerStatsSuccess() {
  return {
    type: GET_CUSTOMERS_STATS_SUCCESS,
  };
}

export function setGetCustomerStatsFailure(error) {
  return {
    type: GET_CUSTOMERS_STATS_FAILURE,
    error,
  };
}

export function setGetRegisteredCustomerCountSuccess() {
  return {
    type: GET_REGISTERED_CUSTOMER_COUNT_SUCCESS,
  };
}

export function setGetRegisteredCustomerCountFailure(error) {
  return {
    type: GET_REGISTERED_CUSTOMER_COUNT_FAILURE,
    error,
  };
}

export function receiveDeletedCustomer(customerId) {
  return {
    type: RECEIVE_DELETED_CUSTOMER,
    customerId,
  };
}

export function getSalesReport(
  storeId,
  item,
  start,
  end,
  locationId = null,
  locationIds = null,
  basis
) {
  return {
    type: GET_SALES_REPORT,
    storeId,
    item,
    start,
    end,
    locationId,
    locationIds,
    basis,
  };
}

export function receiveSalesReport(report) {
  return {
    type: RECEIVE_SALES_REPORT,
    report,
  };
}

export function setGetSalesReportSuccess() {
  return {
    type: GET_SALES_REPORT_SUCCESS,
  };
}

export function setGetSalesReportFailure(error) {
  return {
    type: GET_SALES_REPORT_FAILURE,
    error,
  };
}

export function getItemsReport(
  storeId,
  start,
  end,
  locationId,
  locationIds,
  basis
) {
  return {
    type: GET_ITEMS_REPORT,
    storeId,
    start,
    end,
    locationId,
    locationIds,
    basis,
  };
}

export function receiveItemsReport(report) {
  return {
    type: RECEIVE_ITEMS_REPORT,
    report,
  };
}

export function setItemsReportSuccess() {
  return {
    type: SET_ITEMS_REPORT_SUCCESS,
  };
}

export function setItemsReportFailure(error) {
  return {
    type: SET_ITEMS_REPORT_FAILURE,
    error,
  };
}

export function getSalesHistories(storeId, locationId, start, end) {
  return {
    type: GET_SALES_HISTORIES,
    storeId,
    locationId,
    start,
    end,
  };
}

export function receiveSalesHistories(histories) {
  return {
    type: RECEIVE_SALES_HISTORIES,
    histories,
  };
}

export function setGetSalesHistoriesSuccess() {
  return {
    type: GET_SALES_HISTORIES_SUCCESS,
  };
}

export function setGetSalesHistoriesFailure(error) {
  return {
    type: GET_SALES_HISTORIES_FAILURE,
    error,
  };
}

export function getOrder(orderId, withStore = false) {
  return {
    type: GET_ORDER,
    orderId,
    withStore,
  };
}

export function getOrderWithTransactions(orderId) {
  return {
    type: GET_ORDER_WITH_TRANSACTIONS,
    orderId,
  };
}

export function setGetOrderSuccess() {
  return {
    type: GET_ORDER_SUCCESS,
  };
}

export function setGetOrderFailure(error) {
  return {
    type: GET_ORDER_FAILURE,
    error,
  };
}

export function getOrdersByStore(storeId, start, end, nextToken = null) {
  return {
    type: GET_ORDERS_BY_STORE,
    storeId,
    start,
    end,
    nextToken,
  };
}

export function setGetOrdersSuccess() {
  return {
    type: GET_ORDERS_SUCCESS,
  };
}

export function setGetOrdersFailure(error) {
  return {
    type: GET_ORDERS_FAILURE,
    error,
  };
}

export function updateOrder(order) {
  return {
    type: UPDATE_ORDER,
    order,
  };
}

export function updateOrders(orders) {
  return {
    type: UPDATE_ORDERS,
    orders,
  };
}

export function setUpdateOrderSuccess() {
  return {
    type: UPDATE_ORDER_SUCCESS,
  };
}

export function setUpdateOrderFailure(error) {
  return {
    type: UPDATE_ORDER_FAILURE,
    error,
  };
}

export function receiveOrder(order) {
  return {
    type: RECEIVE_ORDER,
    order,
  };
}

export function receiveOrders(orders) {
  return {
    type: RECEIVE_ORDERS,
    orders,
  };
}

export function getOrderHistories(storeId, start, end, nextToken = null) {
  return {
    type: GET_ORDER_HISTORIES,
    storeId,
    start,
    end,
    nextToken,
  };
}

export function getAllOrderHistories(storeId, locationId, start, end, filters) {
  return {
    type: GET_ALL_ORDER_HISTORIES,
    storeId,
    locationId,
    start,
    end,
    filters,
  };
}

export function getShippingOrders(locationId, start, end, filters) {
  return {
    type: GET_SHIPPING_ORDERS,
    locationId,
    start,
    end,
    filters,
  };
}

export function receiveOrderHistories(histories) {
  return {
    type: RECEIVE_ORDER_HISTORIES,
    histories,
  };
}

export function setGetOrderHistoriesSuccess() {
  return {
    type: GET_ORDER_HISTORIES_SUCCESS,
  };
}

export function setGetOrderHistoriesFailure(error) {
  return {
    type: GET_ORDER_HISTORIES_FAILURE,
    error,
  };
}

export function updateOrderFromReceipt(orderId, user) {
  return {
    type: UPDATE_ORDER_FROM_RECEIPT,
    orderId,
    user,
  };
}

export function getProviderOrders(filters, page, size) {
  return {
    type: GET_PROVIDER_ORDERS,
    filters,
    page,
    size,
  };
}

export function clearProviderOrders() {
  return {
    type: CLEAR_PROVIDER_ORDERS,
  };
}

export function receiveProviderOrders(orders) {
  return {
    type: RECEIVE_PROVIDER_ORDERS,
    orders,
  };
}

export function sendShippingNotice(order, receiver, option) {
  return {
    type: SEND_SHIPPING_NOTICE,
    order,
    receiver,
    option,
  };
}

// Terminal
export function getTerminals() {
  return {
    type: GET_TERMINALS,
  };
}

export function createOrUpdateTerminal(terminal) {
  return {
    type: CREATE_OR_UPDATE_TERMINAL,
    terminal,
  };
}

export function deleteTerminal(terminalId) {
  return {
    type: DELETE_TERMINAL,
    terminalId,
  };
}

export function receiveDeletedTerminal(terminalId) {
  return {
    type: RECEIVE_DELETED_TERMINAL,
    terminalId,
  };
}

export function setTerminalStatusSuccesss() {
  return {
    type: SET_TERMINAL_STATUS_SUCCESS,
  };
}

export function setTerminalStatusFailure() {
  return {
    type: SET_TERMINAL_STATUS_FAILURE,
  };
}

export function receiveTerminals(terminals) {
  return {
    type: RECEIVE_TERMINALS,
    terminals,
  };
}

export function findEmployeeByEmail(email) {
  return {
    type: FIND_EMPLOYEE_BY_EMAIL,
    email,
  };
}

export function receiveSearchedMgmtStores(stores) {
  return {
    type: RECEIVE_SEARCHED_MGMT_STORES,
    stores,
  };
}

export function getMgmtContract(employee) {
  return {
    type: GET_MGMT_CONTRACT,
    employee,
  };
}

export function receiveMgmtContract(contract) {
  return {
    type: RECEIVE_MGMT_CONTRACT,
    contract,
  };
}

export function receiveMgmtLocations(locations) {
  return {
    type: RECEIVE_MGMT_LOCATIONS,
    locations,
  };
}

export function clearMgmtLocations() {
  return {
    tyep: CLEAR_MGMT_LOCATIONS,
  };
}

export function setMgmtStatusSuccess() {
  return {
    type: SET_MGMT_STATUS_SUCCESS,
  };
}

export function setMgmtStatusFailure(error) {
  return {
    type: SET_MGMT_STATUS_FAILURE,
    error,
  };
}

export function receiveOperator(operator) {
  return {
    type: RECEIVE_OPERATOR,
    operator,
  };
}

export function receiveContract(contract) {
  return {
    type: RECEIVE_CONTRACT,
    contract,
  };
}

export function clearContract() {
  return {
    type: CLEAR_CONTRACT,
  };
}

export function clearOperator() {
  return {
    type: CLEAR_OPERATOR,
  };
}

export function clearAllContract() {
  return {
    type: CLEAR_ALL_CONTRACT,
  };
}

export function createOrUpdateContract(
  contract,
  operator,
  employee,
  isFranchise
) {
  return {
    type: CREATE_OR_UPDATE_CONTRACT,
    contract,
    operator,
    employee,
    isFranchise,
  };
}

export function receiveUpdatedContract(contract) {
  return {
    type: RECEIVE_UPDATED_CONTRACT,
    contract,
  };
}

export function createOrUpdateRentalContract(rentalContract) {
  return {
    type: CREATE_OR_UPDATE_RENTAL_CONTRACT,
    rentalContract,
  };
}

export function receiveRentals(rentalContracts) {
  return {
    type: RECEIVE_RENTALS,
    rentalContracts,
  };
}

export function receiveContractTerminal(terminal) {
  return {
    type: RECEIVE_CONTRACT_TERMINAL,
    terminal,
  };
}

export function receiveDeletedContractTerminalId(terminalId) {
  return {
    type: RECEIVE_DELETED_CONTRACT_TERMINAL_ID,
    terminalId,
  };
}

export function findStoresByOperatorId(businessOperatorId) {
  return {
    type: FIND_STORES_BY_OPERATOR_ID,
    businessOperatorId,
  };
}

export function receiveSearchedStoresByOperatorId(stores) {
  return {
    type: RECEIVE_SEARCHED_STORES_BY_OPERATOR_ID,
    stores,
  };
}

// Notice
export function getNotices(storeId) {
  return {
    type: GET_NOTICES,
    storeId,
  };
}

export function receiveNotices(notices) {
  return {
    type: RECEIVE_NOTICES,
    notices,
  };
}

export function getArchivedNotices(locationId) {
  return {
    type: GET_ARCHIVED_NOTICES,
    locationId,
  };
}

export function receiveArchivedNotices(notices) {
  return {
    type: RECEIVE_ARCHIVED_NOTICES,
    notices,
  };
}

export function getNoticeCampaigns(storeId) {
  return {
    type: GET_NOTICE_CAMPAIGNS,
    storeId,
  };
}

export function receiveNoticeCampaigns(campaigns) {
  return {
    type: RECEIVE_NOTICE_CAMPAIGNS,
    campaigns,
  };
}

export function deleteNoticeCampaign(campaign) {
  return {
    type: DELETE_NOTICE_CAMPAIGN,
    campaign,
  };
}

export function createOrUpdateNotice(notice, file = null) {
  return {
    type: CREATE_OR_UPDATE_NOTICE,
    notice,
    file,
  };
}

export function receiveNotice(notice) {
  return {
    type: RECEIVE_NOTICE,
    notice,
  };
}

export function createOrUpdateNoticeCampaign(noticeCampaign) {
  return {
    type: CREATE_OR_UPDATE_NOTICE_CAMPAIGN,
    noticeCampaign,
  };
}

export function receiveNoticeCampaign(noticeCampaign) {
  return {
    type: RECEIVE_NOTICE_CAMPAIGN,
    noticeCampaign,
  };
}

export function deleteNotice(notice) {
  return {
    type: DELETE_NOTICE,
    notice,
  };
}

export function receiveDeleteNotice(notice) {
  return {
    type: RECEIVE_DELETE_NOTICE,
    notice,
  };
}

export function receiveDeletedNoticeCampaign(campaign) {
  return {
    type: RECEIVE_DELETED_NOTICE_CAMPAIGN,
    campaign,
  };
}

export function setArticleStatusSuccess() {
  return {
    type: SET_ARTICLE_STATUS_SUCCESS,
  };
}

export function setArticleStatusFailure(error) {
  return { type: SET_ARTICLE_STATUS_FAILURE, error };
}

// Around market
export function signUpMarket(user, orderId = null) {
  return {
    type: SIGNUP_MARKET,
    user,
    orderId,
  };
}

export function signInMarket(id, password, orderId = null) {
  return {
    type: SIGNIN_MARKET,
    id,
    password,
    orderId,
  };
}

export function resetMarketPassword(ident, password) {
  return {
    type: RESET_MARKET_PASSWORD,
    ident,
    password,
  };
}

export function signOutMarket() {
  return {
    type: SIGNOUT_MARKET,
  };
}

export function setMarketStatusSuccess() {
  return {
    type: SET_MARKET_STATUS_SUCCESS,
  };
}

export function setMarketStatusFailure(error) {
  return {
    type: SET_MARKET_STATUS_FAILURE,
    error,
  };
}

export function setMarketStoreStatusSuccess() {
  return {
    type: SET_MARKET_STORE_STATUS_SUCCESS,
  };
}

export function setMarketStoreStatusFailure(error) {
  return {
    type: SET_MARKET_STORE_STATUS_FAILURE,
    error,
  };
}

export function setMarketOrderStatusSuccess() {
  return {
    type: SET_MARKET_ORDER_STATUS_SUCCESS,
  };
}

export function setMarketOrderStatusFailure(error) {
  return {
    type: SET_MARKET_ORDER_STATUS_FAILURE,
    error,
  };
}

export function receiveStore(store) {
  return {
    type: RECEIVE_STORE,
    store,
  };
}

export function clearStore() {
  return {
    type: CLEAR_STORE,
  };
}

export function getOrderStore(storeId) {
  return {
    type: GET_ORDER_STORE,
    storeId,
  };
}

export function setStoreFavorite(storeCustomer) {
  return {
    type: SET_STORE_FAVORITE,
    storeCustomer,
  };
}

export function loadStore(storeId, locationId) {
  return {
    type: LOAD_STORE,
    storeId,
    locationId,
  };
}

export function addItemToCart(item) {
  return {
    type: ADD_ITEM_TO_CART,
    item,
  };
}

export function setOrderType(orderType) {
  return {
    type: SET_ORDER_TYPE,
    orderType,
  };
}

export function addItemsToCart(items) {
  return {
    type: ADD_ITEMS_TO_CART,
    items,
  };
}

export function duplicateOrderToCart(order) {
  return {
    type: DUPLICATE_ORDER_TO_CART,
    order,
  };
}

export function removeItemFromCart(index) {
  return {
    type: REMOVE_ITEM_FROM_CART,
    index,
  };
}

export function clearCart() {
  return {
    type: CLEAR_CART,
  };
}

export function deleteCart(cart) {
  return {
    type: DELETE_CART,
    cart,
  };
}

export function receiveCart(cart) {
  return {
    type: RECEIVE_CART,
    cart,
  };
}

export function getCart(cartId, withStore) {
  return {
    type: GET_CART,
    cartId,
    withStore,
  };
}

export function receivePaidOrder(ident) {
  return {
    type: RECEIVE_PAID_ORDER,
    ident,
  };
}

export function getOrdersByCustomer(customerId, nextToken = null, limit = 10) {
  return {
    type: GET_ORDERS_BY_CUSTOMER,
    customerId,
    limit,
    nextToken,
  };
}

export function payOrder(cart, method) {
  return {
    type: PAY_ORDER,
    cart,
    method,
  };
}

export function toggleSidebar() {
  return {
    type: TOGGLE_SIDEBAR,
  };
}

export function sendReceiptLink(recipient, order) {
  return {
    type: SEND_RECEIPT_LINK,
    recipient,
    order,
  };
}

export function cancelOrder(order) {
  return {
    type: CANCEL_ORDER,
    order,
  };
}

export function setMarketAuthenticated(isAuthenticated) {
  return {
    type: SET_MARKET_AUTHENTICATED,
    isAuthenticated,
  };
}

export function receiveCheckout(checkout) {
  return {
    type: RECEIVE_CHECKOUT,
    checkout,
  };
}

export function getOrderByApiKey(orderId, withStore = false) {
  return {
    type: GET_ORDER_BY_APIKEY,
    orderId,
    withStore,
  };
}

export function signOut() {
  return {
    type: SIGN_OUT,
  };
}

export function dropoutAccount() {
  return {
    type: DROPOUT_ACCOUNT,
  };
}

// Subscription
export function getSubscriptionPlans(storeId) {
  return {
    type: GET_SUBSCRIPTION_PLANS,
    storeId,
  };
}

export function getSubscriptionPlan(planId, locationId = null) {
  return {
    type: GET_SUBSCRIPTION_PLAN,
    planId,
    locationId,
  };
}

export function getSubscriptionPlansByIds(ids) {
  return {
    type: GET_SUBSCRIPTION_PLANS_BY_IDS,
    ids,
  };
}

export function createSubscriptionPlan(plan) {
  return {
    type: CREATE_SUBSCRIPTION_PLAN,
    plan,
  };
}

export function updateSubscriptionPlan(plan) {
  return {
    type: UPDATE_SUBSCRIPTION_PLAN,
    plan,
  };
}

export function deleteSubscriptionPlan(plan) {
  return {
    type: DELETE_SUBSCRIPTION_PLAN,
    plan,
  };
}

export function receiveSubscriptionPlan(plan) {
  return {
    type: RECEIVE_SUBSCRIPTION_PLAN,
    plan,
  };
}

export function receiveSubscriptionPlans(plans) {
  return {
    type: RECEIVE_SUBSCRIPTION_PLANS,
    plans,
  };
}

export function setSubscriptionStatusSucess() {
  return {
    type: SET_SUBSCRIPTION_STATUS_SUCCESS,
  };
}

export function setSubscriptionStatusFailure(error) {
  return {
    type: SET_SUBSCRIPTION_STATUS_FAILURE,
    error,
  };
}

export function setEditingSubscriptionPlan(editing) {
  return {
    type: SET_EDITING_SUBSCRIPTION_PLAN,
    editing,
  };
}

export function clearEditingSubscriptionPlan() {
  return {
    type: CLEAR_EDITING_SUBSCRIPTION_PLAN,
  };
}

export function createCustomerPayment(customer, payment) {
  return {
    type: CREATE_CUSTOMER_PAYMENT,
    customer,
    payment,
  };
}

export function deleteCustomerPayment(id) {
  return {
    type: DELETE_CUSTOMER_PAYMENT,
    id,
  };
}

export function subscribeSubscriptionPlan(
  customerId,
  planId,
  locationId,
  storeId
) {
  return {
    type: SUBSCRIBE_SUBSCRIPTION_PLAN,
    customerId,
    planId,
    locationId,
    storeId,
  };
}

export function stopSubscription(subscriptionId) {
  return {
    type: STOP_SUBSCRIPTION,
    subscriptionId,
  };
}

export function restoreSubscription(subscriptionId) {
  return {
    type: RESTORE_SUBSCRIPTION,
    subscriptionId,
  };
}

export function cancelSubscription(subscriptionId) {
  return {
    type: CANCEL_SUBSCRIPTION,
    subscriptionId,
  };
}

export function getSubscriptionEvents(customerId) {
  return {
    type: GET_SUBSCRIPTION_EVENTS,
    customerId,
  };
}

export function receiveSubscriptionEvents(events) {
  return {
    type: RECEIVE_SUBSCRIPTION_EVENTS,
    events,
  };
}

export function getSubscriptionReport(storeId, locationId, start, end) {
  return {
    type: GET_SUBSCRIPTION_REPORT,
    storeId,
    locationId,
    start,
    end,
  };
}

export function receiveSubscriptionReport(report) {
  return {
    type: RECEIVE_SUBSCRIPTION_REPORT,
    report,
  };
}

export function setGetSubscriptionReportSuccess() {
  return {
    type: SET_SUBSCRIPTION_REPORT_SUCCESS,
  };
}

export function setGetSubscriptionReportFailure(error) {
  return {
    type: SET_SUBSCRIPTION_REPORT_FAILURE,
    error,
  };
}

export function setSubscriptionEditing(editing) {
  return {
    type: SET_SUBSCRIPTION_EDITING,
    editing,
  };
}

// Settlements
export function getSettlements(storeId, locationId, start, end = null) {
  return {
    type: GET_SETTLEMENTS,
    storeId,
    locationId,
    start,
    end,
  };
}

export function receiveSettlements(settlements) {
  return {
    type: RECEIVE_SETTLEMENTS,
    settlements,
  };
}

export function setSettlementsStatusSuccess() {
  return {
    type: SET_SETTLEMENTS_STATUS_SUCCESS,
  };
}

export function setSettlementsStatusFailure(error) {
  return {
    type: SET_SETTLEMENTS_STATUS_FAILURE,
    error,
  };
}

// PurchaseOrder

export function getPurchaseOrders(storeId, locationId = null) {
  return {
    type: GET_PURCHASE_ORDERS,
    storeId,
    locationId,
  };
}

export function getPurchaseOrderById(purchaseOrderId) {
  return {
    type: GET_PURCHASE_ORDER_BY_ID,
    purchaseOrderId,
  };
}

export function getEmployeeByPurchaseOrderId(purchaseOrderId) {
  return {
    type: GET_EMPLOYEE_BY_PURCHASE_ORDER_ID,
    purchaseOrderId,
  };
}

export function receivePurchaseOrders(purchaseOrders) {
  return {
    type: RECEIVE_PURCHASE_ORDERS,
    purchaseOrders,
  };
}

export function receivePurchaseOrder(purchaseOrder) {
  return {
    type: RECEIVE_PURCHASE_ORDER,
    purchaseOrder,
  };
}

export function receiveDeletedPurchaseOrder(purchaseOrder) {
  return {
    type: RECEIVE_DELETED_PURCHASE_ORDER,
    purchaseOrder,
  };
}

export function setPurchaseOrderStatusSuccess() {
  return {
    type: SET_PURCHASE_ORDER_STATUS_SUCCESS,
  };
}

export function setPurchaseOrderStatusFailure(error) {
  return {
    type: SET_PURCHASE_ORDER_STATUS_FAILURE,
    error,
  };
}

export function createOrUpdatePurchaseOrder(purchaseOrder, adjustments) {
  return {
    type: CREATE_OR_UPDATE_PURCHASE_ORDER,
    purchaseOrder,
    adjustments,
  };
}

export function deletePurchaseOrder(purchaseOrder) {
  return {
    type: DELETE_PURCHASE_ORDER,
    purchaseOrder,
  };
}

// Supplier

export function getSuppliers(storeId, locationId = null) {
  return {
    type: GET_SUPPLIERS,
    storeId,
    locationId,
  };
}

export function receiveSuppliers(suppliers) {
  return {
    type: RECEIVE_SUPPLIERS,
    suppliers,
  };
}

export function receiveSupplier(supplier) {
  return {
    type: RECEIVE_SUPPLIER,
    supplier,
  };
}

export function receiveDeletedSupplier(supplier) {
  return {
    type: RECEIVE_DELETED_SUPPLIER,
    supplier,
  };
}

export function setSupplierStatusSuccess() {
  return {
    type: SET_SUPPLIER_STATUS_SUCCESS,
  };
}

export function setSupplierStatusFailure(error) {
  return {
    type: SET_SUPPLIER_STATUS_FAILURE,
    error,
  };
}

export function createOrUpdateSupplier(supplier) {
  return {
    type: CREATE_OR_UPDATE_SUPPLIER,
    supplier,
  };
}

export function deleteSupplier(supplier) {
  return {
    type: DELETE_SUPPLIER,
    supplier,
  };
}

// Cafe24 Config
export function getProviderConfig(storeId) {
  return {
    type: GET_PROVIDER_CONFIG,
    storeId,
  };
}

export function updateProviderConfig(updater) {
  return {
    type: UPDATE_PROVIDER_CONFIG,
    updater,
  };
}

export function setProviderStatusSuccess() {
  return {
    type: SET_PROVIDER_STATUS_SUCCESS,
  };
}

export function setProviderStatusFailure(error) {
  return {
    type: SET_PROVIDER_STATUS_FAILURE,
    error,
  };
}

export function receiveProviderConfig(config) {
  return {
    type: RECEIVE_PROVIDER_CONFIG,
    config,
  };
}

export function getProviderCustomerGroups(storeId) {
  return {
    type: GET_PROVIDER_CUSTOMER_GROUPS,
    storeId,
  };
}

export function receiveProviderCustomerGroups(groups) {
  return {
    type: RECEIVE_PROVIDER_CUSTOMER_GROUPS,
    groups,
  };
}

export function clearProviderCustomerGroups() {
  return {
    type: CLEAR_PROVIDER_CUSTOMER_GROUPS,
  };
}

export function getTierUpdateHistories(storeId) {
  return {
    type: GET_TIER_UPDATE_HISTORIES,
    storeId,
  };
}

export function receiveTierUpdateHistories(histories) {
  return {
    type: RECEIVE_TIER_UPDATE_HISTORIES,
    histories,
  };
}

export function clearTierUpdateHistories() {
  return {
    type: CLEAR_TIER_UPDATE_HISTORIES,
  };
}

export function getProviderProducts(search, searchType, isLoadMore) {
  return {
    type: GET_PROVIDER_PRODUCTS,
    search,
    searchType,
    isLoadMore,
  };
}

export function linkProviderProducts(options, excludeKeys, pairs) {
  return {
    type: LINK_PROVIDER_PRODUCTS,
    options,
    excludeKeys,
    pairs,
  };
}

export function syncProviderInventories() {
  return {
    type: SYNC_PROVIDER_INVENTORIES,
  };
}

export function clearProviderProducts() {
  return {
    type: CLEAR_PROVIDER_PRODUCTS,
  };
}

export function receiveProviderProducts(items) {
  return {
    type: RECEIVE_PROVIDER_PRODUCTS,
    items,
  };
}

export function updateStoreProvider(provider) {
  return {
    type: UPDATE_STORE_PROVIDER,
    provider,
  };
}

export function getInventorySyncHistories(filters) {
  return {
    type: GET_INVENTORY_SYNC_HISTORIES,
    filters,
  };
}

export function receiveInventorySyncHistories(histories) {
  return {
    type: RECEIVE_INVENTORY_SYNC_HISTORIES,
    histories,
  };
}

export function clearInventorySyncHistories() {
  return {
    type: CLEAR_INVENTORY_SYNC_HISTORIES,
  };
}

export function unlinkProviderProducts(skuIds) {
  return {
    type: UNLINK_PROVIDER_PRODUCTS,
    skuIds,
  };
}

export function getProviderProductsLinkHistories(start, end) {
  return {
    type: GET_PROVIDER_PRODUCTS_LINK_HISTORIES,
    start,
    end,
  };
}

export function receiveLinkHistories(histories) {
  return {
    type: RECEIVE_LINK_HISTORIES,
    histories,
  };
}

export function clearLinkHistories() {
  return {
    type: CLEAR_LINK_HISTORIES,
  };
}

export function syncProviderProducts(skuIds, excludeKeys) {
  return {
    type: SYNC_PROVIDER_PRODUCTS,
    skuIds,
    excludeKeys,
  };
}

export function getDraftOnoffConfig(storeId) {
  return {
    type: GET_DRAFT_ONOFF_CONFIG,
    storeId,
  };
}

export function getLiveOnoffConfig(storeId) {
  return {
    type: GET_LIVE_ONOFF_CONFIG,
    storeId,
  };
}

export function receiveDraftOnoffConfig(config) {
  return {
    type: RECEIVE_DRAFT_ONOFF_CONFIG,
    config,
  };
}

export function receiveLiveOnoffConfig(config) {
  return {
    type: RECEIVE_LIVE_ONOFF_CONFIG,
    config,
  };
}

export function createOrUpdateOnoffConfig(config) {
  return {
    type: CREATE_OR_UPDATE_ONOFF_CONFIG,
    config,
  };
}

export function clearDraftOnoffConfig() {
  return {
    type: CLEAR_DRAFT_ONOFF_CONFIG,
  };
}

export function deleteDraftOnoffConfig() {
  return {
    type: DELETE_DRAFT_ONOFF_CONFIG,
  };
}

export function getCherryPickers(storeId) {
  return {
    type: GET_CHERRY_PICKERS,
    storeId,
  };
}

export function receivePickers(customers) {
  return {
    type: RECEIVE_PICKERS,
    customers,
  };
}

export function clearPickers() {
  return {
    type: CLEAR_PICKERS,
  };
}

export function getBlackListCustomers(storeId) {
  return {
    type: GET_BLACKLIST_CUSTOMERS,
    storeId,
  };
}

export function receiveBlackListCustomers(customers) {
  return {
    type: RECEIVE_BLACKLIST_CUSTOMERS,
    customers,
  };
}

export function clearBlackListCustomers() {
  return {
    type: CLEAR_BLACKLIST_CUSTOMERS,
  };
}

export function getCustomerTierHistories(storeId, customerId, start, end) {
  return {
    type: GET_CUSTOMER_TIER_HISTORIES,
    storeId,
    customerId,
    start,
    end,
  };
}

export function receiveCustomerTierHistories(histories) {
  return {
    type: RECEIVE_CUSTOMER_TIER_HISTORIES,
    histories,
  };
}

export function clearCustomerTierHistories() {
  return {
    type: CLEAR_CUSTOMER_TIER_HISTORIES,
  };
}

export function updateCustomerTier(storeId, customerId, tierId) {
  return {
    type: UPDATE_CUSTOMER_TIER,
    storeId,
    customerId,
    tierId,
  };
}

export function updateCustomerBlackList(storeId, customerId, active) {
  return {
    type: UPDATE_CUSTOMER_BLACKLIST,
    storeId,
    customerId,
    active,
  };
}

// Waiting
export function getWaiting(waitingId) {
  return {
    type: GET_WAITING,
    waitingId,
  };
}

export function cancelWaiting(waiting) {
  return {
    type: CANCEL_WAITING,
    waiting,
  };
}

export function receiveWaiting(waiting) {
  return {
    type: RECEIVE_WAITING,
    waiting,
  };
}

export function receiveWaitingInfo(info) {
  return {
    type: RECEIVE_WAITING_INFO,
    info,
  };
}

export function getWaitingInfo(storeId, locationId, waitingId) {
  return {
    type: GET_WAITING_INFO,
    storeId,
    locationId,
    waitingId,
  };
}

export function clearWaiting() {
  return {
    type: CLEAR_WAITING,
  };
}

export function setWaitingStatusSuccess() {
  return {
    type: SET_WAITING_STATUS_SUCCESS,
  };
}

export function setWaitingStatusFailure(error) {
  return {
    type: SET_WAITING_STATUS_FAILURE,
    error,
  };
}
