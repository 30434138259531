import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "moment/locale/ko";
import _ from "lodash";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import {
  CategoryFilterModal,
  CustomTable,
  DefaultLoader,
  FilledButton,
  LimitExcessModal,
  NofilledButton,
  ReactSelectCustomStyle,
} from "../components/Elements";
import ReactSelect from "react-select";
import { DateRangePicker } from "rsuite";

import Authority from "../services/Authority";
import {
  downloadCSV,
  isValidDate,
  numberToCurrency,
  numberWithCommas,
  usePrevious,
} from "../helpers/Utils";
import moment from "moment";
import Constants from "../helpers/Constants";
import {
  getCategoriesById,
  getCategoryProducts,
  getCurrentOrganization,
  getInventoryHistoriesById,
  getInventoryHistoryCount,
  getInventoryLocationsById,
  getInventoryReport,
  getInventoryReportStatus,
  getInventoryStatus,
  getProductsById,
  getSkusById,
  getStoreId,
  isMultiChannel,
} from "../reducers";
import {
  downloadInventoryHistories,
  getInventoryAssetReport,
  getInventoryDateReport,
  getInventoryHistoriesByStore,
  getInventoryItemReport,
  getInventoryTurnoverReport,
} from "../actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAssetHistoryReason, getOriginReason } from "../reducers/inventory";

const ITEM_PER_PAGE = 30;
const REPORT_TYPES = [
  {
    key: "summary",
    text: "일별 리포트",
    value: "summary",
  },
  {
    key: "itemReport",
    text: "제품별 리포트",
    value: "itemReport",
  },
  {
    key: "asset",
    text: "자산리포트",
    value: "asset",
  },
  {
    key: "turnOver",
    text: "재고회전율",
    value: "turnOver",
  },
];

const TURNOVER_TABLE_HEADER = [
  {
    label: "제품",
    width: "14%",
    key: "prodName",
  },
  {
    label: "하위제품",
    width: "14%",
    key: "skuName",
  },
  {
    label: "현재재고",
    width: "11%",
    key: "currQty",
  },
  {
    label: "평균재고",
    width: "11%",
    key: "avgQty",
  },
  {
    label: "재고회전율",
    width: "10%",
    key: "turnover",
  },
  {
    label: "총 입고",
    width: "10%",
    key: "totalInQty",
  },
  {
    label: "총 출고",
    width: "10%",
    key: "totalOutQty",
  },
  {
    label: "일평균입고",
    width: "10%",
    key: "dayAvgInQty",
  },
  {
    label: "일평균출고",
    width: "10%",
    key: "dayAvgOutQty",
  },
];

const DAY_TABLE_HEADER = [
  {
    label: "일자",
    width: "15%",
    key: "date",
  },
  {
    label: "총 매출",
    width: "20%",
    key: "sales",
    isQty: true,
    func: numberWithCommas,
    hasSort: true,
  },
  {
    label: "판매수량",
    width: "15%",
    key: "quantity",
    isQty: true,
    func: numberWithCommas,
    hasSort: true,
  },
  {
    label: "매출원가",
    width: "20%",
    key: "cost",
    func: numberWithCommas,
    hasSort: true,
  },
  {
    label: "이익",
    width: "20%",
    key: "profit",
    isQty: true,
    func: numberWithCommas,
    hasSort: true,
  },
  {
    label: "이익률",
    width: "10%",
    key: "marginRate",
    isQty: true,
    hasSort: true,
    isRate: true,
  },
];

const ITEM_TABLE_HEADER = [
  {
    label: "제품",
    width: "14%",
    key: "prodName",
  },
  {
    label: "하위제품",
    width: "14%",
    key: "skuName",
  },
  {
    label: "단가",
    width: "12%",
    key: "cost",
    leftBorder: true,
    isQty: true,
    func: numberWithCommas,
  },
  {
    label: "판매수량",
    width: "12%",
    key: "quantity",
    leftBorder: true,
    func: numberWithCommas,
    hasSort: true,
  },
  {
    label: "총 매출",
    width: "12%",
    key: "totalSales",
    leftBorder: true,
    isQty: true,
    func: numberWithCommas,
    hasSort: true,
  },
  {
    label: "매출원가",
    width: "12%",
    key: "totalCost",
    leftBorder: true,
    func: numberWithCommas,
    hasSort: true,
  },
  {
    label: "이익",
    width: "12%",
    key: "profit",
    leftBorder: true,
    isQty: true,
    func: numberWithCommas,
    hasSort: true,
  },
  {
    label: "이익률",
    width: "12%",
    key: "marginRate",
    leftBorder: true,
    isQty: true,
    hasSort: true,
    isRate: true,
  },
];

export const ASSET_TABLE_HEADER = [
  {
    label: "종류",
    width: "14%",
    key: "reason",
  },
  {
    label: "일시",
    width: "16%",
    key: "date",
  },
  {
    label: "제품",
    width: "20%",
    key: "product",
  },
  {
    label: "품목코드(SKU)",
    width: "18%",
    key: "sku",
  },
  {
    label: "금액",
    width: "16%",
    key: "totalCost",
    isAmount: true,
    func: numberToCurrency,
  },
  {
    label: "사유",
    width: "16%",
    key: "originReason",
  },
];

const restrictReport = () => {
  const { afterToday } = DateRangePicker;
  return afterToday();
};

const ReportSwitch = React.memo(
  ({
    reportNav,
    search,
    onSearchChange,
    data,
    stats,
    brushupItemReport,
    brushupReport,
    brushupTurnoverReport,
    isLoading,
    sortBy,
    onHeaderSortPress,
    onLoadMore,
    onReportDown,
    page,
    onCategoryFilterPress,
    categoryFiltersIds,
    brushupHistory,
    histories,
    historyCount,
    isHistoryLoading,
    onHistoryLoadMore,
    isAssetExcelDownload,
  }) => {
    switch (reportNav) {
      case "summary":
        let dayReport = brushupReport(data);
        let margin = (stats.totalSales || 0) - (stats.totalCost || 0);
        let marginRate =
          stats.totalSales !== 0
            ? (((stats.totalSales || 0) - (stats.totalCost || 0)) /
                stats.totalSales) *
              100
            : null;
        const hasDayReportMore =
          (data || []).filter((d) => d.quantity > 0).length >
            dayReport.length && dayReport.length === page * ITEM_PER_PAGE;
        return isLoading ? (
          <DefaultLoader active={isLoading} />
        ) : (
          <>
            <div className="reports-panel">
              <div className="flex-wrap">
                <div className="stock-report-stat-comp">
                  <div className="title">총 매출</div>
                  <div className="value">
                    {numberToCurrency(stats.totalSales || 0)}
                  </div>
                </div>
                <div className="stock-report-stat-comp">
                  <div className="title">총 매출원가</div>
                  <div className="value">
                    {numberToCurrency(stats.totalCost || 0)}
                  </div>
                </div>
                <div className="stock-report-stat-comp">
                  <div className="title">수익</div>
                  <div
                    className="value"
                    style={{ color: margin < 0 ? "#e02020" : "#337ef3" }}
                  >
                    {numberToCurrency(margin || 0)}
                  </div>
                </div>
                <div className="stock-report-stat-comp">
                  <div className="title">이익률</div>
                  <div
                    className="value"
                    style={{ color: marginRate < 0 ? "#e02020" : "#337ef3" }}
                  >
                    {marginRate ? `${Math.floor(marginRate)}%` : "-"}
                  </div>
                </div>
              </div>
            </div>
            <div className="reports-panel mt-small">
              <div className="d-flex justify-content-end">
                <NofilledButton
                  buttonColor="blue"
                  label="다운로드"
                  onClick={onReportDown}
                />
              </div>
              <CustomTable
                items={dayReport}
                headers={DAY_TABLE_HEADER}
                topMarginStyle={{ marginTop: "15px" }}
                sortBy={sortBy}
                onHeaderSortPress={onHeaderSortPress}
              />
            </div>
            {hasDayReportMore ? (
              <div
                type="button"
                onClick={onLoadMore}
                className="width-100 flex-center"
                style={{
                  height: "55px",
                  backgroundColor: "#fff",
                  cursor: "pointer",
                }}
              >
                <div className="label font-bold blue-color">{"더보기"}</div>
              </div>
            ) : null}
          </>
        );
      case "itemReport":
        let itemReport = brushupItemReport(data);
        let totalItemSize = 0;
        for (let d of data) {
          for (let item of d.items || []) {
            if (item.quantity > 0) {
              totalItemSize += 1;
            }
          }
        }
        const hasItemReportMore =
          totalItemSize > itemReport.length &&
          itemReport.length === page * ITEM_PER_PAGE;
        return (
          <div className="reports-panel">
            {isLoading ? (
              <DefaultLoader active={isLoading} />
            ) : (
              <>
                <div className="flex-between mt-mb-base">
                  <FilledButton
                    onClick={onCategoryFilterPress}
                    buttonColor={
                      categoryFiltersIds.length > 0 ? "blue" : "blue-outline"
                    }
                    customStyle={{ minHeight: "40px" }}
                    customTextClass={"h7"}
                    textComp={
                      <div className="flex-center">
                        <div className="h7">
                          {categoryFiltersIds.length > 0
                            ? `${categoryFiltersIds.length}개 카테고리 필터중`
                            : "카테고리 필터"}
                        </div>
                        <FontAwesomeIcon icon={faFilter} className="ml-base" />
                      </div>
                    }
                  />
                  <NofilledButton
                    buttonColor="blue"
                    label="다운로드"
                    onClick={onReportDown}
                  />
                </div>
                <CustomTable
                  items={itemReport}
                  hasSearch
                  searchValue={search}
                  onSearchChange={onSearchChange}
                  headers={ITEM_TABLE_HEADER}
                  topMarginStyle={{ marginTop: "15px" }}
                  placeholder={"제품이름, 품목코드(SKU), 바코드 검색 가능"}
                  allowTwoLine
                  sortBy={sortBy}
                  onHeaderSortPress={onHeaderSortPress}
                />
                <>
                  {hasItemReportMore ? (
                    <div
                      type="button"
                      onClick={onLoadMore}
                      className="width-100 flex-center"
                      style={{
                        height: "55px",
                        backgroundColor: "#fff",
                        cursor: "pointer",
                      }}
                    >
                      <div className="label font-bold blue-color">
                        {"더보기"}
                      </div>
                    </div>
                  ) : null}
                </>
              </>
            )}
          </div>
        );
      case "asset":
        let _histories = brushupHistory(histories);
        const hasHistoriesMore =
          (_histories || []).length < historyCount &&
          page * ITEM_PER_PAGE === _histories.length;
        return isLoading ? (
          <DefaultLoader active={isLoading} />
        ) : (
          <>
            <div className="reports-panel">
              <div className="flex-wrap">
                <div className="stock-report-stat-comp">
                  <div className="title">자산총계</div>
                  <div className="value">
                    {numberToCurrency(stats.assetAmount || 0)}
                  </div>
                </div>
                <div className="stock-report-stat-comp">
                  <div className="title">잠재 판매가액</div>
                  <div className="value">
                    {numberToCurrency(stats.salesExpectedAmount || 0)}
                  </div>
                </div>
                <div className="stock-report-stat-comp">
                  <div className="title ">총 출고자산</div>
                  <div className="value" style={{ color: "#e02020" }}>
                    {numberToCurrency(stats.outAmount || 0)}
                  </div>
                </div>
                <div className="stock-report-stat-comp">
                  <div className="title ">총 입고자산</div>
                  <div className="value" style={{ color: "#337ef3" }}>
                    {numberToCurrency(stats.inAmount || 0)}
                  </div>
                </div>
              </div>
            </div>
            <div className="reports-panel mt-small">
              <div className="d-flex justify-content-end">
                <NofilledButton
                  buttonColor="blue"
                  label="다운로드"
                  loading={isAssetExcelDownload}
                  disabled={isAssetExcelDownload}
                  onClick={onReportDown}
                />
              </div>
              <CustomTable
                items={_histories}
                headers={ASSET_TABLE_HEADER}
                topMarginStyle={{ marginTop: "15px" }}
              />
            </div>
            {hasHistoriesMore || isHistoryLoading ? (
              <NofilledButton
                onClick={onHistoryLoadMore}
                buttonColor={"blue"}
                customStyle={{
                  height: "55px",
                  margin: "auto",
                }}
                loading={isHistoryLoading}
                textComp={
                  <div className="label font-bold blue-color">{"더보기"}</div>
                }
              />
            ) : null}
          </>
        );
      case "turnOver":
        let turnoverReport = brushupTurnoverReport(data);
        const hasTurnOverReportMore =
          (data || []).length > turnoverReport.length &&
          page * ITEM_PER_PAGE === turnoverReport.length;
        return (
          <div className="reports-panel">
            {isLoading ? (
              <DefaultLoader active={isLoading} />
            ) : (
              <>
                <div className="d-flex justify-content-end">
                  <NofilledButton
                    buttonColor="blue"
                    label="다운로드"
                    onClick={onReportDown}
                  />
                </div>
                <CustomTable
                  items={turnoverReport}
                  hasSearch
                  searchValue={search}
                  onSearchChange={onSearchChange}
                  headers={TURNOVER_TABLE_HEADER}
                  topMarginStyle={{ marginTop: "15px" }}
                  placeholder={"제품이름, 품목코드(SKU), 바코드 검색 가능"}
                  allowTwoLine
                  sortBy={sortBy}
                  onHeaderSortPress={onHeaderSortPress}
                />
                <>
                  {hasTurnOverReportMore ? (
                    <div
                      type="button"
                      onClick={onLoadMore}
                      className="width-100 flex-center"
                      style={{
                        height: "55px",
                        backgroundColor: "#fff",
                        cursor: "pointer",
                      }}
                    >
                      <div className="label font-bold blue-color">
                        {"더보기"}
                      </div>
                    </div>
                  ) : null}
                </>
              </>
            )}
          </div>
        );
      default:
        break;
    }
  }
);

const Navs = React.memo(({ reportNav, onNavSelect }) => (
  <div className="report-nav-container mt-line">
    <div className="report-nav-wrapper">
      {REPORT_TYPES.map((report) => (
        <div
          key={report.key}
          onClick={() => onNavSelect(report.key)}
          className={`report-nav ${reportNav === report.key ? "active" : ""}`}
        >
          {report.text}
        </div>
      ))}
    </div>
  </div>
));

const HeaderRender = React.memo(
  ({
    startDate,
    endDate,
    onPeriodChange,
    onStartDateChange,
    onEndDateChange,
    navProps,
    onLocationChange,
    getLocations,
    selectedLoc,
    locationsById,
    multiChannel,
  }) => {
    return (
      <>
        <div className="d-flex align-items-center">
          <input
            dateFormat="yyyy/MM/dd"
            locale="ko"
            type="date"
            name="startDate"
            value={moment(startDate).format("YYYY-MM-DD")}
            className="custom-date-range-picker"
            onChange={onStartDateChange}
          />
          &nbsp;~&nbsp;
          <input
            dateFormat="yyyy/MM/dd"
            locale="ko"
            type="date"
            name="endDate"
            value={moment(endDate).format("YYYY-MM-DD")}
            className="custom-date-range-picker"
            onChange={onEndDateChange}
          />
          {multiChannel && Authority.level() === "store" ? (
            <ReactSelect
              closeMenuOnSelect={true}
              name={"locationId"}
              placeholder={"전체지점/채널"}
              value={getLocations(Object.values(locationsById)).filter(
                (loc) => selectedLoc === loc.id
              )}
              options={[
                { value: null, label: "전체지점/채널" },
                ...getLocations(Object.values(locationsById)),
              ]}
              onChange={onLocationChange}
              styles={{
                ...ReactSelectCustomStyle,
                control: (provided) => ({
                  ...provided,
                  borderWidth: 1,
                  borderColor: "#d1d4d8",
                  borderRadius: 0,
                  padding: "0 20px",
                  height: "55px",
                  minWidth: "12rem",
                  marginLeft: "1.5rem",
                }),
              }}
            />
          ) : null}
          <FilledButton
            onClick={onPeriodChange}
            label="조회"
            buttonColor="blue"
            customClass="ml-sm"
            customStyle={{ minWidth: "5rem", height: "55px" }}
          />
        </div>

        <Navs {...navProps} />
      </>
    );
  }
);

const INIT_SORT = { key: null, type: null };

const StockReport = React.memo(({}) => {
  const today = new Date();

  let [dateLimitAccessModal, setDateLimitAccess] = useState(false);
  let [categoryFilterModal, setCategoryFilterModal] = useState(false);
  let [startDate, setStartDate] = useState(today);
  let [endDate, setEndDate] = useState(today);
  let [selectedPeriod, setSelectedPeriod] = useState("");
  let [reportNav, setReportNav] = useState("summary");
  let [selectedLoc, setSelectedLoc] = useState(null);
  let [search, setSearch] = useState("");
  let [isLoading, setLoading] = useState(false);
  let [isHistoryLoading, setHistoryLoading] = useState(false);
  let [isAssetExcelDownload, setAssetExcelDownload] = useState(false);
  let [page, setPage] = useState(1);
  let [sortBy, setSortBy] = useState(_.cloneDeep(INIT_SORT));
  let [categoryFiltersIds, setCategoryFiltersIds] = useState([]);
  let [tempCategoryFilterIds, setTempCategoryFilterIds] = useState([]);

  const dispatch = useDispatch();
  let locationsById = useSelector(getInventoryLocationsById);
  let organization = useSelector(getCurrentOrganization);
  let reportStatus = useSelector(getInventoryReportStatus);
  let productsById = useSelector(getProductsById);
  let skusById = useSelector(getSkusById);
  let categoriesById = useSelector(getCategoriesById);
  let categoryProducts = useSelector(getCategoryProducts);
  let data = useSelector(getInventoryReport);
  let _storeId = useSelector(getStoreId);
  let histories = useSelector(getInventoryHistoriesById);
  let historyCount = useSelector(getInventoryHistoryCount);
  let inventoryStatus = useSelector(getInventoryStatus);
  let multiChannel = useSelector(isMultiChannel);

  const handleCloseDateLimitExcessModal = () => {
    setDateLimitAccess(false);
  };

  const handleNavSelect = (nav) => {
    setReportNav(nav);
    setSearch("");
    setPage(1);
    let start = startDate;
    let end = endDate;
    if (isValidDate(start) && isValidDate(end)) {
      if (nav === "turnOver") {
        start = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        end = today;
        setStartDate(start);
        setEndDate(end);
      }
      getReport(nav, organization.storeId, organization.locationId, start, end);
    }
  };

  const getInventoryHistories = (
    _page,
    dates,
    locationId = null,
    override = true
  ) => {
    let filters = [
      {
        key: "reason",
        operation: "ni",
        value: ["damaged", "restock"],
      },
    ];
    if (locationId) {
      filters.push({
        key: "locationId",
        value: locationId,
        operation: "eq",
      });
    }
    if (dates[0] && dates[1]) {
      let s = new Date(dates[0]);
      let e = new Date(dates[1]);
      s.setHours(0, 0, 0, 0);
      e.setHours(23, 59, 59, 999);
      const _s = new Date(s).toISOString();
      const _e = new Date(e).toISOString();
      filters.push({
        key: "createdAt",
        value: [_s, _e],
        operation: "between",
      });
    }
    if (_page !== page) {
      setPage(_page);
    }
    if (!override) {
      setHistoryLoading(true);
    }
    dispatch(
      getInventoryHistoriesByStore(
        _page,
        ITEM_PER_PAGE,
        "asset",
        filters,
        override
      )
    );
  };

  const getReport = (item, storeId, locationId, start, end) => {
    setLoading(true);
    let _start = _.cloneDeep(start);
    let _end = _.cloneDeep(end);
    _start.setHours(0, 0, 0, 0);
    _end.setHours(23, 59, 59, 999);
    switch (item) {
      case "summary":
        dispatch(
          getInventoryDateReport(
            storeId,
            selectedLoc ? selectedLoc : locationId,
            _start.toISOString(),
            _end.toISOString()
          )
        );
        break;
      case "itemReport":
        dispatch(
          getInventoryItemReport(
            storeId,
            selectedLoc ? selectedLoc : locationId,
            _start.toISOString(),
            _end.toISOString()
          )
        );
        break;
      case "turnOver":
        dispatch(
          getInventoryTurnoverReport(
            storeId,
            selectedLoc ? selectedLoc : locationId,
            _start.toISOString(),
            _end.toISOString()
          )
        );
        break;
      case "asset":
        getInventoryHistories(
          page,
          [_start.toISOString(), _end.toISOString()],
          selectedLoc ? selectedLoc : locationId
        );
        dispatch(
          getInventoryAssetReport(
            storeId,
            selectedLoc ? selectedLoc : locationId,
            _start.toISOString(),
            _end.toISOString()
          )
        );
      default:
        break;
    }
  };

  const brushupHistory = (_his) => {
    let _data = [];
    let _histories = _.cloneDeep(_his || []);
    for (let history of _histories) {
      const sku = skusById[history.skuId];
      const product = sku?.parentId ? productsById[sku?.parentId] : "";
      _data.push({
        reason: getAssetHistoryReason(history),
        date: moment(history.createdAt).format(
          Constants.MOMENT.DATETIME_FORMAT
        ),
        product: product.name,
        sku: sku?.model,
        totalCost: history.totalCost || 0,
        originReason: getOriginReason(history.reason),
      });
    }
    return _data;
  };

  const handleStartDateChange = (e) => {
    setStartDate(new Date(e.target.value));
  };

  const handleEndDateChange = (e) => {
    setEndDate(new Date(e.target.value));
  };

  const handlePeriodChange = () => {
    let date = [startDate, endDate];
    if (isValidDate(date[0]) && isValidDate(date[1])) {
      const diff = moment(date[1]).diff(moment(date[0]), "months");
      if (diff > Constants.REPORT_RESTRICTION_MONTH) {
        return setDateLimitAccess(true);
      }
      if (!_.isEmpty(date)) {
        setStartDate(new Date(date[0]));
        setEndDate(new Date(date[1]));
        getReport(
          reportNav,
          organization.storeId,
          organization.locationId,
          new Date(date[0]),
          new Date(date[1])
        );
      } else {
        setStartDate(today);
        setEndDate(today);
      }
    }
  };

  const resetPeriod = () => {
    changePeriod("", today, today);
  };

  const changePeriod = (period, start, end) => {
    setSelectedPeriod(period);
    handlePeriodChange([start, end]);
  };

  const getLocations = (locations) => {
    return (locations || [])
      .filter(
        (loc) => loc.id === organization.locationId || !organization.locationId
      )
      .map((_loc) => {
        return { ..._loc, value: _loc.id, label: _loc.name };
      });
  };

  const handleLocationChange = (e) => {
    setSelectedLoc(e.id);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const skuFilter = (sku, prodName) => {
    const _search = search.toLowerCase();
    return (
      prodName.toLowerCase().includes(_search) ||
      (sku.unit || "").toLowerCase().includes(_search) ||
      (sku.model || "").toLowerCase().includes(_search)
    );
  };

  const getFilteredProduct = () => {
    let products = _.cloneDeep(Object.values(productsById));
    if (reportNav === "itemReport" && categoryFiltersIds.length > 0) {
      products = products.map((prod) => ({
        ...prod,
        categoryIds: categoryProducts
          .filter((cp) => cp.productId === prod.id)
          .map((item) => item.categoryId),
      }));
      products = products.filter((prod) => {
        for (let prodCateId of prod.categoryIds || []) {
          if (categoryFiltersIds.includes(prodCateId)) {
            return true;
          } else {
            return false;
          }
        }
      });
    }
    return {
      productsById: products.reduce((obj, p) => {
        obj[p.id] = p;
        return obj;
      }, {}),
      skusById: products.reduce((obj, prod) => {
        for (let sku of prod.skus) {
          if (skuFilter(sku, prod.name)) {
            obj[sku.itemId] = {
              ...sku,
              parentId: prod.itemId || prod.id,
              taxId: prod.taxId,
              trackStock: sku.stockTrack || prod.trackStock,
              categoryIds: prod.categoryIds,
            };
          }
        }
        return obj;
      }, {}),
    };
  };

  const brushupItemReport = (report) => {
    let _data = [];
    const filteredProducts = getFilteredProduct();
    const skus = skusById;
    for (let listItem of report) {
      const existSku = skus[listItem.skuId];
      const filteredSku = filteredProducts.skusById[listItem.skuId];
      if (
        listItem.skuId &&
        ((existSku && filteredSku) || (!existSku && !filteredSku))
      ) {
        const prod = filteredProducts.productsById[filteredSku?.parentId];
        for (let j = 0; j < (listItem.items || []).length; j++) {
          if (listItem.items[j].quantity > 0) {
            _data = [
              ..._data,
              {
                prodName: prod?.name || "삭제된 제품",
                skuName: filteredSku?.name || "삭제된 제품",
                cost: listItem.items[j].cost,
                quantity: listItem.items[j].quantity,
                totalSales: listItem.items[j].totalSales,
                totalCost:
                  (listItem.items[j].quantity || 0) *
                  (listItem.items[j].cost || 0),
                profit:
                  listItem.items[j].totalSales -
                  (listItem.items[j].quantity || 0) *
                    (listItem.items[j].cost || 0),
                marginRate:
                  listItem.items[j].totalSales === 0
                    ? "-"
                    : (
                        ((listItem.items[j].totalSales -
                          (listItem.items[j].quantity || 0) *
                            (listItem.items[j].cost || 0)) /
                          (listItem.items[j].totalSales || 1)) *
                        100
                      ).toFixed(0),
              },
            ];
          }
        }
      }
    }

    _data = _.sortBy(_data, "prodName");
    if (sortBy.key) {
      if (sortBy.type === Constants.SORT_DIRECTION.ASCEND) {
        _data = _.sortBy(_data, sortBy.key);
      } else {
        _data = _.reverse(_.sortBy(_data, sortBy.key));
      }
    }
    return _data.slice(0, page * ITEM_PER_PAGE);
  };

  const brushupReport = (report) => {
    let _data = [];
    let _report = (report || []).filter((r) => r.quantity > 0);
    for (let i = 0; i < (_report || []).length; i++) {
      _data = [
        ..._data,
        {
          date: moment(_report[i]?.date).format(Constants.MOMENT.DATE_FORMAT),
          quantity: _report[i].quantity,
          sales: _report[i].totalSales,
          cost: _report[i].totalCost,
          profit: (_report[i].totalSales || 0) - (_report[i].totalCost || 0),
          marginRate:
            _report[i].totalSales === 0
              ? "-"
              : (
                  ((_report[i].totalSales - _report[i].totalCost) /
                    (_report[i].totalSales || 1)) *
                  100
                ).toFixed(0),
        },
      ];
    }

    if (sortBy.key) {
      if (sortBy.type === Constants.SORT_DIRECTION.ASCEND) {
        _data = _.sortBy(_data, sortBy.key);
      } else {
        _data = _.reverse(_.sortBy(_data, sortBy.key));
      }
    } else {
      _data = _.reverse(_.sortBy(_data, "date"));
    }
    return _data.slice(0, page * ITEM_PER_PAGE);
  };

  const brushupTurnoverReport = (report) => {
    let _data = [];
    const filteredProducts = getFilteredProduct();
    const skus = skusById;
    for (let listItem of report) {
      const existSku = skus[listItem.skuId];
      const filteredSku = filteredProducts.skusById[listItem.skuId];
      if (
        listItem.skuId &&
        ((existSku && filteredSku) || (!existSku && !filteredSku))
      ) {
        const prod = filteredProducts.productsById[filteredSku?.parentId];
        _data = [
          ..._data,
          {
            prodName: prod?.name || "삭제된 제품",
            skuName: filteredSku?.name || "삭제된 제품",
            ...listItem,
          },
        ];
      }
    }
    if (sortBy.key) {
      if (sortBy.type === Constants.SORT_DIRECTION.ASCEND) {
        _data = _.sortBy(_data, sortBy.key);
      } else {
        _data = _.reverse(_.sortBy(_data, sortBy.key));
      }
    }
    return _.sortBy(_data, "prodName").slice(0, page * ITEM_PER_PAGE);
  };

  const handleSortPress = (headerKey) => {
    if (sortBy.key === headerKey) {
      if (sortBy.type === Constants.SORT_DIRECTION.ASCEND) {
        setSortBy({ key: headerKey, type: Constants.SORT_DIRECTION.DESCEND });
      } else {
        setSortBy(_.cloneDeep(INIT_SORT));
      }
    } else {
      setSortBy({ key: headerKey, type: Constants.SORT_DIRECTION.ASCEND });
    }
  };

  const handleExportCsv = () => {
    let report = data.list;
    let _data = [];
    let headers = [];
    let columns = null;
    let fileName = "";
    const filteredProducts = getFilteredProduct();
    const skus = skusById;
    switch (reportNav) {
      case "summary":
        let _report = (report || []).filter((r) => r.quantity > 0);
        columns = DAY_TABLE_HEADER;
        headers = columns.map((column) => column.label);
        fileName = "일별 리포트";
        for (let i = 0; i < (_report || []).length; i++) {
          _data = [
            ..._data,
            [
              moment(_report[i]?.date).format(Constants.MOMENT.DATE_FORMAT),
              _report[i].totalSales,
              _report[i].quantity,
              _report[i].totalCost,

              (_report[i].totalSales || 0) - (_report[i].totalCost || 0),
              _report[i].totalSales === 0
                ? "-"
                : (
                    ((_report[i].totalSales - _report[i].totalCost) /
                      (_report[i].totalSales || 1)) *
                    100
                  ).toFixed(0),
            ],
          ];
        }
        _data = _.reverse(_.sortBy(_data, "date"));
        downloadCSV([headers, ..._data], fileName);
        break;
      case "itemReport":
        columns = ITEM_TABLE_HEADER;
        headers = columns.map((column) => column.label);
        fileName = "제품별 리포트";
        for (let listItem of report) {
          const existSku = skus[listItem.skuId];
          const filteredSku = filteredProducts.skusById[listItem.skuId];
          if (
            listItem.skuId &&
            ((existSku && filteredSku) || (!existSku && !filteredSku))
          ) {
            const prod = filteredProducts.productsById[filteredSku?.parentId];
            for (let j = 0; j < (listItem.items || []).length; j++) {
              if (listItem.items[j].quantity > 0) {
                _data = [
                  ..._data,
                  [
                    prod?.name || "삭제된 제품",
                    filteredSku?.name || "삭제된 제품",
                    listItem.items[j].cost,
                    listItem.items[j].quantity,
                    listItem.items[j].totalSales,
                    (listItem.items[j].quantity || 0) *
                      (listItem.items[j].cost || 0),

                    listItem.items[j].totalSales -
                      (listItem.items[j].quantity || 0) *
                        (listItem.items[j].cost || 0),
                    listItem.items[j].totalSales === 0
                      ? "-"
                      : (
                          ((listItem.items[j].totalSales -
                            (listItem.items[j].quantity || 0) *
                              (listItem.items[j].cost || 0)) /
                            (listItem.items[j].totalSales || 1)) *
                          100
                        ).toFixed(0),
                  ],
                ];
              }
            }
          }
        }
        downloadCSV([headers, ..._data], fileName);
        break;
      case "asset":
        const filters = [
          {
            key: "reason",
            operation: "ni",
            value: ["damaged", "restock"],
          },
        ];
        const dates = [startDate, endDate];
        const locationId = selectedLoc ? selectedLoc : organization.locationId;
        if (locationId) {
          filters.push({
            key: "locationId",
            value: locationId,
            operation: "eq",
          });
        }
        if (dates[0] && dates[1]) {
          let s = new Date(dates[0]);
          let e = new Date(dates[1]);
          s.setHours(0, 0, 0, 0);
          e.setHours(23, 59, 59, 999);
          const _s = new Date(s).toISOString();
          const _e = new Date(e).toISOString();
          filters.push({
            key: "createdAt",
            value: [_s, _e],
            operation: "between",
          });
        }
        setAssetExcelDownload(true);
        dispatch(downloadInventoryHistories("asset", filters));
        break;
      case "turnOver":
        columns = TURNOVER_TABLE_HEADER;
        headers = columns.map((column) => column.label);
        fileName = "재고회전율 리포트";
        for (let listItem of report) {
          const existSku = skus[listItem.skuId];
          const filteredSku = filteredProducts.skusById[listItem.skuId];
          if (
            listItem.skuId &&
            ((existSku && filteredSku) || (!existSku && !filteredSku))
          ) {
            const prod = filteredProducts.productsById[filteredSku?.parentId];
            _data = [
              ..._data,
              [
                prod?.name || "삭제된 제품",
                filteredSku?.name || "삭제된 제품",
                listItem.currQty,
                listItem.avgQty,
                listItem.turnover,
                listItem.totalInQty,
                listItem.totalOutQty,
                listItem.dayAvgInQty,
                listItem.dayAvgOutQty,
              ],
            ];
          }
        }
        downloadCSV([headers, ..._data], fileName);
        break;
      default:
    }
  };

  const handleCategoryFilterPress = () => {
    setCategoryFilterModal(true);
    if (categoryFiltersIds.length) {
      setTempCategoryFilterIds(categoryFiltersIds);
    }
  };

  const handleCategoryFilterModalClose = () => {
    setCategoryFilterModal(false);
    setTempCategoryFilterIds([]);
  };

  const handleCategoryFilterSave = () => {
    let _temp = _.cloneDeep(tempCategoryFilterIds);
    if (_temp.length === Object.keys(categoriesById || {}).length) {
      setCategoryFilterModal(false);
      setTempCategoryFilterIds([]);
      setCategoryFiltersIds([]);
    } else {
      setCategoryFilterModal(false);
      setTempCategoryFilterIds([]);
      setCategoryFiltersIds(_temp);
    }
  };

  const handleCategoryFilterAllCheck = () => {
    if (
      (tempCategoryFilterIds.length > 0 &&
        tempCategoryFilterIds.length !==
          (Object.keys(categoriesById) || []).length) ||
      tempCategoryFilterIds.length === 0
    ) {
      setTempCategoryFilterIds(Object.keys(categoriesById));
    } else if (
      tempCategoryFilterIds.length ===
      (Object.keys(categoriesById) || []).length
    ) {
      setTempCategoryFilterIds([]);
    }
  };

  const handleCategoryFilterCheck = (cateId) => {
    if (tempCategoryFilterIds.includes(cateId)) {
      setTempCategoryFilterIds(
        tempCategoryFilterIds.filter((_id) => cateId !== _id)
      );
    } else {
      setTempCategoryFilterIds([...tempCategoryFilterIds, cateId]);
    }
  };

  const handleLoadMore = () => {
    setPage(page + 1);
  };

  const handleHistoryLoadMore = () => {
    const locationId = organization.locationId;
    getInventoryHistories(
      page + 1,
      [startDate, endDate],
      selectedLoc ? selectedLoc : locationId,
      false
    );
  };

  // INIT

  const initLoading = () => {
    setLoading(false);
  };

  const initHistoryLoading = () => {
    setHistoryLoading(false);
    setAssetExcelDownload(false);
  };

  // DID MOUNT
  useEffect(() => {
    if (_storeId) {
      getReport(
        reportNav,
        organization.storeId || _storeId,
        organization.locationId,
        startDate,
        endDate
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // DID UPDATE
  let prevReportStatus = usePrevious(reportStatus);
  let prevStoreId = usePrevious(_storeId);
  let prevInventoryStatus = usePrevious(inventoryStatus);
  useEffect(() => {
    if (isLoading && prevReportStatus?.isFetching && !reportStatus.isFetching) {
      if (reportStatus.error) {
        if (reportStatus.error === "InvalidPeriod") {
          alert("재고 회전율 리포트는 7일씩 조회가 가능합니다.");
        } else {
          alert("리포트 조회에 실패하였습니다.\n잠시 후 다시 시도해 주세요.");
        }
      }
      initLoading();
    }
    if (prevStoreId !== _storeId) {
      getReport(
        reportNav,
        _storeId,
        organization.locationId,
        startDate,
        endDate
      );
    }
    if (
      isHistoryLoading &&
      prevInventoryStatus?.isFetching &&
      !inventoryStatus.isFetching
    ) {
      if (inventoryStatus.error) {
        alert("리포트 조회에 실패하였습니다.\n잠시 후 다시 시도해 주세요.");
      }
      initHistoryLoading();
    }
    if (
      isAssetExcelDownload &&
      prevInventoryStatus?.isFetching &&
      !inventoryStatus.isFetching
    ) {
      if (inventoryStatus.error) {
        alert("리포트 다운로드에 실패하였습니다.\n잠시 후 다시 시도해 주세요.");
      }
      initHistoryLoading();
    }
  });

  return (
    <>
      {dateLimitAccessModal ? (
        <LimitExcessModal
          open={dateLimitAccessModal}
          onClose={handleCloseDateLimitExcessModal}
          title={"기간 조회는 최대 90일까지 가능합니다."}
          customTitleClass={"normal mt-mb-base"}
        />
      ) : null}
      {categoryFilterModal ? (
        <CategoryFilterModal
          open={categoryFilterModal}
          onCancel={handleCategoryFilterModalClose}
          onSave={handleCategoryFilterSave}
          tempCategoryFilterIds={tempCategoryFilterIds}
          categoriesById={categoriesById}
          onCheckPress={handleCategoryFilterCheck}
          onAllCheck={handleCategoryFilterAllCheck}
          categoryProducts={categoryProducts}
        />
      ) : null}
      <div
        style={{ width: "73vw", overflowY: "auto" }}
        className="responsive-wrapper"
      >
        <HeaderRender
          startDate={startDate}
          endDate={endDate}
          resetPeriod={resetPeriod}
          onPeriodChange={handlePeriodChange}
          onStartDateChange={handleStartDateChange}
          onEndDateChange={handleEndDateChange}
          selectedPeriod={selectedPeriod}
          changePeriod={changePeriod}
          multiChannel={multiChannel}
          getLocations={getLocations}
          onLocationChange={handleLocationChange}
          selectedLoc={selectedLoc}
          locationsById={locationsById}
          navProps={{
            reportNav: reportNav,
            onNavSelect: handleNavSelect,
          }}
        />

        <ReportSwitch
          reportNav={reportNav}
          search={search}
          onSearchChange={handleSearchChange}
          brushupItemReport={brushupItemReport}
          brushupTurnoverReport={brushupTurnoverReport}
          brushupReport={brushupReport}
          stats={data.stats || {}}
          data={data.list || []}
          isLoading={
            isLoading ||
            (inventoryStatus.isFetching &&
              !isHistoryLoading &&
              !isAssetExcelDownload)
          }
          onHeaderSortPress={handleSortPress}
          sortBy={sortBy}
          onLoadMore={handleLoadMore}
          onReportDown={handleExportCsv}
          page={page}
          onCategoryFilterPress={handleCategoryFilterPress}
          categoryFiltersIds={categoryFiltersIds}
          brushupHistory={brushupHistory}
          histories={Object.values(histories)}
          historyCount={historyCount}
          isHistoryLoading={isHistoryLoading}
          onHistoryLoadMore={handleHistoryLoadMore}
          isAssetExcelDownload={isAssetExcelDownload}
        />
      </div>
    </>
  );
});

export default StockReport;
