import moment from "moment";

import {
  getAfterTaxRefundAmount,
  getImmediateTaxRefundAmount,
  getMethodName,
} from "../reducers/order";
import Constant from "./Constants";
import _ from "lodash";
import { numberToCurrency } from "./Utils";
import { INVENTORY_TRANSACTION_BY_REASON, RETURN_REASON } from "./GuideData";

const RETURN_REASON_LABEL_BY_KEY = RETURN_REASON.reduce((obj, r) => {
  obj[r.key] = r.label;
  return obj;
}, {});

const VISIBLE_ORDER_ITEM_TYPE = [
  "sku",
  "custom",
  "reward",
  "credit",
  "coupon",
  "discount",
];

export const getPaymentInfo = (order) => {
  const orderAmount = getOrderAmount(order);
  const useCredit = getCreditAmount(order);
  const totalDiscountAmount = getDiscountAmount(order);
  const pointDiscountAmount = getRewardDiscountAmount(order);
  const discountAmount = totalDiscountAmount - pointDiscountAmount;
  const immediateTaxRefundAmount = getImmediateTaxRefundAmount(order) || 0;
  const afterTaxRefundAmount = getAfterTaxRefundAmount(order) || 0;
  const shippingFee = getShippingFee(order);
  const deliveryFee = getDeliveryFee(order);
  const paidAmount = getPaidAmount(order, false);
  const refundAmount = getRefundAmount(order);
  const payAmount = getPayAmount(order);
  const savePoint = order.pointSaved || 0;
  const tax = order.tax || 0;

  let unPaidAmount = 0;
  // NOTE: 정책 상 UI에서 환불금액을 미결제금액으로 보여주지 않음
  if (order.state === "created") {
    unPaidAmount = payAmount - paidAmount;
  } else {
    unPaidAmount = payAmount - getPaidAmount(order, false) - shippingFee;
  }
  if (unPaidAmount < 0) {
    unPaidAmount = 0;
  }
  return {
    useCredit,
    discountAmount,
    totalDiscountAmount,
    pointDiscountAmount,
    immediateTaxRefundAmount,
    afterTaxRefundAmount,
    shippingFee,
    deliveryFee,
    orderAmount,
    paidAmount,
    unPaidAmount,
    refundAmount,
    savePoint,
    payAmount,
    tax,
  };
};

export const brushupCharges = (charges) => {
  let _charges = [];
  (charges || []).forEach((charge) => {
    if (isPaymentCharge(charge)) {
      _charges = [
        ..._charges,
        {
          method: getMethodName(charge),
          extraMethod:
            (charge.method === "extra" && charge.extraMethod) || null,
          approvalNumber: charge.approvalNumber,
          note: charge.note || "",
          amount: charge.amount,
          createdAt: moment(charge.createdAt).format(
            Constant.MOMENT.DATETIME_FORMAT
          ),
        },
      ];
    }
  });

  return _charges;
};

export const getPaymentMethodsLabel = (order) => {
  let brushedCharges = brushupCharges(order.charges);
  return brushedCharges.length > 0
    ? brushedCharges.map((c) => c.method).join(", ")
    : "";
};

export const isPaymentCharge = (charge) => {
  return !["refund", "credit", "point"].includes(charge.method);
};

export const filterRefundCharge = (charge) => {
  return !["refund"].includes(charge.method);
};

export const getPaymentCharges = (charges) => {
  return (charges || []).filter((charge) => isPaymentCharge(charge));
};

export const getRefundCharges = (charges) => {
  return (charges || []).filter(
    (charge) => isPaymentCharge(charge) && !_.isEmpty(charge.refunds)
  );
};

export const brushupRefundItems = (charges) => {
  const refundCharges = getRefundCharges(charges);
  return refundCharges.map((item) => ({
    method: getMethodName(item, true),
    items: (item.refunds || []).map((refund) => ({
      right: `-${numberToCurrency(refund.amount)}`,
      left: moment(refund.createdAt).format(Constant.MOMENT.DATETIME_FORMAT),
      mid: RETURN_REASON_LABEL_BY_KEY[refund.reason],
    })),
  }));
};

export const getOrderAmount = (order) => {
  const amount = _.sumBy(filterOrderItems(order?.items), "amount") || 0;
  return amount;
};

export const filterOrderItems = (orderItems) => {
  return (orderItems || []).filter((item) =>
    ["sku", "credit", "custom"].includes(item.type)
  );
};

export const getShippingFee = (order) => {
  // NOTE: 카페24 배송비 환불 시, orderItem.amount는 그대로지만 order.fee가 0으로 변경된다.
  // 그래서 실제 배송비는 order.fee를 참조
  if (!getDeliveryFee(order)) {
    const shippingFee = order.fee;
    return shippingFee;
  } else {
    return 0;
  }
};

export const getDeliveryFee = (order) => {
  const shippingFee =
    _.sumBy(
      (order?.items || []).filter((item) => ["delivery"].includes(item.type)),
      "amount"
    ) || 0;

  return shippingFee;
};

export const getDiscountAmount = (order) => {
  const discountAmount =
    _.sumBy(
      (order?.items || []).filter((item) =>
        ["coupon", "discount"].includes(item.type)
      ),
      "amount"
    ) || 0;

  return discountAmount + getRewardDiscountAmount(order);
};

export const getRewardDiscountAmount = (order) => {
  const discountAmount =
    _.sumBy(
      (order?.items || []).filter((item) => ["reward"].includes(item.type)),
      "amount"
    ) || 0;

  return discountAmount + getCafe24PointAmount(order);
};

export const getCafe24PointAmount = (order) => {
  let cafe24DiscountAmount = 0;
  (order?.charges || []).forEach((charge) => {
    if (charge.method === "point") {
      cafe24DiscountAmount += charge.amount || 0;
      // (charge.refunds || []).forEach((refund) => {
      //   cafe24DiscountAmount -= refund.amount || 0;
      // });
    }
  });

  return cafe24DiscountAmount;
};

export const getCreditAmount = (order) => {
  let creditAmount = 0;
  (order?.charges || []).forEach((charge) => {
    if (charge.method === "credit") {
      creditAmount += charge.amount || 0;
      (charge.refunds || []).forEach((refund) => {
        creditAmount -= refund.amount || 0;
      });
    }
  });

  return creditAmount;
};

export const getPayAmount = (order) => {
  const orderAmount = getOrderAmount(order);
  const discountAmount = getDiscountAmount(order);
  const creditAmount = getCreditAmount(order);
  const immediateTaxRefundAmount = getImmediateTaxRefundAmount(order) || 0;
  let payAmount =
    orderAmount -
    discountAmount -
    creditAmount -
    immediateTaxRefundAmount +
    // NOTE: 총 주문금액에는 fee가 빠지지만 결제된 금액에는 fee가 포함되기때문에 fee를 더해준다.
    (order.fee || 0);
  return payAmount;
};

// NOTE: for UI
export const getHeadAmount = (order) => {
  const payAmount = getPayAmount(order);
  if (order.state === "created") {
    return payAmount;
  } else {
    const paidAmount = getPaidAmount(order, false);
    return paidAmount > payAmount ? payAmount : paidAmount;
  }
};

export const getRefundAmount = (order) => {
  let refundAmount = 0;
  const now = new Date();
  (order.charges || []).forEach((charge) => {
    if (isPaymentCharge(charge)) {
      if (!_.isEmpty(charge.refunds)) {
        charge.refunds.forEach((refund) => {
          // const isReturnedAfterPaid =
          //   new Date(refund.createdAt).getTime() >
          //   (new Date(order.paidAt) || now).getTime();
          // if (isReturnedAfterPaid) {
          refundAmount += _.sumBy(charge.refunds, "amount");
          // }
        });
      }
    }
  });
  return refundAmount;
};

export const getPaidAmount = (order, includeRefund = true) => {
  if (order.paidAmount) {
    return order.paidAmount;
  } else {
    let paidAmount = 0;
    (order.charges || []).forEach((charge) => {
      if (isPaymentCharge(charge)) {
        paidAmount = paidAmount + charge.amount;
        if (!_.isEmpty(charge.refunds) && includeRefund) {
          paidAmount -= _.sumBy(charge.refunds, "amount");
        }
      }
    });

    return paidAmount;
  }
};

export const brushupReturns = (order, key) => {
  const returns = {};
  for (let ret of order.returns || []) {
    for (let item of ret.items || []) {
      if (item[key] in returns) {
        returns[item[key]] = {
          ...returns[item[key]],
          quantity: returns[item[key]].quantity + item.quantity,
          amount: returns[item[key]].amount + item.amount,
        };
      } else {
        returns[item[key]] = item;
      }
    }
  }

  return returns;
};

const brushupRewardOrderItem = (item) => {
  return {
    leftTitle: `포인트사용(${item.name}, ${item.point}P)`,
    midTitle: item.quantity ? `x${item.quantity}` : "",
    rightTitle:
      (item.discountMethod ? "- " : "") + numberToCurrency(item.amount),
    note: item.note,
    itemId: item.parentId,
  };
};

const brushupOrderItemModifiers = (modifiers) => {
  return (modifiers || []).map((mod) => ({
    left: mod.name,
    mid: mod.quantity ? `x${mod.quantity}` : "",
    right: numberToCurrency(mod.amount),
  }));
};

const brushupOrderItemDiscounts = (discounts) => {
  let _discounts = [];
  (discounts || []).forEach((dis) => {
    if (dis.amount > 0 && dis.scope === "partial" && dis.type !== "reward") {
      _discounts = [
        ..._discounts,
        {
          left: dis.name,
          mid: dis.quantity > 0 ? `x${dis.quantity}` : "",
          right: `${"- "}${numberToCurrency(dis.amount)}`,
        },
      ];
    }
  });
  return _discounts;
};

const getQuantityLabel = (orderItem) => {
  const isVoided = orderItem.quantity === 0 && orderItem.voidQuantity > 0;
  let _quantityLabel = "";
  if (isVoided) {
    _quantityLabel = `x${orderItem.voidQuantity}`;
  } else if (orderItem.quantity) {
    if (!(orderItem.quantity === 1 && orderItem.type === "coupon")) {
      _quantityLabel = `x${orderItem.quantity}`;
    }
  }
  return _quantityLabel;
};

export const brushupOrderItems = (order) => {
  const items = order.items;
  let orderItems = [];

  for (let item of (items || []).filter((orderItem) =>
    VISIBLE_ORDER_ITEM_TYPE.includes(orderItem.type)
  )) {
    if (item.type === "reward") {
      orderItems = [...orderItems, brushupRewardOrderItem(item)];
    } else {
      const modifiers = brushupOrderItemModifiers(item.modifiers);
      const discounts = brushupOrderItemDiscounts(item.discounts);
      const diningOption = brushUpDiningOption(item, order);
      const isPartialDiscountOrCoupon =
        (item.discountIncludeIds || []).length > 0 ||
        (item.discountExcludeIds || []).length > 0;

      // NOTE: 부분할인은 subItems에 포함됨
      if (!isPartialDiscountOrCoupon) {
        const isVoided = item.quantity === 0 && item.voidQuantity > 0;
        orderItems = [
          ...orderItems,
          {
            leftTitle: item.name,
            midTitle: getQuantityLabel(item),
            rightTitle:
              (item.discountMethod ? "- " : "") + numberToCurrency(item.amount),
            note: item.note,
            subItems: item.description
              ? [
                  { left: item.description },
                  ...modifiers,
                  ...discounts,
                  ...diningOption,
                ]
              : [...modifiers, ...discounts, ...diningOption],
            createdAt: item.createdAt
              ? moment(item.createdAt).format(Constant.MOMENT.DATETIME_FORMAT)
              : null,
            referType: item.referType,
            itemId: item.parentId,
            isVoided: isVoided,
            originalQuantity: item.quantity,
            id: item.id,
          },
        ];
      }
    }
  }
  return orderItems;
};

export const brushupExchangeItems = (order) => {
  const returns = brushupReturns(order, "parentId");
  const items = Object.values(returns || {}).filter(
    (item) => item.referType === "exchange"
  );
  let exchangeItems = [];
  for (let item of (items || []).filter((orderItem) =>
    VISIBLE_ORDER_ITEM_TYPE.includes(orderItem.type)
  )) {
    const modifiers = brushupOrderItemModifiers(item.modifiers);
    const discounts = brushupOrderItemDiscounts(item.discounts);
    const inventoryText = {
      left: `반품처리: ${
        INVENTORY_TRANSACTION_BY_REASON[item.inventoryReason]?.label || ""
      }`,
    };
    const exchangedAt = item.createdAt
      ? [
          {
            left: `반품일: ${moment(item.createdAt).format(
              Constant.MOMENT.DATETIME_FORMAT
            )}`,
          },
        ]
      : [];
    exchangeItems = [
      ...exchangeItems,
      {
        leftTitle: item.name,
        midTitle: getQuantityLabel(item),
        rightTitle:
          (item.discountMethod ? "- " : "") + numberToCurrency(item.amount),
        note: item.note,
        subItems: item.description
          ? [
              { left: item.description },
              ...modifiers,
              ...discounts,
              inventoryText,
              ...exchangedAt,
            ]
          : [...modifiers, ...discounts, inventoryText, ...exchangedAt],
        itemId: item.parentId,
        createdAt: item.createdAt
          ? moment(item.createdAt).format(Constant.MOMENT.DATETIME_FORMAT)
          : null,
        referType: item.referType,
        id: item.id,
      },
    ];
  }
  return exchangeItems;
};

export const brushupReturnsWithoutExchange = (order, key) => {
  const returns = {};
  for (let ret of order.returns || []) {
    for (let item of (ret.items || []).filter(
      (i) => i.referType !== "exchange"
    )) {
      if (item[key] in returns) {
        returns[item[key]] = {
          ...returns[item[key]],
          quantity: returns[item[key]].quantity + item.quantity,
          amount: returns[item[key]].amount + item.amount,
        };
      } else {
        returns[item[key]] = item;
      }
    }
  }
  let returnItems = [];
  for (let item of (Object.values(returns || {}) || []).filter((orderItem) =>
    VISIBLE_ORDER_ITEM_TYPE.includes(orderItem.type)
  )) {
    if (item.type === "reward") {
      returnItems = [...returnItems, brushupRewardOrderItem(item)];
    } else {
      const modifiers = brushupOrderItemModifiers(item.modifiers);
      const discounts = brushupOrderItemDiscounts(item.discounts);
      const diningOption = brushUpDiningOption(item, order);
      const isPartialDiscountOrCoupon =
        (item.discountIncludeIds || []).length > 0 ||
        (item.discountExcludeIds || []).length > 0;

      // NOTE: 부분할인은 subItems에 포함됨
      if (!isPartialDiscountOrCoupon) {
        const isVoided = item.quantity === 0 && item.voidQuantity > 0;
        returnItems = [
          ...returnItems,
          {
            leftTitle: item.name,
            midTitle: getQuantityLabel(item),
            rightTitle:
              (item.discountMethod ? "- " : "") + numberToCurrency(item.amount),
            note: item.note,
            subItems: item.description
              ? [
                  { left: item.description },
                  ...modifiers,
                  ...discounts,
                  ...diningOption,
                ]
              : [...modifiers, ...discounts, ...diningOption],
            itemId: item.parentId,
            isVoided: isVoided,
            referType: item.referType,
            originalQuantity: item.quantity,
            id: item.id,
          },
        ];
      }
    }
  }
  return returnItems;
};

const brushUpDiningOption = (item, order) => {
  // NOTE: 상위 옵션이 "포장"일 때, 개별 아이템에서 "배달"을 눌렀다가 "포장"으로 수정한 경우
  // item.diningOption과 상위 diningOption이 동일하기때문에 문구 표기가 필요없다
  let name = null;
  if (item.diningOption) {
    if (item.diningOption === "forhere" && order.diningOption !== "forhere") {
      name = "매장주문";
    } else if (
      item.diningOption === "takeout" &&
      order.diningOption !== "takeout"
    ) {
      name = "포장";
    } else if (
      item.diningOption === "delivery" &&
      order.diningOption !== "delivery"
    ) {
      name = "배달";
    }
  }
  if (name) {
    return [
      {
        left: name,
        mid: null,
        right: null,
      },
    ];
  }
  return [];
};
