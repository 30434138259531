import { combineReducers } from "redux";
import * as actions from "../actions";
import _ from "lodash";

export const INIT_PURCHASE_ORDER = {
  expectDate: null,
  supplierOrigin: null,
  supplyingLocationId: null,
  supplierId: null,
  inventories: [],
};

export const STATUS_OPTIONS = [{ text: "발주 취소", value: "cancel" }];

export const checkOtherLocation = (purchaseOrder) => {
  if (
    purchaseOrder.supplierOrigin === "location" &&
    purchaseOrder.supplyingLocationId &&
    purchaseOrder.supplyingLocationId !== purchaseOrder.locationId
  ) {
    return true;
  }
  return false;
};

export const isEditable = (status) => {
  if (status === "received") {
    return false;
  }
  return true;
};

export const getTotalCost = (inventories, excludeVat) => {
  let totalCost = 0;
  for (let oi of inventories) {
    totalCost +=
      ((oi.waitingQuantity || 0) + (oi.receivedQuantity || 0)) * oi.cost || 0;
  }
  return excludeVat ? totalCost * 1.1 : totalCost;
};

export const getStatusLabel = (status) => {
  switch (status) {
    case "pending":
      return "대기상태";
    case "confirmed":
      return "발주확인";
    case "received":
      return "입고완료";
    case "partial":
      return "부분입고";
    default:
      return "-";
  }
};

export const getStatusColor = (status) => {
  switch (status) {
    case "pending":
      return "#838b96";
    case "confirmed":
      return "#009688";
    case "received":
      return "#337ef3";
    case "partial":
      return "#ff8b3d";
    default:
      return "#1a283e";
  }
};

export const getInventorySummaryName = (inven) => {
  let summaryName = "";
  if (!_.isEmpty(inven)) {
    if ((inven.attributes || []).length < 1) {
      summaryName = "기본 재고";
    } else {
      for (let attr of inven.attributes) {
        summaryName =
          summaryName +
          (!summaryName ? "" : " / ") +
          (!attr.value ? "" : attr.value);
      }
    }
  }
  return summaryName;
};

const initialState = {
  status: {
    isFetching: false,
    error: null,
  },
  byId: {},
};

function byId(state = initialState.byId, action) {
  switch (action.type) {
    case actions.RECEIVE_PURCHASE_ORDERS:
      const _purchaseOrders = action.purchaseOrders.reduce((obj, po) => {
        obj[po.id] = po;
        return obj;
      }, {});
      return _purchaseOrders;
    case actions.RECEIVE_PURCHASE_ORDER:
      return {
        ...state,
        [action.purchaseOrder.id]: action.purchaseOrder,
      };
    case actions.RECEIVE_DELETED_PURCHASE_ORDER:
      const { [action.purchaseOrder.id]: _, ...purchaseOrders } = state;
      return purchaseOrders;
    default:
      return state;
  }
}

function status(state = initialState.status, action) {
  switch (action.type) {
    case actions.CREATE_OR_UPDATE_PURCHASE_ORDER:
    case actions.DELETE_PURCHASE_ORDER:
    case actions.GET_PURCHASE_ORDERS:
    case actions.GET_PURCHASE_ORDER_BY_ID:
      return {
        isFetching: true,
        error: null,
      };
    case actions.SET_PURCHASE_ORDER_STATUS_SUCCESS:
      return initialState.status;
    case actions.SET_PURCHASE_ORDER_STATUS_FAILURE:
      return {
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default combineReducers({
  status,
  byId,
});
