import { combineReducers } from "redux";
import _ from "lodash";

import local from "./local";
import employee from "./employee";
import store from "./store";
import campaign from "./campaign";
import item, { getMarketModifiers, getModifierIds } from "./item";
import customer from "./customer";
import report from "./report";
import order from "./order";
import coupon from "./coupon";
import articles from "./articles";
import market, * as fromMarket from "./market";
import review from "./review";
import editing from "./editing";
import subscription from "./subscription";
import settlements from "./settlements";
import terminals from "./terminals";
import inventory from "./inventory";
import purchaseOrder from "./purchaseOrder";
import supplier from "./supplier";
import mgmt from "./mgmt";
import waiting from "./waiting";
import providerConfig from "./providerConfig";
import Authority from "./../services/Authority";
import Constants from "../helpers/Constants";

export default combineReducers({
  local,
  employee,
  store,
  item,
  campaign,
  customer,
  report,
  order,
  articles,
  market,
  coupon,
  review,
  editing,
  subscription,
  settlements,
  terminals,
  inventory,
  purchaseOrder,
  supplier,
  mgmt,
  waiting,
  providerConfig,
});

export function getSignUpState(state) {
  return state.local.signUp;
}

export function isNarrowWidth(state) {
  return state.local.narrowWidth.narrowWidth;
}

export function getPdfStatus(state) {
  return state.local.pdfStatus;
}

export function getDropoutStatus(state) {
  return state.local.dropoutStatus;
}

export function getOngoingRequestProgressEvents(state) {
  return Object.values(state.local.requestProgressEvents).filter(
    (e) => e.status === "ongoing" && e.progress !== 100
  );
}

export function getOngoingStockRequestProgressEvents(state) {
  return Object.values(state.local.requestProgressEvents).filter(
    (e) =>
      e.status === "ongoing" &&
      e.progress !== 100 &&
      [
        Constants.ASYNC_PROGRESS_FUNC.CANCEL_PURCHASE_ORDER,
        Constants.ASYNC_PROGRESS_FUNC.APPLY_PURCHASE_ORDER,
        Constants.ASYNC_PROGRESS_FUNC.APPLY_INVENTORY_TRANSFER_HISTORY,
        Constants.ASYNC_PROGRESS_FUNC.CANCEL_INVENTORY_TRANSFER_HISTORY,
        Constants.ASYNC_PROGRESS_FUNC.APPLY_INVENTORY_COUNT_HISTORY,
        Constants.ASYNC_PROGRESS_FUNC.APPLY_INVENTORY_ADJUSTMENTS,
        Constants.ASYNC_PROGRESS_FUNC.SYNC_INVENTORIES,
      ].includes(e.func)
  );
}

export function getOngoingPurchaseOrderRequestProgressEvents(state) {
  return Object.values(state.local.requestProgressEvents).filter(
    (e) =>
      e.status === "ongoing" &&
      e.progress !== 100 &&
      [
        Constants.ASYNC_PROGRESS_FUNC.CANCEL_PURCHASE_ORDER,
        Constants.ASYNC_PROGRESS_FUNC.APPLY_PURCHASE_ORDER,
      ].includes(e.func)
  );
}

export function getOngoingTransferRequestProgressEvents(state) {
  return Object.values(state.local.requestProgressEvents).filter(
    (e) =>
      e.status === "ongoing" &&
      e.progress !== 100 &&
      [
        Constants.ASYNC_PROGRESS_FUNC.APPLY_INVENTORY_TRANSFER_HISTORY,
        Constants.ASYNC_PROGRESS_FUNC.CANCEL_INVENTORY_TRANSFER_HISTORY,
      ].includes(e.func)
  );
}

export function getOngoingProductsProgressEvents(state) {
  return Object.values(state.local.requestProgressEvents).filter(
    (e) =>
      e.status === "ongoing" &&
      e.progress !== 100 &&
      [Constants.ASYNC_PROGRESS_FUNC.SYNC_PRODUCTS].includes(e.func)
  );
}

export function getFailedRequestProgressEvents(state) {
  return Object.values(state.local.requestProgressEvents).filter(
    (e) => e.status === "done" && e.errorMessage
  );
}

export function getAuthorizeModal(state) {
  return state.local.authorizeModal;
}

export function getModal(state) {
  return state.local.modal;
}

export function getEnvironment(state) {
  return state.local.environment;
}

export function getLocalEnvironment(state) {
  return state.local.localEnvironment;
}

export function getCustomerOpenState(state) {
  return state.local.customerInfo;
}

export function getEditingLocation(state) {
  return state.editing.location;
}

export function getEditingPlan(state) {
  return state.editing.plan;
}

export function getEditingEmployee(state) {
  return state.editing.employee;
}

export function getEditingNotice(state) {
  return state.editing.notice;
}

export function getEditingNoticeCampaign(state) {
  return state.editing.noticeCampaign;
}

export function storeStatus(state) {
  return state.store.storeStatus;
}

export function getCurrentCost(state) {
  return state.store.cost;
}

export function getCostStatus(state) {
  return state.store.costStatus;
}

export function billingHistoriesStatus(state) {
  return state.store.billingHistoriesStatus;
}

export function isStoreFetching(state) {
  return state.store.storeStatus.isFetching;
}

export function isBillingHistoriesFetching(state) {
  return state.store.billingHistoriesStatus.isFetching;
}

export function getStore(state) {
  return state.store.store || {};
}

export function getStoreStatus(state) {
  return state.store.storeStatus;
}

export function getStoreError(state) {
  return state.store.storeStatus.error;
}

export function getStoreCustomerGroups(state) {
  return state.store.store.groups ? state.store.store.groups.items : [];
}

export function getStoreCustomerConfig(state) {
  return state.store.store.customer || {};
}

export function getBillingHistoriesError(state) {
  return state.store.billingHistoriesStatus.error;
}

export function getStoreInfo(state) {
  return (state.store.store.info ? state.store.store.info : {}) || {};
}

export function getStoreItemConfig(state) {
  return (state.store.store.item ? state.store.store.item : {}) || {};
}

export function getStoreLoyalty(state) {
  return getStore(state).loyalty || {};
}

export function getStoreLoyaltyRwardMinRequireRate(state) {
  return getStoreLoyalty(state).reward?.minRequireRate || 0;
}

export function getStoreInventoryConfig(state) {
  return (state.store.store.inventory ? state.store.store.inventory : {}) || {};
}

export function getStoreMarketConfig(state) {
  return (state.store.store.market ? state.store.store.market : {}) || {};
}

export function getStoreLocations(state) {
  return state.store.store.locations?.items || [];
}

export function getStoreLocationsById(state) {
  return getStoreLocations(state).reduce((obj, loc) => {
    obj[loc.id] = _.cloneDeep(loc);
    return obj;
  }, {});
}

export function getOfflineLocations(state) {
  let locations = getStoreLocations(state);
  return locations.filter((loc) => !loc.provider && loc.type !== "warehouse");
}

export function getActiveLocations(state) {
  let locations = getStoreLocations(state);
  return locations.filter((loc) => loc.state !== "inactive");
}

export function getActiveOfflineLocations(state) {
  let locations = getOfflineLocations(state);
  return locations.filter((loc) => loc.state !== "inactive");
}

export function getWarehouseLocations(state) {
  let locations = getStoreLocations(state);
  return locations.filter((loc) => loc.type === "warehouse");
}

export function getInactiveLocations(state) {
  let locations = getStoreLocations(state);
  return locations.filter((loc) => loc.state === "inactive");
}

export function getExternalLocations(state) {
  let locations = getStoreLocations(state);
  return locations.filter((loc) => loc.provider && loc.type === "external");
}

export function getCafe24Location(state) {
  const locations = getExternalLocations(state);
  return locations.filter((loc) => loc.provider === "cafe24")[0] || {};
}

export function getOfflineLocationsById(state) {
  let locations = getOfflineLocations(state);
  return locations.reduce((obj, loc) => {
    obj[loc.id] = _.cloneDeep(loc);
    return obj;
  }, {});
}

export function getActvieOfflineLocationsById(state) {
  let locations = getActiveOfflineLocations(state);
  return locations.reduce((obj, loc) => {
    obj[loc.id] = _.cloneDeep(loc);
    return obj;
  }, {});
}

export function getWarehouseLocationsById(state) {
  let locations = getWarehouseLocations(state);
  return locations.reduce((obj, loc) => {
    obj[loc.id] = _.cloneDeep(loc);
    return obj;
  }, {});
}

export function getInventoryLocations(state) {
  const warehouseLocations = getWarehouseLocations(state);
  const offlineLocations = getActiveOfflineLocations(state);
  const cafe24Location = getCafe24Location(state);
  const cafe24Provider = getCafe24Provider(state);
  if (!_.isEmpty(cafe24Provider) && cafe24Provider?.inventory) {
    return [...warehouseLocations, ...offlineLocations, cafe24Location];
  } else {
    return [...warehouseLocations, ...offlineLocations];
  }
}

export function getInventoryLocationsById(state) {
  const locations = getInventoryLocations(state);
  return locations.reduce((obj, loc) => {
    obj[loc.id] = _.cloneDeep(loc);
    return obj;
  }, {});
}

export function isAbleToTransfer(state) {
  const inventoryLocations = getInventoryLocations(state);
  return (inventoryLocations || []).length > 1;
}

export function getActiveStoreLocationsById(state) {
  let locations = getActiveLocations(state);
  return locations.reduce((obj, loc) => {
    obj[loc.id] = _.cloneDeep(loc);
    return obj;
  }, {});
}

export function getInactiveLocationsById(state) {
  let locations = getInactiveLocations(state);
  return locations.reduce((obj, loc) => {
    obj[loc.id] = _.cloneDeep(loc);
    return obj;
  }, {});
}

export function getLocationsWithLocationAdminAccess(state) {
  let locations = getStoreLocations(state);
  const currentOrg = getCurrentOrganization(state);
  const hasAdminAccess = hasLocationAdminAccess(state);
  return (locations || []).filter(
    (loc) =>
      loc.id === currentOrg.locationId ||
      loc.type === "external" ||
      hasAdminAccess
  );
}

export function getStoreNotification(state) {
  return state.store.store.notification ? state.store.store.notification : {};
}

export function getReceiptConfig(state) {
  const _isMultiLocation = isMultiLocation(state);
  if (_isMultiLocation) {
    return getCurrentLocation(state).receipt || {};
  } else {
    return state.store.store.receipt || {};
  }
}

export function getViewConfig(state) {
  const _isMultiLocation = isMultiLocation(state);
  if (_isMultiLocation) {
    return getCurrentLocation(state).view || {};
  } else {
    return state.store.store.view || {};
  }
}

export function getStorePayments(state) {
  return state.store.store.payments ? state.store.store.payments : {};
}

export function getPaymentsForTaxRefund(state) {
  const _isMultiLocation = isMultiLocation(state);
  if (_isMultiLocation) {
    return getCurrentLocation(state).payments || {};
  } else {
    return state.store.store.payments || {};
  }
}

export function isContracted(state) {
  return state.store.contract.store?.businessOperatorId ?? false;
}

export function getStoreBilling(state) {
  return state.store.store.billing ? state.store.store.billing : {};
}

export function getBilling(state) {
  const location = getCurrentLocation(state);
  const org = getCurrentOrganization(state);
  if (org.role && !["owner", "admin"].includes(org.role)) {
    if (location.type === "indirect") {
      return location.billing || {};
    }
  }
  return state.store.store?.billing || {};
}

export function getRequestProgressEventOrigin(state) {
  const org = getCurrentOrganization(state);
  const originId = ["owner", "admin"].includes(org.role)
    ? org.storeId
    : org.locationId;
  return { name: "dashboard", id: originId };
}

export function getStoreDevices(state) {
  return state.store.store.devices ? state.store.store.devices : [];
}

export function getPosMiniDevices(state) {
  let storeDevices = getStoreDevices(state);
  return storeDevices.filter(
    (d) => d.type.toLowerCase() === "pos" || d.type.toLowerCase() === "mini"
  );
}

export function getStoreBillingHistories(state) {
  return state.store.billingHistories ? state.store.billingHistories : [];
}

export function getStoreFailedBillingHistories(state) {
  return getStoreBillingHistories(state).filter(
    (his) => his.status === "failed"
  );
}

export function getStoreRegularBillingHistories(state) {
  return getStoreBillingHistories(state).filter(
    (his) => his.type === "regular"
  );
}

export function getFailedRegualarBillingHistories(state) {
  const regularBillingHistories = getStoreRegularBillingHistories(state);
  return regularBillingHistories.filter((his) => his.status === "failed");
}

export function getStoreId(state) {
  return state.store.store ? state.store.store.id : null;
}

export function getStoreName(state) {
  return state.store.store.info ? state.store.store.info.name : null;
}

export function getStoreTaxes(state) {
  return state.store.store.taxes ? state.store.store.taxes : [];
}

export function getLocationPhone(state) {
  const multiLocation = isMultiLocation(state);
  const location = getCurrentLocation(state);
  const store = getStore(state);
  return multiLocation && Authority.level() !== "store"
    ? location.phone
    : store.info.phone;
}

export function getLocationName(state) {
  const _isMultiLocation = isMultiLocation(state);
  const location = getCurrentLocation(state);
  const store = getStore(state);
  return _isMultiLocation && Authority.level() !== "store"
    ? location.name
    : store.info?.name;
}

export function getSettings(state) {
  const _isMultiLocation = isMultiLocation(state);
  const location = getCurrentLocation(state);
  const store = getStore(state);
  return _isMultiLocation && Authority.level() !== "store"
    ? location.settings
    : store.settings;
}

export function isRetailMode(state) {
  const store = getStore(state);
  return store.settings?.mode === "retail";
  // return true;
}

export function getReportBasis(state) {
  const _isMultiLocation = isMultiLocation(state);
  const location = getCurrentLocation(state);
  const store = getStore(state);
  return _isMultiLocation && Authority.level() !== "store"
    ? location.settings?.reportBasis ?? null
    : store.settings?.reportBasis ?? null;
}

export function getSalesStartedAt(state) {
  const location = getCurrentLocation(state);
  const _isMultiLocation = isMultiLocation(state);
  const _startedAt = location.sales?.startedAt ?? null;
  const isHeadOffice = _isMultiLocation && Authority.level() === "store";
  return isHeadOffice ? new Date() : _startedAt;
}

export function getSalesStartedAtForSalesDate(state) {
  const location = getCurrentLocation(state);
  const _startedAt = location.state === "opened" && location.sales?.startedAt;
  return _startedAt ? _startedAt : null;
}

export function getSalesStartedAtForMarket(state) {
  const storeInfo = getCartStoreInfo(state);
  const _startedAt =
    storeInfo?.state === "opened" && storeInfo.sales?.startedAt;
  return _startedAt ? _startedAt : null;
}

export function getLoyalty(state) {
  return state.store.store.loyalty ? state.store.store.loyalty : {};
}

export function getMarketConfig(state) {
  return state.store.store.market ? state.store.store.market : {};
}

export function getKioskConfig(state) {
  return state.store.store.kiosk ? state.store.store.kiosk : {};
}

export function getKakaoConfig(state) {
  return state.store.store.kakao || {};
}

export function isKakaoChannelActive(state) {
  const kakao = state.store.store.kakao;
  return kakao?.channelId && kakao?.status === "active";
}

export function getStorePolicy(state) {
  return state.store.store.policy || {};
}

export function getMenuPlans(state) {
  return state.store.store.plans?.menus || [];
}

export function isSyncMenuStatusFetching(state) {
  return state.store.menuStatus.isFetching;
}

export function getMenuSyncStatusError(state) {
  return state.store.menuStatus.error;
}

export function getOrderPlans(state) {
  return state.store.store.plans?.orders || [];
}

export function getReviewConfig(state) {
  return state.store.store.review || {};
}

export function getSubscriptionConfig(state) {
  return state.store.store.subscription ? state.store.store.subscription : {};
}

export function getMarketAccount(state) {
  const multiLocation = isMultiLocation(state);
  const location = getCurrentLocation(state);
  if (multiLocation && location.type === "indirect") {
    return location.market?.account || {};
  } else {
    return state.store.store.market?.account || {};
  }
}

export function getSubscriptionAccount(state) {
  const multiLocation = isMultiLocation(state);
  const location = getCurrentLocation(state);
  if (multiLocation && location.type === "indirect") {
    return location.subscription?.account || {};
  } else {
    return state.store.store.subscription?.account || {};
  }
}

export function getLabelTemplates(state) {
  return state.store.templates || [];
}

export function getTemplateStatus(state) {
  return state.store.templateStatus || [];
}

export function getCampaignsById(state) {
  return state.campaign.campaigns;
}

export function getCampaigns(state) {
  return Object.values(state.campaign.campaigns);
}

export function getCampaignStatus(state) {
  return state.campaign.campaignStatus;
}

export function getExpectedRecipientNumber(state) {
  return state.campaign.expectedRecipientNumber;
}

export function getItemStatus(state) {
  return state.item.itemStatus;
}

export function isLegacyItems(state) {
  return state.item.items.legacy;
}

export function getItems(state) {
  return state.item.items;
}

export function getProductsWithDependency(state) {
  const currentLocationId = getCurrentLocationId(state);
  const items = getItems(state);
  const categoriesByProduct = items.categoryProducts.reduce((obj, pc) => {
    if (!(pc.productId in obj)) {
      obj[pc.productId] = [];
    }
    obj[pc.productId] = [...obj[pc.productId], pc.categoryId];
    return obj;
  }, {});
  const modifiersByProduct = items.productModifiers.reduce((obj, pc) => {
    if (!(pc.productId in obj)) {
      obj[pc.productId] = [];
    }
    obj[pc.productId] = [...obj[pc.productId], pc.modifierId];
    return obj;
  }, {});

  return items.products.map((p) => ({
    ...p,
    locationIds:
      !p.locationIds || p.locationIds.length === 0
        ? [currentLocationId]
        : p.locationIds,
    modifierIds: modifiersByProduct[p.id] || [],
    categoryIds: categoriesByProduct[p.id] || [],
  }));
}

export function getItemInventoryHistories(state) {
  return state.item.histories;
}

export function getInventoryHistories(state) {
  return state.inventory.histories;
}

export function getInventoryHistoriesById(state) {
  return state.inventory.histories.byId;
}

export function getInventoryHistorySummaryBySkuId(state) {
  return state.inventory.summary.bySkuId;
}

export function getInventoryHistoryCount(state) {
  return state.inventory.histories.count;
}

export function getInventoryHistoryItemCount(state) {
  return state.inventory.histories.currentItemCount;
}

export function getInventoryTransferHistoriesById(state) {
  return state.inventory.transfer.byId;
}

export function getInventoryTransferHistoryCount(state) {
  return state.inventory.transfer.count;
}

export function receiveInventoryCountHistoriesById(state) {
  return state.inventory.count.byId;
}

export function receiveInventoryCountHistoriesCount(state) {
  return state.inventory.count.count;
}

export function getInventoryStatus(state) {
  return state.inventory.status;
}

export function getProducts(state) {
  return state.item.items.products ? state.item.items.products : [];
}

export function getProductsById(state) {
  const productsById = getProducts(state).reduce((obj, prod) => {
    obj[prod.itemId || prod.id] = {
      ...prod,
      skusById: prod.skus.reduce((skuObj, sku) => {
        skuObj[sku.itemId] = sku;
        return skuObj;
      }, {}),
    };
    return obj;
  }, {});
  return productsById;
}

export function getActivateProductsById(state) {
  let products = getProducts(state).filter((prod) => {
    for (let sku of prod.skus) {
      if (sku.isActive === false) {
        return false;
      } else {
        return true;
      }
    }
  });
  return products.reduce((obj, p) => {
    obj[p.id] = p;
    return obj;
  }, {});
}

export function getMarketProductsById(state) {
  const modifiers = getModifiers(state).reduce((obj, m) => {
    obj[m.itemId] = m;
    return obj;
  }, {});
  const categories = getCategories(state).reduce((obj, c) => {
    obj[c.itemId] = c;
    return obj;
  }, {});
  return getProducts(state).reduce((obj, prod) => {
    obj[prod.itemId] = {
      ...prod,
      modifiers: {
        items: getMarketModifiers(
          state.item.items.productModifiers || [],
          prod.itemId,
          modifiers
        ),
      },
      categories: {
        items: (state.item.items.categoryProducts || [])
          .filter((item) => item.productId === prod.itemId)
          .map((item) => categories[item.categoryId]),
      },
    };
    return obj;
  }, {});
}

export function getModifiers(state) {
  return state.item.items.modifiers ? state.item.items.modifiers : [];
}

export function getCategories(state) {
  return state.item.items.categories ? state.item.items.categories : [];
}

export function getCategoriesById(state) {
  return getCategories(state).reduce((obj, cate) => {
    obj[cate.itemId] = cate;
    return obj;
  }, {});
}

export function getCategoryProducts(state) {
  return state.item.items.categoryProducts
    ? state.item.items.categoryProducts
    : [];
}

export function getProductModifiers(state) {
  return state.item.items.productModifiers
    ? state.item.items.productModifiers
    : [];
}

export function getModifiersById(state) {
  return getModifiers(state).reduce((obj, mod) => {
    obj[mod.itemId] = mod;
    return obj;
  }, {});
}

export function getLoyaltyReportStatus(state) {
  return state.store.loyaltyReportStatus;
}

export function getCustomersStats(state) {
  return state.customer.customersStats.byId;
}

export function getCustomersStatsCount(state) {
  return state.customer.customersStats.count;
}

export function getCustomerHistoryStatus(state) {
  return state.customer.historyStatus;
}

export function getCurrentLocationId(state) {
  const location = getCurrentLocation(state);
  if (location.id) {
    return location.id;
  }
  return null;
}

export function getCurrentLocationBilling(state) {
  const location = getCurrentLocation(state);
  return location.billing || {};
}

export function isMultiLocation(state) {
  const policy = getStorePolicy(state);
  return policy.multiLocation ? true : false;
}

export function isHeadOffice(state) {
  const isMulti = isMultiLocation(state);
  return isMulti && Authority.level() === "store";
}

// 여러개 지점 혹은 여러 채널을 보유한 것을 확인할때
export function isMultiChannel(state) {
  const policy = getStorePolicy(state);
  return policy?.multiLocation || !_.isEmpty(policy?.provider);
}

export function hasLocationInventory(state) {
  const hasMulti = isMultiLocation(state);
  const locations = getInventoryLocations(state);
  return (!hasMulti && (locations || []).length > 1) || hasMulti;
}

export function getCurrentLocation(state) {
  const organization = getCurrentOrganization(state);
  if (organization.locationId) {
    const locations = getStoreLocationsById(state);
    return locations[organization.locationId] || {};
  }
  return state.store.store.location ? state.store.store.location : {};
}

export function getSalesReportStatus(state) {
  return state.report.salesStatus;
}

export function getSalesReport(state) {
  return state.report.sales;
}

export function getItemsReportStatus(state) {
  return state.report.itemsStatus;
}

export function getItemsReport(state) {
  return state.report.items;
}

export function getOrderReport(state) {
  return state.report.order;
}

export function getCustomerReport(state) {
  return state.report.customer;
}

export function getOrderReportStatus(state) {
  return state.report.orderStatus;
}

export function getChannelOrderReport(state) {
  return state.report.channel;
}

export function getChannelOrderReportStatus(state) {
  return state.report.channelStatus;
}

export function getPointReport(state) {
  return state.report.point;
}

export function getPointReportStatus(state) {
  return state.report.pointStatus;
}

export function getDeliveryReport(state) {
  return state.report.delivery;
}

export function getDeliveryReportStatus(state) {
  return state.report.deliveryStatus;
}

export function getTrsReport(state) {
  return state.report.trs;
}

export function getTrsItemReport(state) {
  return state.report.trsItem;
}

export function getReviewReport(state) {
  return state.report.review;
}

export function getReviewReportStatus(state) {
  return state.report.reviewStatus;
}

export function getLoyaltyReport1(state) {
  return state.report.loyalty;
}

export function getLoyaltyReport1Status(state) {
  return state.report.loyaltyStatus;
}

export function getSalesHistoriesStatus(state) {
  return state.report.dailyStatus;
}

export function getSalesHistories(state) {
  return state.report.daily;
}

export function getOrderHistoriesStatus(state) {
  return state.order.historiesStatus;
}

export function getSubscriptionReport(state) {
  return state.report.subscription;
}

export function getSubscriptionReportStatus(state) {
  return state.report.subscriptionStatus;
}

export function getInventoryReport(state) {
  return state.report.inventory;
}

export function getInventoryReportStatus(state) {
  return state.report.inventoryStatus;
}

export function getOrderHistories(state) {
  return state.order.histories;
}

export function getPointTransactions(state) {
  return state.store.points;
}

export function getPointTransactionStatus(state) {
  return state.store.pointStatus;
}

export function getOrdersById(state) {
  return state.order.orders.byId;
}

export function getOrdersByReceiptId(state) {
  return Object.values(state.order.orders.byId).reduce((obj, o) => {
    obj[o.receiptId] = o;
    return obj;
  }, {});
}

export function getOrders(state) {
  return state.order.orders;
}

export function getShippingOrders(state) {
  return Object.values(state.order.orders.byId).filter(
    (o) => o.retailOption === "shipping"
  );
}

export function getNotDeliveredOrdersCount(state) {
  return Object.values(state.order.orders.byId).filter(
    (o) =>
      o.diningState &&
      o.diningState !== "delivered" &&
      o.diningState !== "cancelled"
  ).length;
}

export function getOrderStatus(state) {
  return state.order.orderStatus;
}

export function getCustomers(state) {
  return Object.values(state.customer.customers.byId);
}

export function getCustomerCount(state) {
  return state.customer.customers.count;
}

export function getCustomersByTag(state) {
  return Object.values(state.customer.customersByTag.byId);
}

export function getCustomerCountByTag(state) {
  return state.customer.customersByTag.count;
}

export function getCustomersByPhone(state) {
  return getCustomers(state).reduce((obj, c) => {
    obj[c.phone] = c;
    return obj;
  }, {});
}

export function getCustomersById(state) {
  return state.customer.customers.byId;
}

export function getRetrievedCustomer(state) {
  return state.customer.retrieved;
}

export function getCustomerStatus(state) {
  return state.customer.customerStatus;
}

export function getCustomerGroups(state) {
  return state.customer.groups.items || [];
}

export function getCustomerGroupsById(state) {
  return (state.customer.groups.items || []).reduce((obj, g) => {
    obj[g.id] = g;
    return obj;
  }, {});
}

export function getCustomerList(state) {
  return state.customer.groups.list || [];
}

export function getCustomerGroupStatus(state) {
  return state.customer.groupStatus;
}

export function getCustomerTags(state) {
  return Object.values(getCustomerTagsById(state));
}

export function getCustomerTagsById(state) {
  return state.customer.tagsById || {};
}

export function getCustomerTagStatus(state) {
  return state.customer.tagStatus;
}

export function getLoyaltySummary(state) {
  return state.report.loyalty;
}

export function getLoyaltySummaryStatus(state) {
  return state.report.loyaltyStatus;
}

export function getSalesSummary(state) {
  return state.report.summary;
}

export function getSalesSummaryStatus(state) {
  return state.report.summaryStatus;
}

// Employee
export function getEmployee(state) {
  return state.employee.employee || {};
}

export function getEmployeesById(state) {
  return state.employee.employees || {};
}

export function getAllEmployeesById(state) {
  let employee = state.employee.employee || {};
  let employeesById = state.employee.employees || {};
  employeesById = {
    ...employeesById,
    [employee.id]: employee,
  };
  return employeesById;
}

export function getCurrentOrganization(state) {
  return state.employee.organization ?? null;
}

export function getOrganizations(state) {
  return state.employee.organizations || [];
}

export function getOrganizationsByEmployeeId(state) {
  const employeesById = getOrganizations(state).reduce((obj, org) => {
    obj[org.employee.id] = {
      ...org.employee,
      organizations: [
        ...(obj[org.employee.id]?.organizations || []),
        _.cloneDeep(org),
      ],
    };
    return obj;
  }, {});
  return employeesById;
}

export function getOrganizationsByEmployee(state) {
  const employees = Object.values(
    getOrganizations(state).reduce((obj, org) => {
      obj[org.employee.id] = {
        ...org.employee,
        organizations: [
          ...(obj[org.employee.id]?.organizations || []),
          _.cloneDeep(org),
        ],
      };
      return obj;
    }, {})
  );
  return employees;
}

export function hasLocationAdminAccess(state) {
  // 전체관리자 권한이 있는지
  const currentOrg = getCurrentOrganization(state);
  const isMulti = isMultiLocation(state);
  return (isMulti && !currentOrg.locationId) || !isMulti;
}

export function getEmployeeStatus(state) {
  return state.employee.status;
}

export function getEmployeeStatusError(state) {
  return state.employee.status.error;
}

export function isEmployeeStatusFetching(state) {
  return state.employee.status.isFetching;
}

// Notice
export function getArticleStatus(state) {
  return state.articles.status;
}

export function getNotices(state) {
  return state.articles.notices;
}

export function getArchivedNotices(state) {
  return state.articles.archives;
}

export function getNoticeCampaigns(state) {
  return state.articles.campaigns;
}

// Around market
export function getProductsByStoreId(state) {
  return state.market.products;
}

export function getSkusById(state) {
  if (state.item.items.products) {
    return _.cloneDeep(state.item.items.products).reduce((obj, prod) => {
      for (let sku of prod.skus) {
        obj[sku.itemId] = {
          ...sku,
          parentId: prod.itemId,
          taxId: prod.taxId,
          trackStock: sku.stockTrack || prod.trackStock,
        };
      }
      return obj;
    }, {});
  }
  return {};
}

export function getSkusByUnit(state) {
  if (state.item.items.products) {
    return _.cloneDeep(state.item.items.products).reduce((obj, prod) => {
      for (let sku of prod.skus) {
        obj[sku.unit || sku.barcode] = {
          ...sku,
          parentId: prod.itemId,
          taxId: prod.taxId,
          trackStock: sku.stockTrack || prod.trackStock,
        };
      }
      return obj;
    }, {});
  }
  return {};
}

export function getPointStatsByStoreId(state) {
  return state.market.stats;
}

export function getUser(state) {
  return state.market.user;
}

export function getMarketUserPaymentMethod(state) {
  return state.market.user.payments || [];
}

export function getMarketUserSubscription(state) {
  const subscriptions = state.market.user.subscriptions || [];
  return subscriptions.reduce((obj, sub) => {
    obj[sub.id] = sub;
    return obj;
  }, {});
}

export function isUserStatusFetching(state) {
  return state.market.status.isFetching;
}

export function getUserStatusError(state) {
  return state.market.status.error;
}

export function getUserStatusAction(state) {
  return state.market.status.action;
}

export function getMarketStatus(state) {
  return state.market.status;
}

export function getMarketOrderStatus(state) {
  return state.market.orderStatus;
}

export function getMarketStoreStatus(state) {
  return state.market.storeStatus;
}

export function getMarketStores(state) {
  return state.market.stores;
}

export function getMarketStorePolicy(state) {
  return state.market.store.policy;
}

export function getMarketStoreInfo(state) {
  return {
    ...(state.market.store.info || {}),
    ...(state.market.store.location || {}),
  };
}

export function getMarketStoreLocationIds(state) {
  return state.market.store.locationIds || [];
}

export function getMarketStoreConfig(state) {
  return state.market.store.market || {};
}

export function getMarketStoreStats(state) {
  return state.market.store.stats || {};
}

export function getMarketStoreLoyalty(state) {
  return state.market.store.loyalty || {};
}

export function getMarketStoreTaxes(state) {
  return (state.market.store.taxes || []).reduce((obj, tax) => {
    obj[tax.id] = tax;
    return obj;
  }, {});
}

export function getMarketStoreId(state) {
  return state.market.store.id;
}

export function getMarketStorePoint(state) {
  return _.sum(
    (state.market.store.points?.items || []).map((p) =>
      p.type === "save" ? p.point : -p.point
    )
  );
}

export function getMarketStoreCredit(state) {
  return _.sum(
    (state.market.store.credits?.items || []).map((c) =>
      c.type === "deposit" ? c.amount : -c.amount
    )
  );
}

export function getMarketReceiptStore(state) {
  return state.market.receiptStore || {};
}

export function getCart(state) {
  return state.market.cart || {};
}

export function getCartStoreInfo(state) {
  return {
    ...(state.market.cart.store?.info || {}),
    ...(state.market.cart.store?.location || {}),
  };
}

export function getCartStoreMarketConfig(state) {
  return state.market.cart.store?.market || {};
}

export function getSkuProducts(state) {
  if (state.item.items.products) {
    return _.cloneDeep(state.item.items.products).reduce((obj, prod) => {
      for (let sku of prod.skus) {
        obj[sku.itemId] = prod;
      }
      return obj;
    }, {});
  }
  return {};
}

export function getCartSavePoint(state) {
  const point = fromMarket.getLoyaltySavePointByOrder(
    getCart(state),
    getMarketStoreLoyalty(state),
    getSkuProducts(state)
  );
  return point;
}

export function getCartUsePoint(state) {
  const point = fromMarket.getLoyaltyUsePointByOrder(
    getCart(state),
    getMarketStoreLoyalty(state)
  );
  return point;
}

export function isCartSavablePoint(state) {
  const point = getCartSavePoint(state);
  return !_.isEmpty(point) && point.point > 0;
  // TODO
  // if (customer) {
  //   const transactions = getPointsByCustomerId(state);
  //   return fromStore.isSavablePoint(
  //     getStoreLoyalty(state),
  //     customer,
  //     getCheckout(state),
  //     getCheckoutSavePoint(state),
  //     transactions[customer.id],
  //   );
  // }
  // return false;
}

export function isSidebarOpen(state) {
  return state.market.sidebar;
}

export function isMarketAuthenticated(state) {
  return state.market.isAuthenticated;
}

export function getCheckout(state) {
  return state.market.checkout;
}

// Coupon

export function getCouponsById(state) {
  return state.coupon.byId || {};
}

export function getBarcodeCouponsById(state) {
  const _coupons = getCouponsById(state);
  return Object.values(_coupons)
    .filter(
      (coupon) => coupon.type === "general" && coupon.method === "barcode"
    )
    .reduce((obj, c) => {
      obj[c.id] = c;
      return obj;
    }, {});
}

export function getCouponStatus(state) {
  return state.coupon.status;
}

export function getSearchedCouponTransactions(state) {
  console.log(state.coupon.searchedTransactions.byId);
  return state.coupon.searchedTransactions.byId || {};
}

export function hasMoreCouponTransactions(state) {
  return state.coupon.searchedTransactions.nextToken || null;
}

export function getCouponAction(state) {
  return state.coupon.status.action;
}

export function getProviderCouponsById(state) {
  return state.coupon.providerById || {};
}

// Review Campaigns

export function getReviewCampaignStatus(state) {
  return state.review.status;
}

export function getReviewCampaignsById(state) {
  return state.review.campaigns;
}

export function getReviewTemplates(state) {
  return Object.values(state.review.campaigns).filter(
    (c) => c.status === "draft"
  );
}

export function getWorkingReviewCampaign(state) {
  const config = getReviewConfig(state);
  if (config.campaignId && config.campaignId in state.review.campaigns) {
    return state.review.campaigns[config.campaignId];
  }
  return {};
}

export function getReviewCampaigns(state) {
  return Object.values(state.review.campaigns).filter(
    (c) => c.status !== "draft"
  );
}

export function getReviewCampaignResultsList(state) {
  return state.review.results.list;
}

export function getReviewCampaignResultsStats(state) {
  return state.review.results.stats;
}

// Subscription
export function isSubscriptionFetching(state) {
  return state.subscription.status.isFetching;
}

export function getSubscriptionError(state) {
  return state.subscription.status.error;
}

export function getSubscriptionAction(state) {
  return state.subscription.status.action;
}

export function getSubscriptionPlans(state) {
  return state.subscription.plans || {};
}

export function getEditingSubscriptionPlan(state) {
  return state.subscription.editing || {};
}

export function getSubscriptionEvents(state) {
  return state.subscription.events || [];
}

export function getSubscriptionEditing(state) {
  return state.subscription.subscriptionEditing || {};
}

// Settlements
export function getSettlements(state) {
  return state.settlements.settlements;
}

export function getSettlementsStatus(state) {
  return state.settlements.status;
}

// Terminals

export function getTerminals(state) {
  return state.terminals.terminals;
}

export function getTerminalStatus(state) {
  return state.terminals.status;
}

// PurchaseOrders

export function getPurchaseOrdersById(state) {
  return state.purchaseOrder.byId;
}

export function getPurchaseOrders(state) {
  return Object.values(getPurchaseOrdersById(state));
}

export function getPurchaseOrderStatus(state) {
  return state.purchaseOrder.status;
}

// Suppliers

export function getSuppliersById(state) {
  return state.supplier.byId || {};
}

export function getSuppliersName(state) {
  const suppliers = Object.values(getSuppliersById(state));
  return suppliers.map((sup) => sup.name.trim());
}

export function getSupplierStatus(state) {
  return state.supplier.status;
}

// MGMT

export function getMgmtStatus(state) {
  return state.mgmt.status;
}

export function getMgmtContract(state) {
  return state.mgmt.contract;
}

export function getSearchedMgmtStores(state) {
  return state.mgmt.searched;
}

export function getMgmtLocations(state) {
  return state.mgmt.locations;
}

export function getSearchedOperatorStores(state) {
  return state.mgmt.searchedStores;
}

export function getCurrentLocationMenuConfig(state) {
  return getCurrentLocation(state)?.menu || {};
}

export function getMenuBoardConfig(state) {
  let config = getCurrentLocationMenuConfig(state);
  return { ...(config.board || {}), pages: config.board?.pages || [] };
}

// providerConfig

export function getCafe24Config(state) {
  return state.providerConfig.config || {};
}

export function getOfflineUsableCouponIds(state) {
  return state.providerConfig.config?.usableCouponIds || [];
}

export function getProviderConfigStatus(state) {
  return state.providerConfig.status || {};
}

export function getStoreProvider(state) {
  return state.store.store.policy?.provider || {};
}

export function getCafe24Provider(state) {
  const provider = getStoreProvider(state);
  return provider?.key === "cafe24" ? provider : {};
}

export function isCafe24Provider(state) {
  const provider = getStoreProvider(state);
  return provider?.key === "cafe24";
}

export function isOutsourcedCustomer(state) {
  const provider = getStoreProvider(state);
  return !_.isEmpty(provider) && provider.customer;
}

export function getUpdateTierHistories(state) {
  return state.providerConfig.histories || [];
}

export function getStockSyncHistories(state) {
  return state.providerConfig.inventoryHistories || [];
}

export function getLinkHistories(state) {
  return state.providerConfig.linkHistories || [];
}

// waiting

export function getWaiting(state) {
  return state.waiting.waiting;
}

export function getWaitingStatus(state) {
  return state.waiting.status;
}

export function getWaitingInfo(state) {
  return state.waiting.info;
}

export function getCreditConfig(state) {
  const location = getCurrentLocation(state);
  if (location.type === "direct" || location.type === "indirect") {
    return location.credit || {};
  }
  return state.store.store?.credit || {};
}

export function getStoreCreditStatus(state) {
  return state.store.credit || 0;
}

export function getCreditTransactionHistoriesById(state) {
  return state.store.creditHistories.byId;
}

export function getCreditTransactionHistoriesCount(state) {
  return state.store.creditHistories.count;
}

export function getProviderOrdersById(state) {
  return state.order.providerOrders.items;
}

export function getProviderOrdersCount(state) {
  return state.order.providerOrders.count;
}

export function getExternalCustomerGroups(state) {
  return state.providerConfig.groups || {};
}

export function getProductsFromProvider(state) {
  return state.providerConfig.providerProducts;
}

export function getDraftOnoffConfig(state) {
  return state.providerConfig.onoffDraftConfig;
}

export function getLiveOnoffConfig(state) {
  return state.providerConfig.onoffLiveConfig;
}

export function getSearchedCherryPickers(state) {
  return state.providerConfig.cherryPickers;
}

export function getSearchedBlackList(state) {
  return Object.values(state.providerConfig.blackList || {});
}

export function getSearchedBlackListById(state) {
  return state.providerConfig.blackList;
}

export function getSearchedCustomerTierHistories(state) {
  return state.providerConfig.customerTierHistories;
}

export function getMirrorConfig(state) {
  return state.store.store.mirror;
}

export function getSenderName(state) {
  const storeInfo = getStoreInfo(state);
  const isMulti = isMultiLocation(state);
  const org = getCurrentOrganization(state);
  const location = getCurrentLocation(state);
  if (isMulti) {
    if (["owner", "admin"].includes(org.role)) {
      return storeInfo.name;
    } else {
      return location.name;
    }
  } else {
    return storeInfo.name;
  }
}

export function getSenderPhone(state) {
  const storeInfo = getStoreInfo(state);
  const isMulti = isMultiLocation(state);
  const org = getCurrentOrganization(state);
  const location = getCurrentLocation(state);
  if (isMulti) {
    if (["owner", "admin"].includes(org.role)) {
      return storeInfo.phone;
    } else {
      return location.phone;
    }
  } else {
    return storeInfo.phone;
  }
}
